export function fetchLinksPreview(urls) {
  const requestUrl = '/api_web/opengraph/';
  const data = { urls };

  const ajax = $.ajax({
    method: 'POST',
    url: requestUrl,
    data,
  });

  return Promise.resolve(ajax);
}
