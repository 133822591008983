import React from 'react';
import PropTypes from 'prop-types';

import DataCard from 'components/core/Profile/components/DataCard';

import { BillsContext } from './utils/withBills';
import TableView from './components/TableView';

function Bills({
  sectionId,
  title,
  description,
  questionText,
  emptyDescription,
  onClearAll,
  onSearch,
  bills,
  sort,
  paginator,
  isLoading,
  formatLegislatorUrl,
  searchFormComponents,
  trackingActions,
  ...restProps
}) {
  const context = {
    formatLegislatorUrl,
    emptyDescription,
    trackingActions,
    isLoading,
    allItems: bills,
    sort,
    paginator,
    onSearch,
    onClearAll,
  };

  return (
    <BillsContext.Provider value={context}>
      <DataCard
        id={sectionId}
        title={title}
        description={description}
        questionText={questionText}
        emptyDescription={emptyDescription}
      >
        <div>
          {searchFormComponents}
        </div>

        <TableView {...restProps} />
      </DataCard>
    </BillsContext.Provider>
  );
}

Bills.propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  questionText: PropTypes.string,
  emptyDescription: PropTypes.string,
  searchFormComponents: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.object.isRequired,
  paginator: PropTypes.object.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  bills: PropTypes.array.isRequired,
  trackingActions: PropTypes.object,
  formatLegislatorUrl: PropTypes.func.isRequired,
  tableColumns: PropTypes.array,
};

export default Bills;
