import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Placeholder() {
  return (
    <div className={styles.filters}>
      <div className={styles.group}>
        <div className={styles.selectAll}>
          <span className={styles.checkbox} />
          <span className={styles.checkboxText} />
        </div>
      </div>
      <div className={classNames(styles.autosuggest, styles.input)}>
        <span className={styles.inputText} />
        <span className={styles.inputDropdown} />
      </div>
    </div>
  );
}

export default Placeholder;
