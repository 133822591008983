import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Autosuggest from 'components/Autosuggest';
import ErrorMessage from 'components/core/ErrorMessage';

import CreatableInput from './components/CreatableInput';
import optionsParser from './optionsParser';

import theme from './theme';
import styles from './styles.module.scss';

export default function TagInput({
  defaultRawValues,
  allowCreate,
  strategy,
  creatable,
  multiple,
  withDisclaimer,
  withOverflow,
  isMobile,
  groupClassName,
  errorMessage,
  ...restProps
}) {
  const [initialValues] = useState(defaultRawValues);

  if (creatable) {
    return (
      <CreatableInput {...restProps} />
    );
  }

  return (
    <>
      <Autosuggest
        theme={theme({ isMobile, withDisclaimer, withOverflow, isError: !isEmpty(errorMessage) })}
        groupClassName={groupClassName}
        labelClassName={styles.label}
        strategy={strategy}
        multiple={multiple}
        defaultRawValues={initialValues}
        optionsParser={optionsParser(allowCreate)}
        {...restProps}
      />
      <ErrorMessage className={styles.errorMessage} message={errorMessage} />
    </>
  );
}

TagInput.defaultProps = {
  shouldOverrideValue: false,
  asyncCreatable: false,
  creatable: false,
  multiple: true,
  isMobile: false,
  withDisclaimer: false,
  withOverflow: false,
};

TagInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  uri: PropTypes.string,
  strategy: PropTypes.string,
  name: PropTypes.string,
  defaultRawValues: PropTypes.array,
  onChange: PropTypes.func,
  allowCreate: PropTypes.bool,
  shouldOverrideValue: PropTypes.bool,
  asyncCreatable: PropTypes.bool,
  creatable: PropTypes.bool,
  withDisclaimer: PropTypes.bool,
  withOverflow: PropTypes.bool,
  isMobile: PropTypes.bool,
  uniqueIdValue: PropTypes.bool,
  overrideValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  multiple: PropTypes.bool,
  reqMethod: PropTypes.oneOf(['GET', 'POST']),
  reqContentType: PropTypes.string,
  reqTransform: PropTypes.func,
  resTransform: PropTypes.func,
  groupClassName: PropTypes.string,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  errorMessage: PropTypes.string,
};
