import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Tooltip from 'components/core/Tooltip';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import styles from './styles.module.scss';

function ButtonGroupTooltip({ isLoading, tooltipNode, tooltipClassName, buttonClassName, ...restProps }) {
  const tooltipRef = useRef();

  const handleClick = () => {
    const isOpen = !isEmpty(tooltipRef.current.state.target);
    isOpen && setTimeout(tooltipRef.current.toggleHint, 0);
  };

  return (
    <div className={styles.container}>
      <Tooltip
        {...restProps}
        forwardRef={tooltipRef}
        content={tooltipNode}
        className={classNames(styles.tooltipContainer, tooltipClassName)}
        hintEvent="click"
        headerClassName={styles.tooltipHeader}
      >
        <Button
          className={classNames(styles.exportButton, buttonClassName)}
          theme="secondary"
          disabled={isLoading}
          onClick={handleClick}
        >
          <div className={styles.buttonText}>
            {isLoading
              ? <Loader size="small" theme="light" onlyIcon />
              : <i className="far fa-download" />
            }
            Export
          </div>

          <div className={styles.bar} />
          <div className={styles.icon}>
            <i className="fas fa-sort-down" />
          </div>
        </Button>
      </Tooltip>
    </div>
  );
}

ButtonGroupTooltip.propTypes = {
  isLoading: PropTypes.bool,
  tooltipNode: PropTypes.func.isRequired,
  tooltipClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default ButtonGroupTooltip;
