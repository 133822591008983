import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useListener } from 'react-bus';
import { every, isEmpty } from 'lodash';

import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import Select from 'components/core/Select';
import Input from 'components/core/Input';

import styles from './styles.module.scss';

function AddedDraggableInputItem({
  bus,
  data,
  message,
  replaceOldNode,
  parentComponentData,
  defaultComponentData,
  hideFirstOptionNode,
  firstOptionOptions,
  firstOptionPlaceholder,
  firstOptionTransform,
  secondOptionTransform,
  transformData,
  updateData,
  onFocus,
  inputType,
  secondaryOptionRender,
}) {
  const baseComponentData = useState(defaultComponentData);
  const [componentData, setComponentData] = parentComponentData || baseComponentData;
  const { firstOption, secondOption } = componentData || {};
  const { value: firstOptionValue } = firstOption || {};

  const defaultErrorData = { errorFirstOption: undefined, errorSecondOption: undefined };
  const [errors, setErrors] = useState(defaultErrorData);
  const { errorFirstOption, errorSecondOption } = errors || {};

  useListener('submitting', useCallback(() => {
    const isEmptyFirst = isEmpty(firstOptionValue);
    const isEmptySecond = !isEmptyFirst && firstOptionValue !== 'any' && isEmpty(secondOption);
    const validator = {
      ...isEmptyFirst && { errorFirstOption: true },
      ...isEmptySecond && { errorSecondOption: true },
    };

    if (!isEmpty(validator)) {
      setErrors(validator);
      bus.emit('errors', true);
    }
  }, [firstOptionValue, secondOption]));

  useEffect(() => {
    if (!every(componentData, property => isEmpty(property))) {
      const replaceNewNode = transformData({ componentData });

      updateData({
        payload: JSON.parse(
          JSON.stringify(data).replace(
            JSON.stringify(replaceOldNode),
            JSON.stringify(replaceNewNode),
          ),
        ),
      });
    }
  }, [componentData]);


  const transformProps = item => ({ componentData, item });
  const handleSecondOption = ({ target: { value } }) => setComponentData(secondOptionTransform(transformProps(value)));
  const getSecondaryOptionNode = () => {
    if (isEmpty(firstOptionValue) || firstOptionValue === 'any') return null;

    const props = {
      value: secondOption,
      onChange: handleSecondOption,
      onFocus: onFocus('errorSecondOption', setErrors),
      className: classNames(errorSecondOption && styles.invalid),
      type: inputType,
    };
    const customProps = { ...props, firstOptionValue };

    const customNode = secondaryOptionRender && secondaryOptionRender(customProps);

    return (
      customNode || (
        <section className={styles.value}>
          <Input {...props} />
        </section>
      )
    );
  };

  const handleFirstOption = (item) => {
    onFocus('errorSecondOption', setErrors)();
    setComponentData(firstOptionTransform(transformProps(item)));
  };

  const componentFirstOptionNode = !hideFirstOptionNode && (
    <section className={styles.selector}>
      <Select
        value={firstOptionValue}
        options={firstOptionOptions}
        placeholder={firstOptionPlaceholder}
        onChange={handleFirstOption}
        onFocus={onFocus('errorFirstOption', setErrors)}
        className={classNames(errorFirstOption && styles.invalid)}
        searchable={false}
        clearable={false}
        hasNewStyle
      />
    </section>
  );

  const componentSecondOptionNode = getSecondaryOptionNode();

  return (
    <div className={styles.container}>
      <div className={styles.base}>
        <span id="baseMessage">{message}</span> {componentFirstOptionNode} { componentSecondOptionNode }
      </div>
    </div>
  );
}

AddedDraggableInputItem.defaultProps = {
  secondOptionReqMethod: 'POST',
  secondOptionAsyncCreatable: true,
  hideFirstOptionNode: false,
  secondOptionMultiSelect: true,
  inputType: 'text',
};

AddedDraggableInputItem.propTypes = {
  bus: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  parentComponentData: PropTypes.array,
  replaceOldNode: PropTypes.object.isRequired,
  defaultComponentData: PropTypes.object.isRequired,
  hideFirstOptionNode: PropTypes.bool,
  firstOptionOptions: PropTypes.array.isRequired,
  firstOptionPlaceholder: PropTypes.string.isRequired,
  firstOptionTransform: PropTypes.func.isRequired,
  secondOptionTransform: PropTypes.func.isRequired,
  transformData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  secondaryOptionRender: PropTypes.func,
};

export default withSmartSearch(AddedDraggableInputItem);
