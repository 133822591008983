import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroupTooltip from './components/ButtonGroupTooltip';
import styles from './styles.module.scss';

const buttonGroupThemes = {
  light: styles.light,
};

function ButtonGroup({ children, theme, className }) {
  return (
    <div
      className={classNames(
        styles.btnGroup,
        theme && buttonGroupThemes[theme],
        className
      )}
      role="group"
    >
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(
    Object.keys(buttonGroupThemes)
  ),
  className: PropTypes.string,
};

export default ButtonGroup;

export { ButtonGroupTooltip };
