/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import { isEmpty, forEach, map, size, head } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants';
import { Column } from 'components/core/Table';
import { stringNumberType } from 'utils/shapes';

import ListItem from '../LinkListItem/components/ListItem';
import styles from './styles.module.scss';

function ViewMoreItem({
  keyName,
  dataList,
  isEmptyData,
  className,
  isObjectData,
  isPlainText,
  isLinkList,
  onClick,
  getHref,
  getItem,
}) {
  const dataListSize = size(dataList);

  function getSubItems(subItems) {
    if (isEmpty(subItems)) {
      return null;
    }

    return map(subItems, (item, subItemIndex) => (
      <p key={`${item}-${subItemIndex}`} className={classNames(styles.tooltipItem, styles.subItem)}>
        {item}
      </p>
    ));
  }

  /** For object dataList structure: [{ id, name, items }] */
  function getObjectTooltip() {
    return (
      <div className={styles.tooltipContent}>
        {map(dataList, (issue = {}, index) => {
          const { name, items } = issue;

          return (
            <Fragment key={`${issue}-${index}`}>
              <p className={classNames(styles.tooltipItem, styles.activeTitle)}>{name}</p>
              {getSubItems(items)}
            </Fragment>
          );
        })}
      </div>
    );
  }

  function getDefaultItem(item, isTooltipItem = false) {
    return getItem ? getItem(item, isTooltipItem) : item;
  }

  function tooltipNode() {
    if (isEmptyData) {
      return null;
    }

    if (isObjectData) {
      return getObjectTooltip();
    }

    if (isLinkList) {
      return (
        <div className={styles.tooltipContent}>
          {map(dataList, (issue, index) => (
            <ListItem
              key={`${issue.id}-${index}`}
              className={styles.tooltipLink}
              onClick={onClick}
              getHref={getHref}
              item={issue}
              column={keyName}
            />
          ))}
        </div>
      );
    }

    return (
      <div className={styles.tooltipContent}>
        {map(dataList, (issue, index) => (
          <p key={`${issue}-${index}`} className={styles.tooltipItem}>
            {getDefaultItem(issue, true)}
          </p>
        ))}
      </div>
    );
  }

  function renderTooltip(firstItem, listLength) {
    return (
      <div className={classNames('tooltip-column', styles.buttonContainer, className)}>
        <div className={classNames('tooltip-column__data', styles.label)}>
          <Tooltip
            content={tooltipNode()}
            placement="bottom"
            className={classNames(
              'tooltip-column__container',
              styles.tooltipWrapper,
              styles.tooltipContainer,
            )}
            appendTo={document.body}
            interactive
          >
            <div className={styles.flexContent}>
              <div>{firstItem}</div>
              <div className={styles.badge}>+{listLength} more</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  function getNestedItemsLength() {
    let currentDataLength = !isEmptyData ? dataListSize : 1;

    if (!isEmptyData) {
      forEach(dataList, ({ items }) => {
        !isEmpty(items) && (currentDataLength += size(items));
      });
    }

    return currentDataLength - 1;
  }

  function renderObjectIssueArea() {
    if (isEmptyData) {
      return EMPTY_VALUE;
    }

    const firstItem = head(dataList) || {};
    const name = firstItem.name || EMPTY_VALUE;

    if (dataListSize === 1) {
      return <div>{name}</div>;
    }

    return renderTooltip(name, getNestedItemsLength());
  }

  function renderDefaultIssueArea() {
    if (isEmptyData || dataListSize === 0) {
      return EMPTY_VALUE;
    }

    const firstItem = getDefaultItem(head(dataList));

    if (dataListSize === 1) {
      return <div>{firstItem}</div>;
    }

    return renderTooltip(firstItem, dataListSize - 1);
  }

  function renderListIssueArea() {
    if (isEmptyData || dataListSize === 0) {
      return EMPTY_VALUE;
    }

    const firstDataItem = head(dataList) || {};

    const firstItem = (
      <ListItem
        className={styles.tableLink}
        onClick={onClick}
        getHref={getHref}
        item={firstDataItem}
        column={keyName}
      />
    );

    if (dataListSize === 1) {
      return firstItem;
    }

    return renderTooltip(firstItem, dataListSize - 1);
  }

  function renderContent() {
    let areaContent = renderDefaultIssueArea;

    if (isLinkList) {
      areaContent = renderListIssueArea;
    }

    if (isObjectData) {
      areaContent = renderObjectIssueArea;
    }

    if (isPlainText) {
      return areaContent();
    }

    return (
      <Column key={keyName} keyName={keyName}>
        {areaContent}
      </Column>
    );
  }

  return renderContent();
}

ViewMoreItem.propTypes = {
  keyName: stringNumberType.isRequired,
  dataList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  getHref: PropTypes.func,
  isEmptyData: PropTypes.bool,
  className: PropTypes.string,
  isObjectData: PropTypes.bool,
  isPlainText: PropTypes.bool,
  getItem: PropTypes.func,
};

export default ViewMoreItem;
