import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Column } from 'components/core/Table';

import styles from './styles.module.scss';

const minThreshold = 50;
const maxThreshold = 90;

export default function ConfidenceColumn({ column, item }) {
  const { keyName } = column;
  const { confidence } = item || {};

  function getConfidenceClassName() {
    if (confidence < minThreshold) return styles.low;
    if (confidence >= minThreshold && confidence < maxThreshold) return styles.medium;

    return styles.high;
  }

  function renderConfidence() {
    return (
      <div className={styles.container}>
        <div className={classNames(styles.circle, getConfidenceClassName())} />
        <span>{Math.round(confidence)}%</span>
      </div>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>
      {renderConfidence}
    </Column>
  );
}

ConfidenceColumn.propTypes = {
  column: PropTypes.object.isRequired,
  item: PropTypes.object,
};
