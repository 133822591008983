import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LABEL_COLOR,
  TEXT_HIGHLIGHT_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  emphasizedText: {
    backgroundColor: TEXT_HIGHLIGHT_COLOR,
  },
  billDescriptionText: {
    fontSize: 8,
    fontWeight: 300,
    color: DEFAULT_LABEL_COLOR,
    lineHeight: 1.4,
  },
  billDescriptionLink: {
    fontSize: 8,
    lineHeight: 1.6,
  },
  billLabelText: {
    fontSize: 8,
    fontWeight: 500,
    color: DEFAULT_LABEL_COLOR,
  },
  billRowSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 6,
  },
  billColumnSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
    flexDirection: 'row',
    width: '50%',
  },
  billLastActionDateSection: {
    flex: 1,
  },
  billLastActionSection: {
    flex: 5,
    paddingRight: 20,
  },
  billLegislatorContainer: {
    marginRight: 9,
  },
  billLegislator: {
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 1.6,
  },
  billLegislatorIcon: {
    fontSize: 6,
    borderRadius: 2,
    padding: '1px 3px',
  },
});

export default styles;
