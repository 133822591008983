import {
  isEmpty,
  isEqual,
  isBoolean,
  isArray,
  omit,
  keys,
  xorWith,
} from 'lodash';

function countOptionFilters(option, emptyOption) {
  if (isEqual(option, emptyOption)) {
    return 0;
  }

  if (isArray(option)) {
    if (isEmpty(xorWith(option, emptyOption))) {
      return 0;
    }
  }

  return 1;
}

export function getAppliedFiltersLength(appliedFilters = {}, emptyState = {}) {
  const { options: appliedFiltersOptions } = appliedFilters;
  const { options: emptyStateOptions, omit: omitOptions = [] } = emptyState;
  const propsToOmit = omitOptions.concat(['searchValue']);
  const remainingAppliedFiltersOptions = omit(appliedFiltersOptions, propsToOmit);
  const remainingEmptyStateOptions = omit(emptyStateOptions, propsToOmit);

  if (isEmpty(remainingAppliedFiltersOptions)
    || isEmpty(remainingEmptyStateOptions)
    || isEqual(remainingAppliedFiltersOptions, remainingEmptyStateOptions)) {
    return 0;
  }

  return keys(remainingAppliedFiltersOptions)
    .map(key => countOptionFilters(
      remainingAppliedFiltersOptions[key],
      remainingEmptyStateOptions[key]
    ))
    .reduce((sum, current) => sum + current, 0);
}

export function areValuesChanged(optionValues, emptyState = {}, searchValue) {
  return (
    !isEmpty(optionValues)
    && !isEqual(optionValues, emptyState.options)
  ) || !isEmpty(searchValue);
}

export function getChangedValue(key, value, emptyValues = {}) {
  const isChanged = value !== emptyValues[key];
  if ((isBoolean(value) || !isEmpty(value)) && isChanged) {
    return { [key]: value };
  }

  return { [key]: emptyValues[key] };
}
