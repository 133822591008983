import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { DEFAULT_LOGO_URL } from 'utils/constants';

import styles from './styles.module.scss';

function ExportPreview({ logoUrl, documentTitle }) {
  const currentDate = moment().format(GLOBAL_DATE_FORMAT);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logoImage} src={logoUrl} alt="logo-custom" />

        <div>
          <p className={styles.currentDate}>Report ({currentDate})</p>
          <h2 className={styles.title}>{documentTitle}</h2>
        </div>
      </div>
    </div>
  );
}

ExportPreview.defaultProps = {
  logoUrl: DEFAULT_LOGO_URL,
};

ExportPreview.propTypes = {
  documentTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

export default ExportPreview;
