import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TextPlaceholder } from 'components/core/Placeholder';
import parentStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

function LegislatorPlaceholder({ className }) {
  return (
    <section className={classNames(parentStyles.card, styles.card, className)}>
      <div className={classNames(parentStyles.avatarContainer, styles.avatar)} />
      <div className={styles.content}>
        <TextPlaceholder className={classNames(parentStyles.title, styles.title)} />
        <TextPlaceholder className={classNames(parentStyles.description, styles.description)} />
        <TextPlaceholder className={classNames(parentStyles.contact, styles.contact)} />
        <TextPlaceholder className={classNames(parentStyles.partyIcon, styles.icon)} />
      </div>
    </section>
  );
}

LegislatorPlaceholder.propTypes = {
  className: PropTypes.string,
};

export default LegislatorPlaceholder;
