import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stringNumberType } from 'utils/shapes';

import { valueTypeShape } from '../../types';
import { NUMBER_COLUMN_ID, STRING_COLUMN_ID } from '../../constants';
import styles from '../../styles.module.scss';

function Default({
  style,
  className,
  valueType,
  value,
  item,
  isRowExpanded,
  toggleRow,
  children,
  columnRef,
}) {
  const customFormatter = typeof children === 'function';
  const columnClassNames = classNames(
    className,
    valueType === NUMBER_COLUMN_ID && styles.numberColumn
  );

  return (
    <td
      className={columnClassNames}
      style={style}
      ref={columnRef}
    >
      {customFormatter ? children(value, item, { isRowExpanded, toggleRow }) : value}
    </td>
  );
}

Default.defaultProps = {
  valueType: STRING_COLUMN_ID,
};

Default.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.bool,
    PropTypes.object,
  ]),
  valueType: valueTypeShape,
  item: PropTypes.shape({}),
  children: PropTypes.func,
  isRowExpanded: PropTypes.bool,
  toggleRow: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({
    [PropTypes.string]: stringNumberType,
  }),
  columnRef: PropTypes.func,
};

export default Default;
