import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import Loader from 'components/core/Loader';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Options({
  onEnableEdit,
  onDelete,
  isDeleting,
  showRepliesMessage,
}) {
  const customIcon = isDeleting && <Loader size="small" theme="light" onlyIcon />;

  const [isVisible, setIsVisible] = useState(false);
  const [isDeletingContent, setIsDeletingContent] = useState(false);

  const closeTooltip = () => setIsVisible(false);
  const openTooltip = () => setIsVisible(true);
  const openDeleteConfirmation = () => setIsDeletingContent(true);

  const resetTooltip = () => {
    setIsDeletingContent(false);
  };

  const handleDelete = () => {
    onDelete();
    closeTooltip();
  };

  const handleEdit = () => {
    onEnableEdit();
    closeTooltip();
  };

  const commonOptionButtonProps = {
    theme: 'light',
    className: styles.item,
  };

  const getOptionsTooltip = () => (
    <Fragment>
      <Button
        {...commonOptionButtonProps}
        onClick={handleEdit}
      >
        Edit comment
      </Button>
      <Button
        {...commonOptionButtonProps}
        onClick={openDeleteConfirmation}
      >
        Delete comment
      </Button>
    </Fragment>
  );

  const getDeleteTooltip = () => (
    <Fragment>
      <header className={styles.title}>Delete Comment</header>
      <p className={styles.deleteMessage}>
        Are you sure you want to delete this comment?
        {showRepliesMessage && (
          <span className={styles.deletePlaceholder}>All replies will be removed as well.</span>
        )}
      </p>
      <div className={styles.buttons}>
        <Button size="small" theme="light" onClick={closeTooltip}>
          Cancel
        </Button>
        <Button
          size="small"
          onClick={handleDelete}
          disabled={isDeleting}
          customIcon={customIcon}
        >
          Delete
        </Button>
      </div>
    </Fragment>
  );

  const getTooltipContent = () => (
    <div className={styles.tooltipContent}>
      {isDeletingContent ? getDeleteTooltip() : getOptionsTooltip()}
    </div>
  );

  return (
    <Tooltip
      content={getTooltipContent()}
      placement="bottom-end"
      theme="light"
      onHidden={resetTooltip}
      onClickOutside={closeTooltip}
      visible={isVisible}
      interactive
    >
      <i
        className={classNames(styles.options, 'fal fa-ellipsis-h fa-lg')}
        alt="Options"
        onClick={openTooltip}
      />
    </Tooltip>
  );
}

Options.propTypes = {
  onEnableEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool.isRequired,
  showRepliesMessage: PropTypes.bool.isRequired,
};

export default Options;
