import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getActiveItems } from './utils';
import LabelDot from './components/LabelDot';
import themes from './themes.module.scss';
import styles from './styles.module.scss';

function LabeledProgressBar({
  isDesktop,
  progressList,
  currentStatus,
  className,
  theme,
  mobileComponent,
  labelClassName,
  dotClassName,
}) {
  if (progressList.length < 2) {
    return null;
  }

  if (!isDesktop) {
    return mobileComponent;
  }

  const activeItems = getActiveItems(progressList, currentStatus);
  const firstItem = progressList[0];
  const isFirstActive = activeItems.includes(firstItem.id);

  function renderProgressStep(item, index) {
    const { id, label, isFailed } = item;

    /* splitted list last item */
    const isLast = index === progressList.length - 2;
    const shouldDisplayFirstItem = index === 0;
    const isActive = activeItems.includes(id);

    const progressClassName = classNames(
      styles.progressStep,
      isActive && themes.activeProgressStep,
    );

    return (
      <div key={id} className={progressClassName}>
        {shouldDisplayFirstItem && (
          <LabelDot
            isFirst
            isActive={isFirstActive}
            label={firstItem.label}
            className={dotClassName}
            labelClassName={labelClassName}
          />
        )}
        <LabelDot
          label={label}
          isActive={isActive}
          isLast={isLast}
          isFailed={isFailed}
          className={dotClassName}
          labelClassName={labelClassName}
        />
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, themes[theme], className)}>
      {progressList.slice(1).map(renderProgressStep)}
    </div>
  );
}

LabeledProgressBar.defaultProps = {
  theme: 'default',
  mobileComponent: null,
};

LabeledProgressBar.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'light',
  ]),
  progressList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  currentStatus: PropTypes.string,
  className: PropTypes.string,
  dotClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  mobileComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default LabeledProgressBar;
