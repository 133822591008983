import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Avatar from 'components/core/Avatar';
import useModal from 'components/core/Modal/useModal';
import { NAVIGATION_USER_PROFILE_IMG_ID } from 'components/Navigation/utils/constants';
import { closeFreshChat } from 'utils/freshchat';

import Panel from './components/Panel';
import styles from './styles.module.scss';

function Account({ photoUrl, name }) {
  const { toggleModal, isOpenModal } = useModal();

  const handleClick = () => {
    closeFreshChat();
    toggleModal();
  };

  return (
    <>
      <Button onClick={handleClick} theme="light" className={styles.account}>
        <Avatar
          id={NAVIGATION_USER_PROFILE_IMG_ID}
          className={styles.avatar}
          photoUrl={photoUrl}
          name={name}
        />
      </Button>

      <Panel isOpen={isOpenModal} toggleOpen={toggleModal} />
    </>
  );
}

Account.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string,
};

export default Account;
