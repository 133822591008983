import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import { isEmpty, isNumber } from 'lodash';

import { TrimTextItem } from 'components/core/Table/components/ColumnItem';
import { EMPTY_VALUE } from 'utils/constants';

import Link from '../Core';
import styles from './styles.module.scss';

const MAX_TEXT_LENGTH = 80;

function LinkList({ linkList, className, linkClassName }) {
  if (isEmpty(linkList)) {
    return EMPTY_VALUE;
  }

  const firstItem = linkList[0];
  const listSize = linkList.length;

  function renderLink(link, index) {
    const { href, value, ...restLinkProps } = link;
    const isFirstItem = !isNumber(index);
    const linkClassNames = classNames(
      isFirstItem ? styles.firstItemLink : styles.tooltipLink,
      linkClassName,
    );

    if (isEmpty(value) && isEmpty(href)) {
      return null;
    }

    if (isEmpty(href)) {
      return (
        <div key={`link-${value}-${index}`} className={linkClassNames}>
          <TrimTextItem
            textToTrim={value}
            maxTextLength={MAX_TEXT_LENGTH}
            withTooltip={false}
          />
        </div>
      );
    }

    return (
      <div key={`link-${href}-${index}`} className={styles.linkContainer}>
        <Link
          {...restLinkProps}
          href={href}
          theme="default"
          className={linkClassNames}
        >
          <TrimTextItem
            textToTrim={value || href}
            maxTextLength={MAX_TEXT_LENGTH}
            withTooltip={false}
          />
        </Link>
      </div>
    );
  }

  function renderTooltipLinks() {
    return (
      <div>
        {linkList.slice(1).map(renderLink)}
      </div>
    );
  }

  if (listSize === 1) {
    return renderLink(firstItem);
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Tooltip
        className="tooltip"
        content={renderTooltipLinks()}
        placement="bottom"
        interactive
      >
        <div className={styles.flexContainer}>
          {renderLink(firstItem)}
          <div className={styles.badge}>
            + {listSize - 1} more
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

const linkShape = PropTypes.shape({
  value: PropTypes.node,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
});

LinkList.propTypes = {
  linkList: PropTypes.arrayOf(linkShape),
  className: PropTypes.string,
  linkClassName: PropTypes.string,
};

export default LinkList;
