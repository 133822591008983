import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';

import { DEFAULT_TEXT_COLOR } from '../../utils/colors.scss';
import LabelTitle from '../LabelTitle';

const styles = StyleSheet.create({
  valueText: {
    fontWeight: 400,
    fontSize: 12,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function LabelTextArray({ style, label, values }) {
  if (isEmpty(values)) {
    return (
      <View style={style}>
        {label && <LabelTitle label={label} />}
        <Text style={styles.valueText}>{EMPTY_VALUE}</Text>
      </View>
    );
  }

  return (
    <View style={style}>
      {label && <LabelTitle label={label} />}
      {values.map((value, index) => (
        <Text key={`biography-${index}`} style={styles.valueText}>{value}</Text>
      ))}
    </View>
  );
}

LabelTextArray.defaultProps = {
  style: {},
};

LabelTextArray.propTypes = {
  label: PropTypes.string,
  values: PropTypes.array,
  style: PropTypes.object,
};

export default LabelTextArray;
