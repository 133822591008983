import React from 'react';
import PropTypes from 'prop-types';

import { EXPORT_RECORD_LIMIT } from 'utils/constants/export';
import { CANCEL_BUTTON, CONTINUE_BUTTON } from 'utils/constants/modal';
import Modal from 'components/core/Modal';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function ExportAllWarning({ isOpenModal, toggleModal, onExport, customKeyMessage = 'records' }) {
  function handleClickConfirm() {
    toggleModal();
    onExport();
  }

  const exportActions = (
    <div className={styles.buttons}>
      <Button
        type="small"
        theme="light"
        onClick={toggleModal}
        className={styles.modalActionBtn}
      >
        {CANCEL_BUTTON}
      </Button>

      <Button
        type="small"
        onClick={handleClickConfirm}
        className={styles.modalActionBtn}
      >
        {CONTINUE_BUTTON}
      </Button>
    </div>
  );

  return (
    <Modal
      title="Bulk Export"
      isOpen={isOpenModal}
      buttons={exportActions}
      toggleOpen={toggleModal}
    >
      <p className={styles.exportModalTitle}>Export limit exceeded</p>
      <p className={styles.exportModalContent}>
        Your export exceeded the limit of {EXPORT_RECORD_LIMIT} {customKeyMessage}.
        To export the first {EXPORT_RECORD_LIMIT} {customKeyMessage} in your search, press “{CONTINUE_BUTTON}.”
        Otherwise, press “{CANCEL_BUTTON}” to go back and refine your search.
      </p>
    </Modal>
  );
}

ExportAllWarning.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  customKeyMessage: PropTypes.string,
};

export default ExportAllWarning;
