import moment from 'moment';

export function extractAllEvents(events) {
  return events.flatMap(dayEvents => dayEvents.events);
}

export function getCalendarEventsByType(events, type) {
  return events
    .filter(event => event.status[type])
    .map(event => moment(event.date));
}
