import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// eslint-disable-next-line import/no-cycle
import ProgressCard from './components/ProgressCard'; // TODO: fix dependency cycle
import styles from './styles.module.scss';

const footerPositions = {
  left: styles.left,
  center: styles.center,
  right: styles.right,
};

const themes = {
  default: styles.default,
  white: styles.white,
  dark: styles.dark,
  light: styles.light,
  medium: styles.medium,
  none: null,
};

function Card({
  id,
  title,
  headerNodes,
  children,
  footerNodes,
  footerPosition,
  className,
  titleClassName,
  headerClassName,
  headerNodeWrapperClassName,
  footerClassName,
  theme,
}) {
  const isHeaderPresent = title || headerNodes;

  return (
    <div id={id} className={classNames(styles.card, themes[theme], className)}>
      {isHeaderPresent && (
        <div className={classNames(styles.header, headerClassName)}>
          {title && <h2 className={classNames(styles.title, titleClassName)}>{title}</h2>}
          {headerNodes && <div className={classNames(headerNodeWrapperClassName)}>{headerNodes}</div>}
        </div>
      )}

      {children}

      {footerNodes && (
        <div className={classNames(styles.footer, footerPositions[footerPosition], footerClassName)}>
          {footerNodes}
        </div>
      )}
    </div>
  );
}

Card.defaultProps = {
  footerPosition: 'right',
  theme: 'default',
};

Card.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  headerNodes: PropTypes.node,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerNodeWrapperClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  children: PropTypes.node,
  footerNodes: PropTypes.node,
  footerPosition: PropTypes.oneOf(
    Object.keys(footerPositions)
  ),
  theme: PropTypes.oneOf(
    Object.keys(themes)
  ),
};

export default Card;

export { ProgressCard };
