import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBus } from 'react-bus';
import { DragDropContext } from 'react-beautiful-dnd';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import PageNotFound from 'components/core/PageNotFound';
import Button from 'components/core/Button';
import Card from 'components/core/Card';

import { SmartSearchContext } from '../../utils/withSmartSearch';
import useSmartSearch from '../../utils/useSmartSearch';
import StatementsAndFilters from '../StatementsAndFilters';
import GeneralDetails from '../GeneralDetails';
import Definitions from '../Definitions';
import styles from './styles.module.scss';

function Container({
  match,
  history,
  builder,
  updateTitle,
  updateDescription,
  updateIsTemplate,
  updateTransactor,
  updateData,
  submitList,
  getList,
  reset,
  draggableItems,
  afterSubmittingURL,
  submitButtonLabel,
  onSubmit,
  onSuccess,
  labels,
  disableIsTemplate,
  titleCharactersLimit,
  descriptionCharactersLimit,
  enableTooltip,
  isDescriptionRequired,
  getFilterTranscript,
  onCancel,
  trackingActions,
}) {
  const { id: listId } = match.params;
  const { govpredictAdmin } = gon.currentUser;
  const copyId = new URLSearchParams(history.location.search).get('copy_id');
  const { error, data, title, description, isTemplate, ui: { isLoading } = {} } = builder;
  const isInitialLoading = (listId || copyId) && isEmpty(data);
  const { isMobile, isDesktop } = useMediaQuery();
  const bus = useBus();

  const {
    submitting,
    isDraggingFilter,
    isDraggingStatement,
    setIsDraggingFilter,
    setIsDraggingStatement,
    onBeforeCapture,
    onDragEnd,
    onDelete,
    onCreate,
    onFocus,
  } = useSmartSearch({
    bus,
    history,
    listId,
    data,
    title,
    description,
    isTemplate,
    updateData,
    submitList,
    draggableItems,
    afterSubmittingURL,
    onSuccess,
    trackingActions,
  });

  useEffect(() => {
    const id = listId || copyId;
    id && getList({ id, copy: !listId });

    return reset;
  }, []);

  const smartSearchContext = {
    ...builder,
    isMobile,
    isDraggingStatement,
    isDraggingFilter,
    updateTitle,
    updateDescription,
    updateIsTemplate,
    updateTransactor,
    updateData,
    setIsDraggingFilter,
    setIsDraggingStatement,
    deleteStatement: onDelete,
    deleteFilter: onDelete,
    submitList,
    onFocus,
    bus,
    draggableItems,
    labels,
    disableIsTemplate,
    titleCharactersLimit,
    descriptionCharactersLimit,
    enableTooltip,
    isDescriptionRequired,
    getFilterTranscript,
  };

  if (error) {
    return <PageNotFound />;
  }

  const getSubmitButtonLabel = () => {
    if (submitButtonLabel) return submitButtonLabel;

    if (listId && !isTemplate || listId && (govpredictAdmin && isTemplate)) {
      return 'Update search';
    }

    return 'Create new search';
  };

  const handleSubmit = (event) => {
    if (onSubmit) {
      return onSubmit(event, onCreate);
    }

    return onCreate(event);
  };

  const actionsNode = !isInitialLoading && (
    <>
      {onCancel && (
        <Button
          type="small"
          theme="light"
          disabled={isLoading || submitting}
          onClick={onCancel}
          className={styles.cancel}
        >
          Cancel
        </Button>
      )}

      <Button
        size="small"
        className={styles.submit}
        disabled={isLoading || submitting}
        onClick={handleSubmit}
      >
        {getSubmitButtonLabel()}
      </Button>
    </>
  );

  function renderContent() {
    const iconNode = <img src="/images/sad-face.svg" alt="Not found" />;

    if (isDesktop) {
      return (
        <div className={styles.container}>
          <div className={styles.form} id="form">
            <Card footerClassName={styles.cardFooter} footerNodes={actionsNode}>
              {isInitialLoading && (
                <div className={styles.cardLoading} id="loading">
                  Loading...
                </div>
              )}

              {!isInitialLoading && (
                <div>
                  <div className={styles.cardContent}>
                    <GeneralDetails />
                  </div>

                  <div className={styles.cardContent}>
                    <Definitions />
                  </div>
                </div>
              )}
            </Card>
          </div>

          <StatementsAndFilters />
        </div>
      );
    }

    return (
      <div className={classNames(styles.container, styles.isEmpty)}>
        <EmptyPlaceholder
          title="Oops!"
          iconNode={iconNode}
          description="This feature is only available on desktop."
        />
      </div>
    );
  }

  return (
    <SmartSearchContext.Provider value={smartSearchContext}>
      <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
        {renderContent()}
      </DragDropContext>
    </SmartSearchContext.Provider>
  );
}

Container.defaultProps = {
  trackingActions: {
    trackItemAddedToBuilder: () => {},
  },
};

Container.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  builder: PropTypes.object.isRequired,
  updateTitle: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired,
  updateIsTemplate: PropTypes.func.isRequired,
  updateTransactor: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  submitList: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  draggableItems: PropTypes.array.isRequired,
  afterSubmittingURL: PropTypes.string,
  submitButtonLabel: PropTypes.node,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  labels: PropTypes.object,
  disableIsTemplate: PropTypes.bool,
  titleCharactersLimit: PropTypes.number,
  descriptionCharactersLimit: PropTypes.number,
  enableTooltip: PropTypes.bool,
  isDescriptionRequired: PropTypes.bool,
  getFilterTranscript: PropTypes.func,
  onCancel: PropTypes.func,
  trackingActions: PropTypes.shape({
    trackItemAddedToBuilder: PropTypes.func,
  }),
};

export default Container;
