import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from '../styles.module.scss';

function MenuItem({ item, activeItem, onClick }) {
  const { title, id } = item || {};
  const { id: activeId } = activeItem || {};
  const isActive = activeId === id;

  function handleClick() {
    const currentItem = isActive ? {} : item;
    onClick(currentItem);
  }

  return (
    <Button
      theme="light"
      size="none"
      className={classNames(styles.button, isActive && styles.buttonActive)}
      onClick={handleClick}
    >
      {title}
    </Button>
  );
}

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  activeItem: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default MenuItem;
