import { PLACEHOLDER_TYPES } from './constants';

function useRenderPlaceholders({
  isPlaceholderBeforeCard,
  hasEmptyPlaceholderComponent,
  hasChildren,
}) {
  const getCardPlaceholderData = ({ draggingFromThisWith, draggingOverWith }) => ({
    draggingCardId: draggingFromThisWith || draggingOverWith,
    cardPlaceholderType: draggingFromThisWith ? PLACEHOLDER_TYPES.draggedOut : PLACEHOLDER_TYPES.draggedIn,
  });

  const shouldRenderEmptyPlaceholder = ({ draggingCardId }) => (hasEmptyPlaceholderComponent && !hasChildren && !draggingCardId);

  const canRenderCardPlaceholder = ({
    isPlaceholderRendered,
    draggingCardId,
  }) => (!!draggingCardId && !isPlaceholderRendered);

  // placeholder should be rendered before the first card that gets true for isPlaceholderBeforeCard
  const shouldRenderCardPlaceholder = ({
    cardId,
    draggingCardId,
    isPlaceholderRendered,
  }) => (
    canRenderCardPlaceholder({
      isPlaceholderRendered,
      draggingCardId,
    }) && isPlaceholderBeforeCard({ cardId, draggingCardId })
  );

  return {
    getCardPlaceholderData,
    shouldRenderEmptyPlaceholder,
    canRenderCardPlaceholder,
    shouldRenderCardPlaceholder,
  };
}

export default useRenderPlaceholders;
