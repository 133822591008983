import moment from 'moment/moment';
import { filter, toLower, isEmpty } from 'lodash';

import {
  GLOBAL_DATE_FORMAT,
  CALENDAR_DATE_FORMAT,
} from 'utils/constants/date';
import { formatDateBy } from 'utils/date';
import {
  FAX_TYPE,
  PHONE_TYPE,
} from 'components/core/Profile/utils/constants';
import { joinCompact } from 'utils/string';

export const PAGE_SIZE = 10;
export const EXPORT_PER_PAGE = { per_page: 100 };

export function formatToMoment(date) {
  // We are using the strict parsing mode here, to make sure
  // we don't get unexpected values while converting the date.
  const isGlobalFormat = moment(date, GLOBAL_DATE_FORMAT, true).isValid();
  const isCalendarFormat = moment(date, CALENDAR_DATE_FORMAT, true).isValid();

  if (isGlobalFormat) {
    return moment(date, GLOBAL_DATE_FORMAT, true);
  }

  if (isCalendarFormat) {
    return moment(date, CALENDAR_DATE_FORMAT, true);
  }

  return date && moment(date);
}

export function formatDate(date) {
  return formatDateBy(date, GLOBAL_DATE_FORMAT);
}

export function getCongressOptions(congressOptions) {
  const formatOptions = [];

  Object.keys(congressOptions).reverse().forEach(key => formatOptions.push({
    value: key,
    label: congressOptions[key],
  }));

  return formatOptions;
}

export const getFullName = (person, displayFullName = false) => {
  const { fullName, firstName, lastName } = person || {};
  const name = joinCompact([firstName, lastName], ' ');

  if (displayFullName) {
    return isEmpty(fullName) ? name : fullName;
  }

  return name;
};

export const getValidPhones = phones => filter(
  phones,
  ({ type }) => toLower(type) === FAX_TYPE || toLower(type) === PHONE_TYPE
);
