import React from 'react';
import Tooltip from '@tippyjs/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import Card from 'components/core/Card';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { ContextualMenu } from 'components/core/Icons';
import { cardGroupTooltipZIndex } from 'style/global/_variables.scss';

import styles from './styles.module.scss';

function CardGroup({
  isMarked,
  isChecked,
  isCheckboxDisabled,
  name,
  toggleCheckbox,
  children,
  className,
  tooltipContent,
  headerContent,
  isCheckboxPresent,
  contentClassName,
  headerContentClassName,
  checkboxClassName,
  id,
}) {
  const cardGroupClassNames = classNames(styles.cardGroup, className, isMarked && styles.isMarked);
  const { isMobile } = useMediaQuery();

  function renderCardCheckbox() {
    if (!isCheckboxPresent) {
      return null;
    }

    return (
      <div className={classNames(styles.checkbox, checkboxClassName)}>
        <Checkbox
          name={name}
          isChecked={isChecked}
          onChange={toggleCheckbox}
          isDisabled={isCheckboxDisabled}
        />
      </div>
    );
  }

  function renderCardActions() {
    if (!tooltipContent) {
      return null;
    }

    return (
      <div className={styles.actions}>
        <Tooltip
          content={tooltipContent}
          theme="light"
          trigger="click"
          placement="bottom-end"
          interactive
          className={styles.hint}
          zIndex={cardGroupTooltipZIndex}
        >
          <span className={styles.hintBtn}>
            <ContextualMenu
              theme="none"
              position="none"
              className={styles.contextualMenu}
              iconClassName={classNames('fa-ellipsis-v', styles.icon)}
              iconSizeClassName="far"
              isDefaultStyle={false}
            />
          </span>
        </Tooltip>
      </div>
    );
  }

  if (isMobile) {
    const hasHeader = isCheckboxPresent || tooltipContent || headerContent;

    return (
      <div className={cardGroupClassNames} id={id}>
        {hasHeader && (
          <Card className={styles.header}>
            {renderCardCheckbox()}

            <div className={classNames(styles.headerContent, headerContentClassName)}>
              {headerContent}
            </div>

            {renderCardActions()}
          </Card>
        )}

        <Card className={classNames(styles.content, contentClassName)}>
          {children}
        </Card>
      </div>
    );
  }

  return (
    <div className={cardGroupClassNames} id={id}>
      {renderCardCheckbox()}

      <Card theme="none" className={classNames(styles.content, contentClassName)}>
        {children}
      </Card>

      {renderCardActions()}
    </div>
  );
}

CardGroup.defaultProps = {
  isChecked: false,
  isCheckboxPresent: true,
};

CardGroup.propTypes = {
  children: PropTypes.node,
  headerContent: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  headerContentClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isChecked: PropTypes.bool,
  toggleCheckbox: PropTypes.func.isRequired,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
  isCheckboxPresent: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
  isMarked: PropTypes.bool,
  id: PropTypes.string,
};

export default CardGroup;
