import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View, Text } from '@react-pdf/renderer';

import styles from './styles';

function CreatedBy({ createdBy }) {
  if (isEmpty(createdBy)) {
    return null;
  }

  return (
    <View style={styles.createdBy}>
      <Text style={styles.label}>Created by: </Text>
      <Text>{createdBy}</Text>
    </View>
  );
}

CreatedBy.propTypes = {
  createdBy: PropTypes.string,
};

export default CreatedBy;
