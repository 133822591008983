import React from 'react';
import PropTypes from 'prop-types';

import withMap from '../../utils/withMap';
import styles from './styles.module.scss';

function MapPlaceholder({ loadingMessage }) {
  return (
    <div className={styles.mapPlaceholder}>
      <div className={styles.loading}>
        <p className={styles.loadingMessage}>{loadingMessage}</p>
      </div>
    </div>
  );
}

MapPlaceholder.defaultProps = {
  loadingMessage: 'Loading map',
};

MapPlaceholder.propTypes = {
  loadingMessage: PropTypes.string,
};

export default withMap(MapPlaceholder);
