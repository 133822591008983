function getNavigationElements() {
  const navigationElement = document.getElementById('navigation');
  const mainContainerElement = document.getElementById('main');

  return {
    navigationElement,
    mainContainerElement,
  };
}

export function pushLayoutForProductBar() {
  const { navigationElement, mainContainerElement } = getNavigationElements();

  if (navigationElement) {
    navigationElement.classList.add('with-product-bar');
  }

  if (mainContainerElement) {
    mainContainerElement.classList.add('with-product-bar');
  }
}

export function pullLayoutForProductBar() {
  const { navigationElement, mainContainerElement } = getNavigationElements();

  if (navigationElement) {
    navigationElement.classList.remove('with-product-bar');
  }

  if (mainContainerElement) {
    mainContainerElement.classList.remove('with-product-bar');
  }
}

export function canRenderTopBar(isMobile = false) {
  const { currentUser } = gon || {};
  const { has_p2a_account } = currentUser || {};

  return has_p2a_account && !isMobile;
}
