import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { FILL_END_COLOR, CHART_OPTIONS } from './constants';

export function transformChartData(lineChartData = []) {
  return {
    chartData: lineChartData.map(item => item.count),
    labels: lineChartData
      .map(item => moment(item.date).format('MMM \'YY')),
  };
}

export function getGradientColor(chartRef, fillStartColor, fillEndColor, gradientY1) {
  if (isEmpty(chartRef)) {
    return fillStartColor;
  }

  try {
    const ctx = chartRef.chartInstance.ctx;
    const gradient = ctx.createLinearGradient(10, 0, 10, gradientY1);

    gradient.addColorStop(0, fillStartColor);
    gradient.addColorStop(1, fillEndColor || FILL_END_COLOR);

    return gradient;
  } catch (error) {
    if (window.Sentry) {
      window.Sentry.captureMessage('Error applying graph gradient background.', error);
    }

    return fillStartColor;
  }
}

export function getChartOptions({ isMobile, tooltipLabel, maxXAxisTicksLimit, rotateMobileXTicks }) {
  const label = tooltipLabel || 'Bills introduced';
  const getTooltipLabel = data => `${label}: ${data.value}`;

  const options = CHART_OPTIONS;
  options.tooltips = {
    ...options.tooltips,
    callbacks: {
      label: getTooltipLabel,
    },
  };

  if (maxXAxisTicksLimit) {
    options.scales.xAxes[0].ticks.maxTicksLimit = maxXAxisTicksLimit;
  }

  if (isMobile) {
    options.elements.point.radius = 0;

    if (rotateMobileXTicks) {
      options.scales.xAxes[0].ticks.maxRotation = 90;
      options.scales.xAxes[0].ticks.minRotation = 90;
    }
  }

  return options;
}
