import map from 'lodash/map';

import { transformSubscription } from 'redux/subscriptions/utils/transformers';

export function transformWorkspacesForTiles(workspaces) {
  return map(workspaces, ({ value, label }) => ({ name: label, token: value }));
}

export function transformSubscriptionsForUI(subscriptions, workspaces) {
  return map(subscriptions, (subscription, index) => ({
    subscription: transformSubscription(subscription),
    feed: workspaces[index],
  }));
}
