import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import { ComposableMap, ZoomableGroup, Geographies } from 'react-simple-maps';

import Legend from './components/Legend';
import MapState from './components/MapState';
import {
  HIGHLIGHT_COLOR,
  MAP_COLORS,
  GEOGRAPHY_LINK,
  MAP_HEIGHT,
  PROJECTION_NAME,
  PROJECTION_CONFIG,
} from './utils/constants';
import useSimpleStatesMap from './useSimpleStatesMap';
import styles from './styles.module.scss';

function SimpleStatesMap({
  data,
  mapColors,
  onStateClick,
  selectedState,
  customStateCounter,
  customColorLimits,
  className,
  isEqualInterval,
  federalBillsCount,
  isSelectedFederalBills,
  onFilterFederalBills,
}) {
  const {
    tooltipContent,
    legendLimits,
    colorLimits,
    onStateHover,
  } = useSimpleStatesMap({
    isEqualInterval,
    customColorLimits,
    customStateCounter,
    data,
  });

  function renderStates(geographies, projection) {
    const { limits, stateCounters } = colorLimits;

    return geographies.map((geography, key) => (
      <Tooltip
        key={key}
        content={tooltipContent}
        className={styles.tooltipContainer}
        placement="bottom"
      >
        <g>
          <MapState
            projection={projection}
            geography={geography}
            colorLimits={limits}
            selectedState={selectedState}
            stateCounters={stateCounters}
            onStateClick={onStateClick}
            mapColors={mapColors}
            onStateHover={onStateHover}
          />
        </g>
      </Tooltip>
    ));
  }

  return (
    <Fragment>
      <ComposableMap
        projection={PROJECTION_NAME}
        projectionConfig={PROJECTION_CONFIG}
        height={MAP_HEIGHT}
        className={classNames(styles.mapStyles, className)}
      >
        <ZoomableGroup disablePanning>
          <Geographies geography={GEOGRAPHY_LINK} disableOptimization>
            {renderStates}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Legend
        limits={legendLimits}
        mapColors={mapColors}
        selectedState={selectedState}
        onStateClick={onStateClick}
        isSelectedFederalBills={isSelectedFederalBills}
        federalBillsCount={federalBillsCount}
        onFilterFederalBills={onFilterFederalBills}
      />
    </Fragment>
  );
}

SimpleStatesMap.defaultProps = {
  mapColors: MAP_COLORS,
  mapHighlightColor: HIGHLIGHT_COLOR,
  isEqualInterval: true,
};

SimpleStatesMap.propTypes = {
  data: PropTypes.array.isRequired,
  mapColors: PropTypes.array.isRequired,
  onStateClick: PropTypes.func,
  customStateCounter: PropTypes.func,
  customColorLimits: PropTypes.func,
  selectedState: PropTypes.string,
  className: PropTypes.string,
  isEqualInterval: PropTypes.bool,
  federalBillsCount: PropTypes.number,
  isSelectedFederalBills: PropTypes.bool,
  onFilterFederalBills: PropTypes.func,
};

export default SimpleStatesMap;
