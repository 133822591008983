import isNotFoundError from 'utils/http/isNotFoundError';

export const fetchBriefing = url => () => {
  const ajax = $.get(url);

  const onSuccess = ({ report_url }) => (report_url);

  const onFailure = (error) => {
    if (isNotFoundError(error)) {
      return '';
    }

    throw error;
  };

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
};
