import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import {
  uniqWith,
  isEmpty,
  isEqual,
  xorWith,
  map,
} from 'lodash';

import ErrorMessage from 'components/core/ErrorMessage';

import {
  buildMention,
  createFetchFunction,
  renderSuggestion,
  createOnAddFunction,
  createFormikHandleFunction,
  mentionShape,
  formatMention,
  buildOnChangeEventParams,
} from './utils';
import { THEMES, buildClassNames, classNamesShape } from './utils/styles';
import TextWithMentions from './components/TextWithMentions';

function TextareaWithMentions({
  mentionsData,
  onChange,
  onMentionsChange,
  onBlur,
  value,
  isError,
  theme,
  classNames,
  errorMessage,
  ...restProps
}) {
  const [currentMentions, setCurrentMentions] = useState([]);
  const builtMentions = mentionsData.map(buildMention);
  const hasError = isError || !isEmpty(errorMessage);

  const handleChange = (event, newValue, newPlainTextValue, allMentions) => {
    onChange && onChange(
      buildOnChangeEventParams({
        event,
        newValue,
        newPlainTextValue,
        allMentions,
      })
    );

    const mentions = uniqWith(
      allMentions.map(formatMention),
      isEqual
    );
    const differentMentions = xorWith(mentions, currentMentions, isEqual);
    if (!isEmpty(differentMentions)) {
      onMentionsChange && onMentionsChange(mentions);
      setCurrentMentions(mentions);
    }
  };

  return (
    <>
      <MentionsInput
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        allowSuggestionsAboveCursor
        classNames={buildClassNames(classNames, theme, hasError)}
        {...restProps}
      >
        {
          map(builtMentions, mentionData => (
            <Mention key={mentionData.trigger} {...mentionData} />
          ))
        }
      </MentionsInput>
      <ErrorMessage message={errorMessage} />
    </>
  );
}

TextareaWithMentions.defaultProps = {
  theme: 'default',
  classNames: {},
  isError: false,
};

TextareaWithMentions.propTypes = {
  mentionsData: PropTypes.arrayOf(mentionShape).isRequired,
  onChange: PropTypes.func,
  onMentionsChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  theme: PropTypes.oneOf(Object.keys(THEMES)),
  classNames: PropTypes.shape(classNamesShape),
};

export default TextareaWithMentions;
export {
  createFetchFunction,
  renderSuggestion,
  createOnAddFunction,
  createFormikHandleFunction,
  TextWithMentions,
  mentionShape,
};
