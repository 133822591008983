import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

import { fieldColumnsLabels } from '../../../utils/constants';
import styles from '../styles.module.scss';

function Item({ title, content }) {
  if (isEmpty(content) || typeof content === 'object') {
    return null;
  }

  let currentContent = content;

  if (typeof content === 'boolean') {
    currentContent = content ? 'Yes' : 'No';
  }

  if (title === fieldColumnsLabels.createdAt) {
    currentContent = moment(content).format(GLOBAL_DATE_FORMAT);
  }

  return (
    <div className={styles.group}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{currentContent}</div>
    </div>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
};

export default Item;
