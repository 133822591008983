export function isOptionSelected(sortOption, selectedSort) {
  const { value, direction } = sortOption || {};
  const { sort: selectedValue, direction: selectedDirection } = selectedSort || {};

  if (direction) {
    return (selectedValue === value) && (direction === selectedDirection);
  }

  return selectedValue === value;
}
