import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { validateFileFormat, handleValidationError } from './utils/validations';
import SelectedFile from './components/SelectedFile';
import useFileUrl from './useFileUrl';
import styles from './styles.module.scss';

function FilePicker({
  label,
  onChange,
  supportedFormats,
}) {
  const [file, setFile] = useState();
  useFileUrl({ file, onChange });

  function setFileIfValid(newFile) {
    try {
      validateFileFormat({
        file: newFile,
        supportedFormats,
      });

      setFile(newFile);
    } catch (error) {
      handleValidationError(error);
    }
  }

  function handleChange({ target }) {
    const [newFile] = target.files;

    setFileIfValid(newFile);
  }

  const resetFile = () => setFile();

  if (file instanceof File) {
    return (
      <SelectedFile
        file={file}
        resetFile={resetFile}
      />
    );
  }

  return (
    <div className={styles.container}>
      <i className="fas fa-paperclip" />

      <label
        htmlFor="filepicker"
        className={styles.label}
      >
        {label}
      </label>

      <input
        id="filepicker"
        type="file"
        onChange={handleChange}
        className={styles.input}
      />
    </div>
  );
}

FilePicker.defaultProps = {
  label: 'Choose file',
  supportedFormats: [],
};

FilePicker.propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default FilePicker;
