import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EMPTY_VALUE } from 'utils/constants';
import QuestionTooltip from 'components/core/Table/components/Header/components/Tooltip';

import styles from './styles.module.scss';

function DataField({
  isLoading,
  label,
  value,
  className,
  containerClassName,
  questionClassName,
  questionText,
}) {
  if (isLoading) {
    return null;
  }

  return (
    <div className={classNames(styles.item, containerClassName)}>
      <div className={styles.flexItem}>
        {label && (
          <p className={styles.itemTitle}>
            {label}
          </p>
        )}
        {questionText && (
          <QuestionTooltip
            iconClassName={styles.questionIcon}
            contentClassName={classNames(styles.questionContent, questionClassName)}
            content={questionText}
          />
        )}
      </div>

      <div className={classNames(styles.currentData, className)}>
        {value || EMPTY_VALUE}
      </div>
    </div>
  );
}

DataField.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  questionClassName: PropTypes.string,
  questionText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DataField;
