import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withMap from 'components/core/Map/utils/withMap';
import { parseArray } from 'components/core/Map/utils';

import { getDescriptionLabel, getLink } from '../utils';
import LinkList from './LinkList';
import styles from '../styles.module.scss';

function Body({ properties, onFetch, profileUrl }) {
  const {
    id,
    district,
    phone,
    email,
    organization,
    title,
    lists,
    tags,
  } = properties;

  const commonListProps = {
    onFetch,
    profileUrl,
    markerId: id,
  };

  return (
    <section className={styles.content}>
      <ul className={styles.list}>
        {getDescriptionLabel('organization', organization)}
        {getDescriptionLabel('title', title)}
        {getDescriptionLabel('district', district)}
        {getDescriptionLabel('phone', getLink('tel:', phone))}
        {getDescriptionLabel('email', getLink('mailto:', email))}
        {!isEmpty(parseArray(lists)) && getDescriptionLabel('lists', (
          <LinkList
            {...commonListProps}
            data={lists}
            columnKeyName="lists"
          />
        ))}
        {!isEmpty(parseArray(tags)) && getDescriptionLabel('tags', (
          <LinkList
            {...commonListProps}
            data={tags}
            columnKeyName="tags"
          />
        ))}
      </ul>
    </section>
  );
}

Body.propTypes = {
  properties: PropTypes.object.isRequired,
  onFetch: PropTypes.func.isRequired,
  profileUrl: PropTypes.string.isRequired,
};

export default withMap(Body);
