import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import classNames from 'classnames';

import useAreaChart from './useAreaChart';
import styles from './styles.module.scss';

function ExportTimeSeries({
  chartClassName,
  lineChartData,
  onSetChartImage,
  ...chartOptionProps
}) {
  const { data, chartOptions, chartRef } = useAreaChart({
    lineChartData,
    isMobile: false,
    ...chartOptionProps,
  });

  function handleAnimationComplete() {
    const chartInstance = chartRef.current && chartRef.current.chartInstance;
    chartInstance && onSetChartImage && onSetChartImage(chartInstance.toBase64Image());
  }

  const exportChartOptions = {
    ...chartOptions,
    animation: {
      onComplete: handleAnimationComplete,
    },
  };

  return (
    <div className={classNames(styles.exportGraph, chartClassName)}>
      <Line ref={chartRef} options={exportChartOptions} data={data} />
    </div>
  );
}

ExportTimeSeries.propTypes = {
  chartClassName: PropTypes.string,
  lineChartData: PropTypes.array.isRequired,
  onSetChartImage: PropTypes.func,
};

export default ExportTimeSeries;
