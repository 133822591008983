import React from 'react';
import PropTypes from 'prop-types';

import Textarea from 'components/core/Textarea';
import { TextareaWithCharInfo } from 'components/core/CharInfo';

function DescriptionTextArea({ charactersLimit, ...restProps }) {
  if (!charactersLimit) {
    return (
      <Textarea {...restProps} />
    );
  }

  return (
    <TextareaWithCharInfo
      {...restProps}
      charInfo={{
        limit: charactersLimit,
      }}
    />
  );
}

DescriptionTextArea.propTypes = {
  charactersLimit: PropTypes.number,
};

export default DescriptionTextArea;
