import defaultSelectTheme from 'components/core/Select/theme';

// fix issue with non clickable select after modal
export const transactorTheme = ({
  ...defaultSelectTheme,
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
  }),
});

export const addNewStatementStyle = (isDraggingStatement, isDraggingOver, statements) => {
  const hasEmptyStatements = statements && statements.length === 0;

  return {
    ...(isDraggingStatement && { border: 'dashed 1px #c3cbf1' }),
    background: isDraggingStatement && (isDraggingOver ? '#fff7ec' : '#e8f0ff'),
    display: hasEmptyStatements || isDraggingStatement ? 'block' : 'none',
    minHeight: hasEmptyStatements ? '210px' : '90px',
  };
};

export const addNewFilterStyle = (isDraggingFilter, isDraggingOver, filters) => ({
  ...(isDraggingFilter && { border: 'dashed 1px #c3cbf1' }),
  background: isDraggingFilter && (isDraggingOver ? '#fff7ec' : '#e8f0ff'),
  display: filters && filters.length === 0 || isDraggingFilter ? 'block' : 'none',
  minHeight: '90px',
});

export const getStatementsStyle = isDraggingOver => ({
  ...(isDraggingOver && { background: '#fff7ec' }),
});

export const getStatementStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

export const getStatementFiltersStyle = isDraggingOver => ({
  ...(isDraggingOver && { background: '#fff7ec' }),
});

export const getStatementFilterStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && { outline: 'solid 1px #eff1f6' }),
});
