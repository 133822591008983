import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import TextPlaceholder from 'components/core/Placeholder/components/Text';

import styles from './styles.module.scss';

function HeaderPlaceholder({ isCheckboxPresent, colSpan, forwardRef, isFixed }) {
  /** Fix uncontrolled input error for placeholder */
  function handleChange() {}

  return (
    <thead ref={forwardRef} className={classNames(isFixed && styles.isFixed)}>
      <tr className={styles.headerRow}>
        {isCheckboxPresent && (
          <th className={styles.checkboxPlaceholder}>
            <div className={styles.checkboxWrapper}>
              <Checkbox onChange={handleChange} />
            </div>
          </th>
        )}

        <th className={styles.headerPlaceholder} colSpan={colSpan}>
          <TextPlaceholder className={styles.headerTextPlaceholder} />
        </th>
      </tr>
    </thead>
  );
}

HeaderPlaceholder.propTypes = {
  isCheckboxPresent: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool.isRequired,
  colSpan: PropTypes.number.isRequired,
  forwardRef: PropTypes.object.isRequired,
};

export default HeaderPlaceholder;
