import isArray from 'lodash/isArray';

export const wrapperClassName = 'Select';
export const getNoOptionsText = () => 'Type to search';

export const isArrayWithEmptyValues = value => isArray(value) && value.length === 0;
export const isEmptyValue = value => !isArray(value) && !value;

export const findStringOrNumberValues = (value, options) =>
  value && options && (typeof value === 'string' || typeof value === 'number') && options.filter((option => option.value === value));

export const findObjectValues = (value, options) =>
  value && options && typeof value === 'object' && options.filter((option => option.value === value.value));

export const getAutosuggesterOptionValue = ({ value, type }) => `${type}${value}`;
