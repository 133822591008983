import React from 'react';
import PropTypes from 'prop-types';

import { pluralize } from 'utils/string';
import { btnPropsShape } from 'components/core/PageLookup/utils/shapes';
import { ConfirmationModal } from 'components/core/Modal';
import useModal from 'components/core/Modal/useModal';
import Button from 'components/core/Button';

function ButtonShareToMyFeed({ onShare, isLoading, itemsSize, className, btnProps }) {
  const { isOpenModal, toggleModal } = useModal();
  const confirmationText = `Are you sure you would like to share ${itemsSize} 
    ${pluralize('item', 'items', itemsSize)} to 'My feed'?`;

  return (
    <>
      <Button
        {...btnProps}
        className={className}
        onClick={toggleModal}
        isLoading={isLoading}
      >
        Share
      </Button>

      <ConfirmationModal
        isOpen={isOpenModal}
        toggle={toggleModal}
        action={onShare}
        title="Share items"
        message={confirmationText}
        messageContinue="Confirm"
      />
    </>
  );
}

ButtonShareToMyFeed.propTypes = {
  onShare: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  itemsSize: PropTypes.number.isRequired,
  className: PropTypes.string,
  btnProps: btnPropsShape,
};

export default ButtonShareToMyFeed;
