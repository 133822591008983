import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const badgeThemes = {
  default: styles.default,
  primary: styles.primary,
  light: styles.light,
  active: styles.active,
  cyan: styles.cyan,
  red: styles.red,
  redWithBorder: styles.redWithBorder,
  darkGrey: styles.darkGrey,
  violet: styles.violet,
};

export const themes = Object.keys(badgeThemes);

function Badge({ theme, className, children, disabled }) {
  return (
    <span
      className={classNames(
        styles.badge,
        badgeThemes[theme],
        className,
        disabled && styles.isDisabled,
      )}
    >
      {children}
    </span>
  );
}

Badge.defaultProps = {
  theme: 'default',
};

Badge.propTypes = {
  theme: PropTypes.oneOf(themes),
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default Badge;

export { default as ButtonBadge } from './components/ButtonBadge';
