import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, size, isEmpty } from 'lodash';

import ListField from 'components/core/Profile/components/KeyDetailsFields/ListField';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { tableShape } from '../../utils/shapes';
import LinkColumn from './components/Column/Link';
import Default from './components/Column/Default';
import styles from './styles.module.scss';

function Table({ table, className, isLoading }) {
  const { isMobile } = useMediaQuery();
  const { label, columns, data, getLabel, placeholderSize } = table;

  function getItem(item, index) {
    const { id: itemId, label: itemLabel, content: itemContent } = item;

    function renderColumn({ id: columnId, component: Component, ...restProps }) {
      const commonProps = {
        ...restProps,
        item,
        key: columnId,
      };

      if (!Component) {
        return <Default {...commonProps} />;
      }

      return <Component {...commonProps} />;
    }

    const displayedColumns = !isEmpty(columns) && (
      <Fragment key={`content-${itemId || index}`}>
        {map(columns, renderColumn)}
      </Fragment>
    );

    const customLabel = getLabel ? getLabel(item) : itemLabel;
    const defaultLabel = `${label} ${index + 1}`;

    return {
      id: itemId,
      label: customLabel || defaultLabel,
      content: displayedColumns || itemContent,
    };
  }

  const items = map(data, getItem);

  const listFieldClassNames = classNames(styles.container, className);
  const placeholderItemsCount = placeholderSize || size(items);

  return (
    <ListField
      items={items}
      className={listFieldClassNames}
      placeholderClassName={listFieldClassNames}
      listItemClassName={classNames(styles.listItem, isMobile && styles.mobileListItem)}
      listLabelClassName={classNames(isMobile && styles.itemLabel)}
      listContentClassName={styles.itemContent}
      badgeClassName={styles.badge}
      isSimpleLabel={isMobile}
      isLoading={isLoading}
      placeholderItemsCount={placeholderItemsCount}
    />
  );
}

Table.propTypes = {
  table: tableShape.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

export { LinkColumn };

export default Table;
