import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { formatUnacceptedChars } from 'utils/formatters';

import styles from '../styles.module.scss';

function HighlighterSequential({ searchValue, content, className }) {
  if (isEmpty(content)) {
    return null;
  }

  if (!searchValue) {
    return <span className={styles.noHighlight}>{content}</span>;
  }

  const formattedWord = formatUnacceptedChars(searchValue);
  let parts = content.split(formattedWord);

  function getHighlighterClassNames(part) {
    const isHighlightWord = part && part.toLowerCase() === searchValue.toLowerCase();

    return classNames(
      isHighlightWord && className,
      {
        [styles.highlight]: isHighlightWord,
        [styles.noHighlight]: !isHighlightWord,
      },
    );
  }

  /** Remove empty strings */
  parts = parts.filter(part => part);
  const SearchWords = parts.map((part, index) => (
    <span key={index} className={getHighlighterClassNames(part)}>
      {part}
    </span>
  ));

  return <span>{SearchWords}</span>;
}

HighlighterSequential.propTypes = {
  searchValue: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
};

export default HighlighterSequential;
