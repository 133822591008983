import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';
import { frequencyType } from 'components/core/FrequencyOptions/shapes';
import { suggestionShape } from 'utils/shapes/suggestion';

export const subscriptionInfoShape = PropTypes.shape({
  frequency: frequencyType,
  isTracking: PropTypes.bool,
  is_disabled: PropTypes.bool,
  is_saved: PropTypes.bool,
  priority: PropTypes.string,
  sendWeekAheadEmail: PropTypes.bool,
  subscribable_data: PropTypes.shape({
    model_id: stringNumberType,
  }),
  subscribable_type: PropTypes.string,
  title: PropTypes.string,
  token: PropTypes.string,
});

export const subscriptionShape = PropTypes.shape({
  documentType: PropTypes.string,
  recordId: stringNumberType,
  subscription: subscriptionInfoShape,
});

export const apiShape = PropTypes.shape({
  createSubscription: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  deleteSubscriptions: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
});

export const workspacesShape = PropTypes.shape({
  values: PropTypes.arrayOf(suggestionShape).isRequired,
  onChangeValues: PropTypes.func.isRequired,
  isWorkspacesChecked: PropTypes.bool.isRequired,
  onCheckWorkspaces: PropTypes.func.isRequired,
  isMyFeedChecked: PropTypes.bool.isRequired,
  onCheckMyFeed: PropTypes.func.isRequired,
});
