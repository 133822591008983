import React from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';

import store from 'redux/store';
import * as actions from 'redux/customFields/actions';

import Container from './components/Container';

function mapStateToProps(state) {
  return { ...state.customFields };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container);

export default function CustomFields(props) {
  return (
    <Provider store={store}>
      <ConnectedContainer {...props} />
    </Provider>
  );
}
