import { useState, useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';

import useUIPaginator from 'utils/paginator/useUIPaginator.js';
import { POST_REQUEST } from 'utils/constants';
import { transformNewsLookupParams } from 'redux/news/common/utils';

import { PAGE_SIZE } from './constants';

function useContainer({ fetchNews, pagination, ui, feed, url, shouldFetch }) {
  const [keywordSearch, setKeywordSearch] = useState('');
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showCluster, setShowCluster] = useState(false);
  const [clusterStories, setClusterStories] = useState([]);
  const [hasUserSearched, setHasUserSearched] = useState(false);

  const { isLoadingFeed } = ui;
  const {
    items: clusterItems,
    isLoadingItems,
    paginator: clusterPaginator,
    onChangePaginator,
  } = useUIPaginator({ allItems: clusterStories, isLoading: isLoadingFeed, pageSize: PAGE_SIZE });

  const isEmptyFeed = isEmpty(feed);
  const isLoading = isLoadingFeed || isLoadingItems;
  const noResults = !isLoading && isEmptyFeed;
  const showPlaceholder = noResults && !hasUserSearched;
  const isEmptySearch = noResults && hasUserSearched;
  const hasPreviewButton = !isEmptyFeed;

  function fetchData(params = {}) {
    if (url) {
      const { keywordSearch: customKeywordSearch } = params;
      setSearchedKeyword(isNil(customKeywordSearch) ? keywordSearch : customKeywordSearch);

      fetchNews({
        ...params,
        perPage: PAGE_SIZE,
        url,
        method: POST_REQUEST,
        resetFeed: true,
      }, { transformParams: transformNewsLookupParams });
    }
  }

  useEffect(() => {
    shouldFetch && fetchData();
  }, [shouldFetch, url]);

  function handleSearch() {
    setHasUserSearched(true);
    fetchData({ keywordSearch });
  }

  function handleChangePaginator(page) {
    fetchData({ page });
  }

  function handleShowCluster(stories) {
    setClusterStories(stories);
    setShowCluster(true);
  }

  function handleHideCluster() {
    setShowCluster(false);
  }

  function handleShowPreview() {
    setShowPreview(!showPreview);
  }

  function handleChangeKeywordSearch(event = {}) {
    const { value } = event.target || {};

    setKeywordSearch(value);
  }

  const handleClearSearch = () => {
    setKeywordSearch('');

    searchedKeyword && fetchData({ keywordSearch: '' });
  };

  return {
    ui,
    showPreview,
    onShowPreview: handleShowPreview,
    items: showCluster ? clusterItems : feed,
    paginator: showCluster ? clusterPaginator : pagination,
    onChangePaginator: showCluster ? onChangePaginator : handleChangePaginator,
    showCluster,
    onShowCluster: handleShowCluster,
    onHideCluster: handleHideCluster,
    keywordSearch,
    onChangeKeywordSearch: handleChangeKeywordSearch,
    onSearch: handleSearch,
    onClearSearch: handleClearSearch,
    isLoading,
    showPlaceholder,
    hasPreviewButton,
    isEmptySearch,
  };
}

export default useContainer;
