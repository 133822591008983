import { StyleSheet } from '@react-pdf/renderer';

import {
  TAG_BORDER_COLOR,
  TAG_BACKGROUND_COLOR,
  WHITE_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  tag: {
    backgroundColor: TAG_BACKGROUND_COLOR,
    borderColor: TAG_BORDER_COLOR,
    borderRadius: 2,
    borderWidth: 1,
    color: WHITE_COLOR,
    fontSize: 8,
    fontWeight: 400,
    margin: '0 4 0 0',
    padding: '2 2 1 3',
  },
});

export default styles;
