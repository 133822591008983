import { useEffect } from 'react';
import isNil from 'lodash/isNil';

import useLogo from './useLogo';

function useSettings({
  options,
  settings,
  settingsManager,
  getCustomizationData,
}) {
  const { companyHasLogo, logoUrl } = useLogo(settings.showMyLogo);
  const withTitle = !isNil(settings.title);

  const onTitleChange = event => settingsManager.setTitle(event.target.value);

  const onFileFormatChange = (_, values) => settingsManager.setFileFormat(values[0]);

  const {
    showPreview,
    showDisclaimer,
    customizationItems,
    customizationDescription,
  } = getCustomizationData && getCustomizationData({
    chooseColumns: settings.chooseColumns,
    fileFormat: settings.fileFormat,
    companyHasLogo,
    options,
  }) || {};

  useEffect(() => {
    settingsManager.setLogoUrl(logoUrl);
  }, [settings.showMyLogo]);

  useEffect(() => {
    settingsManager.setCustomization(null, []);
  }, [settings.fileFormat]);

  return {
    withTitle,
    showPreview,
    showDisclaimer,
    customizationItems,
    customizationDescription,
    onFileFormatChange,
    onTitleChange,
  };
}

export default useSettings;
