import React from 'react';

import { stafferShape } from 'components/core/Profile/components/Cards/Staffers/utils/shapes';

import { OFFICE_LEADERSHIP_LABEL, OFFICE_MEMBER_LABEL } from '../../utils/constants';

function OfficeColumn({ item }) {
  const { isLeadership } = item || {};

  return (
    <td>
      { isLeadership ? OFFICE_LEADERSHIP_LABEL : OFFICE_MEMBER_LABEL }
    </td>
  );
}

OfficeColumn.propTypes = {
  item: stafferShape,
};

export default OfficeColumn;
