import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

function useSearch({
  isDesktop,
  filterValues,
  onChangeSearchParam,
  onChangeSearchValue,
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const { searchValue: appliedSearchValue } = filterValues || {};
  const [currentSearchValue, setCurrentSearchValue] = useState(appliedSearchValue);

  const handleShowFilters = () => setShowFilters(!showFilters);

  const handleShowSearch = () => setShowSearch(!showSearch);

  const handleSearchChange = ({ target: { value } = {} }) => setCurrentSearchValue(value);

  const handleSearchSubmit = () => {
    onChangeSearchValue(currentSearchValue);
    !isDesktop && setShowSearch(!showSearch);

    if (onChangeSearchParam) {
      isEmpty(currentSearchValue) ? onChangeSearchParam() : onChangeSearchParam(currentSearchValue);
    }
  };

  useEffect(() => {
    (appliedSearchValue !== currentSearchValue) && setCurrentSearchValue(appliedSearchValue);
  }, [appliedSearchValue]);

  return {
    showSearch,
    showFilters,
    handleShowSearch,
    handleShowFilters,
    handleSearchSubmit,
    search: currentSearchValue,
    handleSearchChange,
  };
}

export default useSearch;
