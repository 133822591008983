import { useEffect, useState } from 'react';
import { isEqual, union, isEmpty, pullAll, find, intersection, filter } from 'lodash';

function getSubItemsIds(checkedItem) {
  const { subItems = [] } = checkedItem || {};

  if (isEmpty(subItems)) {
    return [];
  }

  return subItems.map(subItem => subItem.id);
}

function useCheckedItems(id, value, onChange, allValues = [], exportTabItems) {
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll] = exportTabItems ? exportTabItems.filter(item => item.isSelectAll) : [];

  useEffect(() => {
    const filteredValues = selectAll ? value.filter(val => val !== selectAll.id) : value;
    !isEqual(filteredValues, checkedItems) && setCheckedItems(filteredValues);
  }, [value]);

  function handleCheck({ isChecked, currentValue, parentValue }) {
    const subItems = getSubItemsIds(find(exportTabItems, { id: currentValue }));

    const items = isChecked
      ? union([...checkedItems], [currentValue, ...subItems])
      : pullAll([...checkedItems], [currentValue, parentValue, ...subItems]);

    setCheckedItems(items);
    onChange(id, items);
  }

  function handleOnCheck(event) {
    handleCheck({
      isChecked: event.target.checked,
      currentValue: event.target.value,
    });
  }

  function handleOnCheckAll(event) {
    const disabledItems = !isEmpty(exportTabItems)
      ? exportTabItems
        .filter(item => item.isDisabled)
        .map(item => item.id)
      : [];

    const allValuesFiltered = selectAll
      ? allValues.filter(item => item !== selectAll.id)
      : allValues;

    const defaultValues = !isEmpty(allValuesFiltered)
      ? allValuesFiltered.filter(item => disabledItems.includes(item))
      : [];
    const items = event.target.checked ? allValuesFiltered : defaultValues;

    setCheckedItems(items);
    onChange(id, items);
  }

  function handleCheckSubItem(event, item) {
    const isChecked = event.target.checked;
    const currentValue = event.target.value;
    const commonProps = { isChecked, currentValue };

    if (isChecked) {
      return handleCheck(commonProps);
    }

    const { id: parentId } = item || {};
    const subItems = getSubItemsIds(item);
    const checkItems = filter(checkedItems, subItem => subItem !== currentValue);
    const isParentUnchecked = intersection(subItems, checkItems).length === 0;

    return handleCheck({
      ...(isParentUnchecked && { parentValue: parentId }),
      ...commonProps,
    });
  }

  return {
    checkedItems,
    onCheck: handleOnCheck,
    onCheckAll: handleOnCheckAll,
    onCheckSubItem: handleCheckSubItem,
  };
}

export default useCheckedItems;
