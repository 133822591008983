import PropTypes from 'prop-types';

const sortDataShape = PropTypes.shape({
  direction: PropTypes.string,
  column: PropTypes.string,
});

export const sortShape = PropTypes.shape({
  sortData: sortDataShape.isRequired,
  resetSortData: PropTypes.func.isRequired,
  getDirection: PropTypes.func.isRequired,
});
