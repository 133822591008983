import { StyleSheet } from '@react-pdf/renderer';

import {
  PROGRESS_BAR_ACTIVE_COLOR,
  LABEL_DOT_BORDER_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const defaultTheme = StyleSheet.create({
  progressBarActive: {
    backgroundColor: PROGRESS_BAR_ACTIVE_COLOR,
  },
  labelDotActive: {
    borderColor: LABEL_DOT_BORDER_COLOR,
    backgroundColor: PROGRESS_BAR_ACTIVE_COLOR,
  },
});

export { defaultTheme };
