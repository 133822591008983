import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import styles from '../styles.module.scss';

const fuzzyHighlight = (value = '', content = '', className, key = 0) => {
  const [v, ...restValue] = value;
  const [c, ...restContent] = content;

  const highlight = children => (
    <span key={key} className={classNames(styles.highlight, className)}>
      {children}
    </span>
  );

  const noHighlight = children => (
    <span key={key} className={styles.noHighlight}>
      {children}
    </span>
  );

  // Nothing to search
  if (isEmpty(value)) {
    return [
      noHighlight(content),
    ];
  }

  // Nothing to search on
  if (isEmpty(content)) {
    return [];
  }

  if (v.toLowerCase() === c.toLowerCase()) {
    return [
      highlight(c),
      ...fuzzyHighlight(restValue, restContent, className, key + 1),
    ];
  }

  return [
    noHighlight(c),
    ...fuzzyHighlight(value, restContent, className, key + 1),
  ];
};

function HighlighterFuzzy({ searchValue, content, className }) {
  return (
    <span>
      {fuzzyHighlight(searchValue, content, className)}
    </span>
  );
}

HighlighterFuzzy.propTypes = {
  searchValue: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
};

export default HighlighterFuzzy;
