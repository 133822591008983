import cloneDeep from 'lodash/cloneDeep';

import { findNode, reorder } from './common';

export default function handleDroppedFilterReorder({ parentId, result, data, updateData }) {
  const oldFiltersGroup = findNode(data, parentId);
  const newFiltersGroup = cloneDeep(oldFiltersGroup);

  newFiltersGroup.filters = [
    ...reorder(
      newFiltersGroup.filters,
      result.source.index,
      result.destination.index,
    ),
  ];

  updateData({
    payload: JSON.parse(
      JSON.stringify(data).replace(
        JSON.stringify(oldFiltersGroup),
        JSON.stringify(newFiltersGroup),
      ),
    ),
  });
}
