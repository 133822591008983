import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../styles/styles.module.scss';
import defaultTheme from '../styles/defaultTheme.module.scss';
import contentTheme from '../styles/contentTheme.module.scss';
import invalidStyles from '../styles/invalid.module.scss';

export const THEMES = {
  default: defaultTheme,
  justContent: contentTheme,
};

export const classNamesShape = {
  mentions: PropTypes.string,
  mentionsSuggestions: PropTypes.string,
  suggestion: PropTypes.string,
  suggestionFocused: PropTypes.string,
  mentionsInput: PropTypes.string,
  mentionsHighlighter: PropTypes.string,
  mentionsControl: PropTypes.string,
};

export const buildClassNames = (fieldClassNames, theme, isError) => ({
  mentions: classNames(
    styles.mentions,
    THEMES[theme].mentions,
    fieldClassNames.mentions,
    isError && invalidStyles.mentions,
  ),
  mentions__suggestions: classNames(
    styles.mentionsSuggestions,
    THEMES[theme].mentionsSuggestions,
    fieldClassNames.mentionsSuggestions,
    isError && invalidStyles.mentionsSuggestions,
  ),
  mentions__suggestions__list: classNames(
    styles.mentionsSuggestionsList,
    THEMES[theme].mentionsSuggestionsList,
    fieldClassNames.mentionsSuggestionsList,
    isError && invalidStyles.mentionsSuggestionsList,
  ),
  suggestion: classNames(
    styles.suggestion,
    THEMES[theme].suggestion,
    fieldClassNames.suggestion,
    isError && invalidStyles.suggestion,
  ),
  suggestionFocused: classNames(
    styles.suggestionFocused,
    THEMES[theme].suggestionFocused,
    fieldClassNames.suggestionFocused,
    isError && invalidStyles.suggestionFocused,
  ),
  mentions__input: classNames(
    styles.mentionsInput,
    THEMES[theme].mentionsInput,
    fieldClassNames.mentionsInput,
    isError && invalidStyles.mentionsInput,
  ),
  mentions__highlighter: classNames(
    styles.mentionsHighlighter,
    THEMES[theme].mentionsHighlighter,
    fieldClassNames.mentionsHighlighter,
    isError && invalidStyles.mentionsHighlighter,
  ),
  mentions__control: classNames(
    styles.mentionsControl,
    THEMES[theme].mentionsControl,
    fieldClassNames.mentionsControl,
    isError && invalidStyles.mentionsControl,
  ),
});
