import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';

import styles from '../styles.module.scss';

export default function SearchInput({ query, onChange }) {
  return (
    <div className={styles.searchInputContainer}>
      <Input
        autoFocus
        value={query}
        onChange={onChange}
        placeholder="Filter fields…"
        className={classNames(styles.fullWidth, styles.inputText)}
      />
    </div>
  );
}

SearchInput.propTypes = {
  query: PropTypes.string,
  onChange: PropTypes.func,
};
