import GlobalNotifier from 'components/GlobalNotifier';
import { isEmpty, includes } from 'lodash';

import { InvalidFormatError } from './exceptions';

const { addNotification } = GlobalNotifier;

const notifyError = (message) => {
  addNotification({ message, level: 'error' });
};

export function handleValidationError(error) {
  const { message } = error;

  if (error instanceof InvalidFormatError) {
    notifyError(message);
  } else {
    throw error;
  }
}

export function validateFileFormat({ file, supportedFormats }) {
  const { type } = file || {};

  const isFileFormatSupported = includes(supportedFormats, type);
  const shouldValidateFormat = !isEmpty(supportedFormats);

  if (shouldValidateFormat && !isFileFormatSupported) {
    throw new InvalidFormatError();
  }
}
