import { useState } from 'react';

function useCloseConfirmModal(initialState = false, toggleMainModal, handleResetModal) {
  const [isOpen, setIsOpen] = useState(initialState);

  function handleToggleModal() {
    setIsOpen(!isOpen);
  }

  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
    handleResetModal();
  }

  function handleReturn() {
    toggleMainModal();
    handleToggleModal();
  }

  return {
    isCloseConfirmOpen: isOpen,
    toggleCloseConfirm: handleToggleModal,
    exitCloseConfirm: handleCloseModal,
    openCloseConfirm: handleOpenModal,
    returnCloseConfirm: handleReturn,
  };
}

export default useCloseConfirmModal;
