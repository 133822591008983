import { useState } from 'react';
import isFunction from 'lodash/isFunction';

import { getColorLimits, getStateCounters, getTooltipContent } from './utils';

function useSimpleStatesMap({
  data,
  isEqualInterval,
  customColorLimits,
  customStateCounter,
}) {
  const [tooltipContent, setTooltipContent] = useState(getTooltipContent());

  const stateCounts = customStateCounter && isFunction(customStateCounter) ?
    customStateCounter(data) :
    getStateCounters(data);

  const colorLimits = customColorLimits && isFunction(customColorLimits) ?
    customColorLimits(data, stateCounts) :
    getColorLimits(data, stateCounts, isEqualInterval);

  const { limits: legendLimits } = colorLimits;

  function handleStateHover(...args) {
    return () => setTooltipContent(getTooltipContent(...args));
  }

  return {
    tooltipContent,
    legendLimits,
    colorLimits,
    onStateHover: handleStateHover,
  };
}

export default useSimpleStatesMap;
