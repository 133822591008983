import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_VALUE } from 'utils/constants';
import { Column } from 'components/core/Table';

function EmptyItem({ keyName }) {
  return (
    <Column key={keyName} keyName={keyName}>{() => EMPTY_VALUE}</Column>
  );
}

EmptyItem.propTypes = {
  keyName: PropTypes.string.isRequired,
};

export default EmptyItem;
