import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { TabsPlaceholder } from 'components/core/Placeholder';
import { ScrollingProvider, Section, SectionLink } from 'components/core/ScrollSection';
import StickyContainer from 'components/core/StickyContainer';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import MobileTabs from './components/MobileTabs';
import useTabs from './useTabs';
import styles from './styles.module.scss';

function FixedTabs({
  isLoading,
  items,
  activeId,
  className,
  itemsNumberPlaceholder,
  isGreyBg,
  history,
  isFullWidth,
}) {
  const { isDesktopLarge } = useMediaQuery();
  const { activeTab, setActiveTab } = useTabs({ items, activeId, isDesktopLarge });

  if (isEmpty(items)) {
    return null;
  }

  if (!isDesktopLarge) {
    return (
      <MobileTabs
        items={items}
        className={className}
        activeTab={activeTab}
        isLoading={isLoading}
        setActiveTab={setActiveTab}
        itemsNumberPlaceholder={itemsNumberPlaceholder}
      />
    );
  }

  function renderTitle(tab = {}) {
    if (isEmpty(tab) || tab.isHidden) {
      return null;
    }

    const { id, title, description, onClick: onTabClick, isVisible = true } = tab;

    if (!isVisible) {
      return null;
    }

    return (
      <SectionLink key={id} section={id} onClick={onTabClick}>
        {({ isSelected, onClick, isDisabled }) => (
          <button
            type="button"
            className={classNames(styles.itemButton, isSelected && styles.activeButton)}
            onClick={onClick}
            disabled={isDisabled}
          >
            <div className={styles.itemTitle}>{title}</div>
            <div className={styles.itemDescription}>{description}</div>
          </button>
        )}
      </SectionLink>
    );
  }

  function renderContent(tab = {}) {
    if (isEmpty(tab) || tab.isHidden) {
      return null;
    }

    const { id, component, isVisible = true } = tab;
    const { type, props } = component;
    const Component = type;

    if (!isVisible) {
      return null;
    }

    const sectionClassName = classNames(!isFullWidth && styles.sectionMaxWidth);

    return (
      <Section id={id} key={id} className={sectionClassName}>
        <Component {...props} history={history} />
      </Section>
    );
  }

  return (
    <ScrollingProvider activeId={activeId}>
      <div className={classNames(styles.fixedContainer, className)}>
        <div className={styles.fakeBanner} />
        <StickyContainer className={styles.stickyContainer} contentClassName={styles.stickyContent}>
          <div id="menuFixedSection" className={styles.titles}>
            {isLoading
              ? (
                <TabsPlaceholder
                  itemsNumber={itemsNumberPlaceholder}
                  isDesktop={isDesktopLarge}
                />
              )
              : items.map(renderTitle)}
          </div>
        </StickyContainer>

        <div className={classNames(styles.tabsContent, isGreyBg && styles.greyLayoutBg)}>
          {items.map(renderContent)}
        </div>
      </div>
    </ScrollingProvider>
  );
}

FixedTabs.defaultProps = {
  isGreyBg: true,
  isFullWidth: false,
};

FixedTabs.propTypes = {
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  activeId: PropTypes.string,
  className: PropTypes.string,
  itemsNumberPlaceholder: PropTypes.number,
  isGreyBg: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

export default FixedTabs;
