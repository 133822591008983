import React from 'react';

import CheatSheet from 'components/core/CheatSheet';

export const PAGE_SIZE = 10;

export const defaultDisclaimerProps = {
  withButtons: false,
  title: 'Did you know?',
  content: <CheatSheet />,
  modalTheme: 'scrollable',
};

export const EMAIL_TYPE = 'email';
export const PHONE_TYPE = 'phone';
export const FAX_TYPE = 'fax';
