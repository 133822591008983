const tableColumns = [
  {
    label: 'Contributor name',
    keyName: 'name',
    allowToggle: false,
  },
  {
    label: 'Amount',
    keyName: 'amount',
    sortingKey: 'contribution_amount',
    allowToggle: true,
  },
  {
    label: 'Recipient name',
    keyName: 'recipient',
    allowToggle: false,
  },
  {
    label: 'Receipt date',
    keyName: 'contribution_date',
    sortingKey: 'contribution_date',
    allowToggle: true,
  },
  {
    label: 'Source type',
    keyName: 'source_type_full',
    allowToggle: false,
  },
  {
    label: 'Election type',
    keyName: 'election_type_full',
    allowToggle: false,
  },
  {
    label: 'Recipient party',
    keyName: 'recipientParty',
    allowToggle: false,
  },
  {
    label: 'Conduit',
    keyName: 'conduitName',
    allowToggle: false,
  },
  {
    label: 'Filing',
    keyName: 'receipts',
    allowToggle: false,
  },
];

export default tableColumns;
