import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, concat } from 'lodash';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getEmploymentContent } from './utils';

function EmploymentHistory({ person, isLoading, hideOnEmptyData }) {
  const { employmentHistory = [], headPositions = [] } = person || {};
  const data = concat(employmentHistory, headPositions);

  const sections = [{
    id: 'employmentHistory',
    title: 'Employment history',
    tables: [{
      id: 'organizations',
      label: 'Organization',
      placeholderSize: 2,
      columns: [{
        id: 'organization',
        getContent: getEmploymentContent,
      }],
      data,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(data)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

EmploymentHistory.defaultProps = {
  hideOnEmptyData: true,
};

EmploymentHistory.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(EmploymentHistory);
