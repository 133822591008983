import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import DataCard from 'components/core/Profile/components/DataCard';
import { BaseOption } from 'components/core/AdvancedSearch';
import DateRange from 'components/core/DateRange';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import SearchBox from 'components/core/SearchBox';
import Button from 'components/core/Button';
import { formatToMoment } from 'components/core/Profile/utils';
import TagInput from 'components/core/TagInput';
import { sortShape } from 'components/core/Sort/shapes';
import { paginatorShape } from 'components/core/Paginator/shapes';
import { tableColumnShape } from 'components/core/Profile/components/Cards/utils/shapes';
import {
  trackPublicRecordResultsFiltered,
  trackPublicRecordResultsDateRangeFiltered,
  trackPublicRecordColumnSorted,
  trackPublicRecordPageVisited,
  trackPublicRecordSavedToTopic,
} from 'utils/tracking';
import { stringNumberType } from 'utils/shapes';

import getSnippetTableColumns from '../../utils/snippetTableColumns';
import rangeTitles from '../../utils/recordTitles';
import { PublicRecordsContext } from '../../utils/withPublicRecords';
import usePublicRecords from '../../utils/usePublicRecords';
import { publicRecordShape } from '../../utils/shapes';
import TableView from '../TableView';
import RecordTypesFilter from './components/RecordTypesFilter';
import styles from './styles.module.scss';

function PublicRecord({
  id,
  isMobile,
  slug,
  fetchPublicRecords,
  recordTypes,
  ui,
  paginator,
  records,
  keywordSearchTitles,
  rangeDateTitles,
  recordTypeTitles,
  tagsTitles,
  trackingContext,
  customTableColumns,
  customTransformers,
  title,
  description,
  questionText,
  emptyDescription,
  emptyDescriptionFiltering,
  displayButtonRecordTypes,
  customColumnComponents,
  sort,
  defaultSort,
  customCheckboxRecordTypes,
  trackingFunctions,
  searchProps,
  updateDataItem,
  defaultSubscription,
  withSnippet,
  shouldFetch,
  ...restProps
}) {
  const checkboxRecordTypes = !isEmpty(customCheckboxRecordTypes)
    ? customCheckboxRecordTypes
    : recordTypes;

  const {
    tags,
    keywordSearch,
    searchedKeyword,
    rangeDate,
    selectedTypes,
    toResetTable,
    onResetTable,
    allSearchData,
    areFiltersApplied,
    onStartDateChange,
    onEndDateChange,
    onChangeTags,
    onChangeKeywordSearch,
    onCheckType,
    onCheckAllTypes,
    onSearch,
    onSubmit,
    onClearAll,
    onClearSearch,
  } = usePublicRecords({
    ...trackingFunctions,
    ...restProps,
    id,
    slug,
    checkboxRecordTypes,
    fetchPublicRecords,
    trackingContext,
    customTransformers,
    defaultSort,
    shouldFetch,
  });

  const tableColumns = withSnippet ? getSnippetTableColumns(searchedKeyword) : customTableColumns;

  const baseOptionClassName = classNames(
    styles.baseOption,
    styles.flexNone,
  );

  const searchComponent = (
    <SearchBox
      withButton
      className={styles.inputSearch}
      inputClassName="test__public-record-search"
      value={keywordSearch}
      onChange={onChangeKeywordSearch}
      onSubmit={onSubmit}
      onClearSearch={onClearSearch}
      isClearable
      {...searchProps}
    />
  );

  const rangeDataComponent = (
    <DateRange
      placeholder={GLOBAL_DATE_FORMAT.toLowerCase()}
      className={styles.rangeDates}
      startDateClassName={styles.date}
      endDateClassName={styles.date}
      startDate={formatToMoment(rangeDate.startDate)}
      endDate={formatToMoment(rangeDate.endDate)}
      handleChangeStart={onStartDateChange}
      handleChangeEnd={onEndDateChange}
      showMonthDropdown
      showYearDropdown
    />
  );

  const tagsComponent = (
    <TagInput
      autoload
      name="tags"
      strategy="tag"
      value={tags}
      overrideValue={tags}
      onChange={onChangeTags}
      withAjaxAbort={false}
      isMobile={isMobile}
      shouldOverrideValue
    />
  );

  const context = {
    ...ui,
    trackingFunctions,
    records,
    paginator,
    isMobile,
    toResetTable,
    onResetTable,
    allSearchData,
    trackingContext,
    fetchPublicRecords,
    recordTypes,
    selectedTypes,
    onCheckType,
    onCheckAllTypes,
    checkboxRecordTypes,
  };

  return (
    <PublicRecordsContext.Provider value={context}>
      <DataCard
        title={title}
        description={description}
        questionText={questionText}
      >
        <div className={styles.content}>
          <div className={styles.keywordForm}>
            <div className={styles.searchContainer}>
              <BaseOption
                {...keywordSearchTitles}
                className={classNames(baseOptionClassName, styles.keyword)}
                customComponent={searchComponent}
              />
              {areFiltersApplied && (
                <Button
                  theme="link"
                  onClick={onClearAll}
                  className={styles.clearAll}
                >
                  Clear all filters
                </Button>
              )}
            </div>
            <BaseOption
              {...tagsTitles}
              className={classNames(styles.baseOption, styles.tags)}
              customComponent={tagsComponent}
            />
            <BaseOption
              {...rangeDateTitles}
              className={baseOptionClassName}
              customComponent={rangeDataComponent}
            />
            <RecordTypesFilter
              recordTypes={recordTypes}
              recordTypeTitles={recordTypeTitles}
              baseOptionClassName={baseOptionClassName}
              displayButtonRecordTypes={displayButtonRecordTypes}
            />
          </div>

          <TableView
            recordTypes={recordTypes}
            toResetTable={toResetTable}
            allSearchData={allSearchData}
            onResetTable={onResetTable}
            updateDataItem={updateDataItem}
            defaultSubscription={defaultSubscription}
            customTableColumns={tableColumns}
            customColumnComponents={customColumnComponents}
            customTransformers={customTransformers}
            emptyDescription={emptyDescription}
            emptyDescriptionFiltering={emptyDescriptionFiltering}
            areFiltersApplied={areFiltersApplied}
            onClearAll={onClearAll}
            sort={sort}
            onSearch={onSearch}
            defaultSort={defaultSort}
          />
        </div>
      </DataCard>
    </PublicRecordsContext.Provider>
  );
}

PublicRecord.propTypes = {
  id: stringNumberType,
  isMobile: PropTypes.bool.isRequired,
  slug: PropTypes.string,
  recordTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    labelPlural: PropTypes.string,
    saveToTopicSlug: PropTypes.string,
  })).isRequired,
  fetchPublicRecords: PropTypes.func.isRequired,
  trackingContext: PropTypes.string.isRequired,
  ui: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isLoadingTags: PropTypes.bool.isRequired,
    isLoadingSubscriptions: PropTypes.bool.isRequired,
    isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
  }).isRequired,
  paginator: paginatorShape.isRequired,
  records: PropTypes.arrayOf(publicRecordShape).isRequired,
  keywordSearchTitles: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  rangeDateTitles: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  recordTypeTitles: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  tagsTitles: PropTypes.shape({
    title: PropTypes.string,
  }),
  customTransformers: PropTypes.shape({
    transformRecords: PropTypes.func,
    transformRecord: PropTypes.func,
  }),
  customTableColumns: PropTypes.arrayOf(tableColumnShape),
  title: PropTypes.string,
  description: PropTypes.string,
  questionText: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyDescriptionFiltering: PropTypes.string,
  displayButtonRecordTypes: PropTypes.bool,
  withSnippet: PropTypes.bool,
  customColumnComponents: PropTypes.objectOf(PropTypes.node),
  sort: sortShape,
  defaultSort: sortShape,
  customCheckboxRecordTypes: PropTypes.arrayOf(PropTypes.shape({
    id: stringNumberType.isRequired,
  })),
  trackingFunctions: PropTypes.shape({
    trackResultsFiltered: PropTypes.func,
    trackResultsDateRangeFiltered: PropTypes.func,
    trackColumnSorted: PropTypes.func,
    trackPageVisited: PropTypes.func,
    trackSavedToTopic: PropTypes.func,
  }),
  updateDataItem: PropTypes.func,
  defaultSubscription: PropTypes.string,
  searchProps: PropTypes.shape({
    withSearchDisclaimer: PropTypes.bool,
    disclaimerProps: PropTypes.shape({
      withButtons: PropTypes.bool,
      title: PropTypes.string,
      content: PropTypes.node,
      modalTheme: PropTypes.string,
    }),
  }),
  shouldFetch: PropTypes.bool,
};

PublicRecord.defaultProps = {
  keywordSearchTitles: rangeTitles.keywordSearch,
  rangeDateTitles: rangeTitles.rangeDate,
  recordTypeTitles: rangeTitles.recordType,
  tagsTitles: rangeTitles.tags,
  customTransformers: {},
  searchProps: {},
  title: 'Public record',
  description: '',
  emptyDescription: '',
  emptyDescriptionFiltering: '',
  displayButtonRecordTypes: false,
  withSnippet: false,
  defaultSort: {},
  customCheckboxRecordTypes: [],
  trackingFunctions: {
    trackResultsFiltered: trackPublicRecordResultsFiltered,
    trackResultsDateRangeFiltered: trackPublicRecordResultsDateRangeFiltered,
    trackColumnSorted: trackPublicRecordColumnSorted,
    trackPageVisited: trackPublicRecordPageVisited,
    trackSavedToTopic: trackPublicRecordSavedToTopic,
  },
  shouldFetch: true,
};

export default PublicRecord;
