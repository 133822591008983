import React from 'react';
import PropTypes from 'prop-types';

import DataCard from 'components/core/Profile/components/DataCard';

import ColumnsContainer from '../ColumnsContainer';
import LegislatorsSection from './components/LegislatorsSection';

function Legislators({ members, title, description, formatLegislatorUrl, showOthers }) {
  const { republicans, democrats, others } = members;

  return (
    <DataCard title={title} description={description}>
      <ColumnsContainer>
        <LegislatorsSection
          title="Republican party members"
          legislators={republicans}
          formatLegislatorUrl={formatLegislatorUrl}
        />
        <LegislatorsSection
          title="Democratic party members"
          legislators={democrats}
          formatLegislatorUrl={formatLegislatorUrl}
        />
        {showOthers && (
          <LegislatorsSection
            title="Other party members"
            legislators={others}
            formatLegislatorUrl={formatLegislatorUrl}
          />
        )}
      </ColumnsContainer>
    </DataCard>
  );
}


Legislators.defaultProps = {
  title: 'Legislators',
  description: '',
  showOthers: true,
};

Legislators.propTypes = {
  members: PropTypes.shape({
    republicans: PropTypes.array.isRequired,
    democrats: PropTypes.array.isRequired,
    others: PropTypes.array.isRequired,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  formatLegislatorUrl: PropTypes.func.isRequired,
  showOthers: PropTypes.bool,
};

export default Legislators;
