import React from 'react';

// eslint-disable-next-line import/no-cycle
import Card from 'components/core/Card';

import TextPlaceholder from '../../../Text';
import styles from './styles.module.scss';

function CardTablePlaceholder() {
  return (
    <div className={styles.cardGroup}>
      <div className={styles.checkbox} />

      <Card className={styles.card}>
        <div className={styles.content}>
          <div className={styles.image} />
          <div className={styles.text}>
            <TextPlaceholder className={styles.title} />
            <TextPlaceholder className={styles.author} />

            <div className={styles.mentions}>
              <TextPlaceholder className={styles.mention} />
              <TextPlaceholder className={styles.mention} />
            </div>
          </div>

          <div className={styles.rightContent}>
            <TextPlaceholder className={styles.sentiment} />
          </div>
        </div>

        <div className={styles.footer}>
          <TextPlaceholder className={styles.share} />
        </div>
      </Card>
    </div>
  );
}

export default CardTablePlaceholder;
