import React, { useState } from 'react';

import DatePicker from 'components/core/DatePicker';

import styles from '../styles.module.scss';

function PreviewDateInput() {
  const [selectedDate, setSelectedDate] = useState(undefined);
  const handleChange = date => setSelectedDate(date);

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleChange}
      iconPosition="right"
      className={styles.datePicker}
    />
  );
}

export default PreviewDateInput;
