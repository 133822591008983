import React from 'react';

import { CardNewsStoryPlaceholder } from 'components/core/Placeholder';
import styles from './styles.module.scss';

function Placeholder() {
  function renderPlaceholderCards() {
    return Array(3)
      .fill(0)
      .map((_, index) => <CardNewsStoryPlaceholder key={`card-${index}`} />);
  }

  return (
    <section className={styles.container}>
      <p className={styles.emptyMessage}>
        Searching through millions of news articles around the world
        <span className={styles.loader} />
      </p>

      {renderPlaceholderCards()}
    </section>
  );
}

export default Placeholder;
