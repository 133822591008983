import React from 'react';
import PropTypes from 'prop-types';

import AppliedFiltersLabel from 'components/core/AppliedFiltersLabel';
import { appliedFiltersShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { pluralize } from 'utils/string';

import styles from './styles.module.scss';

function SearchInfo({
  onClearAll,
  dataLength,
  appliedFilters,
  appliedFiltersLength,
  isLoading,
}) {
  const { searchValue } = appliedFilters || {};
  const resultsWord = pluralize('result', 'results', dataLength);

  return (
    <AppliedFiltersLabel
      containerClass={styles.container}
      isLoading={isLoading}
      results={dataLength}
      resultsWord={resultsWord}
      onClearFilters={onClearAll}
      filtersCount={appliedFiltersLength}
      searchValue={searchValue}
    />
  );
}

SearchInfo.defaultProps = {
  dataLength: 0,
};

SearchInfo.propTypes = {
  dataLength: PropTypes.number,
  onClearAll: PropTypes.func.isRequired,
  appliedFilters: appliedFiltersShape,
  appliedFiltersLength: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

export default SearchInfo;
