import { useState, useEffect } from 'react';
import { isEqual, get, includes, size, xorWith, reduce } from 'lodash';

function useExportColumns({ itemsSelected }) {
  const [selectedColumns, setSelectedColumns] = useState({});

  const resetSelectedColumns = () => {
    const allColumnsSelected = reduce(itemsSelected, (items, item) => ({
      [item.value]: item.columns,
      ...items,
    }), {});

    setSelectedColumns(allColumnsSelected);
  };

  useEffect(() => {
    resetSelectedColumns();
  }, [size(itemsSelected)]);

  const isChecked = (item, column) => {
    const columns = get(selectedColumns, item.value, []);

    return includes(columns, column);
  };

  const isCheckedAll = (item) => {
    const columns = get(selectedColumns, item.value, []);

    return size(columns) === size(item.columns);
  };

  const onCheck = (item, column) => {
    const oldColumns = get(selectedColumns, item.value, []);
    const newColumns = xorWith(oldColumns, [column], isEqual);

    setSelectedColumns({
      ...selectedColumns,
      [item.value]: newColumns,
    });
  };

  const onCheckAll = (item) => {
    const newColumns = isCheckedAll(item)
      ? []
      : item.columns;

    setSelectedColumns({
      ...selectedColumns,
      [item.value]: newColumns,
    });
  };

  return {
    selectedColumns,
    columnsManager: {
      onCheck,
      onCheckAll,
      isChecked,
      isCheckedAll,
    },
  };
}

export default useExportColumns;
