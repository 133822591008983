export const handleDragOver = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export const handleDrop = handleChange => (event) => {
  handleChange(event.dataTransfer.files);
  handleDragOver(event);
};

export const handleUploadClick = (handleChange, setProgress, setFailures) => () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;
  input.style.display = 'none';
  document.body.appendChild(input);
  input.onchange = event => handleChange(event.target.files, setProgress, setFailures);
  input.click();
};
