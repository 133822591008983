import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import styles from '../styles.module.scss';

function FooterActions({
  currentStepIndex,
  steps,
  isLastStep,
  onSubmit,
  onStepClick,
  onNextStep,
  onPrevStep,
  onCloseModal,
  CustomButtons,
  isLoading,
  isSaving,
}) {
  if (CustomButtons) {
    return (
      <CustomButtons
        steps={steps}
        currentStepIndex={currentStepIndex}
        isLoading={isLoading || isSaving}
        isLastStep={isLastStep}
        onSubmit={onSubmit}
        onStepClick={onStepClick}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
        onCloseModal={onCloseModal}
      />
    );
  }

  function getTooltipNode(title) {
    return <span>{title}</span>;
  }

  const stepNavigation = (
    <nav className={styles.stepNavigation}>
      {steps.map(({ index, title }) => (
        <Tooltip
          key={`step-nav-${index}`}
          content={getTooltipNode(title)}
          placement="top"
          zIndex="10001"
          className={classNames(
            'step-button',
            styles.tooltipWrapper,
            styles.tooltipContainer,
          )}
        >
          <span>
            <Button
              size="small"
              theme="light"
              type="button"
              className={classNames(
                styles.stepNavigationBtn,
                currentStepIndex === index && styles.isActive
              )}
              onClick={onStepClick(index)}
            />
          </span>
        </Tooltip>
      ))}
    </nav>
  );

  return (
    <Fragment>
      {stepNavigation}

      <div className={styles.buttons}>
        <Button
          size="small"
          theme="light"
          disabled={isLoading}
          onClick={onCloseModal}
          className={classNames(styles.modalActionBtn, styles.cancelBtn)}
        >
          Cancel
        </Button>

        {!isLastStep && (
          <Fragment>
            <Button
              theme="tertiary"
              size="small"
              disabled={isLoading}
              onClick={onSubmit}
              className={styles.modalActionBtn}
            >
              {isLoading
                ? <Loader size="small" theme="light" className={styles.loader} />
                : <Fragment>Save & finish</Fragment>
              }
            </Button>
            <Button
              size="small"
              disabled={isLoading}
              onClick={onNextStep}
              className={styles.modalActionBtn}
            >
              Next
            </Button>
          </Fragment>
        )}

        {isLastStep && (
          <Button
            size="small"
            disabled={isLoading}
            onClick={onSubmit}
            className={styles.modalActionBtn}
          >
            {isLoading
              ? <Loader size="small" theme="light" className={styles.loader} />
              : <span>Save</span>
            }
          </Button>
        )}
      </div>
    </Fragment>
  );
}

FooterActions.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func,
  onStepClick: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  CustomButtons: PropTypes.elementType,
};

export default FooterActions;
