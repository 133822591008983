import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, uniq } from 'lodash';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';
import { stafferShape } from 'components/core/Profile/components/Cards/Staffers/utils/shapes';

function TitlesColumn({ keyName, item }) {
  const { positions } = item || {};
  const titles = uniq(map(positions, 'title'));

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={titles}
      isEmptyData={isEmpty(titles)}
    />
  );
}

TitlesColumn.propTypes = {
  keyName: PropTypes.string,
  item: stafferShape,
};

export default TitlesColumn;
