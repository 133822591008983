import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CoreSelect from 'components/core/Select';
import Label from 'components/core/Label';
import { sortOptionShape } from 'components/core/Sort/shapes';

import styles from './styles.module.scss';

function Select({
  className,
  options,
  onSort,
  selectedSort,
  labelClassName,
  selectClassName,
  defaultOption,
}) {
  function handleSelectOption(selectedOption) {
    onSort(selectedOption)();
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Label className={classNames(styles.label, labelClassName)}>
        Sort by
      </Label>

      <div className={classNames(styles.select, selectClassName)}>
        <CoreSelect
          clearable={false}
          searchable={false}
          options={options}
          onChange={handleSelectOption}
          value={selectedSort || defaultOption}
          hasNewStyle
        />
      </div>
    </div>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(sortOptionShape).isRequired,
  onSort: PropTypes.func.isRequired,
  selectedSort: sortOptionShape.isRequired,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  defaultOption: sortOptionShape.isRequired,
};

export default Select;
