import React from 'react';

import Label from 'components/core/Label';
import Card from 'components/core/Card';

import PreviewContent from './components/PreviewContent';
import { isEmptyFieldType } from '../../utils';
import { fieldTypeShape } from '../../utils/shapes';
import withCustomField from '../../utils/withCustomField';
import styles from './styles.module.scss';

function PreviewFields({ fieldType }) {
  const Title = <Label theme="light">Custom Field Preview</Label>;

  if (isEmptyFieldType(fieldType)) {
    return (
      <div className={styles.preview}>
        {Title}
        <div className={styles.content}>
          <p>Nothing to preview</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.preview}>
      {Title}

      <Card className={styles.card}>
        <PreviewContent />
      </Card>
    </div>
  );
}

PreviewFields.propTypes = {
  fieldType: fieldTypeShape.isRequired,
};

export default withCustomField(PreviewFields);
