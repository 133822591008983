import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';

import CoreEmptyPlaceholder from 'components/core/EmptyPlaceholder';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

const defaultGetText = targetType => `This ${toLower(targetType)} does not yet have any tags associated with them`;
const defaultGetButtonText = targetType => `Tag this ${toLower(targetType)}`;

function EmptyTagsPlaceholder({ onClick, getText, getButtonText, targetType }) {
  const iconNode = <img src="/images/tag.svg" alt="Not found" />;

  const getEmptyDescription = () => (
    <>
      <p className={styles.text}>
        {getText(targetType)}
      </p>
      <Button
        onClick={onClick}
        theme="tableLink"
        className={styles.emptyCustomFieldsLink}
      >
        {getButtonText(targetType)}
      </Button>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <CoreEmptyPlaceholder
        iconNode={iconNode}
        className={styles.emptyPlaceholder}
        description={getEmptyDescription()}
      />
    </div>
  );
}

EmptyTagsPlaceholder.defaultProps = {
  getText: defaultGetText,
  getButtonText: defaultGetButtonText,
  targetType: 'item',
};

EmptyTagsPlaceholder.propTypes = {
  onClick: PropTypes.func.isRequired,
  getText: PropTypes.func,
  getButtonText: PropTypes.func,
  targetType: PropTypes.string,
};

export default EmptyTagsPlaceholder;
