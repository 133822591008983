import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

export default function Header({ activeTab, tabs, onTabClick, sideContent, className }) {
  function handleOnClick(tab) {
    return () => onTabClick(tab);
  }

  function renderTitle(tab) {
    const { title, id } = tab.props;
    const tabClassName = classNames(styles.listItem, activeTab.props.id === id && styles.listItemActive);

    return (
      <li
        key={`header-${title}-${id}`}
        className={classNames(tabClassName, className)}
        onClick={handleOnClick(tab)}
        role="button"
      >
        {title}
      </li>
    );
  }

  return (
    <ul className={styles.list}>
      {tabs.map(renderTitle)}
      <li className={styles.sideContent}>
        {sideContent}
      </li>
    </ul>
  );
}

Header.propTypes = {
  activeTab: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  sideContent: PropTypes.node,
  className: PropTypes.string,
};
