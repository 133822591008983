import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const QuestionMark = forwardRef((props, ref) => {
  const { className } = props;

  return (
    <span ref={ref} className={classNames(styles.icon, className)}>
      <img src="/images/question-mark.svg" className={styles.iconImage} alt="" />
    </span>
  );
});

export default QuestionMark;
