import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Link } from '@react-pdf/renderer';
import { size, map } from 'lodash';

import { isExternal, getUrlWithBase } from 'utils/url';

import styles from './styles';

function ListLinks({ label, value }) {
  const makeLink = ({ label: linkLabel, value: linkValue }, index) => {
    if (!linkLabel) {
      return null;
    }

    if (!linkValue) {
      return <Text style={styles.link} key={`link-${linkLabel}`}>{linkLabel}</Text>;
    }

    const linksNumber = size(value);
    const isNotTheLastLink = linksNumber > 1 && index < linksNumber - 1;
    const shouldIndentLink = index === 0 && isNotTheLastLink;
    const url = isExternal(linkValue) ? linkValue : getUrlWithBase(linkValue);
    const linkIndentStyle = {
      ...styles.link,
      ...styles.indent,
    };

    return (
      <View style={styles.links} key={`link-${linkLabel}`}>
        <Link
          key={linkValue}
          style={shouldIndentLink ? linkIndentStyle : styles.link}
          src={url}
        >
          {linkLabel}
        </Link>
        {isNotTheLastLink && <Text style={styles.text}>, </Text>}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}: </Text>
      <View style={styles.links}>
        {map(value, makeLink)}
      </View>
    </View>
  );
}

ListLinks.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default ListLinks;
