import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import Link from 'components/core/Link';
import Button from 'components/core/Button';

import styles from '../../ViewMoreItem/styles.module.scss';

function ListItem({ item, column, className, onClick, getHref, isExternal }) {
  const { id, name } = item;

  if (isFunction(getHref)) {
    return (
      <Link
        theme="tableLink"
        size="none"
        href={getHref(id, name, column)}
        className={classNames(styles.tableLink, className)}
        isExternal={isExternal}
      >
        {name}
      </Link>
    );
  }

  if (isFunction(onClick)) {
    return (
      <Button
        theme="tableLink"
        size="none"
        onClick={onClick(id, name, column)}
        className={classNames(styles.tableLink, className)}
      >
        {name}
      </Button>
    );
  }

  return (
    <div className={classNames(styles.tableLink, className)}>
      {name}
    </div>
  );
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  column: PropTypes.string,
  onClick: PropTypes.func,
  getHref: PropTypes.func,
  isExternal: PropTypes.bool,
};

export default ListItem;
