import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@react-pdf/renderer';

import { TextWithMentions } from 'components/core/TextareaWithMentions';

import styles from './styles';

function Description({ children }) {
  const renderMention = (mention) => {
    const { url, trigger, label, text } = mention;

    if (!url) {
      return <Text>{text}</Text>;
    }

    return (
      <Link
        wrap
        src={url}
        style={styles.link}
      >
        {trigger}{label}
      </Link>
    );
  };

  return (
    <Text style={styles.description} wrap>
      <TextWithMentions renderMention={renderMention} isPDF>
        {children}
      </TextWithMentions>
    </Text>
  );
}

Description.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Description;
