import { useState, useEffect } from 'react';
import min from 'lodash/min';

import {
  getPage,
  getNextInterval,
  getPreviousInterval,
  getIntervalFromCurrentPage,
} from './utils';
import { MOBILE_NUMBER_PAGES, DESKTOP_NUMBER_PAGES, DEFAULT_INITIAL_PAGE } from './constants';

export default function usePaginator({
  current,
  total,
  onChange,
  totalItems,
  pageSize,
  isMobile,
  isLoading,
}) {
  const maxNumberOfPages = isMobile ? MOBILE_NUMBER_PAGES : DESKTOP_NUMBER_PAGES;
  const numberOfPages = min([maxNumberOfPages, total]);
  const initialPage = current || DEFAULT_INITIAL_PAGE;

  const [{ start, end }, setPageInterval] = useState({ start: 1, end: numberOfPages });
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      onChange(page);
    }
  };

  const handleLoadMorePage = (page = currentPage, step = 1) => {
    setCurrentPage(page + step);
    onChange(page + step);
  };

  const handleNextPage = (step = 1) => () =>
    handlePageChange(getPage(currentPage + step, total));
  const handlePreviousPage = (step = 1) => () =>
    handlePageChange(getPage(currentPage - step, total));

  // Centralize current page if numberOfPages changes
  useEffect(() => {
    const pageInterval =
      getIntervalFromCurrentPage(currentPage, numberOfPages, total);

    setPageInterval(pageInterval);
  }, [numberOfPages, total]);

  // Update local currentPage if current was changed by parent
  useEffect(() => { current && setCurrentPage(current); }, [current]);

  // Update page interval based on currentPage position, going to the start
  // of the next interval or the end of the previous interval
  useEffect(() => {
    if (currentPage > end) {
      setPageInterval(getNextInterval(currentPage, numberOfPages, total));
    }
    if (currentPage < start) {
      setPageInterval(getPreviousInterval(currentPage, numberOfPages, total));
    }
  }, [currentPage]);

  const descriptionStart = (pageSize * (currentPage - 1)) + 1;
  const descriptionEnd = min([pageSize * currentPage, totalItems]);
  const isNextDisabled = currentPage === total || isLoading;
  const isPreviousDisabled = currentPage === 1 || isLoading;
  const onPageClick = page => () => handlePageChange(page);

  return {
    start,
    end,
    currentPage,
    isNextDisabled,
    isPreviousDisabled,
    descriptionStart,
    descriptionEnd,
    maxNumberOfPages,
    onPageClick,
    onNextClick: handleNextPage,
    onPreviousClick: handlePreviousPage,
    onLoadMore: handleLoadMorePage,
  };
}
