import React from 'react';
import PropTypes from 'prop-types';

import { Consumer } from '../utils/scrollContext';

const SectionLink = ({ section, children, onClick }) => (
  <Consumer>
    {({ scrollTo, selected, isMoving }) => {
      const handleClick = () => {
        scrollTo(section);
        onClick(section);
      };
      const isSelected = selected === section;

      return children({ onClick: handleClick, isSelected, isDisabled: isMoving });
    }}
  </Consumer>
);

SectionLink.defaultProps = {
  onClick: () => {},
};

SectionLink.propTypes = {
  section: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default SectionLink;
