import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactHintFactory from 'react-hint';
import uniqueId from 'lodash/uniqueId';

import styles from './styles.module.scss';

const basePosition = 'bottom';

const tooltipThemes = {
  dark: styles.dark,
};

const tooltipPositions = {
  bottomRight: styles.bottomRight,
  bottomLeft: styles.bottomLeft,
};

const tooltipSizes = {
  small: styles.smallSize,
};

const ReactHint = ReactHintFactory(React);

function Tooltip({
  theme,
  content,
  name,
  position,
  children,
  className,
  headerClassName,
  hintEvent,
  forwardRef,
  size,
  persist,
  ...restProps
}) {
  function handleTooltipToggle() {
    if (!persist) {
      return;
    }

    const isTooltipOpen = forwardRef.current && forwardRef.current.state.target;

    if (isTooltipOpen) {
      setTimeout(forwardRef.current.toggleHint, 0);
    }
  }

  const hintName = `data-custom-${name}-${uniqueId()}`;
  const hintEvents = {
    [hintEvent]: true,
  };

  return (
    <span className={classNames(styles.tooltip, tooltipThemes[theme], headerClassName)}>
      <span onClick={handleTooltipToggle} className={styles.trigger} {...{ [hintName]: true }}>
        {children}
      </span>
      <ReactHint
        ref={forwardRef}
        position={basePosition}
        attribute={hintName}
        className={classNames(styles.hint, tooltipPositions[position], tooltipSizes[size], className)}
        onRenderContent={content}
        events={hintEvents}
        persist={persist}
        {...restProps}
      />
    </span>
  );
}

Tooltip.propTypes = {
  theme: PropTypes.string,
  content: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  headerClassName: PropTypes.string,
  position: PropTypes.oneOf(
    Object.keys(tooltipPositions),
  ),
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  persist: PropTypes.bool,
  delay: PropTypes.number,
  forwardRef: PropTypes.object,
  hintEvent: PropTypes.oneOf([
    'click',
    'focus',
    'hover',
  ]),
};

Tooltip.defaultProps = {
  persist: false,
  delay: 100,
  position: 'bottomRight',
  hintEvent: 'click',
  name: '',
};

export default Tooltip;
