// Date format for datepicker input display
const DATE_FORMAT = 'MM/dd/yyyy';

// ISO_8601 International Standard Date format
const DATE_FORMAT_ISO_8601 = 'YYYY-MM-DD';

// Date format for datepicker calendar header display (eg. June 2019)
const DATEPICKER_HEADER_DATE_FORMAT = 'MMMM YYYY';

// Minimal day format, default to 2 characters (eg. Su, Mo, Tu, We...)
const MIN_DAY_FORMAT = 'dd';

// Minimal day format pattern, to further display 1 character (eg. S, M, T, W...)
const SINGLE_CHAR_WEEKDAY_FORMAT = 'S_M_T_W_T_F_S';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MONTH_FORMAT = 'MMMM';
const YEAR_FORMAT = 'YYYY';
const START_YEAR_RANGE = 2000;

export default {
  DATE_FORMAT,
  DATE_FORMAT_ISO_8601,
  DATEPICKER_HEADER_DATE_FORMAT,
  MIN_DAY_FORMAT,
  SINGLE_CHAR_WEEKDAY_FORMAT,
  MONTH_FORMAT,
  YEAR_FORMAT,
  START_YEAR_RANGE,
  MONTHS,
};
