import React from 'react';
import PropTypes from 'prop-types';

import EmptyTablePlaceholder from 'components/core/Profile/components/Placeholders/EmptyTable';

import styles from './styles.module.scss';

function ClearableEmptyPlaceholder({
  areFiltersApplied,
  emptyDescription,
  emptyDescriptionFiltering,
}) {
  const description = (
    <div className={styles.placeholderContainer}>
      <div className={styles.textContent}>{(areFiltersApplied && emptyDescriptionFiltering) || emptyDescription}</div>
    </div>
  );

  return <EmptyTablePlaceholder description={description} />;
}

ClearableEmptyPlaceholder.propTypes = {
  areFiltersApplied: PropTypes.bool,
  emptyDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  emptyDescriptionFiltering: PropTypes.string,
};

export default ClearableEmptyPlaceholder;
