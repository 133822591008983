import PropTypes from 'prop-types';

import { CONTINUOUS_ID, DAILY_ID, NONE_ID, SHARED_ID, WEEKLY_ID } from './constants';

export const frequencyType = PropTypes.oneOf([
  NONE_ID,
  DAILY_ID,
  WEEKLY_ID,
  CONTINUOUS_ID,
  SHARED_ID,
  null,
  '',
]);
