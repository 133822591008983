import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View } from '@react-pdf/renderer';

import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';

import Tag from './components/Tag';
import styles from './styles';

function Tags({ tags, style }) {
  if (isEmpty(tags)) {
    return null;
  }

  return (
    <View style={styles.tags}>
      {tags.map(tag => (
        <Tag style={style} key={tag.value}>
          {tag.label}
        </Tag>
      ))}
    </View>
  );
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(tagShape),
  style: PropTypes.objectOf(PropTypes.string, PropTypes.number),
};

export default Tags;
