import { StyleSheet } from '@react-pdf/renderer';

import { DEFAULT_TEXT_COLOR, DEFAULT_LINE_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  timelineContainer: {
    fontSize: 12,
    position: 'relative',
    color: DEFAULT_TEXT_COLOR,
  },
  timelineTitle: {
    fontWeight: 500,
    marginBottom: 10,
  },
  title: {
    fontWeight: 300,
    lineHeight: 1.4,
  },
  itemText: {
    maxWidth: 484,
  },
  timelineDash: {
    width: 27,
    borderTopWidth: 1,
    borderTopColor: DEFAULT_LINE_COLOR,
  },
  lastTimelineDash: {
    width: 28,
  },
  lastItem: {
    position: 'absolute',
    top: '100%',
    left: -1,
    marginBottom: 0,
  },
  itemInfo: {
    marginTop: -6,
    marginLeft: 4,
  },
  emptyText: {
    marginTop: 10,
  },
  timelineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  timelineItemsContainer: {
    borderLeftWidth: 1,
    borderLeftColor: DEFAULT_LINE_COLOR,
  },
});

export default styles;
