import React from 'react';
import PropTypes from 'prop-types';

import useTagManager from 'components/core/ButtonTagManager/useTagManager';
import BaseOption from 'components/core/AdvancedSearch/components/Options/components/BaseOption';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { stringNumberType } from 'utils/shapes';

import { formatCreateLabel, getNewOptionData } from './utils';
import { onSaveTags as defaultSaveTags } from './api';
import styles from './styles.module.scss';

export const tagsLabelNode = <><div><i className="far fa-tag" /></div>Tag</>;

export const getAddAllTagsLabelNode = isDesktop => (
  isDesktop
    ? <><div><i className="fas fa-share" /></div>Tag all</>
    : <div><i className="far fa-share" /></div>
);

function ButtonTagManager({
  recordId,
  recordType,
  ids,
  label,
  buttonClassName,
  tags: initialTags = [],
  onSaveTags,
  isMultipleItems,
  isLoading: isLoadingRedux,
  withAjaxAbort,
  autoload,
  updateDataItem,
  isOpen,
  CustomButtonComponent,
  disabled,
  customIsOpenModal,
  customToggleModal,
  ...restProps
}) {
  const {
    tags,
    isLoading,
    isOpenModal,
    onSave,
    onChange,
    toggleModal,
  } = useTagManager({
    ...restProps,
    ids,
    isOpen,
    recordId,
    recordType,
    onSaveTags,
    initialTags,
    isMultipleItems,
    updateDataItem,
    customIsOpenModal,
    customToggleModal,
  });

  const buttons = (
    <>
      <Button
        theme="light"
        size="small"
        className={styles.cancelButton}
        onClick={toggleModal}
      >
        Cancel
      </Button>
      <Button
        size="small"
        isLoading={isLoading || isLoadingRedux}
        onClick={onSave}
        type="button"
      >
        Save
      </Button>
    </>
  );

  const title = isMultipleItems ? 'Add tags' : 'Manage tags';
  const ButtonComponent = CustomButtonComponent || Button;

  return (
    <>
      <ButtonComponent
        {...restProps}
        className={buttonClassName}
        disabled={isLoading || isLoadingRedux || disabled}
        onClick={toggleModal}
      >
        {label}
      </ButtonComponent>
      <Modal
        title={title}
        isOpen={isOpenModal}
        className={styles.modal}
        footerClassName={styles.footer}
        toggleOpen={toggleModal}
        buttons={buttons}
      >
        <BaseOption
          title="Tag name"
          asyncCreatable
          clearIndicator={!isMultipleItems}
          isClearable={!isMultipleItems}
          strategy="tag"
          createOptionPosition="last"
          formatCreateLabel={formatCreateLabel}
          getNewOptionData={getNewOptionData}
          defaultSelections={tags}
          overrideValue={tags}
          onChange={onChange}
          withAjaxAbort={withAjaxAbort}
          autoload={autoload}
          withOverflow
        />
      </Modal>
    </>
  );
}

ButtonTagManager.defaultProps = {
  label: 'Tag',
  onSaveTags: defaultSaveTags,
  disabled: false,
};

ButtonTagManager.propTypes = {
  isMultipleItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  recordType: PropTypes.string,
  recordId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  ids: PropTypes.arrayOf(stringNumberType),
  tags: PropTypes.arrayOf(tagShape),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  buttonClassName: PropTypes.string,
  onSaveTags: PropTypes.func,
  updateDataItem: PropTypes.func,
  autoload: PropTypes.bool,
  withAjaxAbort: PropTypes.bool,
  isOpen: PropTypes.bool,
  CustomButtonComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  customIsOpenModal: PropTypes.bool,
  customToggleModal: PropTypes.func,
  itemType: PropTypes.string,
};

export default ButtonTagManager;
