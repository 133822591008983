import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TablePlaceholder from 'components/core/Placeholder/components/Table';
import { TrimTextItem, DateItem, DraggableItem } from 'components/core/Table/components/ColumnItem';
import Table, { Column } from 'components/core/Table';
import {
  uiShape,
  allCustomFieldsItemShape,
  customFieldShape,
} from 'components/core/CustomFields/utils/shapes';
import { DRAG_END_REASON } from 'components/core/KanbanBoard/constants';

import { getFieldTypeDescription } from '../../../utils/constants';
import { sortShape } from '../utils/shapes';
import tableColumns from '../tableColumns';
import styles from '../styles.module.scss';

const DESCRIPTION_MAX_LIMIT = 55;

function FieldsTable({
  sort,
  ui,
  isLoadingCustomFields,
  allCustomFields,
  selectedItems,
  renderRowActions,
  onSelectItems,
  handleSortByColumn,
  onUpdatePosition,
}) {
  const { sortData, resetSortData } = sort;

  useEffect(() => {
    resetSortData();
  }, [ui.resetTable]);

  if (isLoadingCustomFields) {
    return <TablePlaceholder columns={6} />;
  }

  function getRequiredDescription(value) {
    return value === true ? 'Yes' : 'No';
  }

  const onDragEnd = ({ destination, source, reason }) => {
    if (!destination || destination.index === source.index || reason === DRAG_END_REASON.CANCEL) {
      return;
    }

    onUpdatePosition({ destination, source });
  };

  return (
    <Table
      uniqueKey="token"
      columns={tableColumns}
      data={allCustomFields}
      selectedItems={selectedItems}
      onSelectItems={onSelectItems}
      sortColumn={sortData.column}
      sortDirection={sortData.direction}
      onSort={handleSortByColumn}
      containerStyle={styles.table}
      isDroppingDisabled={ui.isUpdatingPositions}
      onDragEnd={onDragEnd}
      droppableId="droppable-custom-fields"
      isCheckboxPresent
      preserveCheckboxes
      withDragNDrop
    >
      <DraggableItem keyName="displayOrder" />
      <Column keyName="name" />
      <Column keyName="description">
        {description => (
          <TrimTextItem textToTrim={description} maxTextLength={DESCRIPTION_MAX_LIMIT} />
        )}
      </Column>
      <Column keyName="fieldType">{fieldType => getFieldTypeDescription(fieldType)}</Column>
      <Column keyName="required">{getRequiredDescription}</Column>
      <DateItem keyName="createdAt" id="createdAt" />
      <Column keyName="actions">{renderRowActions}</Column>
    </Table>
  );
}

FieldsTable.propTypes = {
  sort: sortShape.isRequired,
  ui: uiShape.isRequired,
  isLoadingCustomFields: PropTypes.bool.isRequired,
  allCustomFields: PropTypes.arrayOf(allCustomFieldsItemShape).isRequired,
  selectedItems: PropTypes.arrayOf(customFieldShape).isRequired,
  renderRowActions: PropTypes.func.isRequired,
  onSelectItems: PropTypes.func.isRequired,
  handleSortByColumn: PropTypes.func.isRequired,
  onUpdatePosition: PropTypes.func.isRequired,
};

export default FieldsTable;
