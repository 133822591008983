import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash';

import Link from 'components/core/Link';
import ViewTags from 'components/core/ViewTags';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import {
  selectedFeedShape,
  mentionShape,
  sourceShape,
  publishedAtShape,
  sentimentShape,
} from 'components/News/utils/shapes';
import { workspaceShape } from 'redux/events/utils/shapes';

import Sentiment from '../Sentiment';
import Mentions from '../Mentions';
import SourceLabel from '../SourceLabel';
import styles from './styles.module.scss';

function Header({
  imageUrl,
  source,
  author,
  href,
  title,
  mentions,
  publishedAt,
  isMobile,
  displaySource,
  rightContent,
  className,
  selectedFeed,
  isExternalComponent,
  isLoadingTags,
  tags,
  onTagClick,
  storyInfoClassName,
  isRouterLink,
  isLoadingMyFeedDocuments,
  sentiment,
  summary,
  withSummary,
  backTo,
  backToQuery,
  markAsReadLinkNode,
  sharings,
}) {
  const displayBadges = !(isEmpty(mentions) && isEmpty(tags) && isEmpty(sharings));

  function renderTitle() {
    const content = (
      <>
        <span className={styles.linkTitle}>{title}</span>
        {isMobile && <Sentiment className={styles.sentiment} {...sentiment} />}
      </>
    );

    if (!href) {
      return (
        <>
          <h2 className={styles.link}>{content}</h2>
          {markAsReadLinkNode}
        </>
      );
    }

    return (
      <>
        <Link
          isExternal
          theme="default"
          className={styles.link}
          iconClassName={styles.icon}
          href={href}
        >
          {content}
        </Link>
        {markAsReadLinkNode}
      </>
    );
  }

  const badgesNode = displayBadges ? (
    <div className={styles.badgesContainer}>
      <Mentions
        mentions={mentions}
        selectedFeed={selectedFeed}
        isExternalComponent={isExternalComponent}
        isRouterLink={isRouterLink}
        backTo={backTo}
        backToQuery={backToQuery}
        isContainerless
      />

      <ViewTags
        isLoading={isLoadingTags || isLoadingMyFeedDocuments}
        tags={tags}
        isContainerless
        onClick={onTagClick}
        isLink={!onTagClick}
        isRouterLink={isRouterLink}
        routerLinkState={{ selectedFeed }}
        tileClassName={styles.tag}
        backTo={backTo}
        backToQuery={backToQuery}
        wrapperClassName={styles.myFeedTile}
        sharings={sharings}
      />
    </div>
  ) : null;

  return (
    <header className={classNames(styles.header, className)}>
      {imageUrl && <img className={styles.image} src={imageUrl} alt="Story thumbnail" />}
      <div className={styles.title}>
        {renderTitle()}

        <div className={styles.titleInfo}>
          {displaySource && (
            <SourceLabel
              source={source}
              author={author}
              publishedAt={get(publishedAt, 'full')}
            />
          )}

          {!isMobile && badgesNode}
        </div>
      </div>

      {isMobile && badgesNode}

      {rightContent && (
        <div className={classNames(styles.storyInfo, storyInfoClassName)}>
          {rightContent}
        </div>
      )}

      {withSummary && summary}
    </header>
  );
}

Header.defaultProps = {
  withSummary: false,
};

Header.propTypes = {
  imageUrl: PropTypes.string,
  source: sourceShape,
  author: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  mentions: PropTypes.arrayOf(mentionShape),
  publishedAt: publishedAtShape,
  rightContent: PropTypes.node,
  isMobile: PropTypes.bool,
  displaySource: PropTypes.bool,
  className: PropTypes.string,
  selectedFeed: selectedFeedShape,
  isExternalComponent: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
  tags: PropTypes.arrayOf(tagShape),
  onTagClick: PropTypes.func,
  storyInfoClassName: PropTypes.string,
  isRouterLink: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool,
  sentiment: sentimentShape,
  summary: PropTypes.node,
  withSummary: PropTypes.bool,
  backTo: PropTypes.string,
  backToQuery: PropTypes.string,
  markAsReadLinkNode: PropTypes.node,
  sharings: PropTypes.arrayOf(workspaceShape),
};

export default Header;
