import styles from './styles.module.scss';

export const inputSizes = {
  default: styles.default,
  small: styles.small,
};

const applyPhoneMask = (number) => {
  number = number
    .replace(/[^0-9]/g, '')
    .slice(-10);

  const a = number.slice(0, 3);
  const b = number.slice(3, 6);
  const c = number.slice(6);

  const numberWithMask = `+1 (${a}) ${b}-${c}`;

  return numberWithMask;
};

export const handlePasteAutofill = (conformedValue, { rawValue }) => {
  const conformedNumber = conformedValue.replace(/[^0-9]/g, '');
  const rawNumber = rawValue.replace(/[^0-9]/g, '');

  if (rawNumber.length > 11) {
    return conformedValue;
  }

  // When the mask bug happens
  if (conformedNumber.indexOf(rawNumber) === -1) {
    return applyPhoneMask(rawValue);
  }

  return conformedValue;
};
