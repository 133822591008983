import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';

import TrimTextItem from 'components/core/Table/components/ColumnItem/TrimTextItem';
import { Column } from 'components/core/Table';
import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';

import styles from '../styles.module.scss';

function BillColumn({ keyName, item, trackingActions, formatBillUrl }) {
  const {
    billId,
    billName,
    billTitle,
    billUrl,
    voteNumber,
  } = item || {};
  const { trackVotingRecordIssueVisited } = trackingActions || {};
  const handleBillClick = () => trackVotingRecordIssueVisited
    && trackVotingRecordIssueVisited(billName, voteNumber);

  function renderBillLink(billUrlPath) {
    if (isEmpty(billUrlPath)) {
      return (
        <div className={styles.billColumn}>
          {billName}
          <div><TrimTextItem textToTrim={billTitle} /></div>
        </div>
      );
    }

    return (
      <div className={styles.billColumn}>
        <Link
          className={styles.billColumnLink}
          href={billUrlPath}
          theme="default"
          isExternal={isNil(billId)}
          onClick={handleBillClick}
        >
          {billName}
        </Link>
        <div className={styles.trimText}>
          <TrimTextItem textToTrim={billTitle} />
        </div>
      </div>
    );
  }

  function renderBill() {
    if (isEmpty(billName)) {
      return EMPTY_VALUE;
    }

    if (!isEmpty(billId)) {
      return renderBillLink(formatBillUrl(billId));
    }

    if (!isEmpty(billUrl)) {
      return renderBillLink(billUrl);
    }

    return renderBillLink;
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderBill}</Column>
  );
}

BillColumn.defaultProps = {
  item: {},
};

BillColumn.propTypes = {
  formatBillUrl: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  trackingActions: PropTypes.object,
  item: PropTypes.object,
};

export default BillColumn;
