import { useEffect, useRef, useState } from 'react';
import find from 'lodash/find';

import { largeNavHeaderHeight } from 'components/core/StickyContainer/styles.module.scss';
import useStickyNode from 'components/core/StickyContainer/useStickyNode';
import { canRenderTopBar } from 'components/core/ProductBar/utils';
import { PRODUCT_BAR_HEIGHT } from 'utils/constants';

export default function useStatementsAndFilters({
  isMobile,
  isDraggingStatement,
  isDraggingFilter,
  draggableItems,
  transactor,
}) {
  const nodeRef = useRef();
  const [stickMargin, setStickMargin] = useState(0);
  const withProductBar = canRenderTopBar(isMobile);
  const offsetHeight = parseInt(largeNavHeaderHeight, 10);
  const topBarOffset = withProductBar ? PRODUCT_BAR_HEIGHT : 0;
  const isDragging = isDraggingStatement || isDraggingFilter;
  const { statements, filters } = find(draggableItems, ({ id }) => id === transactor) || {};
  const isSticky = useStickyNode({ ref: nodeRef, offsetHeight: offsetHeight + topBarOffset });
  const isStickyAndNotDragging = isSticky && !isDragging;

  const styleProps = !isStickyAndNotDragging && {
    style: { transform: `translateY(${stickMargin}px)` },
  };

  function updateStickMargin() {
    const scrolledDistanceFromTop = window.pageYOffset || (
      document.documentElement
      || document.body.parentNode
      || document.body
    ).scrollTop;

    const HEADER_PAGE_HEIGHT = 100;
    const STATEMENTS_AND_FILTERS_TITLE = 69;
    const margin = scrolledDistanceFromTop - (HEADER_PAGE_HEIGHT + STATEMENTS_AND_FILTERS_TITLE);

    setStickMargin(margin >= 0 ? margin : 0);
  }

  useEffect(() => {
    document.addEventListener('scroll', updateStickMargin);

    return () => {
      document.removeEventListener('scroll', updateStickMargin);
    };
  });

  return {
    isStickyAndNotDragging,
    withProductBar,
    statements,
    styleProps,
    filters,
    nodeRef,
  };
}
