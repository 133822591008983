import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import Options from './components/Options';
import getItemStyle from './theme';
import styles from './styles.module.scss';

function DroppableItem({ index, type, options }) {
  const getRenderItem = (provided, snapshot, rubric) => {
    const { name } = options[rubric.source.index];

    const draggableItemProps = {
      ...provided.draggableProps,
      ...provided.dragHandleProps,
      ref: provided.innerRef,
      className: styles.dragging,
      style: getItemStyle(snapshot.isDragging, provided.draggableProps.style),
    };

    return (
      <span {...draggableItemProps}>
        <i className="far fa-arrows-alt" /> {name}
      </span>
    );
  };

  return (
    <div className={styles.container}>
      <Droppable droppableId={`droppable${index}`} isDropDisabled type={type} renderClone={getRenderItem}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Options
              options={options}
              dragDropPlaceholder={provided.placeholder}
              droppableSnapshot={snapshot}
            />
          </div>
        )}
      </Droppable>
    </div>
  );
}

DroppableItem.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default DroppableItem;
