import { isEmpty, isFunction } from 'lodash';

export function getLegislatorUrl(legislator, formatLegislatorUrl) {
  const { id, url } = legislator || {};

  if (!isEmpty(url)) {
    return url;
  }

  if (!isFunction(url) || isEmpty(id)) {
    return null;
  }

  return formatLegislatorUrl(id);
}
