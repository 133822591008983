import React from 'react';
import PropTypes from 'prop-types';

import { BaseLabelGroup } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function Chamber({ keyName, chambers, titles, value, onChange, className }) {
  return (
    <BaseLabelGroup
      {...titles}
      className={className}
      labelGroupClassName={styles.multiLabel}
      id={keyName}
      name={keyName}
      values={chambers}
      value={value}
      onChange={onChange}
    />
  );
}

Chamber.defaultProps = {
  keyName: 'chamber',
  titles: {
    title: 'Chamber',
    placeholder: 'Chamber the legislator serves in',
  },
};

Chamber.propTypes = {
  value: PropTypes.array.isRequired,
  chambers: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  titles: PropTypes.object,
  className: PropTypes.string,
};

export default Chamber;
