import React from 'react';
import PropTypes from 'prop-types';
import { View, Link } from '@react-pdf/renderer';
import { size, map, isEmpty, truncate, slice } from 'lodash';

import { LabelItemList, LabelLink, LabelText } from 'components/core/ExportDocument';
import { TAGS_DISPLAY_LENGTH } from 'components/core/ViewTags/constants';

import styles from './styles';

const TAG_LENGTH = 27;

export const renderTagLink = href => (item, index) => {
  const { label } = item;

  if (isEmpty(label)) {
    return null;
  }
  const keyName = `${label}-${index}`;

  return (
    <Link key={keyName} src={href}>
      <LabelText
        textStyle={styles.billTagText}
        value={truncate(label, { length: TAG_LENGTH })}
        style={styles.billTag}
      />
    </Link>
  );
};

function BillFooter({
  bill,
  displayBillTextSection,
  displayGovpredictLinkSection,
  displayTagsSection,
  billLinkLabel,
}) {
  const {
    billTextLink,
    govPredictLink,
    tags = [],
  } = bill || {};

  const allTagsSize = size(tags);
  const displayViewAllTags = displayTagsSection && allTagsSize > TAGS_DISPLAY_LENGTH;

  return (
    <>
      {displayTagsSection && (
        <LabelItemList
          style={styles.billTagContainerSection}
          labelStyle={styles.billTagLabelText}
          listStyle={styles.billTagSection}
          label="Tags:"
          hideComma
        >
          {map(slice(tags, 0, TAGS_DISPLAY_LENGTH), renderTagLink(govPredictLink))}
        </LabelItemList>
      )}
      <View style={styles.billLinksSection}>
        {displayViewAllTags && (
          <LabelLink
            value={`View all ${allTagsSize} tags on bill page`}
            href={govPredictLink}
            linkStyle={styles.billLink}
            labelStyle={styles.linkStyle}
          />
        )}
        {displayBillTextSection && (
          <LabelLink
            value={billLinkLabel}
            href={billTextLink}
            linkStyle={styles.billLink}
            labelStyle={styles.linkStyle}
            emptyTextStyle={styles.billEmptyLink}
          />
        )}

        {displayGovpredictLinkSection && (
          <LabelLink
            value="Intelligence bill profile"
            href={govPredictLink}
            linkStyle={styles.billLink}
            labelStyle={styles.linkStyle}
            emptyTextStyle={styles.billEmptyLink}
          />
        )}
      </View>
    </>
  );
}

BillFooter.defaultProps = {
  billLinkLabel: 'Bill text',
};

BillFooter.propTypes = {
  bill: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  displayBillTextSection: PropTypes.bool,
  displayGovpredictLinkSection: PropTypes.bool,
  displayTagsSection: PropTypes.bool,
  billLinkLabel: PropTypes.string,
};

export default BillFooter;
