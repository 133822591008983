import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function TextPlaceholder({ className }) {
  return (
    <div className={classNames(styles.textLabel, className)} />
  );
}

TextPlaceholder.propTypes = {
  className: PropTypes.string,
};

export default TextPlaceholder;
