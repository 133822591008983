import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/core/Select';
import Label from 'components/core/Label';
import { getError } from 'utils/forms';

import { PRODUCT_SLUGS_OPTIONS } from '../../utils/constants';
import { errorsShape, productSlugsType, touchedShape } from '../../utils/shapes';
import styles from '../FieldsData/styles.module.scss';

function AvailableInField({ setFieldValue, productSlugs, formState }) {
  function handleProductSlugsChange(selectedOption) {
    setFieldValue('productSlugs', selectedOption);
  }

  return (
    <div className={styles.availableIn}>
      <Label theme="light">Make available in…</Label>
      <Select
        hasNewStyle
        multi
        name="productSlugs"
        value={productSlugs}
        options={PRODUCT_SLUGS_OPTIONS}
        onChange={handleProductSlugsChange}
        errorMessage={getError('productSlugs', formState)}
        clearable={false}
        className="custom-fields__available-in"
      />
    </div>
  );
}

AvailableInField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  productSlugs: productSlugsType,
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: touchedShape,
  }),
};

export default AvailableInField;
