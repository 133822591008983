import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/core/Button';
import { SettingsButton, settingsButtonOptionsShape } from 'components/core/PageHeader';

import withSearch from '../../utils/withSearch';
import { CustomToggleViewButton, CustomToggleViewContent } from '../CustomToggleView';
import AdvancedSearch from '../AdvancedSearch';
import PageViewToggle from '../PageViewToggle';

import styles from './styles.module.scss';

function MobileView({
  className,
  appliedFiltersLength,
  searchContent,
  advancedSearchContent,
  actionsContent,
  handleAdvancedSearchToggle,
  handleSearchToggle,
  isAdvancedSearchOpen,
  isSearchOpen,
  rightContent,
  pageView,
  customToggleViewProps,
  settingsButtonOptions,
  customFilterContent,
  customFilterButton,
}) {
  const customIcon = <i className={classNames('far', 'fa-search', styles.icon)} />;
  const isEmptyActionsContent = typeof actionsContent !== 'function' || isEmpty(actionsContent());
  const isEmptyRightContent = isEmpty(rightContent);
  const withPageView = !isEmpty(pageView);
  const withCustomToggleView = !isEmpty(customToggleViewProps);

  const advancedSearchButton = (
    <AdvancedSearch
      handleAdvancedSearchToggle={handleAdvancedSearchToggle}
      appliedFiltersLength={appliedFiltersLength}
    />
  );

  if (isAdvancedSearchOpen) {
    return (
      <section className={classNames(styles.container, className)}>
        {advancedSearchContent}
      </section>
    );
  }

  if (isEmptyActionsContent && isEmptyRightContent) {
    return (
      <section className={classNames(styles.container, className)}>
        <header className={classNames('search-header', styles.header)}>
          <div className={styles.left}>
            <div className={styles.search}>
              {customFilterContent && customFilterButton}
              {searchContent}
            </div>
          </div>

          <div className={styles.right}>
            {withCustomToggleView && <CustomToggleViewButton />}
            {advancedSearchContent && advancedSearchButton}
          </div>
        </header>
      </section>
    );
  }

  if (isSearchOpen) {
    return (
      <section className={classNames(styles.container, className)}>
        <header className={classNames('search-header', styles.header)}>
          {searchContent}
        </header>
      </section>
    );
  }

  function getSettingsButton() {
    if (isEmpty(settingsButtonOptions)) {
      return null;
    }

    return (
      <SettingsButton
        options={settingsButtonOptions}
        className={styles.settingsButton}
      />
    );
  }

  return (
    <section className={classNames(styles.container, className)}>
      <header className={classNames('search-header', styles.header)}>
        <div className={styles.left}>
          {!isEmptyActionsContent && actionsContent()}
          {!isEmptyRightContent && rightContent}
          {withPageView && <PageViewToggle />}
        </div>

        <div className={styles.right}>
          {withCustomToggleView && <CustomToggleViewButton />}
          {advancedSearchContent && advancedSearchButton}
          {customFilterContent && customFilterButton}
          <Button
            className={styles.searchButton}
            theme="primary"
            customIcon={customIcon}
            onClick={handleSearchToggle}
            iconClassName={styles.iconWrapper}
          />
        </div>
      </header>
      {withCustomToggleView && <CustomToggleViewContent />}
      {getSettingsButton()}
    </section>
  );
}

MobileView.defaultProps = {
  isAdvancedSearchOpen: false,
  isSearchOpen: false,
  appliedFiltersLength: 0,
  customFilterContent: false,
};

MobileView.propTypes = {
  className: PropTypes.string,
  appliedFiltersLength: PropTypes.number,
  handleAdvancedSearchToggle: PropTypes.func,
  handleSearchToggle: PropTypes.func,
  isAdvancedSearchOpen: PropTypes.bool,
  isSearchOpen: PropTypes.bool,
  advancedSearchContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
  searchContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  actionsContent: PropTypes.func,
  rightContent: PropTypes.node,
  pageView: PropTypes.shape({
    pageView: PropTypes.string,
    isTablePageView: PropTypes.bool,
    onPageViewChange: PropTypes.func,
  }),
  customToggleViewProps: PropTypes.shape({
    viewContent: PropTypes.node,
    contentClassName: PropTypes.string,
    isCustomViewOpen: PropTypes.bool,
    mobileTitle: PropTypes.string,
    onToggleCustomView: PropTypes.func,
  }),
  settingsButtonOptions: settingsButtonOptionsShape,
  customFilterContent: PropTypes.bool,
  customFilterButton: PropTypes.node,
};

export default withSearch(MobileView);
