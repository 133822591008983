const titles = {
  sections: {
    title: 'Sections',
    description: 'Choose which sections to include',
  },
  fileFormat: {
    title: 'File Format',
    description: 'Choose the file format',
  },
  customization: {
    title: 'Customization',
    description: 'Brand the report with your logo',
  },
  preview: {
    title: 'Preview',
  },
};

export default titles;
