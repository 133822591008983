import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import classNames from 'classnames';
import startsWith from 'lodash/startsWith';

import Button from 'components/core/Button';
import Badge from 'components/core/Badge';
import { SlideUpModal } from 'components/core/Modal';
import { trackNotificationsClicked } from 'components/NavigationV2/utils/tracking';
import reduxConnect from 'utils/redux-connect';
import * as actions from 'redux/notifications/actions';

import { notificationShape } from '../../utils/shapes';
import useNotifications from '../../utils/useNotifications';
import { MY_FEED_LINK, TASK_BOARD_LINK } from '../../utils/constants';
import AlertsPanel from '../AlertsPanel';
import Placeholder from '../Placeholder';
import styles from './styles.module.scss';

function Container({
  notifications,
  totalNotifications,
  isMobile,
  isDisabled,
  withPortal,
  parentSelector,
  className,
  ...restProps
}) {
  const { pathname } = window.location;
  const isDashboard = startsWith(pathname, MY_FEED_LINK) || startsWith(pathname, TASK_BOARD_LINK);
  const isVisible = !isDashboard || withPortal;
  const displayNotifications = isVisible && !isDisabled;
  const localCount = localStorage.getItem('notificationsCount');

  const {
    isOpenPanel,
    onTogglePanel,
    pulse,
    displayBadge,
    displayCloseIcon,
    badgeContent,
  } = useNotifications({
    ...restProps,
    notifications,
    isMobile,
    totalNotifications,
    displayNotifications,
    localCount,
  });

  function getPanelContent() {
    return (
      <AlertsPanel
        {...restProps}
        notifications={notifications}
        totalNotifications={totalNotifications}
        isMobile={isMobile}
        onClose={onTogglePanel}
      />
    );
  }

  function getCustomIcon() {
    return displayCloseIcon
      ? <img src="/images/close-blue-icon.svg" alt="Close button" />
      : <i className={classNames('fa fa-bell', pulse && styles.pulse)} />;
  }

  if (!isVisible) {
    return null;
  }

  if (isDisabled) {
    return <Placeholder />;
  }

  const handleClick = () => {
    onTogglePanel();
    trackNotificationsClicked();
  };
  const isBadgeV2 = gon.isNavigationV2Enabled && isMobile;
  const buttonNode = (
    <Button
      theme="light"
      iconClassName={styles.icon}
      className={classNames(
        styles.notifications,
        displayCloseIcon && styles.closeNotifications,
        className
      )}
      customIcon={getCustomIcon()}
      onClick={handleClick}
    >
      {displayBadge && (
        <Badge className={classNames(styles.badge, isBadgeV2 && styles.badgeV2)} theme="red">
          {badgeContent}
        </Badge>
      )}
    </Button>
  );

  const desktopContent = (
    <Tooltip
      placement="bottom-end"
      className={classNames(styles.tooltip, styles.tooltipBox)}
      content={getPanelContent()}
      theme="light"
      visible={isOpenPanel}
      onClickOutside={onTogglePanel}
      interactive
      arrow={!isMobile}
    >
      <span>
        {buttonNode}
      </span>
    </Tooltip>
  );

  const mobileContent = (
    <>
      {buttonNode}
      <SlideUpModal
        isOpen={isOpenPanel}
        toggleOpen={onTogglePanel}
        overlayClassName={classNames(styles.overlay, gon.isNavigationV2Enabled && styles.v2)}
        bodyClassName={styles.modalBody}
        className={classNames(styles.modal, gon.isNavigationV2Enabled && styles.v2)}
        parentSelector={parentSelector}
      >
        {getPanelContent()}
      </SlideUpModal>
    </>
  );

  return isMobile ? mobileContent : desktopContent;
}

Container.defaultProps = {
  isDisabled: false,
  withPortal: false,
};

Container.propTypes = {
  notifications: PropTypes.arrayOf(notificationShape),
  totalNotifications: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  withPortal: PropTypes.bool,
  parentSelector: PropTypes.func,
  isUserMenuOpen: PropTypes.bool,
  toggleUserMenu: PropTypes.func,
  className: PropTypes.string,
};

export default reduxConnect('notifications', actions)(Container);
