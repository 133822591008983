import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

function Subject({ keyName, titles, value, onChange, className, isFederal, customStrategy }) {
  const billStrategy = isFederal
    ? 'federal_bill_top_term_subjects'
    : 'state_bill_main_subject';
  const strategy = customStrategy || billStrategy;

  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      {...titles}
      className={className}
      name={keyName}
      strategy={strategy}
      overrideValue={value}
      defaultSelections={value}
      onChange={handleChange}
    />
  );
}

Subject.defaultProps = {
  isFederal: true,
  keyName: 'subjects',
  titles: {
    title: 'Subject',
    placeholder: 'The main subject of the bill',
  },
};

Subject.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFederal: PropTypes.bool,
  customStrategy: PropTypes.string,
};

export default Subject;
