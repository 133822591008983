import { useState, useEffect } from 'react';

import { PDF_ID, SHOW_PAGE_CUSTOMIZATION_ID } from 'utils/constants/export';

function useExportModal({
  isOpenModal,
  defaultSectionValues = [],
  defaultCustomizationValues = [SHOW_PAGE_CUSTOMIZATION_ID],
  defaultFileFormatValues = [PDF_ID],
  onResetSummary,
}) {
  const [sectionValues, setSectionValues] = useState([]);
  const [fileFormatValues, setFileFormatValues] = useState(defaultFileFormatValues);
  const [customizationValues, setCustomizationValues] = useState([]);
  const [customInputsValues, setCustomInputsValues] = useState({});

  useEffect(() => {
    /* Reset form on open */
    if (isOpenModal) {
      setSectionValues(defaultSectionValues);
      setFileFormatValues(defaultFileFormatValues);
      setCustomizationValues(defaultCustomizationValues);
      setCustomInputsValues({});
      onResetSummary();
    }
  }, [isOpenModal]);

  function handleChangeSections(_, values) {
    setSectionValues(values);
  }

  function handleChangeFileFormat(_, values) {
    setFileFormatValues(values);
  }

  function handleChangeCustomization(_, values) {
    setCustomizationValues(values);
  }

  function handleChangeCustomInput({ key, value }) {
    setCustomInputsValues({
      ...customInputsValues,
      [key]: value,
    });
  }

  const getFileFormatProps = ({ onChange = () => {}, ...props } = {}) => ({
    ...props,
    values: fileFormatValues,
    onChange: (...args) => {
      handleChangeFileFormat(...args);
      onChange(...args);
    },
  });

  const getCustomizationProps = ({ onChange = () => {}, ...props } = {}) => ({
    ...props,
    values: customizationValues,
    onChange: (...args) => {
      handleChangeCustomization(...args);
      onChange(...args);
    },
  });

  const getSectionsProps = ({ onChange = () => {}, ...props } = {}) => ({
    ...props,
    values: sectionValues,
    onChange: (...args) => {
      handleChangeSections(...args);
      onChange(...args);
    },
  });

  const getCustomInputsValues = ({ key }) => customInputsValues[key];

  const getCustomInputProps = ({ onChangeCallback, key, ...props } = {}) => ({
    ...props,
    value: getCustomInputsValues({ key }),
    onChange: (value) => {
      handleChangeCustomInput({ key, value });
      onChangeCallback && onChangeCallback(value);
    },
  });

  return {
    sectionValues,
    fileFormatValues,
    customizationValues,
    customInputsValues,
    getFileFormatProps,
    getCustomizationProps,
    getSectionsProps,
    getCustomInputProps,
  };
}

export default useExportModal;
