import { useEffect, useState } from 'react';

import { getMapBounds } from './index';

function useMapBounds({
  map,
  markers,
  showMap,
}) {
  const [markersBounds, setMarkersBounds] = useState(null);

  useEffect(() => {
    if (map) {
      setMarkersBounds(getMapBounds(markers));
    }
  }, [map, markers]);

  useEffect(() => {
    if (map && markersBounds && showMap) {
      map.resize();
      map.fitBounds(markersBounds, { duration: 0, padding: 100 });
    }
  }, [markersBounds, showMap]);

  return {
    markersBounds,
  };
}

export default useMapBounds;
