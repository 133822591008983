import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';

function useSearchItem({
  activeBtn,
  onClickItem,
  onClearItem,
  onSaveItem,
  onRemoveItem,
  isDirty,
  allowSubmit,
  isSaveNameEnabled,
  onSearch,
  isMobile,
  isClickedItem,
}) {
  const { isOpenModal, toggleModal } = useModal();

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemObject, setSelectedItemObject] = useState(null);
  const [name, setName] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState();

  useEffect(() => {
    setSelectedItem(null);
  }, [activeBtn]);

  useEffect(() => {
    if (isDirty || !allowSubmit) {
      setSelectedItem(null);
    }
  }, [isDirty, allowSubmit]);

  useEffect(() => {
    isSaveNameEnabled && setNameErrorMessage(null);
  }, [isOpenModal, isSaveNameEnabled]);

  useEffect(() => {
    if (!isDirty && allowSubmit && isClickedItem && isMobile) {
      onSearch && onSearch();
    }
  }, [isDirty, allowSubmit, isClickedItem]);


  const resetValues = () => {
    setSelectedItem(null);
    setSelectedItemObject(null);
    setName('');
    setNameErrorMessage(null);
  };

  const handleClick = (item, index) => (event) => {
    event.stopPropagation();
    setSelectedItem(index);

    if (onClickItem) {
      onClickItem(item);
    }
  };

  const handleChangeName = (event) => {
    const nameValue = event.target.value;
    setName(nameValue);

    if (!isEmpty(nameValue)) {
      setNameErrorMessage('');
    }
  };

  const handleSave = (item) => {
    resetValues();

    onSaveItem(item);
  };

  const handleSaveWithModal = (event) => {
    event.stopPropagation();

    if (isEmpty(name)) {
      setNameErrorMessage('Please enter a search name.');
    } else {
      onSaveItem({ ...selectedItemObject, name });
      resetValues();
      toggleModal();
    }
  };

  const handleTriggerSave = item => (event) => {
    event.stopPropagation();

    if (isSaveNameEnabled) {
      setSelectedItemObject(item);

      return toggleModal();
    }

    return handleSave(item);
  };

  const handleRemove = item => (event) => {
    event.stopPropagation();
    setSelectedItem(null);

    onRemoveItem(item);
  };

  const handleClear = item => (event) => {
    event.stopPropagation();
    setSelectedItem(null);

    onClearItem && onClearItem(item);
  };

  return {
    selectedItem,
    nameErrorMessage,
    name,
    isOpenModal,
    toggleModal,
    onClick: handleClick,
    onTriggerSave: handleTriggerSave,
    onSaveWithName: handleSaveWithModal,
    onRemove: handleRemove,
    onClear: handleClear,
    onChangeName: handleChangeName,
  };
}

export default useSearchItem;
