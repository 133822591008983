import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';
import Link from 'components/core/Link';
import { getDirectMailLink } from 'utils/socialMedia/links';

import { validateEmail } from './validators';
import styles from '../styles.module.scss';

function EmailItem({ keyName, item, onClick, isExternal }) {
  const { email, emails = [] } = item;
  const dataList = email ? [email] : emails;

  const handleClick = (emailItem, isEmail) => () => isEmail && onClick && onClick(emailItem);

  function renderEmail(emailItem, isTooltipItem) {
    const isEmail = validateEmail(emailItem);
    return (
      <Link
        size="none"
        theme="link"
        className={classNames(styles.link, isTooltipItem && styles.tooltipLink)}
        href={isEmail ? getDirectMailLink(emailItem) : emailItem}
        onClick={handleClick(emailItem, isEmail)}
        isExternal={isExternal}
        displayExternalIcon={false}
      >
        {isEmail ? emailItem : 'Web form'}
      </Link>
    );
  }

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={dataList}
      isEmptyData={isEmpty(dataList)}
      getItem={renderEmail}
    />
  );
}

EmailItem.defaultProps = {
  item: {},
  isExternal: false,
};

EmailItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    email: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ])),
  }),
  onClick: PropTypes.func,
  isExternal: PropTypes.bool,
};

export default EmailItem;
