import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

function Placeholder({ className }) {
  return <TextPlaceholder className={classNames(styles.placeholder, className)} />;
}

Placeholder.propTypes = {
  className: PropTypes.string,
};

export default Placeholder;
