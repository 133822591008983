import React from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';

export default function TabsLayout({
  activeTab,
  className,
  tabs,
  onTabClick,
  tabContentClassName,
  buttonClassName,
}) {
  const { id: activeId } = activeTab.props;
  return (
    <section className={className}>
      <Header
        activeTab={activeTab}
        tabs={tabs}
        onTabClick={onTabClick}
        buttonClassName={buttonClassName}
      />
      <div className={tabContentClassName}>
        {tabs.map(({ props: { id, children } }) => id === activeId && children)}
      </div>
    </section>
  );
}

TabsLayout.propTypes = {
  activeTab: PropTypes.object,
  className: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabContentClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};
