import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DatePicker from 'components/core/DatePicker';

import { momentShape } from 'utils/shapes/moments';
import styles from './styles.module.scss';

function DateRange({
  title,
  placeholder,
  dateFormat,
  startDate,
  maxStartDate,
  endDate,
  startDateClassName,
  endDateClassName,
  handleChangeEnd,
  handleChangeStart,
  className,
  showMonthDropdown,
  showYearDropdown,
  autoOpenEndDate,
}) {
  const getInstanceOfDate = date => (date && date.toDate ? date.toDate() : date);
  const maxDate = getInstanceOfDate(endDate || maxStartDate);
  const minDate = getInstanceOfDate(startDate);
  const commonProps = {
    dateFormat,
    startDate: getInstanceOfDate(startDate),
    endDate: getInstanceOfDate(endDate),
    placeholder,
    showMonthDropdown,
    showYearDropdown,
  };

  const endCalendarRef = createRef();

  function onChangeStart(date) {
    handleChangeStart(date);
    autoOpenEndDate && endCalendarRef.current.setOpen(true);
  }

  return (
    <div className={classNames('date-range', styles.dateRange, className)}>
      {title && <span className={classNames('title', styles.text)}>{title}</span>}
      <DatePicker
        selectsStart
        selected={startDate}
        maxDate={maxDate}
        onChange={onChangeStart}
        popperPlacement="bottom-start"
        className={classNames(styles.startDate, startDateClassName)}
        {...commonProps}
      />
      <span className={styles.text}>to</span>
      <DatePicker
        selected={endDate}
        selectsEnd
        onChange={handleChangeEnd}
        minDate={minDate}
        className={classNames(styles.endDate, endDateClassName)}
        popperPlacement="bottom-end"
        popperClassName={styles.endDatePopper}
        forwardRef={endCalendarRef}
        {...commonProps}
      />
    </div>
  );
}

DateRange.defaultProps = {
  showMonthDropdown: false,
  showYearDropdown: false,
  startDateClassName: null,
  endDateClassName: null,
  autoOpenEndDate: true,
};

DateRange.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  startDateClassName: PropTypes.string,
  endDateClassName: PropTypes.string,
  maxStartDate: PropTypes.shape(momentShape),
  startDate: PropTypes.shape(momentShape),
  endDate: PropTypes.shape(momentShape),
  handleChangeEnd: PropTypes.func.isRequired,
  handleChangeStart: PropTypes.func.isRequired,
  className: PropTypes.string,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  autoOpenEndDate: PropTypes.bool,
};

export default DateRange;
