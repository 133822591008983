import React from 'react';
import PropTypes from 'prop-types';

import Highlighter from 'components/core/Highlighter';

import OptionIcon from './components/OptionIcon';
import OptionType from './components/OptionType';
import OptionPartyIcon from './components/OptionPartyIcon';

function RichOption({
  type,
  label,
  inputValue,
  strategy,
  party_slug: partySlug,
  profile_img_url: photoUrl,
  showIcon,
  optionClassName,
  ...restProps
}) {
  const customIconProps = {
    name: label,
    photoUrl,
  };

  return (
    <div className={optionClassName}>
      <OptionIcon
        isVisible={showIcon}
        type={type}
        strategy={strategy}
        customIconProps={customIconProps}
      />
      <Highlighter
        content={label}
        searchValue={inputValue}
      />
      <OptionPartyIcon
        type={type}
        partySlug={partySlug}
      />
      <OptionType type={type} {...restProps} />
    </div>
  );
}

RichOption.defaultProps = {
  inputValue: '',
  showIcon: true,
};

RichOption.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  strategy: PropTypes.string,
  showIcon: PropTypes.bool,
  party_slug: PropTypes.string,
  inputValue: PropTypes.string,
  profile_img_url: PropTypes.string,
  optionClassName: PropTypes.string,
};

export default RichOption;
