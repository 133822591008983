import React from 'react';
import PropTypes from 'prop-types';

import LabelGroup from 'components/core/LabelGroup';
import CoreRadioBox from 'components/core/RadioBox';

import styles from '../styles.module.scss';

function Radiobox({ id, items, values, onChange }) {
  const handleChange = ({ target: { value: changedValue } }) => onChange(id, changedValue);

  const renderRadiobox = (item) => {
    const { id: radioboxId, label, disabled } = item || {};

    return (
      <CoreRadioBox
        key={radioboxId}
        label={label}
        value={radioboxId}
        disabled={disabled}
        checked={values === radioboxId}
        onChange={handleChange}
      />
    );
  };

  return (
    <LabelGroup containerClassName={styles.item}>
      {items.map(renderRadiobox)}
    </LabelGroup>
  );
}

Radiobox.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  values: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Radiobox;
