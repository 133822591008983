import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import Avatar from 'components/core/Avatar';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import TipMessage, { Kbd } from 'components/core/TipMessage';
import { getCurrentUser } from 'utils/currentUser';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import CommentInput from '../CommentInput';
import useNewComment from './useNewComment';
import styles from './styles.module.scss';

function NewComment({
  onPost,
  isPosting,
  parentType,
  parentId,
  fieldRef,
  replyUserMention,
  onCancelReplyMention,
  className,
}) {
  const customIcon = isPosting && <Loader size="small" theme="light" onlyIcon />;

  const { isDesktop } = useMediaQuery();
  const { profileImgUrl, fullName } = getCurrentUser();
  const photoUrl = profileImgUrl || '';

  const isParentComment = isEmpty(parentType);
  const placeholder = isParentComment ? 'Add a reply' : 'Add a comment';

  const elementId = `newComment-${parentId}-${parentType || 'reply'}`;

  const showTipMessage = !isParentComment && isDesktop && !isPosting;

  const {
    inputValue,
    handleClick,
    handleChange,
    handleMentionsChange,
  } = useNewComment({
    onPost,
    parentType,
    parentId,
    replyUserMention,
    onCancelReplyMention,
  });

  return (
    <div className={classNames(styles.container, className)}>
      <Avatar
        name={fullName}
        photoUrl={photoUrl}
        className={styles.avatar}
      />
      <div className={styles.newComment} id={elementId}>
        <CommentInput
          placeholder={placeholder}
          fieldRef={fieldRef}
          value={inputValue}
          onChange={handleChange}
          parentId={elementId}
          classNames={{ mentionsControl: styles.field }}
          onMentionsChange={handleMentionsChange}
          onCtrlEnterDown={handleClick}
        />
        {!isEmpty(inputValue.trim()) && (
          <div className={styles.test}>
            <Button
              size="small"
              className={styles.button}
              onClick={handleClick}
              disabled={isPosting}
              customIcon={customIcon}
            >
              Post
            </Button>
            <TipMessage isVisible={showTipMessage} className={styles.tip}>
              press
              <Kbd>CMD</Kbd>
              /
              <Kbd>CTRL</Kbd>
              and
              <Kbd>ENTER</Kbd>
              to post
            </TipMessage>
          </div>
        )}
      </div>
    </div>
  );
}

NewComment.defaultProps = {
  replyUserMention: '',
  onCancelReplyMention: () => {},
};

NewComment.propTypes = {
  onPost: PropTypes.func.isRequired,
  isPosting: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  fieldRef: PropTypes.object,
  parentType: PropTypes.string,
  parentId: PropTypes.number.isRequired,
  replyUserMention: PropTypes.string,
  onCancelReplyMention: PropTypes.func,
  className: PropTypes.string,
};

export default NewComment;
