import trim from 'lodash/trim';

import { ROOT_BREADCRUMB } from 'components/Navigation/utils/constants';

export function getParentFromBreadcrumb(breadcrumb) {
  const [lastBreadcrumb] = [breadcrumb || '']
    .map(breadcrumbStr => breadcrumbStr.split(' / ').slice(-1))
    .map(trim);

  if (lastBreadcrumb === ROOT_BREADCRUMB) {
    return '';
  }

  return lastBreadcrumb;
}
