import React from 'react';
import PropTypes from 'prop-types';

import { Consumer } from '../utils/scrollContext';

const Section = ({ id, children, ...rest }) => (
  <Consumer>
    {({ registerRef }) => (
      <section ref={registerRef(id)} id={id} {...rest}>
        {children}
      </section>
    )}
  </Consumer>
);

Section.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Section.defaultProps = {
  children: null,
};

export default Section;
