import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/core/Modal';
import LabelGroup from 'components/core/LabelGroup';
import RadioBox from 'components/core/RadioBox';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import withCalendar from 'components/core/HearingCalendars/utils/withCalendar';
import { EMAIL_ID, FACEBOOK, TWITTER } from 'utils/constants/socialMedia';

import useShare from './useShare';
import styles from '../styles.module.scss';

function Share({
  items,
  selectedEvent,
  checkedEvents,
  isShareModalOpen,
  isLoading,
  toggleShareModal,
}) {
  const {
    handleShareSubmit,
    handleChangeShareItem,
  } = useShare(isShareModalOpen, selectedEvent, checkedEvents);

  if (!isShareModalOpen) {
    return null;
  }

  const shareActions = (
    <Fragment>
      <Button
        type="small"
        theme="light"
        disabled={isLoading}
        onClick={toggleShareModal}
      >
        Cancel
      </Button>

      <Button
        type="small"
        disabled={isLoading}
        onClick={handleShareSubmit}
      >
        <Fragment>
          {isLoading
            ? <Loader size="small" theme="light" className={styles.loader} />
            : <span>Share</span>}
        </Fragment>
      </Button>
    </Fragment>
  );

  return (
    <Modal
      isOpen={isShareModalOpen}
      title="Share"
      buttons={shareActions}
      toggleOpen={toggleShareModal}
    >
      <p>How would you like to share { checkedEvents.length === 1 ? 'this item' : 'these items' }?</p>

      <LabelGroup>
        {items.map((item, index) => (
          <RadioBox
            value={item.value}
            label={item.label}
            key={index}
            name="export"
            defaultChecked={item.defaultChecked}
            onChange={handleChangeShareItem}
          />
        ))}
      </LabelGroup>
    </Modal>
  );
}

Share.defaultProps = {
  items: [
    { label: 'Facebook', value: FACEBOOK, defaultChecked: false },
    { label: 'Twitter', value: TWITTER, defaultChecked: true },
    { label: 'Email', value: EMAIL_ID, defaultChecked: false },
  ],
};

Share.propTypes = {
  items: PropTypes.array,
  selectedEvent: PropTypes.object,
  isShareModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  checkedEvents: PropTypes.array.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
};

export default withCalendar(Share);
