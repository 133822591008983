import paths from 'components/Navigation/menuData/paths';

export const getAccountSections = () => ([
  {
    id: 'profile',
    title: 'My profile',
    links: [
      {
        href: paths.accountSettings.manageProfile,
        name: 'Manage profile',
      },
      {
        href: paths.accountSettings.changePassword,
        name: 'Change password',
      },
      {
        href: paths.accountSettings.manageProfilePhoto,
        name: 'Manage profile photo',
      },
    ],
  },
  {
    id: 'help',
    title: 'Help',
    links: [
      {
        href: 'https://govpredict.zendesk.com/hc/en-us',
        name: 'General support',
        isExternal: true,
      },
    ],
  },
  {
    id: 'orgSettings',
    title: 'Organization settings',
    links: [
      {
        href: `${paths.organizationSettings.manageOrganization}/${gon.companyId}`,
        name: 'Manage organization',
        disabled: !gon.currentUser.companyAdmin,
      },
      {
        href: paths.organizationSettings.users,
        name: 'Manage users',
        disabled: !gon.currentUser.companyAdmin,
      },
      {
        href: '/custom_fields',
        name: 'Custom fields',
        disabled: (
          !(gon.currentUser.companyAdmin && (gon.isStakeholderMgmtEnabled || gon.isGrmEnabled))
        ),
      },
      {
        href: paths.organizationSettings.inviteUsers,
        name: 'Invite users',
        disabled: !gon.currentUser.companyAdmin,
      },
      {
        href: paths.organizationSettings.institutionalData,
        name: 'Institutional data',
        disabled: !gon.currentUser.companyAdmin,
      },
    ],
  },
]);

export const LOG_OUT_LINK_PROPS = {
  href: paths.accountSettings.logOut,
  'data-method': 'delete',
  rel: 'nofollow',
};
