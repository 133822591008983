import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import { findNode } from 'components/core/SmartSearchBuilder/utils/dragEndActions/common';
import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';

import GroupOperator from './GroupOperator';
import { getStatementFilterStyle } from '../theme';
import styles from '../styles.module.scss';

function StatementFilter({
  draggableIndex,
  isDraggingOver,
  isLastOperator,
  parentOperator,
  parentId,
  filter,
  deleteFilter,
  draggableItems,
}) {
  const { id, dimension } = filter;
  const draggedItem = findNode(draggableItems, dimension);
  const { component: FilterComponent } = draggedItem || {};
  const isGroupOperatorDisplayed = !isLastOperator && !isDraggingOver;

  const handleDeleteStatementFilter = () => deleteFilter(id);

  return (
    <Draggable draggableId={id} index={draggableIndex}>
      {(draggableProvided, draggableSnapshot) => (
        <div className={styles.statementFilterContainer}>
          <div
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
            className={styles.statementFilter}
            style={getStatementFilterStyle(
              draggableSnapshot.isDragging,
              draggableProvided.draggableProps.style
            )}
          >
            <div className={styles.statementFilterInfo}>
              <span className={styles.statementFilterName}>
                {FilterComponent && (
                  <FilterComponent filter={filter} draggedItem={draggedItem} />
                )}
              </span>
              <span {...draggableProvided.dragHandleProps} className={styles.statementFilterDrag}>
                <i className="far fa-arrows-alt" />
              </span>
              <span className={styles.statementFilterDelete} onClick={handleDeleteStatementFilter}>
                <i className="fal fa-times" />
              </span>
            </div>

            {isGroupOperatorDisplayed && (
              <div className={styles.statementFilterOperator}>
                <GroupOperator type="item" id={parentId} operator={parentOperator} />
              </div>
            )}
          </div>
          {draggableProvided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

StatementFilter.propTypes = {
  isLastOperator: PropTypes.bool.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  draggableIndex: PropTypes.number.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  draggableItems: PropTypes.array.isRequired,
};

export default withSmartSearch(StatementFilter);
