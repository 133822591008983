// @ts-nocheck
import React from 'react';
import { map, size, reject, reduce } from 'lodash';

import Checkbox from 'components/core/Checkbox';
import { CheckEvent } from 'utils/types/events';

import { CheckboxItem, OnChange } from '../../utils/types';

type SelectAllWithSubItemsProps = {
  id: string;
  values: string[];
  items: CheckboxItem[];
  selector: string;
  onChange: OnChange;
  className?: string;
}

function SelectAllWithSubItems({
  id,
  values,
  items,
  selector,
  onChange,
  className,
}: SelectAllWithSubItemsProps) {
  const enabledItems = reject(items, 'disabled');
  const isChecked = size(values) === size(enabledItems);

  const handleSelectAll = ({ target: { checked } }: CheckEvent) => {
    const getOptionalFields = () => (
      reduce(enabledItems, (subFields, field) => {
        if (!field.subItems) {
          return subFields;
        }

        return {
          ...subFields,
          [field.subItems.id]: checked ? map(field.subItems.options, 'value') : [],
        };
      }, {})
    );

    const value = checked ? map(enabledItems, selector) : [];

    onChange(id, value, { optionalFields: getOptionalFields() });
  };

  return (
    <Checkbox
      isChecked={isChecked}
      label="Select/deselect all"
      onChange={handleSelectAll}
      className={className}
    />
  );
}

export default SelectAllWithSubItems;
