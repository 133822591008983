import React from 'react';
import PropTypes from 'prop-types';
import { Page, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Roboto',
  },
});

function BasePage({ children }) {
  return (
    <Page size="A4" style={styles.page} wrap>
      {children}
    </Page>
  );
}

BasePage.propTypes = {
  children: PropTypes.node,
};

export default BasePage;
