import React from 'react';
import PropTypes from 'prop-types';

import HighlighterFuzzy from './components/HighlighterFuzzy';
import HighlighterSequential from './components/HighlighterSequential';
import { SEARCH_TYPES } from './contants';

function Highlighter({ searchValue, content, className, searchType }) {
  const HighlighterComponent = {
    [SEARCH_TYPES.FUZZY]: HighlighterFuzzy,
    [SEARCH_TYPES.SEQUENTIAL]: HighlighterSequential,
  }[searchType];

  return (
    <HighlighterComponent
      content={content}
      searchValue={searchValue}
      className={className}
    />
  );
}

Highlighter.defaultProps = {
  searchType: SEARCH_TYPES.FUZZY,
};

Highlighter.propTypes = {
  searchValue: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  searchType: PropTypes.oneOf([
    SEARCH_TYPES.FUZZY,
    SEARCH_TYPES.SEQUENTIAL,
  ]),
};

export { SEARCH_TYPES };

export default Highlighter;
