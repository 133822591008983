import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import styles from './styles.module.scss';

function Drawer({ isOpen, children, 'data-test': dataTest }) {
  return (
    <div className={classNames(styles.drawer, !isOpen && styles.hideDrawer)} data-test={dataTest}>
      {children}
    </div>
  );
}

Drawer.defaultProps = {
  isOpen: false,
  'data-test': 'drawer',
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  'data-test': PropTypes.string,
};

export default Drawer;

export { Header, Body, Footer };
