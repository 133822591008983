import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';
import ViewMoreButton from 'components/core/ViewMoreButton';
import SomethingWentWrong from 'components/Errors/SomethingWentWrong';

import useTimeline from './utils/useTimeline';
import AsyncTimeline from './components/AsyncTimeline';
import TimeGroup from './components/TimeGroup';
import styles from './styles.module.scss';

function Timeline({
  title,
  description,
  emptyDescription,
  className,
  items,
  renderCustomTitle,
  isUpperCaseItemTitle,
  renderCustomCardContent,
  limit,
  timelineStatus,
}) {
  const commonProps = { title, description, emptyDescription };
  const isLoading = timelineStatus === 'loading';
  const {
    isHiddenEvents,
    isOpenMore,
    visibleItems,
    toggleViewMore,
  } = useTimeline({ items, isLoading, limit });
  const hasLoadingError = timelineStatus === 'error';
  const showPlaceholder = !hasLoadingError && isEmpty(items);

  if (isLoading) {
    return <LoadingPlaceholder {...commonProps} />;
  }

  function renderTimeGroup(item, index) {
    const { date } = item;

    return (
      <TimeGroup
        key={`card-action-${date}-${index}`}
        renderCustomTitle={renderCustomTitle}
        timeGroupItem={item}
        isUpperCaseItemTitle={isUpperCaseItemTitle}
        renderCustomCardContent={renderCustomCardContent}
        isFirstCard={index === 0}
      />
    );
  }

  return (
    <DataCard
      {...commonProps}
      showPlaceholder={showPlaceholder}
      className={classNames(styles.container, className)}
    >
      {hasLoadingError ? (
        <div className={styles.somethingWentWrong}>
          <SomethingWentWrong />
        </div>
      ) : (
        <>
          <div className={styles.itemsContainer}>
            {visibleItems.map(renderTimeGroup)}
          </div>
          <ViewMoreButton
            theme="grey"
            openText="Show all"
            closeText="Show less"
            className={styles.viewMoreButton}
            isVisible={isHiddenEvents}
            isOpen={isOpenMore}
            onClick={toggleViewMore}
          />
        </>
      )}
    </DataCard>
  );
}

Timeline.propTypes = {
  className: PropTypes.string,
  timelineStatus: PropTypes.string.isRequired,
  renderCustomTitle: PropTypes.func,
  isUpperCaseItemTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  emptyDescription: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  renderCustomCardContent: PropTypes.func,
  limit: PropTypes.number,
};

export { AsyncTimeline };
export default Timeline;
