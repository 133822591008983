import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from 'components/core/Card';
import ProgressBar from 'components/core/ProgressBar';
import { TextInputPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

function ProgressCard({ title, content, percent, placeholder, className }) {
  const headerNodes = <ProgressBar percent={percent} theme="light" />;

  return (
    <Card
      title={title}
      headerNodes={headerNodes}
      headerClassName={styles.header}
      headerNodeWrapperClassName={styles.progressBar}
      className={classNames(styles.card, styles.medium, className)}
      theme="medium"
    >
      {placeholder && (
        <TextInputPlaceholder className={styles.placeholder}>{placeholder}</TextInputPlaceholder>
      )}
      {content}
    </Card>
  );
}

ProgressCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  percent: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]),
};

export default ProgressCard;
