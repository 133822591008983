import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import Select from 'components/core/Select';

import styles from './styles.module.scss';

function TabsNavigation({ options }) {
  const { isMobile } = useMediaQuery();

  const redirectToPath = (path) => {
    window.location.assign(path);
  };

  const handleSelectChange = ({ value }) => (
    redirectToPath(value)
  );

  function isItemSelected(value) {
    return window.location.pathname.includes(value);
  }

  const getSelectedItemValue = () => {
    const activeItem = options.find(({ value }) => isItemSelected(value));
    return activeItem && activeItem.value;
  };

  if (isMobile) {
    return (
      <Select
        options={options}
        onChange={handleSelectChange}
        value={getSelectedItemValue()}
        clearable={false}
        hasNewStyle
        isSearchable={false}
        hideSelectedOptions
        className={styles.tabsNavigation}
        inputId="tabsNavigationSelect"
      />
    );
  }

  return (
    <ButtonGroup className={styles.tabsNavigation} theme="light">
      {options.map(({ label, value }) => (
        <Button
          key={value}
          onClick={() => redirectToPath(value)}
          className={classNames(styles.button, isItemSelected(value) && styles.active)}
          theme="secondary"
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export const optionShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

TabsNavigation.propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
};

export default TabsNavigation;
