import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Paginator from 'components/core/Paginator';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import styles from '../styles.module.scss';

function SearchesPaginator({ isMobile, isSaved, isLoading, paginatorSaved, isLoadingSearches }) {
  if (!isSaved) {
    return null;
  }

  if (isEmpty(paginatorSaved)) {
    return null;
  }

  const {
    current,
    total,
    onChangeSavedPage,
    perPage,
    totalCount,
  } = paginatorSaved;

  if (current === 1 && total === 1) {
    return null;
  }

  return (
    <Paginator
      isMobile={isMobile}
      isLoading={isLoading || isLoadingSearches}
      isTablePaginator={false}
      className={styles.paginator}
      current={current}
      total={total}
      pageSize={perPage}
      totalItems={totalCount}
      onChange={onChangeSavedPage}
    />
  );
}

SearchesPaginator.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isSaved: PropTypes.bool.isRequired,
  paginatorSaved: PropTypes.object,
  isLoadingSearches: PropTypes.bool,
};

export default withSearch(SearchesPaginator);
