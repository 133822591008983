import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from '../Select';

import defaultTheme from './theme';
import styles from './styles.module.scss';

function ButtonDropdown({
  title,
  iconClass,
  options,
  customTheme,
  minHeight,
  className,
  ...restProps
}) {
  const theme = customTheme || defaultTheme(minHeight);
  const placeholder = (
    <span>
      {iconClass && <i className={classNames(iconClass, styles.icon)} />}
      <span className={styles.title}>{title}</span>
    </span>
  );

  return (
    <div className={classNames('btn-dropdown', styles.buttonDropdown, className)}>
      <Select
        name={title}
        value={null}
        searchable={false}
        options={options}
        placeholder={placeholder}
        hasNewStyle
        customTheme={theme}
        {...restProps}
      />
    </div>
  );
}

ButtonDropdown.propTypes = {
  iconClass: PropTypes.string,
  minHeight: PropTypes.number,
  customTheme: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ButtonDropdown;
