import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import styles from './styles.module.scss';

function DraggableCard({ children, id, index, ...restProps }) {
  const skipDropAnimationStyle = (style, isDropAnimating) => {
    // skip drop animation https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md#skipping-the-drop-animation
    // in case of reenabling it:
    // - beaware that it will be dropped over the card that is being hovered instead of over the placeholder
    // - in some cases, it will also be moved by 10px because of placeholder margin, so be sure to fix that positioning
    if (isDropAnimating) {
      return {
        ...style,
        transitionDuration: '0.001s',
      };
    }

    return style;
  };

  return (
    <div className={styles.wrapper}>
      <Draggable draggableId={`${id}`} index={index} {...restProps}>
        {({ innerRef, draggableProps, dragHandleProps }, { isDragging, isDropAnimating }) => (
          <div
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            className={classNames(!isDragging && styles.fixedCard)}
            style={skipDropAnimationStyle(draggableProps.style, isDropAnimating)}
          >
            {children}
          </div>
        )}
      </Draggable>
    </div>
  );
}

DraggableCard.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
};

export default DraggableCard;
