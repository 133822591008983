import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { isFunction, isNumber, isString, isEmpty } from 'lodash';

import { LabelText } from 'components/core/ExportDocument';
import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles';

function TableView({ tableColumns, values, renderColumnValue }) {
  if (isEmpty(values)) {
    return <LabelText />;
  }

  /** Equal length default */
  const columnWidth = 100 / tableColumns.length;
  const currentColumnStyle = { width: `${columnWidth}%` };

  function renderColumn(column) {
    const {
      keyName,
      label,
      subLabel,
      width,
      customHeaderStyle = {},
      customHeaderColumnStyle = {},
    } = column;
    const currentStyle = {
      ...currentColumnStyle,
      ...(width && { width }),
      ...customHeaderStyle,
    };

    const columnStyle = {
      ...styles.columnHeader,
      ...customHeaderColumnStyle,
    };

    return (
      <View style={currentStyle} key={`table-header-${keyName}`}>
        <Text style={columnStyle}>{label}</Text>

        {subLabel && (
          <Text style={styles.subLabel}>{subLabel}</Text>
        )}
      </View>
    );
  }

  function getValue(valueObject, index) {
    const { keyName, width, customColumnStyle = {} } = valueObject;
    const currentStyle = {
      ...currentColumnStyle,
      ...styles.rowText,
      ...(width && { width }),
      ...customColumnStyle,
    };

    let value = valueObject[keyName];
    if (isFunction(renderColumnValue)) {
      value = renderColumnValue(valueObject, keyName);
    }

    if (isNumber(value) || isString(value) || isEmpty(value)) {
      return (
        <Text
          style={currentStyle}
          key={`table-value-${keyName}-${index}`}
        >
          {value || EMPTY_VALUE}
        </Text>
      );
    }

    return (
      <View
        style={currentStyle}
        key={`table-value-${keyName}-${index}`}
      >
        {value}
      </View>
    );
  }

  function renderValue(value, index) {
    const valuesLength = values.length;
    const isLastValue = index === (valuesLength - 1);

    const borderStyle = !isLastValue && styles.rowBorder;

    const rowStyle = {
      ...styles.row,
      ...borderStyle,
    };

    return (
      <View wrap={false} key={`table-row-${index}`} style={rowStyle}>
        {tableColumns.map(props => getValue({ ...props, ...value }, index))}
      </View>
    );
  }

  const rowStyle = { ...styles.rowBorder, ...styles.row, ...styles.rowHeader };

  return (
    <View style={styles.table}>
      <View fixed className="table-header" style={rowStyle}>
        {tableColumns.map(renderColumn)}
      </View>

      <View className="table-content" style={styles.header}>
        {values.map(renderValue)}
      </View>
    </View>
  );
}

TableView.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    subLabel: PropTypes.node,
    keyName: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customColumnStyle: PropTypes.object,
    customHeaderColumnStyle: PropTypes.object,
  })).isRequired,
  renderColumnValue: PropTypes.func,
  values: PropTypes.array,
};

export default TableView;
