export const desktopNavOpenStyle = `<style>
  @media only screen and (min-width: 768px) {
   .wrap-main.moved,
   .wrap-main.moved .action-bar {
      padding-left: 375px;
    }
  }
  
  .mobile-nav-mock.moved {
    width: 332px;
  }
</style>`;

export const borderBoxFixStyle = '<style>.action-bar { box-sizing: border-box; }}</style>';

export const withNavBarStyle = `<style>
  .mobile-nav-mock {
    background: linear-gradient(72deg, #0a5cd5 0%, #215fe6 100%);
    z-index: 52;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 60px;
    color: #fff;
    justify-content: center;
    align-items: center;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  .wrap-main {
    max-width: 1920px;
    width: 100%;
    background-color: color(white);
    padding: 42px 20px 120px;
    flex: 1;
    margin: 0 auto;
    justify-self: center;
  }

  @media only screen and (min-width: 768px) {
    .mobile-nav-mock {
      top: 0;
      width: 64px;
      height: 100%;
    }
    
    .wrap-main {
      padding-left: 84px;
    }
  }
</style>`;

export const defaultBodyStyle = `<style>
  body {
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.428571429;
    color: #222222;
    background-color: #f7f8fa;
  };
</style>`;

export const defaultDescription = {
  default: 'Select your actions',
  mobile: 'Select your actions',
  desktop: 'Select the record(s) you wish to alter and then perform action',
};
