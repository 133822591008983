import React from 'react';
import PropTypes from 'prop-types';

import Caret from '../Caret';
import styles from './styles.module.scss';

function CaretTitle({ title, isOpen, toggle }) {
  return (
    <div onClick={toggle} onKeyDown={toggle} className={styles.itemTitle}>
      <Caret isOpen={isOpen} />
      {title}
    </div>
  );
}

CaretTitle.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CaretTitle;
