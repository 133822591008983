import { joinCompact, getParenthesisItem } from 'utils/string';

export const getPublicationContent = ({ type, title, publisher, details, year }) => {
  const formattedTitle = title ? `"${title}"` : '';
  const formattedPublisher = getParenthesisItem(publisher);
  const formattedDetails = getParenthesisItem(details);
  const formattedYear = getParenthesisItem(year);
  const firstPart = joinCompact([type, formattedTitle, formattedPublisher]);
  const secondPart = joinCompact([formattedDetails, formattedYear], ' ');

  return `${firstPart} ${secondPart}`;
};
