import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import useSortTable from 'utils/useSortTable';
import Table, { Column } from 'components/core/Table';
import Paginator from 'components/core/Paginator';

import MoneyColumn from './components/MoneyColumn';
import ConfidenceColumn from './components/ConfidenceColumn';
import RecipientColumn from './components/RecipientColumn';
import FilingColumn from './components/FilingColumn';
import styles from './styles.module.scss';

export default function TableView({
  columns,
  data,
  isMobile,
  isLoading,
  paginator,
  onPageChange,
  onColumnSort,
  emptyMessage,
  rowLengthPlaceholder,
  sort,
  tableWrapperClassname,
  history,
  receiptState,
  searchReceipt,
  isReceiptLoading,
  receiptError,
}) {
  const { current, perPage, total, totalItems } = paginator || {};
  const { sortData, setSortData, getDirection } = useSortTable();

  useEffect(() => {
    sort && setSortData(sort);
  }, []);

  function handlePageChange(page) {
    onPageChange({
      page,
      sort_column: sortData.column,
      sort_direction: sortData.direction,
    });
  }

  function handleColumnSort(column) {
    onColumnSort({
      sort_column: column,
      sort_direction: getDirection(column),
    });
  }

  function renderColumn(column, tableData) {
    const { keyName, fixedWidth } = column;
    const columnProps = {
      key: keyName,
      keyName,
      column,
      fixedWidth,
      ...tableData,
    };

    const filingColumnProps = {
      ...columnProps,
      receiptState,
      searchReceipt,
      isReceiptLoading,
      receiptError,
      history,
    };

    const tableColumns = {
      recipient: <RecipientColumn {...columnProps} />,
      amount: <MoneyColumn {...columnProps} />,
      confidence: <ConfidenceColumn {...columnProps} />,
      filing: <FilingColumn {...filingColumnProps} />,
    };

    return tableColumns[keyName] || <Column key={keyName} keyName={keyName} />;
  }

  function renderPaginator() {
    if (isEmpty(data) || isEmpty(paginator)) {
      return null;
    }

    return (
      <Paginator
        isMobile={isMobile}
        isLoading={isLoading}
        onChange={handlePageChange}
        total={total}
        current={current}
        pageSize={perPage}
        totalItems={totalItems}
      />
    );
  }

  return (
    <Fragment>
      <Table
        isFixed
        columns={columns}
        isMobile={isMobile}
        isLoading={isLoading}
        sort={sort}
        onSort={handleColumnSort}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        tableWrapperClassname={classNames(styles.table, tableWrapperClassname)}
        customEmptyMessage={emptyMessage}
        data={data}
        rowLengthPlaceholder={rowLengthPlaceholder}
      >
        {columns.map(renderColumn)}
      </Table>
      {renderPaginator()}
    </Fragment>
  );
}

TableView.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  paginator: PropTypes.object,
  sort: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onColumnSort: PropTypes.func.isRequired,
  emptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  rowLengthPlaceholder: PropTypes.number,
  tableWrapperClassname: PropTypes.string,
  history: PropTypes.object,
  receiptState: PropTypes.object,
  searchReceipt: PropTypes.func,
  isReceiptLoading: PropTypes.bool,
  receiptError: PropTypes.bool,
};
