/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardComponent from 'components/core/Card';

import TextPlaceholder from '../Text';
import CardTablePlaceholder from './components/CardTable';
import CardNewsStoryPlaceholder from './components/CardNewsStory';
import styles from './styles.module.scss';

function Card({ title, rows, className, containerClassName, ...restProps }) {
  function getLabel(index) {
    return (
      <div key={index} className={styles.label}>
        <TextPlaceholder className={styles.title} />
        <TextPlaceholder className={styles.content} />
      </div>
    );
  }

  return (
    <CardComponent title={title} className={className} {...restProps}>
      <div className={classNames(styles.container, containerClassName)}>
        {Array(rows).fill(0).map((_, index) => getLabel(index))}
      </div>
    </CardComponent>
  );
}

Card.defaultProps = {
  rows: 15,
};

Card.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default Card;
export { CardTablePlaceholder, CardNewsStoryPlaceholder };
