import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import isEmpty from 'lodash/isEmpty';

import { SectionTitle } from 'components/core/ExportDocument';
import { EMPTY_VALUE } from 'utils/constants';

import Card from './components/Card';

const styles = StyleSheet.create({
  emptyPlaceholder: {
    textAlign: 'left',
    fontSize: 12,
  },
});

function YourActivity({ cards }) {
  const emptyPlaceholder = <Text style={styles.emptyPlaceholder}>{EMPTY_VALUE}</Text>;

  function renderCard(card = {}, index) {
    return <Card key={`grm-card-${index}`} {...card} />;
  }

  return (
    <View>
      <SectionTitle title="Activity" />
      {isEmpty(cards)
        ? emptyPlaceholder
        : cards.map(renderCard)}
    </View>
  );
}

YourActivity.propTypes = {
  cards: PropTypes.array,
};

export default YourActivity;
