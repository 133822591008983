import React from 'react';
import PropTypes from 'prop-types';
import { map, get } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants';
import { joinCompact } from 'utils/string';
import { getValidPhones } from 'components/core/Profile/utils';
import { positionShape } from 'components/core/Profile/utils/shapes';
import { formatUrl } from 'components/core/Profile/components/Table/components/Column/Link/utils';
import {
  getPhoneLabel,
  getPositionDescription,
  getPhone,
} from 'components/core/Profile/components/Cards/Positions/utils';
import { getStateName } from 'components/core/Profile/components/Cards/utils';

import TableSection from '../../TableSection';

function Position({ position, positionIndex }) {
  const {
    address,
    phones = [],
  } = position;

  const {
    building,
    street,
    city,
    state,
    zipCode,
  } = address || {};
  const hasMultiplePositions = positionIndex > 1;

  const stateName = getStateName(get(state, 'name'));
  const displayedAddress = joinCompact([street, city, stateName, zipCode]);
  const description = hasMultiplePositions ? getPositionDescription(position) : null;
  const title = hasMultiplePositions ? `Role/Office ${positionIndex}` : 'Office';

  function getPhones() {
    return map(getValidPhones(phones), (phone) => {
      const { type } = phone;
      const phoneUrl = getPhone(phone);

      return {
        label: getPhoneLabel(phone),
        value: phoneUrl,
        href: formatUrl({ url: phoneUrl, type }),
      };
    });
  }

  const tables = [
    [
      {
        label: 'Address',
        value: displayedAddress || EMPTY_VALUE,
      },
      {
        label: 'Building',
        value: building || EMPTY_VALUE,
      },
    ],
    getPhones(),
  ];

  return (
    <TableSection
      title={title}
      description={description}
      tables={tables}
    />
  );
}

Position.propTypes = {
  position: positionShape,
  positionIndex: PropTypes.number,
};

export default Position;
