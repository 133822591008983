import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextareaWithCharInfo from './components/TextareaWithCharInfo';
import InputWithCharInfo from './components/InputWithCharInfo';
import getCharInfo from './getCharInfo';
import styles from './styles.module.scss';

function CharInfo({ content, limit, className }) {
  const {
    charsLeft,
    charsMessage,
    charsClassName,
  } = getCharInfo({ content, limit });

  return (
    <div className={classNames(styles.container, className)}>
      <span>
        {`Maximum ${limit} characters limit`}
      </span>
      <span className={charsClassName}>
        <b>{charsLeft}</b> {charsMessage}
      </span>
    </div>
  );
}

CharInfo.defaultProps = {
  limit: 5000,
  content: '',
};

CharInfo.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  limit: PropTypes.number,
};

export default CharInfo;

export { TextareaWithCharInfo, InputWithCharInfo };
