import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import styles from '../styles.module.scss';

function ItemActions({ isRecent, item, onSave, onRemove }) {
  const buttonProps = {
    theme: 'light',
    className: styles.btn,
  };

  if (isRecent) {
    return (
      <Button {...buttonProps} onClick={onSave(item)}>
        Save
      </Button>
    );
  }

  return (
    <Button {...buttonProps} onClick={onRemove(item)}>
      Remove
    </Button>
  );
}

ItemActions.propTypes = {
  isRecent: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withSearch(ItemActions);
