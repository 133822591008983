import isEmpty from 'lodash/isEmpty';

function getNameInFilters(id, filters) {
  const filterName = filters.reduce((accumulator, currentFilter) => {
    const { options } = currentFilter;

    const searchedOption = options.find(({ id: optionId }) => optionId === id);

    if (!isEmpty(searchedOption)) {
      const { name } = searchedOption;
      accumulator = name;
    }

    return accumulator;
  }, []);

  return filterName;
}

function getNameInStatements(id, statements) {
  const searchedStatement = statements.find(({ id: statementId }) => statementId === id);
  const { name } = searchedStatement || {};

  return name;
}

export function getNameInDraggableItems(id, draggableItems) {
  const statementName = draggableItems.reduce((accumulator, currentItem) => {
    const { filters, statements } = currentItem;

    const searchedFilter = getNameInFilters(id, filters);
    const searchedStatement = getNameInStatements(id, statements);

    if (!isEmpty(searchedFilter)) {
      accumulator = searchedFilter;
    }

    if (!isEmpty(searchedStatement)) {
      accumulator = searchedStatement.toLowerCase();
    }

    return accumulator;
  }, []);

  return statementName;
}
