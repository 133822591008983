import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';

import styles from './styles.module.scss';

function TooltipTitle({ tooltipContent, className }) {
  if (isEmpty(tooltipContent)) {
    return null;
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Tooltip
        className={styles.iconTooltip}
        content={tooltipContent}
        theme="light"
        trigger="click"
        placement="bottom-start"
        interactive
        arrow
        zIndex={1}
      >
        <span className={styles.disclaimerIcon}>
          <i className="fas fa-question-circle" />
        </span>
      </Tooltip>
    </div>
  );
}

TooltipTitle.propTypes = {
  tooltipContent: PropTypes.node,
  className: PropTypes.string,
};

export default TooltipTitle;
