import isEmpty from 'lodash/isEmpty';

import { THEMES } from 'components/core/DuotoneIcon';
import Avatar from 'components/core/Avatar';

import ImageIcon from './components/ImageIcon';
import { TYPES } from './constants';
import styles from './styles.module.scss';

const avatarProps = {
  CustomIconComponent: Avatar,
  iconProps: {
    className: styles.avatar,
  },
};

const documentProps = {
  CustomIconComponent: ImageIcon,
  iconProps: {
    url: '/images/documents_icon.svg',
  },
};

export const optionTypes = {
  [TYPES.FEDERAL_LEGISLATOR]: {
    description: 'Federal Legislator',
    iconProps: {
      className: 'fa-podium-star',
    },
  },
  [TYPES.STATE_LEGISLATOR]: {
    description: 'State Legislator',
    iconProps: {
      className: 'fa-podium',
    },
  },
  [TYPES.STAKEHOLDER]: {
    description: 'Stakeholder',
    iconProps: {
      className: 'fa-podcast',
    },
  },
  [TYPES.COLLEAGUE]: {
    ...avatarProps,
    description: 'Colleague',
  },
  [TYPES.STATE_EXECUTIVE]: {
    ...avatarProps,
    description: 'State Executive',
  },
  [TYPES.STATE_AGENCY_STAFFER]: {
    ...avatarProps,
    description: 'State Executive Staffer',
  },
  [TYPES.FEDERAL_EXECUTIVE]: {
    ...avatarProps,
    description: 'Federal Executive',
  },
  [TYPES.LOCAL_EXECUTIVE]: {
    ...avatarProps,
    description: 'Local Executive',
  },
  [TYPES.USER]: {
    ...avatarProps,
    description: 'User',
  },
  [TYPES.STATE_STAFFER]: {
    description: 'State Staffer',
    CustomIconComponent: ImageIcon,
    iconProps: {
      url: '/images/staffer_icon.svg',
    },
  },
  [TYPES.FEDERAL_STAFFER]: {
    description: 'Federal Staffer',
    CustomIconComponent: ImageIcon,
    iconProps: {
      url: '/images/staffer_icon.svg',
    },
  },
  [TYPES.FEDERAL_BILL]: {
    description: 'Federal Bill',
    iconProps: {
      className: 'fa-flag-usa',
    },
  },
  [TYPES.STATE_BILL]: {
    description: 'State Bill',
    iconProps: {
      className: 'fa-flag-alt',
    },
  },
  [TYPES.FEDERAL_REGULATION]: {
    description: 'Federal Regulation',
    iconProps: {
      className: 'fa-stamp',
    },
  },
  [TYPES.STATE_REGULATION]: {
    description: 'State Regulation',
    iconProps: {
      className: 'fa-stamp',
    },
  },
  [TYPES.FEDERAL_COMMITTEE]: {
    description: ({ state_and_chamber }) => state_and_chamber && `(${state_and_chamber})`,
    iconProps: {
      className: 'fa-flag-usa',
    },
  },
  [TYPES.STATE_COMMITTEE]: {
    description: ({ state_and_chamber }) => state_and_chamber && `(${state_and_chamber})`,
    iconProps: {
      className: 'fa-flag-alt',
    },
  },
  [TYPES.HILL_DAY]: {
    description: 'Hill Day',
    iconProps: {
      className: 'fa-landmark-alt',
      theme: THEMES.GREEN,
    },
  },
  [TYPES.HILL_DAY_MEETING]: {
    description: 'Hill Day meeting',
    iconProps: {
      className: 'fa-calendar-minus',
      theme: THEMES.GREEN,
    },
  },
  [TYPES.CONSTITUENT]: {
    description: ({ matched_filters }) => {
      if (isEmpty(matched_filters)) return null;

      return '(Constituent)';
    },
  },
  [TYPES.AGENCY_PRESS_RELEASE]: {
    ...documentProps,
    description: 'Agency press release',
  },
  [TYPES.CBO_COST_ESTIMATE]: {
    ...documentProps,
    description: 'CBO cost estimate',
  },
  [TYPES.COMMITTEE_REPORT]: {
    ...documentProps,
    description: 'Committee report',
  },
  [TYPES.CONGRESSIONAL_PRESS_RELEASE]: {
    ...documentProps,
    description: 'Congressional press release',
  },
  [TYPES.CONGRESSIONAL_RECORD_ITEM]: {
    ...documentProps,
    description: 'Congressional record item',
  },
  [TYPES.CONTRACT]: {
    ...documentProps,
    description: 'Contract',
  },
  [TYPES.CRS_REPORT]: {
    ...documentProps,
    description: 'CRS report',
  },
  [TYPES.DEAR_COLLEAGUE_LETTER]: {
    ...documentProps,
    description: 'Dear Colleague letter',
  },
  [TYPES.EXECUTIVE_ORDER]: {
    ...documentProps,
    description: 'Executive order',
  },
  [TYPES.GAO_REPORT]: {
    ...documentProps,
    description: 'GAO report',
  },
  [TYPES.GRANT]: {
    ...documentProps,
    description: 'Grant',
  },
  [TYPES.LIVE_HEARING_TRANSCRIPT]: {
    ...documentProps,
    description: 'Live hearing transcript',
  },
  [TYPES.OFFICIAL_HEARING_TRANSCRIPT]: {
    ...documentProps,
    description: 'Official hearing transcript',
  },
  [TYPES.SAP_REPORT]: {
    ...documentProps,
    description: 'SAP report',
  },
  [TYPES.STATE_OF_THE_UNION_ADDRESS]: {
    ...documentProps,
    description: 'State of the Union addresse',
  },
  [TYPES.TREATY_DOCUMENT]: {
    ...documentProps,
    description: 'Treaty document',
  },
  [TYPES.THIRD_PARTY_REPORT]: {
    ...documentProps,
    description: 'Third party report',
  },
  [TYPES.WHITE_HOUSE_PRESS_BRIEFING]: {
    ...documentProps,
    description: 'White House press briefing',
  },
  [TYPES.GOVERNOR_ORDER]: {
    ...documentProps,
    description: 'Governor order',
  },
  [TYPES.GOVERNOR_PRESS_RELEASE]: {
    ...documentProps,
    description: 'Governor press release',
  },
  [TYPES.LOCAL_EXECUTIVE]: {
    description: 'Local executive',
  },
  [TYPES.STATE_EXECUTIVE]: {
    description: 'State executive',
  },
  [TYPES.STATE_AGENCY_STAFFER]: {
    description: 'State agency staffer',
  },
  [TYPES.FEDERAL_EXECUTIVE]: {
    description: 'Federal executive',
  },
};
