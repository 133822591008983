import cloneDeep from 'lodash/cloneDeep';

import { findNode } from '../dragEndActions/common';

export default function updateItemOperator({ id, value, data, updateData }) {
  const oldFilterGroup = findNode(data, id);
  const newFilterGroup = cloneDeep(oldFilterGroup);
  newFilterGroup.operator = value;

  updateData({
    payload: JSON.parse(
      JSON.stringify(data).replace(
        JSON.stringify(oldFilterGroup),
        JSON.stringify(newFilterGroup),
      ),
    ),
  });
}
