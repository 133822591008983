import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { themeStyles, THEMES } from './constants';

function DuotoneIcon({ className, theme }) {
  const themeStyle = themeStyles[theme] || {};

  return (
    <i
      className={classNames('fad', className)}
      style={themeStyle}
    />
  );
}

DuotoneIcon.defaultProps = {
  theme: THEMES.ORANGE,
};

DuotoneIcon.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.keys(THEMES)),
};

export { THEMES };

export default DuotoneIcon;
