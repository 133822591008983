import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import styles from '../styles.module.scss';

function ActiveItem({ activeItem, onClick }) {
  if (isEmpty(activeItem) || isEmpty(activeItem.component)) {
    return null;
  }

  const { component, title } = activeItem;

  return (
    <div>
      <button className={styles.backButton} onClick={onClick}>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.arrowBack}>
          <i className="fas fa-caret-left" />
          Back
        </div>
      </button>

      <div className={styles.activeComponent}>
        {component}
      </div>
    </div>
  );
}

ActiveItem.propTypes = {
  activeItem: PropTypes.shape({
    title: PropTypes.string,
    component: PropTypes.object,
  }),
  onClick: PropTypes.func.isRequired,
};

export default ActiveItem;
