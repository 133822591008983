import React from 'react';
import orderBy from 'lodash/orderBy';

import { getLimitExceedInfo, getLimitExceedError } from 'utils/notification';
import {
  LIMIT_PDF_SELECTED_ITEMS,
  EXPORT_RECORD_LIMIT,
  EXPORT_DOCX_RECORD_LIMIT,
  DOCX_ID,
} from 'utils/constants/export';
import useExportPDFDocument from 'components/core/ExportDocument/utils/useExportPDFDocument';
import useLoader from 'utils/useLoader';
import SORT_DIRECTIONS from 'utils/constants/sortDirections';
import { submitSingleExport } from 'utils/api';

function useExportLookupModal({
  fileName,
  selectedItems,
  sort,
  toggleModal,
  documentTitle,
  documentComponent,
  slugSingleExport,
  fetchComponentData,
  customApiProps,
  perPage,
  onNotifySuccess,
  onNotifyFailure,
}) {
  const loaders = useLoader();

  const sortedSelectedItems = orderBy(selectedItems, ['page', 'index'], [SORT_DIRECTIONS.ASC, SORT_DIRECTIONS.ASC]);
  const selectedItemIds = sortedSelectedItems.map(item => item.id);
  const documentProps = { documentTitle, selectedItems: sortedSelectedItems };

  const { isDocumentLoading, onExport: onExportPDF } = useExportPDFDocument({
    fetchComponentData,
    documentComponent: documentComponent && React.cloneElement(documentComponent, documentProps),
    onFileExported: toggleModal,
    limitExceedProps: {
      selectedItemsLength: sortedSelectedItems.length,
      limit: LIMIT_PDF_SELECTED_ITEMS,
      onLimitExceedInfo: getLimitExceedInfo,
    },
    onNotifySuccess,
    onNotifyFailure,
    fileName: `${fileName}.pdf`,
  });

  function handleExport(props) {
    const { fileExtension } = props;
    const exportLimit = fileExtension === DOCX_ID
      ? EXPORT_DOCX_RECORD_LIMIT
      : EXPORT_RECORD_LIMIT;

    return submitSingleExport({
      ...props,
      sort,
      fileName,
      loaders,
      perPage: perPage || exportLimit,
      selectedItems: selectedItemIds,
      reportSlug: slugSingleExport,
      onFileExported: toggleModal,
      customApiProps,
      limitExceedProps: {
        selectedItemsLength: selectedItemIds.length,
        limit: perPage || exportLimit,
        onLimitExceedError: getLimitExceedError,
      },
      onNotifySuccess,
      onNotifyFailure,
    });
  }

  return {
    onExportPDF,
    onExport: handleExport,
    isLoading: isDocumentLoading || loaders.isLoading,
  };
}

export default useExportLookupModal;
