import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';

import Button from 'components/core/Button';
import { handleClickFilter } from 'components/Stakeholder/Profile/utils';
import joinComponents from 'utils/joinComponents';

import Info from './Info';
import styles from './styles.module.scss';

function LinkList({ label, items, itemType, history }) {
  function makeLink(item) {
    return (
      <Button
        theme="light"
        className={styles.link} key={item.name}
        onClick={handleClickFilter(item, itemType, history)}
      >
        {item.name}
      </Button>
    );
  }

  const links = items.map(item => makeLink(item));
  const value = isEmpty(links) ? 'N/A' : joinComponents(links);

  return <Info label={label} value={value} />;
}

LinkList.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default withRouter(LinkList);
