import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';
import { animationTransitionDuration } from './settings.scss';

function ActionBarTransition({ children, id, transitionDuration, enable }) {
  const transitionClassNames = {
    enterActive: styles.transitionEntering,
    exitActive: styles.transitionExiting,
  };

  return (
    <CSSTransition
      key={id}
      timeout={transitionDuration}
      classNames={transitionClassNames}
      in={enable}
    >
      {children}
    </CSSTransition>
  );
}

ActionBarTransition.propTypes = {
  id: PropTypes.string,
  transitionDuration: PropTypes.number,
  enable: PropTypes.bool,
  children: PropTypes.object,
};

ActionBarTransition.defaultProps = {
  transitionDuration: parseInt(animationTransitionDuration, 10),
  enable: true,
};

export default ActionBarTransition;
