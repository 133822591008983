import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Textarea from 'components/core/Textarea';

function DocumentSummary({
  reportHasSummary,
  onChange,
  onReset,
  value,
}) {
  useEffect(() => {
    onReset && onReset();
  }, []);

  useEffect(() => {
    !reportHasSummary && onReset && onReset();
  }, [reportHasSummary]);

  if (!reportHasSummary) {
    return null;
  }

  return (
    <Textarea
      name="documentSummary"
      placeholder=""
      onChange={onChange}
      value={value}
    />
  );
}

DocumentSummary.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  reportHasSummary: PropTypes.bool,
  value: PropTypes.string,
};

export default DocumentSummary;
