import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/core/Select';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import getStyle from 'components/core/TagInput/theme';

import customFieldShape from '../../shapes/customField';
import controlStateShape from '../../shapes/controlState';
import Tooltip from '../../components/Tooltip';
import normalizeControlValue from '../../utils/normalizeControlValue';

function Autocomplete({ field, state, tooltipTheme, onBlur, onChange, className, placeholder }) {
  const { token, name, required, description, options = [] } = field;

  const handleChange = (event) => {
    const normalizedEvent = {
      target: {
        id: token,
        value: event && [event.value],
      },
    };

    onChange(normalizedEvent);
  };

  const handleBlur = (event) => {
    const normalizedEvent = {
      target: {
        id: token,
        value: [event.value],
      },
    };

    onBlur(normalizedEvent);
  };

  const controlValue = normalizeControlValue(state.value);

  return (
    <>
      <Label>
        {name} <Asterisk show={required} />
        <Tooltip content={description} theme={tooltipTheme} />
      </Label>
      <Select
        className={className}
        errorMessage={state.error}
        value={controlValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        id={token}
        options={options.map(option => ({ label: option, value: option }))}
        customTheme={getStyle({ isError: state.error })}
      />
    </>
  );
}

Autocomplete.propTypes = {
  field: customFieldShape.isRequired,
  state: controlStateShape.isRequired,
  tooltipTheme: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Autocomplete;
