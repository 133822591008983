import { StyleSheet } from '@react-pdf/renderer';

import { DEFAULT_HEADER_COLOR, DEFAULT_LINE_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  headerSection: {
    backgroundColor: DEFAULT_HEADER_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    padding: '5px 10px',
    borderBottomWidth: 1,
    borderBottomColor: DEFAULT_LINE_COLOR,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    minHeight: 35,
  },
  bodySection: {
    padding: '7px 10px 0',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    padding: '7px 10px',
    borderTopWidth: 1,
    borderTopColor: DEFAULT_LINE_COLOR,
    width: '100%',
  },
  card: {
    marginBottom: 6,
    borderWidth: 1,
    borderColor: DEFAULT_LINE_COLOR,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderStyle: 'solid',
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: DEFAULT_LINE_COLOR,
  },
});

export default styles;
