import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';

function useBulkExport({ allItems, isExportOpen }) {
  const { isOpenModal, toggleModal } = useModal(isExportOpen);

  const isBulkExportDisabled = isEmpty(allItems);

  return {
    isOpenModal,
    toggleModal,
    isBulkExportDisabled,
  };
}

export default useBulkExport;
