import React from 'react';
import PropTypes from 'prop-types';

import DropdownPosition from 'components/core/ButtonPosition/components/DropdownPosition';
import ButtonSlideGroup from 'components/core/ButtonSlideGroup';

import { positions, NO_POSITION_ID } from './utils/constants';
import { handleUpdatePosition, handleFetchData } from './utils';

function PositionButton({
  withTooltip,
  isLoadingPosition,
  isOpen,
  size,
  recordId,
  recordType,
  handleSetPosition,
  initialPosition,
  className,
  placeholderClassName,
  containerClassName,
  shouldFetchOnInit,
}) {
  const buttonProps = {
    isOpen,
    isLoadingOption: isLoadingPosition,
    baseParams: { recordId, recordType },
    initialOption: initialPosition || NO_POSITION_ID,
    options: positions,
    size,
    shouldFetchOnInit,
    placeholderClassName,
    containerClassName,
    className,
    fetchData: handleFetchData({ recordId, recordType }),
    handleUpdateOption: handleUpdatePosition,
    onSetOption: handleSetPosition,
  };

  if (withTooltip) {
    return <DropdownPosition {...buttonProps} />;
  }

  return <ButtonSlideGroup {...buttonProps} />;
}

PositionButton.propTypes = {
  withTooltip: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoadingPosition: PropTypes.bool,
  size: PropTypes.string,
  recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordType: PropTypes.string,
  handleSetPosition: PropTypes.func,
  initialPosition: PropTypes.string,
  shouldFetchOnInit: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
};

export default PositionButton;
