import PropTypes from 'prop-types';

import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { stringNumberType } from 'utils/shapes';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

export const itemShape = PropTypes.shape({
  id: stringNumberType.isRequired,
  url: PropTypes.string,
  documentType: PropTypes.string,
  title: PropTypes.string,
  itemType: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
  trackings: PropTypes.arrayOf(trackingShape),
  withTrack: PropTypes.bool.isRequired,
});
