const recordTitles = {
  keywordSearch: {
    title: 'Keyword search',
  },
  rangeDate: {
    title: 'Date range',
  },
  recordType: {
    title: 'Record type',
  },
  tags: {
    title: 'Tags',
  },
};

export default recordTitles;
