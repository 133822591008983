import { useState, useEffect } from 'react';

export default function useCustomNote({ onChange, disabled }) {
  const [inputValue, setInputValue] = useState([]);

  function resetData() {
    setInputValue([]);
  }

  useEffect(() => {
    disabled && resetData();
  }, [disabled]);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  function updateInputValue(newValue) {
    setInputValue(newValue);
  }

  return {
    inputValue,
    updateInputValue,
  };
}
