import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/core/Modal';

import useDocumentSummary from './utils/useDocumentSummary';
import useCompanyLogo from './utils/useCompanyLogo';
import useExportModal from './utils/useExportModal';
import Sections from './components/Sections';
import SuggesterItem from './components/SuggesterItem';
import CustomNote from './components/CustomNote';
import FileFormat from './components/FileFormat';
import Preview from './components/Preview';
import Customization from './components/Customization';
import Actions from './components/Actions';
import ExportPreview from './components/ExportPreview';
import styles from './styles.module.scss';

function ExportModal({
  isLoading,
  isOpenModal,
  toggleModal,
  children,
  onExport,
  displayCompanyLogo,
  defaultSectionValues,
  defaultCustomizationValues,
  defaultFileFormatValues,
  theme,
  className,
  bodyClassName,
}) {
  const {
    documentSummary,
    onChangeSummary,
    onResetSummary,
  } = useDocumentSummary({ isOpenModal });

  const {
    getFileFormatProps,
    getCustomizationProps,
    getSectionsProps,
    getCustomInputProps,
    ...values
  } = useExportModal({
    isOpenModal,
    defaultSectionValues,
    defaultCustomizationValues,
    defaultFileFormatValues,
    onResetSummary,
  });

  const {
    companyHasLogo,
    isLogoLoading,
    logoUrl,
  } = useCompanyLogo({ displayCompanyLogo, isOpenModal });

  const actionsComponent = (
    <Actions
      {...values}
      documentSummary={documentSummary}
      logoUrl={logoUrl}
      isLoading={isLoading || isLogoLoading}
      onExport={onExport}
      toggleModal={toggleModal}
    />
  );

  return (
    <Modal
      title="Export options"
      isOpen={isOpenModal}
      toggleOpen={toggleModal}
      className={classNames(styles.modal, className)}
      bodyClassName={bodyClassName}
      buttons={actionsComponent}
      theme={theme}
    >
      <div className={styles.modalContainer}>
        {children({
          getFileFormatProps,
          getCustomizationProps,
          getSectionsProps,
          getCustomInputProps,
          onChangeSummary,
          onResetSummary,
          documentSummary,
          companyHasLogo,
          isLogoLoading,
          logoUrl,
        })}
      </div>
    </Modal>
  );
}

ExportModal.defaultProps = {
  theme: 'scrollable',
};

ExportModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  displayCompanyLogo: PropTypes.bool,
  defaultSectionValues: PropTypes.array,
  defaultCustomizationValues: PropTypes.array,
  defaultFileFormatValues: PropTypes.array,
  theme: PropTypes.string,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
};

export {
  Sections,
  FileFormat,
  Preview,
  Customization,
  ExportPreview,
  SuggesterItem,
  CustomNote,
};

export default ExportModal;
