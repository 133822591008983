import React from 'react';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

const Placeholder = () => (
  <div className={styles.container}>
    <span className={styles.img} />
    <div className={styles.content}>
      <TextPlaceholder className={styles.title} />
      <TextPlaceholder className={styles.description} />
      <TextPlaceholder className={styles.url} />
    </div>
  </div>
);

export default Placeholder;
