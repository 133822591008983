import Modal from './components/Modal';

export default Modal;
export { default as MultiStepModal } from './components/MultiStep';
export { default as CloseConfirmation } from './components/CloseConfirmation';
export { default as TextConfirmation } from './components/TextConfirmation';
export { default as Disclaimer } from './components/Disclaimer';
export { default as ConfirmationModal } from './components/Confirmation';
export { default as SlideUpModal } from './components/SlideUpModal';
export { default as EditorialAlertModal } from './components/EditorialAlert';
