import React from 'react';
import PropTypes from 'prop-types';

import Textarea from 'components/core/Textarea';
import CharInfo from 'components/core/CharInfo';

import styles from './styles.module.scss';

function TextareaWithCharInfo({ charInfo, value, ...restProps }) {
  return (
    <div className={styles.container}>
      <Textarea {...restProps} value={value} />
      <CharInfo {...charInfo} content={value} />
    </div>
  );
}

TextareaWithCharInfo.propTypes = {
  value: PropTypes.string,
  charInfo: PropTypes.object,
};

export default TextareaWithCharInfo;
