
import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LINE_COLOR,
  DEFAULT_LABEL_COLOR,
  SECONDARY_LINE_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  labelBarContainer: {
    marginTop: 5,
    paddingBottom: 20,
    position: 'relative',
    width: '100%',
  },
  progressBar: {
    width: '100%',
    height: 4,
    top: 0,
    borderRadius: 15,
    position: 'absolute',
    backgroundColor: DEFAULT_LINE_COLOR,
  },
  activeProgressBar: {
    position: 'absolute',
    top: 0,
  },
  dotsContainer: {
    marginTop: -4,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dotContainer: {
    position: 'relative',
  },
  dotText: {
    position: 'absolute',
    fontSize: 9,
    marginTop: 20,
    width: 80,
    fontWeight: 200,
    color: DEFAULT_LABEL_COLOR,
  },
  labelDot: {
    height: 12,
    width: 12,
    borderWidth: 3,
    borderRadius: 7.5,
    borderColor: DEFAULT_LINE_COLOR,
    backgroundColor: SECONDARY_LINE_COLOR,
  },
});

export default styles;
