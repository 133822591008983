import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from '../utils/scrollContext';
import useScrollProvider from '../utils/useScrollProvider';

function ScrollingProvider({ activeId, children }) {
  const {
    isMoving,
    refList,
    selected,
    onRegisterRef,
    onClickToSection,
  } = useScrollProvider({ activeId });

  const value = {
    refList,
    selected,
    isMoving,
    registerRef: onRegisterRef,
    scrollTo: onClickToSection,
  };

  return <Provider value={value}>{children}</Provider>;
}

ScrollingProvider.propTypes = {
  activeId: PropTypes.string,
  children: PropTypes.node,
};

export default ScrollingProvider;
