import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Column } from 'components/core/Table';
import { dragHandlePropsShape } from 'utils/shapes/dnd';

import styles from './styles.module.scss';

function DraggableItem({ keyName, dragHandleProps, className, rowIndex }) {
  const renderPosition = () => (
    <span {...dragHandleProps} className={classNames(styles.position, className)}>
      <i className={classNames('fas fa-arrows-alt-v', styles.icon)} />
      #{rowIndex + 1}
    </span>
  );

  return <Column key={keyName} keyName={keyName}>{renderPosition}</Column>;
}

DraggableItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  dragHandleProps: dragHandlePropsShape,
  className: PropTypes.string,
  rowIndex: PropTypes.number,
};

export default DraggableItem;
