import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { removeLastComma } from '../utils';
import styles from '../styles.module.scss';

function Dropdown({ items, getSuggestionItemProps }) {
  if (isEmpty(items)) {
    return null;
  }

  function renderItem(item) {
    const { active, formattedSuggestion } = item;
    const itemClass = classNames(styles.dropdownItem, active && styles.isActive);
    const itemProps = getSuggestionItemProps(item, { className: itemClass });
    const secondaryText = removeLastComma(formattedSuggestion.secondaryText);

    return (
      <div {...itemProps}>
        <img className={styles.icon} src="/images/map/map-marker.png" alt="Map Marker Icon" />
        <b className={styles.mainText}>{formattedSuggestion.mainText}</b>
        <small>{secondaryText}</small>
      </div>
    );
  }

  return (
    <div className={styles.dropdownContainerWrapper}>
      <div className={styles.dropdownContainer}>
        {items.map(renderItem)}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
};

export default Dropdown;
