import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isFunction } from 'lodash';

import { optionTypes } from '../utils';
import { TYPES } from '../constants';
import styles from '../styles.module.scss';

function OptionType({ type, ...restProps }) {
  const { description } = optionTypes[type] || {};

  if (isNil(description)) {
    return null;
  }

  const renderDescription = () => {
    if (isFunction(description)) {
      return description(restProps);
    }

    return `(${description})`;
  };

  return (
    <i className={styles.optionType}>
      {renderDescription()}
    </i>
  );
}

OptionType.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
};

export default OptionType;
