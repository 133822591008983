import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/core/ErrorMessage';
import Checkbox from 'components/core/Checkbox';
import LabelGroup from 'components/core/LabelGroup';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';

import customFieldShape from '../../shapes/customField';
import controlStateShape from '../../shapes/controlState';
import normalizeEventTarget from '../../utils/normalizeEventTarget';
import Tooltip from '../../components/Tooltip';
import styles from '../styles.module.scss';

export default function CheckboxControl({ field, state, onChange, tooltipTheme, ...restProps }) {
  const { description, token, options, name, required } = field;
  const { value, error } = state;

  const anyAnswer = option => value.includes(option);

  function removeOption(option) {
    return value.filter(item => item !== option);
  }

  function addOption(option) {
    return [...value, option];
  }

  function handleChange(event) {
    const { target } = event;
    const { value: newValue, checked } = target;
    const values = checked ? addOption(newValue) : removeOption(newValue);
    const normalizedEvent = normalizeEventTarget(event, { id: token, value: values });

    onChange(normalizedEvent);
  }

  return (
    <>
      <Label htmlFor={token}>
        {name} <Asterisk show={required} />
        <Tooltip content={description} theme={tooltipTheme} />
      </Label>
      <LabelGroup containerClassName={styles.options}>
        {
          options.map(option => (
            <Checkbox
              {...restProps}
              key={`checkbox-${option}`}
              label={option}
              value={option}
              isChecked={anyAnswer(option)}
              onChange={handleChange}
            />
          ))
        }
      </LabelGroup>
      <ErrorMessage message={error} />
    </>
  );
}

CheckboxControl.propTypes = {
  field: customFieldShape.isRequired,
  state: controlStateShape,
  onChange: PropTypes.func.isRequired,
  tooltipTheme: PropTypes.string,
};
