import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import withCalendar from 'components/core/HearingCalendars/utils/withCalendar';
import Box from 'components/core/Box';

import Event from './components/Event';
import styles from './styles.module.scss';

function DayEvents({
  dayEvent: {
    events,
    date,
  },
}) {
  return (
    <div className={classNames('hearing-day-event', styles.dayEvent)}>
      <Box text={date} className={styles.title} />

      {map(events, (event, index) => (
        <Event
          key={`${event.id}-${index}`}
          event={event}
        />
      ))}
    </div>
  );
}

DayEvents.defaultProps = {
  dayEvent: {},
};

DayEvents.propTypes = {
  dayEvent: PropTypes.object.isRequired,
};

export default withCalendar(DayEvents);
