import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import NoSubscriptionMessage from './components/NoSubscriptionMessage';
import styles from './styles.module.scss';

function EmptyPlaceholder({
  iconNode,
  title,
  description,
  className,
  iconClassName,
  titleClassName,
  descriptionClassName,
}) {
  return (
    <div className={classNames(styles.emptyPlaceholder, className)} data-spec="empty_placeholder">
      {iconNode && (
        <div className={classNames(styles.icon, iconClassName)}>{iconNode}</div>
      )}

      {title && (
        <div className={classNames(styles.title, titleClassName)}>{title}</div>
      )}

      {description && (
        <div className={classNames(styles.description, descriptionClassName)}>{description}</div>
      )}
    </div>
  );
}

EmptyPlaceholder.propTypes = {
  title: PropTypes.string,
  iconNode: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
};

export default EmptyPlaceholder;
export { NoSubscriptionMessage };
