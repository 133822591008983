import {
  mapLevel1,
  mapLevel2,
  mapLevel3,
  mapLevel4,
  mapLevel5,
  highlight,
  hover,
  stroke,
} from './settings.scss';

export const GEOGRAPHY_LINK = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/v0.12.x/examples/albers-usa/static/states.json';
export const MAP_LEGEND_RANGE = 4;
export const DEFAULT_INTERVAL = 1;
export const MAP_HEIGHT = 600;

export const PROJECTION_NAME = 'albersUsa';
export const PROJECTION_CONFIG = { scale: 1000 };

export const MAP_COLORS = [
  mapLevel1,
  mapLevel2,
  mapLevel3,
  mapLevel4,
  mapLevel5,
];

export const HIGHLIGHT_COLOR = highlight;
export const STROKE_COLOR = stroke;
export const HOVER_COLOR = hover;
