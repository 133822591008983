export const headerMobileHeight = 124;
export const headerDesktopHeight = 126;
export const tableHeaderHeight = 38;
export const navigationHeight = 60;
export const actionBarMobileHeight = 47;
export const actionBarDesktopHeight = 68;
export const productBarHeight = 35;
export const paginatorHeight = 75;
export const scrollbarHeight = 9;
export const scrollbarHeightMobile = 48;
