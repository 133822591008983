import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LINE_COLOR,
  DEFAULT_HEADER_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  billHeaderSection: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: DEFAULT_HEADER_COLOR,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
    flexDirection: 'row',
    width: '100%',
    padding: '5px 10px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: DEFAULT_LINE_COLOR,
    height: 35,
  },
});

export default styles;
