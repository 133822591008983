import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import Button from 'components/core/Button';
import { isOptionSelected } from 'components/core/Sort/utils';
import { sortOptionShape } from 'components/core/Sort/shapes';

import TooltipNode from '../TooltipNode';
import styles from './styles.module.scss';

function TooltipComponent({
  className,
  onSort,
  selectedSort,
  options,
  tooltipNodeClassName,
  optionClassName,
  tooltipClassName,
  placement,
  isLoading,
  defaultOption,
  labelClassName,
}) {
  const currentSort = options.find(option => isOptionSelected(option, selectedSort)) || defaultOption;

  function renderTooltipNode() {
    return (
      <TooltipNode
        onClick={onSort}
        selectedSort={currentSort}
        options={options}
        className={tooltipNodeClassName}
        optionClassName={optionClassName}
      />
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Tooltip
        content={renderTooltipNode()}
        className={classNames(styles.tooltipContainer, tooltipClassName)}
        placement={placement}
        zIndex={2}
        theme="light"
        trigger="click"
      >
        <span className={classNames(styles.trigger, isLoading && styles.isDisabled)}>
          <Button
            theme="none"
            disabled={isLoading}
            className={styles.sortButton}
          >
            <span className={classNames(styles.label, labelClassName)}>
              Sort by:
            </span>
            {currentSort.label}

            <div className={styles.icon}>
              <i className="fas fa-sort-down" />
            </div>
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

TooltipComponent.propTypes = {
  className: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(sortOptionShape).isRequired,
  tooltipNodeClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  placement: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  defaultOption: sortOptionShape,
  labelClassName: PropTypes.string,
};

export default TooltipComponent;
