import { useState, useEffect } from 'react';

function useSearchOptions({ searchValue: defaultSearchValue, searchType: defaultSearchType }) {
  const [searchValue, setSearchValue] = useState(defaultSearchValue || '');
  const [searchType, setSearchType] = useState(defaultSearchType);
  const [searchTypeAdvanced, setSearchTypeAdvanced] = useState(defaultSearchType);
  const [isDirty, setIsDirty] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isClickedItem, setIsClickedItem] = useState(false);

  useEffect(() => {
    setSearchValue(defaultSearchValue);
  }, [defaultSearchValue]);

  function handleStateOnClickItem() {
    setIsClickedItem(true);
    setIsDirty(false);
    setAllowSubmit(true);
  }

  function handleStateOnChange() {
    setIsClickedItem(false);
    setIsDirty(true);
    setAllowSubmit(true);
  }

  function handleStateOnClearAll() {
    setIsDirty(false);
    setAllowSubmit(false);
    setIsClickedItem(false);
  }

  function handleClickSearch() {
    setIsDirty(false);
    setIsClickedItem(false);
  }

  return {
    searchValue,
    searchType,
    searchTypeAdvanced,
    isDirty,
    isClickedItem,
    allowSubmit,
    setSearchValue,
    setSearchType,
    setSearchTypeAdvanced,
    setIsDirty,
    setAllowSubmit,
    setIsClickedItem,
    onClickItemHandleState: handleStateOnClickItem,
    onChangeHandleState: handleStateOnChange,
    onClearAllHandleState: handleStateOnClearAll,
    onClickSearch: handleClickSearch,
  };
}

export default useSearchOptions;
