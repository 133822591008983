import { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import {
  fetchReplies,
  saveReply,
  updateReply,
  deleteReply,
  transformCommentForUI,
} from '../../api';

const useReplies = ({
  parentComment,
  onBeforeLoadReplies,
  onLoadReplies,
  onSaveReply,
  onUpdateReply,
  onDeleteReply,
  onOpenParentReply,
  shouldLoadAllComments,
}) => {
  const { user, repliesCount: parentRepliesCount, id: parentId } = parentComment;

  const newReplyRef = useRef(null);
  const [replies, setReplies] = useState([]);
  const [repliesCount, setRepliesCount] = useState(parentRepliesCount);
  const appendReplies = newReplies => setReplies([...replies, ...newReplies]);
  const prependReplies = newReplies => setReplies([...newReplies, ...replies]);

  const [hasMoreToLoad, setHasMoreToLoad] = useState(!!parentRepliesCount);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [replyUserMention, setReplyUserMention] = useState();

  const handleCancelReplyMention = () => setReplyUserMention('');

  const handleLoadReplies = () => {
    setIsLoading(true);
    onBeforeLoadReplies && onBeforeLoadReplies();
    // in order to avoid problem with duplicates after fetching due to new replies between two fetches,
    // we don't use pagination, instead we fetch the replies older than the oldest comment already loaded
    const oldestCreatedAt = isEmpty(replies) ? undefined : replies[0].createdAt;

    return fetchReplies({
      parentId,
      createdAtBefore: oldestCreatedAt,
      shouldLoadAllComments,
    })
      .then(
        (response) => {
          const { data: newReplies, next_page: nextPage } = response;

          setHasMoreToLoad(!!nextPage);

          prependReplies(newReplies.map(transformCommentForUI).reverse());

          setIsLoading(false);
          onLoadReplies && onLoadReplies(response);
        }
      );
  };

  useEffect(() => {
    shouldLoadAllComments && handleLoadReplies();
  }, [shouldLoadAllComments]);

  const handleSaveReply = ({ text, mentions }) => {
    setIsSaving(true);

    return saveReply(parentId, text)
      .then(
        (response) => {
          appendReplies([transformCommentForUI(response)]);
          setIsSaving(false);
          setIsReplyOpen(false);
          setRepliesCount(repliesCount + 1);

          onSaveReply && onSaveReply(response, mentions);
        }
      );
  };

  const handleUpdateReply = reply => updateReply(parentId, reply)
    .then(
      (response) => {
        const responseReply = transformCommentForUI(response);

        const replaceReply = currentReply => (
          currentReply.id === responseReply.id ? responseReply : currentReply
        );
        setReplies(replies.map(replaceReply));

        onUpdateReply && onUpdateReply(response, reply.mentions);
      }
    );

  const handleDeleteReply = replyId => deleteReply(parentId, replyId)
    .then(
      (response) => {
        setReplies(replies.filter(
          currentReply => (currentReply.id !== replyId)
        ));
        setRepliesCount(repliesCount - 1);
        onDeleteReply && onDeleteReply(response);
      }
    );

  const handleReplyClick = (userMention) => {
    if (onOpenParentReply) {
      onOpenParentReply(`@[${user.name} (${user.email})](colleague:${user.id})`);
      return;
    }

    if (replies.length === 0 && hasMoreToLoad) {
      handleLoadReplies();
    }

    newReplyRef.current && newReplyRef.current.focus();
    setIsReplyOpen(true);
    setReplyUserMention(userMention);
  };

  return {
    replies,
    newReplyRef,
    repliesCount,
    hasMoreToLoad,
    isSaving,
    isLoading,
    isReplyOpen,
    replyUserMention,
    handleLoadReplies,
    handleSaveReply,
    handleUpdateReply,
    handleDeleteReply,
    handleReplyClick,
    handleCancelReplyMention,
  };
};

export default useReplies;
