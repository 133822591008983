import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, sortBy, snakeCase } from 'lodash';

import useCheckedItems from 'components/core/AdvancedSearch/utils/useCheckedItems';
import Label from 'components/core/Label';
import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';
import ErrorMessage from 'components/core/ErrorMessage';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import { BaseOption } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function BaseLabelGroup({
  id,
  value,
  onChange,
  className,
  title,
  placeholder,
  values,
  name,
  withSelectAll,
  titles,
  reqTransform,
}) {
  const [error, setError] = useState({});
  const valuesKeys = Object.keys(values);
  const valueKeys = Object.keys(value);

  function handleErrorMessage(key, data) {
    const message = isEmpty(data) ? 'This field is required' : '';

    setError(prevProps => ({ ...prevProps, [key]: message }));
  }

  function handleChangeDataObject(itemId, items, itemValue = []) {
    const data = {};

    items.forEach((item) => {
      data[item] = !isEmpty(value[item]) ? value[item] : itemValue;
    });

    onChange(itemId, data);
    setError({});
  }

  function handleChangeInput(key) {
    return (data) => {
      value[key] = data;

      onChange(id, value);
      handleErrorMessage(key, data);
    };
  }

  const { checkedItems, onCheck, onCheckAll } = useCheckedItems(
    id,
    valueKeys,
    handleChangeDataObject,
    valuesKeys
  );

  function renderBaseOption(key) {
    const onBlur = () => handleErrorMessage(key, value[key]);

    return (
      <Fragment>
        <BaseOption
          {...titles[key]}
          {...(reqTransform && { reqTransform: reqTransform(snakeCase(key)) })}
          shouldOverrideValue
          overrideValue={value[key]}
          onChange={handleChangeInput(key)}
          className={styles.option}
          onBlur={onBlur}
          isRequired
        />
        <ErrorMessage message={error[key]} />
      </Fragment>
    );
  }

  return (
    <div className={className}>
      <Label>{title}</Label>
      <TextInputPlaceholder className={styles.placeholder}>{placeholder}</TextInputPlaceholder>

      <LabelGroup className={styles.labelParent}>
        {withSelectAll && (
          <Checkbox
            label="Select/Deselect All"
            onChange={onCheckAll}
            isChecked={isEqual(sortBy(checkedItems), sortBy(valuesKeys))}
          />
        )}

        {valuesKeys.map(key => (
          <div key={`${id}-${key}`} className={styles.labelGroup}>
            <Checkbox
              label={values[key]}
              value={key}
              name={name}
              onChange={onCheck}
              isChecked={checkedItems.includes(key)}
            />

            {checkedItems.includes(key) && renderBaseOption(key)}
          </div>
        ))}
      </LabelGroup>
    </div>
  );
}

BaseLabelGroup.defaultProps = {
  withSelectAll: false,
};

BaseLabelGroup.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  withSelectAll: PropTypes.bool,
  titles: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  values: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  reqTransform: PropTypes.func,
};

export default BaseLabelGroup;
