import React from 'react';
import PropTypes from 'prop-types';

import ActionBar from 'components/core/ActionBar';
import withMap from 'components/core/Map/utils/withMap';

import MapType from '../MapType';
import Heatmap from '../Heatmap';
import styles from './styles.module.scss';

function MobileView({ filters, withHeatmap, description, activeFilters, onFilterClick, title }) {
  const mapTypeKey = 'mapType';
  const heatmapKey = 'displayType';

  return (
    <ActionBar
      title={title}
      selectedItems={[1]}
      className={styles.actionBar}
      description={description}
    >
      <MapType
        {...filters[mapTypeKey]}
        filterKey={mapTypeKey}
        onFilterClick={onFilterClick(mapTypeKey)}
        selectedFilter={activeFilters[mapTypeKey]}
      />
      {withHeatmap && (
        <Heatmap
          {...filters[heatmapKey]}
          filterKey={heatmapKey}
          onFilterClick={onFilterClick(heatmapKey)}
          selectedFilter={activeFilters[heatmapKey]}
        />
      )}
    </ActionBar>
  );
}

MobileView.defaultProps = {
  title: 'Layers',
  description: {
    default: 'Hide options',
  },
};

MobileView.propTypes = {
  filters: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  withHeatmap: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.object,
};

export default withMap(MobileView);
