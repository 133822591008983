import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getAppliedFiltersLength } from 'components/core/AdvancedSearch/utils';
import TableView from 'components/core/PageLookup/components/TableView';
import SearchHeader from 'components/core/SearchHeader';
import CommonActions from 'components/core/SearchHeader/components/CommonActions';
import AdvancedSearch from 'components/core/AdvancedSearch';
import SearchBox from 'components/core/SearchBox';
import { withTrack } from 'utils/tracking/utils';
import { settingsButtonOptionsShape } from 'components/core/PageHeader';

import { advancedSearchShape, mapDataShape } from '../../utils/shapes';
import usePageLookup from '../../utils/usePageLookup';
import withLookup from '../../utils/withLookup';
import MapView from '../MapView';
import useAdvancedSearch from './utils/useAdvancedSearch';
import useCustomToggleView from './utils/useCustomToggleView';
import usePageViewTracking from './utils/usePageViewTracking';
import styles from './styles.module.scss';

function Container({
  pageView,
  dataProps,
  dataLookup,
  dataOptions,
  tableData,
  actions,
  mapData,
  advancedSearch,
  notifications,
  renderCustomActions,
  useCustomActions,
  tracking,
  HeaderButtons,
  renderHeaderButtons,
  customToggleViewProps: customToggleView,
  withSearchDisclaimer,
  disclaimerProps,
  settingsButtonOptions,
}) {
  const {
    isVisible,
    isSaveNameEnabled,
    advancedContainerClassName,
    lastSearchId,
    setLastSearchId,
    transformSearchesForUI,
    saveInRecentSearch,
    filtersCountFn,
  } = advancedSearch;

  const { fetchData, resetData, initialFetch } = actions;
  const { ui, allData, appliedFilters, isMobile, disabledActionsText } = dataLookup;
  const { optionsComponent, emptyState } = dataOptions;
  const { isLoadingData } = ui;
  const appliedFiltersLength = filtersCountFn ? filtersCountFn(appliedFilters.options) : getAppliedFiltersLength(appliedFilters, emptyState);
  const {
    customToggleViewProps,
    onCloseCustomView,
  } = useCustomToggleView({ customToggleViewProps: customToggleView });

  const advancedOptionsProps = useAdvancedSearch({
    dataProps,
    dataOptions,
    advancedSearch,
    notifications,
    appliedFilters,
    onCloseCustomView,
    lastSearchId,
    setLastSearchId,
    tableData,
  });

  const {
    searchValue,
    onSearchValueChange,
    options,
    onChangeSearchValue,
    onSubmit,
    searchData,
    onClearAll,
    isSearchOpen,
    isAdvancedSearchOpen,
    onToggleAdvancedSearch,
    onSearchToggle,
    onRemoveItem,
    onSearch,
    onSaveItem,
    onClickItem,
    onChangeFilter,
    onClearAllSearchParams,
    getSearchParams,
  } = advancedOptionsProps;

  usePageLookup({
    setLastSearchId,
    initialFetch,
    fetchData,
    notifications,
    resetData,
    dataProps,
    emptyState,
    transformSearchesForUI,
    searchValue,
    onSearchOptionsChange: onClickItem,
    onSearchValueChange,
    saveInRecentSearch,
    onClearAllSearchParams,
    getSearchParams,
  });

  const {
    trackRecentSearchSavedItem,
    trackSaveSearchDeletedItem,
    trackResultSearched,
    trackMapViewVisited,
    trackTableViewVisited,
  } = tracking;

  usePageViewTracking({
    pageView,
    trackMapViewVisited,
    trackTableViewVisited,
  });

  function getAdvancedSearchProperties() {
    if (!isVisible) return {};

    const advancedSearchContent = (
      <div
        id="advancedSearchContainer"
        className={advancedContainerClassName}
      >
        <AdvancedSearch
          {...advancedSearch}
          {...advancedOptionsProps}
          onSearch={withTrack(onSearch, trackResultSearched)}
          onSaveItem={withTrack(onSaveItem, trackRecentSearchSavedItem)}
          onRemoveItem={withTrack(onRemoveItem, trackSaveSearchDeletedItem)}
          optionsNode={optionsComponent({ data: options, isMobile, ...dataProps })}
          isSaveNameEnabled={isSaveNameEnabled}
          withSearchDisclaimer={withSearchDisclaimer}
          disclaimerProps={disclaimerProps}
        />
      </div>
    );

    return ({
      isAdvancedSearchOpen,
      advancedSearchContent,
      handleAdvancedSearchToggle: onToggleAdvancedSearch,
    });
  }

  const commonActionProps = {
    ...tableData,
    ...actions,
    ...dataLookup,
    data: allData,
    dataProps,
    appliedSearches: searchData,
    ui,
    tracking,
    HeaderButtons,
    renderHeaderButtons,
  };

  const customActionProps = {
    ...actions,
    ...dataLookup,
    ...useCustomActions,
    tracking,
    data: allData,
    dataProps,
    appliedSearches: searchData,
    ui,
  };

  const tableColumnOptions = {
    onChangeFilter,
  };

  const headerActions = <CommonActions {...commonActionProps} />;
  const customActions = () => renderCustomActions(customActionProps);

  const actionsContent = {
    ...(renderCustomActions && { actionsContent: customActions }),
    ...(!renderCustomActions && { rightContent: headerActions }),
  };

  const searchContent = (
    <SearchBox
      withIcon
      withButton
      value={searchValue}
      onChange={onChangeSearchValue}
      onSubmit={withTrack(onSubmit, trackResultSearched)}
      withSearchDisclaimer={withSearchDisclaimer}
      disclaimerProps={disclaimerProps}
    />
  );

  return (
    <>
      <header className={styles.container}>
        <SearchHeader
          {...getAdvancedSearchProperties()}
          {...actionsContent}
          customToggleViewProps={customToggleViewProps}
          isSearchOpen={isSearchOpen}
          searchContent={searchContent}
          appliedFiltersLength={appliedFiltersLength}
          handleSearchToggle={onSearchToggle}
          disabledActionsText={disabledActionsText}
          onClearAll={onClearAll}
          pageView={pageView}
          isLoading={isLoadingData}
          settingsButtonOptions={settingsButtonOptions}
        />
      </header>

      <div role="main">
        <TableView columnOptions={tableColumnOptions} />
        {!isEmpty(mapData) && <MapView />}
      </div>
    </>
  );
}

Container.defaultProps = {
  tracking: {},
  customToggleViewProps: {},
};

/*
  Note: prop types definition here might not be accurate since there's
  extensive usage of the spread operator
*/
Container.propTypes = {
  dataProps: PropTypes.shape({
    yearsRange: PropTypes.arrayOf(PropTypes.string),
  }),
  dataLookup: PropTypes.shape({
    isMobile: PropTypes.bool,
    allData: PropTypes.arrayOf(PropTypes.object),
    isDesktopLarge: PropTypes.bool,
    areEmptyEmails: PropTypes.bool,
    appliedFilters: PropTypes.shape({
      searchValue: PropTypes.string,
      options: PropTypes.shape({}),
    }),
    disabledActionsText: PropTypes.node,
    ui: PropTypes.shape({
      isInitialLoading: PropTypes.bool,
      isSavingSearches: PropTypes.bool,
      toResetSelectedItems: PropTypes.bool,
      isLoadingAllEmails: PropTypes.bool,
      isLoadingData: PropTypes.bool,
      isLoadingExports: PropTypes.bool,
      isRecentSearchesLoading: PropTypes.bool,
      isRemovingItem: PropTypes.bool,
      isSavedSearchesLoading: PropTypes.bool,
    }),
  }),
  mapData: mapDataShape,
  dataOptions: PropTypes.shape({
    useOptions: PropTypes.func,
    optionsComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
    emptyState: PropTypes.shape({
      searchValue: PropTypes.string,
      options: PropTypes.shape({
        legislator: PropTypes.arrayOf(PropTypes.object),
      }),
      omit: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  advancedSearch: advancedSearchShape,
  tableData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  actions: PropTypes.shape({
    submitAllExport: PropTypes.func,
    fetchData: PropTypes.func,
    resetData: PropTypes.func,
    initialFetch: PropTypes.func,
  }),
  pageView: PropTypes.shape({
    pageView: PropTypes.string,
    isTablePageView: PropTypes.bool,
    onPageViewChange: PropTypes.func,
  }),
  renderCustomActions: PropTypes.func,
  notifications: PropTypes.func,
  tracking: PropTypes.shape({
    trackRecentSearchSavedItem: PropTypes.func,
    trackSaveSearchDeletedItem: PropTypes.func,
    trackSearchedItems: PropTypes.func,
    trackAllResultsEmailed: PropTypes.func,
    trackResultEmailed: PropTypes.func,
    trackAllResultsExported: PropTypes.func,
    trackResultsExported: PropTypes.func,
    trackResultSearched: PropTypes.func,
    trackTableViewVisited: PropTypes.func,
    trackMapViewVisited: PropTypes.func,
  }),
  useCustomActions: PropTypes.shape({
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    toggleOpen: PropTypes.func,
    paginator: PropTypes.shape({
      current: PropTypes.number,
      pageSize: PropTypes.number,
      total: PropTypes.number,
      totalCount: PropTypes.number,
    }),
  }),
  HeaderButtons: PropTypes.elementType,
  customToggleViewProps: PropTypes.shape({
    viewContent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
  }),
  renderHeaderButtons: PropTypes.func,
  withSearchDisclaimer: PropTypes.bool,
  disclaimerProps: PropTypes.shape({
    withButtons: PropTypes.bool,
    content: PropTypes.node,
    isDisabled: PropTypes.bool,
    title: PropTypes.node,
    modalTheme: PropTypes.string,
    modalClassName: PropTypes.string,
  }),
  settingsButtonOptions: settingsButtonOptionsShape,
};

export default withLookup(Container);
