import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function AlertType({ title, content, description, className }) {
  return (
    <div className={classNames(styles.card, className)}>
      {title && <div className={styles.title}>{title}</div>}
      {content && <div>{content}</div>}
      {description && <div>{description}</div>}
    </div>
  );
}

AlertType.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
};

export default AlertType;
