// @ts-nocheck
import React, { Fragment, ReactNode } from 'react';
import { map, filter, includes, get } from 'lodash';

import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';
import { CheckEvent } from 'utils/types/events';

import SelectAll from '../SelectAll';
import filterStyles from '../styles.module.scss';
import { CheckboxWithSubItemsProps, CheckboxItem, SubItems, SubItem } from './utils/types';
import SelectAllWithSubItems from './components/SelectAllWithSubItems';
import Item from './components/Item';
import styles from './styles.module.scss';

function CheckboxWithSubItems({
  withoutBorders,
  withStars,
  withSelectAll,
  id,
  items,
  values,
  onChange,
  isSingleSelection,
  allValues,
}: CheckboxWithSubItemsProps): ReactNode {
  const handleMultiChange = (checked: boolean, item: CheckboxItem) => {
    const filterValue = checked ? [...values, item.id] : filter(values, val => val !== item.id);
    const subFieldId = get(item, 'subItems.id');

    const getSubField = () => {
      if (!subFieldId) return null;

      return {
        [subFieldId]: checked ? map(item.subItems.options, 'value') : [],
      };
    };

    onChange(id, filterValue, { optionalFields: getSubField() });
  };

  const handleChange = (item: CheckboxItem) => (
    ({ target: { checked } }: CheckEvent) => {
      if (isSingleSelection) {
        onChange(id, checked ? item.id : '');
      } else {
        handleMultiChange(checked, item);
      }
    }
  );

  const renderLabel = (labelProps: { label: ReactNode, isChecked: boolean }) => {
    const { label } = labelProps || {};

    if (withStars) {
      return <Item {...labelProps} />;
    }

    return label;
  };

  const handleSubItemChange = (subId: string, value: string) => ({ target }: CheckEvent) => {
    const subValues = allValues && allValues[subId] || [];

    const handleCheck = (checked: boolean) => (
      checked ? [...subValues, value] : filter(subValues, subValue => subValue !== value)
    );

    onChange(subId, handleCheck(target.checked));
  };

  const renderSubItems = (subItems: SubItems) => {
    const subItemValues = allValues && allValues[subItems.id];

    const renderSubItem = (subItem: SubItem) => {
      const isChecked = includes(subItemValues, subItem.value);

      return (
        <Checkbox
          key={subItem.value}
          isChecked={isChecked}
          label={subItem.label}
          onChange={handleSubItemChange(subItems.id, subItem.value)}
          className={styles.item}
        />
      );
    };

    return (
      <div className={styles.subItems}>
        <h5 className={styles.title}>{subItems.title}</h5>
        {subItems.withSelectAll && Array.isArray(subItemValues) && (
          <SelectAll
            id={subItems.id}
            values={subItemValues}
            items={subItems.options}
            selector="value"
            onChange={onChange}
            className={styles.item}
          />
        )}
        {subItems.options.map(renderSubItem)}
      </div>
    );
  };

  const renderCheckbox = (item: CheckboxItem) => {
    const { id: checkboxId, label, disabled, subItems } = item || {};
    const isChecked = isSingleSelection
      ? values === checkboxId
      : includes(values, checkboxId);
    const showSubItems = isChecked && subItems;

    return (
      <Fragment key={checkboxId}>
        <Checkbox
          isDisabled={disabled}
          isChecked={isChecked}
          label={renderLabel({ label, isChecked })}
          className={withStars && filterStyles.checkboxWithStars}
          onChange={handleChange(item)}
        />
        {showSubItems && renderSubItems(subItems)}
      </Fragment>
    );
  };

  return (
    <LabelGroup
      containerClassName={filterStyles.item}
      className={withoutBorders && filterStyles.checkboxWithoutBorders}
    >
      {withSelectAll && Array.isArray(values) && (
        <SelectAllWithSubItems
          id={id}
          values={values}
          items={items}
          selector="id"
          onChange={onChange}
        />
      )}
      {map(items, renderCheckbox)}
    </LabelGroup>
  );
}

CheckboxWithSubItems.defaultProps = {
  isSingleSelection: false,
};

export default CheckboxWithSubItems;
