import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LINE_COLOR,
  DEFAULT_TEXT_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  table: {
    boxSizing: 'border-box',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowHeader: {
    backgroundColor: DEFAULT_LINE_COLOR,
    color: DEFAULT_TEXT_COLOR,
    fontSize: 11,
    alignItems: 'flex-start',
  },
  columnHeader: {
    padding: '8px 15px',
  },
  subLabel: {
    fontWeight: 'bold',
    padding: '0 15px 8px',
  },
  rowText: {
    color: DEFAULT_TEXT_COLOR,
    fontSize: 10,
    padding: '8px 15px',
  },
  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: DEFAULT_LINE_COLOR,
  },
});

export default styles;
