import { useEffect } from 'react';

function initBeforeUnload() {
  window.onbeforeunload = undefined;
}

function onBeforeUnload(onContinue) {
  window.onbeforeunload = () => {
    onContinue && onContinue();
    return '';
  };
}

function useConfirmBeforeLeave({ enabled, onContinue }) {
  useEffect(() => {
    enabled
      ? onBeforeUnload(onContinue)
      : initBeforeUnload();
  }, [enabled]);

  useEffect(() => initBeforeUnload, []);
}

export default useConfirmBeforeLeave;
