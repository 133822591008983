import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Title from 'components/core/Profile/components/Title';

import titles from '../../utils/titles';
import ReportTitlePreview from './components/ReportTitlePreview';
import styles from '../styles.module.scss';

function Preview({ title, description, previewComponent }) {
  const titleClassName = {
    className: styles.container,
    titleClassName: styles.title,
    descriptionClassName: styles.description,
  };

  return (
    <Fragment>
      <Title
        title={title}
        description={description}
        {...titleClassName}
      />
      {previewComponent}
    </Fragment>
  );
}

Preview.defaultProps = {
  title: titles.preview.title,
  description: titles.preview.description,
};

Preview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  previewComponent: PropTypes.node.isRequired,
};

export { ReportTitlePreview };
export default Preview;
