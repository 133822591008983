import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';
import Link from 'components/core/Link';
import { getPhoneLink } from 'utils/socialMedia/links';

import styles from '../styles.module.scss';

function PhoneColumn({ keyName, item, onClick }) {
  const { phone, phones = [] } = item;
  const dataList = phone ? [phone] : phones;
  const handleClick = phoneItem => () => onClick(phoneItem);

  function renderPhone(phoneItem, isTooltipItem) {
    return (
      <Link
        size="none"
        theme="link"
        className={classNames(styles.link, isTooltipItem && styles.tooltipLink)}
        href={getPhoneLink(phoneItem)}
        onClick={handleClick(phoneItem)}
        displayExternalIcon={false}
      >
        {phoneItem}
      </Link>
    );
  }

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={dataList}
      isEmptyData={isEmpty(dataList)}
      getItem={renderPhone}
    />
  );
}

PhoneColumn.defaultProps = {
  item: {},
  onClick: () => {},
};

PhoneColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    phone: PropTypes.string,
    phones: PropTypes.arrayOf(PropTypes.string),
  }),
  onClick: PropTypes.func,
};

export default PhoneColumn;
