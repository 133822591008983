import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import Card from 'components/core/Card';

import styles from './styles.module.scss';

function BaseCard({ children, className, displayCheckbox, onChange, isChecked }) {
  return (
    <Card className={classNames(styles.container, className)}>
      <article className={styles.article}>
        {displayCheckbox && (
          <div className={styles.checkboxContainer}>
            <Checkbox
              className={styles.checkbox}
              isChecked={isChecked}
              onChange={onChange}
            />
          </div>
        )}
        <div className={styles.content}>
          {children}
        </div>
      </article>
    </Card>
  );
}

BaseCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  displayCheckbox: PropTypes.bool,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default BaseCard;
