import {
  trackCongressionalStafferEmailed,
  trackCongressionalStafferVisited,
  trackCongressionalStaffersPageVisited,
  trackCongressionalStaffersResultsSorted,
} from 'utils/tracking/profile';

export function getTrackingActions(context) {
  return {
    trackStafferEmailed: trackCongressionalStafferEmailed(context),
    trackStafferVisited: trackCongressionalStafferVisited(context),
    trackStaffersPageVisited: trackCongressionalStaffersPageVisited(context),
    trackStaffersResultsSorted: trackCongressionalStaffersResultsSorted(context),
  };
}
