import React from 'react';
import PropTypes from 'prop-types';

import { BasePage, DocumentFooter, DocumentTitle } from 'components/core/ExportDocument';

import FooterName from './FooterName';

function PageLayout({
  documentTitle,
  footerName,
  children,
  isEmptyData,
  logoUrl,
  isPageNumbersVisible,
}) {
  if (isEmptyData) {
    return null;
  }

  return (
    <BasePage>
      <DocumentTitle title={documentTitle} logoUrl={logoUrl} />
      <DocumentFooter
        isPageNumbersVisible={isPageNumbersVisible}
        leftContent={<FooterName name={footerName} />}
      />
      {children}
    </BasePage>
  );
}

PageLayout.defaultProps = {
  documentTitle: 'Legislator Report',
  isPageNumbersVisible: true,
};

PageLayout.propTypes = {
  documentTitle: PropTypes.string,
  footerName: PropTypes.string,
  children: PropTypes.node,
  isEmptyData: PropTypes.bool,
  logoUrl: PropTypes.string,
  isPageNumbersVisible: PropTypes.bool,
};

export default PageLayout;
