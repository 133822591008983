import { CSV_LABEL, CSV_ID } from 'utils/constants/export';
import {
  defaultDisclaimerProps as defaultProfileDisclaimerProps,
} from 'components/core/Profile/utils/constants';

import styles from '../styles.module.scss';

export const EXPORT_OPTIONS = [{ label: CSV_LABEL, value: CSV_ID, defaultChecked: true }];

export const SEARCH_ID_PARAM = 'searchId';
export const SEARCH_QUERY_PARAM = 'search';
export const SEARCH_SORT_PARAM = 'sort';
export const SEARCH_DIRECTION_PARAM = 'direction';
export const SEARCH_PAGE_PARAM = 'page';

export const SEARCH_PARAMS = [
  SEARCH_QUERY_PARAM,
  SEARCH_ID_PARAM,
  SEARCH_SORT_PARAM,
  SEARCH_DIRECTION_PARAM,
  SEARCH_PAGE_PARAM,
];

export const SORT_PARAMS = [SEARCH_SORT_PARAM, SEARCH_DIRECTION_PARAM];

export const defaultDisclaimerProps = {
  ...defaultProfileDisclaimerProps,
  modalClassName: styles.modal,
};
