import React, { ReactNode } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Avatar from 'components/core/Avatar';
import TooltipTitle from './TooltipTitle';
import styles from './styles.module.scss';

interface Props {
  isPresentAvatar: boolean;
  avatarUrl: string;
  avatarName: string;
  description: string | ReactNode;
  title: string | ReactNode;
  leadershipRole: ReactNode;
  isFullWidthTitle: boolean;
  titleClassName: string;
  avatarTitleClassName: string;
  descriptionClassName: string;
  tooltipContent: ReactNode;
  tooltipClassName: string;
  isMobile: boolean;
}

function TitleContainer({
  isPresentAvatar,
  avatarUrl,
  avatarName,
  description,
  title,
  leadershipRole,
  isFullWidthTitle,
  titleClassName,
  avatarTitleClassName,
  descriptionClassName,
  tooltipContent,
  tooltipClassName,
  isMobile,
}: Props) {
  const titlesClassNames = classNames(
    styles.titleContainer,
    { [styles.fullWidthTitle]: isFullWidthTitle },
    titleClassName,
  );

  const avatarTitleClassNames = classNames(styles.avatarTitleContainer, avatarTitleClassName);

  const isMobileV2 = gon.isNavigationV2Enabled && isMobile;
  const descriptionClassNames = classNames(styles.descriptionContainer, descriptionClassName);

  return (
    <div id="subproduct-title" className={titlesClassNames}>
      <div className={avatarTitleClassNames}>
        {isPresentAvatar && avatarUrl && (
          <Avatar photoUrl={avatarUrl} name={avatarName} className={styles.avatar} />
        )}
        {isMobileV2 ? !isMobile && <h1 className="subproduct-title"> {title}</h1> : <h1 className="subproduct-title"> {title}</h1>}
        {leadershipRole}
        <TooltipTitle className={tooltipClassName} tooltipContent={tooltipContent} />
      </div>

      {!isEmpty(description) && <div className={descriptionClassNames}>{description}</div>}
    </div>
  );
}

export default TitleContainer;
