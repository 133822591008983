import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import LabelGroup from 'components/core/LabelGroup';
import RadioBox from 'components/core/RadioBox';

import { DEFAULT_FREQUENCIES } from './constants';
import styles from './styles.module.scss';

const caseTransformers = {
  uppercase: styles.uppercase,
  lowercase: styles.lowercase,
  capitalize: styles.capitalize,
  sentenceCase: styles.none,
};

function FrequencyOptions({
  selectedOption,
  options,
  onChange,
  isMobile,
  className,
  isDisabled,
  buttonClassName,
  labelCase,
}) {
  const labelClassName = caseTransformers[labelCase];
  const buttonClassNames = option => classNames(
    option === selectedOption && styles.isActive,
    buttonClassName,
    labelClassName
  );
  const isChecked = option => option === selectedOption;
  const handleChange = (option, label) => () => onChange(option, label);

  if (isMobile) {
    return (
      <LabelGroup className={styles.labelGroup}>
        {options.map(({ value, label }) => (
          <RadioBox
            key={value}
            label={label}
            theme="default"
            disabled={isDisabled}
            className={classNames(buttonClassName, labelClassName)}
            onChange={handleChange(value, label)}
            checked={(isChecked(value))}
          />
        ))}
      </LabelGroup>
    );
  }

  return (
    <ButtonGroup theme="light" className={classNames(styles.buttonGroup, className)}>
      {options.map(({ value, label }) => (
        <Button
          key={value}
          theme="default"
          disabled={isDisabled}
          onClick={handleChange(value, label)}
          className={buttonClassNames(value)}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

FrequencyOptions.defaultProps = {
  options: DEFAULT_FREQUENCIES,
  labelCase: 'uppercase',
};

FrequencyOptions.propTypes = {
  isMobile: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedOption: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  onChange: PropTypes.func.isRequired,
  labelCase: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'sentenceCase']),
};

export default FrequencyOptions;
