export const RECORD_TYPES = {
  STATE_BILL: 'state_bill',
  FEDERAL_BILL: 'federal_bill',
  STATE_REGULATIONS: 'state_regulation',
};

export const EMPTY_SUMMARY = {
  id: null,
  content: '',
  updatedAt: null,
};

export const LIMIT = 5000;
