import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, map } from 'lodash';

import { getBackToParams } from 'components/core/Link/components/BackTo/utils';
import Link from 'components/core/Link';
import Badge from 'components/core/Badge';
import { mentionShape, selectedFeedShape } from 'components/News/utils/shapes';
import { MENTIONS_BASE_URL } from 'components/core/News/utils/constants';

import styles from './styles.module.scss';

function Mentions({
  selectedFeed,
  mentions,
  className,
  customButton,
  isExternalComponent,
  isRouterLink,
  isContainerless,
  badgeClassName,
  backTo,
  backToQuery,
}) {
  if (isEmpty(mentions)) {
    return null;
  }

  function renderMention(mention, index) {
    const { text, types } = mention || {};
    const params = `?text=${text}&types=${types}${getBackToParams({ backTo, backToQuery })}`;
    const mentionLink = {
      pathname: MENTIONS_BASE_URL,
      search: params,
      state: { selectedFeed },
    };
    const href = `${MENTIONS_BASE_URL}${params}`;

    const content = <Badge className={classNames(styles.badge, badgeClassName)}>{text}</Badge>;
    const commonProps = {
      key: `${text}-${index}`,
      className: styles.mention,
      theme: 'light',
      size: 'none',
    };

    if (isExternalComponent) {
      return (
        <Link
          {...commonProps}
          isExternal
          href={href}
          displayExternalIcon={false}
        >
          {content}
        </Link>
      );
    }

    return (
      <Link
        {...commonProps}
        to={mentionLink}
        href={href}
        isRouterLink={isRouterLink}
      >
        {content}
      </Link>
    );
  }

  const content = (
    <>
      {map(mentions, renderMention)}
      {customButton}
    </>
  );

  if (isContainerless) {
    return content;
  }

  return (
    <div className={classNames(styles.mentions, className)}>
      {content}
    </div>
  );
}

Mentions.defaultProps = {
  isExternalComponent: false,
  isRouterLink: false,
  isContainerless: false,
};

Mentions.propTypes = {
  mentions: PropTypes.arrayOf(mentionShape),
  className: PropTypes.string,
  selectedFeed: selectedFeedShape,
  customButton: PropTypes.element,
  isExternalComponent: PropTypes.bool,
  isRouterLink: PropTypes.bool,
  isContainerless: PropTypes.bool,
  badgeClassName: PropTypes.string,
  backTo: PropTypes.string,
  backToQuery: PropTypes.string,
};

export default Mentions;
