// Themes
export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';
export const BLUE_THEME = 'blue';

// Sizes
export const MEDIUM_SIZE = 'medium';
export const SMALL_SIZE = 'small';
export const EXTRA_SMALL_SIZE = 'xSmall';
export const BIG_SIZE = 'big';
