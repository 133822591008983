import { useState } from 'react';
import { values as _values, isEmpty as _isEmpty, omitBy } from 'lodash';
import Api from './Api';

const DEFAULT_PAGINATION = {
  current: 1,
  total: 1,
  totalCount: 0,
  perPage: 10,
};

function useAdvancedSearch({ initialValues, slug, transformer }) {
  const [values, setValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const [savedSearches, setSavedSearches] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [savedSearchesPagination, setSavedSearchesPagination] = useState(DEFAULT_PAGINATION);

  const api = Api({ slug, transformer });

  const loadSavedSearches = (page = 1) => {
    setIsLoading(true);

    api.loadSavedSearches({ page })
      .then(({ searches, pagination }) => {
        setSavedSearches(searches);
        setSavedSearchesPagination(pagination);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadRecentSearches = () => {
    setIsLoading(true);

    api.loadRecentSearches()
      .then(({ searches }) => {
        setRecentSearches(searches);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isEmpty = () => {
    const searchValues = _values(values);

    return searchValues.every(_isEmpty);
  };

  const create = () => {
    if (isEmpty(values)) {
      return null;
    }

    return api.create(omitBy(values, _isEmpty));
  };

  const save = search => (
    api.save(search)
      .then(loadRecentSearches)
  );

  const remove = search => (
    api.remove(search)
      .then(() => loadSavedSearches())
  );

  const click = ({ search_params: searchParams } = {}) => {
    setValues(omitBy(searchParams, _isEmpty));
  };

  const changeSavedSearchesPage = (page) => {
    setSavedSearchesPagination({
      ...savedSearchesPagination,
      current: page,
    });

    loadSavedSearches(page);
  };

  return {
    isLoading,
    search: {
      create,
      save,
      remove,
      click,
    },
    options: {
      values,
      onChange: setValues,
    },
    recentSearches: {
      searches: recentSearches,
      load: loadRecentSearches,
    },
    savedSearches: {
      searches: savedSearches,
      load: loadSavedSearches,
      pagination: {
        ...savedSearchesPagination,
        change: changeSavedSearchesPage,
      },
    },
  };
}

export default useAdvancedSearch;
