import moment from 'moment';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export default function (value) {
  if (typeof value === 'string') {
    return moment(value, GLOBAL_DATE_FORMAT);
  }

  return value;
}
