import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  LEGISLATOR_TYPE,
  COMMITTEE_TYPE,
  OTHER_TYPE,
} from 'redux/staffers/common/constants';
import LinkToLegislator from 'components/shared/link-to-legislator';
import Link from 'components/core/Link';
import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';

import columnStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const OTHER_VALUE = 'Other';

function OfficeItem({ keyName, value, tracking, item, withOtherType, legislationLevel }) {
  const {
    trackStafferProfileLegislatorVisited,
    trackStafferProfileCommitteeVisited,
  } = tracking;

  const { committee, legislator, employment } = item;

  function getLegislatorLink() {
    const noOffice = isEmpty(committee) && isEmpty(legislator) && isEmpty(employment);

    if (noOffice && withOtherType) {
      return OTHER_VALUE;
    }

    if (isEmpty(value) || value === EMPTY_VALUE) {
      return EMPTY_VALUE;
    }

    if (value.type === LEGISLATOR_TYPE) {
      return (
        <LinkToLegislator
          legislator={value}
          legislationLevel={legislationLevel}
          onClick={trackStafferProfileLegislatorVisited}
          className={styles.legislation}
          showEnvelope={false}
        />
      );
    }

    if (value.type === COMMITTEE_TYPE) {
      return (
        <Link
          size="none"
          theme="link"
          className={columnStyles.link}
          href={value.url}
          onClick={trackStafferProfileCommitteeVisited}
        >
          {value.name}
        </Link>
      );
    }

    if (value.type === OTHER_TYPE) {
      return value.name;
    }

    return EMPTY_VALUE;
  }

  return <Column key={keyName} keyName={keyName}>{getLegislatorLink}</Column>;
}

OfficeItem.defaultProps = {
  value: {},
  item: {},
  withOtherType: false,
  legislationLevel: 'federal',
};

OfficeItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  legislationLevel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  item: PropTypes.object,
  withOtherType: PropTypes.bool,
  tracking: PropTypes.object.isRequired,
};

export default OfficeItem;

