import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Title from 'components/core/Profile/components/Title';
import Input from 'components/core/Input';
import { TextareaWithCharInfo } from 'components/core/CharInfo';

import useCustomNote from './useCustomNote';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

function CustomNote({
  title,
  description,
  onChange,
  contentCharactersLimit,
  titleCharactersLimit,
  className,
  disabled,
  isVisible,
}) {
  const {
    noteTitle,
    noteContent,
    onTitleChange,
    onContentChange,
  } = useCustomNote({
    onChange,
    disabled,
  });

  if (!isVisible) {
    return null;
  }

  const titleInputClassNames = classNames(
    disabled && styles.disabled,
    styles.titleInput,
  );

  const contentClassNames = classNames(
    disabled && styles.disabled,
  );

  const wrapperClassNames = classNames(
    styles.wrapper,
    className,
  );

  return (
    <div className={wrapperClassNames}>
      <Title
        title={title}
        description={description}
        enableTooltip
        className={parentStyles.titleWithTooltip}
        titleClassName={parentStyles.title}
      />

      <Input
        disabled={disabled}
        onChange={onTitleChange}
        value={noteTitle}
        className={titleInputClassNames}
        maxLength={titleCharactersLimit}
      />

      <TextareaWithCharInfo
        disabled={disabled}
        onChange={onContentChange}
        charInfo={{
          limit: contentCharactersLimit,
        }}
        value={noteContent}
        className={contentClassNames}
      />
    </div>
  );
}

CustomNote.defaultProps = {
  contentCharactersLimit: 5000,
  titleCharactersLimit: 130,
  isVisible: true,
};

CustomNote.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  contentCharactersLimit: PropTypes.number,
  titleCharactersLimit: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default CustomNote;
