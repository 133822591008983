import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// eslint-disable-next-line import/no-cycle
import Card from 'components/core/Card';

import TablePlaceholder from '../../../Table';
import TextPlaceholder from '../../../Text';
import parentStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

function CardTablePlaceholder({ rows, columns, title, headerNodes, className, labelRows, labelContainerClassName, ...props }) {
  function getLabel(index) {
    return (
      <div key={index} className={parentStyles.label}>
        <TextPlaceholder className={parentStyles.title} />
        <TextPlaceholder className={parentStyles.content} />
      </div>
    );
  }

  return (
    <Card
      title={title}
      headerNodes={headerNodes}
      className={classNames(styles.card, className)}
      {...props}
    >
      {labelRows && (
        <div className={classNames(parentStyles.container, labelContainerClassName)}>
          {Array(labelRows).fill(0).map((_, index) => getLabel(index))}
        </div>
      )}

      <TablePlaceholder
        rows={rows}
        columns={columns}
        contentClassName={styles.table}
      />
    </Card>
  );
}

CardTablePlaceholder.defaultProps = {
  rows: 3,
  columns: 3,
};

CardTablePlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  headerNodes: PropTypes.node,
  className: PropTypes.string,
  rows: PropTypes.number,
  columns: PropTypes.number,
  labelRows: PropTypes.number,
  labelContainerClassName: PropTypes.string,
};

export default CardTablePlaceholder;
