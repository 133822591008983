import { joinCompact, getParenthesisItem } from 'utils/string';

import { getDate } from '../../utils';

export const getPositionContent = ({ title, organization, description, startDate, endDate }) => {
  const date = getDate(startDate, endDate);
  const formattedDescription = getParenthesisItem(description);
  const secondPart = joinCompact([organization, formattedDescription, date], ' ');

  return joinCompact([title, secondPart]);
};
