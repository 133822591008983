const tableColumns = [
  {
    label: 'Display order',
    keyName: 'displayOrder',
  },
  {
    label: 'Title',
    keyName: 'name',
    sortingKey: 'name',
  },
  {
    label: 'Description',
    keyName: 'description',
    sortingKey: 'description',
  },
  {
    label: 'Type',
    keyName: 'fieldType',
    sortingKey: 'field_type',
  },
  {
    label: 'Required',
    keyName: 'required',
    sortingKey: 'required',
  },
  {
    label: 'Created',
    keyName: 'createdAt',
    sortingKey: 'created_at',
  },
  {
    label: 'Action',
    keyName: 'actions',
  },
];

export default tableColumns;
