import { StyleSheet } from '@react-pdf/renderer';

import {
  ATTACHMENT_BACKGROUND_COLOR,
  ATTACHMENT_TEXT_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  attachments: {
    marginBottom: '19px',
    marginTop: '-5px',
  },

  file: {
    margin: '0 3px 3px 0',
    padding: '6px 8px',
    borderRadius: 3,
    backgroundColor: ATTACHMENT_BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'row',
  },

  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: ATTACHMENT_TEXT_COLOR,
    fontSize: 11,
  },

  size: {
    whiteSpace: 'nowrap',
    fontSize: 11,
  },
});

export default styles;
