import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CorePaginator from 'components/core/Paginator';

import withNews from '../utils/withNews';
import { PAGE_SIZE } from '../utils/constants';

function Paginator({
  isMobile,
  isLoading,
  paginator,
  onChangePaginator,
}) {
  if (isEmpty(paginator)) {
    return null;
  }

  const { current, total, totalCount } = paginator;

  return (
    <CorePaginator
      isMobile={isMobile}
      isLoading={isLoading}
      current={current}
      total={total}
      totalItems={totalCount}
      pageSize={PAGE_SIZE}
      onChange={onChangePaginator}
    />
  );
}

Paginator.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  paginator: PropTypes.object.isRequired,
  onChangePaginator: PropTypes.func.isRequired,
};

export default withNews(Paginator);
