import React from 'react';
import PropTypes from 'prop-types';

import { BaseLabelGroup } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function Party({ parties, keyName, titles, value, onChange, className }) {
  return (
    <BaseLabelGroup
      {...titles}
      className={className}
      labelGroupClassName={styles.multiLabel}
      id={keyName}
      name={keyName}
      values={parties}
      value={value}
      onChange={onChange}
    />
  );
}

Party.defaultProps = {
  keyName: 'party',
  titles: {
    title: 'Party',
    placeholder: 'Party affiliation of the legislator',
  },
};

Party.propTypes = {
  parties: PropTypes.object.isRequired,
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Party;
