import contributionTitles from 'components/core/Moneyball/utils/contributionTitles';

const titles = {
  contributions: {
    title: 'Receipts',
    questionText: contributionTitles.tooltipContent,
  },
  contributionsForm: {
    title: 'Contributions from',
  },
};

export default titles;
