import { isValidElement } from 'react';
import { isEmpty, isObject } from 'lodash';
import { toast } from 'react-toastify';

import CloseButton from '../components/CloseButton';
import { defaultNotification } from './notificationTypes';

/** Get content for notification */
export function getContent(data) {
  if (isEmpty(data)) {
    return null;
  }

  if (isValidElement(data)) {
    return data;
  }

  if (isObject(data)) {
    return data.content;
  }

  return data;
}

export function updateNotification(id, content, options) {
  if (isEmpty(content)) {
    return null;
  }

  const { onClose } = options || {};
  const closeButton = props => CloseButton({ ...props, onClose, content });
  const currentContent = getContent(content);

  if (isEmpty(id)) {
    return defaultNotification(currentContent, { id, closeButton, ...options });
  }

  return toast.update(id, { render: currentContent, closeButton, ...options });
}
