/* eslint-disable react/no-array-index-key */
import React, { Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Draggable } from 'react-beautiful-dnd';

import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import Checkbox from 'components/core/Checkbox';
import RadioBox from 'components/core/RadioBox';
import { EMPTY_VALUE } from 'utils/constants';

import { DimensionLockingContext } from '../../utils/withDimensionLocking';
import BodyPlaceholder from '../Placeholder/BodyPlaceholder';
import useExpander from './useExpander';
import styles from '../../styles.module.scss';

/**
 * Clone a child element of type Column to repeat on every row in the table
 * @param {Column} child child to use as base
 * @param value value to be shown
 * @param item entire item (row)
 * @param cellIndex current cell index
 * @param rowIndex current row index
 * @param uniqueKey item unique identifier
 * @param emptyValuePlaceholder
 * @param isRowExpanded
 * @param toggleRow
 */
function cloneChild({
  child,
  value,
  item,
  cellIndex,
  rowIndex,
  uniqueKey,
  emptyValuePlaceholder,
  isRowExpanded,
  toggleRow,
  dragHandleProps,
  isDragging,
  withDragNDrop,
}) {
  return (
    <DimensionLockingContext.Provider
      key={`${item[uniqueKey]}-${rowIndex}-${cellIndex}`}
      value={{ isDragging, withDragNDrop }}
    >
      {
        React.cloneElement(child, {
          ...child.props,
          value: value || emptyValuePlaceholder,
          item,
          isRowExpanded,
          toggleRow,
          dragHandleProps,
          rowIndex,
        })
      }
    </DimensionLockingContext.Provider>
  );
}

export default function Body({
  isInitialLoading,
  isLoading,
  rowLengthPlaceholder,
  data,
  isCheckboxPresent,
  isRadioButtonPresent,
  selectedRadioButton,
  onRadioButtonChange,
  columnLength,
  children,
  uniqueKey,
  checkboxesMapping,
  onRowCheckChange,
  customEmptyMessage,
  emptyValuePlaceholder,
  renderExpandedRow,
  isSelectNonePresent,
  fixedEmptyMessage,
  droppablePlaceholder,
  isDroppingDisabled,
  withDragNDrop,
}) {
  const { toggleRow, isRowExpanded } = useExpander();
  const colSpanLength = (isCheckboxPresent || isRadioButtonPresent) ? columnLength + 1 : columnLength;

  if (isLoading) {
    return (
      <BodyPlaceholder
        rowLengthPlaceholder={rowLengthPlaceholder}
        isInitialLoading={isInitialLoading}
        isCheckboxPresent={isCheckboxPresent}
        columnLength={columnLength}
      />
    );
  }

  if (!data || (data && data.length === 0)) {
    const tdStyle = classNames(fixedEmptyMessage && styles.fixedMessage);

    return (
      <tbody className={styles.emptyMsg}>
        <tr>
          <td
            colSpan={colSpanLength}
            className={tdStyle}
          >
            <EmptyPlaceholder description={customEmptyMessage} />
          </td>
        </tr>
      </tbody>
    );
  }

  function renderSelectNoneRow() {
    return (
      <tr>
        <td key={`selection-column-${data.length}`}>
          <div className={styles.radioWrapper}>
            <RadioBox
              checked={isEmpty(selectedRadioButton)}
              onChange={() => onRadioButtonChange('')}
            />
          </div>
        </td>
        <td className={styles.selectNone} colSpan={colSpanLength}>
          Select none of these
        </td>
      </tr>
    );
  }

  return (
    <tbody>
      {data.map((item, rowIndex) => (
        <Fragment key={item[uniqueKey]}>
          <Draggable draggableId={String(item[uniqueKey])} index={rowIndex} isDragDisabled={isDroppingDisabled}>
            {(provided, snapshot) => (
              <tr
                key={`row-${item[uniqueKey]}`}
                ref={provided.innerRef}
                className={classNames(!isDroppingDisabled && snapshot.isDragging && styles.isDragging)}
                {...provided.draggableProps}
              >
                {isCheckboxPresent && (
                  <td key={`selection-column-${item[uniqueKey]}`}>
                    <Checkbox
                      className={styles.checkbox}
                      isChecked={!!checkboxesMapping[item[uniqueKey]]}
                      onChange={() => onRowCheckChange(item)}
                    />
                  </td>
                )}

                {isRadioButtonPresent && (
                  <td key={`selection-column-${item[uniqueKey]}`}>
                    <div className={styles.radioWrapper}>
                      <RadioBox
                        checked={String(item[uniqueKey]) === selectedRadioButton}
                        onChange={() => onRadioButtonChange(item[uniqueKey])}
                      />
                    </div>
                  </td>
                )}

                {
                  Children.toArray(children).map((child, cellIndex) => cloneChild({
                    child,
                    item,
                    cellIndex,
                    rowIndex,
                    uniqueKey,
                    emptyValuePlaceholder,
                    value: item[child.props.keyName],
                    isRowExpanded: isRowExpanded(item[uniqueKey]),
                    toggleRow: toggleRow(item[uniqueKey]),
                    dragHandleProps: provided.dragHandleProps,
                    isDragging: snapshot.isDragging,
                    withDragNDrop,
                  }))
                }
              </tr>
            )}
          </Draggable>
          {renderExpandedRow && isRowExpanded(item[uniqueKey]) && renderExpandedRow({
            ...item,
            isCheckboxPresent,
            checkboxesMapping,
            onRowCheckChange,
            uniqueKey,
          })}
        </Fragment>
      ))}
      {isRadioButtonPresent && isSelectNonePresent && renderSelectNoneRow()}
      {droppablePlaceholder}
    </tbody>
  );
}

Body.defaultProps = {
  columnLength: 1,
  customEmptyMessage: 'There are no results that match the applied filters.',
  emptyValuePlaceholder: EMPTY_VALUE,
  fixedEmptyMessage: false,
};

Body.propTypes = {
  isInitialLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  rowLengthPlaceholder: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.node,
    })
  ).isRequired,
  isCheckboxPresent: PropTypes.bool.isRequired,
  isRadioButtonPresent: PropTypes.bool.isRequired,
  isSelectNonePresent: PropTypes.bool.isRequired,
  selectedRadioButton: PropTypes.string,
  onRadioButtonChange: PropTypes.func,
  columnLength: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]),
  uniqueKey: PropTypes.string.isRequired,
  checkboxesMapping: PropTypes.shape({
    [PropTypes.oneOfType([PropTypes.string, PropTypes.number])]: PropTypes.bool,
  }).isRequired,
  onRowCheckChange: PropTypes.func.isRequired,
  customEmptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  emptyValuePlaceholder: PropTypes.node,
  renderExpandedRow: PropTypes.func,
  fixedEmptyMessage: PropTypes.bool,
  droppablePlaceholder: PropTypes.node,
  isDroppingDisabled: PropTypes.bool.isRequired,
  withDragNDrop: PropTypes.bool.isRequired,
};
