import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import DataCard from 'components/core/Profile/components/DataCard';
import { MONEYBALL_COMBINE_INDIVIDUALS_VIEW_URL } from 'components/Moneyball/utils/constants';
import { queryParser, queryStringify } from 'utils/query-string';
import Link from 'components/core/Link';
import EmptyPlaceholder, { NoSubscriptionMessage } from 'components/core/EmptyPlaceholder';

import useContributions from '../../utils/useContributions';
import { CONTRIBUTION_OPTIONS, STAKEHOLDER_CONTRIBUTIONS_KEY } from '../../utils/constants';
import titles from '../../utils/titles';
import Form from '../Form';
import Table from '../Table';
import styles from './styles.module.scss';

const { isStakeholderMgmtEnabled } = gon;

function Container({
  ids,
  isMobile,
  lookUp,
  history,
  searchReceipt,
  searchReceipts,
  submitExport,
  contributionOptions,
  customTitles,
  tableClassName,
  nrRowsPlaceholder,
  perPage,
  searchProperties,
  appliedSearchFilters,
  isEmptyData,
  customEmptyMessage,
}) {
  const searchParams = queryParser(history.location.search, { arrayFormat: 'none' });
  const { contribution, onChangeContribution, fetchData } = useContributions({
    ids,
    searchProperties,
    perPage,
    searchReceipts,
  });

  const noSubscriptionMessage = (
    <NoSubscriptionMessage
      name="Stakeholder Management"
      description="Stakeholder Management"
    />
  );

  /** TODO add to DataCard -> rightItems={getMoneyballLink()} when available */
  // eslint-disable-next-line no-unused-vars
  function getMoneyballLink() {
    if (isEmpty(ids)) {
      return null;
    }

    const itemsToken = queryStringify({ ids }, { arrayFormat: 'none' });
    return (
      <Link
        className={styles.moneyballLink}
        theme="default"
        size="none"
        href={`${MONEYBALL_COMBINE_INDIVIDUALS_VIEW_URL}?${itemsToken}`}
      >
        View on moneyball
      </Link>
    );
  }

  function getTable() {
    const isStakeholdersFilterSelected = contribution === STAKEHOLDER_CONTRIBUTIONS_KEY;

    if (isStakeholdersFilterSelected && !isStakeholderMgmtEnabled) {
      return (
        <EmptyPlaceholder
          iconNode={<img src="/images/empty-custom-fields.svg" alt="Looking for something?" />}
          title="Looking for something?"
          description={noSubscriptionMessage}
          className={styles.noSubscriptionWarning}
        />
      );
    }

    return (
      <Table
        {...lookUp}
        history={history}
        perPage={perPage}
        tableClassName={tableClassName}
        searchReceipt={searchReceipt}
        searchReceipts={fetchData}
        submitExport={submitExport}
        isMobile={isMobile}
        nrRowsPlaceholder={nrRowsPlaceholder}
        searchProperties={searchProperties[contribution]}
        appliedSearchFilters={appliedSearchFilters}
        customEmptyMessage={customEmptyMessage}
        searchParams={searchParams}
      />
    );
  }

  return (
    <DataCard {...customTitles} showPlaceholder={isEmptyData}>
      <Form
        contributionOptions={contributionOptions}
        contribution={contribution}
        onChangeContribution={onChangeContribution}
      />
      {getTable()}
    </DataCard>
  );
}

Container.defaultProps = {
  customTitles: titles.contributions,
  contributionOptions: CONTRIBUTION_OPTIONS,
  customEmptyMessage: '',
};

Container.propTypes = {
  ids: PropTypes.array,
  searchProperties: PropTypes.object,
  appliedSearchFilters: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  lookUp: PropTypes.object.isRequired,
  searchReceipt: PropTypes.func.isRequired,
  searchReceipts: PropTypes.func.isRequired,
  submitExport: PropTypes.func.isRequired,
  customTitles: PropTypes.object,
  contributionOptions: PropTypes.array,
  tableClassName: PropTypes.string,
  nrRowsPlaceholder: PropTypes.number,
  perPage: PropTypes.number,
  isEmptyData: PropTypes.bool,
  history: PropTypes.object.isRequired,
  customEmptyMessage: PropTypes.string,
};

export default Container;
