import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function SummaryButton({
  buttonLabel,
  isLoading,
  isDisabled,
  onClick,
}) {
  const iconClass = isLoading
    ? 'fas fa-circle-notch fa-spin'
    : 'fas fa-poll-h';

  return (
    <Button
      className={styles.button}
      customIcon={<i className={classNames(iconClass, styles.icon)} />}
      theme="light"
      onClick={onClick}
      disabled={isDisabled}
    >
      {buttonLabel}
    </Button>
  );
}

SummaryButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SummaryButton;
