import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Title from 'components/core/Profile/components/Title';
import useRadioGroup from 'components/core/AdvancedSearch/utils/useRadioGroup';
import LabelGroup from 'components/core/LabelGroup';

import titles from '../../utils/titles';
import LabelItem from '../LabelItem';
import styles from '../styles.module.scss';

function FileFormat({
  values,
  onChange,
  title,
  description,
  exportFileItems,
  enableTooltip,
}) {
  const checkedItemProps = useRadioGroup({
    id: 'fileFormat',
    defaultCheckedItems: values,
    onChange,
  });
  const titleClassName = {
    className: enableTooltip ? styles.titleWithTooltip : styles.container,
    titleClassName: styles.title,
    descriptionClassName: styles.description,
  };

  return (
    <>
      <Title
        title={title}
        description={description}
        enableTooltip={enableTooltip}
        {...titleClassName}
      />

      <LabelGroup>
        {exportFileItems.map((item, index) => (
          <LabelItem
            key={`file-format-${index}`}
            {...item}
            {...checkedItemProps}
            type="radio"
          />
        ))}
      </LabelGroup>
    </>
  );
}

FileFormat.defaultProps = {
  title: titles.fileFormat.title,
  description: titles.fileFormat.description,
};

FileFormat.propTypes = {
  exportFileItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  enableTooltip: PropTypes.bool,
};

export default FileFormat;
