import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/core/Table/components/Header/components/Tooltip';

import styles from './styles.module.scss';

export default function TitleWithTooltip({
  title,
  description,
  className,
  titleClassName,
  rightItems,
}) {
  return (
    <div className={classNames(styles.container, className)}>
      <span className={titleClassName}>
        {title}
      </span>
      <Tooltip content={description} />
      {rightItems && <div>{rightItems}</div>}
    </div>
  );
}

TitleWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  rightItems: PropTypes.node,
};
