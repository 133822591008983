import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { DEFAULT_TEXT_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  footerSection: {
    position: 'absolute',
    bottom: 20,
    left: 40,
    right: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: DEFAULT_TEXT_COLOR,
  },
  textNumber: {
    fontSize: 10,
    fontWeight: 700,
  },
});

function DocumentFooter({ leftContent, isPageNumbersVisible }) {
  function renderPageNumber({ pageNumber }) {
    if (isPageNumbersVisible) {
      return `Page ${pageNumber}`;
    }

    return '';
  }

  return (
    <View fixed style={styles.footerSection}>
      <View fixed>{leftContent}</View>
      <Text
        style={styles.textNumber}
        render={renderPageNumber}
        fixed
      />
    </View>
  );
}

DocumentFooter.propTypes = {
  leftContent: PropTypes.node,
  isPageNumbersVisible: PropTypes.bool,
};

export default DocumentFooter;
