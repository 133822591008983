const recordColumn = {
  label: 'Record type',
  keyName: 'type',
  sortingKey: 'record_type',
  allowToggle: true,
};
const titleColumn = {
  label: 'Title',
  keyName: 'title',
  sortingKey: 'title.raw',
  allowToggle: true,
};
const snippetColumn = {
  label: 'Snippet',
  keyName: 'snippet',
  allowToggle: true,
};
const dateColumn = {
  label: 'Date',
  keyName: 'date',
  sortingKey: 'date',
  allowToggle: true,
};
const addedToColumn = {
  label: 'Added to',
  keyName: 'addedTo',
  allowToggle: false,
};
const tagsColumn = {
  label: 'Tags',
  keyName: 'tags',
  allowToggle: false,
};
const actionsColumn = {
  label: 'Actions',
  keyName: 'actions',
  allowToggle: false,
};

const tableColumns = [
  recordColumn,
  titleColumn,
  dateColumn,
  addedToColumn,
  tagsColumn,
  actionsColumn,
];

export const snippetTableColumns = [
  recordColumn,
  snippetColumn,
  dateColumn,
  addedToColumn,
  tagsColumn,
  actionsColumn,
];

export default tableColumns;
