import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import Medals from './Medals';
import { getBranchContent } from './utils';

function MilitaryService({ person, isLoading, hideOnEmptyData }) {
  const { militaryService = [] } = person || {};

  const getMilitaryBranchContent = ({ medals, ...restProps }) => (
    <span>
      {getBranchContent(restProps)}
      <Medals medals={medals} />
    </span>
  );

  const sections = [{
    id: 'militaryService',
    title: 'Military service',
    tables: [{
      id: 'militaryBranchs',
      label: 'Military branch',
      placeholderSize: 2,
      columns: [{
        id: 'militaryBranch',
        getContent: getMilitaryBranchContent,
      }],
      data: militaryService,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(militaryService)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

MilitaryService.defaultProps = {
  hideOnEmptyData: true,
};

MilitaryService.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(MilitaryService);
