import { useState } from 'react';

function useAccordionState(initialState, multi) {
  const [isOpen, setIsOpen] = useState(initialState);

  const handleToggle = (key) => {
    setIsOpen(prevState => ({ ...(multi && prevState), [key]: !prevState[key] }));
  };

  const closeAll = () => {
    setIsOpen(initialState);
  };

  return {
    isOpen,
    closeAll,
    handleToggle,
  };
}

export default useAccordionState;
