import { useEffect, useState } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';

import { formatDate } from 'components/core/Profile/utils';

import notifications from './notifications';

function usePublicRecords({
  id,
  slug,
  checkboxRecordTypes,
  fetchPublicRecords,
  trackingContext,
  customTransformers,
  defaultSort,
  trackResultsFiltered,
  trackResultsDateRangeFiltered,
  shouldFetch,
}) {
  const defaultRecordTypes = (checkboxRecordTypes.map(type => type.id) || []).sort();
  const defaultRangeData = { startDate: null, endDate: null };

  const [tags, setTags] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState('');
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [rangeDate, setRangeDate] = useState(defaultRangeData);
  const [selectedTypes, setSelectedTypes] = useState(defaultRecordTypes);
  const [toResetTable, setToResetTable] = useState(true);

  const selectedTypeSorted = (selectedTypes || []).sort();
  const areFiltersApplied = !isEmpty(keywordSearch)
    || !isEmpty(searchedKeyword)
    || !isEmpty(tags)
    || !isEqual(rangeDate, defaultRangeData)
    || !isEqual(selectedTypeSorted, defaultRecordTypes);

  const allSearchData = {
    id,
    ...defaultSort,
    slug,
    tags,
    keywordSearch,
    rangeDate,
    selectedTypes,
  };

  function handleSearch(customSearchData = {}) {
    if (id) {
      const { keywordSearch: customKeywordSearch } = customSearchData;
      setSearchedKeyword(isNil(customKeywordSearch) ? keywordSearch : customKeywordSearch);

      return fetchPublicRecords({ ...allSearchData, ...customSearchData }, customTransformers)
        .catch((error) => {
          notifications.generalFailedNotification();
          throw error;
        });
    }

    return Promise.resolve({});
  }

  function handleSubmit() {
    handleSearch().then(() => {
      if (keywordSearch) {
        trackResultsFiltered(trackingContext, 'keyword', keywordSearch);
      }
    });
  }

  /* Search triggered by init or changes of input */
  function handleInputSearch(customSearchData = {}) {
    handleSearch(customSearchData);
    setToResetTable(true);
  }

  /** Init load data + load data on change checkboxes + data */
  useEffect(() => {
    shouldFetch && handleInputSearch();
  }, [tags, selectedTypes, rangeDate, shouldFetch, id]);

  useEffect(() => {
    const selectedTypesLabels = checkboxRecordTypes
      .filter(item => selectedTypes.includes(item.id))
      .map(item => item.labelPlural);

    trackResultsFiltered(trackingContext, 'record type', selectedTypesLabels);
  }, [selectedTypes]);

  useEffect(() => {
    const { startDate, endDate } = rangeDate;

    if (startDate || endDate) {
      trackResultsDateRangeFiltered(trackingContext, startDate, endDate);
    }
  }, [rangeDate]);

  function handleChangeTypes(newTypes) {
    setSelectedTypes(newTypes);
  }

  function handleCheckType(typeId, event) {
    const { checked } = event.target;

    const items = checked
      ? [...selectedTypes, typeId]
      : selectedTypes.filter(item => item !== typeId);

    handleChangeTypes(items);
  }

  function handleCheckAllTypes(event) {
    const { checked } = event.target;
    const items = checked
      ? checkboxRecordTypes.map(type => type.id)
      : [];

    handleChangeTypes(items);
  }

  const handleChangeKeywordSearch = (event) => {
    const { value } = event.target;
    setKeywordSearch(value);
  };

  const handleClearSearch = () => {
    setKeywordSearch('');

    searchedKeyword && handleSearch({ keywordSearch: '' });
  };

  const handleChangeTags = option => setTags(option);

  function handleStartDateChange(date) {
    setRangeDate(prevProps => ({
      ...prevProps,
      startDate: formatDate(date),
    }));
  }

  function handleEndDateChange(date) {
    setRangeDate(prevProps => ({
      ...prevProps,
      endDate: formatDate(date),
    }));
  }

  function handleClearAll() {
    setRangeDate(defaultRangeData);
    setSelectedTypes(defaultRecordTypes);
    setToResetTable(true);
    setTags([]);
    handleClearSearch();
  }

  return {
    tags,
    keywordSearch,
    searchedKeyword,
    rangeDate,
    selectedTypes,
    areFiltersApplied,
    onCheckType: handleCheckType,
    onCheckAllTypes: handleCheckAllTypes,
    toResetTable,
    onResetTable: setToResetTable,
    allSearchData,
    onStartDateChange: handleStartDateChange,
    onEndDateChange: handleEndDateChange,
    onChangeKeywordSearch: handleChangeKeywordSearch,
    onChangeTags: handleChangeTags,
    onSearch: handleSearch,
    onSubmit: handleSubmit,
    onClearAll: handleClearAll,
    onClearSearch: handleClearSearch,
  };
}

export default usePublicRecords;
