import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LabeledProgressBar from './components/LabeledProgressBar';

import styles from './styles.module.scss';

const themes = {
  default: styles.default,
  light: styles.light,
};

const titleThemes = {
  success: styles.success,
  pending: styles.pending,
  failure: styles.failure,
};

export default function ProgressBar({ percent, title, theme, titleTheme, className }) {
  const normalizedPercent = Math.min(100, Math.max(0, percent));
  const widthStyle = { width: `${normalizedPercent}%` };

  return (
    <div className={classNames(styles.bar, themes[theme], className)}>
      {title && (
        <div className={classNames(styles.title, titleThemes[titleTheme])}>
          {title}
        </div>
      )}

      <div className={styles.progress} style={widthStyle} />
    </div>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(['default', 'light']),
  className: PropTypes.string,
  title: PropTypes.string,
  titleTheme: PropTypes.oneOf(['success', 'pending', 'failure']),
};

ProgressBar.defaultProps = {
  percent: 0,
  theme: 'default',
  title: '',
  titleTheme: 'pending',
};

export { LabeledProgressBar };
