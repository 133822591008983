import React from 'react';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { SearchContext } from './utils/withSearch';
import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';

function SearchHeader(props) {
  const { isDesktop } = useMediaQuery();

  const searchContext = {
    ...props,
    isMobile: !isDesktop,
  };

  return (
    <SearchContext.Provider value={searchContext}>
      {isDesktop ? <DesktopView /> : <MobileView />}
    </SearchContext.Provider>
  );
}

export default SearchHeader;
