import isEmpty from 'lodash/isEmpty';

import { CHART_OPTIONS, BAR_COLORS, INDEPENDENT_COLORS } from './constants';

function getColor(legislator, index, isBorderColor = false) {
  const { party } = legislator;

  const colorAttribute = isBorderColor ? 'borderColor' : 'backgroundColor';
  const currentParty = !isEmpty(party) ? party.toLowerCase() : '';
  const barColor = BAR_COLORS[currentParty];

  return barColor ? barColor[colorAttribute] : INDEPENDENT_COLORS[colorAttribute];
}

function getLegislatorName(legislator) {
  const { universal_moniker: fullName, name, party_short: partyShort } = legislator || {};

  const currentParty = !isEmpty(partyShort) ? `[${partyShort}]` : partyShort;
  const currentName = !isEmpty(fullName) ? fullName : name;

  return [currentName, currentParty].join(' ');
}

function getLegislators(legislators) {
  if (isEmpty(legislators)) {
    return [];
  }

  return legislators;
}

export function transformChartData(legislators = []) {
  return {
    chartData: getLegislators(legislators).map(legislator => legislator.count),
    labels: getLegislators(legislators).map(getLegislatorName),
    borderColor: getLegislators(legislators).map((legislator, index) => getColor(legislator, index, true)),
    backgroundColor: getLegislators(legislators).map(getColor),
  };
}

export function getChartOptions() {
  const getTooltipLabel = data => `Bills: ${data.value}`;

  return {
    ...CHART_OPTIONS,
    tooltips: {
      ...CHART_OPTIONS.tooltips,
      callbacks: {
        label: getTooltipLabel,
      },
    },
  };
}
