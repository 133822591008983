import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { mapTypeCodes } from 'components/core/Map/utils/constants';
import MapContainer from 'components/core/Map';

import useMapProfile from '../utils/useMapProfile';
import styles from '../styles.module.scss';

const mapRef = createRef();

function MapContent({ mapLocation, isMobile }) {
  const mapProps = {
    ...mapLocation,
    data: mapLocation,
    isMobile,
    mapRef,
    isLoadingMap: false,
    withFilters: false,
    withNavigation: true,
    loadingMessage: 'Loading data..',
    defaultFilters: {
      mapType: mapTypeCodes.congressional,
    },
  };

  const mapProfileProps = useMapProfile({ mapRef, ...mapProps });
  const profileContext = {
    height: '400px',
    forwardRef: mapRef,
    ...mapProps,
    ...mapProfileProps,
  };

  return (
    <div className={classNames(styles.container, 'chart-overflow')}>
      <MapContainer {...profileContext} />
    </div>
  );
}


MapContent.propTypes = {
  mapLocation: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MapContent;
