import { generalFailNotification } from '../utils/notifications';

const PER_PAGE = 4;
const MAX_PER_PAGE = 100;

export const fetchReplies = ({ parentId, createdAtBefore, shouldLoadAllReplies }) => {
  const url = `/api_web/comments/${parentId}/replies`;
  const method = 'GET';
  const perPage = shouldLoadAllReplies ? MAX_PER_PAGE : PER_PAGE;
  const data = {
    created_at_before: createdAtBefore && moment(createdAtBefore).toISOString(),
    per_page: perPage,
  };
  const ajax = $.ajax({ url, method, data });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const saveReply = (parentId, text) => {
  const url = `/api_web/comments/${parentId}/replies`;
  const method = 'POST';
  const data = { text };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const updateReply = (parentId, { id, text }) => {
  const url = `/api_web/comments/${parentId}/replies/${id}`;
  const method = 'PUT';
  const data = { text };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const deleteReply = (parentId, id) => {
  const url = `/api_web/comments/${parentId}/replies/${id}`;
  const method = 'DELETE';

  const ajax = $.ajax({
    url,
    method,
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};
