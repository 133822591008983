import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import {
  isEmpty,
  size as length,
  map,
  slice,
  truncate,
  isFunction,
} from 'lodash';

import Button from 'components/core/Button';
import { getBackToParams } from 'components/core/Link/components/BackTo/utils';
import ButtonTag from 'components/core/ButtonTag';
import { TagsPlaceholder } from 'components/core/Placeholder';
import WorkspaceTiles from 'components/core/WorkspaceTiles';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { workspaceShape } from 'redux/events/utils/shapes';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

import {
  MOBILE_LABEL_LENGTH,
  DESKTOP_LABEL_LENGTH,
  SIZES,
  TAGS_DISPLAY_LENGTH,
} from './constants';
import styles from './styles.module.scss';

function ViewTags({
  isLoading,
  tags,
  emptyValue,
  tooltipTheme,
  className,
  onClick,
  tileClassName,
  isContainerless,
  isLink,
  customButton,
  displayAll,
  isRouterLink,
  routerLinkState,
  baseUrl,
  backTo,
  backToQuery,
  tagsDisplayLength,
  theme,
  onGetTo,
  mobileLabelLength,
  desktopLabelLength,
  size,
  sharings,
  trackings,
  displayAllWorkspaces,
  withInteractiveTags,
}) {
  const { isMobile } = useMediaQuery();

  if (isLoading) {
    return <TagsPlaceholder className={className} />;
  }

  const truncateOptions = {
    length:
      isMobile || (size === SIZES.small)
        ? mobileLabelLength
        : desktopLabelLength,
  };

  const hasWorkspaceTiles = !(isEmpty(sharings) && isEmpty(trackings));

  if (isEmpty(tags) && !hasWorkspaceTiles) {
    return emptyValue;
  }

  const handleClick = tag => () => {
    onClick && onClick(tag);
  };

  function renderTag(tag, index) {
    const { label, value } = tag || {};
    const keyName = `${index}-${value}`;

    const tagLink = {
      pathname: `${baseUrl}/${value}`,
      search: `?name=${label}`,
      state: routerLinkState,
    };
    const to = isFunction(onGetTo) ? onGetTo(tag) : tagLink;

    const renderLabel = () => {
      const truncatedLabel = truncate(label, truncateOptions);

      if (length(label) > truncateOptions.length) {
        return (
          <Tooltip className={styles.truncateTooltip} content={label}>
            <span>{truncatedLabel}</span>
          </Tooltip>
        );
      }

      return truncatedLabel;
    };

    // TODO refactor so it won't render a button if it is not clickable, so it won't affect Accessibility
    return (
      <ButtonTag
        key={keyName}
        onClick={handleClick(tag)}
        theme={theme}
        isLink={isLink}
        href={`${baseUrl}/${value}?name=${label}${getBackToParams({ backTo, backToQuery })}`}
        isRouterLink={isRouterLink}
        to={to}
        className={classNames(tileClassName, !(isFunction(onClick) || isLink) && styles.notClickable)}
      >
        {renderLabel()}
      </ButtonTag>
    );
  }

  const sizeTags = length(tags);

  const renderTooltipRemainedTags = () => {
    if (displayAll) {
      return null;
    }

    if (sizeTags > tagsDisplayLength) {
      const remainingTags = slice(tags, tagsDisplayLength, sizeTags);
      const stringTags = map(remainingTags, 'label').join(', ');
      const interactiveTags = map(remainingTags, tag => (
        <Button
          key={`tooltip-tag-${tag.value}`}
          onClick={handleClick(tag)}
          theme="tableLink"
          className={styles.tooltipTag}
        >
          {tag.label}
        </Button>
      ));
      const tooltipContent = (
        <div className={styles.tooltipContainer}>
          {withInteractiveTags ? interactiveTags : stringTags}
        </div>
      );

      return (
        <Tooltip
          theme={tooltipTheme}
          content={tooltipContent}
          placement="bottom"
          interactive={withInteractiveTags}
          arrow
        >
          <span className={styles.remainingTags}>
            <ButtonTag theme={theme}>
              +{sizeTags - tagsDisplayLength}
            </ButtonTag>
          </span>
        </Tooltip>
      );
    }

    return null;
  };

  const displayedTags = displayAll ? tags : slice(tags, 0, tagsDisplayLength);

  const content = (
    <>
      <WorkspaceTiles
        isLoading={isLoading}
        className={tileClassName}
        wrapperClassName={styles.workspaceTile}
        sharings={sharings}
        trackings={trackings}
        displayAll={displayAllWorkspaces}
      />
      {map(displayedTags, renderTag)}
      {customButton}
      {renderTooltipRemainedTags()}
    </>
  );

  if (isContainerless) {
    return content;
  }

  return (
    <div className={className}>
      {content}
    </div>
  );
}

ViewTags.defaultProps = {
  emptyValue: null,
  tooltipTheme: 'light',
  isContainerless: false,
  displayAll: false,
  baseUrl: '/news/tag',
  tagsDisplayLength: TAGS_DISPLAY_LENGTH,
  theme: 'darkBlue',
  mobileLabelLength: MOBILE_LABEL_LENGTH,
  desktopLabelLength: DESKTOP_LABEL_LENGTH,
  size: SIZES.large,
  displayAllWorkspaces: false,
  withInteractiveTags: false,
};

ViewTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  isLoading: PropTypes.bool,
  tooltipTheme: PropTypes.string,
  className: PropTypes.string,
  emptyValue: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  tileClassName: PropTypes.string,
  isContainerless: PropTypes.bool,
  isLink: PropTypes.bool,
  customButton: PropTypes.node,
  displayAll: PropTypes.bool,
  isRouterLink: PropTypes.bool,
  routerLinkState: PropTypes.shape({}),
  baseUrl: PropTypes.string,
  backTo: PropTypes.string,
  backToQuery: PropTypes.string,
  tagsDisplayLength: PropTypes.number,
  mobileLabelLength: PropTypes.number,
  desktopLabelLength: PropTypes.number,
  theme: PropTypes.string,
  size: PropTypes.string,
  onGetTo: PropTypes.func,
  sharings: PropTypes.arrayOf(workspaceShape),
  trackings: PropTypes.arrayOf(trackingShape),
  displayAllWorkspaces: PropTypes.bool,
  withInteractiveTags: PropTypes.bool,
};

export default ViewTags;
