import { useState, useEffect } from 'react';

import { getCompanyLogo } from 'utils/api';

function useCompanyLogo({ displayCompanyLogo, isOpenModal }) {
  const { currentUser = {} } = gon;
  const { companyHasLogo } = currentUser;
  const shouldFetchLogo = (
    companyHasLogo
    && displayCompanyLogo
    && isOpenModal
  );

  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    if (shouldFetchLogo) {
      setIsLogoLoading(true);

      Promise.resolve(getCompanyLogo(logoUrl))
        .then((companyLogoUrl) => {
          setLogoUrl(companyLogoUrl);
          setIsLogoLoading(false);
        });
    }
  }, [shouldFetchLogo]);

  return {
    isLogoLoading,
    logoUrl,
    companyHasLogo,
  };
}

export default useCompanyLogo;
