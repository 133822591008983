export const CONTINUOUS_ID = 'continuous';
export const DAILY_ID = 'daily';
export const WEEKLY_ID = 'weekly';
export const NONE_ID = 'none';
export const SHARED_ID = 'shared';

export const WEEK_AHEAD = {
  value: 'week_ahead',
  label: 'Weak ahead',
};

export const CONTINUOUS = {
  value: CONTINUOUS_ID,
  label: 'Continuous',
};

export const DAILY = {
  value: DAILY_ID,
  label: 'Daily',
};

export const NONE = {
  value: NONE_ID,
  label: 'None',
};

export const WEEKLY = {
  value: WEEKLY_ID,
  label: 'Weekly',
};

export const DEFAULT_FREQUENCIES = [
  CONTINUOUS,
  DAILY,
  NONE,
];

export const ALL_FREQUENCIES = [
  NONE,
  WEEKLY,
  DAILY,
  CONTINUOUS,
];
