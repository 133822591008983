import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Kbd({ children, className }) {
  return (
    <div className={classNames(styles.container, className)}>
      <span className={styles.value}>
        {children}
      </span>
    </div>
  );
}

Kbd.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

export default Kbd;
