/* eslint-disable react/no-did-update-set-state */

import React, { Component, Children, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Tooltip from 'components/core/Tooltip';
import { ContextualMenu } from 'components/core/Icons';
import getDesktopNavBarHeight from 'utils/breakpoints/getDesktopNavBarHeight';
import { canRenderTopBar } from 'components/core/ProductBar/utils';

import Header from './components/Header';
import Dimensions from './components/Dimensions';
import Body from './components/Body';
import Column from './components/Column';
import {
  headerMobileHeight,
  headerDesktopHeight,
  navigationHeight,
  actionBarMobileHeight,
  actionBarDesktopHeight,
  paginatorHeight,
  tableHeaderHeight,
  scrollbarHeight,
  scrollbarHeightMobile,
  productBarHeight,
} from './utils/constants';
import styles from './styles.module.scss';

const themes = {
  default: styles.default,
  light: styles.light,
};

const MOBILE_HEIGHT_OFFSET = 26;

/* IMPORTANT: In order to use the table with drag n drop enable
*  you must provide a onDragEnd fn along with a DraggableItem
*  or another custom column to receive the dragHandleProps
*/
export default class Container extends Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);

    this.state = {
      offsetHeight: this.checkFixedTableOffsetHeight(),
      checkboxesMapping: {},
      selectAll: false,
      isDimensionsOpen: false,
      selectedRadioButton: '',
      windowInnerHeight: window.innerHeight,
    };
  }

  tooltipRef = createRef();

  headerRef = React.createRef();

  tableRef = React.createRef();

  tableWrapperRef = React.createRef();

  componentWillReceiveProps = (nextProps) => {
    const {
      isScrollToHeader,
      preserveCheckboxes,
      selectedItems,
      data,
      uniqueKey,
      isRadioButtonPresent,
      isCheckboxPresent,
    } = nextProps || {};

    const { checkboxesMapping } = this.state;

    if (isScrollToHeader) {
      window.scrollTo({
        top: this.tableRef.current.offsetTop,
        behavior: 'smooth',
      });
    }

    if (preserveCheckboxes) {
      const checkedItems = this.parseCheckboxesToArray(checkboxesMapping);
      const availableItems = data.map(item => String(item[uniqueKey]));
      const notFit = availableItems.find(item => !checkedItems.includes(item));

      this.setState({ selectAll: (notFit === undefined) });
    }

    if (isRadioButtonPresent && !isEmpty(selectedItems)) {
      const selectedItem = selectedItems[0];

      this.setState({ selectedRadioButton: String(selectedItem[uniqueKey]) });
    }

    if (isCheckboxPresent && !isEmpty(selectedItems)) {
      selectedItems.forEach((item) => { checkboxesMapping[item[uniqueKey]] = item; });
      this.setState({ checkboxesMapping });
    }

    if (isEmpty(selectedItems)) {
      this.setState({
        checkboxesMapping: {},
        selectedRadioButton: '',
        selectAll: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { isFixed, isLoading, fullWidthTable, selectedItems, isDesktopLarge } = this.props;

    if (isFixed
      && fullWidthTable
      && (
        isLoading !== prevProps.isLoading
        || selectedItems.length !== prevProps.selectedItems.length
        || isDesktopLarge !== prevProps.isDesktopLarge
      )) {
      const offsetHeight = this.checkFixedTableOffsetHeight();

      this.setState(prevState => ({ ...prevState, offsetHeight }));
    }
  }

  componentDidMount() {
    const { data, selectedItems, preserveCheckboxes, uniqueKey, isRadioButtonPresent, isCheckboxPresent } = this.props;
    const { checkboxesMapping } = this.state;

    if (isCheckboxPresent && !isEmpty(selectedItems)) {
      selectedItems.forEach((item) => {
        checkboxesMapping[item[uniqueKey]] = !checkboxesMapping[item[uniqueKey]] ? item : null;
      });

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ checkboxesMapping });
    }

    if (preserveCheckboxes) {
      const checkedItems = this.parseCheckboxesToArray(checkboxesMapping);
      const availableItems = data.map(item => String(item[uniqueKey]));
      const notFit = availableItems.find(item => !checkedItems.includes(item));

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ selectAll: (notFit === undefined) });
    }

    if (isRadioButtonPresent && !isEmpty(selectedItems)) {
      const selectedItem = selectedItems[0];

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ selectedRadioButton: String(selectedItem[uniqueKey]) });
    }

    // TODO: use useTransition api after upgrade to React 18v
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      windowInnerHeight: window.innerHeight,
    });
  }

  checkFixedTableOffsetHeight = () => {
    const {
      isMobile,
      isDesktopLarge,
      selectedItems,
      isPaginatorPresent,
      preserveCheckboxes,
    } = this.props;
    const isActionBarVisible = selectedItems && selectedItems.length;
    const tableWrapperRefNode = this.tableWrapperRef.current;
    const dynamicHeaderHeight = isMobile ? headerMobileHeight : headerDesktopHeight;
    const dynamicNavigationHeight = isMobile ? navigationHeight : getDesktopNavBarHeight({ isDesktopLarge });
    const dynamicActionBarHeight = isMobile ? actionBarMobileHeight : actionBarDesktopHeight;
    const actionBarHeight = isActionBarVisible ? dynamicActionBarHeight : 0;
    const windowScrollSize = tableWrapperRefNode && (tableWrapperRefNode.offsetHeight - tableWrapperRefNode.clientHeight);
    const dynamicScrollBarHeight = windowScrollSize || scrollbarHeight;
    const scrollbarMobileAlign = isMobile ? scrollbarHeightMobile : 0;
    const dynamicProductBarHeight = canRenderTopBar(isMobile) ? productBarHeight : 0;

    let dynamicPaginatorHeight = isPaginatorPresent ? paginatorHeight : 0;
    if (!preserveCheckboxes) {
      dynamicPaginatorHeight = isPaginatorPresent && !isActionBarVisible ? paginatorHeight : 0;
    }

    return dynamicHeaderHeight
      + dynamicPaginatorHeight
      + tableHeaderHeight
      + dynamicNavigationHeight
      + actionBarHeight
      + dynamicScrollBarHeight
      + dynamicProductBarHeight
      + scrollbarMobileAlign;
  };

  parseCheckboxesToArray = checkboxesMapping => Object.keys(checkboxesMapping)
    .filter(key => checkboxesMapping[key]);

  parseCheckboxesItems = checkboxesMapping => Object.keys(checkboxesMapping)
    .filter(key => checkboxesMapping[key])
    .map(item => checkboxesMapping[item]);

  handleRowCheckChanges = ({ parent, ...restItem }) => {
    const { onSelectItems, disableScrollToHeader, uniqueKey } = this.props;
    const { checkboxesMapping } = this.state;

    if (parent) {
      const cluster = checkboxesMapping[parent[uniqueKey]];
      const { items = [] } = cluster || {};
      const isChecked = items.some(
        ({ [uniqueKey]: checkedItemId }) => checkedItemId === restItem[uniqueKey]
      );

      checkboxesMapping[parent[uniqueKey]] = {
        ...checkboxesMapping[parent[uniqueKey]] ? checkboxesMapping[parent[uniqueKey]] : parent,
        items: isChecked
          ? items.filter(({ [uniqueKey]: checkedItemId }) => checkedItemId !== restItem[uniqueKey])
          : [...items, restItem],
      };
    } else {
      checkboxesMapping[restItem[uniqueKey]] = !checkboxesMapping[restItem[uniqueKey]]
        ? restItem
        : null;
    }

    this.setState({ checkboxesMapping });

    onSelectItems && onSelectItems(this.parseCheckboxesItems(checkboxesMapping));

    if (disableScrollToHeader) {
      disableScrollToHeader();
    }
  };

  filterVisibleChildren = (children) => {
    const { hiddenColumns } = this.props;
    return Children.toArray(children)
      .filter(({ props }) => !hiddenColumns[props.keyName]);
  };

  handleSelectAll = (selected) => {
    const { data, uniqueKey, onSelectItems, disableScrollToHeader } = this.props;
    const { checkboxesMapping } = this.state;

    data.forEach((item) => {
      checkboxesMapping[item[uniqueKey]] = selected ? item : null;
    });

    this.setState({
      checkboxesMapping,
      selectAll: selected,
    });

    onSelectItems && onSelectItems(this.parseCheckboxesItems(checkboxesMapping));

    if (disableScrollToHeader) {
      disableScrollToHeader();
    }
  };

  handleRadioButtonChange = (uniqueKeyId) => {
    const { data, uniqueKey, onSelectItems } = this.props;

    this.setState({ selectedRadioButton: String(uniqueKeyId) });

    onSelectItems && onSelectItems(data.filter(item => String(item[uniqueKey]) === String(uniqueKeyId)));
  };

  handleTooltipClose = () => setTimeout(this.tooltipRef.current.toggleHint, 0);

  visibleColumns = () => {
    const { columns, hiddenColumns } = this.props;
    return columns.filter(col => !hiddenColumns[col.keyName]);
  };

  renderDimensionsForm = () => {
    const {
      columns,
      hiddenColumns,
      onColumnToggle,
      isMobile,
    } = this.props;
    if (!onColumnToggle) {
      return null;
    }

    const dimensions = columns.filter(column => column.allowToggle !== false);

    return (
      <Dimensions
        columns={dimensions}
        visibleColumns={this.visibleColumns()}
        hidden={hiddenColumns}
        onToggle={onColumnToggle}
        onClose={this.handleTooltipClose}
        isMobile={isMobile}
      />
    );
  };

  render() {
    const {
      isFixed,
      isLoading,
      isInitialLoading,
      rowLengthPlaceholder,
      data,
      onSort,
      sortColumn,
      sortDirection,
      containerStyle,
      onColumnToggle,
      uniqueKey,
      isCheckboxPresent,
      isRadioButtonPresent,
      isSelectNonePresent,
      customEmptyMessage,
      emptyValuePlaceholder,
      tableStyle,
      renderExpandedRow,
      fullWidthTable,
      tableWrapperClassname,
      tableContainerClassname,
      theme,
      isAlignedToTop,
      fixedEmptyMessage,
      droppableId,
      isDroppingDisabled,
      onDragEnd,
      withDragNDrop,
      isMobile,
    } = this.props;
    const { selectAll, checkboxesMapping, offsetHeight, selectedRadioButton, windowInnerHeight } = this.state;
    const { children: propsChildren } = this.props;
    const children = this.filterVisibleChildren(propsChildren);

    let style = tableStyle;
    if (isFixed && fullWidthTable) style = { ...style, height: windowInnerHeight - offsetHeight };

    const tableContainerClassNames = classNames(
      styles.tableContainer,
      containerStyle,
      !isFixed && styles.defaultShadow,
      tableContainerClassname,
    );

    return (
      <div
        className={classNames(
          isFixed && styles.fixedTable,
          fullWidthTable && styles.fullWidth,
          themes[theme],
          isAlignedToTop && styles.alignedToTop,
          tableWrapperClassname,
        )}
        ref={this.tableRef}
      >
        <div className={classNames(styles.tableWrapper, isCheckboxPresent && styles.checkboxWrap)} ref={this.tableWrapperRef}>
          {!isLoading && onColumnToggle && (
            <div className={styles.contextualMenuWrapper}>
              <Tooltip
                persist
                forwardRef={this.tooltipRef}
                content={this.renderDimensionsForm}
                className={styles.dimensions}
              >
                <ContextualMenu position="right" tooltipRef={this.tooltipRef} />
              </Tooltip>
            </div>
          )}
          <section className={styles.tableSection}>
            {isFixed && <hr className={styles.headerShadow} />}
            <div
              className={tableContainerClassNames}
              style={{
                ...style,
                height: style.height + (isMobile ? MOBILE_HEIGHT_OFFSET : 0),
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={droppableId} isDroppingDisabled={isDroppingDisabled}>
                  {provided => (
                    <table ref={provided.innerRef}>
                      <Header
                        isInitialLoading={isInitialLoading}
                        isLoading={isLoading}
                        headerRef={this.headerRef}
                        isCheckboxPresent={isCheckboxPresent}
                        isRadioButtonPresent={isRadioButtonPresent}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        selectAll={selectAll}
                        columns={this.visibleColumns()}
                        onSelectAll={this.handleSelectAll}
                        onSort={onSort}
                        isFixed={isFixed}
                      />
                      <Body
                        isInitialLoading={isInitialLoading}
                        isLoading={isLoading}
                        rowLengthPlaceholder={rowLengthPlaceholder}
                        data={data}
                        columnLength={this.visibleColumns().length}
                        isCheckboxPresent={isCheckboxPresent}
                        isRadioButtonPresent={isRadioButtonPresent}
                        isSelectNonePresent={isSelectNonePresent}
                        selectedRadioButton={selectedRadioButton}
                        onRadioButtonChange={this.handleRadioButtonChange}
                        uniqueKey={uniqueKey}
                        checkboxesMapping={checkboxesMapping}
                        onRowCheckChange={this.handleRowCheckChanges}
                        customEmptyMessage={customEmptyMessage}
                        emptyValuePlaceholder={emptyValuePlaceholder}
                        renderExpandedRow={renderExpandedRow}
                        fixedEmptyMessage={fixedEmptyMessage}
                        droppablePlaceholder={provided.placeholder}
                        isDroppingDisabled={isDroppingDisabled}
                        withDragNDrop={withDragNDrop}
                      >
                        {children}
                      </Body>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

// TODO: define shapes
Container.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isScrollToHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  rowLengthPlaceholder: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isCheckboxPresent: PropTypes.bool,
  isRadioButtonPresent: PropTypes.bool,
  isSelectNonePresent: PropTypes.bool,
  customEmptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    keyName: PropTypes.string,
    allowToggle: PropTypes.bool,
  })).isRequired,
  uniqueKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]),
  onSort: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  hiddenColumns: PropTypes.shape({}),
  onColumnToggle: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectItems: PropTypes.func,
  containerStyle: PropTypes.string,
  emptyValuePlaceholder: PropTypes.node,
  tableStyle: PropTypes.shape({}),
  disableScrollToHeader: PropTypes.func,
  renderExpandedRow: PropTypes.func,
  isFixed: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDesktopLarge: PropTypes.bool,
  isPaginatorPresent: PropTypes.bool,
  fullWidthTable: PropTypes.bool,
  preserveCheckboxes: PropTypes.bool,
  tableWrapperClassname: PropTypes.string,
  tableContainerClassname: PropTypes.string,
  theme: PropTypes.string,
  isAlignedToTop: PropTypes.bool,
  fixedEmptyMessage: PropTypes.bool,
  droppableId: PropTypes.string,
  isDroppingDisabled: PropTypes.bool,
  onDragEnd: PropTypes.func,
  withDragNDrop: PropTypes.bool,
};

Container.defaultProps = {
  hiddenColumns: {},
  data: [],
  uniqueKey: 'id',
  isCheckboxPresent: false,
  isRadioButtonPresent: false,
  isSelectNonePresent: false,
  isScrollToHeader: false,
  isFixed: false,
  isMobile: false, // Required when table is fixed
  isPaginatorPresent: false, // Required when table is fixed
  fullWidthTable: false,
  theme: 'default',
  isAlignedToTop: false,
  selectedItems: [],
  droppableId: 'droppable-table',
  isDroppingDisabled: true,
  onDragEnd: () => {},
  withDragNDrop: false,
  tableStyle: {},
};

export { Column };
