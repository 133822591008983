import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import useActiveItem from 'components/core/AdvancedSearch/utils/useActiveItem';

import withSearch from 'components/core/AdvancedSearch/utils/withSearch';
import OptionsSection from '../OptionsSection';
import TopBar from './components/TopBar';
import ListItems from './components/ListItems';
import styles from './styles.module.scss';

function ListSearches({
  isMobile,
  savedItems,
  recentItems,
  onSearch,
  onClearAll,
  loadSavedSearches,
  loadRecentSearches,
  isDirty,
  allowSubmit,
  isClickedItem,
}) {
  const {
    isNew,
    isRecent,
    isSaved,
    activeBtn,
    onChange,
  } = useActiveItem({ isMobile, loadSavedSearches, loadRecentSearches, onClearAll });

  const showClearAll = allowSubmit && !!onClearAll;
  const showSearch = !!onSearch;

  function renderList() {
    if (isMobile && isNew) {
      return (
        <div className={styles.mobileOptions}>
          <OptionsSection />
          <div>
            {showClearAll && (
              <Button
                size="small"
                theme="light"
                onClick={onClearAll}
                className={classNames(styles.mobileSearchBtn, styles.clearAll)}
              >
                Clear All
              </Button>
            )}
            {showSearch && (
              <Button
                size="small"
                onClick={onSearch}
                className={styles.mobileSearchBtn}
              >
                Search
              </Button>
            )}
          </div>
        </div>
      );
    }

    return (
      <ListItems
        activeBtn={activeBtn}
        isRecent={isRecent}
        isSaved={isSaved}
        items={isSaved ? savedItems : recentItems}
        isDirty={isDirty}
        onSearch={onSearch}
        isMobile={isMobile}
        isClickedItem={isClickedItem}
      />
    );
  }

  return (
    <div className={styles.content}>
      <TopBar
        isNew={isNew}
        isRecent={isRecent}
        isSaved={isSaved}
        onClick={onChange}
      />

      {renderList()}
    </div>
  );
}

ListSearches.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  savedItems: PropTypes.array.isRequired,
  recentItems: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  onClearAll: PropTypes.func,
  loadSavedSearches: PropTypes.func.isRequired,
  loadRecentSearches: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  allowSubmit: PropTypes.bool.isRequired,
  isClickedItem: PropTypes.bool.isRequired,
};

export default withSearch(ListSearches);
