import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import withSearch from 'components/core/SearchHeader/utils/withSearch';

import styles from '../styles.module.scss';

function ToggleButton({ customToggleViewProps, isMobile }) {
  const {
    isCustomViewOpen,
    onClick,
    buttonClassName,
    showText,
    hideText,
    iconClassName,
    isDisabled,
  } = customToggleViewProps;
  const text = isCustomViewOpen ? hideText : showText;
  const buttonClassNames = classNames(styles.button, buttonClassName);
  const iconClassNames = classNames(
    styles.icon,
    iconClassName,
    isCustomViewOpen && styles.activeIcon,
  );

  if (isMobile) {
    return (
      <Button
        theme="default"
        className={buttonClassNames}
        onClick={onClick}
        customIcon={<i className={iconClassNames} />}
      />
    );
  }

  return (
    <Button
      theme="light"
      className={buttonClassNames}
      onClick={onClick}
      customIcon={<i className={iconClassNames} />}
      disabled={isDisabled}
    >
      <span className={styles.text}>{text}</span>
    </Button>
  );
}

ToggleButton.propTypes = {
  customToggleViewProps: PropTypes.shape({
    isCustomViewOpen: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    buttonClassName: PropTypes.string,
    iconClassName: PropTypes.string.isRequired,
    showText: PropTypes.string.isRequired,
    hideText: PropTypes.string.isRequired,
  }),
  isMobile: PropTypes.bool.isRequired,
};

export default withSearch(ToggleButton);
