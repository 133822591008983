import { useState } from 'react';

function useExpander() {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = rowId => () => {
    setExpandedRows({
      ...expandedRows,
      [rowId]: !expandedRows[rowId],
    });
  };

  const isRowExpanded = rowId => !!expandedRows[rowId];

  return {
    toggleRow,
    isRowExpanded,
  };
}

export default useExpander;
