const advancedOperators = [
  {
    name: 'exactPhrase',
    regex: /".*"/,
  },
  {
    name: 'orConditional',
    regex: /\|/,
  },
  {
    name: 'bothConditional',
    regex: /\+/,
  },
  {
    name: 'excludeConditional',
    regex: /-\w+/,
  },
  {
    name: 'prefixConditional',
    regex: /\w+\*/,
  },
  {
    name: 'withinConditional',
    regex: /\w+\/\d/,
  },
  {
    name: 'precedingMatchConditional',
    regex: /~N/,
  },
  {
    name: 'groupConditional',
    regex: /\(.*\)/,
  },
];

const containsAdvancedOperators = term => (
  advancedOperators.some(operator => operator.regex.test(term))
);

export { advancedOperators, containsAdvancedOperators };
