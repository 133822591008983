import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'formik';

import Button from 'components/core/Button';

import withCustomField from '../../utils/withCustomField';
import { uiShape, fieldTypeShape, optionShape } from '../../utils/shapes';
import { isEmptyFieldType, hasOptions } from '../../utils';
import Option from './components/Option';
import styles from './styles.module.scss';

function FieldsOptions({
  ui,
  fieldType,
  options,
  handleChange,
  handleBlur,
}) {
  if (isEmptyFieldType(fieldType)) {
    return null;
  }

  const isLoading = ui.isSavingCustomField || ui.isUpdatingCustomField;
  const defaultValue = { value: '' };
  const commonProps = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <div className={styles.fieldsInteraction}>
      {hasOptions(fieldType.value) && (
        <FieldArray
          name="options"
          render={({ remove, push }) => (
            <>
              <div className={classNames(styles.formGroup, styles.options)}>
                {options.map((option, index) => (
                  <Option
                    {...commonProps}
                    // this component is used for creating an option, there no unique key available
                    // eslint-disable-next-line react/no-array-index-key
                    key={`custom-option-${index}`}
                    index={index}
                    option={option}
                    onRemove={remove}
                    optionsCount={options.length}
                  />
                ))}
              </div>

              <div className={styles.formGroup}>
                <Button
                  type="button"
                  size="none"
                  theme="light"
                  onClick={() => push(defaultValue)}
                  isAddIcon
                  disabled={isLoading}
                >
                  Add Value
                </Button>
              </div>
            </>
          )}
        />
      )}
    </div>
  );
}

FieldsOptions.propTypes = {
  ui: uiShape.isRequired,
  fieldType: fieldTypeShape.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default withCustomField(FieldsOptions);
