const labels = {
  title: {
    name: 'Title',
    desc: 'This is the label that will be displayed for this search on your smart search home.',
  },
  description: {
    name: 'Description',
    desc: 'This is the description that will be displayed on smart search home (recommended < 155 characters).',
  },
  isTemplate: {
    name: 'Template',
    desc: 'Check to mark this search as template',
  },
};

export default labels;
