import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

function StateDelegation({ keyName, titles, value, onChange, className, resTransform }) {
  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      {...titles}
      className={className}
      name={keyName}
      strategy="mappy_state_alpha2"
      defaultSelections={value}
      overrideValue={value}
      onChange={handleChange}
      resTransform={resTransform}
    />
  );
}

StateDelegation.defaultProps = {
  keyName: 'stateDelegation',
  titles: {
    title: 'State delegation',
    placeholder: 'State in which the legislator serves (e.g., California, Iowa)',
  },
};

StateDelegation.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  resTransform: PropTypes.func,
};

export default StateDelegation;
