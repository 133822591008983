import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Placeholder from './Placeholder';
import ErrorPdf from './ErrorPdf';

import styles from '../styles.module.scss';

export default function Iframe({ sourceType, link, iframeClass, originalSourceUrl }) {
  const [withIframe, setWithIframe] = useState(null);
  const ajax = () => $.ajax({
    // TODO: type options will set always withIframe true, investigate later
    type: sourceType === 'IRS' ? 'OPTIONS' : 'GET',
    url: link,
  });

  useEffect(() => {
    Promise.resolve(ajax())
      .then(() => setWithIframe(true))
      .catch(() => setWithIframe(false));
  }, []);

  if (withIframe === false) return <ErrorPdf originalSourceUrl={originalSourceUrl} />;

  return (
    <>
      <Placeholder />
      <iframe
        src={withIframe && link}
        frameBorder="0"
        className={classNames(styles.iframe, iframeClass)}
        title="PDF file"
      />
    </>
  );
}

Iframe.propTypes = {
  link: PropTypes.string.isRequired,
  sourceType: PropTypes.string,
  iframeClass: PropTypes.string,
  originalSourceUrl: PropTypes.string,
};
