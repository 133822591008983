import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, uniq } from 'lodash';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';
import { stafferShape } from 'components/core/Profile/components/Cards/Staffers/utils/shapes';

function RoleColumn({ keyName, item }) {
  const { positions } = item || {};
  const roles = uniq(map(positions, 'role'));

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={roles}
      isEmptyData={isEmpty(roles)}
    />
  );
}

RoleColumn.propTypes = {
  keyName: PropTypes.string,
  item: stafferShape,
};

export default RoleColumn;
