import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useSortTable from 'utils/useSortTable';
import { getColumnLabelBySortingKey } from 'utils/table';

function useTable({
  sort: sortRedux,
  isLoadingPublicRecords,
  paginator,
  onSearch,
  trackingContext,
  toResetTable,
  onResetTable,
  tableColumns,
  trackPageVisited,
  trackColumnSorted,
}) {
  const sort = useSortTable({ toReset: toResetTable }, isLoadingPublicRecords);

  const isPaginatorPresent = !isEmpty(paginator);

  /** Fix default sort  */
  useEffect(() => {
    if (!isEmpty(sortRedux)) {
      const { sort: reduxColumn, direction } = sortRedux;

      sort && sort.setSortData({ direction, column: reduxColumn });
    }
  }, [sortRedux]);

  function handleSortColumn(column) {
    onResetTable(false);

    const { getDirection } = sort;
    const direction = getDirection(column);
    const columnLabel = getColumnLabelBySortingKey(tableColumns, column);

    onSearch({ sort: column, direction });
    trackColumnSorted(trackingContext, columnLabel, direction);
  }

  function handleChangePage(page) {
    onResetTable(false);

    const { sortData = {} } = sort;
    const { column, direction } = sortData;

    onSearch({ page, sort: column, direction });
    trackPageVisited(trackingContext, page);
  }

  return {
    ...sort,
    isPaginatorPresent,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
  };
}

export default useTable;
