import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual, isEmpty, isFunction } from 'lodash';
import { pluralize } from 'utils/string';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import Description from './components/Description';
import OpenIcon from './components/OpenIcon';
import ActionBarTransition from './components/Transition';
import useChatPosition from './useChatPosition';
import { defaultDescription } from './utils';

import styles from './styles.module.scss';

const themes = {
  default: styles.default,
  light: styles.light,
};

function ActionBar({
  isDefaultOpen,
  children,
  title,
  description,
  isFixed,
  selectedItems,
  persist,
  className,
  contentClassName,
  theme,
  withChatOffset,
}) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const areItemsEmpty = isEmpty(selectedItems);
  const { isMobile, isDesktop } = useMediaQuery();
  const MOBILE_CLOSED_ACTION_BAR_DESCRIPTION = 'Click to reveal options';

  /**
  * Action bar must always be rendered for this hook to work properly.
  */
  useChatPosition(areItemsEmpty, withChatOffset, persist);

  if (!isMobile && isOpen) setIsOpen(false);

  const isDescriptionsChanged = !isEqual(description, ActionBar.defaultProps.description);
  const descriptions = isDescriptionsChanged || areItemsEmpty ? description : {
    default: `${selectedItems.length} ${pluralize('record', 'records', selectedItems.length)} selected`,
  };

  const getDescriptionPerBreakpoint = () => {
    if (isMobile && descriptions.mobile && isOpen) return descriptions.mobile;
    if (isMobile && !isOpen) return MOBILE_CLOSED_ACTION_BAR_DESCRIPTION;
    if (isDesktop && descriptions.desktop) return descriptions.desktop;

    return descriptions.default;
  };

  const openActionBar = () => isMobile && setIsOpen(!isOpen);
  const content = isFunction(children) ? children({ toggleVisibility: setIsOpen }) : children;
  const contentClassNames = classNames(
    contentClassName,
    styles.content,
  );

  return (
    <ActionBarTransition id="action-bar-transition" enable={isOpen}>
      <section
        id="action-bar"
        className={classNames(
          'action-bar',
          styles.actionBar,
          themes[theme],
          className,
          isOpen && styles.isOpen,
          isFixed && styles.isFixed,
          !isFixed && styles.isRelative,
        )}
      >
        <div className={contentClassNames}>
          <div className={styles.headerContent} onClick={openActionBar}>
            <Description title={title} description={getDescriptionPerBreakpoint()} />
            {isMobile && <OpenIcon isOpen={isOpen} />}
          </div>
          <div className={styles.childrenContent}>
            {content}
          </div>
        </div>
      </section>
    </ActionBarTransition>
  );
}

ActionBar.propTypes = {
  title: PropTypes.string,
  isFixed: PropTypes.bool,
  description: PropTypes.object,
  selectedItems: PropTypes.array,
  persist: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  theme: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
  withChatOffset: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

ActionBar.defaultProps = {
  title: 'Actions',
  isFixed: true,
  isDefaultOpen: false,
  withChatOffset: false,
  description: defaultDescription,
  persist: false,
  selectedItems: [],
  theme: 'default',
};

export default ActionBar;
