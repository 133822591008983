import { useState } from 'react';
import { xorWith, some, isEqual, orderBy } from 'lodash';

function useItemsSelected() {
  const [itemsSelected, setItemsSelected] = useState([]);

  function onChange(item) {
    const newItems = xorWith(itemsSelected, [item], isEqual);

    setItemsSelected(newItems);
  }

  const isChecked = item => some(itemsSelected, item);
  const itemsSelectedSorted = orderBy(itemsSelected, ['value']);

  return {
    itemsSelected: itemsSelectedSorted,
    itemsSelectedManager: {
      onChange,
      isChecked,
    },
  };
}

export default useItemsSelected;
