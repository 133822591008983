import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';

import styles from '../styles.module.scss';

function Items({ items }) {
  if (isEmpty(items)) {
    return null;
  }

  function renderItem(item, index) {
    const keyName = `item-legislator-${index}`;

    if (isEmpty(item)) {
      return <div key={keyName}>{EMPTY_VALUE}</div>;
    }

    const { value, href } = item || {};

    if (isEmpty(href)) {
      return <div key={keyName}>{value}</div>;
    }

    return (
      <Link
        href={href}
        key={keyName}
        theme="default"
        className={styles.itemLink}
      >
        {value}
      </Link>
    );
  }

  return (
    <div className={styles.itemsContainer}>
      {items.map(renderItem)}
    </div>
  );
}

Items.propTypes = {
  items: PropTypes.array,
};

export default Items;
