import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ActionBarDescription({ title, description }) {
  return (
    <Fragment>
      { title && <span className={styles.title}>{ title }</span> }
      { description && (
        <Fragment>
          <span className={styles.separator}> | </span>
          <span className={styles.description}>{ description }</span>
        </Fragment>
      )}
    </Fragment>
  );
}

ActionBarDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

ActionBarDescription.defaultProps = {
  title: 'Actions',
  description: 'Select the record(s) you wish to alter and then perform action',
};

export default ActionBarDescription;
