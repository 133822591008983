import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { BaseOption } from 'components/core/AdvancedSearch';

import RecordTypesButton from '../../RecordTypesButton';
import Types from '../../Types';
import styles from '../styles.module.scss';

function RecordTypeFilter({
  recordTypes,
  recordTypeTitles,
  baseOptionClassName,
  displayButtonRecordTypes,
}) {
  if (isEmpty(recordTypes)) {
    return null;
  }

  if (displayButtonRecordTypes) {
    return (
      <BaseOption
        {...recordTypeTitles}
        className={baseOptionClassName}
        customComponent={<RecordTypesButton />}
      />
    );
  }

  return (
    <BaseOption
      {...recordTypeTitles}
      className={classNames(baseOptionClassName, styles.regularRecordType)}
      customComponent={<Types />}
    />
  );
}

RecordTypeFilter.propTypes = {
  recordTypes: PropTypes.array.isRequired,
  recordTypeTitles: PropTypes.object.isRequired,
  baseOptionClassName: PropTypes.string.isRequired,
  displayButtonRecordTypes: PropTypes.bool.isRequired,
};

export default RecordTypeFilter;
