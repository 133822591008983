import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import { PageHeaderPlaceholder } from 'components/core/Placeholder';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import MobileHeaderBar from '../MobileHeaderBar';
import TitleContainer from '../TitleContainer';
import styles from './styles.module.scss';

function Container({
  isLoading,
  customBreadcrumb,
  rightContent,
  withBorder,
  isFullWidth,
  isFullWidthTitle,
  isPresentAvatar,
  headerClassName,
  rightContentClassName,
  headerContentClassName,
  applyExtraMargin,
  isRightOffset,
  customMobileTitle,
  isMobileOffsetVisible,
  customMobileContent,
  hasActionsOnly,
  ...restProps
}) {
  const { isMobile } = useMediaQuery();
  const headerClassNames = classNames(
    'header-page',
    isFullWidth && 'full-width',
    withBorder && styles.headerBorder,
    isRightOffset && styles.offsetRight,
    headerClassName,
  );

  const headerContentClassNames = classNames(
    'header-content',
    !isFullWidth && styles.globalMaxWidth,
    headerContentClassName
  );

  function renderContent() {
    if (isLoading) {
      return (
        <PageHeaderPlaceholder
          isPresentAvatar={isPresentAvatar}
          isFullWidth={isFullWidth}
        />
      );
    }

    if (isMobile && customMobileContent) {
      return isFunction(customMobileContent)
        ? customMobileContent()
        : customMobileContent;
    }

    const titleContainer = (
      <TitleContainer
        {...restProps}
        isFullWidthTitle={isFullWidthTitle}
        isMobile={isMobile}
      />
    );

    return (
      <header className={headerClassNames}>
        <div className={headerContentClassNames}>
          <div className="title-container">
            {!hasActionsOnly && (
              <div className={styles.leftContent}>
                <div className={styles.mainContent}>
                  {!isFullWidthTitle && titleContainer}
                </div>
              </div>
            )}
            <div className={styles.rightContent}>
              {rightContent && (
                <div id="custom-content" className={rightContentClassName}>
                  {rightContent}
                </div>
              )}
            </div>
          </div>
          {isFullWidthTitle && titleContainer}
          {applyExtraMargin && <div className={styles.extraMargin} />}
        </div>
      </header>
    );
  }

  return (
    <>
      {isMobile && (
        <MobileHeaderBar
          {...restProps}
          customBreadcrumb={customBreadcrumb}
          customParentTitle={customMobileTitle}
          isOffsetVisible={isMobileOffsetVisible}
        />
      )}
      {renderContent()}
    </>
  );
}

Container.defaultProps = {
  isFullWidth: false,
  isMobileOffsetVisible: true,
  hasActionsOnly: false,
};

Container.propTypes = {
  isLoading: PropTypes.bool,
  customBreadcrumb: PropTypes.string,
  rightContent: PropTypes.node,
  rightContentClassName: PropTypes.string,
  withBorder: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isFullWidthTitle: PropTypes.bool,
  isPresentAvatar: PropTypes.bool,
  headerClassName: PropTypes.string,
  headerContentClassName: PropTypes.string,
  applyExtraMargin: PropTypes.bool,
  isRightOffset: PropTypes.bool,
  customMobileTitle: PropTypes.node,
  isMobileOffsetVisible: PropTypes.bool,
  hasActionsOnly: PropTypes.bool,
  customMobileContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Container;
