import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import constants from 'components/core/HearingCalendars/utils/constants';
import { EASTERN_TIME_ABBREVIATION } from 'utils/constants/date';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Badge from 'components/core/Badge';

import styles from './styles.module.scss';

function BadgeInfo({ committeeType, scheduledDate }) {
  const { isTablet } = useMediaQuery();
  const withCommitteeType = Boolean(committeeType);
  const isSenateCommittee = committeeType === constants.SENATE_COMMITTEE;
  const committeeBadgeStyle = isSenateCommittee
    ? styles.senateBadge
    : styles.houseBadge;

  const momentDate = moment(scheduledDate);
  const isPastDate = momentDate.isBefore();
  const day = momentDate.format('ddd');
  const dateAndMonth = momentDate.format('MMMM D');
  const time = momentDate.format('h:mm a');

  return (
    <div className={styles.badgeContainer}>
      <div className={styles.leftItems}>
        {time && (
          <Badge
            className={classNames(styles.badge, isPastDate && styles.expiredBadge)}
            theme="primary"
          >
            {time} {EASTERN_TIME_ABBREVIATION}
          </Badge>
        )}

        {(isTablet && dateAndMonth) && (
          <Badge className={classNames(styles.badge, styles.largeBadge)}>
            {day}, {dateAndMonth}
          </Badge>
        )}
      </div>

      <div className={styles.rightItems}>
        {(isTablet && isPastDate) && (
          <Badge
            className={classNames(styles.badge, styles.expiredBadge)}
            theme="light"
          >
            Archived
          </Badge>
        )}
        {withCommitteeType && (
          <div className={classNames(styles.roundBadge, committeeBadgeStyle)}>
            {isSenateCommittee ? 'S' : 'H'}
          </div>
        )}
      </div>
    </div>
  );
}

BadgeInfo.propTypes = {
  scheduledDate: PropTypes.string.isRequired,
  committeeType: PropTypes.oneOf([
    constants.SENATE_COMMITTEE,
    constants.HOUSE_COMMITTEE,
    constants.JOINT_COMMITTEE,
  ]),
};

export default BadgeInfo;
