import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function OpenIcon({ isOpen }) {
  function renderIcon() {
    if (isOpen) {
      return <i className="fal fa-times" />;
    }
    return <i className={'fal fa-chevron-double-up'} />;
  }

  return (
    <span className={styles.openIcon}>
      {renderIcon()}
    </span>
  );
}

OpenIcon.propTypes = {
  isOpen: PropTypes.bool,
};

OpenIcon.defaultProp = {
  isOpen: false,
};

export default OpenIcon;
