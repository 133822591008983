import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import DuotoneIcon from 'components/core/DuotoneIcon';

import { optionTypes } from '../utils';
import { TYPES } from '../constants';
import styles from '../styles.module.scss';

function OptionIcon({
  type,
  strategy,
  isVisible,
  customIconProps,
}) {
  if (!isVisible) return null;

  const optionProps = optionTypes[type || strategy] || {};
  const { iconProps, CustomIconComponent } = optionProps;

  if (isEmpty(iconProps)) {
    return <i className={classNames(styles.emptyIcon, styles.icon)} />;
  }

  const { className, theme, ...restIconProps } = iconProps;

  if (CustomIconComponent) {
    return (
      <CustomIconComponent className={className} {...customIconProps} {...restIconProps} />
    );
  }

  return (
    <DuotoneIcon
      className={classNames(styles.icon, className)}
      theme={theme}
      {...restIconProps}
    />
  );
}

OptionIcon.defaultProps = {
  customIconProps: {},
  isVisible: true,
};

OptionIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
  strategy: PropTypes.string,
  // eslint-disable-next-line
  customIconProps: PropTypes.object,
  isVisible: PropTypes.bool,
};

export default OptionIcon;
