import { useEffect, useState } from 'react';
import { pick, omit, join, omitBy, keys } from 'lodash';

import { queryParse, queryStringify } from 'utils/query-string';

import { SEARCH_PARAMS } from './constants';

export default function useSearchParams({
  sortDirection,
  sortColumn,
  currentPage,
  lastSearchId,
  history = window.history,
  location = window.location,
  initialQuery = '',
}) {
  const [queryString, setQueryString] = useState(initialQuery);

  const getUrlParams = () => {
    const { search } = location;

    return queryParse(search);
  };

  const getSearchParams = () => {
    const allParams = getUrlParams();

    return pick(allParams, SEARCH_PARAMS);
  };

  const updateSearchParams = (params, paramsToRemove = []) => {
    const { pathname } = location;
    const validParams = omitBy(params, param => !param);
    const invalidParamsKeys = keys(omitBy(params, param => !!param));
    const currentValidParams = omit(getUrlParams(), invalidParamsKeys);

    const searchParams = omit({ ...currentValidParams, ...validParams }, paramsToRemove);
    const query = queryStringify(searchParams);
    const path = join([pathname, query], query ? '?' : '');

    setQueryString(query);
    history.replaceState({ path }, null, path);
  };

  const handleSearchChange = (search) => {
    updateSearchParams({ search });
  };

  const handleSearchIdChange = (searchId) => {
    updateSearchParams({ searchId });
  };

  const handleReset = () => updateSearchParams({}, SEARCH_PARAMS);

  const clearParams = params => updateSearchParams({}, params);

  useEffect(() => {
    updateSearchParams({ sort: sortColumn });
  }, [sortColumn]);

  useEffect(() => {
    updateSearchParams({ direction: sortDirection });
  }, [sortDirection]);

  useEffect(() => {
    updateSearchParams({ page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    handleSearchIdChange(lastSearchId);
  }, [lastSearchId]);

  return {
    getSearchParams,
    handleReset,
    handleSearchChange,
    handleSearchIdChange,
    updateSearchParams,
    queryString,
    clearParams,
  };
}
