import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import Button from 'components/core/Button';

import styles from '../../styles.module.scss';

export default function EditButton({ onClick, isEnabled, children }) {
  const Control = (
    <Button
      theme="secondary"
      size="small"
      type="button"
      onClick={onClick}
      className={styles.actionBtn}
      disabled={!isEnabled}
    >
      {children}
    </Button>
  );
  const Hint = (
    <p className={styles.tooltipText}>
      Editing is disabled because this field already has values.
    </p>
  );

  if (isEnabled) {
    return Control;
  }

  return (
    <Tooltip
      className={classNames(styles.tooltipWrapper, styles.tooltipContainer)}
      content={Hint}
      placement="bottom"
      interactive
      appendTo={document.body}
    >
      <span tabIndex="0">
        {Control}
      </span>
    </Tooltip>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
