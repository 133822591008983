import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getIsTracking } from 'components/core/Table/components/ColumnItem/ActionsItem/utils';
import TrackButton, { subscriptionInfoShape } from 'components/core/TrackButton';
import { tagShape, listSubscriptionsShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { positions } from 'components/core/ButtonPosition/utils/constants';
import { notifySetPosition } from 'components/Dashboard/common/UserNotifications';
import PositionButton from 'components/core/ButtonPosition';
import ViewTags from 'components/core/ViewTags';
import { workspaceShape } from 'redux/events/utils/shapes';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

import { subscriptionsShape } from '../../utils/shapes';
import styles from './styles.module.scss';

function CallToActions({
  withTrack,
  withPosition,
  withWeekAheadEmail,
  isCommittee,
  isLoadingSubscription,
  isMultipleSubscription,
  isLoadingMyFeedDocuments,
  isLoadingTags,
  isMobile,
  id,
  tags = [],
  documentType,
  itemType,
  className,
  containerClassName,
  defaultSubscription,
  position,
  placeholderClassName,
  positionClassName,
  shouldDisplayIcon,
  subscriptions,
  listSubscriptions,
  onUpdateTrackings,
  updateCommitteeSubscriptions,
  onTagClick,
  emptyTagsPlaceholder,
  withBadges,
  feedToken,
  sharings,
  trackings,
}) {
  const isTracking = getIsTracking(trackings);
  const subscriptionInfo = { withWeekAheadEmail, isMultipleSubscription };
  const commonProps = {
    recordId: id,
    recordType: documentType,
    isMobile,
  };

  const updateParams = {
    modelId: id,
    id,
  };

  function handleSetPosition(newPosition) {
    notifySetPosition({
      itemType,
      positionLabel: positions[newPosition].label,
    });
  }

  const isLoadingWorkspaceTags = isLoadingSubscription || isLoadingMyFeedDocuments;

  return (
    <div className={classNames(styles.container, containerClassName)}>
      {withBadges && (
        <div className={styles.badgesContainer}>
          <ViewTags
            subscriptions={subscriptions}
            isLoading={isLoadingTags || isLoadingWorkspaceTags}
            tags={tags}
            onClick={onTagClick}
            className={classNames(styles.badges, className)}
            emptyValue={emptyTagsPlaceholder}
            sharings={sharings}
            trackings={trackings}
            displayAllWorkspaces
            withInteractiveTags
          />
        </div>
      )}
      <div className={styles.callToActions}>
        {withTrack && (
          <TrackButton
            {...commonProps}
            isTracking={isTracking}
            isCommittee={isCommittee}
            isLoadingTracking={isLoadingSubscription}
            shouldDisplayIcon={shouldDisplayIcon}
            itemType={itemType}
            updateParams={updateParams}
            defaultSubscription={defaultSubscription}
            subscriptionInfo={subscriptionInfo}
            listSubscriptions={listSubscriptions}
            updateDataItem={onUpdateTrackings}
            updateCommitteeSubscriptions={updateCommitteeSubscriptions}
            feedToken={feedToken}
            trackings={trackings}
            withButtonContent
          />
        )}

        {withPosition && (
          <PositionButton
            {...commonProps}
            containerClassName={positionClassName}
            placeholderClassName={placeholderClassName}
            position={position}
            handleSetPosition={handleSetPosition}
            shouldFetchOnInit
            isLoadingPosition
          />
        )}
      </div>
    </div>
  );
}

CallToActions.defaultProps = {
  withBadges: true,
};

CallToActions.propTypes = {
  withPosition: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool,
  isCommittee: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  documentType: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  itemType: PropTypes.string,
  subscription: subscriptionInfoShape,
  subscriptions: subscriptionsShape,
  listSubscriptions: listSubscriptionsShape,
  withTrack: PropTypes.bool,
  withWeekAheadEmail: PropTypes.bool,
  isLoadingSubscription: PropTypes.bool,
  isMultipleSubscription: PropTypes.bool,
  defaultSubscription: PropTypes.string,
  placeholderClassName: PropTypes.string,
  positionClassName: PropTypes.string,
  position: PropTypes.string,
  shouldDisplayIcon: PropTypes.bool,
  onUpdateTrackings: PropTypes.func,
  updateCommitteeSubscriptions: PropTypes.func,
  onTagClick: PropTypes.func,
  emptyTagsPlaceholder: PropTypes.node,
  withBadges: PropTypes.bool,
  feedToken: PropTypes.string,
  sharings: PropTypes.arrayOf(workspaceShape),
  trackings: PropTypes.arrayOf(trackingShape),
};

export default CallToActions;
