import React from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import TimePickerInput from './components/TimePickerInput';

import { TIME_FORMAT } from './constants';
import styles from './styles.module.scss';

function TimePicker({
  placeholder,
  value,
  onChange,
  className,
  interval,
  isDisabled,
  ...restProps
}) {
  return (
    <span className={styles.tooltip}>
      <ReactDatePicker
        {...restProps}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={interval}
        dateFormat={TIME_FORMAT}
        customInput={<TimePickerInput customClassName={className} isDisabled={isDisabled} />}
        placeholderText={placeholder}
        selected={value}
        onChange={onChange}
      />
    </span>
  );
}

TimePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  interval: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  value: null,
  interval: 15,
  isDisabled: false,
  placeholder: TIME_FORMAT.toUpperCase(),
};

export default TimePicker;
