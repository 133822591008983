import React from 'react';
import { compact, isEmpty } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants';
import { personShape } from 'components/core/Profile/utils/shapes';

import TableSection from '../../TableSection';

function PersonalDetails({ person }) {
  const {
    maritalStatus,
    spouseName,
    family,
    religion,
    ethnicity,
    residence,
    gender,
    birthInfo = {},
  } = person || {};

  const {
    place: birthplace,
    date: birthdate,
  } = birthInfo;

  const properties = compact([
    maritalStatus,
    spouseName,
    family,
    religion,
    ethnicity,
    residence,
    gender,
    birthplace,
    birthdate,
  ]);

  if (isEmpty(properties)) {
    return null;
  }

  const tables = [
    [
      {
        label: 'Birthplace',
        value: birthplace || EMPTY_VALUE,
      },
      {
        label: 'Birthdate',
        value: birthdate || EMPTY_VALUE,
      },
      {
        label: 'Marital status',
        value: maritalStatus || EMPTY_VALUE,
      },
      {
        label: 'Spouse',
        value: spouseName || EMPTY_VALUE,
      },
      {
        label: 'Family',
        value: family || EMPTY_VALUE,
      },
      {
        label: 'Religion',
        value: religion || EMPTY_VALUE,
      },
      {
        label: 'Ethnicity',
        value: ethnicity || EMPTY_VALUE,
      },
      {
        label: 'Residence',
        value: residence || EMPTY_VALUE,
      },
      {
        label: 'Gender',
        value: gender || EMPTY_VALUE,
      },
    ],
  ];

  return (
    <TableSection
      title="Personal details"
      tables={tables}
    />
  );
}

PersonalDetails.propTypes = {
  person: personShape,
};

export default PersonalDetails;
