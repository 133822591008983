import isEmpty from 'lodash/isEmpty';

import { isMyFeed } from 'components/Dashboard/utils';
import { BASE_URL } from 'components/Dashboard/utils/constants';

export const shouldShowTile = (isTracking, subscriptions) => (
  isTracking || !isEmpty(subscriptions)
);

export const getTileUrl = token => (
  isMyFeed(token) ? BASE_URL : `${BASE_URL}/workspaces/${token}`
);
