import isNil from 'lodash/isNil';

export const isValid = ({
  inputValue,
  text,
  isCaseSensitive,
}) => {
  if (isNil(inputValue)) {
    return true;
  }

  return isCaseSensitive ? inputValue === text : inputValue.toLowerCase() === text.toLowerCase();
};
