import React from 'react';
import PropTypes from 'prop-types';
import { compact, isEmpty } from 'lodash';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';

function PersonalDetails({ person, isLoading }) {
  const {
    maritalStatus,
    spouseName,
    family,
    religion,
    ethnicity,
    residence,
    gender,
    birthInfo = {},
  } = person || {};

  const {
    place: birthplace,
    date: birthdate,
  } = birthInfo;

  const properties = compact([
    maritalStatus,
    spouseName,
    family,
    religion,
    ethnicity,
    residence,
    gender,
    birthplace,
    birthdate,
  ]);

  if (isEmpty(properties)) {
    return null;
  }

  const sections = [{
    id: 'personalDetails',
    title: 'Personal details',
    tables: [
      {
        id: 'leftPersonalDetailsTable',
        data: [
          {
            id: 'birthplace',
            label: 'Birthplace',
            content: birthplace,
          },
          {
            id: 'birthdate',
            label: 'Birthdate',
            content: birthdate,
          },
          {
            id: 'maritalStatus',
            label: 'Marital status',
            content: maritalStatus,
          },
          {
            id: 'spouseName',
            label: 'Spouse',
            content: spouseName,
          },
          {
            id: 'family',
            label: 'Family',
            content: family,
          },
        ],
      },
      {
        id: 'rightPersonalDetailsTable',
        data: [
          {
            id: 'religion',
            label: 'Religion',
            content: religion,
          },
          {
            id: 'ethnicity',
            label: 'Ethnicity',
            content: ethnicity,
          },
          {
            id: 'residence',
            label: 'Residence',
            content: residence,
          },
          {
            id: 'gender',
            label: 'Gender',
            content: gender,
          },
        ],
      },
    ],
  }];

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

PersonalDetails.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
};

export default withProfile(PersonalDetails);
