const SENATE_COMMITTEE = 'senate';
const HOUSE_COMMITTEE = 'house';
const JOINT_COMMITTEE = 'joint';
const FEDERAL = 'federal';
const STATE = 'state';

export default {
  SENATE_COMMITTEE,
  HOUSE_COMMITTEE,
  JOINT_COMMITTEE,
  FEDERAL,
  STATE,
};
