import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { EXITED, ENTERED, ENTERING, EXITING } from 'react-transition-group/Transition';

import styles from './styles.module.scss';

const transitionStatusToClassHash = {
  [ENTERING]: styles.collapsing,
  [ENTERED]: classNames(styles.collapse, 'collapse', 'show'),
  [EXITING]: styles.collapsing,
  [EXITED]: classNames(styles.collapse, 'collapse'),
};

function getHeight(node) {
  return node.scrollHeight;
}

function getTransitionClass(status) {
  return transitionStatusToClassHash[status] || 'collapse';
}

function Collapse({
  isOpen,
  className,
  children,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  animationTimeout,
  ...respProps
}) {
  const [height, setHeight] = useState(null);

  function handleOnEntering(node, isAppearing) {
    setHeight(getHeight(node));
    onEntering(node, isAppearing);
  }

  function handleOnEntered(node, isAppearing) {
    setHeight(null);
    onEntered(node, isAppearing);
  }

  function handleOnExit(node) {
    setHeight(getHeight(node));
    onExit(node);
  }

  function handleOnExiting(node) {
    /** Getting this variable triggers a reflow */
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars

    setHeight(0);
    onExiting(node);
  }

  function handleOnExited(node) {
    setHeight(null);
    onExited(node);
  }

  const style = height !== null ? { height } : {};

  return (
    <Transition
      timeout={animationTimeout}
      in={isOpen}
      onEntering={handleOnEntering}
      onEntered={handleOnEntered}
      onExit={handleOnExit}
      onExiting={handleOnExiting}
      onExited={handleOnExited}
    >
      {(status) => {
        const collapseClass = getTransitionClass(status);

        return (
          <div
            ref={respProps.innerRef}
            style={style}
            className={classNames(className, collapseClass)}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
}

Collapse.defaultProps = {
  ...Transition.defaultProps,
  animationTimeout: 350,
};

Collapse.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onEntering: PropTypes.func,
  onEntered: PropTypes.func,
  onExit: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func,
  animationTimeout: PropTypes.number,
};

export default Collapse;
