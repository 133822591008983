import { withFormik } from 'formik';
import map from 'lodash/map';

import validationSchema from 'components/core/CustomFields/utils/validationSchema';
import Notifications from 'components/core/CustomFields/utils/notifications';

import Form from '../Form';

const initialValues = {
  fieldName: '',
  fieldType: {
    value: '',
  },
  productSlugs: [],
  fieldDescription: '',
  question: '',
  options: [],
  initialOptionsLength: 0,
  required: false,
};

function onResetForm(values, actions) {
  actions.resetForm(initialValues);
}

function onSubmitForm(values, actions) {
  const { createCustomField, resetLoadingFields } = actions.props;

  const payload = {
    custom_field: {
      name: values.fieldName,
      product_slugs: map(values.productSlugs, 'value'),
      field_type: values.fieldType.value,
      description: values.fieldDescription,
      question: values.question,
      options: map(values.options, ({ value }) => ({
        old_value: null,
        new_value: value,
      })),
      required: values.required,
    },
  };

  createCustomField(payload, actions.resetForm)
    .then(Notifications.customFieldSaved)
    .catch(() => {
      Notifications.customFieldFailed();
      resetLoadingFields();
    });
}

const Container = withFormik({
  validationSchema,
  mapPropsToValues: () => initialValues,
  enableReinitialize: true,
  handleReset: onResetForm,
  handleSubmit: onSubmitForm,
})(Form);

export default Container;
