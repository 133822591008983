import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.module.scss';

function ResponsiveTooltipContainer({ children, className }) {
  return (
    <div className={classNames(styles.container, className)}>
      {children}
    </div>
  );
}

ResponsiveTooltipContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ResponsiveTooltipContainer;
