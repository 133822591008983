import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import DataCard from 'components/core/Profile/components/DataCard';

import { StakeholderContext } from './utils/withStakeholders';
import { getTrackingActions as getDefaultTrackingActions } from './utils/trackingActions';
import TableView from './components/TableView';
import LookupLink from './components/LookupLink';
import styles from './styles.module.scss';

const { isStakeholderMgmtEnabled } = gon;

function Container({
  sectionId,
  title,
  description,
  questionText,
  emptyTitle,
  emptyDescription,
  trackingContext,
  getTrackingActions,
  stakeholderLookup,
  fetchStakeholders,
  districtInfo,
  chamberCategory,
  noSubscriptionTitle,
  noSubscriptionDescription,
  isFederalSenator,
  ...restProps
}) {
  const noSubscription = !isStakeholderMgmtEnabled;
  const placeholderTitle = noSubscription ? noSubscriptionTitle : emptyTitle;
  const placeholderDescription = noSubscription ? noSubscriptionDescription : emptyDescription;
  const { id: districtId, name: districtName, state, stateName } = districtInfo;

  const { isMobile } = useMediaQuery();

  const {
    stakeholder,
    customFields,
    stakeholderLookup: stakeholderLookupData,
    exportReport,
  } = stakeholderLookup;

  const {
    stakeholders,
    sort,
    paginator,
    stakeholderLists,
    tableColumns,
  } = stakeholderLookupData;

  const commonUi = { ...stakeholder.ui, ...stakeholderLookupData.ui, ...customFields.ui };
  const isEmptyData = !commonUi.isLoadingData && isEmpty(stakeholders);

  const context = {
    ...restProps,
    ...stakeholderLookup,
    ...customFields,
    districtInfo,
    chamberCategory,
    stakeholderLists,
    stakeholders,
    exportReport,
    trackingActions: getTrackingActions(trackingContext),
    tableColumns,
    isMobile,
    sort,
    paginator,
    ui: commonUi,
    fetchStakeholders,
    isFederalSenator,
  };

  const rightItems = isStakeholderMgmtEnabled && (
    <LookupLink
      districtId={districtId}
      chamberCategory={chamberCategory}
      districtName={districtName}
      isFederalSenator={isFederalSenator}
      state={state}
      stateName={stateName}
    />
  );

  return (
    <StakeholderContext.Provider value={context}>
      <DataCard
        id={sectionId}
        title={title}
        description={description}
        questionText={questionText}
        emptyTitle={placeholderTitle}
        emptyDescription={placeholderDescription}
        showPlaceholder={noSubscription || isEmptyData}
        className={styles.stakeholders}
        titleClassName={styles.title}
        rightItems={rightItems}
      >
        <TableView />
      </DataCard>
    </StakeholderContext.Provider>
  );
}

Container.defaultProps = {
  getTrackingActions: getDefaultTrackingActions,
  chamberCategory: 'congressional',
};

Container.propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  questionText: PropTypes.string,
  emptyTitle: PropTypes.string,
  emptyDescription: PropTypes.string,
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  sort: PropTypes.object,
  paginator: PropTypes.object,
  tableColumns: PropTypes.array,
  trackingContext: PropTypes.string,
  getTrackingActions: PropTypes.func,
  stakeholderLookup: PropTypes.object,
  fetchStakeholders: PropTypes.func,
  districtInfo: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    state: PropTypes.string,
    stateName: PropTypes.string,
  }),
  isFederalSenator: PropTypes.bool,
  chamberCategory: PropTypes.string,
  noSubscriptionTitle: PropTypes.string,
  noSubscriptionDescription: PropTypes.node.isRequired,
};

export default Container;
