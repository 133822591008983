import React from 'react';

import AmountFormatter from 'components/Moneyball/common/TableView/components/AmountFormatter';
import { DISBURSEMENTS } from 'components/Moneyball/utils/constants';
import styles from '../styles.module.scss';

function renderInfoItem(itemLabel, itemValue) {
  if (!itemValue) return null;

  const renderItemValue = itemLabel === 'Amount'
    ? <AmountFormatter amount={itemValue} />
    : itemValue;

  return (
    <li>
      <div className={styles.label}>{itemLabel}</div>
      <div className={styles.value}>{renderItemValue}</div>
    </li>
  );
}

export default function getInfoNode(searchType = '', receipt = {}) {
  const isDisbursements = searchType === DISBURSEMENTS;
  const {
    recipient,
    recipientAddress,
    recipientTreasurer,
    recipientCommitteeType,
    amount,
    date,
    description,
    sourceType,
    electionType,
    electionCycle,
  } = receipt;

  let infoNode = (
    <div className={styles.content}>
      <ul>
        {renderInfoItem('Recipient Name', recipient)}
        {renderInfoItem('City and State', recipientAddress)}
        {renderInfoItem('Committee Type', recipientCommitteeType)}
        {renderInfoItem('Treasurer', recipientTreasurer)}
      </ul>
      <hr />
      <ul>
        {renderInfoItem('Amount', amount)}
        {renderInfoItem('Date', date)}
        {renderInfoItem('Source type', sourceType)}
        {renderInfoItem('Election type', electionType)}
        {renderInfoItem('Election year', electionCycle)}
      </ul>
    </div>
  );

  if (isDisbursements) {
    infoNode = (
      <div className={styles.content}>
        <ul>
          {renderInfoItem('Recipient Name', recipient)}
        </ul>
        <hr />
        <ul>
          {renderInfoItem('Amount', amount)}
          {renderInfoItem('Date', date)}
          {renderInfoItem('Source type', sourceType)}
          {renderInfoItem('Description', description)}
          {renderInfoItem('Election type', electionType)}
          {renderInfoItem('Election year', electionCycle)}
        </ul>
      </div>
    );
  }

  return infoNode;
}
