import React from 'react';

import TextPlaceholder from 'components/core/Placeholder/components/Text';

import styles from './styles.module.scss';

function Placeholder() {
  return Array(3).fill(0).map((_, index) => (
    <div key={`search-item-${index}`} className={styles.itemPlaceholder}>
      <div className={styles.infoSection}>
        <TextPlaceholder className={styles.title} />
        <TextPlaceholder />
      </div>

      <TextPlaceholder className={styles.button} />
    </div>
  ));
}

export default Placeholder;
