import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useListener } from 'react-bus';
import isEmpty from 'lodash/isEmpty';

import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import { ConfirmationModal } from 'components/core/Modal';
import Select from 'components/core/Select';

import StatementDescription from './components/StatementDescription';
import StatementsAdd from './components/StatementsAdd';
import Statements from './components/Statements';
import { transactorTheme } from './theme';
import styles from './styles.module.scss';

function Definitions({
  isDraggingStatement,
  isDraggingFilter,
  ui,
  bus,
  data,
  transactor,
  updateTransactor,
  deleteStatement,
  deleteFilter,
  onFocus,
  draggableItems,
  getFilterTranscript,
  title,
}) {
  const { isLoading } = ui || {};
  const { statement } = data || {};
  const { id: statementId, operator: statementOperator, statements } = statement || {};
  const transactors = draggableItems.map(({ id, name }) => ({ label: name, value: id }));
  const [modal, setModal] = useState({ isOpen: false, message: undefined, action: undefined });
  const defaultErrorData = { transactor: undefined, statements: undefined };
  const [errors, setErrors] = useState(defaultErrorData);
  const { transactor: errorTransactor, statements: errorStatements } = errors || {};

  const validate = useCallback(() => {
    const isEmptyTransactor = isEmpty(transactor);
    const validator = {
      ...isEmptyTransactor && { transactor: true },
      ...(!isEmptyTransactor && isEmpty(statements)) && { statements: true },
    };

    if (!isEmpty(validator)) {
      setErrors(validator);
      bus.emit('errors', true);
    }
  }, [transactor, statements]);

  useListener('submitting', validate);

  function toggleModalConfirm() {
    const { isOpen } = modal;

    setModal({ isOpen: !isOpen });
  }

  const handleTransactorChange = ({ label, value }) => {
    const { name } = transactor && draggableItems.find(i => i.id === transactor);
    const injectedMessage = `${name && `from ${name} search `} to ${label} search`;
    const message = `Changing transactor types (e.g., switching ${injectedMessage}) will reset all your changes. Are you sure you want to continue?`;
    const action = () => updateTransactor({ payload: value });

    transactor && statements.length && value !== transactor
      ? setModal({ isOpen: true, message, action })
      : action();
  };

  const transactorNode = (
    <section className={styles.transactor}>
      <Select
        id="transactor"
        value={transactor}
        options={transactors}
        onChange={handleTransactorChange}
        onFocus={onFocus('transactor', setErrors)}
        customTheme={transactorTheme}
        className={classNames(errorTransactor && styles.transactorInvalid)}
        placeholder="Transactor"
        isDisabled={isLoading}
        searchable={false}
        clearable={false}
        hasNewStyle
      />
    </section>
  );

  const transactorObject = transactors.find(({ value }) => value === transactor);

  return (
    <div className={styles.container}>
      <div className={styles.type}>
        Include {transactorNode} in new List IF…
      </div>

      {!transactor && (
        <div className={styles.typeDescription} id="typeDescription">
          Select a transactor above to begin building your search.
        </div>
      )}

      {transactor && (
        <>
          <Statements
            isDraggingStatement={isDraggingStatement}
            isDraggingFilter={isDraggingFilter}
            draggableIndex={0}
            parentOperator={statementOperator}
            parentId={statementId}
            statements={statements}
            deleteStatement={deleteStatement}
            deleteFilter={deleteFilter}
          />

          <StatementsAdd
            isJoinGroupPartHidden
            isDraggingStatement={isDraggingStatement}
            errorStatements={errorStatements}
            statements={statements}
          />
        </>
      )}

      {getFilterTranscript && (
        <StatementDescription
          searchTitle={title}
          transactor={transactorObject}
          parentStatements={statements}
          parentOperator={statementOperator}
          getFilterTranscript={getFilterTranscript}
          draggableItems={draggableItems}
        />
      )}

      <ConfirmationModal {...modal} toggle={toggleModalConfirm} />
    </div>
  );
}

Definitions.propTypes = {
  bus: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  transactor: PropTypes.string.isRequired,
  isDraggingStatement: PropTypes.bool.isRequired,
  isDraggingFilter: PropTypes.bool.isRequired,
  updateTransactor: PropTypes.func.isRequired,
  deleteStatement: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  draggableItems: PropTypes.array.isRequired,
  getFilterTranscript: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default withSmartSearch(Definitions);
