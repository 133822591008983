import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import Notifications from '../../utils/Notifications';
import styles from './styles.module.scss';

function Actions({
  sectionValues,
  fileFormatValues,
  customizationValues,
  customInputsValues,
  isLoading,
  toggleModal,
  logoUrl,
  documentSummary,
  onExport,
}) {
  const customIcon = isLoading && <Loader size="small" theme="light" onlyIcon />;

  function handleExport() {
    if (isEmpty(sectionValues)) {
      Notifications.notifyInvalidForm();
    } else {
      onExport({
        ...customInputsValues,
        sections: sectionValues,
        fileFormat: fileFormatValues,
        customization: customizationValues,
      }, logoUrl, documentSummary, toggleModal);
    }
  }

  return (
    <div className={styles.actionContainer}>
      <Button className={styles.actionButton} size="small" theme="light" onClick={toggleModal}>
        Cancel
      </Button>

      <Button size="small" onClick={handleExport} disabled={isLoading} customIcon={customIcon}>
        Export
      </Button>
    </div>
  );
}

Actions.propTypes = {
  customInputsValues: PropTypes.object,
  logoUrl: PropTypes.string,
  customizationValues: PropTypes.array,
  isLoading: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  sectionValues: PropTypes.array,
  fileFormatValues: PropTypes.array,
  documentSummary: PropTypes.string,
};

export default Actions;
