import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import range from 'lodash/range';
import classNames from 'classnames';

import constants from 'components/core/DatePicker/constants';

import styles from '../../styles.module.scss';

const { YEAR_FORMAT, START_YEAR_RANGE } = constants;

function YearsRange({ date, onYearChange }) {
  const years = range(START_YEAR_RANGE, moment().year() + 1, 1);
  const selectedYear = moment(date).format(YEAR_FORMAT);

  function handleYearChange(e) {
    const { value } = e.target;
    onYearChange(value);
  }

  return (
    <div className={styles.container}>
      <select
        className={styles.select}
        value={selectedYear}
        onChange={handleYearChange}
      >
        {years.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
      <i className={classNames('icon', 'fas', 'fa-caret-down', styles.dropdownIcon)} />
    </div>
  );
}

YearsRange.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onYearChange: PropTypes.func,
};

export default YearsRange;
