import PropTypes from 'prop-types';

export const notificationShape = PropTypes.shape({
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  itemTitle: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
});

export const uiShape = PropTypes.shape({
  isClearingNotifications: PropTypes.bool.isRequired,
});
