import React from 'react';

export const SmartSearchContext = React.createContext();

function withSmartSearch(Component) {
  return function SmartSearchComponent(props) {
    return (
      <SmartSearchContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </SmartSearchContext.Consumer>
    );
  };
}

export default withSmartSearch;
