import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import classNames from 'classnames';
import { isEmpty, truncate } from 'lodash';

import styles from './styles.module.scss';

function TrimTextItem({ textToTrim, maxTextLength, className, withTooltip }) {
  if (isEmpty(textToTrim)) {
    return null;
  }

  if (textToTrim.length <= maxTextLength) {
    return <span className={classNames(className)}>{textToTrim}</span>;
  }

  function getTooltipNode() {
    return <span className={styles.textTrim}>{textToTrim}</span>;
  }

  const textToDisplay = truncate(textToTrim, { length: maxTextLength, separator: /,?\.* +/ });

  if (!withTooltip) {
    return (
      <div className={classNames(className)}>{textToDisplay}</div>
    );
  }

  return (
    <Tooltip
      content={getTooltipNode()}
      className={styles.tooltipContainer}
      placement="bottom"
    >
      <span className={classNames(styles.trimText, className)}>{textToDisplay}</span>
    </Tooltip>
  );
}

TrimTextItem.defaultProps = {
  maxTextLength: 90,
  withTooltip: true,
};

TrimTextItem.propTypes = {
  textToTrim: PropTypes.string,
  className: PropTypes.string,
  maxTextLength: PropTypes.number,
  withTooltip: PropTypes.bool,
};

export default TrimTextItem;
