import React from 'react';

import Box from 'components/core/Box';

import styles from './styles.module.scss';

function Disclaimer() {
  const text = (
    <span>
      Column options will appear on subsequent screens for your filtered item types.
      Hit the “<b>next</b>” button to continue.
    </span>
  );

  return <Box text={text} theme="default" className={styles.box} />;
}

export default Disclaimer;
