import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';
import {
  NEW_STATE,
  RECENT_STATE,
  SAVED_STATE,
} from 'components/core/AdvancedSearch/utils/constants';

import styles from './styles.module.scss';

function TopBar({ isMobile, isNew, isSaved, isRecent, onClick }) {
  const searchLabel = !isMobile && 'Searches';

  return (
    <ButtonGroup theme="light" className={styles.btnGroup}>
      {isMobile && (
        <Button
          className={classNames(isNew && styles.active)}
          theme="default"
          onClick={onClick(NEW_STATE)}
        >
          New
        </Button>
      )}
      <Button
        className={classNames(isRecent && styles.active)}
        theme="default"
        onClick={onClick(RECENT_STATE)}
      >
        Recent {searchLabel}
      </Button>
      <Button
        className={classNames(isSaved && styles.active)}
        theme="default"
        onClick={onClick(SAVED_STATE)}
      >
        Saved {searchLabel}
      </Button>
    </ButtonGroup>
  );
}

TopBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  isRecent: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withSearch(TopBar);
