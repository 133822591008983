import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Accordion, { Item } from 'components/core/Accordion';

import Header from './components/Header';
import styles from './styles.module.scss';

function renderTitle({ tab, isOpen, toggle, onTabClick }) {
  return (
    <Header
      tab={tab}
      isOpen={isOpen}
      toggle={toggle}
      onTabClick={onTabClick}
    />
  );
}

function renderContent({ tabContent, tabContentClassName }) {
  return <div className={classNames(styles.content, tabContentClassName)}>{tabContent}</div>;
}

export default function AccordionLayout({ className, tabs, onTabClick, tabContentClassName }) {
  return (
    <Accordion
      className={className}
      renderTitle={renderTitle}
      renderContent={renderContent}
      multi={false}
    >
      {tabs.map((tab) => {
        const { id, title, children } = tab.props;

        return (
          <Item
            className={styles.tab}
            key={`${title}-${id}`}
            tab={tab}
            tabContent={children}
            onTabClick={onTabClick}
            tabContentClassName={tabContentClassName}
          />
        );
      })}
    </Accordion>
  );
}

AccordionLayout.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabContentClassName: PropTypes.string,
};

renderTitle.propTypes = {
  tab: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

renderContent.propTypes = {
  tabContent: PropTypes.node.isRequired,
  tabContentClassName: PropTypes.string,
};
