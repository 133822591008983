import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import { NO_PRIORITY, priorities } from 'components/core/PriorityTooltip/constants';
import SlideUpModal from 'components/core/Modal/components/SlideUpModal';
import useModal from 'components/core/Modal/useModal';
import { priorityTooltipZIndex } from 'style/global/_variables.scss';

import PriorityButton from './components/PriorityButton';
import styles from './styles.module.scss';

function PriorityToolTip({
  isOpen,
  isMobile,
  isLoadingPriority,
  selectedPriority,
  customLabel,
  containerClassName,
  buttonClassName,
  iconClassName,
  loaderClassName,
  onChangePriority,
}) {
  const { isOpenModal, toggleModal, closeModal } = useModal(isOpen);

  function handleChangePriority(value) {
    closeModal();
    onChangePriority(value);
  }

  const renderPriority = priority => (
    <PriorityButton
      key={priority}
      priority={priority}
      isDisabled={isLoadingPriority}
      onClick={handleChangePriority}
      iconClassName={styles.iconTooltip}
      className={classNames(styles.buttonTooltip, isMobile && styles.mobileButton)}
    />
  );

  const content = (
    <div className={styles.prioritiesContainer}>
      {Object.keys(priorities).map(renderPriority)}
    </div>
  );

  return (
    <Tooltip
      theme="light"
      placement="bottom-end"
      className={classNames(styles.tooltip)}
      content={content}
      disabled={isMobile}
      visible={isOpenModal}
      onClickOutside={toggleModal}
      zIndex={priorityTooltipZIndex}
      arrow
    >
      <span className={classNames(styles.tooltipContainer, containerClassName)}>
        <PriorityButton
          isLoading={isLoadingPriority}
          priority={selectedPriority}
          className={buttonClassName}
          iconClassName={iconClassName}
          loaderClassName={loaderClassName}
          customLabel={customLabel}
          onClick={toggleModal}
        />
        {isMobile && (
          <SlideUpModal
            title="Priority"
            isOpen={isOpenModal}
            toggleOpen={toggleModal}
          >
            {content}
          </SlideUpModal>
        )}
      </span>
    </Tooltip>
  );
}

PriorityToolTip.defaultProps = {
  customLabel: 'Priority',
  selectedPriority: NO_PRIORITY,
};

PriorityToolTip.propTypes = {
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLoadingPriority: PropTypes.bool,
  selectedPriority: PropTypes.string,
  customLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  onChangePriority: PropTypes.func.isRequired,
};

export default PriorityToolTip;
