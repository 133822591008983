import { useEffect, useRef } from 'react';

import useTimeline from '../../utils/useTimeline';

function useAsyncTimeline({ paginator, items, isLoading, fetchTimeline, limit }) {
  const timelineContainerRef = useRef(null);
  const {
    showPlaceholder,
    isHiddenEvents,
    isOpenMore,
    visibleItems,
    toggleViewMore,
  } = useTimeline({ items, isLoading, limit });
  const { current, total } = paginator || {};
  const isFirstPageView = current === 1 && !isOpenMore;
  const isLastPage = !isFirstPageView && current === total;

  useEffect(() => {
    fetchTimeline({ page: 1 });
  }, []);

  function handleCollapse() {
    if (timelineContainerRef.current) {
      timelineContainerRef.current.scrollIntoView();
    }

    toggleViewMore();
  }

  function handleClickViewMore() {
    if (isFirstPageView) {
      return toggleViewMore();
    }

    if (isLastPage) {
      return fetchTimeline({ page: 1, isInitialData: true })
        .then(handleCollapse);
    }

    return fetchTimeline({ page: current + 1 });
  }

  return {
    timelineContainerRef,
    isHiddenEvents,
    isOpenMore: isLastPage,
    visibleItems,
    onClickViewMore: handleClickViewMore,
    showPlaceholder,
  };
}

export default useAsyncTimeline;
