import React from 'react';
import PropTypes from 'prop-types';

import DraggableOption from './DraggableOption';
import styles from '../styles.module.scss';

function Options({ dragDropPlaceholder, options, droppableSnapshot }) {
  return (
    <ul className={styles.group}>
      {options.map((option, optionIndex) => (
        <DraggableOption
          {...option}
          key={`label-${option.id}-${optionIndex}`}
          index={optionIndex}
          droppableSnapshot={droppableSnapshot}
        />
      ))}
      {dragDropPlaceholder}
    </ul>
  );
}

Options.propTypes = {
  options: PropTypes.array.isRequired,
  dragDropPlaceholder: PropTypes.object.isRequired,
  droppableSnapshot: PropTypes.object.isRequired,
};

export default Options;
