import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const radioThemes = {
  default: styles.default,
  button: styles.button,
  light: styles.light,
  strong: styles.strong,
};

function RadioBox({ label, placeholder, theme, className, onChange, ...restProps }) {
  const handleChange = (event) => {
    const { target } = event || {};

    target.focus && target.focus();
    onChange && onChange(event);
  };

  return (
    <label
      className={classNames(
        styles.container,
        radioThemes[theme],
        !label && styles.placeholder,
        className,
      )}
    >
      <input
        {...restProps}
        onChange={handleChange}
        className={styles.radioInput}
        type="radio"
      />
      <span className={styles.labelText}>{label || placeholder}</span>
      <span className={styles.radio} />
    </label>
  );
}

RadioBox.defaultProps = {
  theme: 'default',
  placeholder: '',
};

RadioBox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf(
    Object.keys(radioThemes)
  ),
  onChange: PropTypes.func,
};

export default RadioBox;
