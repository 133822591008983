import React from 'react';
import PropTypes from 'prop-types';
import { map, size, reject } from 'lodash';

import CoreCheckbox from 'components/core/Checkbox';
import { stringNumberType } from 'utils/shapes';

function SelectAll({ id, values, items, selector, onChange, className }) {
  const enabledItems = reject(items, 'disabled');
  const isChecked = size(values) === size(enabledItems);

  const handleSelectAll = ({ target: { checked } }) => {
    onChange(id, checked ? map(enabledItems, selector) : []);
  };

  return (
    <CoreCheckbox
      isChecked={isChecked}
      label="Select/deselect all"
      onChange={handleSelectAll}
      className={className}
    />
  );
}

SelectAll.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    [PropTypes.string]: stringNumberType,
    disabled: PropTypes.bool,
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selector: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SelectAll;
