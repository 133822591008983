import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Icon({ text, className }) {
  return (
    <span className={classNames(className, styles.icon, 'icon')}>{ text }</span>
  );
}

Icon.defaultProps = {
  text: '',
  className: '',
};

Icon.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
