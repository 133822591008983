import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import SnippetColumn
  from 'components/Federal/BillLookup/components/TableColumnsView/components/SnippetColumn';
import AddedToItem from 'components/core/Table/components/ColumnItem/AddedToItem';
import ActionsItem from 'components/core/Table/components/ColumnItem/ActionsItem';
import TagsItem from 'components/core/Table/components/ColumnItem/TagsItem';
import { EmptyTablePlaceholder } from 'components/core/Profile/components/Placeholders';
import TrimTextItem from 'components/core/Table/components/ColumnItem/TrimTextItem';
import { DateItem, BillTitleItem } from 'components/core/Table/components/ColumnItem';
import Table, { Column } from 'components/core/Table';
import Paginator from 'components/core/Paginator';
import { sortShape } from 'components/core/Sort/shapes';
import { paginatorShape } from 'components/core/Paginator/shapes';
import { billShape } from 'utils/shapes/bills';
import { tableColumnShape } from 'components/core/Profile/components/Cards/utils/shapes';

import { DEFAULT_PAGE_SIZE } from '../../utils/constants';
import useTable from '../../utils/useTable';
import withBills from '../../utils/withBills';
import defaultTableColumns from '../../utils/defaultTableColumns';
import SponsorsColumn from '../Columns/SponsorsColumn';
import CosponsorsColumn from '../Columns/CosponsorsColumn';
import styles from './styles.module.scss';

function TableView({
  isMobile,
  isLoading,
  sort,
  allItems,
  paginator,
  emptyDescription,
  trackingActions,
  tableColumns,
  onSearch,
  onClearAll,
  updateDataItem,
  defaultSubscription,
  isLoadingSubscriptions,
  isLoadingTags,
  isLoadingMyFeedDocuments,
}) {
  const {
    isPaginatorPresent,
    sortData,
    onSortColumn,
    onChangePage,
  } = useTable({ sort, paginator, onSearch, onClearAll, trackingActions, tableColumns });

  if (!isLoading && isEmpty(allItems)) {
    return <EmptyTablePlaceholder description={emptyDescription} />;
  }

  function renderColumn(column) {
    const { keyName } = column;

    function getWrapColumn(value) {
      return (
        <div className={styles.lastActionColumn}>
          <TrimTextItem textToTrim={value} maxTextLength={80} />
        </div>
      );
    }

    const columnComponents = {
      bill: <BillTitleItem key={keyName} keyName={keyName} />,
      sponsors: <SponsorsColumn key={keyName} keyName={keyName} />,
      cosponsors: <CosponsorsColumn key={keyName} keyName={keyName} />,
      snippet: <SnippetColumn key={keyName} keyName={keyName} />,
      lastAction: <Column key={keyName} keyName={keyName}>{getWrapColumn}</Column>,
      introducedAt: <DateItem key={keyName} keyName={keyName} id="introducedAt" />,
      lastActionDate: <DateItem key={keyName} keyName={keyName} id="lastActionDate" />,
      addedTo: (
        <AddedToItem
          key={keyName}
          keyName={keyName}
          isLoadingMyFeedDocuments={isLoadingMyFeedDocuments}
        />
      ),
      tags: (
        <TagsItem
          key={keyName}
          keyName={keyName}
          isLoading={isLoadingTags}
        />
      ),
      actions: (
        <ActionsItem
          key={keyName}
          keyName={keyName}
          itemNameBy="billId"
          isMobile={isMobile}
          updateDataItem={updateDataItem}
          defaultSubscription={defaultSubscription}
          isLoadingSubscriptions={isLoadingSubscriptions}
        />
      ),
    };

    return columnComponents[keyName] || <Column key={keyName} keyName={keyName} />;
  }

  function renderPagination() {
    if (!isPaginatorPresent) {
      return null;
    }

    const { current, total, totalCount, pageSize } = paginator;

    return (
      <Paginator
        isMobile={isMobile}
        isLoading={isLoading}
        current={current}
        total={total}
        pageSize={pageSize}
        totalItems={totalCount}
        onChange={onChangePage}
      />
    );
  }

  const tableClassName = classNames(
    styles.tableContent,
    paginator
    && paginator.current > 1
    && paginator.current === paginator.total
    && styles.fixedHeight
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isLoading}
        rowLengthPlaceholder={DEFAULT_PAGE_SIZE}
        uniqueKey="id"
        columns={tableColumns}
        containerStyle={tableClassName}
        data={allItems}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={onSortColumn}
        isPaginatorPresent={isPaginatorPresent}
        isMobile={isMobile}
        isFixed
        isAlignedToTop
      >
        {tableColumns.map(renderColumn)}
      </Table>

      {renderPagination()}
    </div>
  );
}

TableView.defaultProps = {
  emptyDescription: '',
  tableColumns: defaultTableColumns,
};

TableView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingSubscriptions: PropTypes.bool.isRequired,
  isLoadingTags: PropTypes.bool.isRequired,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
  defaultSubscription: PropTypes.string.isRequired,
  sort: sortShape.isRequired,
  allItems: PropTypes.arrayOf(billShape).isRequired,
  paginator: paginatorShape.isRequired,
  onSearch: PropTypes.func.isRequired,
  trackingActions: PropTypes.shape({}), // { fnName: fn, ... }
  emptyDescription: PropTypes.string,
  tableColumns: PropTypes.arrayOf(tableColumnShape),
  onClearAll: PropTypes.func.isRequired,
  updateDataItem: PropTypes.func.isRequired,
};

export default withBills(TableView);
