import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  card: {
    marginBottom: 10,
    padding: 0,
    width: '100%',
  },

  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontSize: 8,
    marginTop: 3,
    marginBottom: 5,
  },

  cardType: {
    fontWeight: 300,
    fontSize: 8,
    textTransform: 'uppercase',
  },
});

export default styles;
