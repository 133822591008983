import React from 'react';

import styles from './styles.module.scss';

export const exactPhrase = {
  syntax: '""',
  usageMessage: (
    <>
      Matches items that contain the phrase exactly as specified.<br />
      <i>E.g.</i>, <code className={styles.highlight}>"state of the union"</code> matches any item containing exactly the quoted words in that order.
    </>
  ),
};

export const orConditional = {
  syntax: '|',
  usageMessage: (
    <>
      Matches either the keyword before the symbol | or after.<br />
      <i>E.g.</i>, <code className={styles.highlight}>capitol | hill</code> matches items with either "capitol" or "hill" within.
    </>
  ),
};

export const bothConditional = {
  syntax: '+',
  usageMessage: (
    <>
      Matches only if all criteria both before and after the + symbol exist.<br />
      <i>E.g.</i>, <code className={styles.highlight}>capitol + hill</code> matches items with both "capitol" and "hill" within.
    </>
  ),
};

export const excludeConditional = {
  syntax: '-',
  usageMessage: (
    <>
      Ignores results for a word with - in front of it.<br />
      <i>E.g.</i>, <code className={styles.highlight}>capitol -hill</code> matches items with the word "capitol" and ignores items that contain "hill" within.
    </>
  ),
};

export const prefixConditional = {
  syntax: '*',
  usageMessage: (
    <>
      Matches when it contains words with that prefix.<br />
      <i>E.g.</i>, <code className={styles.highlight}>Alpha*</code> returns results like alphabet.
    </>
  ),
};

export const withinConditional = {
  syntax: 'w/x',
  usageMessage: (
    <>
      Matches when word preceding operator is within x words of word following operator.<br />
      <i>E.g.</i>, <code className={styles.highlight}>state w/2 union</code> matches items that have the word "state" within two words of the word "union".<br />
      Note that this operator is not compatible with any other advanced search operators except for quotation marks; in other words, the words before and after must be a single word or a group of words in quotes.
    </>
  ),
};

export const precedingMatchConditional = {
  syntax: '~N',
  usageMessage: (
    <>
      Fuzzy matches the word or phrase preceding ~N.<br />
      <i>E.g.</i>, <code className={styles.highlight}>Pizza~N</code> matches items containing words like Pizza, Piaza, or Plaza,
      <code className={styles.highlight}>"Four score and seven years ago"~N</code> matches items that contain similar phrases like "Seven score and seven years ago".<br />
    </>
  ),
};

export const groupConditional = {
  syntax: '()',
  usageMessage: (
    <>
      Will group queries to create subqueries.<br />
      <i>E.g.</i>, <code className={styles.highlight}>large + (cats | dogs)</code>  matches items that have both "large" and "cats", and it matches items that have both "large" and "dogs".
    </>
  ),
};

export const defaultCheatSheetData = [
  exactPhrase,
  orConditional,
  bothConditional,
  excludeConditional,
  prefixConditional,
  withinConditional,
  precedingMatchConditional,
  groupConditional,
];

export const newsCheatSheetData = [
  exactPhrase,
  orConditional,
  bothConditional,
  excludeConditional,
  groupConditional,
];
