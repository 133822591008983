export const STAFFER_TYPES = {
  FEDERAL_STAFFER: 'federal',
  STATE_STAFFER: 'staffer',
  STATE_AGENCY_STAFFER: 'state_agency',
};

export const OFFICE_LEADERSHIP_LABEL = 'Leadership';
export const OFFICE_LEADERSHIP_ID = 'leadership';

export const OFFICE_MEMBER_LABEL = 'Member';
export const OFFICE_MEMBER_ID = 'member';

export const OFFICE_TYPE_OPTIONS = [
  {
    label: OFFICE_LEADERSHIP_LABEL,
    value: OFFICE_LEADERSHIP_ID,
  },
  {
    label: OFFICE_MEMBER_LABEL,
    value: OFFICE_MEMBER_ID,
  },
];
