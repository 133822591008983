import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Select from './components/Select';
import Tooltip from './components/Tooltip';
import { DEFAULT_SORT, SORT_OPTIONS } from './utils/constants';

function Sort({
  options,
  defaultOption,
  selectedSort,
  isLoading,
  onSort,
  placement,
  tooltipNodeClassName,
  tooltipClassName,
  optionClassName,
  className,
  isSelect,
  labelClassName,
  selectClassName,
}) {
  const defaultSort = { sort: defaultOption.value, direction: defaultOption.direction };
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const { toReset } = selectedSort;

  useEffect(() => {
    toReset
      ? setCurrentSort(defaultSort)
      : setCurrentSort(selectedSort);
  }, [toReset, selectedSort]);

  function handleSort({ value, direction }) {
    return () => {
      setCurrentSort({ sort: value, direction });
      onSort({ value, direction });
    };
  }

  const commonProps = {
    className,
    options,
    selectedSort: currentSort,
    onSort: handleSort,
    labelClassName,
    defaultOption,
  };

  return useMemo(() => {
    if (isSelect) {
      return (
        <Select
          {...commonProps}
          selectClassName={selectClassName}
        />
      );
    }

    return (
      <Tooltip
        {...commonProps}
        tooltipNodeClassName={tooltipNodeClassName}
        optionClassName={optionClassName}
        tooltipClassName={tooltipClassName}
        placement={placement}
        isLoading={isLoading}
      />
    );
  }, [currentSort, isLoading]);
}

Sort.defaultProps = {
  selectedSort: DEFAULT_SORT,
  options: SORT_OPTIONS,
  placement: 'bottom-start',
  defaultOption: DEFAULT_SORT,
  isLoading: false,
  isSelect: false,
};

Sort.propTypes = {
  selectedSort: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  placement: PropTypes.string,
  tooltipNodeClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  className: PropTypes.string,
  defaultOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onSort: PropTypes.func.isRequired,
  isSelect: PropTypes.bool,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
};

export default Sort;
