import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_VALUE } from 'utils/constants';

import Legislator from './components/Legislator';
import { sortLegislators } from './utils';
import styles from './styles.module.scss';

function LegislatorsSection({ legislators, title, formatLegislatorUrl }) {
  const sortedLegislators = sortLegislators(legislators);

  const renderLegislators = () => {
    if (isEmpty(legislators)) {
      return EMPTY_VALUE;
    }

    return sortedLegislators.map(legislator => (
      <Legislator
        {...legislator}
        className={styles.legislator}
        key={legislator.id}
        formatLegislatorUrl={formatLegislatorUrl}
      />
    ));
  };

  return (
    <div className={styles.section}>
      <h3 className={styles.title}>
        {title}
      </h3>
      {renderLegislators()}
    </div>
  );
}

LegislatorsSection.defaultProps = {
  legislators: [],
};

LegislatorsSection.propTypes = {
  legislators: PropTypes.array,
  title: PropTypes.string.isRequired,
  formatLegislatorUrl: PropTypes.func,
};

export default LegislatorsSection;
