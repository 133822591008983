import React from 'react';
import PropTypes from 'prop-types';

import AccordionLayout from './components/AccordionLayout';
import TabsLayout from './components/TabsLayout';

export default function MobileView({
  activeTab,
  className,
  tabs,
  onTabClick,
  tabContentClassName,
  mobileLayout,
  buttonClassName,
}) {
  const commonProps = {
    activeTab,
    className,
    tabs,
    onTabClick,
    tabContentClassName,
    buttonClassName,
  };

  if (mobileLayout === 'tabs') {
    return <TabsLayout {...commonProps} />;
  }

  return <AccordionLayout {...commonProps} />;
}

MobileView.propTypes = {
  mobileLayout: PropTypes.string.isRequired,
  activeTab: PropTypes.object,
  className: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabContentClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};
