import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, sortBy, capitalize } from 'lodash';
import Tooltip from '@tippyjs/react';

import { EMPTY_VALUE } from 'utils/constants';
import { Column } from 'components/core/Table';
import { EmptyItem } from 'components/core/Table/components/ColumnItem';

import ListItem from './components/ListItem';
import styles from './styles.module.scss';

function LinkListItem({ keyName, value, onClick, getHref, isColumn, isExternal }) {
  if (isColumn && (isEmpty(value) || value === EMPTY_VALUE)) {
    return <EmptyItem keyName={keyName} />;
  }

  const capitalizedValues = value.map(({ id, name }) => ({ id, name: capitalize(name) }));
  const sortedList = sortBy(capitalizedValues, 'name');
  const firstItem = sortedList[0];

  const tooltipNode = () => (
    <div className={styles.tooltipContent}>
      {sortedList.map((issue, index) => (
        <ListItem
          key={`${issue.id}-${index}`}
          onClick={onClick}
          getHref={getHref}
          item={issue}
          column={keyName}
          className={styles.tooltipLink}
          isExternal={isExternal}
        />
      ))}
    </div>
  );

  const renderLinks = () => {
    if (sortedList.length === 1) {
      return (
        <ListItem
          item={firstItem}
          onClick={onClick}
          getHref={getHref}
          column={keyName}
          isExternal={isExternal}
        />
      );
    }

    return (
      <div role="button" className={styles.buttonOutline}>
        <div className={classNames('link-list-column', styles.linkLabel)}>
          <Tooltip
            className={classNames(
              'link-list-column',
              styles.tooltipWrapper,
              styles.tooltipContainer,
            )}
            content={tooltipNode()}
            placement="bottom"
            appendTo={document.body}
            interactive
          >
            <div className={styles.flexContent}>
              <ListItem
                item={firstItem}
                onClick={onClick}
                getHref={getHref}
                column={keyName}
                isExternal={isExternal}
              />
              <div className={styles.badge}>
                +{sortedList.length - 1} more
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };


  if (!isColumn) {
    return renderLinks();
  }

  return <Column key={keyName} keyName={keyName}>{renderLinks}</Column>;
}

LinkListItem.defaultProps = {
  isColumn: true,
};

LinkListItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  getHref: PropTypes.func,
  isColumn: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export default LinkListItem;
