import { getTimelineDefaultFilters } from 'components/Grm2/ActivityButton/useActivityButton';
import { transformFiltersForBE, transformCardForFeed, transformCustomFieldsForFeed } from 'redux/grm2/transformers';
import { PRODUCT_SLUGS } from 'components/core/CustomFields/utils/constants';

const fetchCustomFields = () => {
  const url = '/api_web/custom_fields/fields';
  const method = 'GET';
  const data = {
    search_form: {
      enabled: true,
      product_slugs: [PRODUCT_SLUGS.GRM],
    },
    per_page: 200,
  };

  const ajax = $.ajax({ url, method, data });

  return Promise.resolve(ajax);
};

export const fetchTimelineCards = () => {
  const filters = {
    keywordLookup: '',
    people: [],
    creator: [],
    bills: [],
    regulations: [],
    committees: [],
    documents: [],
    activityTypes: [],
    interactionTypes: [],
    tags: [],
    eventDate: {
      startDate: null,
      endDate: null,
    },
    creationDate: {
      startDate: null,
      endDate: null,
    },
    attachments: false,
  };
  const url = '/api_web/grm_v2/cards/lookup';
  const method = 'POST';
  const data = {
    direction: 'desc',
    sort: 'created_at',
    search_form: transformFiltersForBE(filters, getTimelineDefaultFilters()),
  };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return fetchCustomFields().then(
    ({ data: customFieldsData }) => {
      const customFields = transformCustomFieldsForFeed(customFieldsData);
      return Promise.resolve(ajax)
        .then(({ data: cards }) => cards.map(card => transformCardForFeed(card, customFields)));
    }
  );
};
