export const transformCommentForBE = comment => (
  {
    record_id: comment.parentId,
    record_type: comment.parentType,
    text: comment.text.trim(),
  }
);

export const transformCommentForUI = comment => ({
  user: {
    name: comment.user.full_name,
    photoUrl: comment.user.profile_img_url,
    id: comment.user.id,
    email: comment.user.email,
  },
  content: comment.text,
  id: comment.id,
  repliesCount: comment.replies_count || 0,
  createdAt: comment.created_at,
});
