import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapContainer from 'components/core/Map';
import { US_COORDINATES } from 'utils/constants';

import withLookup from '../../utils/withLookup';
import useMapLookup from '../../utils/useMap';
import useMapView from './useMapView';

const mapRef = createRef();

function MapView({ dataLookup, actions, mapData, pageView, PopupComponent, markerImage }) {
  const {
    height,
    onFetch,
    isMobile,
    isLoadingMap,
    isTablePageView,
  } = useMapView({ dataLookup, actions, pageView });

  const {
    data,
    profileUrl,
    defaultLoadingMessage,
    getFilters,
    defaultFilters,
    withFilters,
    withNavigation,
    withHeatmap,
    heatmapName,
    clusterName,
    onFilterChange,
  } = mapData;

  const mapProps = {
    isMobile,
    mapRef,
    data,
    onFetch,
    profileUrl,
    defaultFilters,
    isLoadingMap,
    withFilters,
    withNavigation,
    withHeatmap,
    heatmapName,
    clusterName,
    isTablePageView,
    center: [US_COORDINATES.longitude, US_COORDINATES.latitude],
    onFilterChange,
    PopupComponent,
    markerImage,
  };

  const mapLookupProps = useMapLookup({ mapRef, ...mapProps });

  const mapContext = {
    isLoadingMap,
    height,
    forwardRef: mapRef,
    filters: getFilters(isMobile),
    ...mapProps,
    ...mapLookupProps,
    className: classNames({ hidden: isTablePageView }),
    loadingMessage: defaultLoadingMessage,
  };

  return <MapContainer {...mapContext} />;
}

MapView.defaultProps = {
  withFilters: true,
  withNavigation: true,
  withHeatmap: true,
  pageView: {},
};

MapView.propTypes = {
  mapData: PropTypes.object.isRequired,
  dataLookup: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  pageView: PropTypes.object,
  PopupComponent: PropTypes.elementType,
  markerImage: PropTypes.string,
};

export default withLookup(MapView);
