import React from 'react';
import PropTypes from 'prop-types';

import { LegislatorListItem } from 'components/core/Table/components/ColumnItem';

import withBills from '../../utils/withBills';
import ViewAllLink from '../ViewAllLink';

function SponsorsColumn({ keyName, item, trackingActions, formatLegislatorUrl }) {
  const { trackBillSponsorVisited } = trackingActions;
  const currentItem = {
    ...item,
    legislators: item.sponsors,
    allLegislatorsSize: item.allSponsorsSize,
  };
  const tooltipContent = <ViewAllLink item={currentItem} />;

  return (
    <LegislatorListItem
      keyName={keyName}
      item={currentItem}
      trackLegislatorVisited={trackBillSponsorVisited}
      formatLegislatorUrl={formatLegislatorUrl}
      tooltipBottomContent={tooltipContent}
      columnClassName="legislator-bills__sponsors-column"
    />
  );
}

SponsorsColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  trackingActions: PropTypes.object,
  item: PropTypes.object.isRequired,
  formatLegislatorUrl: PropTypes.func.isRequired,
};

export default withBills(SponsorsColumn);
