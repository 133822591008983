import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LABEL_COLOR,
  DEFAULT_DISABLED_COLOR,
  WHITE_COLOR,
  BLUE_BADGE_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  billTagContainerSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: 250,
  },
  billTagSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontSize: 8,
    fontWeight: 500,
    color: DEFAULT_LABEL_COLOR,
  },
  billTagText: {
    fontSize: 7,
    fontWeight: 500,
    color: WHITE_COLOR,
    paddingBottom: 0,
  },
  billTagLabelText: {
    paddingBottom: 0,
    paddingRight: 5,
    fontSize: 8,
    fontWeight: 500,
    color: DEFAULT_LABEL_COLOR,
  },
  billTag: {
    backgroundColor: BLUE_BADGE_COLOR,
    margin: 1,
    borderRadius: 4,
    padding: '2px 4px',
    marginLeft: 3,
  },
  billLinksSection: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  billLink: {
    paddingBottom: 0,
    fontSize: 8,
    marginLeft: 15,
  },
  billEmptyLink: {
    fontSize: 8,
    marginLeft: 15,
    color: DEFAULT_DISABLED_COLOR,
  },
  linkStyle: {
    paddingBottom: 0,
  },
});

export default styles;
