import { some, get } from 'lodash';

const someSubscriptionAttribute = attribute => ({ subscription, subscriptions }) => (
  get(subscription, attribute) || some(subscriptions, attribute)
);

export const getIsTracking = trackings => some(
  trackings,
  someSubscriptionAttribute('isTracking')
);

export const getSendWeekAheadEmail = trackings => some(
  trackings,
  someSubscriptionAttribute('sendWeekAheadEmail')
);
