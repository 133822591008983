import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'components/core/Table';

import styles from './styles.module.scss';

// TODO: Render a Link to the Recipient page in Moneyball after Moneyball V2 is released to the clients.
export default function RecipientColumn({ column, item, fixedWidth = false }) {
  const { keyName } = column;
  const { recipient } = item || {};

  function renderValue() {
    return (
      <div className={fixedWidth && styles.fixedWidth}>
        {recipient}
      </div>
    );
  }

  return <Column key={keyName} keyName={keyName}>{renderValue}</Column>;
}

RecipientColumn.propTypes = {
  column: PropTypes.object.isRequired,
  item: PropTypes.object,
  fixedWidth: PropTypes.bool,
};
