import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import DataField from '../DataField';
import styles from './styles.module.scss';

function BiographyField({ description, label }) {
  const descriptionText = !isEmpty(description) && (
    <ul className={styles.listBiography}>
      {description.map((item, index) => (
        <li key={`description-${index}`}>{item}</li>
      ))}
    </ul>
  );

  return (
    <DataField
      label={label}
      value={descriptionText}
    />
  );
}

BiographyField.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
};

BiographyField.defaultProps = {
  label: 'Biography',
};

export default BiographyField;
