import { StyleSheet } from '@react-pdf/renderer';

import { LABEL_FIELD_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  eventDate: {
    fontSize: 8,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  label: {
    color: LABEL_FIELD_COLOR,
    display: 'inline',
    fontSize: 8,
    fontWeight: 700,
  },
});

export default styles;
