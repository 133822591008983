import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import {
  trackHearingVisited,
  trackHearingAddedToCalendar,
} from 'utils/tracking/intel';

import styles from './styles.module.scss';

function TooltipContent({ event, toggleExportModal, toggleShareModal }) {
  const {
    addToCalendarUrl,
    url,
  } = event || {};

  function handleToggleExportModal() {
    toggleExportModal(event);
  }

  function handleToggleShareModal() {
    toggleShareModal(event);
  }

  function handleTrackHearingVisited() {
    trackHearingVisited({ menu: 'dropdown' });
  }

  return (
    <div className={classNames('event-actions', styles.tooltipContent)}>
      <Link
        theme="light"
        href={url}
        customIcon={<i className="far fa-external-link" />}
        onClick={handleTrackHearingVisited}
        className="hearing-view"
        displayExternalIcon={false}
        isExternal
      >
        View
      </Link>
      <Link
        theme="light"
        href={addToCalendarUrl}
        customIcon={<i className="far fa-calendar-plus" />}
        onClick={trackHearingAddedToCalendar}
        className="hearing-add-to-calendar"
        displayExternalIcon={false}
        isExternal
      >
        Add to calendar
      </Link>
      <Button
        theme="light"
        className="hearing-export-single"
        onClick={handleToggleExportModal}
      >
        Export
      </Button>
      <Button
        theme="light"
        className="hearing-share-single"
        onClick={handleToggleShareModal}
      >
        Share
      </Button>
    </div>
  );
}

TooltipContent.propTypes = {
  event: PropTypes.object.isRequired,
  toggleExportModal: PropTypes.func.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
};

export default TooltipContent;
