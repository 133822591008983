import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import constants from 'components/core/DatePicker/constants';

import styles from '../../styles.module.scss';

const { MONTHS, MONTH_FORMAT } = constants;

function MonthsRange({ date, onMonthChange }) {
  const selectedMonth = moment(date).format(MONTH_FORMAT);

  function handleMonthChange(e) {
    const { value } = e.target;
    onMonthChange(MONTHS.indexOf(value));
  }

  return (
    <div className={styles.container}>
      <select
        className={styles.select}
        value={selectedMonth}
        onChange={handleMonthChange}
      >
        {MONTHS.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>

      <i className={classNames('icon', 'fas', 'fa-caret-down', styles.dropdownIcon)} />
    </div>
  );
}

MonthsRange.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onMonthChange: PropTypes.func,
};

export default MonthsRange;
