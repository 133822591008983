import isEmpty from 'lodash/isEmpty';

export function isFirstPosition(index) {
  return index === 0;
}

export function isLastPosition(index, array) {
  return array.length - 1 === index;
}

export function isBlankText(string) {
  return isEmpty(string.trim());
}

export function normalizeArrayToString(array) {
  return array.filter(el => el).join('').split(' ').join('');
}

export function isBlankspace(index, array) {
  const letter = array[index];

  return letter && letter === ' ';
}

export function moveCursorBackward(index, requiredLetters, fieldRefs) {
  if (!isFirstPosition(index)) {
    const cursorPosition = isBlankText(requiredLetters[index - 1]) ? 2 : 1;
    const inputRef = fieldRefs[index - cursorPosition];

    inputRef && inputRef.current.focus();
  }
}

export function moveCursorForward(index, requiredLetters, fieldRefs) {
  if (!isLastPosition(index, requiredLetters)) {
    const cursorPosition = isBlankText(requiredLetters[index + 1]) ? 2 : 1;
    const inputRef = fieldRefs[index + cursorPosition];

    inputRef && inputRef.current.focus();
  }
}
