import { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

function useFetchData({ fetchData, pageView, notifyDefaultFetchDataFailed, filters }) {
  const isHiddenTable = !isEmpty(pageView) && !pageView.isTablePageView;
  const isInitialRender = useRef(true);

  function handleFetchData(params = {}) {
    return fetchData({
      ...filters,
      ...params,
    }).catch((error) => {
      notifyDefaultFetchDataFailed && notifyDefaultFetchDataFailed();
      throw error;
    });
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!isHiddenTable) {
      handleFetchData();
    }
  }, [isHiddenTable]);

  return { handleFetchData };
}

export default useFetchData;
