import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { AdvancedSearchContext } from './utils/withSearch';
import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';
import BaseLabelGroup from './components/Options/components/BaseLabelGroup';
import BaseOption from './components/Options/components/BaseOption';
import BaseButtonGroup from './components/Options/components/BaseButtonGroup';
import BaseRange from './components/Options/components/BaseRange';
import BaseLabelInputGroup from './components/Options/components/BaseLabelInputGroup';

import { MOBILE_SEARCH_PLACEHOLDER } from './utils/constants';

function AdvancedSearch({
  searchValue,
  onChangeSearchValue,
  paginatorSaved,
  onChangeSavedPage,
  onClearSearch,
  searchPlaceholder,
  ...restProps
}) {
  const { isDesktop } = useMediaQuery();

  const advancedSearchContext = {
    ...restProps,
    isMobile: !isDesktop,
    inputProps: {
      value: searchValue,
      onChange: onChangeSearchValue,
      placeholder: !isDesktop ? MOBILE_SEARCH_PLACEHOLDER : searchPlaceholder,
      onClearSearch,
    },
    ...(!isEmpty(paginatorSaved) && {
      paginatorSaved: {
        ...paginatorSaved,
        onChangeSavedPage,
      },
    }),
  };

  return (
    <AdvancedSearchContext.Provider value={advancedSearchContext}>
      {isDesktop ? <DesktopView /> : <MobileView />}
    </AdvancedSearchContext.Provider>
  );
}

AdvancedSearch.defaultProps = {
  paginatorSaved: {
    current: 1,
    total: 1,
  },
  title: 'Advanced Search',
  searchValue: '',
  displayHeader: true,
  displaySearchInput: true,
  allowSubmit: false,
  isClickedItem: false,
  isDirty: false,
};

AdvancedSearch.propTypes = {
  optionsNode: PropTypes.node,
  searchValue: PropTypes.string,
  filterByNode: PropTypes.object,
  isLoadingSearches: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  paginatorSaved: PropTypes.object,
  isDirty: PropTypes.bool,
  allowSubmit: PropTypes.bool,
  onChangeSearchValue: PropTypes.func,
  onClearSearch: PropTypes.func,
  savedItems: PropTypes.array,
  recentItems: PropTypes.array,
  loadSavedSearches: PropTypes.func.isRequired,
  loadRecentSearches: PropTypes.func.isRequired,
  onSaveItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onClearItem: PropTypes.func,
  onClearAll: PropTypes.func,
  onClickItem: PropTypes.func,
  onChangeSavedPage: PropTypes.func,
  onCancel: PropTypes.func,
  onSearch: PropTypes.func,
  isDisplayedOnCancel: PropTypes.bool,
  isPresentShowMore: PropTypes.bool,
  isSaveNameEnabled: PropTypes.bool,
  displayHeader: PropTypes.bool,
  isClickedItem: PropTypes.bool,
  displaySearchInput: PropTypes.bool,
  title: PropTypes.string,
  optionsClassName: PropTypes.string,
  searchesClassName: PropTypes.string,
};

export default AdvancedSearch;
export { BaseLabelGroup, BaseOption, BaseButtonGroup, BaseRange, BaseLabelInputGroup };
