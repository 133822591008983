import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontSize: 10,
    fontWeight: 500,
  },
});

export default styles;
