import { useState, useEffect } from 'react';

import {
  fetchComments,
  saveComment,
  updateComment,
  deleteComment,
  transformCommentForUI,
} from '../api';
import { CommentsRepository } from './index';

const useComments = ({
  parentId,
  parentType,
  onBeforeLoadComments,
  onLoadComments,
  onSaveComment,
  onUpdateComment,
  onDeleteComment,
  shouldLoadAllComments,
}) => {
  const [comments, setComments] = useState([]);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadComments = () => {
    setIsLoading(true);
    onBeforeLoadComments && onBeforeLoadComments();
    // in order to avoid problem with duplicates after fetching due to new comments between two fetches,
    // we don't use pagination, instead we fetch the comments older than the oldest comment already loaded
    const oldestCreatedAt = CommentsRepository(comments).getOldestCreatedAt();

    fetchComments({
      parentId,
      parentType,
      createdAtBefore: oldestCreatedAt,
      shouldLoadAllComments,
    })
      .then((response) => {
        const { data: newComments, next_page: nextPage } = response;
        const responseComments = newComments.map(transformCommentForUI);

        setHasMoreToLoad(!!nextPage);
        setComments(CommentsRepository(comments).appendComments(responseComments));

        onLoadComments && onLoadComments(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setComments([]);
    handleLoadComments();
  }, [parentId]);

  const handleSaveComment = (comment) => {
    setIsSaving(true);

    return saveComment(comment)
      .then((response) => {
        const responseComment = transformCommentForUI(response);

        setComments(CommentsRepository(comments).prependComments([responseComment]));

        onSaveComment && onSaveComment(response, comment.mentions);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleUpdateComment = comment => (
    updateComment(comment)
      .then((response) => {
        const responseComment = transformCommentForUI(response);

        setComments(CommentsRepository(comments).updateComment(responseComment));

        onUpdateComment && onUpdateComment(response, comment.mentions);
      }));

  const handleDeleteComment = commentId => (
    deleteComment(commentId)
      .then((response) => {
        setComments(CommentsRepository(comments).deleteById(commentId));

        onDeleteComment && onDeleteComment(response);
      }));

  return {
    comments,
    hasMoreToLoad,
    isSaving,
    isLoading,
    handleLoadComments,
    handleSaveComment,
    handleUpdateComment,
    handleDeleteComment,
  };
};

export default useComments;
