import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function FooterContainer({ children, className }) {
  return (
    <footer className={classNames(styles.footer, className)}>
      {children}
    </footer>
  );
}

FooterContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FooterContainer;
