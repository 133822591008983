import { isEmpty, find, map, nth, omit, omitBy, pick, reduce } from 'lodash';

import extractUrls from 'utils/extract-urls';

export const transformPreviewDataToFE = items => map(items, ({ og_data: ogData, url }) => ({
  ...pick(ogData, ['image', 'description', 'title']),
  url,
}));

function getFirstExtractedUrl(text) {
  const extractedUrls = extractUrls(text);

  return nth(extractedUrls, 0);
}

export const getCardsUrlToPreview = cards => omitBy(
  reduce(
    cards,
    (result, card) => ({
      ...result,
      [card.id]: getFirstExtractedUrl(card.description),
    }),
    {}
  ),
  isEmpty
);

export const removeCardsLinkPreview = ({ cardsLinkPreview, updatedCards }) => {
  const cardsId = map(updatedCards, 'id');

  return omit(cardsLinkPreview, cardsId);
};

export const getUpdatedLinksPreview = (
  oldLinksPreview,
  {
    cardsLink,
    linksPreview,
    repeatFetch = false,
  }
) => {
  const newLinksPreview = reduce(cardsLink, (result, link, key) => {
    const urlPreview = find(linksPreview, { url: link });
    const isDefault = isEmpty(urlPreview) && !repeatFetch;

    return {
      ...result,
      [key]: isDefault ? { url: link } : urlPreview,
    };
  }, {});

  return {
    ...oldLinksPreview,
    ...newLinksPreview,
  };
};
