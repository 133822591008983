import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextPlaceholder from 'components/core/Placeholder/components/Text';
import styles from './styles.module.scss';

function Paginator({ className }) {
  return (
    <div className={classNames(styles.paginatorWrapper, className)}>
      <TextPlaceholder className={styles.textPlaceholder} />
    </div>
  );
}

Paginator.propTypes = {
  className: PropTypes.string,
};
export default Paginator;
