import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import useComments from './utils/useComments';
import Comment from './components/Comment';
import NewComment from './components/NewComment';
import styles from './styles.module.scss';

function Comments({
  className,
  parentId,
  parentType,
  highlightedCommentId,
  highlightedParentId,
  shouldLoadAllComments,
  onBeforeLoadComments,
  onLoadComments,
  onSaveComment,
  onUpdateComment,
  onDeleteComment,
  onBeforeLoadReplies,
  onLoadReplies,
  onSaveReply,
  onUpdateReply,
  onDeleteReply,
}) {
  const {
    comments,
    hasMoreToLoad,
    isSaving,
    isLoading,
    handleLoadComments,
    handleSaveComment,
    handleUpdateComment,
    handleDeleteComment,
  } = useComments({
    parentId,
    parentType,
    onBeforeLoadComments,
    onLoadComments,
    onSaveComment,
    onUpdateComment,
    onDeleteComment,
    shouldLoadAllComments,
  });

  const shouldLoadReplies = commentId => (
    shouldLoadAllComments && Number(highlightedParentId || highlightedCommentId) === commentId
  );

  return (
    <div className={className}>
      <NewComment
        parentId={parentId}
        parentType={parentType}
        isPosting={isSaving}
        onPost={handleSaveComment}
      />
      {comments.map(comment => (
        <Comment
          comment={comment}
          key={comment.id}
          onEdit={handleUpdateComment}
          onDelete={handleDeleteComment}
          highlightedCommentId={highlightedCommentId}
          shouldLoadAllComments={shouldLoadReplies(comment.id)}
          onBeforeLoadReplies={onBeforeLoadReplies}
          onLoadReplies={onLoadReplies}
          onSaveReply={onSaveReply}
          onUpdateReply={onUpdateReply}
          onDeleteReply={onDeleteReply}
        />
      ))}
      {hasMoreToLoad && (
        <Button
          theme="tableLink"
          className={styles.loadMore}
          onClick={handleLoadComments}
          disabled={isLoading}
        >
          Load more comments
          {isLoading && <Loader size="small" onlyIcon className={styles.loader} />}
        </Button>
      )}
    </div>
  );
}

Comments.defaultProps = {
  shouldLoadAllComments: false,
};

Comments.propTypes = {
  className: PropTypes.string,
  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.string.isRequired,
  highlightedCommentId: PropTypes.string,
  highlightedParentId: PropTypes.string,
  shouldLoadAllComments: PropTypes.bool,
  onBeforeLoadComments: PropTypes.func,
  onLoadComments: PropTypes.func,
  onSaveComment: PropTypes.func,
  onUpdateComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
  onBeforeLoadReplies: PropTypes.func,
  onLoadReplies: PropTypes.func,
  onSaveReply: PropTypes.func,
  onUpdateReply: PropTypes.func,
  onDeleteReply: PropTypes.func,
};

export default Comments;
