import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Avatar from 'components/core/Avatar';
import { getCurrentUser } from 'utils/currentUser';

import { commentShape } from '../../utils/shapes';
import NewComment from '../NewComment';
import Actions from './components/Actions';
import CommentBox from './components/CommentBox';
import useComment from './useComment';
import useReplies from './useReplies';
import styles from './styles.module.scss';

function Comment({
  comment,
  isReply,
  highlightedCommentId,
  onOpenParentReply,
  onEdit,
  onDelete,
  shouldLoadAllComments,
  onBeforeLoadReplies,
  onLoadReplies,
  onSaveReply,
  onUpdateReply,
  onDeleteReply,
}) {
  const isCurrentUser = comment.user.id === getCurrentUser().userId;
  const {
    fieldContent,
    isEditing,
    isDeleting,
    showSaveLoader,
    setIsEditing,
    handleDelete,
    handleCommentChange,
    handleSaveEdit,
    handleCancelEdit,
    handleMentionsChange,
  } = useComment({
    comment,
    onEdit,
    onDelete,
  });

  const {
    replies,
    repliesCount,
    hasMoreToLoad,
    isSaving,
    isLoading,
    isReplyOpen,
    replyUserMention,
    newReplyRef,
    handleLoadReplies,
    handleSaveReply,
    handleUpdateReply,
    handleDeleteReply,
    handleReplyClick,
    handleCancelReplyMention,
  } = useReplies({
    parentComment: comment,
    onBeforeLoadReplies,
    onLoadReplies,
    onSaveReply,
    onUpdateReply,
    onDeleteReply,
    onOpenParentReply,
    shouldLoadAllComments,
  });
  const isCommentHighlighted = Number(highlightedCommentId) === comment.id;
  const commentRef = useRef();

  useEffect(() => {
    if (isCommentHighlighted) {
      const { offsetTop } = commentRef.current;
      const navigationHeight = document.getElementById('navigation').offsetHeight;
      isCommentHighlighted && window.scrollTo(0, offsetTop - navigationHeight);
    }
  }, [isCommentHighlighted]);

  const renderReply = reply => (
    <Comment
      comment={reply}
      key={reply.id}
      isReply
      onBeforeLoadReplies={onBeforeLoadReplies}
      onOpenParentReply={handleReplyClick}
      onEdit={handleUpdateReply}
      onDelete={handleDeleteReply}
      highlightedCommentId={highlightedCommentId}
    />
  );

  return (
    <div ref={commentRef} className={styles.comment}>
      <Avatar photoUrl={comment.user.photoUrl} name={comment.user.name} className={styles.avatar} />
      <div className={styles.container} id={`test__comment-${comment.id}`}>
        <CommentBox
          isEditing={isEditing}
          isCurrentUser={isCurrentUser}
          isCommentHighlighted={isCommentHighlighted}
          userName={comment.user.name}
          commentDate={comment.createdAt}
          content={fieldContent}
          onEnableEdit={() => setIsEditing(true)}
          onDelete={handleDelete}
          onChange={handleCommentChange}
          isDeleting={isDeleting}
          id={comment.id}
          isReply={isReply}
          onMentionsChange={handleMentionsChange}
          onSaveEdit={handleSaveEdit}
        />
        <Actions
          isEditing={isEditing}
          onReplyClick={handleReplyClick}
          repliesCount={repliesCount}
          areFirstRepliesLoaded={!!replies.length}
          onSaveEdit={handleSaveEdit}
          onCancelEdit={handleCancelEdit}
          isSaveDisabled={isEmpty(fieldContent)}
          showSaveLoader={showSaveLoader}
          onLoadMoreClick={handleLoadReplies}
          hasMoreToLoad={hasMoreToLoad}
          isLoadingReplies={isLoading}
        />
        <div className={styles.replies}>
          {replies.map(renderReply)}
          {isReplyOpen && (
            <NewComment
              replyUserMention={replyUserMention}
              onCancelReplyMention={handleCancelReplyMention}
              parentId={comment.id}
              onPost={handleSaveReply}
              fieldRef={newReplyRef}
              className={styles.newReply}
              isPosting={isSaving}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape(commentShape).isRequired,
  isReply: PropTypes.bool,
  highlightedCommentId: PropTypes.string,
  onOpenParentReply: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  shouldLoadAllComments: PropTypes.bool,
  onBeforeLoadReplies: PropTypes.func,
  onLoadReplies: PropTypes.func,
  onSaveReply: PropTypes.func,
  onUpdateReply: PropTypes.func,
  onDeleteReply: PropTypes.func,
};

export default Comment;

export { default as CommentPlaceholder } from './components/CommentPlaceholder';
