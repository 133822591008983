import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select';
import uniqueId from 'lodash/uniqueId';

import KEY_CODES from 'utils/keyCodes';

import theme from '../theme';

export default function CreatableInput({
  uniqueIdValue,
  overrideValue,
  onChange,
  ...restProps
}) {
  const [selectValue, setSelectValue] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    overrideValue ? setSelectValue(overrideValue) : setSelectValue([]);
  }, [overrideValue]);

  const customComponents = { DropdownIndicator: null };

  const createOption = label => ({
    value: uniqueIdValue ? `${label}-${uniqueId()}` : label,
    label,
  });

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.charCode || event.which;
    const isEnterKey = keyCode === KEY_CODES.ENTER;
    const isTabKey = keyCode === KEY_CODES.TAB;

    const { value } = event.target;

    if (!value) return;

    if (isEnterKey || isTabKey) {
      setSelectValue((prevValue) => {
        const newSelectValue = [...prevValue, createOption(value)];
        onChange && onChange(newSelectValue);

        return newSelectValue;
      });
      setInputValue('');
      event.preventDefault();
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    setSelectValue(value);
    onChange(value);
  };

  return (
    <Creatable
      styles={theme()}
      value={selectValue}
      inputValue={inputValue}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onInputChange={handleInputChange}
      components={customComponents}
      menuIsOpen={false}
      isMulti
      isClearable
      {...restProps}
    />
  );
}

CreatableInput.defaultProps = {
  uniqueIdValue: true,
  placeholder: '', // get rid of default 'Select...' placeholder
};

CreatableInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  uniqueIdValue: PropTypes.bool,
  overrideValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};
