import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import EmptyPlaceholder from 'components/core/EmptyPlaceholder';

import styles from './styles.module.scss';

function EmptyTablePlaceholder({ title, description, className }) {
  return (
    <div className={classNames(styles.placeholderContainer, className)}>
      <EmptyPlaceholder
        title={title}
        description={description}
      />
    </div>
  );
}

EmptyTablePlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};
export default EmptyTablePlaceholder;
