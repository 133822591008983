import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import TextPlaceholder from '../Text';
import styles from './styles.module.scss';

function TagsPlaceholder({ className, tagClassName, itemsNumber }) {
  function renderTag(_, index) {
    return <TextPlaceholder className={classNames(styles.tag, tagClassName)} key={`tags-${index}`} />;
  }

  return (
    <div className={classNames(styles.tags, className)}>
      {map(Array(itemsNumber), renderTag)}
    </div>
  );
}

TagsPlaceholder.defaultProps = {
  itemsNumber: 2,
};

TagsPlaceholder.propTypes = {
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  itemsNumber: PropTypes.number,
};

export default TagsPlaceholder;
