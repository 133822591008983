import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants';
import Link from 'components/core/Link';

import { formattingTypes } from './utils';
import styles from './styles.module.scss';

export default function DetailRow({ title, value, customFormatting, isCapitalized, link, onClick, className }) {
  const getFormattedValue = () => {
    const { phone, email, externalLink, internalLink } = formattingTypes;

    if (isEmpty(value)) {
      return EMPTY_VALUE;
    }

    if (customFormatting === phone) {
      return <Link theme="default" className={styles.link} href={`tel:${value}`} >{value}</Link>;
    }

    if (customFormatting === email) {
      return <Link theme="default" className={styles.link} href={`mailto:${value}`} >{value}</Link>;
    }

    if (customFormatting === externalLink || customFormatting === internalLink) {
      return (
        <Link
          theme="default"
          isExternal={customFormatting === externalLink}
          className={styles.link}
          onClick={onClick}
          href={link}
        >
          {value}
        </Link>
      );
    }

    return value;
  };

  return (
    <div className={classNames(styles.label, className)}>
      {title && <div className={styles.title}>{title}</div>}
      <div
        className={classNames(
          styles.content,
          isCapitalized && styles.textCapitalized
        )}
      >
        {getFormattedValue(value)}
      </div>
    </div>
  );
}

DetailRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  customFormatting: PropTypes.oneOf(Object.values(formattingTypes)),
  isCapitalized: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
  className: PropTypes.string,
};

DetailRow.defaultProps = {
  isCapitalized: false,
  onClick: () => {},
};
