import { useEffect, useState } from 'react';

function useStickyNode({ ref: nodeRef, offsetHeight }) {
  const [isSticky, setIsSticky] = useState(false);

  function handleScroll() {
    const nodeCoordinates = nodeRef.current && nodeRef.current.getBoundingClientRect();
    const top = nodeCoordinates.top;
    const shouldStick = top < offsetHeight;

    setIsSticky(shouldStick);
  }

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isSticky;
}

export default useStickyNode;
