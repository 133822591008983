import React from 'react';
import PropTypes from 'prop-types';

import { SlideUpModal } from 'components/core/Modal';
import Link from 'components/core/Link';
import PanelHeader from 'components/NavigationV2/components/MobileView/components/common/PanelHeader';

import { getAccountSections, LOG_OUT_LINK_PROPS } from './utils';
import styles from './styles.module.scss';

function Panel({ isOpen, toggleOpen }) {
  const accountSections = getAccountSections();
  const renderLink = ({ href, name, isExternal, disabled }) => {
    if (disabled) {
      return null;
    }

    return (
      <Link
        displayExternalIcon
        href={href}
        theme="light"
        className={styles.link}
        isExternal={isExternal}
        key={href}
        iconClassName={styles.icon}
      >
        {name}
      </Link>
    );
  };

  const renderSection = ({ id, title, links }) => {
    if (links.every(link => link.disabled)) {
      return null;
    }

    return (
      <div className={styles.section} key={id}>
        <h5 className={styles.title}>{title}</h5>

        {links.map(renderLink)}
      </div>
    );
  };

  return (
    <SlideUpModal
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      className={styles.modal}
      bodyClassName={styles.body}
    >
      <PanelHeader title="Account" onClose={toggleOpen} />

      <div className={styles.links}>
        {accountSections.map(renderSection)}

        <Link {...LOG_OUT_LINK_PROPS} theme="light" className={styles.logOut}>
          Log out
        </Link>
      </div>
    </SlideUpModal>
  );
}

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default Panel;
