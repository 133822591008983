import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { mailInfoShape, optionsShape } from 'components/core/ButtonShare/shapes';
import { MY_FEED } from 'utils/constants/socialMedia';
import LabelGroup from 'components/core/LabelGroup';
import RadioBox from 'components/core/RadioBox';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';

import useShare from './useShare';
import styles from '../../styles.module.scss';

function ShareModal({
  options,
  theme,
  className,
  onShare,
  isOpen,
  fullUrl,
  mailInfo,
  ...restProps
}) {
  const {
    isShareModalOpen,
    shareOption,
    toggleShareModal,
    handleShareSubmit,
    handleChangeShareItem,
  } = useShare({ ...restProps, options, onShare, isOpen, fullUrl, mailInfo });

  const shareActions = (
    <>
      <Button
        size="small"
        theme="light"
        onClick={toggleShareModal}
      >
        Cancel
      </Button>

      <Button
        size="small"
        disabled={!shareOption}
        onClick={shareOption && handleShareSubmit}
      >
        Share
      </Button>
    </>
  );

  return (
    <>
      <Button
        theme={theme}
        className={className}
        onClick={toggleShareModal}
      >
        Share
      </Button>
      <Modal
        isOpen={isShareModalOpen}
        title="Share to..."
        buttons={shareActions}
        toggleOpen={toggleShareModal}
      >
        <LabelGroup containerClassName={styles.modalContainer}>
          {map(options, ({ value, label }) => {
            if (value === MY_FEED) {
              return (
                <RadioBox
                  key={value}
                  value={value}
                  label={label}
                  name="export"
                  defaultChecked={value === shareOption}
                  onChange={handleChangeShareItem}
                />
              );
            }

            return (
              <RadioBox
                key={value}
                value={value}
                label={label}
                name="export"
                defaultChecked={value === shareOption}
                onChange={handleChangeShareItem}
              />
            );
          })}
        </LabelGroup>
      </Modal>
    </>
  );
}

ShareModal.propTypes = {
  itemName: PropTypes.string,
  onShare: PropTypes.func,
  className: PropTypes.string,
  theme: PropTypes.string,
  options: optionsShape,
  recordId: PropTypes.string,
  recordType: PropTypes.string,
  itemType: PropTypes.string,
  isOpen: PropTypes.bool,
  fullUrl: PropTypes.string,
  mailInfo: mailInfoShape,
};

export default ShareModal;
