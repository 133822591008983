import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PartyIcon from 'components/shared/party-icon';

import { percentage } from './utils';
import styles from './styles.module.scss';

function PartyLegend({ republicans, democrats, others, className, showOthers }) {
  const total = republicans + democrats + others;

  return (
    <div className={classNames(styles.legend, className)}>
      <div className={styles.item}>
        <PartyIcon partyShort="R" partySlug="republican" />
        {`${republicans} (${percentage(republicans, total)}%)`}
      </div>
      <div className={styles.item}>
        <PartyIcon partyShort="D" partySlug="democratic" />
        {`${democrats} (${percentage(democrats, total)}%)`}
      </div>
      {showOthers && (
        <div className={styles.item}>
          <PartyIcon partyShort="O" partySlug="other" />
          {`${others} (${percentage(others, total)}%)`}
        </div>)
      }
    </div>
  );
}

PartyLegend.defaultProps = {
  republicans: 0,
  democrats: 0,
  others: 0,
  showOthers: true,
};

PartyLegend.propTypes = {
  className: PropTypes.string,
  republicans: PropTypes.number,
  democrats: PropTypes.number,
  others: PropTypes.number,
  showOthers: PropTypes.bool,
};

export default PartyLegend;
