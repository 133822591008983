import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';

function useCustomToggleView({ customToggleViewProps }) {
  const withCustomToggleView = !isEmpty(customToggleViewProps);
  const {
    isOpenModal: isCustomViewOpen,
    toggleModal: handleToggleCustomView,
    closeModal: handleCloseCustomView,
  } = useModal();

  function handleClick(...args) {
    const { onClick } = customToggleViewProps || {};
    handleToggleCustomView();

    onClick && onClick(...args);
  }

  function getToggleViewProps() {
    if (!withCustomToggleView) {
      return {};
    }

    return {
      ...customToggleViewProps,
      isCustomViewOpen,
      onClick: handleClick,
      onToggleCustomView: handleToggleCustomView,
    };
  }

  return {
    customToggleViewProps: getToggleViewProps(),
    onToggleCustomView: handleToggleCustomView,
    onCloseCustomView: handleCloseCustomView,
  };
}

export default useCustomToggleView;
