import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionBar from 'components/core/ActionBar';
import Link from 'components/core/Link';
import Button from 'components/core/Button';
import withCalendar from 'components/core/HearingCalendars/utils/withCalendar';

import useActionFooter from './useActionFooter';
import styles from './styles.module.scss';

function FooterActions({
  checkedEvents,
  description,
  onClearAllEvents,
  toggleExportModal,
  toggleShareModal,
  isFixed,
}) {
  const {
    isPresentSelectedItems,
    onlyOneEventSelected,
    checkedEventsFirstItem,
  } = useActionFooter({ checkedEvents });

  const btnProps = {
    theme: 'light',
    size: 'medium',
  };

  function handleToggleExportModal() {
    toggleExportModal();
  }

  function handleToggleShareModal() {
    toggleShareModal();
  }

  return isPresentSelectedItems && (
    <ActionBar
      title="Actions"
      description={description}
      selectedItems={checkedEvents}
      isFixed={isFixed}
    >
      <Button
        {...btnProps}
        onClick={onClearAllEvents}
      >
        Clear
      </Button>

      {onlyOneEventSelected && (
        <>
          <Link
            {...btnProps}
            className={classNames('hearing-view', styles.button)}
            href={checkedEventsFirstItem.url}
            target="_blank"
            rel="noopener noreferrer"
            customIcon={<i className="far fa-external-link" />}
          >
            View
          </Link>
          <Link
            {...btnProps}
            className={classNames('hearing-add-to-calendar', styles.button)}
            href={checkedEventsFirstItem.addToCalendarUrl}
            target="_blank"
            rel="noopener noreferrer"
            customIcon={<i className="far fa-calendar-plus" />}
          >
            Add to Calendar
          </Link>
        </>
      )}

      <Button
        {...btnProps}
        className={classNames('hearing-export-selected', styles.button)}
        onClick={handleToggleExportModal}
      >
        Export
      </Button>

      {onlyOneEventSelected && (
        <Button
          {...btnProps}
          className={classNames('hearing-share-selected', styles.button)}
          onClick={handleToggleShareModal}
        >
          Share
        </Button>
      )}
    </ActionBar>
  );
}

FooterActions.defaultProps = {
  description: {
    default: 'Select your actions',
    desktop: 'Select item(s) and choose action',
  },
  isFixed: true,
};

FooterActions.propTypes = {
  description: PropTypes.object,
  checkedEvents: PropTypes.array.isRequired,
  onClearAllEvents: PropTypes.func.isRequired,
  toggleExportModal: PropTypes.func.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
  isFixed: PropTypes.bool,
};

export default withCalendar(FooterActions);
