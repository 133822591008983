import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import { map, truncate, isEmpty, slice, size } from 'lodash';

import {
  BLUE_BADGE_COLOR,
  BLUE_BADGE_BORDER_COLOR,
  SECONDARY_BADGE_COLOR,
  GREEN_BADGE_COLOR,
  GREEN_BADGE_BORDER_COLOR,
  WHITE_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';
import { TAGS_DISPLAY_LENGTH } from 'components/core/ViewTags/constants';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import {
  getDisplayingStatus,
} from 'components/Dashboard/common/FeedPage/components/Export/components/Document/utils';
import {
  DISPLAY_PDF_TAGS,
} from 'components/Dashboard/common/FeedPage/components/Export/components/Modal/constants';
import { stringNumberType } from 'utils/shapes';

const styles = StyleSheet.create({
  flexContainer: {
    marginBottom: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  badge: {
    backgroundColor: SECONDARY_BADGE_COLOR,
    color: WHITE_COLOR,
    fontSize: 10,
    borderRadius: 3,
    padding: '2px 6px',
    margin: '0 5px 5px 0',
  },
  greenBadge: {
    backgroundColor: GREEN_BADGE_COLOR,
    borderWidth: 1,
    borderColor: GREEN_BADGE_BORDER_COLOR,
  },
  blueBadge: {
    borderWidth: 1,
    backgroundColor: BLUE_BADGE_COLOR,
    borderColor: BLUE_BADGE_BORDER_COLOR,
  },
  link: {
    textDecoration: 'none',
  },
});

function Tags({ displayingProps, tags, url, isRecent, tagStyle }) {
  const displayTagsSection = getDisplayingStatus({ displayingProps, section: DISPLAY_PDF_TAGS });

  if (!displayTagsSection || isEmpty(tags)) {
    return null;
  }

  const sizeTags = size(tags);
  const colorBadge = isRecent ? styles.greenBadge : styles.blueBadge;

  function renderTag(tag, index) {
    const { label } = tag || {};

    if (!label) {
      return null;
    }

    return (
      <Text
        key={`tag-item-${index}`}
        style={[styles.badge, colorBadge, tagStyle]}
      >
        {truncate(label, { length: 40 })}
      </Text>
    );
  }

  function getExtra() {
    if (sizeTags > TAGS_DISPLAY_LENGTH) {
      return (
        <Link src={url} style={[styles.badge, styles.blueBadge, styles.link, tagStyle]}>
          +{sizeTags - TAGS_DISPLAY_LENGTH}
        </Link>
      );
    }

    return null;
  }

  return (
    <View style={styles.flexContainer}>
      {map(slice(tags, 0, TAGS_DISPLAY_LENGTH), renderTag)}
      {getExtra()}
    </View>
  );
}

Tags.defaultProps = {
  tagStyle: {},
};

Tags.propTypes = {
  isRecent: PropTypes.bool,
  tags: PropTypes.arrayOf(tagShape),
  url: PropTypes.string,
  tagStyle: PropTypes.shape({
    [PropTypes.string]: stringNumberType,
  }),
  displayingProps: PropTypes.arrayOf(PropTypes.string),
};

export default Tags;
