import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import AddedToItem from 'components/core/Table/components/ColumnItem/AddedToItem';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { EMPTY_VALUE } from 'utils/constants';
import { workspaceShape } from 'redux/events/utils/shapes';

import InfoTab from '../InfoTab';
import styles from './styles.module.scss';

export default function AddedToTab({ tags, sharings }) {
  const renderAddedToItem = () => {
    if (isEmpty(tags) && !sharings) {
      return (
        <div className={styles.tabValue}>
          {EMPTY_VALUE}
        </div>
      );
    }

    return (
      <div className={styles.tabValue}>
        <AddedToItem
          isColumn={false}
          item={{ tags, sharings }}
          withTags
        />
      </div>
    );
  };

  return <InfoTab label="Added to" renderValue={renderAddedToItem} />;
}

AddedToTab.propTypes = {
  tags: PropTypes.arrayOf(tagShape),
  sharings: PropTypes.arrayOf(workspaceShape),
};
