import React from 'react';
import PropTypes from 'prop-types';

import {
  Preview,
  Customization,
  FileFormat,
} from 'components/core/ExportDocument/components/ExportModal';
import { ReportTitlePreview } from 'components/core/ExportDocument/components/ExportModal/components/Preview';

import InputField from '../InputField';
import Disclaimer from '../Disclaimer';
import shapes from '../../shapes';
import useSettings from './useSettings';

import styles from './styles.module.scss';

function Settings({
  documentSummaryProps,
  documentTitle,
  exportFileItems,
  previewFormats,
  settingsManager,
  settings,
  options,
  getCustomizationData,
}) {
  const {
    withTitle,
    showPreview,
    showDisclaimer,
    customizationItems,
    customizationDescription,
    onFileFormatChange,
    onTitleChange,
  } = useSettings({
    exportFileItems,
    previewFormats,
    settingsManager,
    settings,
    options,
    getCustomizationData,
  });

  const previewComponent = (
    <ReportTitlePreview
      logoUrl={settings.logoUrl}
      documentTitle={settings.title || documentTitle}
    />
  );

  return (
    <>
      {withTitle && (
        <>
          <InputField
            label="Title"
            value={settings.title}
            onChange={onTitleChange}
          />
          <hr className={styles.divider} />
        </>
      )}
      <div className={styles.container}>
        <div className={styles.flexItem}>
          <FileFormat
            values={[settings.fileFormat]}
            exportFileItems={exportFileItems}
            onChange={onFileFormatChange}
          />
          <Customization
            values={settings.customizationValues}
            description={customizationDescription}
            exportCustomizationItems={customizationItems}
            documentSummaryProps={documentSummaryProps}
            onChange={settingsManager.setCustomization}
            enableTooltip
          />
        </div>
        {showPreview && (
          <div className={styles.flexItem}>
            <Preview previewComponent={previewComponent} />
          </div>
        )}
      </div>
      {showDisclaimer && (
        <Disclaimer />
      )}
    </>
  );
}

Settings.propTypes = {
  documentTitle: PropTypes.string,
  documentSummaryProps: shapes.summary,
  exportFileItems: PropTypes.arrayOf(PropTypes.object),
  previewFormats: PropTypes.arrayOf(PropTypes.string),
  options: shapes.options,
  settings: shapes.settings.isRequired,
  settingsManager: shapes.settingsManager.isRequired,
  getCustomizationData: PropTypes.func.isRequired,
};

export default Settings;
