import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/roboto/Roboto-Light.ttf', fontWeight: 300 },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic' },
    { src: '/fonts/roboto/Roboto-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/roboto/Roboto-Medium.ttf', fontWeight: 500 },
    { src: '/fonts/roboto/Roboto-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/roboto/Roboto-Black.ttf', fontWeight: 900 },
  ],
});

Font.registerEmojiSource({
  url: 'https://twemoji.maxcdn.com/v/13.0.2/72x72/',
  format: 'png',
});

function BaseDocument({ children }) {
  return (
    <Document>
      {children}
    </Document>
  );
}

BaseDocument.propTypes = {
  children: PropTypes.node,
};

export default BaseDocument;
