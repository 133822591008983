import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import DataCard from 'components/core/Profile/components/DataCard';
import SearchBox from 'components/core/SearchBox';
import { BaseOption } from 'components/core/AdvancedSearch';
import { defaultDisclaimerProps } from 'components/core/Profile/utils/constants';

import TableView from './components/TableView';
import FilterButton from './components/FilterButton';
import { uiShape, stafferShape } from './utils/shapes';
import { getTrackingActions as getFederalTrackingActions } from './utils/trackingActions';
import useStaffers from './utils/useStaffers';
import styles from './styles.module.scss';

function Container({
  title,
  description,
  emptyDescription,
  trackingContext,
  ui,
  staffers,
  getTrackingActions,
  isFederal,
  id,
  withSearchFilters,
  ...restProps
}) {
  const {
    handleSearch,
    keywordSearch,
    stafferTitles,
    issuesArea,
    officeType,
    onChangeOfficeType,
    onChangeKeywordSearch,
    onChangeStafferTitle,
    onChangeIssueArea,
    onSubmit,
    onClearAll,
    areFiltersApplied,
  } = useStaffers({ ...restProps, id });

  function renderSearchForm() {
    if (!withSearchFilters) {
      return null;
    }

    const searchComponent = (
      <SearchBox
        withButton
        value={keywordSearch}
        onChange={onChangeKeywordSearch}
        onSubmit={onSubmit}
        withSearchDisclaimer
        disclaimerProps={defaultDisclaimerProps}
      />
    );

    return (
      <div className={styles.formBody}>
        <div className={styles.searchContainer}>
          <BaseOption
            title="Search"
            questionText="Type to refine the table's results."
            customComponent={searchComponent}
            className={styles.baseOption}
          />
          {areFiltersApplied && (
            <Button
              theme="link"
              onClick={onClearAll}
              className={styles.clearAll}
            >
              Clear all filters
            </Button>
          )}
        </div>
        <div className={styles.baseOption}>
          <FilterButton
            id={id}
            stafferTitles={stafferTitles}
            issuesArea={issuesArea}
            officeType={officeType}
            isFederal={isFederal}
            onSubmit={onSubmit}
            onChangeStafferTitle={onChangeStafferTitle}
            onChangeIssueArea={onChangeIssueArea}
            onChangeOfficeType={onChangeOfficeType}
          />
        </div>
      </div>
    );
  }

  return (
    <DataCard
      title={title}
      description={description}
      emptyDescription={emptyDescription}
    >
      {renderSearchForm()}

      <TableView
        {...restProps}
        ui={ui}
        staffers={staffers}
        onSearch={handleSearch}
        onClearAll={onClearAll}
        areFiltersApplied={areFiltersApplied}
        emptyDescription={emptyDescription}
        trackingActions={getTrackingActions(trackingContext)}
      />
    </DataCard>
  );
}

Container.defaultProps = {
  getTrackingActions: getFederalTrackingActions,
  withSearchFilters: false,
};

Container.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  emptyDescription: PropTypes.node,
  staffers: PropTypes.arrayOf(stafferShape).isRequired,
  ui: uiShape.isRequired,
  id: PropTypes.string,
  trackingContext: PropTypes.string.isRequired,
  getTrackingActions: PropTypes.func,
  withSearchFilters: PropTypes.bool,
  isFederal: PropTypes.bool,
};

export default Container;
