import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import useTabs from './useTabs';
import FixedTabs from './components/FixedTabs';
import Tab from './components/Tab';
import MobileView from './components/MobileView';
import DesktopView from './components/DesktopView';

export default function Tabs({
  className,
  defaultActiveTabId,
  children,
  tabHeaderClassName,
  tabContentClassName,
  onTabChange,
  sideContent,
  mobileLayout,
  buttonClassName,
}) {
  const { isMobile } = useMediaQuery();
  const tabs = React.Children.toArray(children);
  const { activeTab, onTabClick } = useTabs({
    tabs,
    onTabChange,
    defaultActiveTabId,
  });

  const props = {
    activeTab,
    onTabClick,
    tabs,
    className,
    tabHeaderClassName,
    tabContentClassName,
    sideContent,
    mobileLayout,
    buttonClassName,
  };

  return isMobile
    ? <MobileView {...props} />
    : <DesktopView {...props} />;
}

Tabs.defaultProps = {
  defaultActiveTabId: 0,
  mobileLayout: 'accordion',
};

Tabs.propTypes = {
  mobileLayout: PropTypes.string,
  defaultActiveTabId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tabHeaderClassName: PropTypes.string,
  tabContentClassName: PropTypes.string,
  onTabChange: PropTypes.func,
  sideContent: PropTypes.node,
  buttonClassName: PropTypes.string,
};

export { Tab, FixedTabs };
