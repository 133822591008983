import sortDirections from 'utils/constants/sortDirections';

/**
 * Toggle sorting direction
 * @param {string} direction direction to toggle
 */

const { ASC, DESC, NO } = sortDirections;

function toggleSortWithDefault(direction) {
  switch (direction) {
    case ASC:
      return DESC;
    case DESC:
      return NO;
    default:
      return ASC;
  }
}

function toggleSort(direction) {
  switch (direction) {
    case DESC:
      return ASC;
    default:
      return DESC;
  }
}

export default {
  toggleSort,
  toggleSortWithDefault,
};
