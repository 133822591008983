import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import stickyStyles from 'components/core/StickyContainer/styles.module.scss';
import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import Accordion, { Item as AccordionItem } from 'components/core/Accordion';

import DroppableItemPlaceholder from './components/DroppableItem/components/Placeholder';
import useStatementsAndFilters from './useStatementsAndFilters';
import AccordionTitle from './components/AccordionTitle';
import DroppableItem from './components/DroppableItem';
import styles from './styles.module.scss';

function StatementsAndFilters({ transactor, ...restProps }) {
  const {
    isStickyAndNotDragging,
    withProductBar,
    statements,
    styleProps,
    filters,
    nodeRef,
  } = useStatementsAndFilters({ ...restProps, transactor });

  const stickyClassNames = classNames(
    stickyStyles.wrapper,
    isStickyAndNotDragging && stickyStyles.isSticky,
    withProductBar && stickyStyles.withProductBar
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.icon}>
          <i className="fas fa-hexagon" />
        </span>
        <h2>Statements and filters</h2>
      </div>

      <div ref={nodeRef} className={stickyStyles.container}>
        <div {...styleProps} className={stickyClassNames}>
          {!transactor && (
            <div className={styles.placeholder}>
              <img src="/images/group.svg" alt="No transactor" />
              <p>
                Define what kind of list you would like to build before selecting statements and filters.
              </p>
            </div>
          )}

          {transactor && (
            <div id="statementsAndFiltersContent" className={styles.content}>
              <div className={styles.filter}>
                <h3>Statements</h3>
                <DroppableItem
                  type="droppableStatements"
                  index="statements"
                  options={statements}
                />
              </div>

              <div className={styles.filter}>
                <h3>Filters</h3>
                <Accordion renderTitle={AccordionTitle} className={styles.accordion} multi>
                  {map(filters, ({ id, name, tooltip, options, isLoading }, index) => (
                    <AccordionItem key={id} keyName={index} title={name} tooltip={tooltip} isOpen>
                      {isLoading
                        ? <DroppableItemPlaceholder />
                        : (
                          <DroppableItem
                            type="droppableFilters"
                            options={options}
                            index={index}
                          />
                        )}
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

StatementsAndFilters.propTypes = {
  transactor: PropTypes.string,
};

export default withSmartSearch(StatementsAndFilters);
