import React from 'react';
import PropTypes from 'prop-types';

import Textarea from 'components/core/Textarea';
import Select from 'components/core/Select';
import Label from 'components/core/Label';
import Input from 'components/core/Input';
import { getError } from 'utils/forms';
import { suggestionShape } from 'utils/shapes/suggestion';

import withCustomField from '../../utils/withCustomField';
import { hasOptions } from '../../utils';
import {
  TYPE_OPTIONS,
  TYPE_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
} from '../../utils/constants';
import { fieldTypeShape, errorsShape, touchedShape } from '../../utils/shapes';
import AvailableInField from '../AvailableInField';
import RequiredProperty from './components/RequiredProperty';
import styles from './styles.module.scss';

export { RequiredProperty };

function FieldsData({
  formState,
  fieldName,
  fieldType,
  productSlugs,
  fieldDescription,
  setFieldValue,
  handleChange,
  handleBlur,
  required,
}) {
  const commonProps = {
    className: styles.input,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  function initEmptyOptions(selectedOption) {
    /** Init two values options for checkbox/radio */
    if (hasOptions(selectedOption && selectedOption.value)) {
      const emptyValue = { value: '' };
      setFieldValue('options', [{ ...emptyValue }, { ...emptyValue }]);
    }
  }

  function handleTypeFieldChange(selectedOption) {
    initEmptyOptions(selectedOption);
    setFieldValue('fieldType', selectedOption);
  }

  function handleRequiredFieldChange({ target }) {
    setFieldValue('required', target.value === 'true');
  }

  return (
    <>
      <div className={styles.fieldsContainer}>
        <div className={styles.formGroup}>
          <Label htmlFor="fieldName" theme="light">Label</Label>
          <Input
            {...commonProps}
            id="fieldName"
            name="fieldName"
            value={fieldName}
            errorMessage={getError('fieldName', formState)}
          />
        </div>

        <div className={styles.formGroup}>
          <Label theme="light">Custom Field Type</Label>
          <Select
            value={fieldType.value}
            id="fieldType"
            name="fieldType"
            optionKey="value"
            options={TYPE_OPTIONS}
            onChange={handleTypeFieldChange}
            placeholder={TYPE_PLACEHOLDER}
            className={styles.input}
            errorMessage={getError('fieldType.value', formState)}
            hasNewStyle
            clearable={false}
            searchable={false}
          />
        </div>

        <div className={styles.formGroup}>
          <RequiredProperty required={required} onChange={handleRequiredFieldChange} />
        </div>
      </div>

      <AvailableInField
        setFieldValue={setFieldValue}
        productSlugs={productSlugs}
        formState={formState}
      />

      <div className={styles.formGroup}>
        <Label htmlFor="fieldDescription" theme="light">Description</Label>
        <Textarea
          {...commonProps}
          id="fieldDescription"
          name="fieldDescription"
          value={fieldDescription}
          placeholder={DESCRIPTION_PLACEHOLDER}
          errorMessage={getError('fieldDescription', formState)}
        />
      </div>
    </>
  );
}

FieldsData.propTypes = {
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: touchedShape,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldType: fieldTypeShape,
  productSlugs: PropTypes.arrayOf(suggestionShape),
  fieldDescription: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export default withCustomField(FieldsData);
