import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from '../../styles.module.scss';

export default function Header({ isOpen, toggle, tab, onTabClick }) {
  function handleTabClick() {
    onTabClick(tab);
    toggle();
  }

  return (
    <header className={styles.header} onClick={handleTabClick} role="button">
      <span className={styles.title}>{tab.props.title}</span>
      <Button theme="link" className={styles.link}>
        {isOpen ? 'See less' : 'See more'}
      </Button>
    </header>
  );
}

Header.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired,
  onTabClick: PropTypes.func.isRequired,
};
