import React from 'react';
import PropTypes from 'prop-types';

import DataCard from 'components/core/Profile/components/DataCard';

import BillColumn from './components/TableView/components/BillColumn';
import VoteNumberColumn from './components/TableView/components/VoteNumberColumn';
import TableView from './components/TableView';

function VotingRecord({
  title,
  description,
  emptyDescription,
  onSearch,
  onClear,
  sort,
  isMobile,
  isLoading,
  allItems,
  paginator,
  searchFormComponent,
  areFiltersApplied,
  trackingActions,
  formatBillUrl,
  ...restProps
}) {
  return (
    <DataCard title={title} description={description}>
      <div>{searchFormComponent}</div>
      <TableView
        {...restProps}
        sort={sort}
        allItems={allItems}
        isLoading={isLoading}
        isMobile={isMobile}
        paginator={paginator}
        onSearch={onSearch}
        onClearAll={onClear}
        trackingActions={trackingActions}
        formatBillUrl={formatBillUrl}
        emptyDescription={emptyDescription}
        areFiltersApplied={areFiltersApplied}
      />
    </DataCard>
  );
}

VotingRecord.defaultProps = {
  trackingActions: {},
};

VotingRecord.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  emptyDescription: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  areFiltersApplied: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  allItems: PropTypes.array.isRequired,
  searchFormComponent: PropTypes.node.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  formatBillUrl: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  paginator: PropTypes.object.isRequired,
  trackingActions: PropTypes.object,
};

export { BillColumn, VoteNumberColumn };
export default VotingRecord;
