import PropTypes from 'prop-types';

export const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

export const mailInfoShape = PropTypes.shape({
  subject: PropTypes.string,
  body: PropTypes.string,
});
