import React from 'react';
import PropTypes from 'prop-types';

import MobileView from './components/MobileView';
import DesktopView from './components/DesktopView';

export default function HelpButton({ isMobile, ...restProps }) {
  return isMobile ? <MobileView {...restProps} /> : <DesktopView {...restProps} />;
}

HelpButton.propTypes = {
  isMobile: PropTypes.bool,
};
