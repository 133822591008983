import React, { memo } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';

import { DOCUMENT_TYPES, POSITION_BUTTON_RECORD_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { notifySetPosition } from 'components/Dashboard/common/UserNotifications';
import { positions } from 'components/core/ButtonPosition/utils/constants';
import PositionButton from 'components/core/ButtonPosition';
import { Column } from 'components/core/Table';

import styles from './styles.module.scss';

function PositionItem({ keyName, item, isLoading }) {
  const { id, recordId, documentType, itemType, position } = item;
  const withPosition = includes([
    POSITION_BUTTON_RECORD_TYPES[DOCUMENT_TYPES.FEDERAL_BILL],
    POSITION_BUTTON_RECORD_TYPES[DOCUMENT_TYPES.STATE_BILL],
    POSITION_BUTTON_RECORD_TYPES[DOCUMENT_TYPES.FEDERAL_REGISTER_REGULATION],
    POSITION_BUTTON_RECORD_TYPES[DOCUMENT_TYPES.STATE_REGULATION],
  ], documentType);

  const handleSetPosition = newPosition => notifySetPosition({
    positionLabel: positions[newPosition].label,
    itemType,
  });

  const renderPosition = () => withPosition && (
    <PositionButton
      withTooltip
      isLoadingPosition={isLoading}
      size="small"
      initialPosition={position}
      position={position}
      recordId={recordId || id}
      recordType={documentType}
      className={styles.position}
      containerClassName={styles.position}
      placeholderClassName={styles.position}
      handleSetPosition={handleSetPosition}
    />
  );

  return (
    <Column key={keyName} keyName={keyName} className={styles.container}>{renderPosition}</Column>
  );
}

PositionItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  item: PropTypes.object,
};

export default memo(PositionItem);
