import React from 'react';
import PropTypes from 'prop-types';
import { head, isEmpty } from 'lodash';

import Table from 'components/core/Profile/components/Table';
import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';
import { getAddressTable, getPhoneTable } from 'components/core/Profile/components/Cards/Positions/utils';

import styles from './styles.module.scss';

function Tables({ isLoading, person }) {
  const { positions } = person || {};

  if (isEmpty(positions)) return null;

  const { address = {}, phones = [] } = head(positions) || {};

  const addressTable = getAddressTable(address);
  const phoneTable = getPhoneTable(phones);

  return (
    <div className={styles.tables}>
      <Table
        table={addressTable}
        isLoading={isLoading}
        className={styles.table}
      />
      <Table
        table={phoneTable}
        isLoading={isLoading}
        className={styles.table}
      />
    </div>
  );
}

Tables.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  person: personShape.isRequired,
};

export default withProfile(Tables);
