import { LIGHT_THEME, DARK_THEME, BLUE_THEME } from 'components/core/Loader/constants';
import styles from './styles.module.scss';

type LoaderTheme = keyof typeof loaderThemes;

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export const buttonThemes = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  light: styles.light,
  warning: styles.warning,
  danger: styles.danger,
  link: styles.link,
  tableLink: styles.tableLink,
  default: styles.default,
  none: styles.none,
  violet: styles.violet,
};

export const buttonSizes = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
  none: styles.none,
  badge: styles.badge,
};

export const loaderThemes = {
  primary: LIGHT_THEME,
  secondary: BLUE_THEME,
  tertiary: LIGHT_THEME,
  light: DARK_THEME,
  warning: LIGHT_THEME,
  danger: LIGHT_THEME,
  link: BLUE_THEME,
  tableLink: BLUE_THEME,
  default: DARK_THEME,
  none: LIGHT_THEME,
  violet: LIGHT_THEME,
};

export interface ButtonProps {
  children: React.ReactNode;
  isAddIcon?: boolean;
  isRemoveIcon?: boolean;
  className?: string;
  theme?: ButtonTheme;
  size?: ButtonSize;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
  type?: ButtonTypes;
  loaderTheme?: LoaderTheme;
  loaderSize?: string;
  loaderColor?: string;
  loaderBackgroundColor?: string;
  loaderOpacity?: number;
  loaderBorderRadius?: number;
  loaderBorderWidth?: number;
  loaderBorderColor?: string;
  loaderBorderStyle?: string;
  loaderBorderSpacing?: number;
  loaderBorderCollapse?: string;
  customIcon?: React.ReactNode;
  iconClassName?: string;
  isLoading?: boolean;
  loaderClassName?: string;
  innerRef?: React.Ref<HTMLButtonElement>;
}

type ButtonSize = keyof typeof buttonSizes;
type ButtonTheme = keyof typeof buttonThemes;
