import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Input from 'components/core/Input';
import Button from 'components/core/Button';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';
import { Disclaimer } from 'components/core/Modal';
import useModal from 'components/core/Modal/useModal';
import { QuestionMark } from 'components/core/Icons';

import styles from './styles.module.scss';

function SearchInput({
  isMobile,
  className,
  inputProps,
  filterByNode,
  withSearchDisclaimer,
  disclaimerProps,
}) {
  const { onClearSearch, ...restProps } = inputProps;
  const { isOpenModal, toggleModal } = useModal();
  const modalProps = { ...disclaimerProps, isOpenModal, toggleModal };
  const withDisclaimer = withSearchDisclaimer && !isEmpty(disclaimerProps);

  return (
    <div className={styles.searchContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.inputSearch}>
          {!isMobile && (
            <div className={styles.searchIcon}>
              <i className="far fa-search" />
            </div>
          )}

          <Input
            {...restProps}
            className={classNames(
              styles.input,
              'advanced-search__input',
              withDisclaimer && styles.withDisclaimer,
              className
            )}
          />

          {withDisclaimer && (
            <>
              <Button
                theme="light"
                size="none"
                customIcon={<QuestionMark className={styles.icon} />}
                className={styles.disclaimer}
                iconClassName={styles.icon}
                onClick={toggleModal}
              />
              <Disclaimer {...modalProps} />
            </>
          )}

          {inputProps.value && (
            <Button
              theme="default"
              className={classNames(styles.clearBtn, withDisclaimer && styles.withDisclaimer)}
              onClick={onClearSearch}
            >
              <i className="fas fa-times-circle" />
            </Button>
          )}
        </div>

        {filterByNode}

        {!isMobile && (
          <Button
            theme="light"
            className={styles.advancedSearchBtn}
            customIcon={<i className="fas fa-filter" />}
            type="submit"
          >
            Hide Advanced Search
          </Button>
        )}
      </div>
      <hr />
    </div>
  );
}

SearchInput.defaultProps = {
  withSearchDisclaimer: false,
  disclaimerProps: {},
};

SearchInput.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inputProps: PropTypes.shape({
    onClearSearch: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  }).isRequired,
  filterByNode: PropTypes.node,
  disclaimerProps: PropTypes.object,
  withSearchDisclaimer: PropTypes.bool,
};

export default withSearch(SearchInput);
