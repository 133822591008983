import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/core/Input';
import { BaseOption } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

const TEXT_INCLUDES_KEY = 'textIncludes';

function TextIncludesField({ value, onChange, titles }) {
  function handleChange(event) {
    const { value: eventValue } = event.target;

    onChange(TEXT_INCLUDES_KEY, eventValue);
  }

  const customComponent = (
    <Input
      value={value}
      onChange={handleChange}
      className={styles.textIncludesInput}
    />
  );

  return <BaseOption {...titles.textIncludes} customComponent={customComponent} />;
}

TextIncludesField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  titles: PropTypes.object,
};

export default TextIncludesField;
