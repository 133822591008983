import {
  transformSearches,
  transformPagination,
} from './transformers';

const BASE_URL = '/api_web/advanced_search/searches';

const create = ({ slug }) => (params = {}, name = null, saved = false) => {
  const data = {
    advanced_search: {
      name,
      saved,
      strategy_slug: slug,
      search_params: params,
    },
  };

  const ajax = $.ajax({
    url: BASE_URL,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(data),
  });

  return Promise.resolve(ajax);
};

const save = () => ({ id, name }) => {
  const data = {
    advanced_search: {
      saved: true,
      name,
    },
  };

  const url = `${BASE_URL}/${id}`;

  const ajax = $.ajax({
    url,
    data,
    type: 'PUT',
  });

  return Promise.resolve(ajax);
};

const remove = () => ({ id }) => {
  const url = `${BASE_URL}/${id}`;

  const ajax = $.ajax({
    url,
    type: 'DELETE',
  });

  return Promise.resolve(ajax);
};

const get = () => (id) => {
  const url = `${BASE_URL}/${id}`;

  const ajax = $.ajax({
    url,
    type: 'GET',
  });

  return Promise.resolve(ajax);
};

function load(params, transformer) {
  const {
    slug,
    saved = false,
    page = 1,
    perPage = 10,
  } = params;

  const data = {
    strategy_slug: slug,
    per_page: perPage,
    saved,
    page,
  };

  const ajax = $.get(BASE_URL, data);

  return Promise.resolve(ajax)
    .then(response => ({
      searches: transformSearches(response.data, transformer),
      pagination: transformPagination(response.pagination),
    }));
}

const loadSavedSearches = ({ slug, transformer }) => params => (
  load({
    ...params,
    slug,
    saved: true,
  }, transformer)
);

const loadRecentSearches = ({ slug, transformer }) => params => (
  load({
    ...params,
    slug,
    saved: false,
  }, transformer)
);

export default ({ slug, transformer }) => ({
  loadSavedSearches: loadSavedSearches({ slug, transformer }),
  loadRecentSearches: loadRecentSearches({ slug, transformer }),
  create: create({ slug }),
  save: save({ slug }),
  remove: remove({ slug }),
  get: get({ slug }),
});
