import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Column } from 'components/core/Table';
import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';
import { STAFFER_TYPES } from 'components/core/Profile/components/Cards/Staffers/utils/constants';
import { stafferShape } from 'components/core/Profile/components/Cards/Staffers/utils/shapes';

import styles from './styles.module.scss';

function StafferColumn({ stafferType, keyName, item, onClick }) {
  const { id, name } = item || {};

  function getUrl() {
    if (!id) return null;

    const { [stafferType]: url } = {
      [STAFFER_TYPES.FEDERAL_STAFFER]: '/federal/congressional_staffers',
      [STAFFER_TYPES.STATE_STAFFER]: '/state/staffers',
      [STAFFER_TYPES.STATE_AGENCY_STAFFER]: '/state/agency_staffers',
    };

    return url ? `${url}/${id}` : null;
  }

  const handleClick = () => onClick && onClick(item);

  function renderTitle() {
    if (isEmpty(name)) {
      return EMPTY_VALUE;
    }

    return (
      <Link
        className={styles.stafferColumn}
        theme="default"
        href={getUrl()}
        onClick={handleClick}
      >
        {name}
      </Link>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderTitle}</Column>
  );
}

StafferColumn.defaultProps = {
  item: {},
};

StafferColumn.propTypes = {
  stafferType: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  item: stafferShape,
  onClick: PropTypes.func,
};

export default StafferColumn;
