import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/core/Label';
import Textarea from 'components/core/Textarea';
import Asterisk from 'components/core/Asterisk';

import customFieldShape from '../../shapes/customField';
import controlStateShape from '../../shapes/controlState';
import normalizeControlValue from '../../utils/normalizeControlValue';
import normalizeEventTarget from '../../utils/normalizeEventTarget';
import Tooltip from '../../components/Tooltip';

export default function TextareaControl({ field, state, onChange, tooltipTheme, ...restProps }) {
  const { token, description, name, required } = field;
  const { value, error } = state;
  const controlValue = normalizeControlValue(value);

  function handleChange(event) {
    const { target } = event;
    const newValue = [target.value];
    const targetOptions = { value: newValue, id: token };
    const normalizedEvent = normalizeEventTarget(event, targetOptions);

    onChange(normalizedEvent);
  }

  return (
    <>
      <Label htmlFor={token}>
        {name} <Asterisk show={required} />
        <Tooltip content={description} theme={tooltipTheme} />
      </Label>
      <Textarea
        {...restProps}
        value={controlValue}
        placeholder=""
        errorMessage={error}
        onChange={handleChange}
      />
    </>
  );
}

TextareaControl.propTypes = {
  field: customFieldShape.isRequired,
  state: controlStateShape,
  onChange: PropTypes.func.isRequired,
  tooltipTheme: PropTypes.string,
};
