export const STATE_BILLS = {
  value: 'state_legislation_bill',
  label: 'State bills',
};

export const STATE_REGULATIONS = {
  value: 'state_legislation_regulation',
  label: 'State regulation',
};

export const FEDERAL_REGULATIONS = {
  value: 'federal_register_regulation',
  label: 'Federal regulation',
};

export const STATE_BILL = {
  value: 'state_bill',
  label: 'State bill',
};

export const FEDERAL_BILL = {
  value: 'bill_keyword',
  label: 'Federal bill',
};

export const BILL = {
  value: 'bill',
  label: 'Bill',
};

export const NEWS = {
  value: 'news',
  label: 'News',
};

export const DEFAULT = {
  value: 'default',
  label: 'Default',
};

export const CONTINUOUS = {
  value: 'continuous',
  label: 'CONTINUOUS',
  labelSentenceCase: 'Continuous',
};

export const DAILY = {
  value: 'daily',
  label: 'DAILY',
  labelSentenceCase: 'Daily',
};

export const NONE = {
  value: 'none',
  label: 'NONE',
  labelSentenceCase: 'None',
};

export const DESCRIPTIONS = {
  [STATE_BILLS.value]: {
    [NONE.value]: 'Selecting "None" will not send you any notifications.',
    [DAILY.value]: 'Selecting "Daily" will send you notifications of state bills in a single daily report.',
    [CONTINUOUS.value]: 'Selecting "Continuous" will send you notifications of state bills as they come in.',
  },
  [STATE_REGULATIONS.value]: {
    [NONE.value]: 'Selecting "None" will not send you any notifications.',
    [DAILY.value]: 'Selecting "Daily" will send you notifications of state regulations in a single daily report.',
    [CONTINUOUS.value]: 'Selecting "Continuous" will send you notifications of state regulations as they come in.',
  },
  [BILL.value]: {
    [NONE.value]: 'Selecting "None" will not send you any notifications.',
    [DAILY.value]: 'Selecting "Daily" will send you notifications of federal bills in a single daily report.',
    [CONTINUOUS.value]: 'Selecting "Continuous" will send you notifications of federal bills as they come in.',
  },
  [NEWS.value]: {
    [NONE.value]: '',
    [DAILY.value]: '',
    [CONTINUOUS.value]: '',
  },
  [DEFAULT.value]: {
    [NONE.value]: 'Selecting "None" will not send you any notifications.',
    [DAILY.value]: 'Selecting "Daily" will send you notifications in a single daily report.',
    [CONTINUOUS.value]: 'Selecting "Continuous" will send you notifications as they come in.',
  },
};

export const FREQUENCY_OPTIONS = {
  [STATE_BILLS.value]: [
    CONTINUOUS,
    DAILY,
    NONE,
  ],
  [STATE_REGULATIONS.value]: [
    CONTINUOUS,
    DAILY,
    NONE,
  ],
  [BILL.value]: [
    CONTINUOUS,
    DAILY,
    NONE,
  ],
  [NEWS.value]: [
    NONE,
    DAILY,
    CONTINUOUS,
  ],
  [DEFAULT.value]: [
    CONTINUOUS,
    DAILY,
    NONE,
  ],
};
