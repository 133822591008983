import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import StatementFiltersAdd from './StatementFiltersAdd';
import StatementFilter from './StatementFilter';
import GroupOperator from './GroupOperator';
import { getStatementFiltersStyle } from '../../Definitions/theme';
import styles from '../styles.module.scss';

function StatementFilterChecker({
  keyPrefix,
  isLastOperator,
  isDraggingOver,
  isDraggingFilter,
  draggableIndex,
  parentOperator,
  parentId,
  filter,
  deleteFilter,
}) {
  const { id, type, operator, filters } = filter;

  if (type === 'filters_group') {
    const isGroupOperatorDisplayed = !isLastOperator && !isDraggingOver;

    return (
      <Fragment>
        <Droppable droppableId={`droppableFiltersReorder:${id}`} type={id}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getStatementFiltersStyle(droppableSnapshot.isDraggingOver)}
            >
              <div className={styles.statementFiltersGroup}>
                {filters.map((filterItem, index) => (
                  <StatementFilterChecker
                    key={`${keyPrefix}-${index}`}
                    keyPrefix={`${keyPrefix}-${index}`}
                    isLastOperator={filters.length - 1 === index}
                    isDraggingOver={droppableSnapshot.isDraggingOver}
                    isDraggingFilter={isDraggingFilter}
                    draggableIndex={index}
                    parentOperator={operator}
                    parentId={id}
                    filter={filterItem}
                    deleteFilter={deleteFilter}
                  />
                ))}

                <StatementFiltersAdd
                  isNewGroupPartHidden
                  isDraggingFilter={isDraggingFilter}
                  currentId={id}
                  parentId={parentId}
                  filters={filters}
                />

                {droppableProvided.placeholder}
              </div>
            </div>
          )}
        </Droppable>

        {isGroupOperatorDisplayed && (
          <div className={styles.statementFiltersGroupOperator}>
            <GroupOperator type="group" id={parentId} operator={parentOperator} />
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <StatementFilter
      key={`${keyPrefix}-${id}`}
      isDraggingOver={isDraggingOver}
      isLastOperator={isLastOperator}
      draggableIndex={draggableIndex}
      parentOperator={parentOperator}
      parentId={parentId}
      filter={filter}
      deleteFilter={deleteFilter}
    />
  );
}

StatementFilterChecker.propTypes = {
  keyPrefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isLastOperator: PropTypes.bool.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  isDraggingFilter: PropTypes.bool.isRequired,
  draggableIndex: PropTypes.number.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  deleteFilter: PropTypes.func.isRequired,
};

export default StatementFilterChecker;
