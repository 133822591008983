import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/core/Card';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import Loader from 'components/core/Loader';
import { CustomFieldsContext } from 'components/core/CustomFields/utils/withCustomField';
import { isEmptyFieldType } from 'components/core/CustomFields/utils';
import { paginatorShape } from 'components/core/Paginator/shapes';

import {
  uiShape,
  allCustomFieldsItemShape,
  customFieldShape,
  valuesShape,
  errorsShape,
  touchedShape,
} from '../../utils/shapes';
import TableView from '../TableView';
import FieldsData from '../FieldsData';
import FieldsOptions from '../FieldsOptions';
import PreviewFields from '../PreviewFields';
import PreviewContent from '../PreviewFields/components/PreviewContent';
import styles from './styles.module.scss';

function Form({
  ui,
  allCustomFields,
  customField,
  fetchCustomFields,
  getCustomFieldByToken,
  createCustomField,
  updateCustomField,
  toggleCustomFieldStatus,
  values,
  touched,
  errors,
  setFieldValue,
  handleReset,
  handleChange,
  handleSubmit,
  handleBlur,
  paginator,
  updatePosition,
}) {
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const { isSavingCustomField } = ui;

  function togglePreviewModal() {
    setIsPreviewModal(!isPreviewModal);
  }

  const loader = <Loader size="small" theme="light" className={styles.loadingBtn} />;
  const actions = (
    <>
      <Button
        type="reset"
        theme="light"
        size="small"
        onClick={handleReset}
        className={styles.actionBtn}
        disabled={isSavingCustomField}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        size="small"
        className={styles.actionBtn}
        disabled={isSavingCustomField}
      >
        {isSavingCustomField ? loader : 'Create'}
      </Button>
    </>
  );

  const previewBtn = (!isEmptyFieldType(values.fieldType) && (
    <Button
      type="button"
      size="small"
      theme="secondary"
      className={styles.previewBtn}
      onClick={togglePreviewModal}
    >
      Preview
    </Button>
  ));

  const fieldsContext = {
    ui,
    allCustomFields,
    customField,
    fetchCustomFields,
    getCustomFieldByToken,
    createCustomField,
    updateCustomField,
    toggleCustomFieldStatus,
    setFieldValue,
    handleChange,
    handleBlur,
    handleReset,
    formState: { errors, touched },
    paginator,
    updatePosition,
    ...values,
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <Card
          title="New Custom Fields"
          headerNodes={previewBtn}
          footerNodes={actions}
        >
          <CustomFieldsContext.Provider value={fieldsContext}>
            <div className={styles.layout}>
              <div className={styles.form}>
                <FieldsData />
                {!isEmptyFieldType(values.fieldType) && <hr />}
                <FieldsOptions />
              </div>

              <PreviewFields />
            </div>

            <Modal
              title="Preview"
              isOpen={isPreviewModal}
              toggleOpen={togglePreviewModal}
            >
              <PreviewContent />
            </Modal>
          </CustomFieldsContext.Provider>
        </Card>
      </form>

      <CustomFieldsContext.Provider value={fieldsContext}>
        <TableView />
      </CustomFieldsContext.Provider>
    </div>
  );
}

Form.propTypes = {
  ui: uiShape.isRequired,
  allCustomFields: PropTypes.arrayOf(allCustomFieldsItemShape),
  customField: customFieldShape,
  fetchCustomFields: PropTypes.func.isRequired,
  getCustomFieldByToken: PropTypes.func.isRequired,
  createCustomField: PropTypes.func.isRequired,
  updateCustomField: PropTypes.func.isRequired,
  toggleCustomFieldStatus: PropTypes.func.isRequired,
  values: valuesShape,
  errors: errorsShape,
  touched: touchedShape,
  setFieldValue: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paginator: paginatorShape,
  updatePosition: PropTypes.func.isRequired,
};

export default Form;
