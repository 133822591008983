import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/core/Input';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';

import styles from './styles.module.scss';

function InputField({ value, label, onChange, isRequired }) {
  return (
    <>
      <Label
        theme="light"
        className={styles.label}
      >
        {label}
        <Asterisk show={isRequired} />
      </Label>
      <Input
        value={value}
        onChange={onChange}
        className={styles.input}
      />
    </>
  );
}

InputField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default InputField;
