import PropTypes from 'prop-types';

import { frequencyType } from 'components/core/FrequencyOptions/shapes';

export const locationShape = PropTypes.shape({
  country: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
});

export const sourceShape = PropTypes.shape({
  domain: PropTypes.string,
  id: PropTypes.number,
  locations: PropTypes.arrayOf(locationShape),
  logoUrl: PropTypes.string,
  name: PropTypes.string,
});

export const mentionShape = PropTypes.shape({
  text: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.shape({}),
});

export const subscriptionShape = PropTypes.shape({
  frequency: frequencyType,
  token: PropTypes.string,
});

export const selectedFeedShape = PropTypes.shape({
  id: PropTypes.number,
  isPersonal: PropTypes.bool,
  name: PropTypes.string,
  subscription: subscriptionShape,
});

export const publishedAtShape = PropTypes.shape({
  raw: PropTypes.string,
  full: PropTypes.string,
  short: PropTypes.string,
});

export const sentimentShape = PropTypes.shape({
  percentage: PropTypes.string,
  type: PropTypes.string,
});
