import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function Header({ title, onBackClick, onCloseClick }) {
  const emptyButton = <div />;

  const backButton = (
    <span className={styles.back} onClick={onBackClick} onKeyPress={onBackClick}>
      <i className="fal fa-angle-left" /> Back
    </span>
  );

  const closeButton = (
    <span className={styles.close} onClick={onCloseClick} onKeyPress={onCloseClick}>
      <i className="fal fa-times" />
    </span>
  );

  return (
    <div className={styles.header}>
      {onBackClick ? backButton : emptyButton}

      <span className={styles.title}>
        {title}
      </span>

      {onCloseClick ? closeButton : emptyButton}
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
  onCloseClick: PropTypes.func,
};

export default Header;
