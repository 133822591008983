import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isObject } from 'lodash';

import { canRenderTopBar } from 'components/core/ProductBar/utils';
import Button from 'components/core/Button';
import useModal from 'components/core/Modal/useModal';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { dataShape } from './utils/shapes';
import MenuItem from './components/MenuItem';
import styles from './styles.module.scss';

function RightSideBar({ items, className, contentClassName, data }) {
  const [activeContent, setActiveContent] = useState({});
  const {
    isOpenModal: isOpenMenu,
    closeModal: closeInitialMenu,
    openModal: openMenu,
  } = useModal();
  const { isMobile, isDesktopLarge } = useMediaQuery();
  const withProductBar = canRenderTopBar(isMobile);
  const { isNavigationV2Enabled } = gon;
  const containerClassNames = classNames(
    styles.container,
    className,
    isOpenMenu && styles.menuActive,
    withProductBar && styles.withProductBar,
  );

  const sidebarClassnames = classNames(
    styles.sidebar,
    (withProductBar && isNavigationV2Enabled) && styles.withProductBarSidebar,
    (!isMobile && isNavigationV2Enabled) && styles.withNavigationV2Sidebar
  );

  const contentClassnames = classNames(
    styles.content,
    contentClassName,
    withProductBar && styles.withProductBarContent,
    (isDesktopLarge && isNavigationV2Enabled) && styles.withNavigationV2Content,
  );

  if (isEmpty(items)) {
    return null;
  }

  function closeMenu() {
    setActiveContent({});
    closeInitialMenu();
  }

  function handleClick(item) {
    setActiveContent(item);
    isEmpty(item) ? closeMenu() : openMenu();
  }

  function renderItem(item, index) {
    return (
      <li className={styles.listItem} key={`tab-item-${index}`}>
        <MenuItem
          item={item}
          onClick={handleClick}
          activeItem={activeContent}
        />
      </li>
    );
  }

  function renderContent() {
    const { content } = activeContent || {};

    if (isObject(content)) {
      return cloneElement(content, { ...data, closeMenu });
    }

    return content;
  }

  return (
    <div className={containerClassNames}>
      <ul className={sidebarClassnames}>
        {items.map(renderItem)}
      </ul>

      <div className={contentClassnames}>
        <Button
          theme="light"
          size="none"
          onClick={closeMenu}
          className={styles.closeButton}
        >
          <i className="fal fa-times" />
        </Button>
        {renderContent()}
      </div>
    </div>
  );
}

RightSideBar.propTypes = {
  data: dataShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      content: PropTypes.node,
    }),
  ),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default RightSideBar;
