import React from 'react';
import PropTypes from 'prop-types';

import TableView from 'components/Moneyball/common/TableView';
import Notifications from 'components/Moneyball/utils/Notifications';

import tableColumns from '../../utils/tableColumns';
import styles from './styles.module.scss';

function Table({
  history,
  ui,
  sort,
  paginator,
  receipt,
  receiptError,
  searchReceipt,
  receipts,
  receiptsError,
  searchReceipts,
  submitExport,
  isMobile,
  tableClassName,
  nrRowsPlaceholder,
  perPage,
  searchParams,
  searchProperties,
  appliedSearchFilters,
  customEmptyMessage,
}) {
  const { appliedFilters } = appliedSearchFilters || {};
  const {
    isInitialLoading,
    isLoadingData,
    isReceiptLoading,
    isLoadingReport,
    toResetSelectedItems,
  } = ui;

  const actionsMap = {
    receipt,
    receiptError,
    searchReceipt,
    isReceiptLoading,
    exportModalTitle: 'Receipt export',
    isLoadingReport,
    submitExport,
  };

  return (
    <div className={styles.tableContainer}>
      <TableView
        uniqueKey="id"
        data={receipts}
        tableClassName={tableClassName}
        nrRowsPlaceholder={nrRowsPlaceholder}
        sorting={sort}
        paginator={paginator}
        tableColumns={tableColumns}
        isInitialLoading={isInitialLoading}
        displayPaginatorPlaceholder={false}
        isLoading={isLoadingData}
        toResetSelectedItems={toResetSelectedItems}
        onFetchData={searchReceipts}
        onFailedNotification={Notifications.defaultFetchDataFailed}
        appliedFilters={appliedFilters}
        searchParams={searchParams}
        actionsMap={actionsMap}
        error={receiptsError}
        isMobile={isMobile}
        perPage={perPage}
        isCheckboxPresent={false}
        fullWidthTable={false}
        customSearchProperties={searchProperties}
        customEmptyMessage={customEmptyMessage}
        history={history}
      />
    </div>
  );
}

Table.defaultProps = {
  nrRowsPlaceholder: 10,
  customEmptyMessage: '',
};

Table.propTypes = {
  ui: PropTypes.object.isRequired,
  appliedSearchFilters: PropTypes.object.isRequired,
  receipt: PropTypes.object.isRequired,
  receiptError: PropTypes.bool.isRequired,
  receipts: PropTypes.array.isRequired,
  receiptsError: PropTypes.bool.isRequired,
  searchParams: PropTypes.object.isRequired,
  searchReceipt: PropTypes.func.isRequired,
  searchReceipts: PropTypes.func.isRequired,
  submitExport: PropTypes.func.isRequired,
  paginator: PropTypes.object.isRequired,
  sort: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  tableClassName: PropTypes.string,
  nrRowsPlaceholder: PropTypes.number,
  perPage: PropTypes.number,
  searchProperties: PropTypes.object,
  history: PropTypes.object.isRequired,
  customEmptyMessage: PropTypes.string,
};

export default Table;
