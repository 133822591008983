import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, omit } from 'lodash';

import { DISBURSEMENTS, RECEIPTS } from 'components/Moneyball/utils/constants';
import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import PDF from 'components/core/PDF';
import { queryStringify } from 'utils/query-string';

import ReceiptAbstract from './components/ReceiptAbstract';
import Error from './components/Error';
import getInfoNode from './utils';
import styles from './styles.module.scss';

function ReceiptModal({
  history,
  actionsMap,
  searchParams,
  receiptModalId,
  appliedFilters,
  handleReceiptModal,
}) {
  const { pathname } = history.location;
  const { searchType } = appliedFilters;
  const { receipt, receiptError, searchReceipt, isReceiptLoading } = actionsMap;
  const { spender, hasPdf, sourceType, ...restReceipt } = receipt;
  const infoNode = getInfoNode(searchType, restReceipt);
  const isDisbursements = searchType === DISBURSEMENTS;
  const linkSearchType = isDisbursements ? DISBURSEMENTS : RECEIPTS;
  const modalTitle = isDisbursements ? 'Disbursement from' : 'Receipt from';
  const link = `${gon.moneyballApiV2}/api/v1/${linkSearchType}/${receiptModalId}.pdf`;
  const [selectedOption, setSelectedOption] = useState('Receipt abstract');
  const isReceiptAbstract = selectedOption === 'Receipt abstract';
  const isRawPDF = selectedOption === 'Raw PDF';
  const modalProps = {
    isOpen: true,
    theme: 'scrollableBody',
    className: styles.modal,
    bodyClassName: styles.body,
    headerClassName: styles.header,
    toggleOpen: handleReceiptModal,
    title: !receiptError && !isReceiptLoading ? `${modalTitle} ‘${spender}’` : '',
  };

  useEffect(() => {
    if (receiptModalId) {
      const receiptId = !get(searchParams, 'receiptId') && { receiptId: receiptModalId };
      const withModalSearchParams = queryStringify(
        { ...searchParams, ...receiptId, ...{ searchType } },
        { arrayFormat: 'none' },
      );

      history.push(`${pathname}?${withModalSearchParams}`);
      searchReceipt({ id: receiptModalId, appliedFilters });
    }

    return () => {
      const withoutModalSearchParams = queryStringify(
        { ...omit(searchParams, ['receiptId', 'searchType']) },
        { arrayFormat: 'none' },
      );

      history.push(`${pathname}?${withoutModalSearchParams}`);
    };
  }, [receiptModalId]);

  function handleSelectedOption({ target }) {
    setSelectedOption(target.textContent);
  }

  const buttonProps = {
    onClick: handleSelectedOption,
    theme: 'default',
  };

  const receiptAbstractProps = {
    isReceiptLoading,
    infoNode,
  };

  const rawPdfProps = {
    containerClass: styles.container,
    isLoading: isReceiptLoading,
    sourceType,
    hasPdf,
    link,
  };

  const isLargeDownContent = (
    <Fragment key="isLargeDownContent">
      {isReceiptAbstract && <ReceiptAbstract {...receiptAbstractProps} />}
      {isRawPDF && <PDF {...rawPdfProps} />}
    </Fragment>
  );

  const isLargeUpContent = (
    <Fragment key="isLargeUpContent">
      <ReceiptAbstract {...receiptAbstractProps} />
      <PDF {...rawPdfProps} />
    </Fragment>
  );

  return (
    <Modal {...modalProps}>
      <div className={styles.switcher}>
        <ButtonGroup className={styles.btnGroup}>
          <Button className={classNames(isReceiptAbstract && styles.isActive)} {...buttonProps}>
            Receipt abstract
          </Button>
          <Button className={classNames(isRawPDF && styles.isActive)} {...buttonProps}>
            Raw PDF
          </Button>
        </ButtonGroup>
      </div>

      <div className={styles.isLargeDown}>
        {!receiptError ? [isLargeDownContent] : <Error />}
      </div>

      <div className={styles.isLargeUp}>
        {!receiptError ? [isLargeUpContent] : <Error />}
      </div>
    </Modal>
  );
}

ReceiptModal.propTypes = {
  history: PropTypes.object.isRequired,
  searchParams: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  handleReceiptModal: PropTypes.func.isRequired,
  actionsMap: PropTypes.object.isRequired,
  receiptModalId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ReceiptModal;
