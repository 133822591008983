import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import { isEmpty, capitalize, get, truncate, size } from 'lodash';

import Link from 'components/core/Link';
import { workspaceShape } from 'redux/events/utils/shapes';
import { subscriptionShape } from 'redux/subscriptions/utils/shapes';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { MOBILE_NAME_LIMIT, DESKTOP_NAME_LIMIT } from './utils/constants';
import { shouldShowTile, getTileUrl } from './utils';
import CommitteeIcon from './components/CommitteeIcon';
import styles from './styles.module.scss';

function WorkspaceTile({
  className,
  wrapperClassName,
  subscriptions,
  workspace,
  subscription,
}) {
  const isEmptySubscriptions = isEmpty(subscriptions);
  const { isMobile } = useMediaQuery();
  const nameLimit = isMobile ? MOBILE_NAME_LIMIT : DESKTOP_NAME_LIMIT;

  function renderIcon() {
    if (!isEmptySubscriptions) {
      return <CommitteeIcon subscriptions={subscriptions} />;
    }

    if (get(subscription, 'isTracking')) {
      return <i className={classNames('fas fa-check-circle', styles.icon)} />;
    }

    return null;
  }

  function handleClick(e) {
    e.stopPropagation();
  }

  const getName = () => {
    if (size(workspace.name) > nameLimit) {
      return (
        <Tooltip
          theme="dark"
          content={workspace.name}
          placement="bottom"
          arrow
        >
          <span>{capitalize(truncate(workspace.name, { length: nameLimit }))}</span>
        </Tooltip>
      );
    }

    return capitalize(workspace.name);
  };

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <Link
        className={classNames(styles.tile, className)}
        theme="default"
        size="none"
        href={getTileUrl(workspace.token)}
        onClick={handleClick}
      >
        {getName()}
        {renderIcon()}
      </Link>
    </div>
  );
}

WorkspaceTile.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  workspace: workspaceShape.isRequired,
  subscription: subscriptionShape,
  subscriptions: PropTypes.arrayOf(subscriptionShape),
};

export default WorkspaceTile;
export { CommitteeIcon };
export { shouldShowTile };
