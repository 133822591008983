import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TrimTextItem from 'components/core/Table/components/ColumnItem/TrimTextItem';
import { EMPTY_VALUE } from 'utils/constants';
import { Column } from 'components/core/Table';
import Link from 'components/core/Link';

import { LOCAL_TYPE_KEYS } from '../utils/constants';
import styles from '../styles.module.scss';

function TitleColumn({ keyName, item, customTextComponent }) {
  const { url, title, type } = item || {};

  function renderTitle() {
    if (isEmpty(title)) {
      return EMPTY_VALUE;
    }

    const textComponent = customTextComponent || <TrimTextItem textToTrim={title} maxTextLength={110} />;

    if (isEmpty(url)) {
      return textComponent;
    }

    const isLocal = LOCAL_TYPE_KEYS.includes(type);

    return (
      <Link
        theme="default"
        href={url}
        isExternal={!isLocal}
        className={styles.linkTable}
      >
        {textComponent}
      </Link>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderTitle}</Column>
  );
}

TitleColumn.defaultProps = {
  item: {},
};

TitleColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  customTextComponent: PropTypes.node,
  item: PropTypes.object,
};

export default TitleColumn;
