import isEmpty from 'lodash/isEmpty';

/*
  eg: geocode service api response:
  "address_components" : [
    {
       "long_name" : "1626",
       "short_name" : "1626",
       "types" : [ "street_number" ]
    },
    {
       "long_name" : "Park Lane",
       "short_name" : "Park Ln",
       "types" : [ "route" ]
    },
    {
       "long_name" : "Orefield",
       "short_name" : "Orefield",
       "types" : [ "neighborhood", "political" ]
    },
    {
       "long_name" : "Boston",
       "short_name" : "Boston",
       "types" : [ "locality", "political" ]
    },
    {
       "long_name" : "Upper Macungie Township",
       "short_name" : "Upper Macungie Township",
       "types" : [ "administrative_area_level_3", "political" ]
    },
    {
       "long_name" : "Lehigh County",
       "short_name" : "Lehigh County",
       "types" : [ "administrative_area_level_2", "political" ]
    },
    {
       "long_name" : "Pennsylvania",
       "short_name" : "PA",
       "types" : [ "administrative_area_level_1", "political" ]
    },
    {
       "long_name" : "United States",
       "short_name" : "US",
       "types" : [ "country", "political" ]
    },
    {
       "long_name" : "18069",
       "short_name" : "18069",
       "types" : [ "postal_code" ]
    },
    {
       "long_name" : "8926",
       "short_name" : "8926",
       "types" : [ "postal_code_suffix" ]
    }
  ]

  we match our address fields with 1 or more geocode types,
  because sometime one field is present but the other one is not!

  eg: city field matches: 'locality' and 'administrative_area_level_3',
  because one of them could be missing sometime,
  and so we pick first one we encounter following a priority order.
*/

const addressComponentsLookup = [
  {
    field: 'country',
    types: ['country'],
  },
  {
    field: 'state',
    types: ['administrative_area_level_1'],
  },
  {
    field: 'city',
    types: ['locality', 'administrative_area_level_3', 'sublocality', 'neighborhood'],
  },
  {
    field: 'street',
    types: ['route'],
  },
  {
    field: 'street_number',
    types: ['street_number'],
  },
  {
    field: 'zipcode',
    types: ['postal_code'],
  },
];

function getAddressFromParts(addressParts = {}) {
  const result = [];

  if (addressParts.street_number) result.push(addressParts.street_number);
  if (addressParts.street) result.push(addressParts.street);

  return result.join(', ');
}

const findAddressComponentByTypes = (addressComponents, types) => {
  for (const type of types) {
    for (const addressComponent of addressComponents) {
      if (addressComponent.types.includes(type)) {
        return addressComponent;
      }
    }
  }

  return null;
};

export function getAddressPartsFromGeoResponse(response) {
  const addressComponents = response[0].address_components;
  const addressParts = {};

  addressComponentsLookup.forEach(({ field, types }) => {
    const addressComponent = findAddressComponentByTypes(addressComponents, types);

    if (addressComponent) {
      addressParts[field] = addressComponent.long_name;
    }
  });

  return {
    ...addressParts,
    address_1: getAddressFromParts(addressParts),
  };
}

export function removeLastComma(text) {
  if (isEmpty(text) || isEmpty(text.match(/,/g))) {
    return text;
  }
  const lastIndexOfComma = text.lastIndexOf(',');

  return text.substring(0, lastIndexOfComma) + text.substring(lastIndexOfComma + 1);
}
