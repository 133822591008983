import React from 'react';
import { Provider as BusProvider } from 'react-bus';

import AddedDraggableItem from './components/AddedDraggableItem';
import AddedDraggableInputItem from './components/AddedDraggableItem/AddedDraggableInputItem';
import { getNameInDraggableItems } from './components/Definitions/utils';
import Container from './components/Container';

function SmartSearchBuilder(props) {
  return (
    <BusProvider>
      <Container {...props} />
    </BusProvider>
  );
}

export { AddedDraggableItem, AddedDraggableInputItem, getNameInDraggableItems };
export default SmartSearchBuilder;
