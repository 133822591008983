const compareLegislators = (a, b) => {
  if (a.rank === b.rank) {
    return a.name < b.name ? -1 : 1;
  }

  return a.rank - b.rank;
};

export const sortLegislators = legislators =>
  [...legislators].sort(compareLegislators);
