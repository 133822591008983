import React from 'react';
import map from 'lodash/map';

import Accordion, { Item } from 'components/core/Accordion';
import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';
import SectionTitle from 'components/core/Modal/components/ExportModal/components/SectionTitle';
import Label from 'components/core/Label';

import shapes from '../../shapes';
import styles from './styles.module.scss';

function ItemsSelection({ options, itemsSelectedManager }) {
  function renderTitle({ field, toggle, isOpen }) {
    return (
      <SectionTitle
        label={field}
        isOpen={isOpen}
        onToggle={toggle}
        titleClassName={styles.sectionTitle}
      />
    );
  }

  return (
    <>
      <Label className={styles.label} theme="light">
        Choose the item types you want to edit column dimensions for. Default export will include all columns.
      </Label>
      <Accordion renderTitle={renderTitle}>
        {map(options, ({ section, items, isOpen }) => (
          <Item
            key={section}
            field={section}
            isOpen={isOpen}
          >
            <LabelGroup>
              {items.map(item => (
                <Checkbox
                  key={item.value}
                  label={item.label}
                  isChecked={itemsSelectedManager.isChecked(item)}
                  onChange={() => itemsSelectedManager.onChange(item)}
                />
              ))}
            </LabelGroup>
          </Item>
        ))}
      </Accordion>
    </>
  );
}

ItemsSelection.propTypes = {
  options: shapes.options.isRequired,
  itemsSelectedManager: shapes.itemsSelectedManager.isRequired,
};

export default ItemsSelection;
