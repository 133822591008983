import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

function StafferTitle({
  value,
  onChange,
  title,
  placeholder,
  keyName,
  strategy,
  isFederal,
  ...restProps
}) {
  function getStrategy() {
    if (strategy) {
      return strategy;
    }

    return isFederal ? 'staffer_title' : 'state_staffer_position_title';
  }

  function handleChange(newValue) {
    onChange(keyName, newValue);
  }

  return (
    <BaseOption
      {...restProps}
      title={title}
      placeholder={placeholder}
      name={keyName}
      strategy={getStrategy()}
      defaultSelections={value}
      overrideValue={value}
      onChange={handleChange}
    />
  );
}

StafferTitle.defaultProps = {
  title: 'Title',
  questionText: 'Specific title of the staffer(s) (e.g., Legislative Director)',
};

StafferTitle.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  strategy: PropTypes.string,
  isFederal: PropTypes.bool,
};

export default StafferTitle;
