import trim from 'lodash/trim';

import { joinCompact, getParenthesisItem } from 'utils/string';

import { getDate } from '../../utils';

export const getEducationItemContent = ({ school, details, startDate, endDate, degree }) => {
  const date = getDate(startDate, endDate);
  const formattedDetails = getParenthesisItem(details);
  const firstPart = joinCompact([school, formattedDetails, date], ' ');

  return joinCompact([firstPart, trim(degree)], ' - ');
};
