import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import Title from 'components/core/Profile/components/Title';
import useCheckedItems from 'components/core/AdvancedSearch/utils/useCheckedItems';
import LabelGroup from 'components/core/LabelGroup';

import titles from '../../utils/titles';
import LabelItem from '../LabelItem';
import styles from '../styles.module.scss';
import DocumentSummary from './components/DocumentSummary';

function Customization({
  values,
  onChange,
  title,
  description,
  exportCustomizationItems,
  className,
  titleClassName,
  documentSummaryProps,
  enableTooltip,
}) {
  const checkedItemProps = useCheckedItems('customization', values, onChange);
  const titleClassNames = {
    className: enableTooltip ? styles.titleWithTooltip : styles.container,
    titleClassName: classNames(styles.title, titleClassName),
    descriptionClassName: styles.description,
  };

  return (
    <>
      <Title
        title={title}
        description={description}
        enableTooltip={enableTooltip}
        {...titleClassNames}
      />

      <LabelGroup className={className}>
        {map(exportCustomizationItems, item => (
          <LabelItem
            {...item}
            {...checkedItemProps}
            key={item.title}
          />
        ))}
      </LabelGroup>

      <DocumentSummary {...documentSummaryProps} />
    </>
  );
}

Customization.defaultProps = {
  title: titles.customization.title,
  description: titles.customization.description,
};

Customization.propTypes = {
  exportCustomizationItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  documentSummaryProps: PropTypes.shape({
    reportHasSummary: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
  }),
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  enableTooltip: PropTypes.bool,
};

export default Customization;
