import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import buttonStyles from 'components/core/Button/styles.module.scss';

import styles from './styles.module.scss';

const linkThemes = {
  primary: buttonStyles.primary,
  secondary: buttonStyles.secondary,
  tertiary: buttonStyles.tertiary,
  light: buttonStyles.light,
  warning: buttonStyles.warning,
  danger: buttonStyles.danger,
  link: buttonStyles.link,
  tableLink: buttonStyles.tableLink,
  buttonDefault: buttonStyles.default,
  default: styles.default,
  violet: buttonStyles.violet,
};

const buttonSizes = {
  badge: buttonStyles.badge,
  small: buttonStyles.small,
  medium: buttonStyles.medium,
  large: buttonStyles.large,
  none: buttonStyles.none,
};

const Link = ({
  theme,
  size,
  customIcon,
  rightIcon,
  children,
  className,
  href,
  isExternal,
  isRouterLink,
  displayExternalIcon,
  iconClassName,
  disabled,
  ...restProps
}) => {
  const customProps = isExternal && {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const linkContent = (
    <>
      {customIcon && (
        <span className={classNames(buttonStyles.icon, iconClassName)}>{customIcon}</span>
      )}

      {children}

      {isExternal && !rightIcon && displayExternalIcon && (
        <span className={classNames(styles.rightIcon, iconClassName)}>
          <i className="far fa-external-link" />
        </span>
      )}

      {rightIcon && (
        <span className={classNames(styles.rightIcon, iconClassName)}>{rightIcon}</span>
      )}
    </>
  );

  if (isRouterLink) {
    return (
      <RouterLink
        {...restProps}
        {...customProps}
        className={classNames(
          buttonStyles.button,
          styles.link,
          linkThemes[theme],
          buttonSizes[size],
          disabled && buttonStyles.isDisabled,
          className,
        )}
      >
        {linkContent}
      </RouterLink>
    );
  }

  return (
    <a
      {...restProps}
      {...customProps}
      href={href}
      className={classNames(
        buttonStyles.button,
        styles.link,
        linkThemes[theme],
        buttonSizes[size],
        disabled && buttonStyles.isDisabled,
        className,
      )}
    >
      {linkContent}
    </a>
  );
};

Link.defaultProps = {
  theme: 'primary',
  size: 'medium',
  isRouterLink: false,
};

Link.propTypes = {
  isExternal: PropTypes.bool,
  isRouterLink: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.oneOf(
    Object.keys(buttonSizes)
  ),
  theme: PropTypes.oneOf(
    Object.keys(linkThemes)
  ),
  customIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  iconClassName: PropTypes.string,
  displayExternalIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default Link;
