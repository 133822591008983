import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CoreButton from 'components/core/Button';
import { optionShape } from '../../shapes';

import styles from './styles.module.scss';

function Button({
  option,
  onClick,
  className,
  iconClassName,
}) {
  const { label, icon, color, value } = option;

  const iconClasses = classNames(
    styles.icon,
    styles[color],
    iconClassName,
  );

  const handleClick = () => onClick(value);

  return (
    <CoreButton
      size="small"
      theme="secondary"
      className={classNames(styles.button, className)}
      iconClassName={iconClasses}
      onClick={handleClick}
      customIcon={<i className={icon} />}
    >
      {label}
    </CoreButton>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  option: optionShape,
};

export default Button;
