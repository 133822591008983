import React from 'react';
import { map, isEmpty } from 'lodash';

import getLabel from 'components/core/Profile/components/Cards/WebAddresses/getLabel';
import { personShape } from 'components/core/Profile/utils/shapes';
import { formatUrl } from 'components/core/Profile/components/Table/components/Column/Link/utils';

import TableSection from '../../TableSection';

function WebAddresses({ person }) {
  const { urls } = person || {};

  if (isEmpty(urls)) {
    return null;
  }

  const tables = [
    map(urls, ({ url, type }) => ({
      label: getLabel({ type }),
      value: url,
      href: formatUrl({ url, type }),
    })),
  ];

  return (
    <TableSection
      title="Web addresses"
      tables={tables}
    />
  );
}

WebAddresses.propTypes = {
  person: personShape,
};

export default WebAddresses;
