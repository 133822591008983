import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const inputThemes = {
  light: styles.light,
  medium: styles.medium,
  strong: styles.strong,
};

const inputPositions = {
  block: styles.block,
  inline: styles.inline,
};

function Label({ htmlFor, children, position, theme, className }) {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        styles.label,
        inputPositions[position],
        inputThemes[theme],
        className,
      )}
    >
      {children}
    </label>
  );
}

Label.defaultProps = {
  position: 'block',
  theme: 'medium',
};

Label.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOf(
    Object.keys(inputPositions),
  ),
  theme: PropTypes.oneOf(
    Object.keys(inputThemes),
  ),
};

export default Label;
