import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

function Default({ item, keyPath, getContent, className }) {
  const content = getContent
    ? getContent(item)
    : get(item, keyPath);

  if (isEmpty(content)) {
    return null;
  }

  return (
    <span className={className}>
      {content}
    </span>
  );
}

Default.propTypes = {
  item: PropTypes.shape({}),
  keyPath: PropTypes.string,
  getContent: PropTypes.func,
  className: PropTypes.string,
};

export default Default;
