import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { compact, map, isEmpty } from 'lodash';

import Table from './components/Table';
import { rowShape } from './utils/shapes';
import styles from './styles';

export default function TableSection({ title, description, tables }) {
  return (
    <View wrap={false} style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {title}
        </Text>
        <Text style={styles.description}>
          {description}
        </Text>
      </View>
      {compact(map(tables, rows => (!isEmpty(rows) && <Table key={rows[0].label} rows={rows} />)))}
    </View>
  );
}

TableSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tables: PropTypes.arrayOf(
    PropTypes.arrayOf(rowShape),
  ),
};
