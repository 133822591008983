import React from 'react';
import PropTypes from 'prop-types';

import SearchBox from 'components/core/SearchBox';
import { BaseOption } from 'components/core/AdvancedSearch';
import { defaultDisclaimerProps } from 'components/core/Profile/utils/constants';

import withNews from '../utils/withNews';
import RightItems from '../RightItems';
import styles from './styles.module.scss';

function Header({
  rightItemsProps,
  keywordSearch,
  onChangeKeywordSearch,
  onSearch,
  onClearSearch,
}) {
  const searchComponent = (
    <SearchBox
      withButton
      value={keywordSearch}
      onChange={onChangeKeywordSearch}
      onSubmit={onSearch}
      withSearchDisclaimer
      disclaimerProps={defaultDisclaimerProps}
      onClearSearch={onClearSearch}
      isClearable
    />
  );

  return (
    <div className={styles.headerActions}>
      <BaseOption
        title="Keyword search"
        customComponent={searchComponent}
        className={styles.searchContainer}
      />

      <RightItems {...rightItemsProps} />
    </div>
  );
}

Header.propTypes = {
  rightItemsProps: PropTypes.object,
  keywordSearch: PropTypes.string.isRequired,
  onChangeKeywordSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
};

export default withNews(Header);
