import React from 'react';
import PropTypes from 'prop-types';

import withProfile from 'components/core/Profile/utils/withProfile';
import CallToActions from 'components/core/Profile/components/CallToActions';
import { redirectToGlobalSearch } from 'components/GlobalAdvancedSearch/utils';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';

import styles from './styles.module.scss';

function Actions({ isLoadingTags, tags }) {
  return (
    <CallToActions
      isLoadingTags={isLoadingTags}
      tags={tags}
      onTagClick={redirectToGlobalSearch}
      containerClassName={styles.tracking}
      className={styles.tags}
    />
  );
}

Actions.propTypes = {
  isLoadingTags: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
};

export default withProfile(Actions);
