import { joinCompact, getParenthesisItem } from 'utils/string';

import { getDate } from '../../utils';

export const getOrganizationContent = ({
  title,
  organization,
  type,
  details,
  startYear,
  endYear,
}) => {
  const date = getDate(startYear, endYear);
  const formattedType = getParenthesisItem(type);
  const secondPart = joinCompact([organization, formattedType, details, date], ' ');

  return joinCompact([title, secondPart]);
};
