import { useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';

import moment from 'moment';

import { DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';

export const VIEW_MORE_LIMIT = 3;

function groupAllItemsByDate(groupedData) {
  const formatData = [];

  Object.keys(groupedData).forEach(item => formatData.push({
    date: item,
    events: groupedData[item],
  }));

  return formatData;
}

function useTimeline({ items, isLoading, limit }) {
  const showPlaceholder = !isLoading && isEmpty(items);
  const currentLimit = limit || VIEW_MORE_LIMIT;
  const isHiddenEvents = items && items.length > currentLimit;

  const [isOpenMore, setIsOpenMore] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const currentItems = isOpenMore ? items : items.slice(0, currentLimit);
    const groupedData = groupBy(currentItems, item => moment(item.date).format(DAY_YEAR_DATE_FORMAT));

    setVisibleItems(groupAllItemsByDate(groupedData));
  }, [isOpenMore, items]);

  function toggleViewMore() {
    setIsOpenMore(!isOpenMore);
  }

  return {
    isHiddenEvents,
    isOpenMore,
    visibleItems,
    toggleViewMore,
    showPlaceholder,
  };
}

export default useTimeline;
