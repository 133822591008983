import React from 'react';
import PropTypes from 'prop-types';
import { slice, get, filter } from 'lodash';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape, roleTypePropType } from 'components/core/Profile/utils/shapes';

import Position from './Position';

function Positions({ person, isLoading, roleType }) {
  const { positions } = person || {};
  const secondaryPositions = slice(positions, 1);
  const filteredPositions = roleType
    ? filter(secondaryPositions, { roleType })
    : secondaryPositions;

  return filteredPositions.map((position, index) => (
    <Position
      position={position}
      positionIndex={index + 2}
      isLoading={isLoading}
      key={`${get(position, 'title')}-${index + 2}`}
    />
  ));
}

Positions.propTypes = {
  person: personShape.isRequired,
  isLoading: PropTypes.bool,
  roleType: roleTypePropType,
};

export default withProfile(Positions);
