import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

function Tags({ keyName, titles, value, onChange, className, resTransform }) {
  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      {...titles}
      autoload
      name={keyName}
      strategy="tag"
      defaultSelections={value}
      overrideValue={value}
      className={className}
      resTransform={resTransform}
      onChange={handleChange}
    />
  );
}

Tags.defaultProps = {
  keyName: 'tags',
  titles: {
    title: 'Tags',
    placeholder: '',
  },
};

Tags.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  resTransform: PropTypes.func,
};

export default Tags;
