import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { formatBytes } from 'components/core/FileUpload/utils';
import { View, Text, Link } from '@react-pdf/renderer';

import styles from './styles';

function Attachments({ uploads }) {
  if (isEmpty(uploads)) {
    return null;
  }

  return (
    <View style={styles.attachments}>
      {uploads.map(({ file_upload_id: id, file_upload_url: url, name, size }) => (
        <View key={id} style={styles.file}>
          <Link src={url} style={styles.name}>
            {name}
          </Link >
          <Text style={styles.size}>
            &nbsp;({formatBytes(size)})
          </Text>
        </View>
      )).reverse()}
    </View>
  );
}

Attachments.propTypes = {
  uploads: PropTypes.array,
};

export default Attachments;
