import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

function ReportLabel({ isMobile, className, iconClassName }) {
  const reportIcon = isMobile
    ? <img src="/images/report-export.svg" alt="File with download symbol" />
    : <i className={classNames(styles.triangle, iconClassName)} />;

  return (
    <>
      <span className={classNames(styles.label, className)}>Report</span>
      {reportIcon}
    </>
  );
}

ReportLabel.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default ReportLabel;
