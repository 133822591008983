export const ALL_CONTRIBUTIONS_KEY = 'allContributions';
export const STAKEHOLDER_CONTRIBUTIONS_KEY = 'stakeholderContributions';

export const CONTRIBUTION_OPTIONS = [
  {
    label: 'All contributions',
    value: ALL_CONTRIBUTIONS_KEY,
  },
  {
    label: 'Stakeholder contributions',
    value: STAKEHOLDER_CONTRIBUTIONS_KEY,
  },
];
