export default function normalizeEventTarget(event, targetProps) {
  const { target } = event;

  return {
    ...event,
    target: {
      ...target,
      ...targetProps,
    },
  };
}
