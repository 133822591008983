import React from 'react';
import { split, map } from 'lodash';

import Link from 'components/core/Link';
import { customError } from 'utils/customError';
import { getUrlWithBase } from 'utils/url';

import { URL_PER_TYPE } from './constants';
import styles from './styles.module.scss';

export const defaultRenderMention = mention => (
  <Link href={mention.url} theme="link" className={styles.link}>
    {`${mention.trigger}${mention.label}`}
  </Link>
);

export const defaultBuildLink = (type, recordId) => {
  if (!URL_PER_TYPE[type]) {
    customError(`Mention link not defined for ${type} type`);
    return null;
  }

  const url = URL_PER_TYPE[type]({ recordId });
  return url && getUrlWithBase(url);
};

const PLAIN_MENTION_REGEXP = /([@#]\[[^\]]+\]\([\w-]+:[\w-]+\))/ig;
// First Group => Trigger
// Second Group => Label
// Third Group => Type
// Fourth Group => Id
const GROUPED_MENTION_REGEXP = /([@#])\[([^\]]+)\]\(([\w-]+):([\w-]+)\)/i;

const textStartsWithMention = value => value.search(PLAIN_MENTION_REGEXP) === 0;

export const getParseMention = buildLink => (
  (value) => {
    if (!textStartsWithMention(value)) {
      return { text: value, isMention: false };
    }

    const mentionData = value.match(GROUPED_MENTION_REGEXP);

    const [
      text,
      trigger,
      label,
      type,
      id,
    ] = mentionData;

    return {
      text,
      trigger,
      label,
      type,
      id,
      url: buildLink(type, id),
      isMention: true,
    };
  }
);

export const getContentWithParsedMentions = (content, buildLink) => {
  // split content into plain text and each group of the Regexp
  const parseMention = getParseMention(buildLink);
  const splittedContent = split(content, PLAIN_MENTION_REGEXP);
  return map(splittedContent, parseMention);
};
