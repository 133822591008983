import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function LinkTitleField({ href, label, className, onClick }) {
  if (isEmpty(href)) {
    return null;
  }

  return (
    <Link
      href={href}
      className={classNames(styles.viewText, className)}
      theme="default"
      size="none"
      onClick={onClick}
      isExternal
    >
      {label || 'View text'}
    </Link>
  );
}

LinkTitleField.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default LinkTitleField;
