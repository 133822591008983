import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ViewTags from 'components/core/ViewTags';
import { Column } from 'components/core/Table';
import { SIZES as TAG_SIZES } from 'components/core/ViewTags/constants';

import { itemShape } from '../utils/shapes';
import styles from './styles.module.scss';

function AddedToItem({
  isColumn,
  keyName,
  item,
  isLoading,
  className,
  isLoadingMyFeedDocuments,
  withTags,
}) {
  const { sharings, trackings, tags } = item || {};

  const renderItems = () => (
    <div className={classNames(styles.badgesContainer, className)}>
      <ViewTags
        isLoading={isLoading || isLoadingMyFeedDocuments}
        size={TAG_SIZES.small}
        tileClassName={styles.tile}
        sharings={sharings}
        trackings={trackings}
        tags={withTags && tags || []}
      />
    </div>
  );

  if (!isColumn) {
    return renderItems();
  }

  return (
    <Column key={keyName} keyName={keyName} className={styles.container}>
      {renderItems}
    </Column>
  );
}

AddedToItem.defaultProps = {
  isColumn: true,
  withTags: false,
};

AddedToItem.propTypes = {
  keyName: PropTypes.string,
  item: itemShape,
  isLoading: PropTypes.bool,
  isColumn: PropTypes.bool,
  className: PropTypes.string,
  isLoadingMyFeedDocuments: PropTypes.bool,
  withTags: PropTypes.bool,
};

export default memo(AddedToItem);
