import { editorialAlertError, editorialAlertSuccess } from './notifications';
import { transformEditorialAlertForBE } from './transformers';

export const submitEditorialAlert = ({ onSuccess, ...payload }) => {
  const url = '/api_web/actions/submit_editorial_alert';
  const data = transformEditorialAlertForBE(payload);

  const ajax = $.ajax({
    url,
    method: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .then(editorialAlertSuccess)
    .then(onSuccess)
    .catch(editorialAlertError);
};
