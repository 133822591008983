// TODO: change once backend cluster grouping is introduced
export const PAGE_SIZE = 30;

export function getUrl(location, profileType, id) {
  return `/api_web/${location}/${profileType}/${id}/news/lookup`;
}

export const LEGISLATOR_PROFILE_TYPE = 'legislators';
export const BILL_PROFILE_TYPE = 'bills';
export const EXECUTIVE_PROFILE_TYPE = 'executives';
