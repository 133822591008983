import React, { Fragment } from 'react';
import { reject, isEmpty, map } from 'lodash';

import Card from 'components/core/Card';
import Link from 'components/core/Link';
import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape, roleTypePropType } from 'components/core/Profile/utils/shapes';
import { pluralize } from 'utils/string';

import { getProfileUrl, getPositionDescription } from './utils';
import styles from './styles.module.scss';

function OtherPositions({ person, roleType }) {
  const otherPositions = reject(person.positions, { roleType });
  const otherPositionsCount = otherPositions.length;

  if (isEmpty(otherPositions)) {
    return null;
  }

  const renderSeparator = (index) => {
    if (index === 0) return null;
    if (index === otherPositionsCount - 1) return otherPositionsCount > 2 ? ', and ' : ' and ';
    return ', ';
  };

  const renderPositionLink = (position, index) => (
    <Fragment key={position.title}>
      {renderSeparator(index)}
      <Link
        href={getProfileUrl(position.roleType, person.personId)}
        theme="tableLink"
        className={styles.positionLink}
      >
        {getPositionDescription(position)}
      </Link>
    </Fragment>
  );

  const positionsLinks = map(otherPositions, renderPositionLink);

  const otherPositionLabel = pluralize(
    'another position',
    `${otherPositionsCount} other positions`,
    otherPositionsCount
  );

  return (
    <Card className={styles.card}>
      This executive has {otherPositionLabel}: {positionsLinks}
    </Card>
  );
}

OtherPositions.propTypes = {
  person: personShape.isRequired,
  roleType: roleTypePropType.isRequired,
};

export default withProfile(OtherPositions);
