import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import ActionBar from 'components/core/ActionBar';

import styles from '../styles.module.scss';

function FooterActions({
  ui,
  selectedItems,
  onClear,
  onDisable,
  onEnable,
}) {
  const isLoading = ui.isUpdatingCustomField || ui.isSavingCustomField;
  const isPresentSelectedItems = selectedItems.length > 0;
  const buttonProps = {
    theme: 'light',
    size: 'medium',
    className: styles.barBtn,
  };

  return isPresentSelectedItems && (
    <ActionBar
      title="Action"
      selectedItems={selectedItems}
      isFixed={false}
      className={styles.actionBar}
    >
      <Button
        {...buttonProps}
        onClick={onClear}
      >
        Clear
      </Button>

      <Button
        {...buttonProps}
        onClick={onDisable}
        disabled={isLoading}
      >
        Disable
      </Button>
      <Button
        {...buttonProps}
        onClick={onEnable}
        disabled={isLoading}
      >
        Enable
      </Button>
    </ActionBar>
  );
}

FooterActions.propTypes = {
  ui: PropTypes.object.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onClear: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
};

export default FooterActions;
