import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';

import AlertType from './components/AlertType';
import Push from './components/Push';
import { LIMIT_NOTIFICATIONS } from './utils/constants';
import styles from './styles.module.scss';

/**
 * Insert <Notification /> at the top of the parent component to use notification success/error functions
 * */
function Notification(props) {
  return (
    <ToastContainer
      className={styles.container}
      toastClassName={styles.card}
      {...props}
    />
  );
}

Notification.defaultProps = {
  limit: LIMIT_NOTIFICATIONS,
};

Notification.propTypes = {
  limit: PropTypes.number,
};

export * from './utils';
export * from './utils/notificationTypes';
export * from './utils/animations';
export { AlertType, Push };
export default Notification;
