import { useState, useEffect } from 'react';

import Notifications from 'components/Moneyball/utils/Notifications';

import { ALL_CONTRIBUTIONS_KEY, STAKEHOLDER_CONTRIBUTIONS_KEY } from './constants';

function useContributions({ searchProperties, perPage, searchReceipts }) {
  const [contribution, setContribution] = useState(ALL_CONTRIBUTIONS_KEY);
  const stakeholdersContributionsOnly = contribution === STAKEHOLDER_CONTRIBUTIONS_KEY;

  const fetchData = filters => searchReceipts(filters, stakeholdersContributionsOnly)
    .catch(Notifications.defaultFetchDataFailed);

  useEffect(() => {
    fetchData({
      ...searchProperties[contribution],
      perPage,
    });
  }, [contribution]);

  function handleChangeContribution(selectedObject = {}) {
    const { value } = selectedObject;
    setContribution(value);
  }

  return {
    contribution,
    onChangeContribution: handleChangeContribution,
    fetchData,
  };
}

export default useContributions;
