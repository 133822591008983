import React from 'react';
import { keyBy, mapValues, max, min, range } from 'lodash';

import styles from '../styles.module.scss';
import { DEFAULT_INTERVAL, MAP_LEGEND_RANGE } from './constants';

function roundValueToFive(value) {
  return Math.floor(value / 5) * 5;
}

export function getStateCounters(data) {
  return mapValues(keyBy(data, 'name'), 'count');
}

function getCountValues(data, stateCounters) {
  const stateCountersValues = Object.values(stateCounters);
  const highestCount = max(stateCountersValues);
  const lowestCount = min(stateCountersValues);

  return { highestCount, lowestCount, stateCounters };
}

function getInterval({ highestCount, lowestCount, isEqualInterval }) {
  if (isEqualInterval) {
    return highestCount / MAP_LEGEND_RANGE;
  }

  return (highestCount - roundValueToFive(lowestCount)) / MAP_LEGEND_RANGE;
}

function getLimits({ limitsRange, adjustedInterval, lowestCount, isEqualInterval }) {
  function getRange(index) {
    if (isEqualInterval) {
      return Math.ceil(index * adjustedInterval);
    }

    return index && Math.ceil((index * adjustedInterval)) + roundValueToFive(lowestCount);
  }

  return range(limitsRange + 1).map(getRange);
}

function getColorRange({ highestCount, lowestCount, isEqualInterval = false }) {
  let limits = [];
  let interval = [];
  let adjustedInterval = DEFAULT_INTERVAL;

  if (highestCount > 0) {
    interval = getInterval({ highestCount, lowestCount, isEqualInterval });
    adjustedInterval = interval > 1 ? interval : DEFAULT_INTERVAL;
    const limitsRange = highestCount < MAP_LEGEND_RANGE ? highestCount : MAP_LEGEND_RANGE;

    limits = getLimits({ limitsRange, adjustedInterval, lowestCount, isEqualInterval });
  }

  return { limits, interval: adjustedInterval };
}

export function getColorLimits(data, stateCounts, isEqualInterval) {
  const { stateCounters, ...stateCountersValues } = getCountValues(data, stateCounts);
  const { limits, interval } = getColorRange({ ...stateCountersValues, isEqualInterval });

  return { limits, stateCounters, interval };
}

export function colorScale(billCount, colorLimits, mapColors) {
  /* Returns range color corresponding to the bill count */
  for (let i = 0; i < colorLimits.length; i += 1) {
    if (billCount <= colorLimits[i]) return mapColors[i];
  }

  return mapColors[0];
}

export function getTooltipContent(stateName = '', stateBillsCount = 0) {
  return (
    <span>
      <b>{stateName}</b>
      <p className={styles.noMargin}>Bills Count: {stateBillsCount}</p>
    </span>
  );
}
