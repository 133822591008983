import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import { isEmpty } from 'lodash';

import Checkbox from 'components/core/Checkbox';
import RadioBox from 'components/core/RadioBox';

import styles from './styles.module.scss';

function LabelItem({
  id,
  title,
  limit,
  onCheck,
  checkedItems,
  type,
  customLabel,
  isDisabled,
  isSelectAll,
  allItemsLength,
  tooltipContent,
}) {
  const isChecked = isSelectAll
    ? checkedItems.length === (allItemsLength - 1)
    : checkedItems.includes(id);

  const defaultLabel = (
    <>
      {title}{limit && <span className={styles.limit}>max {limit} items</span>}
    </>
  );

  const radioItem = (
    <Fragment key={`item-${id}`}>
      <RadioBox
        name={id}
        value={id}
        checked={isChecked}
        disabled={isDisabled}
        label={customLabel || defaultLabel}
        onChange={onCheck}
      />
    </Fragment>
  );

  const checkboxItem = (
    <Checkbox
      label={customLabel || defaultLabel}
      value={id}
      name={id}
      onChange={onCheck}
      isChecked={isChecked}
      isDisabled={isDisabled}
    />
  );

  const item = type === 'radio' ? radioItem : checkboxItem;

  if (isDisabled && !isEmpty(tooltipContent)) {
    return (
      <Tooltip
        content={tooltipContent}
        theme="light"
        interactive
        arrow
      >
        <div>{item}</div>
      </Tooltip>
    );
  }

  return item;
}

LabelItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  limit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onCheck: PropTypes.func.isRequired,
  checkedItems: PropTypes.array.isRequired,
  type: PropTypes.string,
  customLabel: PropTypes.node,
  isDisabled: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  allItemsLength: PropTypes.number,
  tooltipContent: PropTypes.string,
};

export default LabelItem;
