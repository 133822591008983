import React from 'react';
import PropTypes from 'prop-types';

import CoreTagInput from 'components/core/TagInput';
import { suggestionShape } from 'utils/shapes/suggestion';
import { strategyInputsShape } from 'components/Autosuggest/utils/shapes';

import styles from '../styles.module.scss';

function TagInput({ id, strategy, values, onChange, multiple, strategyInputs }) {
  const handleChange = newValue => onChange(id, newValue);

  return (
    <CoreTagInput
      shouldOverrideValue
      strategy={strategy}
      overrideValue={values}
      groupClassName={styles.item}
      onChange={handleChange}
      multiple={multiple}
      strategyInputs={strategyInputs}
    />
  );
}

TagInput.propTypes = {
  id: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(suggestionShape).isRequired,
    suggestionShape,
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  strategyInputs: strategyInputsShape,
};

export default TagInput;
