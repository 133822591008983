import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/core/Modal';

import styles from './styles.module.scss';

function ImageModal({ isOpen, title, image, partyIcon, toggleOpen, isLoading }) {
  if (isLoading) {
    return null;
  }

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      headerTitle={<>{title} {partyIcon}</>}
      className={styles.modal}
      headerClassName={styles.modalHeader}
      bodyClassName={styles.modalBody}
    >
      <img
        src={image}
        alt={title}
        className={styles.image}
      />
    </Modal>
  );
}

ImageModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.string,
  partyIcon: PropTypes.node,
  toggleOpen: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

export default ImageModal;
