import { useEffect, useState } from 'react';
import { useListener } from 'react-bus';
import cloneDeep from 'lodash/cloneDeep';

import { findNode, findParentNode } from './dragEndActions/common';
import dragEndActions from './dragEndActions';

function useSmartSearch({
  bus,
  history,
  listId,
  data,
  title,
  description,
  isTemplate,
  updateData,
  submitList,
  draggableItems,
  afterSubmittingURL,
  onSuccess,
  trackingActions,
}) {
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isDraggingFilter, setIsDraggingFilter] = useState(false);
  const [isDraggingStatement, setIsDraggingStatement] = useState(false);
  const setIsDraggingFilterToFalse = () => setIsDraggingFilter(false);
  const setIsDraggingStatementToFalse = () => setIsDraggingStatement(false);

  useListener('submitting', () => setSubmitting(true));
  useListener('errors', error => setErrors(prevState => [...prevState, error]));

  useEffect(() => {
    if (submitting) {
      setTimeout(() => {
        const isValid = errors.filter(Boolean).length === 0;

        setSubmitting(false);
        setErrors([]);

        if (isValid) {
          submitList({
            id: listId,
            payload: { title, description, data, isTemplate },
            onSuccess: (payload) => {
              if (onSuccess) {
                onSuccess(payload);
              } else {
                history.push(afterSubmittingURL);
              }
            },
          });
        }
      }, 250);
    }
  }, [submitting]);

  useEffect(() => {
    document.addEventListener('mouseup', setIsDraggingFilterToFalse);
    document.addEventListener('mouseup', setIsDraggingStatementToFalse);

    return () => {
      document.removeEventListener('mouseup', setIsDraggingFilterToFalse);
      document.removeEventListener('mouseup', setIsDraggingStatementToFalse);
    };
  }, []);

  function onBeforeCapture({ draggableId }) {
    // scrollTo is required to fix MON-1340
    const offsetScroll = window.pageYOffset;
    const { scrollHeight, offsetHeight } = document.documentElement;
    const { type } = findNode(draggableItems, draggableId) || {};

    scrollHeight === offsetHeight + offsetScroll && window.scrollTo({ top: offsetScroll - 1 });
    type && type === 'statement' && setIsDraggingStatement(true);
    type && type === 'filter' && setIsDraggingFilter(true);
  }

  function onDragEnd(result = {}) {
    if (!result.destination) return;

    const { draggableId, destination: { droppableId } } = result;
    const regexIsFilterJoin = /droppableFiltersJoinFor:([a-z0-9-]+)/gi;
    const regexIsFilterGroup = /droppableFiltersGroupFor:([a-z0-9-]+)/gi;
    const regexIsFilterReorder = /droppableFiltersReorder:([a-z0-9-]+)/gi;
    const regexIsStatementJoin = /droppableStatementsJoinFor:([a-z0-9-]+)/gi;
    const regexIsStatementReorder = /droppableStatementsReorder:([a-z0-9-]+)/gi;
    const isDroppedFilterJoin = regexIsFilterJoin.exec(droppableId);
    const isDroppedFilterGroup = regexIsFilterGroup.exec(droppableId);
    const isDroppedFilterReorder = regexIsFilterReorder.exec(droppableId);
    const isDroppedStatementJoin = regexIsStatementJoin.exec(droppableId);
    const isDroppedStatementReorder = regexIsStatementReorder.exec(droppableId);
    const isDroppedStatementGroup = droppableId.match('droppableStatementsGroupNew');

    const action = isDroppedFilterJoin && 'isDroppedFilterJoin'
      || isDroppedFilterGroup && 'isDroppedFilterGroup'
      || isDroppedFilterReorder && 'isDroppedFilterReorder'
      || isDroppedStatementJoin && 'isDroppedStatementJoin'
      || isDroppedStatementGroup && 'isDroppedStatementGroup'
      || isDroppedStatementReorder && 'isDroppedStatementReorder';

    const parentId = isDroppedStatementJoin && isDroppedStatementJoin[1]
      || isDroppedStatementReorder && isDroppedStatementReorder[1]
      || isDroppedFilterJoin && isDroppedFilterJoin[1]
      || isDroppedFilterGroup && isDroppedFilterGroup[1]
      || isDroppedFilterReorder && isDroppedFilterReorder[1];

    const { trackItemAddedToBuilder } = trackingActions;
    trackItemAddedToBuilder(draggableId);

    dragEndActions({
      action,
      result,
      data,
      updateData,
      parentId,
    })();
  }

  function onDelete(id) {
    let oldNode;
    let newNode;
    const { type } = findNode(data, id);
    const toCheck = type === 'statement' ? 'statements' : 'filters';

    oldNode = findParentNode(data, id);
    newNode = cloneDeep(oldNode);
    newNode[toCheck] = newNode[toCheck].filter(item => item.id !== id);

    if (newNode[toCheck].length === 0) {
      const { id: oldNodeId } = oldNode;
      oldNode = findParentNode(data, oldNodeId);
      newNode = cloneDeep(oldNode);
      newNode[toCheck] = newNode[toCheck].filter(item => item.id !== oldNodeId);

      // node with null value is removed while submitting
      if (toCheck === 'filters' && newNode[toCheck].length === 0) {
        newNode = null;
      }
    }

    updateData({
      payload: JSON.parse(
        JSON.stringify(data).replace(
          JSON.stringify(oldNode),
          JSON.stringify(newNode),
        ),
      ),
    });
  }

  function onCreate() {
    bus.emit('submitting', true);
  }

  const onFocus = (name, fn) => () => fn(prevState => ({ ...prevState, [name]: undefined }));

  return {
    submitting,
    isDraggingFilter,
    isDraggingStatement,
    setIsDraggingFilter,
    setIsDraggingStatement,
    onBeforeCapture,
    onDragEnd,
    onDelete,
    onCreate,
    onFocus,
  };
}

export default useSmartSearch;
