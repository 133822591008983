export const CHECKBOX_TYPE_SLUG = 'checkbox';
export const RADIO_TYPE_SLUG = 'radio';
export const INPUT_TYPE_SLUG = 'string';
export const TEXTAREA_TYPE_SLUG = 'textarea';
export const NUMERIC_TYPE_SLUG = 'numeric';
export const DATE_TYPE_SLUG = 'date';
export const AUTOCOMPLETE_SLUG = 'autocomplete';
// 25 -> 1000 intended to remove pagination because of the way
// reordering custom fields work on the BE
export const DEFAULT_PAGE_SIZE = 1000;

export const TYPE_OPTIONS = [
  {
    label: 'Radio buttons',
    value: RADIO_TYPE_SLUG,
  },
  {
    label: 'Checkboxes',
    value: CHECKBOX_TYPE_SLUG,
  },
  {
    label: 'Single line text',
    value: INPUT_TYPE_SLUG,
  },
  {
    label: 'Paragraph text',
    value: TEXTAREA_TYPE_SLUG,
  },
  {
    label: 'Number',
    value: NUMERIC_TYPE_SLUG,
  },
  {
    label: 'Date',
    value: DATE_TYPE_SLUG,
  },
  {
    label: 'Autocomplete',
    value: AUTOCOMPLETE_SLUG,
  },
];

export const PRODUCT_SLUGS = {
  GRM: 'grm',
  STAKEHOLDER_MGMT: 'stakeholder_mgmt',
};

export const PRODUCT_SLUGS_OPTIONS = [
  {
    label: 'Activity',
    value: PRODUCT_SLUGS.GRM,
  },
  {
    label: 'Stakeholder Management',
    value: PRODUCT_SLUGS.STAKEHOLDER_MGMT,
  },
];

export const REQUIRED_FIELD_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export function getFieldTypeDescription(slug, typesMapping = TYPE_OPTIONS) {
  const option = typesMapping.find(item => item.value === slug);

  if (!option) {
    return 'Other';
  }

  return option.label;
}

export const fieldColumnsLabels = {
  createdAt: 'Created at',
  description: 'Description',
  fieldType: 'Field Type',
  name: 'Name',
  question: 'Question',
  token: 'Token',
  options: 'Options',
  required: 'Required',
  enabled: 'Enabled',
  isEditable: 'Editable',
  action: 'Action',
  productSlugs: 'Available in',
};

export const NAME_PLACEHOLDER = 'Ex: ‘George Washington’';
export const OPTION_PLACEHOLDER = 'Ex: ‘George Washington’';
export const DISABLED_MSG = 'This field has already collected answers, and thus cannot be changed.';
export const TYPE_PLACEHOLDER = 'Select a Type';
export const DESCRIPTION_PLACEHOLDER = 'Describe what the custom field is for so you and your coworkers will understand how to use it…';
