import { useState } from 'react';

function useAvatar() {
  const [hasAvatar, setHasAvatar] = useState(true);

  return {
    hasAvatar,
    onAvatarError: () => setHasAvatar(false),
  };
}

export default useAvatar;
