import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function ViewAllLink({ item }) {
  const { allLegislatorsSize = 0, billUrl } = item || {};

  function renderLink() {
    const text = allLegislatorsSize > 0
      ? `View all ${allLegislatorsSize} on bill page`
      : 'View all on bill page';

    if (billUrl) {
      return (
        <Link theme="default" size="none" href={billUrl}>
          {text}
        </Link>
      );
    }

    return text;
  }

  return (
    <div className={styles.viewAllButton}>
      {renderLink()}
    </div>
  );
}

ViewAllLink.propTypes = {
  item: PropTypes.object,
};

export default ViewAllLink;
