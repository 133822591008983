import isArray from 'lodash/isArray';

import { clusterCircleGradient, clusterCircleBorderColor, textColor } from './settings.scss';
import { getMapPopup } from './getMapPopup';
import { addMarkerImage, addMapSource } from './index';

function addClusterLayers(sourceName, map, isHeatmapChecked) {
  const visibility = isHeatmapChecked ? 'none' : 'visible';

  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: sourceName,
    filter: ['has', 'point_count'],
    paint: {
      'circle-stroke-width': 1,
      'circle-stroke-color': clusterCircleBorderColor,
      'circle-color': clusterCircleGradient,
      'circle-radius': ['step', ['get', 'point_count'], 10, 2, 20, 20, 50, 50, 80],
    },
    layout: {
      visibility,
    },
  });

  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: sourceName,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      visibility,
    },
    paint: {
      'text-color': textColor,
    },
  });

  map.addLayer({
    id: 'unclustered-point',
    type: 'symbol',
    source: sourceName,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'map-marker',
      'icon-size': 0.3,
      'icon-allow-overlap': true,
      visibility,
    },
  });
}

function onClusterClick(sourceName, map) {
  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
    const clusterId = features[0].properties.cluster_id;
    const center = features[0].geometry.coordinates;

    map.getSource(sourceName).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (error) throw error;

      map.easeTo({ center, zoom });
    });
  });
}

function onUnclusteredPointClick(popupProps, map) {
  map.on('click', 'unclustered-point', (e) => {
    const { setActivePopup } = popupProps || {};
    const { features } = e || {};
    const properties = [];

    isArray(features) && features.forEach(feature => properties.push(feature.properties));

    const coordinates = e.features[0].geometry.coordinates.slice();
    const popupOptions = { properties, ...popupProps };

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popup = getMapPopup(popupOptions)
      .setLngLat(coordinates)
      .addTo(map);

    setActivePopup && setActivePopup(popup);
  });
}

function styleMapCursor(target, map) {
  map.on('mouseenter', target, () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', target, () => {
    map.getCanvas().style.cursor = '';
  });
}

function initClusterEvents(sourceName, popupProps, map) {
  styleMapCursor('unclustered-point', map);
  styleMapCursor('clusters', map);

  onClusterClick(sourceName, map);
  onUnclusteredPointClick(popupProps, map);
}

export function setMarkersAndClusters(sourceName, map, data, popupProps, markerImage, isHeatmapChecked) {
  map.on('load', () => {
    addMapSource(sourceName, data, map, true);

    addMarkerImage(map, markerImage, () => {
      addClusterLayers(sourceName, map, isHeatmapChecked);
    });

    initClusterEvents(sourceName, popupProps, map);
  });
}
