import {
  trackStakeholderEmailed,
  trackStakeholderPhoned,
  trackStakeholderVisited,
  trackStakeholdersPageVisited,
  trackStakeholderResultsSorted,
} from 'utils/tracking/profile';

export function getTrackingActions(context) {
  return {
    trackStakeholderEmailed: trackStakeholderEmailed(context),
    trackStakeholderVisited: trackStakeholderVisited(context),
    trackStakeholderPhoned: trackStakeholderPhoned(context),
    trackStakeholdersPageVisited: trackStakeholdersPageVisited(context),
    trackStakeholderResultsSorted: trackStakeholderResultsSorted(context),
  };
}
