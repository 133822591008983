import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { unknownObjectShape } from 'utils/shapes';
import { InfiniteLoadPaginator } from 'components/core/Paginator';
import Button from 'components/core/Button';
import styles from './styles.module.scss';

function InfinitePaginator({
  ui: { isInfiniteDisabled, isLoading },
  type,
  appliedFilters,
  createdAt,
  fetchData,
}) {
  return useMemo(() => {
    if (isInfiniteDisabled) {
      return null;
    }

    const handleOnLoad = page => fetchData({
      type,
      isInfinite: true,
      createdAt,
      searchData: {
        ...appliedFilters,
        page,
      },
    });

    const renderCustomLoader = () => isLoading && (
      <Button
        size="large"
        theme="secondary"
        className={styles.button}
        disabled
      >
        <span>Loading...</span>
      </Button>
    );

    return (
      <InfiniteLoadPaginator
        isLoading={isLoading}
        isPaginatorPresent={!isInfiniteDisabled}
        renderCustomLoader={renderCustomLoader}
        onLoad={handleOnLoad}
      />
    );
  }, [isInfiniteDisabled, isLoading, appliedFilters]);
}

InfinitePaginator.propTypes = {
  ui: PropTypes.shape({
    isLoading: PropTypes.bool,
    isInfiniteDisabled: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string,
  appliedFilters: unknownObjectShape.isRequired,
  fetchData: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
};

export default InfinitePaginator;
