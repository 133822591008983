import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuToggleButton from 'components/Navigation/MenuToggleButton';
import useModal from 'components/core/Modal/useModal';
import Tooltip from 'components/core/Tooltip';

import TooltipContent from '../TooltipContent';
import styles from './styles.module.scss';

function MobileView({ page, pageName, className }) {
  const tooltipRef = useRef(null);
  const {
    isOpenModal: isOpen,
    toggleModal: toggleTooltip,
    closeModal: closeTooltip,
  } = useModal();

  const getTooltipContent = () => (
    <TooltipContent isMobile page={page} pageName={pageName} />
  );

  function handleClose() {
    closeTooltip();
    setTimeout(tooltipRef.current.toggleHint, 0);
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Tooltip
        delay={0}
        className={styles.tooltip}
        forwardRef={tooltipRef}
        content={getTooltipContent}
      >
        <MenuToggleButton
          inactiveContent="?"
          onBlur={handleClose}
          onClick={toggleTooltip}
          inactiveClassName={styles.helpButton}
          isActive={isOpen}
        />
      </Tooltip>
    </div>
  );
}

MobileView.propTypes = {
  page: PropTypes.string,
  pageName: PropTypes.string,
  className: PropTypes.string,
};

export default MobileView;
