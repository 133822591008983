import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import isArray from 'lodash/isArray';

import {
  wrapperClassName,
  findObjectValues,
  findStringOrNumberValues,
  getNoOptionsText,
  isArrayWithEmptyValues,
  isEmptyValue,
} from 'components/core/Select/utils';
import ErrorMessage from 'components/core/ErrorMessage';
import { suggestionShape } from 'utils/shapes/suggestion';

import Option from './components/Option';
import DropdownIndicator from './components/DropdownIndicator';
import theme from './theme';

const Select = (props) => {
  const {
    clearable,
    isDisabled,
    multi,
    multiple,
    options,
    onChange,
    onBlur,
    searchable,
    value,
    className,
    placeholder,
    backspaceRemoves,
    deleteRemoves,
    name,
    joinValues,
    inputClassName,
    hasNewStyle,
    customTheme,
    errorMessage,
    mountOpened,
    autoFocus,
    ...otherProps
  } = props;

  /** Remove node element from DOM when no values are provided */
  const getName = () => {
    const isMultiple = multiple || multi;

    if (!joinValues && (isMultiple && isArrayWithEmptyValues(value)) || isEmptyValue(value)) {
      return '';
    }

    return name;
  };

  const getSelectOptions = (opts) => {
    if (isArray(opts)) {
      return opts.flatMap(opt => opt.options || opt);
    }

    return opts;
  };

  const getValue = () => {
    const selectOptions = getSelectOptions(options);

    if (isArray(value) && (multiple || multi)) {
      const customValues = [];

      value.forEach((val) => {
        customValues.push(...(findStringOrNumberValues(val, selectOptions) || []));
        customValues.push(...(findObjectValues(val, selectOptions) || []));
      });

      return customValues;
    }

    return findStringOrNumberValues(value, selectOptions) || findObjectValues(value, selectOptions) || value;
  };

  const customClassNames = classNames(
    wrapperClassName,
    className,
    inputClassName,
  );
  const delimiter = joinValues ? ',' : undefined;
  const customComponents = (hasNewStyle ? { Option, DropdownIndicator } : { Option });

  return (
    <>
      <ReactSelect
        styles={{ ...theme(hasNewStyle, errorMessage), ...customTheme }}
        value={getValue()}
        name={getName()}
        options={options}
        className={customClassNames}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        components={customComponents}
        noOptionsMessage={getNoOptionsText}
        isMulti={multi || multiple}
        isDisabled={isDisabled}
        isSearchable={searchable}
        isClearable={clearable}
        backspaceRemovesValue={backspaceRemoves}
        deleteRemoves={deleteRemoves}
        delimiter={delimiter}
        menuIsOpen={mountOpened}
        autoFocus={autoFocus}
        {...otherProps}
      />

      <ErrorMessage message={errorMessage} />
    </>
  );
};

Select.propTypes = {
  deleteRemoves: PropTypes.bool,
  backspaceRemoves: PropTypes.bool,
  clearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  multi: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    suggestionShape,
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.bool,
    }),
  ])),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  searchable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  joinValues: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  hasNewStyle: PropTypes.bool,
  customTheme: PropTypes.shape({}),
  errorMessage: PropTypes.string,
  mountOpened: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

Select.defaultProps = {
  clearable: true,
  isDisabled: false,
  hasNewStyle: false,
  customTheme: null,
  mountOpened: undefined,
  autoFocus: undefined,
  placeholder: '', // get rid of default 'Select...' placeholder
};

export default Select;
