import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTextMask from 'react-text-mask';

import ErrorMessage from 'components/core/ErrorMessage';

import { inputSizes, handlePasteAutofill } from './helpers';
import maskMapping from './mask-mapping';

import styles from './styles.module.scss';

export default function MaskedInput({ isError, maskSlug, placeholder, errorMessage, className, size, pipe, ...restProps }) {
  const maskMapper = maskMapping[maskSlug];

  const inputClassName = classNames(
    className,
    styles.input,
    inputSizes[size],
    errorMessage && styles.invalid
  );

  // Workaround to deal with know bug (paste, autofill)
  // https://github.com/text-mask/text-mask/issues/689

  let pipeHandler = pipe;

  if (maskSlug === 'us_phone_number_with_country_code') {
    pipeHandler = handlePasteAutofill;
  }

  return (
    <Fragment>
      <ReactTextMask
        {...restProps}
        className={classNames(inputClassName, isError && styles.invalid)}
        mask={maskMapper.mask}
        placeholder={placeholder || maskMapper.placeholder}
        pipe={pipeHandler}
      />
      <ErrorMessage message={errorMessage} />
    </Fragment>
  );
}

MaskedInput.propTypes = {
  maskSlug: PropTypes.oneOf(
    Object.keys(maskMapping)
  ).isRequired,
  size: PropTypes.oneOf(
    Object.keys(inputSizes),
  ),
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  pipe: PropTypes.func,
  isError: PropTypes.bool,
};

MaskedInput.defaultProps = {
  size: 'default',
  guide: true,
  placeholderChar: '_',
  keepCharPositions: false,
};
