import React from 'react';

import { error } from 'components/core/Notification';

import styles from './notifications.module.scss';

export const notifyBriefingError = () => {
  const content = (
    <>
      <p className={styles.title}>Requested report is not available.</p>
      <p className={styles.subtitle}>
        Please contact Expert on Demand or Customer Success for assistance.
      </p>
    </>
  );

  return error(content)();
};
