import { toLower, upperFirst, replace } from 'lodash';

import { isURL } from 'utils/url';

const labels = {
  youtube: 'YouTube',
  linkedin: 'LinkedIn',
};

const getLabel = ({ url, type }) => {
  const typeWithoutIndex = replace(type, / [0-9]/, '');

  if (isURL(url) && toLower(typeWithoutIndex) === 'email') return 'Web form';

  return labels[toLower(typeWithoutIndex)] || upperFirst(toLower(type));
};

export default getLabel;
