import React from 'react';
import PropTypes from 'prop-types';

// TODO: fix cyclic dependency
// eslint-disable-next-line import/no-cycle
import { SettingsButton } from 'components/core/PageHeader';

export default function PageActionsButton({ tooltipTitle, pageLinks, className }) {
  const mapLinkToOption = ({ title, href }) => ({
    label: title,
    url: href,
  });

  return (
    <SettingsButton
      title={tooltipTitle}
      options={pageLinks.map(mapLinkToOption)}
      className={className}
    />
  );
}

PageActionsButton.defaultProps = {
  tooltipTitle: 'Settings',
};

PageActionsButton.propTypes = {
  tooltipTitle: PropTypes.string,
  pageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};
