import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextPlaceholder } from 'components/core/Placeholder';

import styles from '../DataField/styles.module.scss';

function DataFieldPlaceholder({ className }) {
  return (
    <div className={classNames(styles.item, className)}>
      <TextPlaceholder className={styles.labelPlaceholder} />
      <TextPlaceholder className={styles.valuePlaceholder} />
    </div>
  );
}

DataFieldPlaceholder.propTypes = {
  className: PropTypes.string,
};

export default DataFieldPlaceholder;
