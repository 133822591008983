import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

export default function EmptyMessage({ isMobile, query }) {
  const notFoundText = `No dimension named, '${query}' found.`;
  const customFieldsIcon = <img className={styles.customFieldsIcon} src="/images/cog.png" alt="Custom Fields" />;

  if (isMobile) {
    return (
      <div className={styles.noResults}>
        {notFoundText}
      </div>
    );
  }

  return (
    <div className={styles.noResults}>
      {notFoundText}
      <p>Click the {customFieldsIcon} icon to manage your Custom Fields.</p>
    </div>
  );
}

EmptyMessage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  query: PropTypes.string,
};
