function useButtons({
  isValid,
  isLoading,
  isFirstStep,
  isLastStep,
  previousStep,
  nextStep,
  onCancel,
  onSubmit,
}) {
  const backButton = {
    show: !isFirstStep,
    onClick: previousStep,
  };

  const cancelButton = {
    show: isFirstStep,
    onClick: onCancel,
  };

  const nextButton = {
    show: !isLastStep,
    onClick: nextStep,
    isDisabled: !isValid,
  };

  const submitButton = {
    show: isLastStep,
    onClick: onSubmit,
    isDisabled: !isValid,
    isLoading,
  };

  return {
    backButton,
    cancelButton,
    nextButton,
    submitButton,
  };
}

export default useButtons;
