import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import { isOptionSelected } from 'components/core/Sort/utils';
import { sortOptionShape } from 'components/core/Sort/shapes';

import styles from './styles.module.scss';

function TooltipNode({ options, onClick, selectedSort, className, optionClassName }) {
  function renderOption(option, index) {
    const { label } = option;
    const { value, direction } = selectedSort;
    const isSelected = isOptionSelected(option, { sort: value, direction });

    return (
      <li key={`sort-option-${index}`}>
        <Button
          theme="light"
          className={classNames(styles.sortOptionButton, isSelected && styles.isSelected, optionClassName)}
          onClick={onClick(option)}
        >
          {label}
        </Button>
      </li>
    );
  }

  return (
    <div className={classNames(styles.tooltipNode, className)}>
      <ul className={styles.list}>
        {options.map(renderOption)}
      </ul>
    </div>
  );
}

TooltipNode.propTypes = {
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selectedSort: sortOptionShape.isRequired,
  options: PropTypes.arrayOf(sortOptionShape).isRequired,
};

export default TooltipNode;
