import { useEffect, useState } from 'react';
import { differenceBy, size, isEmpty, isNil, filter, map } from 'lodash';
import { toast } from 'react-toastify';

import { LIMIT_NOTIFICATIONS } from './constants';

function useMultipleNotifications({
  id,
  nextList,
  myPageProps,
  renderDisplayAll,
  displayNotifications = true,
}) {
  const [notifications, setNotifications] = useState([]);
  const [pushNotifications, setPushNotifications] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [pulse, setPulse] = useState(false);
  let timer = null;

  function dismissAll() {
    toast.dismiss();
  }

  function handleChangePulse(value) {
    setPulse(value);
  }

  /** Do not notify on close - removed from the list - marked as read */
  function onClose(props) {
    const { id: contentId } = props || {};

    if (!isNil(contentId)) {
      setIsClosing(true);
      setNotifications(prevProps => filter(prevProps, item => item.id !== contentId));
    }
  }

  function handleNewNotifications() {
    if (isEmpty(nextList)) {
      return;
    }

    setIsClosing(false);

    const newNotifications = differenceBy(nextList, notifications, 'id');

    if (isEmpty(newNotifications)) {
      return;
    }

    setPulse(true);

    timer = setTimeout(() => setPulse(false), 2000);

    setNotifications(prevState => [
      ...prevState,
      ...newNotifications,
    ]);

    setPushNotifications(newNotifications);
  }

  /** Add new notifications to the current state */
  useEffect(() => {
    handleNewNotifications();

    return () => clearTimeout(timer);
  }, [nextList]);

  /** Generate new notifications when new notifications are present */
  useEffect(() => {
    if (!isClosing && displayNotifications) {
      dismissAll();
      const notificationsLength = size(pushNotifications);
      const isOverLimit = notificationsLength > LIMIT_NOTIFICATIONS;
      const title = `${notificationsLength} new notifications`;

      notificationsLength > 0 && renderDisplayAll({
        id,
        title,
        myPageProps,
        notifications: map(pushNotifications, 'content'),
        isOverLimit,
        onClose,
      });
    }
  }, [pushNotifications]);

  return {
    pulse,
    onChangePulse: handleChangePulse,
  };
}

export default useMultipleNotifications;
