import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { truncate, map, isEmpty, size, replace } from 'lodash';

import { LabelLink, LabelText, LegislatorName } from 'components/core/ExportDocument';
import { splitHighlightText } from 'utils/highlightText';
import { NEW_LINE_REGEX, NON_PRINTABLE_ASCII } from 'utils/string';

import styles from './styles';

const maxTextLength = 1000;

function getHighlightedWord(text = '') {
  if (isEmpty(text)) {
    return '';
  }

  return map(splitHighlightText(text), ({ textContent, isHighlight }, index) => (
    <Text key={`export-text-${index}`} style={isHighlight && styles.emphasizedText}>
      {textContent}
    </Text>
  ));
}

export function transformSnippet(snippet) {
  const trimmedSnippet = replace(replace(snippet, NEW_LINE_REGEX, ' '), NON_PRINTABLE_ASCII, '');

  return getHighlightedWord(trimmedSnippet);
}

export function renderSnippet(snippet) {
  return (
    <View>
      <LabelText
        textStyle={styles.billDescriptionText}
        labelStyle={styles.billLabelText}
        value={transformSnippet(snippet)}
        label="Snippet"
      />
    </View>
  );
}

export function getDescriptionItem(itemValue, itemLabel, billLink, maxTextLengthLimit = maxTextLength) {
  /*
  * Fixes react-pdf issue when Text tag length is over 2 pages
  * https://github.com/diegomura/react-pdf/issues/685
  */
  const truncatedValue = truncate(itemValue, { length: maxTextLengthLimit, separator: ' ' });
  const displayViewAllText = size(itemValue) > maxTextLengthLimit;

  return (
    <View>
      <LabelText
        textStyle={styles.billDescriptionText}
        labelStyle={styles.billLabelText}
        value={truncatedValue}
        label={itemLabel}
      />
      {displayViewAllText && (
        <LabelLink
          value="View all text on bill page"
          href={billLink}
          linkStyle={styles.billDescriptionLink}
          emptyTextStyle={styles.billEmptyLink}
        />
      )}
    </View>
  );
}

export function renderLegislator(legislator) {
  const { id, partyShort } = legislator;
  const keyName = partyShort ? id : `committee-${id}`;

  return (
    <View key={keyName} style={styles.billLegislatorContainer}>
      <LegislatorName
        hideAvatar
        nameStyle={styles.billLegislator}
        partyIconStyle={styles.billLegislatorIcon}
        {...legislator}
      />
    </View>
  );
}
