import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import useSortTable from 'utils/useSortTable';
import { getColumnLabelBySortingKey } from 'utils/table';
import getDistrictFilters from 'components/Stakeholder/common/utils/getDistrictFilters';

import Notifications from './Notifications';

function useTable({
  id,
  sort: sortRedux,
  paginator,
  fetchStakeholders,
  slug,
  trackingActions,
  customTransformers,
  tableColumns,
  defaultHiddenColumns,
  toResetSelectedItems,
  districtInfo,
  chamberCategory,
  isFederalSenator,
}) {
  const sort = useSortTable();

  const { id: districtId, name: districtName, state, stateName } = districtInfo;
  const { pageSize = PAGE_SIZE } = paginator || {};

  /** Fix default sort  */
  useEffect(() => {
    if (!isEmpty(sortRedux)) {
      const { sort: reduxColumn, direction } = sortRedux;

      sort && sort.setSortData({ direction, column: reduxColumn });
    }
  }, [sortRedux]);

  const isPaginatorPresent = !isEmpty(paginator);
  const {
    trackStakeholdersPageVisited,
    trackStakeholdersResultsSorted,
  } = trackingActions;

  const [selectedItems, setSelectedItems] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);

  const isSelectedItemsPresent = selectedItems.length > 0;

  function handleSelectItems(items) {
    setSelectedItems(items);
  }

  function handleClearItems() {
    setSelectedItems([]);
  }

  function handleHideColumn(columnKeyName, isHidden) {
    setHiddenColumns(prevState => ({
      ...prevState,
      [columnKeyName]: isHidden,
    }));
  }

  useEffect(() => {
    toResetSelectedItems && handleClearItems();
  }, [toResetSelectedItems]);

  function handleSearch(customData) {
    if (id) {
      const searchData = getDistrictFilters({
        chamberCategory,
        districtId,
        districtName,
        isFederalSenator,
        state,
        stateName,
      });

      fetchStakeholders({ ...customData, searchData, id, slug, perPage: pageSize }, customTransformers)
        .catch((error) => {
          Notifications.defaultFetchDataFailed();
          throw error;
        });
    }
  }

  useEffect(() => {
    const anyFilterValue = districtId || (state && isFederalSenator);

    anyFilterValue && handleSearch();
  }, [districtId, state, isFederalSenator, id]);

  function handleSortColumn(column) {
    const { getDirection } = sort;
    const direction = getDirection(column);
    const columnLabel = getColumnLabelBySortingKey(tableColumns, column);

    handleSearch({ sort: column, direction });
    trackStakeholdersResultsSorted && trackStakeholdersResultsSorted(columnLabel, direction);
  }

  function handleChangePage(page) {
    const { sortData = {} } = sort;
    const { column, direction } = sortData;

    handleSearch({ page, sort: column, direction });
    trackStakeholdersPageVisited && trackStakeholdersPageVisited(page);
  }

  return {
    ...sort,
    isPaginatorPresent,
    isSelectedItemsPresent,
    hiddenColumns,
    selectedItems,
    perPage: pageSize,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
    onHideColumn: handleHideColumn,
    onClearItems: handleClearItems,
    onSelectItems: handleSelectItems,
  };
}

export default useTable;
