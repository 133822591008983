import { transformCommentForBE } from './transformers';
import { generalFailNotification } from '../utils/notifications';

const PER_PAGE = 4;
const MAX_PER_PAGE = 100;

export const fetchComments = ({
  parentId,
  parentType,
  createdAtBefore,
  shouldLoadAllComments,
}) => {
  const url = '/api_web/comments';
  const method = 'GET';
  const perPage = shouldLoadAllComments ? MAX_PER_PAGE : PER_PAGE;

  const data = {
    record_id: parentId,
    record_type: parentType,
    created_at_before: createdAtBefore && moment(createdAtBefore).toISOString(),
    per_page: perPage,
  };
  const ajax = $.ajax({ url, method, data });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const saveComment = (comment) => {
  const url = '/api_web/comments';
  const method = 'POST';
  const data = transformCommentForBE(comment);

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const updateComment = ({ id, text }) => {
  const url = `/api_web/comments/${id}`;
  const method = 'PUT';
  const data = { text };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};

export const deleteComment = (id) => {
  const url = `/api_web/comments/${id}`;
  const method = 'DELETE';

  const ajax = $.ajax({
    url,
    method,
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .catch(generalFailNotification);
};
