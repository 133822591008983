import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Buttons({
  backButton,
  nextButton,
  submitButton,
  cancelButton,
}) {
  return (
    <div className={styles.container}>
      {backButton.show && (
        <Button
          className={styles.button}
          size="small"
          theme="light"
          onClick={backButton.onClick}
        >
          Back
        </Button>
      )}
      {cancelButton.show && (
        <Button
          className={styles.button}
          size="small"
          theme="light"
          onClick={cancelButton.onClick}
        >
          Cancel
        </Button>
      )}
      {nextButton.show && (
        <Button
          size="small"
          onClick={nextButton.onClick}
          disabled={nextButton.isDisabled}
        >
          Next
        </Button>
      )}
      {submitButton.show && (
        <Button
          size="small"
          onClick={submitButton.onClick}
          disabled={submitButton.isDisabled}
          isLoading={submitButton.isLoading}
        >
          Export
        </Button>
      )}
    </div>
  );
}

const buttonShape = PropTypes.shape({
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}).isRequired;

Buttons.propTypes = {
  backButton: buttonShape,
  cancelButton: buttonShape,
  nextButton: buttonShape,
  submitButton: buttonShape,
};

export default Buttons;
