import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import Title from 'components/core/Profile/components/Title';
import Table from 'components/core/Profile/components/Table';
import Card from 'components/core/Card';

import { sectionShape } from './shapes';
import styles from './styles.module.scss';

function Details({ sections, className, tableClassName, isLoading }) {
  function renderTable(table) {
    return (
      <Table
        table={table}
        key={table.id}
        className={tableClassName}
        isLoading={isLoading}
      />
    );
  }

  function renderSection({ id, title, titleDetail, titleDescription, tables, tablesClassName }) {
    return (
      <section key={id} className={styles.section}>
        <Title
          title={title}
          detail={titleDetail}
          description={titleDescription}
          titleContainerClassName={styles.titleContainer}
          titleClassName={styles.title}
          descriptionClassName={styles.description}
        />
        <div className={classNames(styles.tables, tablesClassName)}>
          {map(tables, renderTable)}
        </div>
      </section>
    );
  }

  return (
    <Card className={classNames(styles.card, className)}>
      {map(sections, renderSection)}
    </Card>
  );
}

Details.propTypes = {
  sections: PropTypes.arrayOf(sectionShape),
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Details;
