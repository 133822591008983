import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';

import styles from './styles.module.scss';

const TimePickerInput = forwardRef(({ isDisabled, customClassName, ...restProps }, ref) => {
  const iconStyles = classNames('fas fa-clock', styles.icon);

  return (
    <span>
      <i className={iconStyles} />
      <Input
        ref={ref}
        {...restProps}
        disabled={isDisabled}
        className={classNames(styles.input, customClassName)}
      />
    </span>
  );
});

TimePickerInput.propTypes = {
  isDisabled: PropTypes.bool,
  customClassName: PropTypes.string,
  ref: PropTypes.func,
};

export default TimePickerInput;
