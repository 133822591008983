import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import Notifications from 'components/core/CustomFields/utils/notifications';
import useSortTable from 'utils/useSortTable';

import { DEFAULT_PAGE_SIZE } from '../../utils/constants';

function useTable({
  isEnabled,
  paginator,
  fetchCustomFields,
  getCustomFieldByToken,
  toggleCustomFieldStatus,
  updatePosition,
  availableIn,
}) {
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { sortData, resetSortData, getDirection } = useSortTable();
  const { direction, column } = sortData;
  const isPaginatorPresent = !isEmpty(paginator) && paginator.totalCount > DEFAULT_PAGE_SIZE;

  function getApiSearchForm(enabled, product) {
    return {
      search_form: {
        enabled,
        product_slugs: [product || availableIn],
      },
      per_page: DEFAULT_PAGE_SIZE,
      direction,
      sort: column,
    };
  }

  function handleFetchCustomFields(enabled, product) {
    resetSortData();
    fetchCustomFields(getApiSearchForm(enabled, product))
      .catch(Notifications.customFieldFailed);
  }

  function setIsEnable() {
    handleFetchCustomFields(true);
  }

  function setIsDisable() {
    handleFetchCustomFields(false);
  }

  function handleSelectChange({ value: enabled }) {
    enabled ? setIsEnable() : setIsDisable();
  }

  function toggleEditModal() {
    setIsEditModal(!isEditModal);
  }

  function getApiStatusField(tokens, enabled) {
    return {
      custom_fields: {
        tokens: tokens.map(token => token.token || token),
        enabled,
      },
      availableIn,
    };
  }

  function handleSelectItems(items) {
    const tokens = items.map(({ token }) => ({ token }));

    setSelectedItems(tokens);
  }

  function handleClearSelectedItems() {
    setSelectedItems([]);
  }

  function handleDisableItems() {
    toggleCustomFieldStatus(
      getApiStatusField(selectedItems, false),
      handleClearSelectedItems,
    )
      .then(Notifications.customFieldUpdated)
      .catch(Notifications.customFieldFailed);
  }

  function handleEnableItems() {
    toggleCustomFieldStatus(
      getApiStatusField(selectedItems, true),
      handleClearSelectedItems,
    )
      .then(Notifications.customFieldUpdated)
      .catch(Notifications.customFieldFailed);
  }

  const handleOpenEditForm = token => () => {
    getCustomFieldByToken(token)
      .then(toggleEditModal)
      .catch(Notifications.customFieldFailed);
  };

  const handleFieldStatus = ({ token, enabled }) => () => {
    toggleCustomFieldStatus(getApiStatusField([token], !enabled))
      .then(Notifications.customFieldUpdated)
      .catch(Notifications.customFieldFailed);
  };

  const handleSortByColumn = (selectedColumn) => {
    const searchParams = getApiSearchForm(isEnabled);
    const params = {
      ...searchParams,
      sort: selectedColumn,
      direction: getDirection(selectedColumn),
    };

    fetchCustomFields(params)
      .catch(Notifications.customFieldFailed);
  };

  function handleChangePage(page) {
    const searchParams = getApiSearchForm(isEnabled);
    const params = {
      page,
      ...searchParams,
    };

    fetchCustomFields(params)
      .catch(Notifications.customFieldFailed);
  }

  function handlePositionChange(event) {
    updatePosition({ ...event, isEnabled, availableIn });
  }

  const handleChangeAvailableIn = product => () => handleFetchCustomFields(isEnabled, product);

  useEffect(() => handleFetchCustomFields(isEnabled), []);

  return {
    isEditModal,
    selectedItems,
    handleSelectItems,
    handleDisableItems,
    handleEnableItems,
    handleClearSelectedItems,
    handleChangePage,
    handleOpenEditForm,
    handleFieldStatus,
    toggleEditModal,
    handleSortByColumn,
    isPaginatorPresent,
    sort: {
      sortData,
      resetSortData,
      getDirection,
    },
    handleSelectChange,
    availableIn,
    handlePositionChange,
    handleChangeAvailableIn,
  };
}

export default useTable;
