import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/core/Label';
import Input from 'components/core/Input';
import Textarea from 'components/core/Textarea';
import withCustomField from 'components/core/CustomFields/utils/withCustomField';
import { DESCRIPTION_PLACEHOLDER } from 'components/core/CustomFields/utils/constants';
import { errorsShape, productSlugsType, touchedShape } from 'components/core/CustomFields/utils/shapes';
import { RequiredProperty } from 'components/core/CustomFields/components/FieldsData';
import AvailableInField from 'components/core/CustomFields/components/AvailableInField';
import { getError } from 'utils/forms';

import styles from '../styles.module.scss';

export function FieldInfo({
  formState,
  handleChange,
  name,
  fieldDescription,
  required,
  setFieldValue,
  productSlugs,
}) {
  function handleRequiredPropertyChange({ target }) {
    setFieldValue('required', target.value === 'true');
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <Label htmlFor="name" theme="light">Label</Label>
          <Input
            name="name"
            className={styles.input}
            value={name}
            onChange={handleChange}
            errorMessage={getError('name', formState)}
          />
        </div>
        <div className={styles.formGroup}>
          <RequiredProperty required={required} onChange={handleRequiredPropertyChange} />
        </div>
      </div>

      <AvailableInField
        setFieldValue={setFieldValue}
        productSlugs={productSlugs}
        formState={formState}
      />

      <div className={styles.formGroup}>
        <Label htmlFor="fieldDescription" theme="light">Description</Label>
        <Textarea
          placeholder={DESCRIPTION_PLACEHOLDER}
          name="fieldDescription"
          className={styles.input}
          value={fieldDescription}
          onChange={handleChange}
          errorMessage={getError('fieldDescription', formState)}
        />
      </div>
    </>
  );
}

FieldInfo.propTypes = {
  formState: PropTypes.shape({
    errors: errorsShape,
    touched: touchedShape,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  fieldDescription: PropTypes.string,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  productSlugs: productSlugsType.isRequired,
};

export default withCustomField(FieldInfo);
