import { useEffect, useState } from 'react';

function useTabs({ items, activeId, isDesktopLarge }) {
  const [activeTab, setActiveTab] = useState();

  /** Find default active item from list */
  useEffect(() => {
    if (activeId) {
      setActiveTab(items.find(item => item.id === activeId));
    }
  }, []);

  /** Reset active on mobile */
  useEffect(() => {
    if (!isDesktopLarge) {
      setActiveTab();
    }
  }, [isDesktopLarge]);

  return { activeTab, setActiveTab };
}

export default useTabs;
