import PropTypes from 'prop-types';

const customField = PropTypes.shape({
  description: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool.isRequired,
});

export default customField;
