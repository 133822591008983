import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Placeholder from './components/Placeholder';
import Modal from './components/Modal';
import Button from './components/Button';
import useButtonSlider from './utils/useButtonSlider';
import { directions } from './utils/constants';

import styles from './styles.module.scss';

function ButtonSlideGroup({
  direction,
  className,
  containerClassName,
  placeholderClassName,
  iconClassName,
  ...restProps
}) {
  const {
    option,
    options,
    onOptionClick,
    otherOptions,
    showDropdown,
    showModal,
    toggleOpen,
    optionsList,
    isLoading,
  } = useButtonSlider(restProps);

  if (isLoading) {
    return <Placeholder className={placeholderClassName} />;
  }

  const renderOption = currentOption => (
    <Button
      className={className}
      key={currentOption}
      iconClassName={iconClassName}
      option={{
        ...options[currentOption],
        value: currentOption,
      }}
      onClick={onOptionClick}
    />
  );

  return (
    <div
      className={classNames(
        styles.container,
        directions[direction],
        showDropdown && styles.containerOpen,
        containerClassName,
      )}
    >
      {renderOption(option)}
      {showDropdown && otherOptions.map(renderOption)}
      <Modal
        onOptionClick={onOptionClick}
        isOpen={showModal}
        toggleOpen={toggleOpen}
        optionsList={optionsList}
        options={options}
      />
    </div>
  );
}

ButtonSlideGroup.defaultProps = {
  direction: 'right',
  shouldFetchOnInit: false,
};

ButtonSlideGroup.propTypes = {
  direction: PropTypes.string,
  containerClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  isLoadingOption: PropTypes.bool,
  baseParams: PropTypes.shape({
    recordId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    recordType: PropTypes.string,
  }),
  onSetOption: PropTypes.func,
  initialOption: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
  handleUpdateOption: PropTypes.func.isRequired,
  shouldFetchOnInit: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default ButtonSlideGroup;
