import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ExportLookupModal from 'components/core/PageLookup/components/Export/components/ExportLookupModal';
import { currentDate } from 'utils/date';
import { PDF_ID } from 'utils/constants/export';

import { typeShape } from '../../utils/shapes';
import { extractAllEvents } from '../../utils';
import {
  DEFAULT_COLUMN_ID,
  FEDERAL_STRATEGY_SLUG_FOR_EXPORT,
  STATE_STRATEGY_SLUG_FOR_EXPORT,
  FEDERAL_EXPORT_TAB_COLUMNS,
  STATE_EXPORT_TAB_COLUMNS,
} from './constants';

function ExportHearingCalendarsModal({
  isOpenModal,
  toggleModal,
  selectedItems,
  allItems,
  type,
}) {
  const { [type]: typeNameProps } = {
    federal: {
      fileName: `Federal hearings calendar - ${currentDate()}`,
      documentTitle: 'Federal hearings calendar report',
      slugSingleExport: FEDERAL_STRATEGY_SLUG_FOR_EXPORT,
      exportTableColumns: FEDERAL_EXPORT_TAB_COLUMNS,
    },
    state: {
      fileName: `State hearings calendar - ${currentDate()}`,
      documentTitle: 'State hearings calendar report',
      slugSingleExport: STATE_STRATEGY_SLUG_FOR_EXPORT,
      exportTableColumns: STATE_EXPORT_TAB_COLUMNS,
    },
  };

  function getEventsIds() {
    const isBulkExport = isEmpty(selectedItems) && !isEmpty(allItems);

    const events = isBulkExport
      ? extractAllEvents(allItems)
      : selectedItems;

    return events.map(event => event.id);
  }

  const customApiProps = {
    'strategy_inputs[event_ids]': JSON.stringify(getEventsIds()),
  };

  return (
    <ExportLookupModal
      {...typeNameProps}
      isOpenModal={isOpenModal}
      toggleModal={toggleModal}
      customApiProps={customApiProps}
      defaultColumnsIds={[DEFAULT_COLUMN_ID]}
      defaultFileFormat={[PDF_ID]}
      withDocxCustomization
      withGovReportToPDF
    />
  );
}

ExportHearingCalendarsModal.defaultProps = {
  selectedItems: [],
  allItems: [],
};

ExportHearingCalendarsModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  allItems: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  type: typeShape.isRequired,
};

export default ExportHearingCalendarsModal;
