import React from 'react';

export const PublicRecordsContext = React.createContext();

function withPublicRecords(Component) {
  return function PublicRecordsComponent(props) {
    return (
      <PublicRecordsContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </PublicRecordsContext.Consumer>
    );
  };
}

export default withPublicRecords;
