import {
  BASE_BORDER_RIGHT_GRID,
  BASE_BORDER_TOP_GRID,
  BASE_GRID_LINES,
  BASE_OPTIONS,
  BASE_TICK,
} from 'utils/constants/charts';

export const CHART_OPTIONS = {
  ...BASE_OPTIONS,
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        maxBarThickness: 15,
        barThickness: 15,
        ticks: BASE_TICK,
      },
      BASE_BORDER_TOP_GRID,
    ],
    yAxes: [
      {
        gridLines: BASE_GRID_LINES,
        ticks: {
          ...BASE_TICK,
          suggestedMin: 0,
        },
      },
      BASE_BORDER_RIGHT_GRID,
    ],
  },
};
