import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 5,
  },
});

export default styles;
