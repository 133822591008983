import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/core/Link';
import Button from 'components/core/Button';
import { handleLinkListClick } from 'utils/table';
import { linkListLimit, remainedLinkListLimit } from 'components/core/Map/utils/constants';
import withMap from 'components/core/Map/utils/withMap';
import { parseArray } from 'components/core/Map/utils';

import styles from '../styles.module.scss';

function LinkList({ markerId, data, columnKeyName, onFetch, profileUrl }) {
  const visibleLinks = parseArray(data).slice(0, linkListLimit);
  const remainedLinksCount = visibleLinks.length - linkListLimit;
  const listLimitExceeded = visibleLinks.length > linkListLimit;
  const remainedListLimitExceeded = remainedLinksCount > remainedLinkListLimit;
  const remainedLinks = remainedListLimitExceeded ? `${remainedLinkListLimit}+` : remainedLinksCount;

  function onClick(item) {
    return handleLinkListClick({
      id: item.id,
      name: item.name,
      columnKeyName,
      onFetch,
      isTablePageView: false,
    });
  }

  const viewMoreLink = (
    <Link
      href={`${profileUrl}${markerId}`}
      theme="light"
      size="medium"
      className={classNames('marker-view', styles.link)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {remainedLinks} More
      <i className="far fa-external-link" />
    </Link>
  );

  function renderLinks() {
    return visibleLinks.map((item, index) => [
      <Button
        key={`${index}-${item.id}`}
        theme="light"
        size="small"
        onClick={onClick(item)}
        className={styles.link}
      >
        {item.name}
      </Button>,
      (index < visibleLinks.length - 1) && ', ',
    ]);
  }

  return (
    <Fragment>
      {renderLinks()}
      {listLimitExceeded && viewMoreLink}
    </Fragment>
  );
}

LinkList.propTypes = {
  data: PropTypes.string.isRequired,
  columnKeyName: PropTypes.string.isRequired,
  onFetch: PropTypes.func.isRequired,
  profileUrl: PropTypes.string.isRequired,
  markerId: PropTypes.number.isRequired,
};

export default withMap(LinkList);
