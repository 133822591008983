import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import Button from 'components/core/Button';
import { trackHelpCenterPageVisited } from 'utils/tracking';
import { openFreshChat } from 'utils/freshchat';

import { GENERAL_HELP_URL, pageHelpLinks } from './constants';
import styles from './styles.module.scss';

export default function TooltipContent({ page, pageName, isMobile, onOpenLiveSupport }) {
  const hasPageHelpCenter = pageHelpLinks[page] && pageName;
  const handleClick = visitedPage => () => {
    trackHelpCenterPageVisited(visitedPage);
  };

  const commonProps = {
    displayExternalIcon: !isMobile,
    isExternal: true,
    theme: 'light',
    className: styles.helpOption,
    iconClassName: styles.externalLinkIcon,
  };

  return (
    <div className={styles.container}>
      {hasPageHelpCenter && (
        <Link
          {...commonProps}
          href={pageHelpLinks[page]}
          onClick={handleClick('product specific help')}
        >
          {pageName} help
        </Link>
      )}
      <Link
        {...commonProps}
        href={GENERAL_HELP_URL}
        onClick={handleClick('general help')}
      >
        General help
      </Link>
      {isMobile && (
        <Button
          theme="light"
          onClick={onOpenLiveSupport}
          className={styles.helpOption}
        >
          Live support
        </Button>
      )}
    </div>
  );
}

TooltipContent.defaultProps = {
  onOpenLiveSupport: openFreshChat,
};

TooltipContent.propTypes = {
  page: PropTypes.string,
  pageName: PropTypes.string,
  isMobile: PropTypes.bool,
  onOpenLiveSupport: PropTypes.func,
};
