import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';

import { DatePickerProps } from './types';
import DatePickerInput from './components/DatePickerInput';
import Header from './components/Header';
import { setDayFormatToSingleChar, normalizeDate, normalizeHighlightDates } from './utils';
import constants from './constants';
import styles from './styles.module.scss';

const { DATE_FORMAT, MIN_DAY_FORMAT } = constants;

const DatePicker: FC<DatePickerProps> = ({
  placeholder,
  selected = null,
  highlightDates = [],
  onChange,
  onMonthChange,
  inline = false,
  className,
  forwardRef,
  iconPosition,
  dateFormat = DATE_FORMAT,
  showMonthDropdown = false,
  showYearDropdown = false,
  ...restProps
}) => {
  setDayFormatToSingleChar();
  const handleFormatWeekDay = (date: Date) => moment(date).format(MIN_DAY_FORMAT);

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => (
    <Header
      {...props}
      {...restProps}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
    />
  );
  const dayClassName = () => styles.day;

  return (
    <div className={classNames('calendar', styles.calendar, className)}>
      <ReactDatePicker
        inline={inline}
        customInput={<DatePickerInput iconPosition={iconPosition} />}
        placeholderText={placeholder}
        selected={normalizeDate(selected)}
        onChange={onChange}
        onMonthChange={onMonthChange}
        popperClassName={styles.popper}
        highlightDates={normalizeHighlightDates(highlightDates)}
        renderCustomHeader={renderCustomHeader}
        formatWeekNumber={handleFormatWeekDay}
        dayClassName={dayClassName}
        ref={forwardRef}
        dateFormat={dateFormat}
        {...restProps}
      />
    </div>
  );
};

export default DatePicker;
