import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import { isEmpty, map, size } from 'lodash';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function Bills({ bills }) {
  if (isEmpty(bills)) {
    return null;
  }

  return (
    <p>
      <span>Bills:</span>
      {map(bills, ({ id, title, billNumber, url }, index) => {
        const withComma = index !== size(bills) - 1;

        return (
          <Tooltip
            key={id}
            content={title}
            interactive
            arrow
          >
            <span className={styles.link}>
              <Link theme="link" href={url}>
                {billNumber}{withComma && ','}
              </Link>
            </span>
          </Tooltip>
        );
      })}
    </p>
  );
}

Bills.propTypes = {
  bills: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
  })),
};

export default Bills;
