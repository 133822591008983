import React from 'react';
import PropTypes from 'prop-types';

import { MapContext } from 'components/core/Map/utils/withMap';

import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';
import styles from './styles.module.scss';

function Popup({ properties, ...restProps }) {
  return properties.map(property => (
    <MapContext.Provider value={{ properties: property, ...restProps }} key={property.id}>
      <section className={styles.popup}>
        <Header />
        <Body />
        <Footer />
      </section>
    </MapContext.Provider>
  ));
}

Popup.propTypes = {
  properties: PropTypes.array.isRequired,
  onFetch: PropTypes.func.isRequired,
  profileUrl: PropTypes.string.isRequired,
};

export default Popup;
