import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const themes = {
  primary: styles.primary,
  secondary: styles.secondary,
};

function Asterisk({ show, className, symbol, theme }) {
  if (!show) return null;

  return (
    <span className={classNames(styles.asterisk, themes[theme], className)}>{symbol}</span>
  );
}

Asterisk.defaultProps = {
  show: false,
  symbol: '*',
  theme: 'primary',
};

Asterisk.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  symbol: PropTypes.string,
  theme: PropTypes.oneOf(['primary', 'secondary']),
};

export default Asterisk;
