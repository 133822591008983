import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Badge from 'components/core/Badge';

import { getStateName } from '../../../utils';
import styles from './styles.module.scss';

export default function StateBadge({ state }) {
  const stateName = getStateName(state);

  if (isEmpty(stateName)) {
    return null;
  }

  return (
    <Badge className={styles.stateBadge}>
      {stateName}
    </Badge>
  );
}

StateBadge.propTypes = {
  state: PropTypes.string,
};
