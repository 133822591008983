import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { POSITION_LOOKUP_OPTIONS } from 'components/core/ButtonPosition/utils/constants';
import { BaseOption } from 'components/core/AdvancedSearch';

function Position({ keyName, title, value, onChange, className }) {
  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      isSelect
      multiple
      title={title}
      name={keyName}
      value={value}
      defaultSelections={value}
      options={POSITION_LOOKUP_OPTIONS}
      className={classNames('form-group', className)}
      onChange={handleChange}
    />
  );
}

Position.defaultProps = {
  keyName: 'position',
  title: 'Position',
};

Position.propTypes = {
  keyName: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Position;
