import React from 'react';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { P2A_LOGIN_PRODUCTION_URL } from 'utils/constants';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Button from 'components/core/Button';
import { addFreshchatClassName } from 'utils/freshchat';

import {
  pullLayoutForProductBar,
  pushLayoutForProductBar,
  canRenderTopBar,
} from './utils';
import styles from './styles.module.scss';

function ProductBar() {
  const { isMobile } = useMediaQuery();

  if (!canRenderTopBar(isMobile)) {
    pullLayoutForProductBar();

    return null;
  }

  function onClick() {
    Cookies.set('last_govpredict_url', window.location.href);
    window.location.href = P2A_LOGIN_PRODUCTION_URL;
  }

  addFreshchatClassName('withProductBar');
  pushLayoutForProductBar();

  return (
    <div className={styles.container}>
      <Button
        theme="tableLink"
        className={styles.productButton}
        onClick={onClick}
      >
        Advocacy
      </Button>
      <Button
        theme="tableLink"
        className={classNames(styles.productButton, styles.active)}
        disabled
      >
        Intelligence
      </Button>
    </div>
  );
}

export default ProductBar;
