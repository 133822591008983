import { backgroundErrorColor, borderError } from 'components/core/Select/settings.scss';

import {
  disabledTagBackground,
  tagBackground,
  textColor,
  boxShadowColor,
  fontFamily,
  controlBackground,
  menuBoxShadow,
  borderRadius,
} from './settings.scss';

export const tagStyle = {
  multiValueLabel: (base, state) => ({
    ...base,
    color: textColor,
    fontSize: 12,
    padding: '2px 4px',
    ...(state.data.isFixed && {
      fontWeight: 'bold',
      color: 'white',
    }),
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    border: 'none',
    backgroundColor: tagBackground,
    color: textColor,
    minWidth: '20px',
    marginLeft: 8,
    borderRadius,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: tagBackground,
      color: textColor,
    },
    ...(state.data.isFixed && {
      display: 'none',
    }),
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: tagBackground,
    borderRadius,
    padding: 2,
    margin: 3,
    maxWidth: 300,
    ...(state.data.isFixed && {
      backgroundColor: disabledTagBackground,
    }),
  }),
  singleValue: base => ({
    ...base,
    padding: '0 10px',
    margin: 0,
  }),
};

const getStyle = ({ isMobile, withDisclaimer, withOverflow, isError } = {}) => ({
  ...tagStyle,
  control: base => ({
    ...base,
    ...(withOverflow && {
      maxHeight: '40vh',
      overflowY: 'auto',
    }),
    boxShadow: `inset 0 1px 3px 0 ${boxShadowColor}`,
    backgroundImage: controlBackground,
    border: 'none',
    padding: 0,
    borderRadius,
    fontWeight: 300,
    fontFamily,
    minHeight: 42,
    ...(isError && {
      background: backgroundErrorColor,
      border: borderError,
      boxShadow: 'none',
      '&:hover': {
        border: borderError,
      },
    }),
  }),
  valueContainer: base => ({
    ...base,
    padding: '1px 3px',
    minHeight: 'inherit',
  }),
  dropdownIndicator: base => ({
    ...base,
    position: 'relative',
  }),
  clearIndicator: base => ({
    ...base,
    ...(withDisclaimer && ({
      paddingRight: isMobile ? 50 : 165,
    })),
  }),
  placeholder: base => ({
    ...base,
    paddingLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingRight: 10,
    fontStyle: 'italic',
  }),
  input: base => ({
    ...base,
    paddingLeft: 8,
  }),
  menu: base => ({
    ...base,
    fontSize: 14,
    color: textColor,
    boxShadow: menuBoxShadow,
    width: '99%',
    marginLeft: '0.5%',
    border: 'none',
    fontFamily,
    fontWeight: 300,
    zIndex: 99,
    '&:after': {
      content: '""',
      position: 'absolute',
      padding: '5px',
    },
  }),
  option: base => ({
    ...base,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
});

export default getStyle;
