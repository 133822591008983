import React from 'react';
import PropTypes from 'prop-types';
import toLower from 'lodash/toLower';

import Card from 'components/core/Card';
import PartyIcon from 'components/shared/party-icon';
import { electionInfoShape } from 'components/core/Profile/utils/shapes';

import Avatar from './components/Avatar';
import Placeholder from './components/Placeholder';
import StateBadge from './components/StateBadge';
import PartyColorBar from './components/PartyColorBar';
import ViewBiography from './components/ViewBiography';
import ElectionInfo from './components/ElectionInfo';
import CustomBadge from './components/CustomBadge';
import styles from './styles.module.scss';

function LargerPhoto({
  isLoadingProfile,
  children,
  className,
  description,
  state,
  avatarUrl,
  name,
  partyShort,
  partySlug,
  biography,
  electionInfo,
  customBadge,
  customBadgeNode,
  onAvatarError,
}) {
  const partyIcon = partyShort && partySlug && (
    <PartyIcon
      partyShort={partyShort}
      partySlug={toLower(partySlug)}
      className={styles.partyIcon}
    />
  );

  const renderContent = () => {
    if (isLoadingProfile) {
      return <Placeholder />;
    }

    return (
      <div className={styles.container}>
        <PartyColorBar partyShort={partyShort} />

        <header className={styles.cardHeader}>
          <div className={styles.info}>
            <Avatar
              isLoadingProfile={isLoadingProfile}
              avatarUrl={avatarUrl}
              name={name}
              partyIcon={partyIcon}
              onError={onAvatarError}
            />

            <div className={styles.title}>
              <div className={styles.nameContainer}>
                <span className={styles.name}>{name}</span>
                {partyIcon}
                <ViewBiography biography={biography} />
              </div>
              <div className={styles.descriptionContainer}>
                <span className={styles.description}>{description}</span>
                <ElectionInfo electionInfo={electionInfo} />
              </div>
            </div>
          </div>

          <div className={styles.badges}>
            <StateBadge state={state} />
            <CustomBadge content={customBadge} />
            {customBadgeNode}
          </div>
        </header>
      </div>
    );
  };

  return (
    <Card className={className}>
      {renderContent()}
      {children}
    </Card>
  );
}

LargerPhoto.propTypes = {
  isLoadingProfile: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.node,
  state: PropTypes.string,
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  biography: PropTypes.node,
  electionInfo: electionInfoShape,
  customBadge: PropTypes.node,
  customBadgeNode: PropTypes.node,
  onAvatarError: PropTypes.func,
};

export default LargerPhoto;
