import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

export function getLink(hrefBaseUrl, value) {
  if (isEmpty(value)) {
    return null;
  }

  return (
    <Link
      href={`${hrefBaseUrl}${value}`}
      className={styles.link}
      iconClassName={styles.linkIcon}
      target="_blank"
      rel="noopener noreferrer"
      theme="light"
      isExternal
    >
      {value}
    </Link>
  );
}

export function getDescriptionLabel(label, value) {
  if (isEmpty(value)) {
    return null;
  }

  return (
    <li>
      <span>{label}:</span>{value}
    </li>
  );
}
