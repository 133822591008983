import { useState } from 'react';

import {
  defaultHighlightingFilters,
  highlightLayers,
  layers,
  mapTypeCodes,
} from './constants';

export default function useMapFilters(map) {
  const [defaultMapFilters, setDefaultMapFilters] = useState({});
  const [isDrawn, setIsDrawn] = useState(false);

  function resetFilters() {
    highlightLayers.forEach((layer) => {
      map.setFilter(layer, defaultHighlightingFilters);
    });
  }

  function getFilter(activeTypeFilter) {
    return ['==', ['get', 'type'], activeTypeFilter];
  }

  function setFilters(activeTypeFilter = mapTypeCodes.none) {
    const filter = getFilter(activeTypeFilter);

    let filters = { ...defaultMapFilters };

    layers.forEach((layer) => {
      const activeFilter = map.getFilter(layer);

      if (!isDrawn && !filters[layer] && activeFilter) {
        filters = {
          ...filters,
          [layer]: activeFilter,
        };
      }

      const validFilters = [filters[layer], filter].filter((item) => !!item);

      setIsDrawn(true);

      if (map) {
        map.setFilter(layer, ['all', ...validFilters]);
      }
    });

    setDefaultMapFilters(filters);
  }

  return {
    resetFilters,
    setFilters,
  };
}
