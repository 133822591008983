import React from 'react';
import { isEmpty, map } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { joinCompact } from 'utils/string';

import styles from './styles.module.scss';

export default function Biography({ person }) {
  const {
    facts,
    interests,
    narrativeBiography,
  } = person || {};

  const { infoText } = narrativeBiography || {};

  const renderBiographySection = (title, data, textKey, separator) => {
    if (isEmpty(data)) return null;

    const itemsList = map(data, ({ [textKey]: text }) => text);

    return (
      <>
        <b className={styles.sectionTitle}>
          {title}
        </b>
        <p className={styles.sectionText}>
          {joinCompact(itemsList, separator)}
        </p>
      </>
    );
  };

  return (
    <div className={styles.biography}>
      {!isEmpty(infoText) && <p dangerouslySetInnerHTML={{ __html: infoText }} />}
      {renderBiographySection('Interests', interests, 'description')}
      {renderBiographySection('Other facts', facts, 'text', '\n')}
    </div>
  );
}

Biography.propTypes = {
  person: personShape,
};
