/**
 * Generate name initials
 * e.g., Test -> T, Test Testeson -> TT, Test Testeson Junior -> TT
 *
 * @export
 * @param {string} name - name to extract initials from
 * @returns {string} initials
 */

export function getInitials(name = '') {
  const excludedTerms = ['of', 'Rep.', 'Sen.', 'Assemb.'];
  const regexp = new RegExp(excludedTerms.join('|'), 'g');

  const pureName = name
    .replace(/[^a-zA-Z ]/g, '')
    .replace(regexp, '')
    .replace(/\s{1,}/g, ' ').trim();

  return pureName.split(' ')
    .map(n => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
}
