import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import TippyTooltip from '@tippyjs/react';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { settingsButtonOptionsShape } from './shapes';
import styles from './styles.module.scss';

export default function SettingsButton({ title, options, href, className }) {
  const { isDesktopLarge } = useMediaQuery();

  function getMenuItem(option, index) {
    const { label, url, onClick } = option;

    if (onClick) {
      return (
        <Button
          key={`${index}-${label}`}
          theme="light"
          className={styles.item}
          onClick={onClick}
        >
          {label}
        </Button>
      );
    }

    return (
      <Link
        key={`${index}-${label}`}
        theme="light"
        className={styles.item}
        href={url}
      >
        {label}
      </Link>
    );
  }

  const getTooltipMenu = () => (
    <div className={styles.container}>
      <div className={styles.content}>
        <header className={styles.title}>{title}</header>
        {options.map(getMenuItem)}
      </div>
    </div>
  );

  if (isEmpty(options)) {
    return (
      <Link
        theme="secondary"
        className={classNames(styles.settingsButton, className)}
        href={href}
      >
        <i className="fas fa-cogs" />
        {isDesktopLarge && 'Settings'}
      </Link>
    );
  }

  return (
    <div className={className}>
      <TippyTooltip
        content={getTooltipMenu()}
        theme="light"
        placement="bottom-end"
        trigger="click"
        interactive
      >
        <div className={styles.trigger}>
          <Button
            theme="secondary"
            className={styles.settingsButton}
          >
            <i className="fas fa-cogs" />
            {isDesktopLarge && 'Settings'}
          </Button>
        </div>
      </TippyTooltip>
    </div>
  );
}

SettingsButton.defaultProps = {
  title: 'Settings',
};

SettingsButton.propTypes = {
  title: PropTypes.string,
  options: settingsButtonOptionsShape,
  href: PropTypes.string,
  className: PropTypes.string,
};

export { settingsButtonOptionsShape };
