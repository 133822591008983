const TITLE_SECTION_ID = 'title';
const DATE_SECTION_ID = 'date';
const TIME_SECTION_ID = 'time';
const COMMITTEE_SECTION_ID = 'committee';
const LOCATION_SECTION_ID = 'location';
const STATE_SECTION_ID = 'state';
const CHAMBER_SECTION_ID = 'chamber';
const BILLS_SECTION_ID = 'bills';
const LINK_SECTION_ID = 'link';

export const DEFAULT_COLUMN_ID = TITLE_SECTION_ID;
export const FEDERAL_STRATEGY_SLUG_FOR_EXPORT = 'committee_hearings';
export const STATE_STRATEGY_SLUG_FOR_EXPORT = 'state_committee_hearings';

export const FEDERAL_EXPORT_TAB_COLUMNS = [
  { id: 'select_all', title: 'Select/Deselect all', isSelectAll: true },
  { id: DATE_SECTION_ID, title: 'Date' },
  { id: TIME_SECTION_ID, title: 'Time' },
  { id: DEFAULT_COLUMN_ID, title: 'Title', isDisabled: true },
  { id: COMMITTEE_SECTION_ID, title: 'Committee' },
  { id: LOCATION_SECTION_ID, title: 'Location' },
  { id: LINK_SECTION_ID, title: 'Link' },
];

export const STATE_EXPORT_TAB_COLUMNS = [
  { id: 'select_all', title: 'Select/Deselect all', isSelectAll: true },
  { id: DATE_SECTION_ID, title: 'Date' },
  { id: TIME_SECTION_ID, title: 'Time' },
  { id: DEFAULT_COLUMN_ID, title: 'Title', isDisabled: true },
  { id: COMMITTEE_SECTION_ID, title: 'Committee' },
  { id: LOCATION_SECTION_ID, title: 'Location' },
  { id: STATE_SECTION_ID, title: 'State' },
  { id: CHAMBER_SECTION_ID, title: 'Chamber' },
  { id: BILLS_SECTION_ID, title: 'Bills' },
  { id: LINK_SECTION_ID, title: 'Link' },
];
