import moment from 'moment';

import { getDate, formatMonthDayYear } from 'utils/date';

export const transformTimeForUI = (commentDate) => {
  const dateDiffFromNow = moment.duration(moment() - getDate(commentDate));

  if (dateDiffFromNow.days()) {
    return formatMonthDayYear(commentDate);
  }

  if (dateDiffFromNow.hours()) {
    return `${dateDiffFromNow.hours()}h`;
  }

  if (dateDiffFromNow.minutes()) {
    return `${dateDiffFromNow.minutes()}m`;
  }

  return 'now';
};
