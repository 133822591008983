import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

export default function Content({ header, title, children, onClick, className }) {
  const editButton = onClick && (
    <Fragment>
      (
      <Button theme="light" className={styles.link} onClick={onClick}>
        Edit {title}
      </Button>
      )
    </Fragment>
  );

  return (
    <section className={styles.container}>
      {header && (
        <header className={styles.header}>
          <p className={styles.infoText}>
            {header} {editButton}
          </p>
        </header>
      )}

      <div className={classNames(styles.content, className)}>{children}</div>
    </section>
  );
}

Content.defaultProps = {
  title: 'section',
};

Content.propTypes = {
  title: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
