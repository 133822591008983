import isEmpty from 'lodash/isEmpty';

export function getBackToParams({ backTo, backToQuery, isFirstParam = false }) {
  if (isEmpty(backTo)) {
    return '';
  }

  const backToQueryParam = isEmpty(backToQuery) ? '' : `&${backToQuery}`;
  const backToParam = `backTo=${backTo}${backToQueryParam}`;

  return isFirstParam ? `?${backToParam}` : `&${backToParam}`;
}
