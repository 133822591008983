import GlobalNotifier from 'components/GlobalNotifier';

const addNotification = GlobalNotifier.addNotification;

const error = message => addNotification({ message, level: 'error' });
const success = message => addNotification({ message, level: 'success' });

export default class Notifications {
  static defaultSavedItemSuccess = () => success('Recent search was successfully saved.');
  static defaultSavedItemFailed = () => error('Could not save recent search. Please try again.');
  static defaultRemoveSavedItemFailed = () => error('Could not remove saved search. Please try again.');
  static defaultRemoveSavedItemSuccess = () => success('Saved search removed successfully.');
  static exportEventSuccess = () => success('Data was exported successfully.');
  static exportEventFailed = () => error('Data export failed. Please try again.');
}
