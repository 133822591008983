import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import truncate from 'lodash/truncate';

import {
  PREVIEW_TITLE_LENGTH,
} from 'components/core/ExportDocument/components/ExportModal/utils/constants';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { DEFAULT_LOGO_URL } from 'utils/constants';

import styles from './styles.module.scss';

function ReportTitlePreview({ logoUrl, documentTitle, children }) {
  const truncatedTitle = truncate(documentTitle, { length: PREVIEW_TITLE_LENGTH });
  const currentDate = moment().format(GLOBAL_DATE_FORMAT);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logoImage} src={logoUrl} alt="logo-custom" />

        <div className={styles.reportTitle}>
          <p className={styles.currentDate}>Report ({currentDate})</p>
          <h2 className={styles.title}>{truncatedTitle}</h2>
        </div>
      </div>

      {children}
    </div>
  );
}

ReportTitlePreview.defaultProps = {
  logoUrl: DEFAULT_LOGO_URL,
};

ReportTitlePreview.propTypes = {
  children: PropTypes.node,
  documentTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

export default ReportTitlePreview;
