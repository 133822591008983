import { toLower, identity, replace } from 'lodash';

import { isURL } from 'utils/url';
import { getDirectMailLink, getPhoneLink } from 'utils/socialMedia/links';
import { EMAIL_TYPE, FAX_TYPE, PHONE_TYPE } from 'components/core/Profile/utils/constants';

const formatters = {
  [EMAIL_TYPE]: getDirectMailLink,
  [PHONE_TYPE]: getPhoneLink,
  [FAX_TYPE]: getPhoneLink,
};

export const formatUrl = ({ url, type }) => {
  if (isURL(url)) { return url; }

  const typeWithoutIndex = replace(type, / [0-9]/, '');
  const formatter = formatters[toLower(typeWithoutIndex)] || identity;

  return formatter(url);
};
