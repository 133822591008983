import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import withProfile from 'components/State/CommitteeProfile/utils/withProfile';
import DataCard from 'components/core/Profile/components/DataCard';
import { CHART_COLORS } from 'utils/constants/charts';
import { objectMap } from 'utils/object';

import PartyLegend from './components/PartyLegend';
import { CHART_OPTIONS } from './constants';
import styles from './styles.module.scss';

function PartyBreakdown({ members, title, description, emptyDescription, showOthers, isMobile }) {
  const { republicans, democrats, others } = objectMap(members, partyMembers => partyMembers.length);

  const padWith = (array, value) => ([value, value, ...array, value, value, ...isMobile ? [value] : []]);
  const withOthers = value => (showOthers ? [value] : []);

  const labels = [
    'Republican',
    'Democratic',
    ...withOthers('Other'),
  ];

  const datasetData = [
    republicans,
    democrats,
    ...withOthers(others),
  ];

  const backgroundColor = [
    CHART_COLORS.REPUBLICAN_BACKGROUND_COLOR,
    CHART_COLORS.DEMOCRATIC_BACKGROUND_COLOR,
    ...withOthers(CHART_COLORS.OTHER_BACKGROUND_COLOR),
  ];

  /** Add empty data to adjust the space between bars */
  const data = {
    labels: padWith(labels, ''),
    datasets: [{
      data: padWith(datasetData, 0),
      backgroundColor: padWith(backgroundColor, 'transparent'),
    }],
  };

  const showPlaceholder = (republicans + democrats + others) === 0;

  return (
    <DataCard
      title={title}
      description={description}
      className={styles.dataCard}
      showPlaceholder={showPlaceholder}
      emptyDescription={emptyDescription}
    >
      <div className={styles.container}>
        <div className={styles.chartContainer}>
          <Bar options={CHART_OPTIONS} data={data} />
        </div>
        <PartyLegend
          republicans={republicans}
          democrats={democrats}
          others={others}
          showOthers={showOthers}
        />
      </div>
    </DataCard>
  );
}

PartyBreakdown.defaultProps = {
  title: 'Party breakdown',
  description: '',
  emptyDescription: '',
  members: {
    republicans: [],
    democrats: [],
    others: [],
  },
  showOthers: true,
};

PartyBreakdown.propTypes = {
  members: PropTypes.shape({
    republicans: PropTypes.array,
    democrats: PropTypes.array,
    others: PropTypes.array,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  emptyDescription: PropTypes.string,
  showOthers: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default withProfile(PartyBreakdown);
