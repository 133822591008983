import PropTypes from 'prop-types';
import { map } from 'lodash';

import { FILE_ITEMS } from 'utils/constants/export';

export const customFileFormatsShape = PropTypes.arrayOf(
  PropTypes.oneOf(map(FILE_ITEMS, 'id'))
);

export const customFileFormatItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })
);
