import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Column } from 'components/core/Table';
import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';

import styles from '../styles.module.scss';

function VoteNumberColumn({ keyName, item, trackingActions }) {
  const { voteNumber, voteNumberUrl } = item || {};
  const { trackVotingRecordVoteVisited } = trackingActions || {};
  const handleVoteClick = () => (
    trackVotingRecordVoteVisited &&
      trackVotingRecordVoteVisited(voteNumber)
  );

  function renderVoteNumber() {
    if (isEmpty(voteNumber)) {
      return EMPTY_VALUE;
    }

    if (isEmpty(voteNumberUrl)) {
      return <div>{voteNumber}</div>;
    }

    return (
      <Link className={styles.voteLink} theme="default" href={voteNumberUrl} onClick={handleVoteClick}>
        {voteNumber}
      </Link>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderVoteNumber}</Column>
  );
}

VoteNumberColumn.defaultProps = {
  item: {},
};

VoteNumberColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  trackingActions: PropTypes.object,
  item: PropTypes.object,
};

export default VoteNumberColumn;
