import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { FEDERAL_BILLS_LABELS } from 'components/state-leg-watchlist/constants';
import Button from 'components/core/Button';

import { HIGHLIGHT_COLOR } from '../../utils/constants';
import { colorScale } from '../../utils';
import styles from './styles.module.scss';

function FederalBillsLegend({
  isSelectedFederalBills,
  federalBillsCount,
  limits,
  mapColors,
  onFilterFederalBills,
}) {
  if (isNil(federalBillsCount)) {
    return null;
  }

  const style = {
    background: isSelectedFederalBills ? HIGHLIGHT_COLOR : colorScale(federalBillsCount, limits, mapColors),
  };

  function filterFederal() {
    if (federalBillsCount > 0 && onFilterFederalBills) {
      onFilterFederalBills();
    }
  }

  return (
    <div className={styles.federal}>
      <p className={styles.text}>{FEDERAL_BILLS_LABELS}</p>
      <Button
        style={style}
        className={styles.button}
        onClick={filterFederal}
      >
        {federalBillsCount}
      </Button>
    </div>
  );
}

FederalBillsLegend.propTypes = {
  isSelectedFederalBills: PropTypes.bool,
  federalBillsCount: PropTypes.number,
  limits: PropTypes.arrayOf(PropTypes.number).isRequired,
  mapColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterFederalBills: PropTypes.func,
};

export default FederalBillsLegend;
