import React from 'react';
import PropTypes from 'prop-types';

import ListLinks from './components/ListLinks';

function Links({ links }) {
  return (
    links.map(({ label, value }) => (
      <ListLinks
        key={label}
        label={label}
        value={value}
      />
    ))
  );
}

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
};

export default Links;
