import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import { TYPES } from 'components/Stakeholder/Lookup/components/Options/utils/constants';
import { queryStringify } from 'utils/query-string';
import getDistrictFilters from 'components/Stakeholder/common/utils/getDistrictFilters';

import styles from '../../styles.module.scss';

export default function LookupLink({
  districtName,
  districtId,
  chamberCategory,
  state,
  stateName,
  isFederalSenator,
}) {
  const anyFilterValue = districtId || (state && isFederalSenator);

  if (!anyFilterValue) return null;

  const { options } = getDistrictFilters({
    chamberCategory,
    districtId,
    districtName,
    isFederalSenator,
    state,
    stateName,
  });
  const filterParam = queryStringify({
    filters: JSON.stringify(options),
  });

  return (
    <Link
      href={`/stakeholder_management/directory?${filterParam}`}
      theme="default"
      className={styles.lookupLink}
    >
      View in lookup
    </Link>
  );
}

LookupLink.propTypes = {
  districtId: PropTypes.node,
  districtName: PropTypes.string,
  chamberCategory: PropTypes.oneOf(
    Object.keys(TYPES),
  ),
  state: PropTypes.string,
  stateName: PropTypes.string,
  isFederalSenator: PropTypes.bool,
};
