import React from 'react';

export const HearingCalendarContext = React.createContext();

function withCalendar(Component) {
  return function CalendarDataComponent(props) {
    return (
      <HearingCalendarContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </HearingCalendarContext.Consumer>
    );
  };
}

export default withCalendar;
