import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';

import constants from './constants';

const commonEventPropTypes = {
  id: stringNumberType,
  committeeId: stringNumberType,
  committeeName: PropTypes.string,
  committeeUrl: PropTypes.string,
  committeeType: PropTypes.string,
  scheduledDate: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  url: PropTypes.string,
  addToCalendarUrl: PropTypes.string,
  isChecked: PropTypes.bool,
};

const billShape = PropTypes.shape({
  id: PropTypes.number,
  billNumber: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
});

export const eventShape = PropTypes.shape(commonEventPropTypes);

export const stateEventShape = PropTypes.shape({
  ...commonEventPropTypes,
  chamber: PropTypes.string,
  state: PropTypes.string,
  bills: PropTypes.arrayOf(billShape),
});

export const typeShape = PropTypes.oneOf([constants.FEDERAL, constants.STATE]);
