import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, filter, some } from 'lodash';

import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';
import { getMyFeed } from 'components/Dashboard/utils';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

import styles from '../styles.module.scss';

// TODO: remove once workspaces are out of beta
const initialSelectedFeeds = gon.isWorkspacesEnabled ? [] : [getMyFeed()];

function UntrackModal({
  isOpenModal,
  toggleModal,
  onRemove,
  isLoading,
  title,
  description,
  trackings,
  hasTrackings,
  withWorkspacesOptions,
}) {
  const [selectedFeeds, setSelectedFeeds] = useState(initialSelectedFeeds);

  useEffect(() => {
    isOpenModal && setSelectedFeeds(initialSelectedFeeds);
  }, [isOpenModal]);

  const handleSelectFeed = ({ target }) => {
    const selectedFeed = { name: target.name, token: target.value };

    const selectFeed = () => setSelectedFeeds(prevSelection => [...prevSelection, selectedFeed]);
    const removeFeed = () => setSelectedFeeds(
      prevSelection => filter(prevSelection, ({ token }) => token !== selectedFeed.token)
    );

    target.checked ? selectFeed() : removeFeed();
  };

  const handleConfirmation = () => onRemove(selectedFeeds);

  const buttons = (
    <>
      <Button
        theme="light"
        size="small"
        onClick={toggleModal}
      >
        Cancel
      </Button>
      <Button
        size="small"
        onClick={handleConfirmation}
        isLoading={isLoading}
        disabled={hasTrackings && isEmpty(selectedFeeds)}
      >
        Confirm
      </Button>
    </>
  );

  const renderFeedItem = ({ feed }) => {
    const isChecked = some(selectedFeeds, ({ token }) => token === feed.token);

    return (
      <Checkbox
        key={feed.token}
        name={feed.name}
        label={feed.name}
        value={feed.token}
        isChecked={isChecked}
        className={styles.checkbox}
        onChange={handleSelectFeed}
      />
    );
  };

  return (
    <Modal
      title={title}
      isOpen={isOpenModal}
      className={styles.modal}
      footerClassName={styles.footer}
      bodyClassName={styles.modalBody}
      toggleOpen={toggleModal}
      buttons={buttons}
      theme="scrollableBody"
    >
      <p>
        {description}
      </p>
      {withWorkspacesOptions && (
        <LabelGroup>
          {map(trackings, renderFeedItem)}
        </LabelGroup>
      )}
    </Modal>
  );
}

UntrackModal.defaultProps = {
  title: 'Untrack item',
  description: 'Are you sure you would like to untrack this item? Doing so will also remove it from your tracked items tab. You will also no longer receive email updates for this item. Any feed item previously populated will not be removed.',
};

UntrackModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  trackings: PropTypes.arrayOf(trackingShape),
  hasTrackings: PropTypes.bool.isRequired,
  withWorkspacesOptions: PropTypes.bool.isRequired,
};

export default UntrackModal;
