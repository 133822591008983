import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';

import { extractKeywords } from 'redux/news/common/utils';
import Button from 'components/core/Button';
import { Placeholder, StoryFeedCard } from 'components/core/News';
import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import { feedItemShape } from 'components/News/utils/shapes';

import withNews from '../utils/withNews';
import styles from './styles.module.scss';

function List({
  isLoading,
  showPreview,
  isMobile,
  isDesktop,
  showCluster,
  onShowCluster,
  onHideCluster,
  items,
  isEmptySearch,
  keywordSearch,
  bulkSaveTags,
  updateTags,
  isLoadingTags,
  shareToMyFeed,
  isSharingToMyFeed,
  isLoadingMyFeedDocuments,
}) {
  function renderCard(card) {
    if (isEmpty(card)) {
      return null;
    }

    const { id: cardId, tags } = card;
    const selectedFeedKeywords = keywordSearch ? extractKeywords(keywordSearch) : [];

    function handleSaveTags(values) {
      return isEmpty(tags)
        ? bulkSaveTags({ ...values, ids: [cardId] })
        : updateTags({ ...values, id: cardId });
    }

    function handleShareToMyFeed() {
      return shareToMyFeed(cardId);
    }

    return (
      <li key={`story-card-${cardId}`}>
        <StoryFeedCard
          card={card}
          showPreview={showPreview}
          isMobile={isMobile}
          isDesktop={isDesktop}
          displayCheckbox={false}
          displayShowRelated={false}
          isExternalComponent
          onShowCluster={onShowCluster}
          selectedFeedKeywords={selectedFeedKeywords}
          onSaveTags={handleSaveTags}
          isLoadingTags={isLoadingTags}
          onShareToMyFeed={handleShareToMyFeed}
          isSharingToMyFeed={isSharingToMyFeed}
          isLoadingMyFeedDocuments={isLoadingMyFeedDocuments}
        />
      </li>
    );
  }

  const hideClusterButton = (
    <Button
      theme="light"
      onClick={onHideCluster}
      customIcon={<i className="fas fa-compress-alt" />}
      iconClassName={styles.icon}
      className={styles.hideClusterButton}
    >
      Hide more coverage
    </Button>
  );

  function renderContent() {
    if (isLoading) {
      return <Placeholder />;
    }

    if (isEmptySearch) {
      return <EmptyPlaceholder description="There are no news stories matching this keyword search." />;
    }

    return (
      <>
        {showCluster && hideClusterButton}
        {map(items, renderCard)}
      </>
    );
  }

  return (
    <ul className={styles.list}>
      {renderContent()}
    </ul>
  );
}

List.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  showCluster: PropTypes.bool.isRequired,
  onShowCluster: PropTypes.func.isRequired,
  onHideCluster: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(feedItemShape).isRequired,
  isEmptySearch: PropTypes.bool.isRequired,
  keywordSearch: PropTypes.string,
  bulkSaveTags: PropTypes.func.isRequired,
  updateTags: PropTypes.func.isRequired,
  isLoadingTags: PropTypes.bool.isRequired,
  shareToMyFeed: PropTypes.func.isRequired,
  isSharingToMyFeed: PropTypes.bool.isRequired,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
};

export default withNews(List);
