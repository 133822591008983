import { toggleLayer, addMapSource } from './index';
import {
  veryHigh,
  high,
  mediumHigh,
  medium,
  mediumLow,
  low,
  veryLow,
} from './settings.scss';

function addHeatMapLayer(sourceName, map, isHeatmapChecked) {
  map.addLayer({
    id: 'heatmap',
    type: 'heatmap',
    source: sourceName,
    layout: {
      visibility: isHeatmapChecked ? 'visible' : 'none',
    },
    paint: {
      'heatmap-weight': ['interpolate', ['linear'], ['zoom'], 0, 3, 22, 1],
      'heatmap-intensity': 0.1,
      'heatmap-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        30,
        3.6,
        45,
        8.79,
        90,
        11.91,
        130,
        13.98,
        150,
        22,
        30,
      ],
      'heatmap-opacity': 0.61,
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(0, 0, 255, 0)',
        0.1,
        veryLow,
        0.3,
        low,
        0.4,
        mediumLow,
        0.5,
        medium,
        0.7,
        mediumHigh,
        0.85,
        high,
        1,
        veryHigh,
      ],
    },
  });
}

export function setHeatMap(sourceName, data, map, isHeatmapChecked) {
  map.on('load', () => {
    addMapSource(sourceName, data, map, false);
    addHeatMapLayer(sourceName, map, isHeatmapChecked);
  });
}

export function onToggleHeatMap(map) {
  toggleLayer(map, 'clusters');
  toggleLayer(map, 'cluster-count');
  toggleLayer(map, 'unclustered-point');
  toggleLayer(map, 'heatmap');
}
