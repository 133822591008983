import React from 'react';
import { map, isEmpty } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getBranchContent } from 'components/core/Profile/components/Cards/MilitaryService/utils';

import TableSection from '../../TableSection';

function MilitaryService({ person }) {
  const { militaryService } = person || {};

  if (isEmpty(militaryService)) {
    return null;
  }

  const tables = [
    map(militaryService, (militaryBranch, index) => ({
      label: `Military branch ${index + 1}`,
      value: getBranchContent(militaryBranch),
    })),
  ];

  return (
    <TableSection
      title="Military service"
      tables={tables}
    />
  );
}

MilitaryService.propTypes = {
  person: personShape,
};

export default MilitaryService;
