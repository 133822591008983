import React from 'react';
import PropTypes from 'prop-types';

import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { getMyFeed } from 'components/Dashboard/utils';
import ButtonTagManager from 'components/core/ButtonTagManager';
import ButtonShare from 'components/core/ButtonShare';

import InfoTab from '../InfoTab';
import styles from './styles.module.scss';

export default function ActionsTab({
  documentId,
  documentTitle,
  documentType,
  documentTypeLabel,
  documentUrl,
  tags,
  onDocumentShared,
  onTagsSaved,
}) {
  // TODO: adjust once we have share to workspaces
  const onShareSuccess = () => onDocumentShared([getMyFeed()]);

  const renderActions = () => (
    <div>
      <ButtonShare
        size="small"
        theme="secondary"
        tooltipPlacement="bottom-end"
        recordId={documentId}
        recordType={documentType}
        itemType={documentTypeLabel}
        itemName={documentTitle}
        itemUrl={documentUrl}
        className={styles.shareButton}
        onSuccess={onShareSuccess}
      />

      <ButtonTagManager
        autoload
        size="small"
        theme="secondary"
        tags={tags}
        recordId={documentId}
        recordType={documentType}
        itemType={documentTypeLabel}
        buttonClassName={styles.tagButton}
        updateDataItem={onTagsSaved}
      />
    </div>
  );

  return <InfoTab label="Actions" renderValue={renderActions} />;
}

ActionsTab.propTypes = {
  documentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  documentTitle: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  documentTypeLabel: PropTypes.string.isRequired,
  documentUrl: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  onDocumentShared: PropTypes.func.isRequired,
  onTagsSaved: PropTypes.func.isRequired,
};
