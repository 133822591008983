import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useListener } from 'react-bus';
import { some, every, isEmpty } from 'lodash';

import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import theme from 'components/core/TagInput/theme';
import TagInput from 'components/core/TagInput';
import Select from 'components/core/Select';

import styles from './styles.module.scss';

const REQUEST_CONTENT_TYPE = 'application/json';

function AddedDraggableItem({
  bus,
  data,
  addons,
  message,
  withSource,
  sourceMultiValueLabel,
  sourceOption,
  replaceOldNode,
  parentComponentData,
  defaultComponentData,
  hideFirstOptionNode,
  firstOptionOptions,
  firstOptionPlaceholder,
  firstOptionTransform,
  secondOptionMultiSelect,
  secondOptionOptions,
  secondOptionStrategy,
  secondOptionSuggester,
  secondOptionReqMethod,
  secondOptionReqTransform,
  secondOptionResTransform,
  secondOptionTransform,
  secondOptionAsyncCreatable,
  secondOptionCustomMessage,
  secondOptionCustomProps,
  transformData,
  updateData,
  onFocus,
  pagination,
  secondaryOptionRender,
  customInstantValidator,
  customSubmitValidator,
}) {
  const baseComponentData = useState(defaultComponentData);
  const [componentData, setComponentData] = parentComponentData || baseComponentData;
  const suggester = secondOptionSuggester && secondOptionSuggester({ componentData });
  const { firstOption, secondOption } = componentData || {};
  const { value: firstOptionValue } = firstOption || {};
  const { type } = replaceOldNode || {};

  const defaultErrorData = { errorFirstOption: undefined, errorSecondOption: undefined };
  const [errors, setErrors] = useState(defaultErrorData);
  const { errorFirstOption, errorSecondOption } = errors || {};

  useEffect(() => {
    customInstantValidator && customInstantValidator({ firstOption, secondOption });
  }, [firstOptionValue, secondOption]);

  useListener('submitting', useCallback(() => {
    const isEmptyFirst = isEmpty(firstOptionValue);
    const isEmptySecond = !isEmptyFirst && firstOptionValue !== 'any' && isEmpty(secondOption);
    const validatorErrors = {
      ...customSubmitValidator && customSubmitValidator({ firstOption, secondOption }),
      ...isEmptyFirst && { errorFirstOption: true },
      ...isEmptySecond && { errorSecondOption: true },
    };

    if (!isEmpty(validatorErrors)) {
      setErrors(validatorErrors);
      bus.emit('errors', true);
    }
  }, [firstOptionValue, secondOption]));

  const secondOptionType = (type !== 'statement' && !some(firstOptionOptions, ['value', 'in'])) && {
    asyncCreatable: !!suggester && secondOptionAsyncCreatable,
    creatable: !suggester,
    uniqueIdValue: false,
  };

  useEffect(() => {
    if (!every(componentData, property => isEmpty(property))) {
      const replaceNewNode = transformData({ componentData });

      updateData({
        payload: JSON.parse(
          JSON.stringify(data).replace(
            JSON.stringify(replaceOldNode),
            JSON.stringify(replaceNewNode),
          ),
        ),
      });
    }
  }, [componentData]);

  const transformProps = item => ({ componentData, item });

  const handleFirstOption = (item) => {
    onFocus('errorSecondOption', setErrors)();
    setComponentData(firstOptionTransform(transformProps(item)));
  };

  const handleSecondOption = item => setComponentData(secondOptionTransform(transformProps(item)));

  const componentFirstOptionNode = !hideFirstOptionNode && (
    <section className={styles.selector}>
      <Select
        value={firstOptionValue}
        options={firstOptionOptions}
        placeholder={firstOptionPlaceholder}
        onChange={handleFirstOption}
        onFocus={onFocus('errorFirstOption', setErrors)}
        className={classNames(errorFirstOption && styles.invalid)}
        searchable={false}
        clearable={false}
        hasNewStyle
      />
    </section>
  );

  const getSecondaryOptionNode = () => {
    if (isEmpty(firstOptionValue) || firstOptionValue === 'any') return null;

    const commonProps = {
      value: secondOption,
      onChange: handleSecondOption,
      onFocus: onFocus('errorSecondOption', setErrors),
      className: classNames(errorSecondOption && styles.invalid),
    };
    const suggesterProps = {
      name: 'lists',
      createOptionPosition: 'first',
      uri: suggester,
      overrideValue: secondOption,
      customMultiValueLabel: withSource && sourceMultiValueLabel,
      customOption: withSource && sourceOption,
      strategy: secondOptionStrategy,
      reqContentType: REQUEST_CONTENT_TYPE,
      reqTransform: secondOptionReqTransform,
      resTransform: secondOptionResTransform,
      reqMethod: secondOptionReqMethod,
      pagination,
      allowCreateWhileLoading: true,
      shouldOverrideValue: true,
      firstOptionValue,
    };
    const customNode = secondaryOptionRender && secondaryOptionRender({
      ...commonProps,
      ...suggesterProps,
    });

    if (customNode) return customNode;

    return (
      <section className={styles.value}>
        {secondOptionOptions && (
          <>
            <Select
              {...commonProps}
              {...secondOptionCustomProps}
              options={secondOptionOptions}
              customTheme={theme()}
              searchable={false}
              multi={secondOptionMultiSelect}
            />
            {secondOptionCustomMessage}
          </>
        )}

        {!secondOptionOptions && (
          <>
            <TagInput
              {...secondOptionType}
              {...commonProps}
              {...suggesterProps}
              {...secondOptionCustomProps}
            />
            {secondOptionCustomMessage}
          </>
        )}
      </section>
    );
  };
  const componentSecondOptionNode = getSecondaryOptionNode();

  return (
    <div className={styles.container}>
      <div className={styles.base}>
        <span id="baseMessage">{message}</span> {componentFirstOptionNode} {componentSecondOptionNode}
      </div>

      {addons && (
        <div className={styles.addons}>
          {addons}
        </div>
      )}
    </div>
  );
}

AddedDraggableItem.defaultProps = {
  secondOptionReqMethod: 'POST',
  secondOptionAsyncCreatable: true,
  hideFirstOptionNode: false,
  secondOptionMultiSelect: true,
};

AddedDraggableItem.propTypes = {
  bus: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  addons: PropTypes.object,
  message: PropTypes.string.isRequired,
  withSource: PropTypes.bool,
  parentComponentData: PropTypes.array,
  replaceOldNode: PropTypes.object.isRequired,
  defaultComponentData: PropTypes.object.isRequired,
  hideFirstOptionNode: PropTypes.bool,
  firstOptionOptions: PropTypes.array.isRequired,
  firstOptionPlaceholder: PropTypes.string.isRequired,
  firstOptionTransform: PropTypes.func.isRequired,
  secondOptionOptions: PropTypes.array,
  secondOptionMultiSelect: PropTypes.bool,
  secondOptionStrategy: PropTypes.string,
  secondOptionSuggester: PropTypes.func,
  secondOptionReqMethod: PropTypes.string,
  secondOptionReqTransform: PropTypes.func,
  secondOptionResTransform: PropTypes.func,
  secondOptionTransform: PropTypes.func.isRequired,
  secondOptionAsyncCreatable: PropTypes.bool,
  secondOptionCustomProps: PropTypes.object,
  secondOptionCustomMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  transformData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  sourceMultiValueLabel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  sourceOption: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  secondaryOptionRender: PropTypes.func,
  customInstantValidator: PropTypes.func,
  customSubmitValidator: PropTypes.func,
};

export default withSmartSearch(AddedDraggableItem);
