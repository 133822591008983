import React from 'react';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';

import EmptyPlaceholder from 'components/core/EmptyPlaceholder';

import withCalendar from '../../utils/withCalendar';
import constants from '../../utils/constants';
import { typeShape } from '../../utils/shapes';
import DayEvents from './components/DayEvents';
import ListEventsPlaceholder from './components/Placeholder';

function ListEvents({ isLoadingEvents, dayEvents, emptyPlaceholderDescription, type }) {
  const isFederal = type === constants.FEDERAL;

  if (isLoadingEvents) {
    return <ListEventsPlaceholder isFederal={isFederal} />;
  }

  if (size(dayEvents) === 0) {
    const description = <div>{emptyPlaceholderDescription}</div>;

    return <EmptyPlaceholder description={description} />;
  }

  return map(dayEvents, (dayEvent, index) => (
    <DayEvents
      key={`${dayEvent.date}-${index}`}
      dayEvent={dayEvent}
    />
  ));
}

ListEvents.defaultProps = {
  emptyPlaceholderDescription: `
    There are no hearings that match the applied filters.
    Please try selecting another date, filter,
    or simply clear and reset your applied filters to see more results.`,
};

ListEvents.propTypes = {
  isLoadingEvents: PropTypes.bool,
  dayEvents: PropTypes.array.isRequired,
  emptyPlaceholderDescription: PropTypes.string,
  type: typeShape.isRequired,
};

export default withCalendar(ListEvents);
