import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import classNames from 'classnames';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';

import { FILL_START_COLOR, BORDER_COLOR } from './constants';
import useAreaChart from './useAreaChart';
import styles from './styles.module.scss';

function TimeSeriesChart({
  isLoading,
  lineChartData,
  isMobile,
  titles,
  className,
  chartClassName,
  titleClassName,
  customTitleClassName,
  placeholderClassName,
  loadingPlaceholderClassName,
  theme,
  ...chartOptionProps
}) {
  const { data, chartOptions, chartRef, showPlaceholder } = useAreaChart({
    lineChartData,
    isMobile,
    ...chartOptionProps,
  });

  return useMemo(() => {
    if (isLoading) {
      return (
        <LoadingPlaceholder
          className={className}
          placeholderClassName={loadingPlaceholderClassName}
          {...titles}
        />
      );
    }

    return (
      <DataCard
        {...titles}
        className={className}
        showPlaceholder={showPlaceholder}
        titleClassName={titleClassName}
        customTitleClassName={customTitleClassName}
        placeholderClassName={placeholderClassName}
        theme={theme}
        id="timeSeriesChart"
      >
        <div className={styles.scrollable}>
          <div className={classNames(styles.chartContainer, chartClassName)}>
            <Line ref={chartRef} options={chartOptions} data={data} />
          </div>
        </div>
      </DataCard>
    );
  }, [isLoading, data, chartOptions]);
}

TimeSeriesChart.defaultProps = {
  fillStartColor: FILL_START_COLOR,
  borderColor: BORDER_COLOR,
  borderWidth: 1,
  pointBorderWidth: 1,
  pointHitRadius: 8,
  pointRadius: 4,
  pointHoverRadius: 6,
  rotateMobileXTicks: true,
  gradientY1: 250,
};

TimeSeriesChart.propTypes = {
  lineChartData: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  titles: PropTypes.object.isRequired,
  className: PropTypes.string,
  chartClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  customTitleClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
  loadingPlaceholderClassName: PropTypes.string,
  fillStartColor: PropTypes.string,
  fillEndColor: PropTypes.string,
  borderColor: PropTypes.string,
  tooltipLabel: PropTypes.string,
  borderWidth: PropTypes.number,
  pointBorderWidth: PropTypes.number,
  pointHitRadius: PropTypes.number,
  pointRadius: PropTypes.number,
  pointHoverRadius: PropTypes.number,
  maxXAxisTicksLimit: PropTypes.number,
  rotateMobileXTicks: PropTypes.bool.isRequired,
  gradientY1: PropTypes.number,
  theme: PropTypes.string,
};

export default TimeSeriesChart;
