import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caret } from 'components/core/Accordion';

import styles from './styles.module.scss';

export default function SectionTitle({
  label,
  isOpen,
  onToggle,
  titleClassName,
}) {
  return (
    <div className={styles.titleContainer}>
      <div
        className={classNames(styles.filterTitle, titleClassName)}
        onClick={onToggle}
        onKeyDown={onToggle}
      >
        {label} <Caret isOpen={isOpen} />
      </div>
    </div>
  );
}

SectionTitle.propTypes = {
  label: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  titleClassName: PropTypes.string,
};
