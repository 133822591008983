import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, map } from 'lodash';

import Link from 'utils/Link';
import Button from 'components/core/Button';
import { toShape } from 'utils/shapes/routerLink';

import styles from './styles.module.scss';

const themes = {
  darkBlue: styles.darkBlue,
  green: styles.green,
  altGreen: styles.altGreen,
  red: styles.red,
  blue: styles.blue,
};

function ButtonTag({
  isOnMyFeed,
  theme,
  checkedBadge,
  letterBadge,
  children,
  onClick,
  className,
  isLink,
  href,
  isRouterLink,
  to,
}) {
  const renderBadges = () => {
    if (!checkedBadge && isEmpty(letterBadge)) {
      return null;
    }

    return (
      <>
        {checkedBadge && (
          <div className={styles.circle}>
            <i className="far fa-check" />
          </div>
        )}
        {map(letterBadge, item => (
          <div className={styles.circle}>
            <span>{item}</span>
          </div>
        ))}
      </>
    );
  };

  const content = (
    <>
      {children}
      {renderBadges()}
    </>
  );

  const tagClassName = classNames(
    styles.tagButton,
    themes[isOnMyFeed ? 'green' : 'blue'],
    themes[theme],
    className,
  );

  const commonProps = {
    className: tagClassName,
    theme: 'tertiary',
    size: 'small',
  };

  if (isLink || isRouterLink) {
    return (
      <Link {...commonProps} href={href} isRouterLink={isRouterLink} to={to}>
        {content}
      </Link>
    );
  }

  return (
    <Button
      {...commonProps}
      onClick={onClick}
    >
      {content}
    </Button>
  );
}

ButtonTag.defaultProps = {
  isLink: false,
  isRouterLink: false,
};

ButtonTag.propTypes = {
  isOnMyFeed: PropTypes.bool,
  theme: PropTypes.string,
  checkedBadge: PropTypes.bool,
  letterBadge: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  isRouterLink: PropTypes.bool,
  to: toShape,
};

export default ButtonTag;
