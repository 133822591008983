import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';

function CommitteesColumn({ keyName, item }) {
  const { committees } = item;
  const isEmptyData = isEmpty(committees);

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={committees.sort()}
      isEmptyData={isEmptyData}
    />
  );
}

CommitteesColumn.defaultProps = {
  item: {},
};

CommitteesColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
};

export default CommitteesColumn;
