import GlobalNotifier from 'components/GlobalNotifier';

const addNotification = GlobalNotifier.addNotification;

const error = message => addNotification({ message, level: 'error' });
const success = message => addNotification({ message, level: 'success' });

export default class Notifications {
  static getEventsFailed = () => error('Could not load events. Please try again.');
  static getCalendarDataFailed = () => error('Could not load calendar. Please try again.');
  static exportEventSuccess = () => success('Data has been exported successfully.');
  static exportEventFailed = () => error('Data export failed. Please try again.');
}
