import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/core/Link';
import Button from 'components/core/Button';
import { TextPlaceholder } from 'components/core/Placeholder';
import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import styles from './styles.module.scss';

function DocumentPlaceholder({ isEmptyText, sourceUrl }) {
  const { isMobile } = useMediaQuery();

  const handleNavigateBack = () => {
    window.history.back();
  };

  const isFederalRegulations = window.location.href.includes('/federal/regulations/');
  const isMobileV2 = gon.isNavigationV2Enabled && isMobile;

  function renderContent() {
    if (isEmptyText && sourceUrl) {
      const description = (
        <span>
          Click&nbsp;
          <Link href={sourceUrl} isExternal theme="default" className={styles.link}>
            here
          </Link>
          &nbsp;to view original source
        </span>
      );

      return (
        <EmptyPlaceholder
          title="Document preview not available"
          description={description}
        />
      );
    }

    return Array(30)
      .fill(0)
      .map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TextPlaceholder key={`placeholder-${index}`} className={styles.placeholderText} />
      ));
  }

  return (
    <div className={styles.container}>
      <div className={styles.placeholderContainer}>
        {!isFederalRegulations && isMobileV2 && (
        <span className={styles.buttonBackWrapperPreview}>
          <Button
            theme="light"
            data-spec="nav-back-button"
            onClick={handleNavigateBack}
          >
            <i className={classNames(styles.iconBack, 'btn btn-secondary far fa-arrow-left',)} />
          </Button>
        </span>
        )}
        <div className={styles.content}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

DocumentPlaceholder.propTypes = {
  isEmptyText: PropTypes.bool,
  sourceUrl: PropTypes.string,
};

export default DocumentPlaceholder;
