import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';
import { suggestionShape } from 'utils/shapes/suggestion';

function Legislator({
  keyName,
  titles,
  value,
  onChange,
  className,
  isFederal,
  customStrategy,
  strategyInputs,
}) {
  const federalStateStrategy = isFederal ? 'federal_legislator' : 'state_legislation_legislators';
  const strategy = customStrategy || federalStateStrategy;

  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      {...titles}
      className={className}
      name={keyName}
      strategy={strategy}
      overrideValue={value}
      defaultSelections={value}
      onChange={handleChange}
      strategyInputs={strategyInputs}
    />
  );
}

Legislator.defaultProps = {
  isFederal: true,
  keyName: 'legislator',
  titles: {
    title: 'Legislator',
    placeholder: 'The names of the legislators you are searching for (e.g., Chuck Grassley)',
  },
};

Legislator.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  value: PropTypes.arrayOf(suggestionShape).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFederal: PropTypes.bool,
  customStrategy: PropTypes.string,
  strategyInputs: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

export default Legislator;
