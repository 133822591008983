import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';
import { SimpleStatesMap } from 'components/core/Map';

import styles from './styles.module.scss';

function StatesMapGraph({
  isLoading,
  data,
  titles,
  mapColors,
  mapHighlightColor,
  className,
}) {
  const showPlaceholder = isEmpty(data);

  return useMemo(() => {
    if (isLoading) {
      return <LoadingPlaceholder className={className} {...titles} />;
    }

    return (
      <DataCard
        {...titles}
        className={classNames(styles.container, className)}
        showPlaceholder={showPlaceholder}
      >
        <div className={styles.scrollable}>
          <div className={styles.chartContainer}>
            <SimpleStatesMap
              data={data}
              mapColors={mapColors}
              highlightColor={mapHighlightColor}
              isEqualInterval={false}
            />
          </div>
        </div>
      </DataCard>
    );
  }, [isLoading, data]);
}

StatesMapGraph.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  titles: PropTypes.object.isRequired,
  mapColors: PropTypes.array,
  mapHighlightColor: PropTypes.string,
  className: PropTypes.string,
};

export default StatesMapGraph;
