import React from 'react';
import classNames from 'classnames';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

function Placeholder() {
  return (
    <div className={styles.container}>
      <TextPlaceholder className={styles.partyZone} />

      <header className={styles.cardHeader}>
        <div className={classNames(styles.info, styles.infoPlaceholder)}>
          <TextPlaceholder className={classNames(styles.avatar, styles.avatarPlaceholder)} />

          <div className={styles.title}>
            <TextPlaceholder className={styles.line} />
            <TextPlaceholder className={styles.line} />
          </div>
        </div>

        <div className={styles.badges}>
          <TextPlaceholder className={styles.line} />
        </div>
      </header>
    </div>
  );
}

export default Placeholder;
