import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';
import { FEDERAL_ROLE, LOCAL_ROLE, STATE_ROLE } from 'utils/transformers/profile/constants';

export const tableShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  getLabel: PropTypes.func,
  placeholderSize: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    getContent: PropTypes.func,
    component: PropTypes.elementType,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    id: stringNumberType,
    label: PropTypes.string,
    content: PropTypes.node,
  })),
});

const birthInfoShape = PropTypes.shape({
  place: PropTypes.string,
  date: PropTypes.string,
  deathDate: PropTypes.string,
});

const partyShape = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

const urlShape = PropTypes.shape({
  url: PropTypes.string,
  // TODO: adjust type oneOf
  // type: PropTypes.oneOf([
  //   'email',
  //   'twitter',
  //   'facebook',
  //   'youtube',
  //   'linkedin',
  //   'homepage',
  // ]),
  type: PropTypes.string,
  displayOrder: stringNumberType,
});

const biographyShape = PropTypes.shape({
  infoText: PropTypes.string,
  date: PropTypes.string,
});

const factShape = PropTypes.shape({
  text: PropTypes.string,
  source: PropTypes.string,
  date: PropTypes.string,
  displayOrder: stringNumberType,
});

const interestShape = PropTypes.shape({
  description: PropTypes.string,
  displayOrder: stringNumberType,
});

const politicalPositionShape = PropTypes.shape({
  organization: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  displayOrder: stringNumberType,
});

const employmentPositionShape = PropTypes.shape({
  title: PropTypes.string,
  company: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

const headPositionShape = PropTypes.shape({
  title: PropTypes.string,
  company: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
});

const educationItemShape = PropTypes.shape({
  school: PropTypes.string,
  details: PropTypes.string,
  degree: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  displayOrder: stringNumberType,
});

const militaryBranchShape = PropTypes.shape({
  highestRank: PropTypes.string,
  branch: PropTypes.string,
  details: PropTypes.string,
  medals: PropTypes.string,
  startRank: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  displayOrder: stringNumberType,
});

const membershipItemShape = PropTypes.shape({
  title: PropTypes.string,
  organization: PropTypes.string,
  type: PropTypes.string,
  details: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  displayOrder: stringNumberType,
});

const publicationShape = PropTypes.shape({
  type: PropTypes.string,
  title: PropTypes.string,
  publisher: PropTypes.string,
  details: PropTypes.string,
  year: PropTypes.string,
  displayOrder: stringNumberType,
});

const awardShape = PropTypes.shape({
  title: PropTypes.string,
  organization: PropTypes.string,
  type: PropTypes.string,
  details: PropTypes.string,
  year: PropTypes.string,
  displayOrder: stringNumberType,
});

export const electionInfoShape = PropTypes.shape({
  percentVote: stringNumberType,
  electionDate: PropTypes.string,
  reelectYear: PropTypes.string,
  electionCode: PropTypes.string,
  electedPosition: PropTypes.string,
});

export const phoneShape = PropTypes.shape({
  type: PropTypes.string,
  areaCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  displayOrder: stringNumberType,
});

export const addressShape = PropTypes.shape({
  street: PropTypes.string,
  building: PropTypes.string,
  room: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  state: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
});

export const roleTypePropType = PropTypes.oneOf([
  STATE_ROLE,
  FEDERAL_ROLE,
  LOCAL_ROLE,
]);

export const positionShape = PropTypes.shape({
  title: PropTypes.string,
  role: PropTypes.string,
  roleType: roleTypePropType,
  organization: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  president: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  levelCode: PropTypes.string,
  apptStartDate: PropTypes.string,
  apptEndDate: PropTypes.string,
  nomitateDate: PropTypes.string,
  confirmDate: PropTypes.string,
  electionInfo: electionInfoShape,
  phones: PropTypes.arrayOf(phoneShape),
  address: addressShape,
});

export const personShape = PropTypes.shape({
  personId: stringNumberType,
  prefixName: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffixName: PropTypes.string,
  fullName: PropTypes.string,
  avatarUrl: PropTypes.string,
  ethnicity: PropTypes.string,
  gender: PropTypes.string,
  maritalStatus: PropTypes.string,
  residence: PropTypes.string,
  religion: PropTypes.string,
  spouseName: PropTypes.string,
  family: PropTypes.string,
  birthInfo: birthInfoShape,
  party: partyShape,
  urls: PropTypes.arrayOf(urlShape),
  narrativeBiography: biographyShape,
  kwBiography: biographyShape,
  facts: PropTypes.arrayOf(factShape),
  interests: PropTypes.arrayOf(interestShape),
  politicalHistory: PropTypes.arrayOf(politicalPositionShape),
  employmentHistory: PropTypes.arrayOf(employmentPositionShape),
  headPositions: PropTypes.arrayOf(headPositionShape),
  education: PropTypes.arrayOf(educationItemShape),
  militaryService: PropTypes.arrayOf(militaryBranchShape),
  membership: PropTypes.arrayOf(membershipItemShape),
  publications: PropTypes.arrayOf(publicationShape),
  awards: PropTypes.arrayOf(awardShape),
  positions: PropTypes.arrayOf(positionShape),
});

export const subscriptionsShape = PropTypes.shape({
  committee: PropTypes.string,
  staffer_office: PropTypes.string,
});
