import { useEffect, useState } from 'react';
import KEY_CODES from 'utils/keyCodes';

import isEmpty from 'lodash/isEmpty';
import { removeLastComma } from './utils';

function useAddressAutocomplete({ value = '', placeId, onChange, onBlur, ref }) {
  const [address, setAddress] = useState(value);
  const [selectedAddress, setSelectedAddress] = useState(value);
  const [selectedAddressId, setSelectedAddressId] = useState(placeId);
  const [isSelected, setIsSelected] = useState(!!value);
  const [clearSuggestions, setClearSuggestions] = useState();
  const [hasTouchedInput, setHasTouchedInput] = useState(false);

  function checkAddressField() {
    if (!address) {
      setSelectedAddress('');
      setSelectedAddressId('');
      onBlur && onBlur({ address: '', placeId: '' });
    } else if (isSelected) {
      setAddress(selectedAddress);
      onBlur && onBlur({ address: selectedAddress, placeId: selectedAddressId });
    } else {
      onBlur && onBlur({ address: '', placeId: '' });
      setAddress('');
    }
    setHasTouchedInput(false);
  }

  function handlePressEscape(event) {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      checkAddressField();
      document.removeEventListener('keydown', handlePressEscape);
    }
  }

  function handleClickOutside(event) {
    if (hasTouchedInput && ref.current && !ref.current.contains(event.target)) {
      // if input is clear when you leave, keep it clear
      // otherwise replace it with last selected value
      clearSuggestions();
      checkAddressField();
      document.removeEventListener('keydown', handlePressEscape);
    }
  }

  useEffect(() => {
    address && onChange && address !== value && onChange({ address, placeId: selectedAddressId });
  }, [address, selectedAddressId]);

  useEffect(() => {
    if (address !== value && selectedAddress !== value) {
      setSelectedAddress(value);
    }
  }, [value]);

  useEffect(() => {
    setAddress(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    if (hasTouchedInput) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handlePressEscape);
    }

    return () => {
      if (hasTouchedInput) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [
    ref,
    address,
    onBlur,
    isSelected,
    selectedAddress,
    onChange,
    clearSuggestions,
    selectedAddressId,
    hasTouchedInput,
  ]);

  function overrideOnBlur(element) {
    if (!isEmpty(element) && typeof clearSuggestions !== 'function') {
      setClearSuggestions(() => element.clearSuggestions);
      element.handleInputOnBlur = () => {};
    }
  }

  const handleChange = (addressValue) => {
    setAddress(addressValue);
    setHasTouchedInput(true);
  };

  const handleSelect = (selectedValue, selectedPlaceId) => {
    const formatedAddress = removeLastComma(selectedValue);

    setSelectedAddress(formatedAddress);
    setSelectedAddressId(selectedPlaceId);
    setIsSelected(true);
    setAddress(formatedAddress);
  };

  const handleError = (_status, _clearSuggestions) => _clearSuggestions();

  return {
    address,
    selectedAddress,
    selectedAddressId,
    isSelected,
    overrideOnBlur,
    onChange: handleChange,
    onError: handleError,
    onSelect: handleSelect,
  };
}

export default useAddressAutocomplete;
