import React from 'react';

import TextPlaceholder from '../Text';
import styles from './styles.module.scss';

function InputPlaceholder() {
  return (
    <div className={styles.inputPlaceholder}>
      <TextPlaceholder className={styles.text} />
    </div>
  );
}

export default InputPlaceholder;
