import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuToggleButton from 'components/Navigation/MenuToggleButton';
import FooterBar from 'components/Navigation/MobileView/common/FooterBar';
import UserProfileMenu from 'components/Navigation/MobileView/UserMenu';
import { findBreadcrumbs } from 'components/Navigation/MobileView/utils';
import { NAVIGATION_USER_PROFILE_IMG_ID } from 'components/Navigation/utils/constants';
import { getIsActiveExact } from 'components/NavigationV2/utils';
import Avatar from 'components/core/Avatar';
import Notifications from 'components/core/Notifications';
import HelpButton from 'components/core/HelpButton';
import Logo from 'components/core/Logo';
import useModal from 'components/core/Modal/useModal';
import { TextPlaceholder } from 'components/core/Placeholder';
import { getCurrentUser } from 'utils/currentUser';
import reduxConnect from 'utils/redux-connect';
import * as actions from 'redux/notifications/actions';

import Account from './components/Account';
import { getParentFromBreadcrumb } from './utils';
import styles from './styles.module.scss';
import NavigationWarningBanner from '../../../../Navigation/common/WarningBanner';

function Container({
  customBreadcrumb,
  customParentTitle,
  headerClassName,
  isOffsetVisible,
  isNotificationsPanelOpen,
  mobileTitle,
  title,
  backTo,
  isLoadingTitle,
}) {
  const { isOpenModal: isUserMenuOpen, toggleModal: toggleUserMenu } = useModal();

  const breadcrumb = customBreadcrumb || findBreadcrumbs(window.location.pathname).breadcrumb;
  const parentTitle = getParentFromBreadcrumb(breadcrumb);
  const innerHTML = { __html: parentTitle };

  const { profileImgUrl, fullName } = getCurrentUser();

  function getTitleProps() {
    if (customParentTitle) {
      return { children: customParentTitle };
    }

    return { dangerouslySetInnerHTML: innerHTML };
  }

  const userAvatar = (
    <Avatar
      id={NAVIGATION_USER_PROFILE_IMG_ID}
      className={styles.avatar}
      photoUrl={profileImgUrl}
      name={fullName}
    />
  );

  const RightButton = gon.isNotificationEnabled ? Notifications : HelpButton;
  const footerBar = gon.isNavigationV2Enabled ? null : <FooterBar />;

  const renderHeader = () => {
    if (gon.isNavigationV2Enabled) {
      const headerTitle = mobileTitle || title;

      const renderLeftItem = () => {
        const isHomepage = getIsActiveExact(gon.homepage.path);

        if (isHomepage) {
          return (
            <div className={styles.homePage}>
              <Logo />
              {headerTitle}
            </div>
          );
        }

        const renderTitle = () => {
          if (isLoadingTitle) {
            return <TextPlaceholder className={styles.titlePlaceholder} />;
          }

          return headerTitle;
        };

        return (
          <a className={styles.return} href={backTo || gon.homepage.path}>
            <i className={classNames('far fa-arrow-left', styles.icon)} />
            <span>{renderTitle()}</span>
          </a>
        );
      };

      return (
        <header className={classNames(styles.header, styles.v2, headerClassName)}>
          {renderLeftItem()}

          <div className={styles.right}>
            <RightButton
              isMobile
              {...gon.isNotificationEnabled && { className: styles.notifications }}
            />
            <Account photoUrl={profileImgUrl} name={fullName} />
          </div>
        </header>
      );
    }

    return (
      <header className={classNames(styles.header, headerClassName)}>
        <MenuToggleButton
          isActive={isUserMenuOpen}
          onClick={toggleUserMenu}
          inactiveContent={userAvatar}
        />
        <div className={styles.title} {...getTitleProps()} />

        <RightButton isMobile />
      </header>
    );
  };

  return (
    <>
      {isOffsetVisible && (
        <div className={classNames(styles.headerOffset, gon.isNavigationV2Enabled && styles.offsetV2)} />
      )}
      {renderHeader()}

      { !gon.companyHasActivePlan
        && <NavigationWarningBanner /> }

      <UserProfileMenu isUserMenuOpen={isUserMenuOpen} />
      {isNotificationsPanelOpen && footerBar}
    </>
  );
}

Container.defaultProps = {
  customParentTitle: '',
  isOffsetVisible: true,
  isLoadingTitle: false,
};

Container.propTypes = {
  pageName: PropTypes.string,
  page: PropTypes.string,
  customBreadcrumb: PropTypes.string,
  customParentTitle: PropTypes.node,
  headerClassName: PropTypes.string,
  isOffsetVisible: PropTypes.bool,
  isNotificationsPanelOpen: PropTypes.bool.isRequired,
  mobileTitle: PropTypes.string,
  title: PropTypes.node,
  backTo: PropTypes.string,
  isLoadingTitle: PropTypes.bool,
};

export default reduxConnect('notifications', actions)(Container);
