export const MOBILE_LABEL_LENGTH = 30;

export const DESKTOP_LABEL_LENGTH = 60;

export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const TAGS_DISPLAY_LENGTH = 4;
