import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import styles from './styles';

function LookupCard({
  headerContent,
  footerContent,
  children,
  hideHeader,
  hideFooter,
  wrap,
}) {
  return (
    <View wrap={wrap} style={styles.card}>
      {!hideHeader && (
        <View wrap={false} style={styles.headerSection}>
          {headerContent}
        </View>
      )}

      <View style={styles.bodySection}>
        {children}
      </View>

      {!hideFooter && (
        <View wrap={false} style={styles.footerSection}>
          {footerContent}
        </View>
      )}
    </View>
  );
}

LookupCard.propTypes = {
  wrap: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  headerContent: PropTypes.node,
  footerContent: PropTypes.node,
  children: PropTypes.node,
};

export default LookupCard;
