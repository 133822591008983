import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatNumber } from 'utils/formatters';
import Button from 'components/core/Button';
import ButtonGroup from 'components/core/ButtonGroup';

import LoadMorePaginator from './components/LoadMore';
import InfiniteLoadPaginator from './components/InfiniteLoad';
import { getPageNumbers } from './utils';
import { DEFAULT_PAGE_SIZE } from './constants';
import usePaginator from './usePaginator';
import styles from './styles.module.scss';

function Paginator({
  isEstimated,
  isLoading,
  current,
  total,
  onChange,
  pageSize,
  className,
  totalItems,
  isMobile,
  isTablePaginator,
}) {
  const {
    start,
    end,
    descriptionStart,
    descriptionEnd,
    currentPage,
    onPageClick,
    maxNumberOfPages,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
    isPreviousDisabled,
  } = usePaginator({
    current,
    total,
    onChange,
    pageSize,
    totalItems,
    isMobile,
    isLoading,
  });
  const previousArrows = (
    <span className={styles.arrowContainer}>
      <button
        type="button"
        className={styles.iconButton}
        disabled={isPreviousDisabled}
        onClick={onPreviousClick(maxNumberOfPages)}
      >
        <i className="fas fa-angle-double-left" />
      </button>
      <button
        type="button"
        className={styles.iconButton}
        disabled={isPreviousDisabled}
        onClick={onPreviousClick()}
      >
        <i className="fas fa-angle-left" />
      </button>
    </span>
  );

  const nextArrows = (
    <span className={styles.arrowContainer}>
      <button
        type="button"
        className={styles.iconButton}
        disabled={isNextDisabled}
        onClick={onNextClick()}
      >
        <i className="fas fa-angle-right" />
      </button>
      <button
        type="button"
        className={styles.iconButton}
        disabled={isNextDisabled}
        onClick={onNextClick(maxNumberOfPages)}
      >
        <i className="fas fa-angle-double-right" />
      </button>
    </span>
  );

  const paginatorDescription = (
    <span className={styles.description}>
      Showing:
      <span className={styles.boldText}>{formatNumber(descriptionStart)}</span>
      -
      <span className={styles.boldText}>{formatNumber(descriptionEnd)}</span>
      of{isEstimated && (<span className={styles.about}>about</span>)}
      <span className={styles.boldText}>{formatNumber(totalItems)}</span>
    </span>
  );

  const pageButtons = (
    <ButtonGroup theme="light" className={styles.buttonContainer}>
      {getPageNumbers(start, end).map(page => (
        <Button
          key={page}
          theme="default"
          size="small"
          disabled={isLoading}
          className={classNames(
            styles.pageButton,
            currentPage === page && styles.active,
          )}
          onClick={onPageClick(page)}
        >
          {page}
        </Button>
      ))}
    </ButtonGroup>
  );

  const containerClassName = classNames(
    styles.container,
    isTablePaginator && styles.containerBox,
    className,
  );

  return (
    <div id="paginator" className={containerClassName}>
      <div className={styles.pagesContainer}>
        {previousArrows}
        {pageButtons}
        {nextArrows}
      </div>
      {paginatorDescription}
    </div>
  );
}

Paginator.propTypes = {
  current: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  total: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  totalItems: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  pageSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isTablePaginator: PropTypes.bool,
  isEstimated: PropTypes.bool,
};

Paginator.defaultProps = {
  pageSize: DEFAULT_PAGE_SIZE,
  isTablePaginator: true,
  isLoading: false,
};

export default Paginator;
export { LoadMorePaginator, InfiniteLoadPaginator };
