import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { findNode } from 'components/core/SmartSearchBuilder/utils/dragEndActions/common';
import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';

import GroupOperator from './GroupOperator';
import StatementFilters from './StatementFilters';
import StatementFiltersAdd from './StatementFiltersAdd';
import { getStatementStyle } from '../theme';
import styles from '../styles.module.scss';

function Statement({
  isDraggingOver,
  isLastOperator,
  isDraggingStatement,
  isDraggingFilter,
  draggableIndex,
  parentOperator,
  parentId,
  statement,
  deleteStatement,
  deleteFilter,
  draggableItems,
}) {
  const { id, name: statementName } = statement || {};
  const { filter } = statement || {};
  const { filters, id: filterId, operator: filterOperator } = filter || {};
  const statementHasFilters = filters && filters.length > 0;
  const displayFilters = statementHasFilters || isDraggingFilter;
  const draggedItem = findNode(draggableItems, statementName);
  const { component: StatementComponent } = draggedItem || {};
  const isGroupOperatorDisplayed = !isLastOperator && !isDraggingOver;

  const handleDeleteStatement = () => deleteStatement(id);

  const filtersNode = displayFilters && (
    <div className={styles.statementFilters}>
      <p>Where</p>

      {statementHasFilters && (
        <StatementFilters
          isDraggingFilter={isDraggingFilter}
          parentOperator={filterOperator}
          parentId={filterId}
          filters={filters}
          deleteFilter={deleteFilter}
        />
      )}

      <StatementFiltersAdd
        isJoinGroupPartHidden
        isDraggingFilter={isDraggingFilter}
        currentId={id}
        parentId={id}
        filters={filters}
      />
    </div>
  );

  return (
    <Draggable draggableId={id} index={draggableIndex}>
      {(draggableProvided, draggableSnapshot) => (
        <div
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
          className={classNames(
            styles.statement,
            isDraggingStatement && styles.isDragging
          )}
          style={getStatementStyle(
            draggableSnapshot.isDragging,
            draggableProvided.draggableProps.style
          )}
        >
          <div
            className={classNames(
              styles.component,
              displayFilters && styles.componentWithFilters,
              isDraggingStatement && styles.componentWithStatementsAdd,
            )}
          >
            <span className={styles.componentDetails}>
              {StatementComponent && (
                <StatementComponent statement={statement} draggedItem={draggedItem} />
              )}
            </span>
            <span {...draggableProvided.dragHandleProps} className={styles.componentDrag}>
              <i className="far fa-arrows-alt" />
            </span>
            <span className={styles.componentDelete} onClick={handleDeleteStatement}>
              <i className="fal fa-times" />
            </span>

            {isGroupOperatorDisplayed && (
              <div className={styles.statementFilterOperator}>
                <GroupOperator type="item" id={parentId} operator={parentOperator} />
              </div>
            )}
          </div>

          {filtersNode}

          {draggableProvided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

Statement.propTypes = {
  isLastOperator: PropTypes.bool.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  isDraggingStatement: PropTypes.bool.isRequired,
  isDraggingFilter: PropTypes.bool.isRequired,
  draggableIndex: PropTypes.number.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  statement: PropTypes.object.isRequired,
  deleteStatement: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  draggableItems: PropTypes.array.isRequired,
};

export default withSmartSearch(Statement);
