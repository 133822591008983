import React from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';

import * as Actions from 'redux/moneyball/receiptPages/lookup/actions';
import store from 'redux/store';

import Container from './components/Container';

function mapStateToProps(state) {
  return { ...state.moneyball.receiptLookup };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container);

export default function Contributors(props) {
  return (
    <Provider store={store}>
      <ConnectedContainer {...props} />
    </Provider>
  );
}
