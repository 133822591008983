import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getPositionContent } from './utils';

function PoliticalHistory({ person, isLoading, hideOnEmptyData }) {
  const { politicalHistory = [] } = person || {};

  const sections = [{
    id: 'politicalHistory',
    title: 'Political history',
    tables: [{
      id: 'organizations',
      label: 'Organization',
      placeholderSize: 2,
      columns: [{
        id: 'organization',
        getContent: getPositionContent,
      }],
      data: politicalHistory,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(politicalHistory)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

PoliticalHistory.defaultProps = {
  hideOnEmptyData: true,
};

PoliticalHistory.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(PoliticalHistory);
