import React from 'react';
import PropTypes from 'prop-types';

import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import SearchInput from './SearchInput';
import Options from './Options';

function OptionsSection({ displaySearchInput }) {
  return (
    <div>
      {displaySearchInput && <SearchInput />}
      <Options />
    </div>
  );
}

OptionsSection.propTypes = {
  displaySearchInput: PropTypes.bool.isRequired,
};

export default withSearch(OptionsSection);
