import { useEffect } from 'react';
import { isFunction, merge } from 'lodash';

import { loadAdvancedSearch as defaultLoadAdvancedSearch } from 'redux/advancedSearch/utils';

export default function usePageLookup({
  initialFetch,
  fetchData,
  notifications,
  resetData,
  dataProps,
  transformSearchesForUI,
  onSearchOptionsChange,
  onSearchValueChange,
  setLastSearchId,
  saveInRecentSearch,
  emptyState,
  loadAdvancedSearch = defaultLoadAdvancedSearch,
  onClearAllSearchParams,
  getSearchParams,
}) {
  const fetchSearchResults = ({
    search = '',
    page = 1,
    sort,
    direction,
  } = {}) => fetchData({
    page,
    sort,
    direction,
    dataProps,
    searchData: merge(emptyState, { searchValue: search }),
  }).then(() => onSearchValueChange(search))
    .catch((error) => {
      notifications && notifications.defaultFetchDataFailed();

      throw error;
    });

  const fetchDefaultSearchResults = ({
    search,
    page,
    direction,
    sort,
  } = {}) => {
    const searchParams = { search, page, direction, sort };

    if (initialFetch) {
      return initialFetch(searchParams)
        .then((filters) => {
          saveInRecentSearch && saveInRecentSearch(filters);
        });
    }

    return fetchSearchResults(searchParams);
  };

  const restoreSearchResults = (page, sort, direction) => (search) => {
    const [searchData] = transformSearchesForUI([search], dataProps);
    const { options = {} } = searchData;
    const { searchValue: query = '' } = options;

    const { id: searchId } = search || {};
    setLastSearchId && setLastSearchId(searchId);

    onSearchOptionsChange(searchData);

    return fetchData({
      page,
      dataProps,
      sort,
      direction,
      searchData: {
        searchValue: query,
        // Moneyball pages are using a nested options property
        options: options.options || options,
      },
    });
  };

  const fetchPreviousSearchResults = (
    { searchId, page, sort, direction }
  ) => loadAdvancedSearch(searchId)
    .then(restoreSearchResults(page, sort, direction))
    .catch((error) => {
      onClearAllSearchParams();
      fetchDefaultSearchResults();

      throw error;
    });

  const fetchLookupData = (searchParams) => {
    const { searchId, search } = searchParams || {};

    if (searchId) return fetchPreviousSearchResults(searchParams);

    if (search) return fetchSearchResults(searchParams);

    return fetchDefaultSearchResults(searchParams);
  };

  useEffect(() => {
    const urlParams = isFunction(getSearchParams) && getSearchParams();

    fetchLookupData(urlParams);

    return () => {
      isFunction(resetData) && resetData();
      setLastSearchId && setLastSearchId();
    };
  }, []);
}
