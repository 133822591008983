import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';
import Select from 'components/core/Select';

import titles from '../../utils/titles';
import styles from './styles.module.scss';

function Form({ contributionOptions, contribution, onChangeContribution }) {
  const contributionsComponent = (
    <Select
      onChange={onChangeContribution}
      options={contributionOptions}
      className={styles.contributorsOption}
      value={contribution}
      clearable={false}
      isSearchable={false}
      hasNewStyle
    />
  );

  return (
    <div className={styles.container}>
      <BaseOption
        {...titles.contributionsForm}
        customComponent={contributionsComponent}
      />
    </div>
  );
}

Form.propTypes = {
  contributionOptions: PropTypes.array.isRequired,
  contribution: PropTypes.string,
  onChangeContribution: PropTypes.func.isRequired,
};

export default Form;
