import {
  minControlHeight,
  backgroundColor,
  indicatorSeparatorColor,
  color,
  fontWeight,
  borderColor,
} from './settings.scss';

const style = minHeight => ({
  control: (base, state) => ({
    ...base,
    backgroundColor,
    borderColor,
    backgroundImage: 'none',
    minHeight: minHeight ? `${minHeight}px` : minControlHeight,
    ...(state.isDisabled && {
      opacity: 0.7,
    }),
    '&:hover': {
      backgroundColor: color,
      '> div > div': {
        color: backgroundColor,
      },
      '.icon.fas': {
        color: backgroundColor,
      },
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    color,
    padding: '8px 2px',
    '> i.icon.fas': {
      color,
      padding: '0 7px',
    },
  }),
  menu: base => ({
    ...base,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: base => ({
    ...base,
    margin: '0 auto',
    backgroundColor: indicatorSeparatorColor,
  }),
  placeholder: base => ({
    ...base,
    fontStyle: 'normal',
    fontWeight,
    color,
    width: '100%',
    textAlign: 'center',
    left: 0,
    '> span span': {
      marginLeft: '7px !important',
    },
  }),
  singleValue: base => ({
    ...base,
    fontStyle: 'normal',
    fontWeight,
    color,
    width: '100%',
    textAlign: 'center',
  }),
  valueContainer: base => ({
    ...base,
    minHeight: 'inherit',
    justifyContent: 'center',
  }),
});

export default style;
