import React from 'react';
import PropTypes from 'prop-types';

import Placeholder from 'components/core/PDF/components/Placeholder';

import styles from '../styles.module.scss';

export default function ReceiptAbstract({ isReceiptLoading, infoNode }) {
  return (
    <div className={styles.info}>
      {isReceiptLoading && <Placeholder />}
      {!isReceiptLoading && infoNode}
    </div>
  );
}

ReceiptAbstract.propTypes = {
  isReceiptLoading: PropTypes.bool.isRequired,
  infoNode: PropTypes.node.isRequired,
};
