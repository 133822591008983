import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import useMap from 'components/core/Map/utils/useMap';
import useMapBounds from 'components/core/Map/utils/useMapBounds';
import { setMarkersAndClusters } from 'components/core/Map/utils/clusters';
import { setHeatMap, onToggleHeatMap } from 'components/core/Map/utils/heatmap';
import { displayTypeCodes } from 'components/core/Map/utils/constants';

function useMapLookup({
  mapRef,
  data,
  onFetch,
  profileUrl,
  defaultFilters,
  withFilters,
  withNavigation,
  clusterName,
  withHeatmap,
  heatmapName,
  isLoadingMap,
  center,
  isTablePageView,
  onFilterChange,
  PopupComponent,
  markerImage,
}) {
  const mapProps = {
    mapRef,
    data,
    defaultFilters,
    withNavigation,
    withFilters,
    isLoadingMap,
    center,
  };

  const {
    map,
    activeFilters,
    onChangeFilter,
    setFilters,
  } = useMap(mapProps);
  const { mapType, displayType } = activeFilters;
  const isMapViewVisible = !isTablePageView;
  const [activePopup, setActivePopup] = useState(null);

  useMapBounds({ map, markers: data && data.features, showMap: isMapViewVisible });

  useEffect(() => {
    if (isMapViewVisible && map) {
      mapRef.current && map.resize();
    }
  }, [isMapViewVisible, map]);

  const destroyMap = () => map && map.remove();

  function initMapLookup() {
    if (map && !isEmpty(data)) {
      map.once('styledata', () => {
        mapRef.current && setFilters(defaultFilters.mapType);
      });

      const isHeatmapChecked = withHeatmap && displayType === displayTypeCodes.heatmap;

      setMarkersAndClusters(
        clusterName,
        map,
        data,
        { onFetch, profileUrl, PopupComponent, setActivePopup },
        markerImage,
        isHeatmapChecked
      );

      withHeatmap && setHeatMap(heatmapName, data, map, isHeatmapChecked);
    }

    return destroyMap;
  }

  useEffect(() => {
    if (map && withFilters && mapType) {
      setFilters(mapType);
      onFilterChange && onFilterChange({ mapType, activeFilters });
    }
  }, [mapType]);

  useEffect(() => {
    if (map && withHeatmap && displayType) {
      onToggleHeatMap(map);
    }
    activePopup && activePopup.remove();
  }, [displayType]);

  useEffect(initMapLookup, [map, data]);

  return {
    map,
    activeFilters,
    onChangeFilter,
  };
}

export default useMapLookup;
