import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LABEL_COLOR,
  DEFAULT_TEXT_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  summaryText: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 6,
    fontSize: 10,
    fontWeight: 300,
    color: DEFAULT_LABEL_COLOR,
  },
  summaryLabel: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 6,
    fontSize: 14,
    fontWeight: 600,
    color: DEFAULT_TEXT_COLOR,
  },
});

export default styles;
