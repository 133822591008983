import { useState, useEffect } from 'react';

export default function useCustomNote({ onChange, disabled }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const onTitleChange = ({ target: { value: newTitle } }) => setTitle(newTitle);

  const onContentChange = ({ target: { value: newContent } }) => setContent(newContent);

  const resetData = () => setTitle('') && setContent('');

  useEffect(() => {
    disabled && resetData();
  }, [disabled]);

  useEffect(() => {
    onChange({ title, content });
  }, [title, content]);

  return {
    noteTitle: title,
    noteContent: content,
    onTitleChange,
    onContentChange,
  };
}
