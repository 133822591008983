import React from 'react';
import PropTypes from 'prop-types';

import Title from 'components/core/Table/components/Dimensions/components/Title';
import withPublicRecords from 'components/core/Profile/components/Cards/PublicRecord/utils/withPublicRecords';
import ResponsiveTooltipContainer from 'components/core/Tooltip/components/ResponsiveTooltipContainer';
import SearchableCheckboxGroup from 'components/core/Checkbox/components/SearchableCheckboxGroup';

import styles from '../styles.module.scss';

function TooltipContent({
  selectedTypes,
  onCheckType,
  onCheckAllTypes,
  onClose,
  isMobile,
  checkboxRecordTypes,
}) {
  const options = checkboxRecordTypes.map(({ id, label, labelPlural }) => ({
    keyName: id,
    label: labelPlural || label,
  }));

  function renderNoResultsPlaceholder(query) {
    return `There are no record types that match "${query}".`;
  }

  return (
    <ResponsiveTooltipContainer>
      <Title title="Record type filters" onClose={onClose} />
      <SearchableCheckboxGroup
        options={options}
        numColumns={2}
        onItemCheck={onCheckType}
        onCheckAll={onCheckAllTypes}
        checkedItems={selectedTypes}
        isMobile={isMobile}
        labelGroupClassName={styles.checkboxGroup}
        renderNoResultsPlaceholder={renderNoResultsPlaceholder}
      />
      {!isMobile && <div className={styles.footer} />}
    </ResponsiveTooltipContainer>
  );
}

TooltipContent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  checkboxRecordTypes: PropTypes.array.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onCheckType: PropTypes.func.isRequired,
  onCheckAllTypes: PropTypes.func.isRequired,
};

export default withPublicRecords(TooltipContent);
