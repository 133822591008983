import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../Core';
import styles from './styles.module.scss';

function BackTo({
  to,
  title,
  isRouterLink,
  className,
  iconClassName,
  titleClassName,
  ...restProps
}) {
  return (
    <Link
      {...restProps}
      to={to}
      isRouterLink={isRouterLink}
      className={classNames(styles.link, className)}
    >
      <i className={classNames('fas fa-caret-left', styles.icon, iconClassName)} />
      <p className={classNames(styles.title, titleClassName)}>{title}</p>
    </Link>
  );
}

BackTo.defaultProps = {
  size: 'none',
  theme: 'light',
  href: '',
  isRouterLink: true,
};

BackTo.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string,
  isRouterLink: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]).isRequired,
  size: PropTypes.string,
  theme: PropTypes.string,
};

export default BackTo;
