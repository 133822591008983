import React from 'react';
import PropTypes from 'prop-types';

import TextPlaceholder from '../Text';
import MobileTabPlaceholder from './components/MobileTabs';
import styles from './styles.module.scss';

function TabsPlaceholder({ itemsNumber, isDesktop }) {
  if (!isDesktop) {
    return <MobileTabPlaceholder itemsNumber={itemsNumber} />;
  }

  function renderTitle(_, index) {
    return (
      <div className={styles.item} key={`tabs-${index}`}>
        <TextPlaceholder className={styles.title} />
        <TextPlaceholder className={styles.description} />
      </div>
    );
  }

  return (
    <div className={styles.titles}>
      {Array(itemsNumber).fill(0).map(renderTitle)}
    </div>
  );
}

TabsPlaceholder.defaultProps = {
  itemsNumber: 4,
};

TabsPlaceholder.propTypes = {
  itemsNumber: PropTypes.number,
  isDesktop: PropTypes.bool.isRequired,
};

export default TabsPlaceholder;
