import React from 'react';
import PropTypes from 'prop-types';
import { slice, map } from 'lodash';

import {
  Header,
  FooterContainer,
  BaseCard,
  ShowClusterLink,
} from 'components/core/News/components/Cards/common';
import { selectedFeedShape, storyClusterShape } from 'components/News/utils/shapes';

import StoryItem from './components/StoryItem';
import styles from './styles.module.scss';

function StoryCluster({
  storyCluster,
  isMobile,
  isDesktop,
  selectedFeed,
  onSelectCard,
  isChecked,
  displayCheckbox,
  isExternalComponent,
  onShowCluster,
  isRouterLink,
}) {
  const { stories, clusterId, ...restStoryCluster } = storyCluster;
  const limitedStories = slice(stories, 0, 3);

  function renderStory(story, index) {
    return (
      <StoryItem
        key={`story-${index}`}
        story={story}
        isMobile={isMobile}
      />
    );
  }

  return (
    <BaseCard displayCheckbox={displayCheckbox} onChange={onSelectCard} isChecked={isChecked}>
      <Header
        {...restStoryCluster}
        isMobile={isMobile}
        className={styles.header}
        isExternalComponent={isExternalComponent}
        isRouterLink={isRouterLink}
      />
      <div className={styles.storiesContainer}>
        {map(limitedStories, renderStory)}
      </div>
      <FooterContainer className={styles.footer}>
        <ShowClusterLink
          id={clusterId}
          selectedFeed={selectedFeed}
          isExternalComponent={isExternalComponent}
          onShowCluster={onShowCluster}
          isDesktop={isDesktop}
          isRouterLink={isRouterLink}
        />
      </FooterContainer>
    </BaseCard>
  );
}

StoryCluster.defaultProps = {
  isExternalComponent: false,
  isRouterLink: false,
};

StoryCluster.propTypes = {
  storyCluster: storyClusterShape.isRequired,
  selectedFeed: selectedFeedShape,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  onSelectCard: PropTypes.func,
  isChecked: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  isExternalComponent: PropTypes.bool,
  onShowCluster: PropTypes.func,
  isRouterLink: PropTypes.bool,
};

export default StoryCluster;
