import PropTypes from 'prop-types';

import { summaryProps } from 'components/core/DocumentContainer/utils/shapes';
import { stringNumberType } from 'utils/shapes';

export const dataShape = PropTypes.shape({
  ...summaryProps,
  id: stringNumberType,
  onDocumentShared: PropTypes.func.isRequired,
  onTagsSaved: PropTypes.func.isRequired,
});
