import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { INTERACTION_TYPES } from 'components/Grm2/constants';
import { View, Text } from '@react-pdf/renderer';

import styles from './styles';

function InteractionType({ interactionType }) {
  if (isEmpty(interactionType)) {
    return null;
  }

  const { label: text } = INTERACTION_TYPES.find(({ value }) => interactionType === value);

  return (
    <View style={styles.interactionType}>
      <Text style={styles.label}>Interaction type: </Text>
      <Text>{text}</Text>
    </View>
  );
}

InteractionType.propTypes = {
  interactionType: PropTypes.oneOf(INTERACTION_TYPES.map(({ value }) => value)),
};

export default InteractionType;
