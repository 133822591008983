import React from 'react';

export const ProfileContext = React.createContext();

function withProfile(Component) {
  return props => (
    <ProfileContext.Consumer>
      {contexts => <Component {...props} {...contexts} />}
    </ProfileContext.Consumer>
  );
}

export default withProfile;
