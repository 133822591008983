import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TextPlaceholder from '../Text';
import styles from './styles.module.scss';

function PageHeader({ isPresentAvatar, isFullWidth }) {
  const headerClassNames = classNames(
    'header-page',
    styles.header,
  );

  const headerContentClassNames = classNames(
    'header-content',
    isFullWidth && styles.fullWidth
  );

  return (
    <header className={headerClassNames}>
      <div className={headerContentClassNames}>
        <div id="subproduct-title" className={styles.titleContainer}>
          {isPresentAvatar && <div className={styles.avatarPlaceholder} />}
          <TextPlaceholder className={styles.title} />
        </div>
      </div>
    </header>
  );
}

PageHeader.defaultProps = {
  isPresentAvatar: false,
  isFullWidth: false,
};

PageHeader.propTypes = {
  isPresentAvatar: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

export default PageHeader;
