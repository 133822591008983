import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Checkbox from 'components/core/Checkbox';

import HeaderPlaceholder from '../Placeholder/HeaderPlaceholder';
import styles from '../../styles.module.scss';
import Tooltip from './components/Tooltip';

const sortIcon = {
  asc: 'fa-arrow-up',
  desc: 'fa-arrow-down',
  no: 'fa-sort',
};
/**
 * Table columns header
 */
export default function Header({
  isInitialLoading,
  isLoading,
  isFixed,
  isCheckboxPresent,
  isRadioButtonPresent,
  sortDirection,
  sortColumn,
  selectAll,
  onSelectAll,
  columns,
  onSort,
  headerRef,
}) {
  const getKey = index => `th-${index}`;
  function iconClass(column) {
    const icon = sortColumn === column ? sortIcon[sortDirection] : sortIcon.no;
    return classNames(styles.sortIcon, `fa ${icon}`);
  }

  function handleSelectAll() {
    const isSelectAll = !selectAll;
    onSelectAll(isSelectAll);
  }

  function isSortable(sortingKey) {
    return !isEmpty(sortingKey) && onSort;
  }

  function handleOnSort({ sortingKey }) {
    return () => {
      if (!sortingKey || !onSort) {
        return;
      }
      onSort(sortingKey);
    };
  }

  function renderHeaderTitle(column) {
    const { sortingKey, label, tooltipContent } = column;

    if (!isSortable(sortingKey)) {
      return (
        <div className={styles.headerText}>
          {label}
          {tooltipContent && <Tooltip content={tooltipContent} />}
        </div>
      );
    }

    return (
      <button
        type="button"
        onClick={handleOnSort({ sortingKey })}
        className={styles.headerButton}
      >
        {label}
        {tooltipContent && <Tooltip content={tooltipContent} />}
        <i className={iconClass(sortingKey)} />
      </button>
    );
  }

  // table headers
  const headers = columns.map((column, index) => (
    <th key={getKey(index)} className={`test__th-${column.keyName}`} data-spec={column.keyName}>
      <span className={styles.hidden}>
        {column.label} &nbsp;
      </span>
      <div className={classNames(styles.headerContent, styles[`th-${column.keyName}`])}>
        {renderHeaderTitle(column)}
      </div>
    </th>
  ));

  if (isLoading || isInitialLoading) {
    return (
      <HeaderPlaceholder
        isFixed={isFixed}
        forwardRef={headerRef}
        isCheckboxPresent={isCheckboxPresent}
        isRadioButtonPresent={isRadioButtonPresent}
        colSpan={columns.length}
      />
    );
  }

  return (
    <thead ref={headerRef}>
      <tr>
        {isCheckboxPresent && (
          <th className={styles.checkboxHeight}>
            <div className={classNames(styles.headerContent, styles.selectAll)}>
              <Checkbox
                className={styles.checkbox}
                onChange={handleSelectAll}
                isChecked={selectAll}
              />
            </div>
          </th>
        )}
        {isRadioButtonPresent && <th />}
        {headers}
      </tr>
    </thead>
  );
}

const columnShape = PropTypes.shape({
  label: PropTypes.string,
  keyName: PropTypes.string.isRequired,
});

Header.propTypes = {
  isInitialLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCheckboxPresent: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool,
  isRadioButtonPresent: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(columnShape),
  onSort: PropTypes.func,
  sortDirection: PropTypes.string,
  sortColumn: PropTypes.string,
  headerRef: PropTypes.object,
  isFixed: PropTypes.bool,
};
