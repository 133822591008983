import React from 'react';
import classNames from 'classnames';

import withDisabledStateTooltip from 'utils/withDisabledStateTooltip';
import Loader from 'components/core/Loader';

import styles from './styles.module.scss';
import { ButtonProps, buttonSizes, buttonThemes, ButtonTypes, loaderThemes } from './types';

function Button({
  onClick,
  type = ButtonTypes.button,
  disabled,
  theme = 'primary',
  size = 'medium',
  customIcon,
  iconClassName,
  isAddIcon = false,
  isRemoveIcon = false,
  children,
  className,
  isLoading,
  loaderClassName,
  innerRef,
  ...restProps
}: ButtonProps) {
  const isDisabled = disabled || isLoading;
  const disabledClassName = isDisabled && styles.isDisabled;

  return (
    <button
      {...restProps}
      ref={innerRef}
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      className={classNames(
        styles.button,
        buttonThemes[theme],
        buttonSizes[size],
        className,
        disabledClassName,
      )}
    >
      {isAddIcon && (
        <div className={classNames(styles.addIcon, iconClassName, disabledClassName)}>
          <i className="fal fa-plus" />
        </div>
      )}

      {isRemoveIcon && (
        <div className={classNames(styles.removeIcon, iconClassName, disabledClassName)}>
          <i className="fal fa-minus" />
        </div>
      )}

      {customIcon && (
        <div className={classNames(styles.icon, iconClassName, disabledClassName)}>{customIcon}</div>
      )}

      {isLoading && (
        <Loader
          size="small"
          theme={loaderThemes[theme]}
          onlyIcon
          className={classNames(styles.loaderDefault, loaderClassName)}
        />
      )}

      {children}
    </button>
  );
}

const ButtonWithTooltip = withDisabledStateTooltip(Button);

export { ButtonWithTooltip };

export default Button;
