import GlobalNotifier from 'components/GlobalNotifier';

const error = message => GlobalNotifier.addNotification({ level: 'error', message });
const success = message => GlobalNotifier.addNotification({ level: 'success', message });

export default {
  failedToSave() {
    error('Failed to save summary. Please try again.');
  },
  savedWithSuccess() {
    success('Summary successfully saved.');
  },
};
