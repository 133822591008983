import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withDimensionLocking } from 'components/core/Table/utils/withDimensionLocking';

import Default from '../Default';

class DnD extends Component {
  ref;

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const { isDragging } = this.props;
    const isDragStarting = isDragging && !prevProps.isDragging;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    return { width, height };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isDragging } = this.props;

    if (!this.ref) {
      return;
    }

    if (snapshot) {
      if (this.ref.style.width === snapshot.width) {
        return;
      }

      this.ref.style.width = `${snapshot.width}px`;
      this.ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (isDragging) {
      return;
    }

    // inline styles not applied
    if (this.ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    this.ref.style.removeProperty('height');
    this.ref.style.removeProperty('width');
  }

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    const { children } = this.props;

    return (
      <Default {...this.props} columnRef={this.setRef}>
        {children}
      </Default>
    );
  }
}

DnD.defaultProps = {
  isDragging: false,
};

DnD.propTypes = {
  isDragging: PropTypes.bool,
  children: PropTypes.func,
};

export default withDimensionLocking(DnD);
