import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import StatementChecker from './StatementChecker';
import { getStatementsStyle } from '../../Definitions/theme';
import styles from '../styles.module.scss';

function Statements({
  isDraggingStatement,
  isDraggingFilter,
  parentOperator,
  parentId,
  statements,
  deleteStatement,
  deleteFilter,
}) {
  return (
    <Droppable droppableId={`droppableStatementsReorder:${parentId}`} type={parentId}>
      {(droppableProvided, droppableSnapshot) => (
        <div
          ref={droppableProvided.innerRef}
          style={getStatementsStyle(droppableSnapshot.isDraggingOver)}
          className={styles.statements}
        >
          {statements.map((statement, index) => (
            <StatementChecker
              key={index}
              keyPrefix={index}
              isLastOperator={statements.length - 1 === index}
              isDraggingOver={droppableSnapshot.isDraggingOver}
              isDraggingStatement={isDraggingStatement}
              isDraggingFilter={isDraggingFilter}
              parentOperator={parentOperator}
              draggableIndex={index}
              parentId={parentId}
              statement={statement}
              deleteStatement={deleteStatement}
              deleteFilter={deleteFilter}
            />
          ))}

          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

Statements.propTypes = {
  isDraggingStatement: PropTypes.bool.isRequired,
  isDraggingFilter: PropTypes.bool.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  statements: PropTypes.array.isRequired,
  deleteStatement: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
};

export default Statements;
