import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { priorities } from 'components/core/PriorityTooltip/constants';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import styles from './styles.module.scss';

export function getPriority(priority) {
  const { label, icon } = priorities[priority] || priorities.no_priority;

  return {
    id: priority,
    label,
    icon,
    iconColor: styles[priority],
  };
}

function PriorityButton({
  isDisabled,
  isLoading,
  priority,
  customLabel,
  className,
  iconClassName,
  loaderClassName,
  onClick,
}) {
  const { label, icon, iconColor } = getPriority(priority);

  function handleClick() {
    onClick && onClick(priority);
  }

  return (
    <Button
      customIcon={!isLoading && <i className={icon} />}
      disabled={isDisabled || isLoading}
      onClick={handleClick}
      theme="light"
      iconClassName={classNames(iconColor, iconClassName)}
      className={className}
    >
      {isLoading && <Loader size="xSmall" onlyIcon className={classNames(styles.loader, loaderClassName)} />}
      {customLabel || label}
    </Button>
  );
}

PriorityButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  priority: PropTypes.oneOf(Object.keys(priorities)),
  customLabel: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default PriorityButton;
