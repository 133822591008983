import React from 'react';
import { isEmpty, truncate, isEqual } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import addProtocolToUrl from 'utils/addProtocolToUrl';
import { getUrlHostname } from 'utils/url';

import { MOBILE_LENGTH, DESKTOP_LENGTH, SIZES } from './constants';
import styles from './styles.module.scss';

function LinkPreview({
  target,
  className,
  title,
  description,
  image,
  url,
  mobileSize,
  desktopSize,
  size,
  show,
}) {
  const { isMobile } = useMediaQuery();

  if (!show) return null;

  const sizes = {
    [SIZES.large]: styles.large,
    [SIZES.medium]: styles.medium,
    [SIZES.small]: styles.small,
  };

  const isSmall = isEqual(size, SIZES.small) || isEqual(size, SIZES.medium) || isMobile;
  const urlWithProtocol = addProtocolToUrl(url);

  const truncateOptions = {
    omission: '...',
    length: isSmall ? mobileSize : desktopSize,
  };

  const renderImage = () => {
    if (isEmpty(image)) {
      return null;
    }

    return (
      <div className={styles.imgWrapper}>
        <img src={image} alt={title} />
      </div>
    );
  };

  const renderUrl = () => {
    if (isEmpty(url)) {
      return null;
    }

    const urlHostname = getUrlHostname(url);

    return urlHostname && (
      <div className={styles.url}>
        <i className="far fa-link" />
        {urlHostname}
      </div>
    );
  };

  return (
    <a
      href={urlWithProtocol}
      target={target}
      className={classNames(styles.container, className)}
    >
      {renderImage()}
      <div className={classNames(styles.content, sizes[size])}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.description}>
          {truncate(description, truncateOptions)}
        </div>
        {renderUrl()}
      </div>
    </a>
  );
}

LinkPreview.defaultProps = {
  target: '_blank',
  size: SIZES.large,
  mobileSize: MOBILE_LENGTH,
  desktopSize: DESKTOP_LENGTH,
  show: true,
};

LinkPreview.propTypes = {
  target: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  mobileSize: PropTypes.number,
  desktopSize: PropTypes.number,
  show: PropTypes.bool,
};

export default LinkPreview;

export * from './api';
export * from './utils';
export { default as Placeholder } from './components/Placeholder';
