import isEmpty from 'lodash/isEmpty';

import { GTE, LTE, BETWEEN } from './constants';

export const rangeOptionFromValues = (start, end) => {
  if (!isEmpty(start) && !isEmpty(end)) {
    return BETWEEN;
  }

  if (!isEmpty(end)) {
    return LTE;
  }

  return GTE;
};
