const errorMessages = {
  fieldName: 'Please enter a label for your question',
  fieldType: 'Please select a field type',
  fieldDescription: 'Please enter a description',
  productSlugs: 'Please select an option',
  options: {
    min: 'Options should contain at least one value, Please add a new option value',
    required: 'Please insert a value',
    unique: 'Each value must be unique',
  },
};

export default errorMessages;
