import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import useModal from 'components/core/Modal/useModal';

import { sectionShape } from './utils/shapes';
import Modal from './components/Modal';
import Button from './components/Button';

export * from './components/Sections';

function Export({
  customIcon,
  buttonClassName,
  buttonTheme,
  customExportButton,
  documentComponent,
  fileName,
  fetchComponentData,
  defaultSectionValues,
  sections,
  previewDocumentTitle,
  onFileExport,
  trackingActions,
}) {
  const { isOpenModal, openModal, toggleModal, closeModal } = useModal(false);

  function openExportModal() {
    const { trackOpenExportModal } = trackingActions || {};

    openModal();
    trackOpenExportModal && trackOpenExportModal();
  }

  const commonProps = {
    isOpenModal,
    closeModal,
    toggleModal,
    openModal: openExportModal,
  };

  function renderButton() {
    if (isFunction(customExportButton)) {
      return customExportButton(commonProps);
    }

    return (
      <Button
        {...commonProps}
        theme={buttonTheme}
        customIcon={customIcon}
        className={buttonClassName}
      />
    );
  }

  return (
    <>
      {renderButton()}
      <Modal
        {...commonProps}
        documentComponent={documentComponent}
        fileName={fileName}
        fetchComponentData={fetchComponentData}
        defaultSectionValues={defaultSectionValues}
        sections={sections}
        previewDocumentTitle={previewDocumentTitle}
        onFileExport={onFileExport}
        trackingActions={trackingActions}
      />
    </>
  );
}

Export.propTypes = {
  documentComponent: PropTypes.node.isRequired,
  fileName: PropTypes.string.isRequired,
  defaultSectionValues: PropTypes.arrayOf(PropTypes.string),
  sections: PropTypes.arrayOf(sectionShape).isRequired,
  previewDocumentTitle: PropTypes.string.isRequired,
  customIcon: PropTypes.node,
  buttonTheme: PropTypes.string,
  buttonClassName: PropTypes.string,
  customExportButton: PropTypes.func,
  fetchComponentData: PropTypes.func,
  onFileExport: PropTypes.func,
  trackingActions: PropTypes.shape({
    trackOpenExportModal: PropTypes.func,
    trackGeneratedExport: PropTypes.func,
  }),
};

export default Export;
