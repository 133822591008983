import moment from 'moment';

import api from 'redux/dashboardV2/common/positions/api';
import { formatDate } from 'utils/date';

import { NO_POSITION_ID, positions } from './constants';

// @TODO: use this function with the hook useNewNote
// to create a grm note in milestone 2
export function getNote(position) {
  const { last_name = '', first_name = '' } = gon.currentUser || {};
  const userName = `${first_name} ${last_name}`;
  const { label = '' } = positions[position];

  // @TODO: Check if any additional data should be sent
  return {
    title: 'New Position set',
    description: `${userName} set position to ${label}`,
    eventDate: formatDate(moment()),
    tags: [],
  };
}

export function handleUpdatePosition({
  currentOption,
  baseParams,
  setIsLoading,
  changeOption,
  rollbackOption,
}) {
  setIsLoading(true);

  if (currentOption === NO_POSITION_ID) {
    return api.deletePosition(baseParams)
      .then(() => changeOption(NO_POSITION_ID))
      .catch(rollbackOption);
  }

  return api.updatePosition({ ...baseParams, position: currentOption })
    .then(() => changeOption(currentOption))
    .catch(rollbackOption)
    .finally(() => setIsLoading(false));
}

export function handleFetchData({ recordId, recordType }) {
  return () => api.fetchPosition({ recordId, recordType });
}
