import isEmpty from 'lodash/isEmpty';

import { defaultHighlightingFilters } from './constants';

export const parseArray = arrayString => JSON.parse(arrayString);

export function getMapBounds(markers) {
  if (isEmpty(markers)) {
    return null;
  }

  const markerBounds = new mapboxgl.LngLatBounds();

  markers.forEach(({ geometry }) => {
    markerBounds.extend(geometry.coordinates);
  });

  return markerBounds;
}

export function toggleLayer(map, layerName) {
  if (map.getLayer(layerName)) {
    const visibility = map.getLayoutProperty(layerName, 'visibility');
    const visibilityValue = !visibility || visibility === 'visible' ? 'none' : 'visible';

    map.setLayoutProperty(layerName, 'visibility', visibilityValue);
  }
}

export function addDefaultLayers(map) {
  const commonOptions = {
    type: 'fill',
    source: {
      type: 'vector',
      url: `mapbox://${gon.mapboxTilesetId}`,
    },
    'source-layer': gon.mapboxSourceLayer,
    filter: defaultHighlightingFilters,
  };

  map.addLayer({
    ...commonOptions,
    id: 'district-fill',
    type: 'fill',
    paint: {
      'fill-color': 'black',
      'fill-opacity': 0.2,
    },
  });

  map.addLayer({
    ...commonOptions,
    id: 'district-stroke',
    type: 'line',
    paint: {
      'line-color': 'black',
      'line-opacity': 0.4,
      'line-width': 2.0,
    },
  });
}

export function addStateHighlight({ map, kwDistrictId, mapType }) {
  if (!map) return;

  if (kwDistrictId && mapType) {
    const filter = [
      'all',
      ['==', 'type', mapType],
      ['==', 'kwDistrictId', kwDistrictId],
    ];

    map.setFilter('district-fill', filter);
    map.setFilter('district-stroke', filter);
  }
}

export function addFocusZoom({ map, latitude, longitude, options = { maxZoom: 7 } }) {
  if (!map || !latitude || !longitude) {
    return;
  }

  const latLng = { lat: latitude, lng: longitude };

  map.fitBounds([latLng, latLng], options);
}

export function addMarkerImage(map, markerImage = '/images/map/map-marker.png', onImageLoad) {
  map.loadImage(markerImage, (error, res) => {
    if (error) throw error;
    map.addImage('map-marker', res);

    onImageLoad();
  });
}

export function addMapSource(sourceName, data, map, isDataClustered) {
  map.addSource(sourceName, {
    type: 'geojson',
    data,
    cluster: isDataClustered,
    clusterMaxZoom: 14,
    clusterRadius: 50,
  });
}
