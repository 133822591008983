import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import { Column } from 'components/core/Table';
import ReceiptModal from 'components/core/Moneyball/components/ReceiptModal';
import { queryParser } from 'utils/query-string';

import useTableReceipts from '../../useTableReceipts';
import styles from './styles.module.scss';

function FilingColumn({ keyName, item, history, receiptState, searchReceipt, isReceiptLoading, receiptError }) {
  const { receipts } = item || {};
  const { id, is_pdf_available, filing_source_url } = receipts || {};
  const isDisabled = !is_pdf_available && !filing_source_url;

  const searchParams = queryParser(history.location.search, { arrayFormat: 'none' });

  const {
    isReceiptModalOpen,
    receiptModalId,
    handleReceiptModal,
  } = useTableReceipts({ searchParams });

  const actionsMap = {
    receipt: receiptState,
    receiptError,
    searchReceipt,
    isReceiptLoading,
    exportModalTitle: 'Receipt export',
  };

  const receiptModalProps = {
    history,
    actionsMap,
    searchParams,
    receiptModalId,
    appliedFilters: {},
    handleReceiptModal,
  };

  const renderReceipt = () => (
    <Fragment>
      <i className={classNames('fal fa-receipt', styles.receipts)} onClick={() => handleReceiptModal(id)} />
      {isReceiptModalOpen && <ReceiptModal {...receiptModalProps} />}
    </Fragment>
  );

  const renderDisabledReceipt = () => (
    <Tooltip
      content="The original filing appears to be unavailable"
      className={styles.tooltip}
    >
      <i className={classNames('fal fa-receipt', styles.receipts, styles.disabled)} />
    </Tooltip>
  );

  return (
    <Column key={keyName} keyName={keyName}>
      {isDisabled ? renderDisabledReceipt : renderReceipt}
    </Column>
  );
}

FilingColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
  history: PropTypes.object,
  receiptState: PropTypes.object,
  searchReceipt: PropTypes.func,
  isReceiptLoading: PropTypes.bool,
  receiptError: PropTypes.bool,
};

export default FilingColumn;
