import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import CardGroupPlaceholder from './CardGroupPlaceholder';
import dayEventStyles from '../DayEvents/styles.module.scss';
import styles from './styles.module.scss';

function Placeholder({ events, isFederal }) {
  const { isMobile } = useMediaQuery();

  return (
    <div className={dayEventStyles.dayEvent}>
      <div className={styles.box}>
        <span className={styles.boxText} />
      </div>
      {events.map((event, id) => (
        <CardGroupPlaceholder
          {...event}
          key={id}
          isMobile={isMobile}
          isFederal={isFederal}
        />
      ))}
    </div>
  );
}

Placeholder.propTypes = {
  events: PropTypes.array,
  isFederal: PropTypes.bool,
};

Placeholder.defaultProps = {
  events: [
    { senate: true, expired: false },
    { senate: false, expired: false },
    { senate: true, expired: true },
  ],
};

export default Placeholder;
