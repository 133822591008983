import useCompanyLogo from 'components/core/ExportDocument/components/ExportModal/utils/useCompanyLogo';
import { DEFAULT_LOGO_URL } from 'utils/constants';

function useLogo(showMyLogo) {
  const {
    logoUrl,
    companyHasLogo,
    isLogoLoading,
  } = useCompanyLogo({ displayCompanyLogo: true, isOpenModal: true });

  if (!showMyLogo || isLogoLoading || !companyHasLogo) {
    return {
      companyHasLogo,
      logoUrl: DEFAULT_LOGO_URL,
    };
  }

  return {
    companyHasLogo,
    logoUrl,
  };
}

export default useLogo;
