import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function TipMessage({
  children,
  className,
  isVisible,
}) {
  if (!isVisible) return null;

  return (
    <span className={classNames(styles.tip, className)}>
      <span className={styles.tipLabel}>TIP:</span>
      {children}
    </span>
  );
}

TipMessage.defaultProps = {
  isVisible: true,
};

TipMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
};

export { default as Kbd } from './components/Kbd';
export default TipMessage;
