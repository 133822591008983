import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_TEXT_COLOR,
  SOCIAL_MEDIA_LINE_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    color: DEFAULT_TEXT_COLOR,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  textSection: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  rowSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  labelText: {
    fontSize: 30,
    fontWeight: 700,
    paddingRight: 10,
  },
  descriptionText: {
    color: DEFAULT_TEXT_COLOR,
    fontSize: 8,
    fontWeight: 400,
    paddingBottom: 8,
  },
  tagShape: {
    borderRadius: 2,
    padding: '1px 3px',
    fontSize: 8,
  },
  colorLessTag: {
    backgroundColor: SOCIAL_MEDIA_LINE_COLOR,
    borderWidth: 0,
    color: DEFAULT_TEXT_COLOR,
    margin: '0 2px',
  },
});

export default styles;
