import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useSortTable from 'utils/useSortTable';
import { getColumnLabelBySortingKey } from 'utils/table';

function useTable({ sort: sortRedux, paginator, onSearch, onClearAll, trackingActions, tableColumns }) {
  const { trackBillsSorted, trackBillsPageVisited } = trackingActions;
  const sort = useSortTable();
  const isPaginatorPresent = !isEmpty(paginator);

  /** Fix default sort  */
  useEffect(() => {
    if (!isEmpty(sortRedux)) {
      const { sort: reduxColumn, direction } = sortRedux;

      sort && sort.setSortData({ direction, column: reduxColumn });
    }
  }, [sortRedux]);

  function handleSortColumn(column) {
    const { getDirection } = sort;
    const direction = getDirection(column);
    const columnLabel = getColumnLabelBySortingKey(tableColumns, column);

    onSearch({ sort: column, direction });
    trackBillsSorted && trackBillsSorted(columnLabel, direction);
  }

  function handleChangePage(page) {
    const { sortData = {} } = sort;
    const { column, direction } = sortData;

    onSearch({ page, sort: column, direction });
    trackBillsPageVisited && trackBillsPageVisited(page);
  }

  function handleClearAll() {
    onClearAll();
  }

  return {
    ...sort,
    isPaginatorPresent,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
    onClear: handleClearAll,
  };
}

export default useTable;
