import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ViewTags from 'components/core/ViewTags';
import { Column } from 'components/core/Table';
import { SIZES as TAG_SIZES } from 'components/core/ViewTags/constants';

import { itemShape } from '../utils/shapes';
import styles from '../AddedToItem/styles.module.scss';

function TagsItem({
  isColumn,
  keyName,
  item,
  isLoading,
  className,
  onChangeFilter,
}) {
  const { tags = [] } = item || {};

  const onChange = (tag) => {
    onChangeFilter && onChangeFilter('tags', [tag]);
  };

  const renderItems = () => (
    <div className={classNames(styles.badgesContainer, className)}>
      <ViewTags
        isLoading={isLoading}
        tags={tags}
        size={TAG_SIZES.small}
        tileClassName={styles.tile}
        onClick={onChange}
      />
    </div>
  );

  if (!isColumn) {
    return renderItems();
  }

  return (
    <Column key={keyName} keyName={keyName} className={styles.container}>
      {renderItems}
    </Column>
  );
}

TagsItem.defaultProps = {
  isColumn: true,
};

TagsItem.propTypes = {
  isColumn: PropTypes.bool,
  keyName: PropTypes.string,
  item: itemShape,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onChangeFilter: PropTypes.func,
};

export default TagsItem;
