import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';

import TextPlaceholder from '../Text';

function ButtonGroupPlaceholder({ btnLength, btnTheme, btnSize, className }) {
  return (
    <ButtonGroup className={className}>
      {Array(btnLength).fill(0).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Button key={`btn-placeholder-${index}`} theme={btnTheme} size={btnSize}>
          <TextPlaceholder />
        </Button>
      ))}
    </ButtonGroup>
  );
}

ButtonGroupPlaceholder.defaultProps = {
  btnLength: 1,
  btnTheme: 'default',
  btnSize: 'small',
};

ButtonGroupPlaceholder.propTypes = {
  btnLength: PropTypes.number,
  btnTheme: PropTypes.string,
  btnSize: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonGroupPlaceholder;
