import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, trim } from 'lodash';
import classNames from 'classnames';

import { TextareaWithCharInfo } from 'components/core/CharInfo';

import styles from './styles.module.scss';

function SummaryContent({ isOpen, content, updatedAt, onChange, className }) {
  const editElement = (
    <div className={classNames(styles.edit, className)}>
      <TextareaWithCharInfo
        value={content}
        onChange={onChange}
      />
    </div>
  );

  const viewElement = (
    <div className={classNames(styles.view, className)}>
      <p>Summary</p>
      <div className={styles.content}>
        <span className={styles.updatedAt}>Last updated: {updatedAt}. </span>
        {content}
      </div>
    </div>
  );

  if (isOpen) {
    return editElement;
  }

  if (isEmpty(trim(content))) {
    return null;
  }

  return viewElement;
}

SummaryContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string,
  content: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SummaryContent;
