import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import styles from './styles.module.scss';

function SourceLabel({ author, source, className, publishedAt }) {
  const { name: sourceTitle } = source || {};

  const sourceDescription = (sourceTitle && author)
    ? `${sourceTitle} by ${author}`
    : sourceTitle || author;
  const publishedAtNode = !isEmpty(publishedAt) && ` - ${publishedAt}`;

  return (
    <span className={classNames(styles.source, className)}>
      {sourceDescription}
      {publishedAtNode}
    </span>
  );
}

SourceLabel.propTypes = {
  author: PropTypes.string,
  source: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  publishedAt: PropTypes.string,
};

export default SourceLabel;
