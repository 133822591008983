import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, trim } from 'lodash';

import { stringNumberType } from 'utils/shapes';
import Textarea from 'components/core/Textarea';

import ConfirmationModal from '../Confirmation';
import { submitEditorialAlert } from '../../utils/api';
import { modalThemes } from '../Modal/helpers';
import styles from './styles.module.scss';

function EditorialAlert({
  isOpen,
  toggleOpen,
  fullName,
  personId,
  personTitle,
  productSlug,
  overlayClassName,
  theme,
  onRequestClose,
  onCrossBtnClick,
  shouldReturnFocusAfterClose,
  parentSelector,
}) {
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const onMessageChange = ({ target: { value } }) => {
    setMessage(value);
    setHasError(isEmpty(trim(value)));
  };

  const handleSubmit = () => {
    if (isEmpty(trim(message))) {
      setHasError(true);
      return;
    }

    submitEditorialAlert({
      message,
      productSlug,
      fullName,
      personId,
      personTitle,
      onSuccess: toggleOpen,
    });
  };

  const clearMessage = () => {
    setMessage('');
    setHasError(false);
  };

  useEffect(clearMessage, [isOpen]);

  const title = `${fullName}: submit editorial alert`;
  const errorMessage = hasError ? "This field can't be blank" : '';

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggleOpen}
      title={title}
      className={styles.modal}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      messageContinue="Submit"
      withAction
      keepItOpened
      action={handleSubmit}
      overlayClassName={overlayClassName}
      theme={theme}
      onRequestClose={onRequestClose}
      onCrossBtnClick={onCrossBtnClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      parentSelector={parentSelector}
    >
      <div className={styles.contentText}>
        <p>
          This is the place to submit a change request to our editors for any of our records. Additions,
          deletions, inaccuracies and misspellings can all be brought our immediate attention using this simple form.
        </p>

        Enter your suggested changes below.
      </div>

      <Textarea
        className={styles.textArea}
        value={message}
        onChange={onMessageChange}
        errorMessage={errorMessage}
      />
    </ConfirmationModal>
  );
}

EditorialAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  personId: stringNumberType,
  personTitle: PropTypes.string,
  productSlug: PropTypes.string,
  overlayClassName: PropTypes.string,
  theme: PropTypes.oneOf(
    Object.keys(modalThemes)
  ),
  onRequestClose: PropTypes.func,
  onCrossBtnClick: PropTypes.func,
  shouldReturnFocusAfterClose: PropTypes.bool,
  parentSelector: PropTypes.func,
};

export default EditorialAlert;
