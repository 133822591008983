import { useEffect } from 'react';

import useMap from 'components/core/Map/utils/useMap';
import { addDefaultLayers, addFocusZoom, addStateHighlight } from 'components/core/Map/utils';

function useMapProfile({
  mapRef,
  data,
  defaultFilters,
  withFilters,
  withNavigation,
  isLoadingMap,
  center,
  kwDistrictId,
  latitude,
  longitude,
}) {
  const mapProps = {
    mapRef,
    data,
    defaultFilters,
    withNavigation,
    withFilters,
    isLoadingMap,
    center,
  };

  const { mapType } = defaultFilters;
  const { map, setFilters } = useMap(mapProps);

  function getFocusState(mapInstance) {
    mapInstance.once('styledata', () => {
      addDefaultLayers(mapInstance);
      setFilters(mapType);
      addFocusZoom({
        map: mapInstance,
        latitude,
        longitude,
        options: {
          maxZoom: 7,
          duration: 0,
          padding: 120,
        },
      });
      addStateHighlight({ map: mapInstance, kwDistrictId, mapType });
    });
  }

  useEffect(() => {
    if (map && center) {
      map.scrollZoom.disable();
      getFocusState(map);
    }
  }, [map, center]);

  return { map };
}

export default useMapProfile;
