import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/core/Checkbox';
import TextPlaceholder from 'components/core/Placeholder/components/Text';

import parentStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

function BodyPlaceholder({ rowLengthPlaceholder, columnLength, isCheckboxPresent }) {
  const placeholderColumnsLength = columnLength > 12 ? 12 : columnLength;
  /** Fix uncontrolled input error for placeholder */
  function handleChange() {}

  return (
    <tbody>
      {Array(rowLengthPlaceholder).fill(0).map((item, rowIndex) => (
        <tr key={`row-${rowIndex}`}>

          {isCheckboxPresent && (
            <td className={parentStyles.checkboxWidth} key={`selection-column-${rowIndex}`}>
              <Checkbox className={parentStyles.checkbox} onChange={handleChange} />
            </td>
          )}

          {Array(placeholderColumnsLength).fill(0).map((_, index) => (
            <td key={`item-${index}`} className={styles.tableCell}>
              <TextPlaceholder className={parentStyles.textPlaceholder} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

BodyPlaceholder.defaultProps = {
  rowLengthPlaceholder: 18,
};

BodyPlaceholder.propTypes = {
  columnLength: PropTypes.number.isRequired,
  rowLengthPlaceholder: PropTypes.number,
  isCheckboxPresent: PropTypes.bool,
};

export default BodyPlaceholder;
