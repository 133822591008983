import { transformForUI } from './transformers';

export function getSummaryAPI({ recordType, recordId }) {
  const fetchSummary = () => {
    const url = `/api_web/summaries/${recordType}/${recordId}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(transformForUI);
  };

  const deleteSummary = () => {
    const [method, url] = ['DELETE', `/api_web/summaries/${recordType}/${recordId}`];
    const ajax = $.ajax({ url, method });

    return Promise.resolve(ajax);
  };

  const saveSummary = ({ content, summaryId }) => {
    const [method, url] = summaryId
      ? ['PUT', `/api_web/summaries/${recordType}/${recordId}`]
      : ['POST', '/api_web/summaries'];

    const data = {
      record_type: recordType,
      record_id: recordId,
      body: content,
    };

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then(transformForUI);
  };

  return {
    fetchSummary,
    deleteSummary,
    saveSummary,
  };
}
