import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BulkEmail, BulkExport } from '../Actions';
import styles from './styles.module.scss';

function CommonActions({
  ui,
  data,
  dataProps,
  appliedSearches,
  submitExport,
  submitAllExport,
  exportReport,
  paginator,
  getAllEmails,
  areEmptyEmails,
  tracking,
  customKeyMessage,
  sort,
  HeaderButtons,
  renderHeaderButtons,
  exportModal,
}) {
  const withExport = exportReport && (submitExport || submitAllExport);

  const commonProps = {
    ui,
    data,
    paginator,
    tracking,
  };

  const bulkExportProps = {
    dataProps,
    appliedSearches,
    submitExport,
    submitAllExport,
    exportReport,
    customKeyMessage,
    exportModal,
  };

  const bulkEmailProps = {
    ui,
    getAllEmails,
    areEmptyEmails,
  };

  return (
    <div className={classNames('header__actions', styles.actionsContent)}>
      {renderHeaderButtons && renderHeaderButtons({ appliedSearches })}

      {HeaderButtons && (
        <HeaderButtons appliedSearches={appliedSearches} />
      )}

      <BulkEmail {...commonProps} {...bulkEmailProps} />

      {withExport && (
        <BulkExport {...commonProps} sort={sort} {...bulkExportProps} />
      )}
    </div>
  );
}

CommonActions.defaultProps = {
  areEmptyEmails: false,
  tracking: {},
};

CommonActions.propTypes = {
  ui: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataProps: PropTypes.object,
  appliedSearches: PropTypes.object,
  submitExport: PropTypes.func,
  submitAllExport: PropTypes.func,
  exportReport: PropTypes.object,
  paginator: PropTypes.object.isRequired,
  getAllEmails: PropTypes.func,
  areEmptyEmails: PropTypes.bool,
  tracking: PropTypes.object,
  customKeyMessage: PropTypes.string,
  sort: PropTypes.object,
  HeaderButtons: PropTypes.elementType,
  renderHeaderButtons: PropTypes.func,
  exportModal: PropTypes.func,
};

export default CommonActions;
