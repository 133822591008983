import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const boxThemes = {
  default: styles.default,
};

function Box({ text, theme, className }) {
  return (
    <div className={classNames(styles.box, boxThemes[theme], className)}>{text}</div>
  );
}

Box.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.string,
  className: PropTypes.string,
};

Box.defaultProps = {
  text: '',
  className: '',
  theme: 'default',
};

export default Box;
