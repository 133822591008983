import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import KEY_CODES from 'utils/keyCodes';
import {
  isLastPosition,
  normalizeArrayToString,
  moveCursorBackward,
  moveCursorForward,
} from './index';

function useTextValidator({ text, onValidationStatusChange }) {
  const requiredLetters = text ? text.toUpperCase().split('') : [];
  const [typedLetters, setTypedLetters] = useState([]);
  const fieldRefs = [];

  useEffect(() => {
    if (!isEmpty(requiredLetters)) {
      const requiredString = normalizeArrayToString(requiredLetters);
      const typedString = normalizeArrayToString(typedLetters);

      onValidationStatusChange(typedString === requiredString);
    }
  }, [typedLetters]);

  function moveCursor(index, isForward = true) {
    isForward ?
      moveCursorForward(index, requiredLetters, fieldRefs) :
      moveCursorBackward(index, requiredLetters, fieldRefs);
  }

  function handleInputChange(value, index) {
    const letters = [].concat(typedLetters);

    if (isEmpty(value)) {
      letters[index] = null;
      setTypedLetters(letters);

      moveCursor(index, false);
      return;
    }

    letters[index] = value;
    setTypedLetters(letters);
    !isLastPosition(index, requiredLetters) && moveCursor(index);
  }

  function handleChange(event, index) {
    const typedLetter = event.target.value.toUpperCase();

    handleInputChange(typedLetter, index);
  }

  function handleKeyDown(event, index) {
    const letter = typedLetters[index];

    if (event.keyCode === KEY_CODES.TAB) {
      moveCursor(index + 1, false);

      return;
    }

    if (isEmpty(letter) && event.keyCode === KEY_CODES.BACKSPACE) {
      event.preventDefault();
      moveCursor(index, false);

      return;
    }

    if (!isEmpty(letter) && event.keyCode === KEY_CODES.ENTER) {
      event.preventDefault();
      moveCursor(index);

      return;
    }
  }

  function handleFocus(event) {
    event.target.select();
  }

  return {
    requiredLetters,
    typedLetters,
    fieldRefs,
    handleFocus,
    handleChange,
    handleKeyDown,
  };
}

export default useTextValidator;
