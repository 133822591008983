import React from 'react';
import PropTypes from 'prop-types';

import { positionShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getPositionDescription, getAddressTable, getPhoneTable } from './utils';
import styles from './styles.module.scss';

function Position({ position, positionIndex, isLoading }) {
  const {
    address = {},
    phones = [],
  } = position || {};

  const addressTable = getAddressTable(address);
  const phoneTable = getPhoneTable(phones);

  const sections = [{
    id: 'publicationsSection',
    title: `Role/Office ${positionIndex}`,
    titleDescription: getPositionDescription(position),
    tables: [addressTable, phoneTable],
    tablesClassName: styles.tablesContainer,
  }];

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
      tableClassName={styles.table}
    />
  );
}

Position.propTypes = {
  position: positionShape,
  positionIndex: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default Position;
