import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';

import LabelTitle from '../LabelTitle';

const styles = StyleSheet.create({
  list: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 12,
  },
  comma: {
    marginRight: 5,
  },
  item: {
    display: 'flex-inline',
    flexDirection: 'row',
  },
});

function LabelItemList({ style, listStyle, labelStyle, label, children, hideComma }) {
  /*
  * To skip null/undefined children we will use Children.toArray API.
  * https://github.com/facebook/react/issues/7685
  */
  const itemsLength = Children.toArray(children).length;
  const listStyles = { ...styles.list, ...listStyle };

  function renderItem(child, index) {
    const { key } = child;
    const shouldDisplayComma = !hideComma && index !== itemsLength - 1;

    return (
      <View style={styles.item} key={key} wrap={false}>
        {child}
        {shouldDisplayComma && (<Text style={styles.comma}>,</Text>)}
      </View>
    );
  }

  function renderItems() {
    if (itemsLength === 0) {
      return <Text>{EMPTY_VALUE}</Text>;
    }

    return Children.map(Children.toArray(children), renderItem);
  }

  return (
    <View style={style}>
      <LabelTitle label={label} style={labelStyle} />
      <View style={listStyles}>
        {renderItems()}
      </View>
    </View>
  );
}

LabelItemList.defaultProps = {
  style: {},
  listStyle: {},
  labelStyle: {},
};

LabelItemList.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
  listStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  hideComma: PropTypes.bool,
};

export default LabelItemList;
