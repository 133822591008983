import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { CARD_TYPES } from 'components/Grm2/constants';
import Tags from 'components/core/Profile/components/Export/components/Tags';
import { linkShape, tagShape } from 'components/Grm2/shapes';

import CreatedBy from '../CreatedBy';
import CreationDate from '../CreationDate';
import Description from '../Description';
import EventDate from '../EventDate';
import InteractionType from '../InteractionType';
import Links from '../Links';
import Title from '../Title';
import Attachments from '../Attachments';

import styles from './styles';

function Card({
  title,
  eventDate,
  interactionType,
  createdBy,
  creationDate,
  description,
  tags,
  links,
  cardType,
  uploads,
}) {
  const { label } = CARD_TYPES[cardType];

  return (
    <View style={styles.card}>
      <Title>{title}</Title>
      <View style={styles.subtitle}>
        <Text style={styles.cardType}>{`${label} - `}</Text>
        <CreationDate>{creationDate}</CreationDate>
      </View>
      <Description>
        {description}
      </Description>
      <Tags tags={tags} />
      <EventDate eventDate={eventDate} />
      <InteractionType interactionType={interactionType} />
      <Links links={links} />
      <CreatedBy createdBy={createdBy} />
      <Attachments uploads={uploads} />
    </View>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  interactionType: PropTypes.string,
  createdBy: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  links: PropTypes.arrayOf(linkShape).isRequired,
  cardType: PropTypes.oneOf(Object.keys(CARD_TYPES)),
  uploads: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Card;
