import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { subscriptionShape } from 'redux/subscriptions/utils/shapes';

import { ICON_TYPES } from './utils/constants';
import styles from './styles.module.scss';

function CommitteeIcon({ subscriptions }) {
  const icons = map(subscriptions, 'subscribableType');

  function renderIcon(type) {
    const { [type]: icon } = ICON_TYPES;

    return <i className={styles.icon} key={type}>{icon}</i>;
  }

  return (
    <>
      {map(icons, renderIcon)}
    </>
  );
}

CommitteeIcon.propTypes = {
  subscriptions: PropTypes.arrayOf(subscriptionShape).isRequired,
};

export default CommitteeIcon;
