import { useState, useEffect } from 'react';
import { some, map, isNil } from 'lodash';

import { COMMITTEE_SECTION } from 'redux/dashboardV2/constants';
import useModal from 'components/core/Modal/useModal';
import useWorkspaces from 'components/core/TrackButton/utils/useWorkspaces';
import useLoader from 'utils/useLoader';

function useBulkTrack({
  isMultipleItems,
  defaultSubscription,
  initialSubscription,
  selectedItems,
  itemType,
  onBulkTrack,
}) {
  const {
    frequency: selectedFrequency,
    sendWeekAheadEmail: initialSendWeekAheadEmail,
  } = initialSubscription || {};
  const { isOpenModal, toggleModal } = useModal();
  const { isLoading, stopLoading, startLoading } = useLoader();
  const workspaces = useWorkspaces();

  const selectedItemsIdsValues = map(selectedItems, ({ id }) => ({ value: id }));

  const defaultFrequency = isNil(selectedFrequency) || isMultipleItems
    ? defaultSubscription
    : selectedFrequency;

  const defaultSendWeekAhead = isNil(initialSendWeekAheadEmail) || isMultipleItems
    ? false
    : initialSendWeekAheadEmail;

  const [frequency, setFrequency] = useState(defaultFrequency);
  const [sendWeekAheadEmail, setSendWeekAheadEmail] = useState(defaultSendWeekAhead);

  useEffect(() => {
    setFrequency(defaultFrequency);
    setSendWeekAheadEmail(defaultSendWeekAhead);
    !isOpenModal && workspaces.reset();
  }, [isOpenModal]);

  // TODO: Fix committee bulk track
  const withWeekAheadEmail = some(selectedItems,
    ({ subscription }) => {
      const { sectionType } = subscription || {};

      return sectionType === COMMITTEE_SECTION;
    });

  function onFinally() {
    stopLoading();
    toggleModal();
  }

  function save(value) {
    startLoading();

    return onBulkTrack({ values: selectedItemsIdsValues, frequency: value, workspaces, itemType })
      .finally(onFinally);
  }

  function handleSaveMobile(value) {
    save(value);
  }

  function handleSave() {
    save(frequency);
  }

  function handleSaveTrackCommittee(data) {
    startLoading();

    return onBulkTrack({ ...data, values: selectedItemsIdsValues, itemType })
      .finally(onFinally);
  }

  function handleChange(value) {
    setFrequency(value);
  }

  function handleChangeSendEmail({ target: { checked } = {} }) {
    setSendWeekAheadEmail(checked);
  }

  function handleCancel() {
    workspaces.reset();
    toggleModal();
  }

  return {
    isLoading,
    isOpenModal,
    toggleModal,
    withWeekAheadEmail,
    sendWeekAheadEmail,
    selectedFrequency: frequency,
    onSave: handleSave,
    onSaveMobile: handleSaveMobile,
    onSaveTrackCommittee: handleSaveTrackCommittee,
    onChangeSendEmail: handleChangeSendEmail,
    onChangeFrequency: handleChange,
    workspaces,
    onCancel: handleCancel,
  };
}

export default useBulkTrack;
