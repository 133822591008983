import reduxConnect from 'utils/redux-connect';
import { fetchStakeholders } from 'redux/stakeholder/Lookup/actions/data';
import { submitExport } from 'redux/stakeholder/Lookup/actions/export';
import * as tagActions from 'redux/stakeholder/Lookup/actions/tags';
import * as listActions from 'redux/stakeholder/Lookup/actions/lists';
import * as commonActions from 'redux/stakeholder/common/actions';
import * as customFieldsActions from 'redux/customFields/actions';

import Container from './Container';

const actions = {
  ...tagActions,
  ...listActions,
  ...commonActions,
  ...customFieldsActions,
  fetchStakeholders,
  submitExport,
};

export default reduxConnect('stakeholder', actions)(Container);
