import moment from 'moment';
import map from 'lodash/map';

import constants from './constants';

const { SINGLE_CHAR_WEEKDAY_FORMAT } = constants;

// Transform moment's min day format of 2 characters 'dd', eg. 'Mo', 'Tu', 'We' etc., to single character - 'M', 'T', 'W' etc. to proper display new designs
export const setDayFormatToSingleChar = () => moment.updateLocale('en', { weekdaysMin: SINGLE_CHAR_WEEKDAY_FORMAT.split('_') });

export const normalizeDate = date => ((date && date.toDate) ? date.toDate() : date);

const normalizeCustomHighlights = highlightDates => (
  map(highlightDates, (highlights) => {
    const [highlightsKey] = Object.keys(highlights);

    return { [highlightsKey]: map(highlights[highlightsKey], normalizeDate) };
  })
);

export const normalizeHighlightDates = (highlightDates) => {
  if (!highlightDates) return [];

  const [highlightsHead] = highlightDates;

  if (moment.isMoment(highlightsHead) || highlightsHead instanceof Date) {
    return map(highlightDates, normalizeDate);
  }

  return normalizeCustomHighlights(highlightDates);
};
