import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import ListSearches from './ListSearches';
import OptionsSection from './OptionsSection';
import FooterActions from './FooterActions';
import styles from '../styles.module.scss';

function DesktopView({ optionsClassName, searchesClassName, onSearch }) {
  const onSubmit = (event) => {
    event.preventDefault();
    onSearch && onSearch();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.container}>
        <div className={classNames(styles.options, optionsClassName)}>
          <OptionsSection />
        </div>

        <div className={classNames(styles.searches, searchesClassName)}>
          <ListSearches />
        </div>
      </div>

      <FooterActions />
    </form>
  );
}

DesktopView.propTypes = {
  optionsClassName: PropTypes.string,
  searchesClassName: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default withSearch(DesktopView);
