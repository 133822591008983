import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import textToHyperLink from 'utils/textToHyperLink';

import {
  defaultRenderMention,
  defaultBuildLink,
  getContentWithParsedMentions,
} from './utils';
import styles from './styles.module.scss';

function TextWithMentions({ children, renderMention, buildLink, isPDF }) {
  const contentWithParsedMention = getContentWithParsedMentions(children, buildLink);

  return (
    <>
      {contentWithParsedMention.map(
        ({ isMention, ...contentSlice }, index) => (
          // In general, using index in key may cause some problems,
          // but since it is only view component, that is enough
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${index}:${contentSlice.text}`}>
            {(isMention || isPDF) ? renderMention(contentSlice) : textToHyperLink({ text: contentSlice.text, className: styles.hyperlink })}
          </Fragment>
        )
      )}
    </>
  );
}

TextWithMentions.defaultProps = {
  renderMention: defaultRenderMention,
  buildLink: defaultBuildLink,
  isPDF: false,
};

TextWithMentions.propTypes = {
  children: PropTypes.string.isRequired,
  renderMention: PropTypes.func,
  buildLink: PropTypes.func,
  isPDF: PropTypes.bool,
};

export default TextWithMentions;
