import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function LabelGroup({ children, className, containerClassName, isError }) {
  return (
    <ul className={classNames(styles.group, isError && styles.errorGroup, containerClassName)}>
      {
        Children.map(children, child => child && (
          <li className={classNames(styles.item, className)}>
            {child}
          </li>
        ))
      }
    </ul>
  );
}

LabelGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isError: PropTypes.bool,
};

export default LabelGroup;
