import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import { BaseOption } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function BaseButtonGroup({
  id,
  value,
  onChange,
  className,
  title,
  placeholder,
  questionText,
  data,
}) {
  function handleButtonClick(props) {
    return () => onChange(props);
  }

  function renderActiveContent() {
    const filteredItem = data.find(({ value: dataValue }) => dataValue === value) || {};

    return filteredItem.content;
  }

  const renderComponent = () => (
    <Fragment>
      <ButtonGroup className={styles.buttonGroup}>
        {data.map(({ label, value: dataValue }, index) => (
          <Button
            theme="default"
            size="medium"
            key={`${id}-${index}`}
            value={dataValue}
            onClick={handleButtonClick(dataValue)}
            className={classNames(styles.button, dataValue === value && styles.isActive)}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>

      {renderActiveContent()}
    </Fragment>
  );

  return (
    <BaseOption
      title={title}
      placeholder={placeholder}
      questionText={questionText}
      className={className}
      customComponent={renderComponent()}
    />
  );
}

BaseButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  questionText: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default BaseButtonGroup;
