import { StyleSheet } from '@react-pdf/renderer';

import { DEFAULT_LINE_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  billFooterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: DEFAULT_LINE_COLOR,
    padding: '7px 10px',
    width: '100%',
  },
});

export default styles;
