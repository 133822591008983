import { useEffect, useState } from 'react';
import { utcDayEnd, utcDayStart } from 'utils/date';

import { areValuesChanged } from 'components/core/AdvancedSearch/utils';

function useOptions({ setAllowSubmit, onChangeHandleState, searchValue, emptyState }) {
  const [optionValues, setOptionValues] = useState(emptyState.options);

  /** Set enable/disable search button */
  useEffect(() => {
    setAllowSubmit(areValuesChanged(optionValues, emptyState, searchValue));
  }, [optionValues, searchValue]);

  function resetValues() {
    setOptionValues(emptyState.options);
  }

  function handleChange(name, value) {
    setOptionValues(prevProps => ({ ...prevProps, [name]: value }));
    onChangeHandleState();
  }

  function handleChangeRange(value, property, isStart = false) {
    setOptionValues(prevProps => ({
      ...prevProps,
      [property]: {
        ...prevProps[property],
        [isStart ? 'start' : 'end']: value,
      },
    }));

    onChangeHandleState();
  }

  function handleChangeStart(start, property) {
    handleChangeRange(utcDayStart(start), property, true);
  }

  function handleChangeEnd(end, property) {
    handleChangeRange(utcDayEnd(end), property);
  }

  return {
    optionValues,
    resetValues,
    setOptionValues,
    onChange: handleChange,
    onChangeDateRange: handleChangeRange,
    onChangeStart: handleChangeStart,
    onChangeEnd: handleChangeEnd,
  };
}

export default useOptions;
