import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFunction } from 'lodash';

import useAvatar from './useAvatar';
import { getInitials } from './utils';
import styles from './styles.module.scss';

function Avatar({
  photoUrl = '',
  name = '',
  icon = '',
  onClick = () => {},
  className = '',
  iconClassName = '',
  avoidDefaultIcon = true,
  isClickable = true,
  onError = () => {},
  ...restProps
}) {
  const isAvatarClickable = isClickable && isFunction(onClick);
  const avatar = useAvatar(photoUrl, avoidDefaultIcon, onError);

  const clickableClassName = isAvatarClickable && styles.clickable;
  const clickableProps = isAvatarClickable && {
    onClick,
    role: 'button',
  };

  return (
    <div
      className={classNames(styles.avatar, className, clickableClassName)}
      {...clickableProps}
      {...restProps}
    >
      {avatar.isValid && (
        <img
          src={photoUrl}
          className={styles.photo}
          onError={avatar.handleError}
          alt=""
        />
      )}

      {!avatar.isValid && getInitials(name)}

      {icon && (
        <div className={classNames(styles.icon, iconClassName)}>{icon}</div>
      )}
    </div>
  );
}

Avatar.defaultProps = {
  avoidDefaultIcon: true,
  isClickable: true,
  icon: '',
  onClick: () => {},
  iconClassName: '',
  onError: () => {},
  photoUrl: '',
  className: '',
};

Avatar.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  avoidDefaultIcon: PropTypes.bool,
  isClickable: PropTypes.bool,
  onError: PropTypes.func,
};

export default Avatar;
