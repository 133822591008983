import { StyleSheet } from '@react-pdf/renderer';

import { SECONDARY_LINE_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  billItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: SECONDARY_LINE_COLOR,
    paddingBottom: 4,
    marginBottom: 4,
  },
  billTitleSection: {
    flex: 2,
  },
  billStatusSection: {
    flex: 1,
    paddingLeft: 40,
  },
  billTitleText: {
    fontWeight: 500,
    fontSize: 11,
  },
  billDescriptionText: {
    fontSize: 11,
  },
  labelTitle: {
    paddingBottom: 10,
  },
  lineBottom: {
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: SECONDARY_LINE_COLOR,
  },
});

export default styles;
