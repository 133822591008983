import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import ButtonGroup from 'components/core/ButtonGroup';

import styles from './styles.module.scss';

export default function BaseFilter({ title, options, selectedFilter, onFilterClick, isLoadingMap }) {
  function handleFilterClick(selectedOption) {
    return () => onFilterClick(selectedOption);
  }

  return (
    <div className={styles.filter}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <ButtonGroup className={styles.options}>
        {options.map(({ value, label }, index) => (
          <Button
            key={`${value}-${index}`}
            theme="default"
            className={classNames(
              styles.option,
              value === selectedFilter && styles.isActive,
            )}
            onClick={handleFilterClick(value)}
            disabled={isLoadingMap}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}

BaseFilter.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  isLoadingMap: PropTypes.bool.isRequired,
};
