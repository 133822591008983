import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { linkProps } from 'components/Reports/utils/shapes';

import CoreLink from '../Core';
import styles from './styles.module.scss';

const THEMES = {
  blue: styles.blue,
  violet: styles.violet,
  green: styles.green,
  red: styles.red,
};

function Mark({ theme, title, description, href, className }) {
  return (
    <CoreLink href={href} className={classNames(styles.link, className)} theme="light">
      <span className={classNames(styles.mark, THEMES[theme])} />
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </CoreLink>
  );
}

Mark.defaultProps = {
  theme: 'blue',
};

Mark.propTypes = {
  ...linkProps,
  theme: PropTypes.string,
};

export default Mark;
