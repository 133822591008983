import React from 'react';
import PropTypes from 'prop-types';
import { startCase, toLower } from 'lodash';
import classNames from 'classnames';

import EmptyPlaceholder from 'components/core/EmptyPlaceholder';

import styles from './styles.module.scss';

function PageNotFound({ fallbackLink, fallbackLabel, fallbackMessage, title, subtitle, center }) {
  const iconNode = <img src="/images/sad-face.svg" alt="Not found" />;
  const defaultFallbackMessage = (
    <p>
      Please check the URL you entered, or navigate back to
      the <a href={fallbackLink}>{startCase(toLower(fallbackLabel))}</a> page
      to find what you are looking for.
    </p>
  );

  const description = (
    <>
      <p>{subtitle}</p>
      {fallbackMessage || defaultFallbackMessage}
    </>
  );

  const pageClassNames = classNames(styles.container, {
    [styles.center]: center,
  });

  return (
    <div className={pageClassNames}>
      <EmptyPlaceholder
        title={startCase(title)}
        iconNode={iconNode}
        description={description}
      />
    </div>
  );
}

PageNotFound.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fallbackLabel: PropTypes.string,
  fallbackLink: PropTypes.string,
  fallbackMessage: PropTypes.string,
  center: PropTypes.bool,
};

PageNotFound.defaultProps = {
  title: 'Not Found',
  subtitle: 'The item you are looking for could not be found. It either doesn\'t exist, or you don\'t have access to it.',
  fallbackLabel: 'home',
  fallbackLink: '/',
  fallbackMessage: null,
  center: false,
};

export default PageNotFound;
