import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getPublicationContent } from './utils';

function Publications({ person, isLoading, hideOnEmptyData }) {
  const { publications = [] } = person || {};

  const sections = [{
    id: 'publicationsSection',
    title: 'Publications',
    tables: [{
      id: 'publicationsTable',
      label: 'Publication',
      placeholderSize: 2,
      columns: [{
        id: 'publication',
        getContent: getPublicationContent,
      }],
      data: publications,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(publications)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

Publications.defaultProps = {
  hideOnEmptyData: true,
};

Publications.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(Publications);
