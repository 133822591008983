import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import DocumentPlaceholder from 'components/core/DocumentPlaceholder';
import PDF from 'components/core/PDF';

import * as Notifications from './notifications';
import styles from './styles.module.scss';

function DocumentContent({
  isLoadingDocument,
  isLoadingExports,
  texts,
  onExportDocument,
  pdfUrl,
  sourceUrl,
  documents,
  documentType,
  strategySlug,
  actionButtons,
}) {
  const isContentEmpty = isEmpty(texts) && !pdfUrl;

  if (!isLoadingDocument && isContentEmpty) {
    return <DocumentPlaceholder isEmptyText sourceUrl={sourceUrl} />;
  }

  function getDataForExport() {
    if (pdfUrl) {
      return {
        url: pdfUrl,
      };
    }

    return {
      strategy_slug: strategySlug,
      strategy_inputs: {
        document_ids: map(documents, 'id'),
      },
    };
  }

  function handleExport() {
    const exportNotifications = {
      getSuccess: Notifications.exportEventSuccess,
      getFailed: Notifications.exportEventFailed,
    };

    if (!pdfUrl && isEmpty(documents)) {
      return Notifications.exportEventFailed();
    }

    const data = getDataForExport();

    return onExportDocument({ data, exportNotifications, documentType });
  }

  function renderText(text, index) {
    const { content } = text || {};

    if (isEmpty(content)) {
      return null;
    }

    return (
      <div
        key={`content-${index}`}
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  function renderContent() {
    if (pdfUrl || isLoadingDocument) {
      return (
        <PDF
          link={pdfUrl}
          sourceType="GET"
          hasPdf
          iframeClass={styles.pdfIframe}
          containerClass={styles.pdfContainer}
          isLoading={isLoadingDocument}
          originalSourceUrl={sourceUrl}
        />
      );
    }

    return texts.map(renderText);
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {renderContent()}
        <div className={styles.actionButtons}>
          {actionButtons}
          <Button
            theme="none"
            size="none"
            className={styles.exportButton}
            disabled={isLoadingExports}
            onClick={handleExport}
          >
            {isLoadingExports
              ? <Loader onlyIcon size="small" className={styles.loader} />
              : <i className={classNames(styles.icon, 'fas fa-download')} />}
          </Button>
        </div>
      </div>
    </div>
  );
}

DocumentContent.propTypes = {
  isLoadingDocument: PropTypes.bool,
  isLoadingExports: PropTypes.bool,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  pdfUrl: PropTypes.string,
  sourceUrl: PropTypes.string,
  strategySlug: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  documentType: PropTypes.shape({
    name: PropTypes.string,
    apiUrl: PropTypes.string,
  }),
  onExportDocument: PropTypes.func.isRequired,
  actionButtons: PropTypes.element,
};

export default DocumentContent;
