import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import styles from './styles.module.scss';

function FooterActions({ onClearAll, isDisplayedOnCancel, onCancel, onSearch, allowSubmit }) {
  const showClearAll = allowSubmit;
  const showCancel = !!onCancel && isDisplayedOnCancel;
  const showSearch = !!onSearch;

  const nothingToShow = !(showClearAll || showCancel || showSearch);

  if (nothingToShow) {
    return null;
  }

  return (
    <div className={styles.footer}>
      {showClearAll && (
        <Button
          theme="light"
          size="small"
          className={styles.clearAll}
          onClick={onClearAll}
        >
          Clear All
        </Button>
      )}

      {showCancel && (
        <Button
          theme="light"
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}

      {showSearch && (
        <Button
          size="small"
          type="submit"
        >
          Search
        </Button>
      )}
    </div>
  );
}

FooterActions.defaultProps = {
  isDisplayedOnCancel: true,
};

FooterActions.propTypes = {
  isDisplayedOnCancel: PropTypes.bool,
  allowSubmit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClearAll: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
};

export default withSearch(FooterActions);
