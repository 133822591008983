import React from 'react';

export const CustomFieldsContext = React.createContext();

function withCustomField(Component) {
  return function CustomFieldContext(props) {
    return (
      <CustomFieldsContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </CustomFieldsContext.Consumer>
    );
  };
}
export default withCustomField;
