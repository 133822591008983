import React from 'react';
import PropTypes from 'prop-types';

import { displayTypeCodes } from 'components/core/Map/utils/constants';
import withMap from 'components/core/Map/utils/withMap';

import BaseFilter from '../BaseFilter';
import styles from './styles.module.scss';

function Heatmap({ title, options, selectedFilter, onFilterClick, isLoadingMap }) {
  return (
    <div className={styles.container}>
      <BaseFilter
        title={title}
        options={options}
        selectedFilter={selectedFilter}
        onFilterClick={onFilterClick}
        isLoadingMap={isLoadingMap}
      />
    </div>
  );
}

Heatmap.defaultProps = {
  title: 'Stakeholder Display',
  options: [
    {
      label: 'Map Markers',
      value: displayTypeCodes.markers,
    },
    {
      label: 'Heatmap',
      value: displayTypeCodes.heatmap,
    },
  ],
};

Heatmap.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  selectedFilter: PropTypes.string.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  isLoadingMap: PropTypes.bool.isRequired,
};

export default withMap(Heatmap);
