import toString from 'lodash/toString';

import {
  getFilteredTags,
  saveTagsAndUpdateRecords,
  transformNewTagsForUI,
  transformTagsForBE,
} from 'redux/tags/utils';
import {
  fetchTagsByRecordIdsNoDispatch,
  updateTagRecordsForRecordNoDispatch,
} from 'redux/tags/actions';
import notifications from 'components/Dashboard/utils/notifications';
import { notifyAddTags } from 'components/Dashboard/common/UserNotifications';

export function fetchTags({ recordId, recordType, onSuccess: onFetchSuccess }) {
  function onSuccess(response) {
    const [record] = response;
    const { tags: transformedInitialTags } = record || {};

    onFetchSuccess(transformedInitialTags);
  }

  function onFailure(error) {
    notifications.generalFailedNotification();

    throw error;
  }

  return fetchTagsByRecordIdsNoDispatch([{ recordId, documentType: recordType }])
    .then(onSuccess)
    .catch(onFailure);
}

export function onSaveTags({
  tags,
  itemType,
  recordId,
  recordType,
  onSuccess: handleSuccess,
}) {
  const { newTags, existingTags } = getFilteredTags(tags);

  function onSuccess(response) {
    handleSuccess && handleSuccess({
      recordId,
      recordType,
      tags: transformNewTagsForUI(response),
    });

    notifyAddTags({ itemType });
  }

  function onFailure(error) {
    notifications.generalFailedNotification();

    throw error;
  }

  function saveTagsForRecord(responseNewTags) {
    return updateTagRecordsForRecordNoDispatch({
      tagIds: transformTagsForBE(existingTags, responseNewTags),
      recordId: toString(recordId),
      recordType,
    }).then(onSuccess)
      .catch(onFailure);
  }

  return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
}
