import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getEducationItemContent } from './utils';

function Education({ person, isLoading, hideOnEmptyData }) {
  const { education = [] } = person || {};

  const sections = [{
    id: 'education',
    title: 'Education',
    tables: [{
      id: 'educationItems',
      label: 'Education',
      placeholderSize: 2,
      columns: [{
        id: 'educationItem',
        getContent: getEducationItemContent,
      }],
      data: education,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(education)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

Education.defaultProps = {
  hideOnEmptyData: true,
};

Education.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(Education);
