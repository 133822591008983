import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ClearableEmptyPlaceholder } from 'components/core/Profile/components/Placeholders';
import { EmailItem, PhoneItem } from 'components/core/Table/components/ColumnItem';
import { PAGE_SIZE } from 'components/core/Profile/utils';
import Table, { Column } from 'components/core/Table';
import Paginator from 'components/core/Paginator';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { tableColumnShape } from 'components/core/Profile/components/Cards/utils/shapes';
import { paginatorShape } from 'components/core/Paginator/shapes';
import {
  stafferShape,
  uiShape,
} from 'components/core/Profile/components/Cards/Staffers/utils/shapes';

import { federalTableColumns } from '../../utils/tableColumns';
import useTable from '../../utils/useTable';
import StafferColumn from '../StafferColumn';
import IssueAreaColumn from '../IssueAreaColumn';
import AddressColumn from '../AddressColumn';
import TitlesColumn from '../TitlesColumn';
import RoleColumn from '../RoleColumn';
import OfficeColumn from '../OfficeColumn';
import styles from './styles.module.scss';

function TableView({
  ui: { isLoading },
  paginator,
  staffers,
  trackingActions,
  tableColumns,
  onSearch,
  areFiltersApplied,
  emptyDescription,
  stafferType,
}) {
  const {
    isPaginatorPresent,
    sortData,
    onSortColumn,
    onChangePage,
  } = useTable({ onSearch, paginator, trackingActions, tableColumns });
  const { isMobile } = useMediaQuery();

  if (!isLoading && isEmpty(staffers)) {
    return (
      <ClearableEmptyPlaceholder
        areFiltersApplied={areFiltersApplied}
        emptyDescription={emptyDescription}
        emptyDescriptionFiltering="There are no results that match the applied filters."
      />
    );
  }

  const handleEmailClick = (item = {}) => trackingActions.trackStafferEmailed(item.name);

  const handleNameClick = (item = {}) => trackingActions.trackStafferVisited(item.name);

  const handlePhoneClick = (item = {}) => trackingActions.trackStafferPhoned(item.name);

  function renderColumn(column, data = {}) {
    function getColumn(value) {
      return <div className={styles.tableColumn}>{value}</div>;
    }

    const { keyName } = column;
    const commonProps = {
      ...data,
      key: keyName,
      keyName,
    };

    const columns = {
      name: <StafferColumn {...commonProps} onClick={handleNameClick} stafferType={stafferType} />,
      issues: <IssueAreaColumn {...commonProps} />,
      email: <EmailItem {...commonProps} onClick={handleEmailClick} />,
      phone: <PhoneItem {...commonProps} onClick={handlePhoneClick} />,
      titles: <TitlesColumn {...commonProps} />,
      address: <AddressColumn {...commonProps} />,
      role: <RoleColumn {...commonProps} />,
      office: <OfficeColumn {...commonProps} />,
    };

    return columns[keyName] || <Column key={keyName} keyName={keyName}>{getColumn}</Column>;
  }

  function renderPagination() {
    if (!isPaginatorPresent) {
      return null;
    }

    const { current, total, totalCount, pageSize } = paginator;

    return (
      <Paginator
        isLoading={isLoading}
        isMobile={isMobile}
        current={current}
        total={total}
        pageSize={pageSize}
        totalItems={totalCount}
        onChange={onChangePage}
      />
    );
  }

  const tableClassName = classNames(
    styles.tableContent,
    paginator
      && paginator.current > 1
      && paginator.current === paginator.total
      && styles.fixedHeight
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isLoading}
        rowLengthPlaceholder={PAGE_SIZE}
        uniqueKey="id"
        columns={tableColumns}
        containerStyle={tableClassName}
        data={staffers}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={onSortColumn}
        isPaginatorPresent={isPaginatorPresent}
        isMobile={isMobile}
        isFixed
      >
        {tableColumns.map(renderColumn)}
      </Table>

      {renderPagination()}
    </div>
  );
}

TableView.defaultProps = {
  trackingActions: {},
  tableColumns: federalTableColumns,
};

TableView.propTypes = {
  ui: uiShape.isRequired,
  paginator: paginatorShape.isRequired,
  staffers: PropTypes.arrayOf(stafferShape).isRequired,
  onSearch: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(tableColumnShape),
  trackingActions: PropTypes.shape({
    trackStafferEmailed: PropTypes.func,
    trackStafferVisited: PropTypes.func,
    trackStafferPhoned: PropTypes.func,
    trackStaffersPageVisited: PropTypes.func,
    trackStaffersResultsSorted: PropTypes.func,
  }),
  areFiltersApplied: PropTypes.bool.isRequired,
  emptyDescription: PropTypes.node.isRequired,
  stafferType: PropTypes.string,
};

export default TableView;
