import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

function BillNumber({ keyName, titles, value, onChange, className, isFederal, customStrategy, multiple }) {
  const federalStateStrategy = isFederal ? 'federal_bill_number' : 'state_bill_number';
  const strategy = customStrategy || federalStateStrategy;

  function handleChange(data) {
    onChange(keyName, data);
  }

  return (
    <BaseOption
      {...titles}
      className={className}
      name={keyName}
      multiple={multiple}
      strategy={strategy}
      overrideValue={value}
      onChange={handleChange}
    />
  );
}

BillNumber.defaultProps = {
  isFederal: true,
  multiple: false,
  keyName: 'billNumbers',
  titles: {
    title: 'Bill Number',
    placeholder: 'Select bill',
  },
};

BillNumber.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFederal: PropTypes.bool,
  customStrategy: PropTypes.string,
  multiple: PropTypes.bool,
};

export default BillNumber;
