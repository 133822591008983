import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getColumnLabelBySortingKey } from 'utils/table';
import useSortTable from 'utils/useSortTable';

function useTable({
  tableColumns,
  sort: sortRedux,
  paginator,
  trackingActions,
  onSearch,
  customData,
  customSortColumn,
  customChangePage,
}) {
  const sort = useSortTable();
  const isPaginatorPresent = !isEmpty(paginator);
  const { trackSorted, trackPageVisited } = trackingActions || {};

  const shouldApplyFixedHeight = paginator
    && paginator.current > 1
    && paginator.current === paginator.total;

  /** Fix default sort  */
  useEffect(() => {
    if (!isEmpty(sortRedux)) {
      const { sort: reduxColumn, direction } = sortRedux;

      sort && sort.setSortData({ direction, column: reduxColumn });
    }
  }, [sortRedux]);

  function handleSortColumn(column) {
    if (customSortColumn) {
      return customSortColumn(column);
    }

    const { getDirection } = sort || {};
    const direction = getDirection(column);

    const columnLabel = getColumnLabelBySortingKey(tableColumns, column);
    trackSorted && trackSorted(columnLabel, direction);

    return onSearch({ ...customData, sort: column, direction });
  }

  function handleChangePage(page) {
    if (customChangePage) {
      return customChangePage(page);
    }

    const { sortData = {} } = sort || {};
    const { column, direction } = sortData || {};

    trackPageVisited && trackPageVisited(page);

    return onSearch({ ...customData, page, sort: column, direction });
  }

  return {
    ...sort,
    shouldApplyFixedHeight,
    isPaginatorPresent,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
  };
}

export default useTable;
