import { useEffect, useState } from 'react';
import { isEmpty, isEqual, isFunction, isUndefined, map, pick } from 'lodash';

import useModal from 'components/core/Modal/useModal';
import useLoader from 'utils/useLoader';

import { transformTag, transformFixedTags } from './utils';
import { fetchTags } from './api';

function useTagManager({
  initialTags = [],
  isMultipleItems,
  onSaveTags,
  updateDataItem,
  recordId,
  recordType,
  itemType,
  isOpen,
  ids,
  customIsOpenModal,
  customToggleModal,
}) {
  const { isOpenModal: defaultIsOpenModal, toggleModal: defaultToggleModal } = useModal(isOpen);
  const { isLoading, stopLoading, startLoading } = useLoader();
  const [defaultTags, setDefaultTags] = useState(initialTags);
  const [tags, setTags] = useState(defaultTags);
  const shouldFetchOnLoad = !isMultipleItems
    && isEmpty(initialTags)
    && !!recordId
    && !isEmpty(recordType);

  function handleDefaultFetchSuccess(responseTags) {
    setDefaultTags(transformFixedTags(responseTags, isMultipleItems));
  }

  const isOpenModal = isUndefined(customIsOpenModal) ? defaultIsOpenModal : customIsOpenModal;
  const toggleModal = isFunction(customToggleModal) ? customToggleModal : defaultToggleModal;

  useEffect(() => {
    shouldFetchOnLoad
    && isOpenModal
    && fetchTags({ recordId, recordType, onSuccess: handleDefaultFetchSuccess });

    !shouldFetchOnLoad && setDefaultTags(initialTags);
  }, [isOpenModal]);

  useEffect(() => {
    /** When editing multiple items, user should not be able to remove the initial tags * */
    const fixedTags = transformFixedTags(defaultTags, isMultipleItems);

    setTags(fixedTags);
  }, [isOpenModal, defaultTags]);

  function handleChange(items) {
    setTags(map(items, transformTag));
  }

  function handleSave() {
    const initTags = map(defaultTags, tag => pick(tag, ['label', 'value']));
    const finalTags = map(tags, tag => pick(tag, ['label', 'value']));

    if (isEqual(initTags, finalTags)) {
      return toggleModal();
    }

    function onFinally() {
      stopLoading();
      toggleModal();
    }

    startLoading();

    return onSaveTags({
      ids,
      tags,
      itemType,
      recordId,
      recordType,
      onSuccess: updateDataItem,
    }).finally(onFinally);
  }

  return {
    tags,
    isOpenModal,
    toggleModal,
    isLoading,
    onChange: handleChange,
    onSave: handleSave,
  };
}

export default useTagManager;
