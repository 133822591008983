import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import constants from 'components/core/DatePicker/constants';

import NavigationButton from '../NavigationButton';
import MonthsRange from './components/MonthsRange';
import YearsRange from './components/YearsRange';

import styles from './styles.module.scss';

const {
  DATEPICKER_HEADER_DATE_FORMAT,
  MONTH_FORMAT,
  YEAR_FORMAT,
} = constants;

function Header({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  showMonthDropdown,
  showYearDropdown,
}) {
  const onlyYearFormat = showMonthDropdown ? YEAR_FORMAT : '';
  const onlyMonthFormat = showYearDropdown ? MONTH_FORMAT : '';

  const withCustomDropdown = showYearDropdown || showMonthDropdown;
  const withBothCustomDropdowns = showYearDropdown && showMonthDropdown;

  const dateFormat = withCustomDropdown ? `${onlyMonthFormat} ${onlyYearFormat}` : DATEPICKER_HEADER_DATE_FORMAT;

  const formattedDate = moment(date).format(dateFormat);

  return (
    <header className={styles.header}>
      <div className={styles.dropdownContainer}>

        {showMonthDropdown && (
          <MonthsRange date={date} onMonthChange={changeMonth} />
        )}

        {!withBothCustomDropdowns && (
          <div className={styles.title}>{formattedDate}</div>
        )}

        {showYearDropdown && (
          <YearsRange date={date} onYearChange={changeYear} />
        )}
      </div>
      <div className={styles.navigation}>
        <NavigationButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} />
        <NavigationButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} isNext />
      </div>
    </header>
  );
}

Header.propTypes = {
  date: PropTypes.instanceOf(Date),
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
};

export default Header;
