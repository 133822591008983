import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import withNews from '../utils/withNews';
import styles from '../styles.module.scss';

function RightItems({
  hasPreviewButton,
  showPreview,
  onShowPreview,
}) {
  const previewButtonText = showPreview
    ? 'Hide full preview'
    : 'Show full preview';

  return (
    <div className={styles.right}>
      {hasPreviewButton && (
        <Button
          theme="tertiary"
          onClick={onShowPreview}
          className={styles.button}
        >
          {previewButtonText}
        </Button>
      )}
    </div>
  );
}

RightItems.propTypes = {
  hasPreviewButton: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool.isRequired,
  onShowPreview: PropTypes.func.isRequired,
};

export default withNews(RightItems);
