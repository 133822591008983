import React from 'react';
import PropTypes from 'prop-types';

import { MY_FEED, FACEBOOK, TWITTER, EMAIL_ID } from 'utils/constants/socialMedia';
import { getUrlWithBase, isURL } from 'utils/url';

import ShareTooltip from './components/ShareTooltip';
import ShareModal from './components/ShareModal';
import { optionsShape } from './shapes';
import { onShareToMyFeed } from './api';

function ButtonShare({
  isOpen,
  withModal,
  options,
  recordId,
  recordType,
  itemType,
  onSuccess,
  itemUrl,
  itemName,
  ...restProps
}) {
  const withShareToMyFeed = recordId && recordType;
  const withMyFeedOption = withShareToMyFeed ? [{ label: 'My feed', value: MY_FEED }] : [];
  const defaultOptions = [
    ...withMyFeedOption,
    { label: 'Facebook', value: FACEBOOK },
    { label: 'Twitter', value: TWITTER },
    { label: 'Email', value: EMAIL_ID },
  ];
  const fullUrl = isURL(itemUrl) ? itemUrl : getUrlWithBase(itemUrl);
  const body = `${itemName} ${fullUrl && `%0D URL: ${fullUrl}`}`;
  const mailInfo = { subject: `${itemName} via Intelligence`, body };

  function handleShareToMyFeed() {
    return onShareToMyFeed({ id: recordId, itemType, type: recordType })
      .then(() => onSuccess && onSuccess(recordId));
  }

  const shareProps = {
    ...restProps,
    options: options || defaultOptions,
    onShare: handleShareToMyFeed,
    isOpen,
    itemName,
    fullUrl,
    mailInfo,
  };

  if (withModal) {
    return <ShareModal {...shareProps} />;
  }

  return <ShareTooltip {...shareProps} />;
}

ButtonShare.defaultProps = {
  withOptions: true,
  withModal: false,
  tooltipPlacement: 'bottom-start',
  isOpen: false,
};

ButtonShare.propTypes = {
  isOpen: PropTypes.bool,
  withModal: PropTypes.bool,
  withOptions: PropTypes.bool,
  withShare: PropTypes.bool,
  options: optionsShape,
  itemUrl: PropTypes.string,
  recordId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  recordType: PropTypes.string,
  itemType: PropTypes.string,
  itemName: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  linkClassName: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default ButtonShare;
