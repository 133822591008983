import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';

import styles from './styles';

// TODO: Truncate the text

function Title({ children }) {
  return (
    <Text style={styles.title}>
      {children}
    </Text>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
