import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';

function IssueAreaColumn({ keyName, item }) {
  const { issues } = item;

  const isEmptyData = isEmpty(issues);

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={issues.sort()}
      isEmptyData={isEmptyData}
    />
  );
}

IssueAreaColumn.defaultProps = {
  item: {},
};

IssueAreaColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
};

export default IssueAreaColumn;
