import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Moneyball/common/Link';
import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import styles from '../styles.module.scss';

export default function ErrorPdf({ originalSourceUrl }) {
  const reportIssueLink = (
    <Link
      theme="link"
      displayExternalIcon={false}
      href="https://govpredict.freshdesk.com/support/tickets/new"
    >
      report an issue.
    </Link>
  );

  const originalSourceLink = (
    <Link
      theme="link"
      displayExternalIcon={false}
      href={originalSourceUrl}
    >
      here
    </Link>
  );

  const description = (
    <>
      There was an issue loading the original filing (.pdf). Please try again later, or {reportIssueLink}
      <br />
      {originalSourceUrl && (
        <div className={styles.originalSource}>
          Click {originalSourceLink} to view the original source
        </div>
      )}
    </>
  );

  return (
    <div className={styles.error}>
      <EmptyPlaceholder
        title="The original filing appears to be unavailable"
        className={styles.errorPdf}
        iconClassName={styles.iconClassName}
        titleClassName={styles.titleClassName}
        descriptionClassName={styles.descriptionClassName}
        description={description}
      />
    </div>
  );
}

ErrorPdf.propTypes = {
  originalSourceUrl: PropTypes.string,
};
