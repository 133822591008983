import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, truncate, replace, size } from 'lodash';
import Tooltip from '@tippyjs/react';

import styles from '../styles.module.scss';

function TrimTextHtmlItem({ textToTrim, maxTextLength, className }) {
  if (isEmpty(textToTrim)) {
    return null;
  }

  const trimmedText = replace(textToTrim, /_{2,}/g, '');
  const originalTextInnerHTML = { __html: trimmedText };

  if (size(trimmedText) <= maxTextLength) {
    return (
      <span
        className={classNames(styles.highlightedText, className)}
        dangerouslySetInnerHTML={originalTextInnerHTML}
      />
    );
  }

  function getTooltipNode() {
    return <span className={styles.textTrim} dangerouslySetInnerHTML={originalTextInnerHTML} />;
  }

  const htmlToDisplay = truncate(trimmedText, { length: maxTextLength, separator: /,?\.<\/* +/ });
  const innerHTML = { __html: htmlToDisplay };

  return (
    <Tooltip
      content={getTooltipNode()}
      className={classNames(styles.tooltipContainer)}
      placement="bottom"
    >
      <span className={classNames(styles.highlightedText, className)} dangerouslySetInnerHTML={innerHTML} />
    </Tooltip>
  );
}

TrimTextHtmlItem.defaultProps = {
  maxTextLength: 90,
};

TrimTextHtmlItem.propTypes = {
  textToTrim: PropTypes.string,
  className: PropTypes.string,
  maxTextLength: PropTypes.number,
};

export default TrimTextHtmlItem;
