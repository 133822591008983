import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { isURL } from 'utils/url';
import CoreLink from 'components/core/Link';

import { formatUrl } from './utils';
import styles from './styles.module.scss';

function Link({ item, urlKeyPath, className, defaultValue, getUrl }) {
  const { type } = item;
  const url = getUrl ? getUrl(item) : item[urlKeyPath];

  const renderLink = () => {
    if (isEmpty(url)) {
      return defaultValue || null;
    }

    const formattedUrl = formatUrl({ url, type });
    const isExternalLink = isURL(url);

    return (
      <CoreLink
        theme="tableLink"
        href={formattedUrl}
        isExternal={isExternalLink}
        displayExternalIcon={isExternalLink}
        className={styles.link}
        iconClassName={styles.icon}
      >
        <span className={styles.url}>{url}</span>
      </CoreLink>
    );
  };

  return (
    <span className={className}>
      {renderLink()}
    </span>
  );
}

Link.defaultProps = {
  urlKeyPath: 'url',
};

Link.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  urlKeyPath: PropTypes.string,
  defaultValue: PropTypes.node,
  className: PropTypes.string,
  getUrl: PropTypes.func,
};

export default Link;
