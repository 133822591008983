import React from 'react';
import { map, isEmpty, concat } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getEmploymentContent } from 'components/core/Profile/components/Cards/EmploymentHistory/utils';

import TableSection from '../../TableSection';

function EmploymentHistory({ person }) {
  const { employmentHistory, headPositions } = person || {};
  const data = concat(employmentHistory, headPositions);

  if (isEmpty(data)) {
    return null;
  }

  const tables = [
    map(data, (historyItem, index) => ({
      label: `Organization ${index + 1}`,
      value: getEmploymentContent(historyItem),
    })),
  ];

  return (
    <TableSection
      title="Employment history"
      tables={tables}
    />
  );
}

EmploymentHistory.propTypes = {
  person: personShape,
};

export default EmploymentHistory;
