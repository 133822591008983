import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

export const REQUIRED_ERROR = 'Please add a workspace.';

function useWorkspaces(initialCheckState = true) {
  const [workspaces, setWorkspaces] = useState([]);
  const [saveToWorkspaces, setSaveToWorkspaces] = useState(initialCheckState);
  const [saveToMyFeed, setSaveToMyFeed] = useState(initialCheckState);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCheckWorkspaces = ({ target }) => setSaveToWorkspaces(target.checked);
  const handleCheckMyFeed = ({ target }) => setSaveToMyFeed(target.checked);

  const reset = () => {
    setWorkspaces([]);
    setSaveToWorkspaces(initialCheckState);
    setSaveToMyFeed(initialCheckState);
    setErrorMessage('');
  };

  const validateWorkspaces = () => {
    const isValid = !(saveToWorkspaces && isEmpty(workspaces));

    isValid ? setErrorMessage('') : setErrorMessage(REQUIRED_ERROR);

    return isValid;
  };

  return {
    values: workspaces,
    onChangeValues: setWorkspaces,
    isWorkspacesChecked: saveToWorkspaces,
    onCheckWorkspaces: handleCheckWorkspaces,
    isMyFeedChecked: saveToMyFeed,
    onCheckMyFeed: handleCheckMyFeed,
    reset,
    errorMessage,
    validateWorkspaces,
  };
}

export default useWorkspaces;
