import React, { createContext } from 'react';

export const SearchContext = createContext();

function withSearch(Component) {
  return function SearchData(props) {
    return (
      <SearchContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </SearchContext.Consumer>
    );
  };
}

export default withSearch;
