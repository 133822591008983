import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

function useBackToTop({ elementRef } = {}) {
  const defaultRef = useRef();
  const usedRef = elementRef || defaultRef;

  const [isVisible, setIsVisible] = useState(false);

  function handleScroll() {
    if (usedRef.current) {
      const { bottom } = usedRef.current.getBoundingClientRect() || {};
      setIsVisible(bottom < 0);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll, 100));

    return () => window.removeEventListener('scroll', handleScroll);
  }, [usedRef]);

  return {
    isVisible,
    backToTopRef: defaultRef,
  };
}

export default useBackToTop;
