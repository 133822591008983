import PropTypes from 'prop-types';

export const valueType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

export const rowShape = PropTypes.shape({
  label: PropTypes.string,
  value: valueType,
});
