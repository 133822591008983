export const URL_PER_TYPE = {
  federal_staffer: ({ recordId }) => `/federal/congressional_staffers/${recordId}`,
  federal_legislator: ({ recordId }) => `/federal/legislators/${recordId}`,
  federal_bill: ({ recordId }) => `/federal/bills/${recordId}`,
  state_staffer: ({ recordId }) => `/state/staffers/${recordId}`,
  state_bill: ({ recordId }) => `/state/bills/${recordId}`,
  state_legislator: ({ recordId }) => `/state/legislators/${recordId}`,
  colleague: () => {},
  stakeholder: ({ recordId }) => `/stakeholder_management/stakeholder/${recordId}`,
};
