import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import Modal from '../Modal';
import styles from './styles.module.scss';

function CloseConfirmation({
  title,
  isOpen,
  toggleOpen,
  onReturn,
  customMessage,
  ...restProps
}) {
  function renderMessage() {
    if (customMessage) {
      return customMessage;
    }

    return <p>You are about to exit the modal window. Are you sure you want to exit?</p>;
  }

  const buttons = (
    <>
      <Button
        type="small"
        theme="tertiary"
        onClick={onReturn}
        className={styles.modalActionBtn}
      >
        Go Back
      </Button>

      <Button
        type="small"
        onClick={toggleOpen}
        className={styles.modalActionBtn}
      >
        Exit
      </Button>
    </>
  );

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      buttons={buttons}
      toggleOpen={toggleOpen}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      className={styles.closeConfirmationModal}
      {...restProps}
    >
      <div>
        {renderMessage()}
        <p className={styles.emphasisText}>All unsaved changes will be lost!</p>
      </div>
    </Modal>
  );
}

CloseConfirmation.defaultProps = {
  title: 'Warning',
};

CloseConfirmation.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  customMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
};

export default CloseConfirmation;
