import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CANCEL_BUTTON, CONTINUE_BUTTON } from 'utils/constants/modal';
import Button from 'components/core/Button';

import Modal from '../Modal';
import styles from './styles.module.scss';

function Disclaimer({ className, content, title, isOpenModal, toggleModal, withButtons, modalTheme, modalClassName }) {
  const buttons = (
    <>
      <Button
        size="small"
        theme="light"
        onClick={toggleModal}
      >
        {CANCEL_BUTTON}
      </Button>

      <Button
        size="small"
        onClick={toggleModal}
      >
        {CONTINUE_BUTTON}
      </Button>
    </>
  );

  return (
    <div className={className}>
      <Modal
        title={title}
        isOpen={isOpenModal}
        buttons={withButtons && buttons}
        toggleOpen={toggleModal}
        className={classNames(styles.modal, modalClassName)}
        headerClassName={styles.header}
        theme={modalTheme}
      >
        {content}
      </Modal>
    </div>
  );
}

Disclaimer.defaultProps = {
  title: 'Disclaimer modal',
  withButtons: true,
};

Disclaimer.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  withButtons: PropTypes.bool,
  modalTheme: PropTypes.string,
  modalClassName: PropTypes.string,
};

export default Disclaimer;
