import { useState, useEffect } from 'react';
import isNil from 'lodash/isNil';

function useFrequency({
  initialSendWeekAheadEmail,
  initialFrequency,
  isOpenModal,
}) {
  const defaultSendWeekAhead = !isNil(initialSendWeekAheadEmail) ? initialSendWeekAheadEmail : false;
  const [frequency, setFrequency] = useState(initialFrequency);
  const [sendWeekAheadEmail, setSendWeekAheadEmail] = useState(defaultSendWeekAhead);

  function handleChangeSendEmail({ target: { checked } = {} }) {
    setSendWeekAheadEmail(checked);
  }

  useEffect(() => {
    setFrequency(initialFrequency);
    setSendWeekAheadEmail(defaultSendWeekAhead);
  }, [isOpenModal]);

  return {
    frequency,
    onFrequencyChange: setFrequency,
    sendWeekAheadEmail,
    defaultSendWeekAhead,
    onSendWeekAheadEmailChange: setSendWeekAheadEmail,
    onChangeSendEmail: handleChangeSendEmail,
  };
}

export default useFrequency;
