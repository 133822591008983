import { compact, flatten, isEmpty } from 'lodash';

import { getMyFeed } from 'components/Dashboard/utils';

import { transformWorkspacesForTiles } from './transformers';

export const getWorkspacesToTrack = (workspaces, payload = {}) => {
  const { isHearing, isStaffer, workspacesData } = payload;
  const formattedWorkspaces = transformWorkspacesForTiles(workspaces.values);

  const hasWorkspacesData = !isEmpty(workspacesData);

  const isCommitteeDataValid = (
    hasWorkspacesData && (workspacesData.isHearing || workspacesData.isStaffer)
  );
  const isWorkspacesDataValid = !hasWorkspacesData || isCommitteeDataValid;
  const isWorkspacesValid = workspaces.isWorkspacesChecked && isWorkspacesDataValid;

  const isMyFeedDataValid = !hasWorkspacesData || (isHearing || isStaffer);
  const isMyFeedValid = workspaces.isMyFeedChecked && isMyFeedDataValid;

  const compactedWorkspaces = compact(flatten([
    isMyFeedValid && getMyFeed(),
    isWorkspacesValid && formattedWorkspaces,
  ]));

  return compactedWorkspaces;
};
