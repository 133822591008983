import { isEmpty, cloneDeep } from 'lodash';

export function transformFirstOption(params = {}) {
  const { defaultComponentData, componentData, item } = params;

  return {
    ...componentData,
    firstOption: item,
    secondOption: defaultComponentData.secondOption,
  };
}

export function transformSecondOption(params = {}) {
  const { componentData, item } = params;

  return { ...componentData, secondOption: item };
}

export function transformFinalData(params = {}) {
  const { componentData, filter } = params;
  const { firstOption, secondOption } = componentData || {};

  return {
    ...cloneDeep(filter),
    operator: firstOption,
    value: secondOption,
  };
}

export function transformSuggesterValue(link) {
  return !isEmpty(link) ? link : null;
}

export function getInitialComponentData(obj = {}, defaultComponentData = {}) {
  const { firstOption, secondOption, addons: defaultAddons } = defaultComponentData;
  const { options, operator, value } = obj;
  const { toType, toValue, addons: optionsAddons } = options || {};

  if (options && Object.entries(options).length > 0) {
    return {
      firstOption: toType,
      secondOption: toValue,
      addons: optionsAddons,
    };
  }

  if (operator && value) {
    return {
      firstOption: operator,
      secondOption: value,
      addons: optionsAddons,
    };
  }

  return {
    firstOption,
    secondOption,
    addons: defaultAddons,
  };
}

export function filterTools({
  defaultComponentData = { firstOption: {}, secondOption: [] },
  suggester,
  filter,
}) {
  const initialComponentData = getInitialComponentData(filter, defaultComponentData);
  const handleFirstTransform = params => transformFirstOption({ ...params, defaultComponentData });
  const handleDataTransform = params => transformFinalData({ ...params, filter });
  const handleSecondSuggester = () => transformSuggesterValue(suggester);

  return {
    initialComponentData,
    handleFirstTransform,
    handleSecondSuggester,
    handleDataTransform,
  };
}
