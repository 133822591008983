export const FEDERAL_BILL_STATUS = [
  { value: 'reported_out_of_committee', label: 'Reported out of Committee' },
  { value: 'passed_chamber', label: 'Passed Chamber' },
  { value: 'vetoed', label: 'Vetoed' },
  { value: 'law', label: 'Law' },
];

export const STATE_BILL_STATUS = [
  { value: 'introduced', label: 'Introduced' },
  { value: 'engrossed', label: 'Engrossed' },
  { value: 'enrolled', label: 'Enrolled' },
  { value: 'passed', label: 'Passed' },
  { value: 'vetoed', label: 'Vetoed' },
  { value: 'failed', label: 'Failed' },
  { value: 'unknown', label: 'Unknown' },
];
