import { StyleSheet } from '@react-pdf/renderer';

import { DEFAULT_LABEL_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  billHeaderItem: {
    width: '25%',
  },
  billDescriptionText: {
    fontSize: 8,
    fontWeight: 300,
    color: DEFAULT_LABEL_COLOR,
    lineHeight: 1,
  },
  billHeaderText: {
    fontSize: 9,
    fontWeight: 500,
    color: DEFAULT_LABEL_COLOR,
    textTransform: 'capitalize',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
});

export default styles;
