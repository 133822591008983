import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';

import { QuestionMark } from 'components/core/Icons';
import { TOOLTIP_OFFSET } from 'utils/constants';

import styles from './styles.module.scss';

export default function TooltipComponent({ content, iconClassName, contentClassName, theme }) {
  if (isEmpty(content)) {
    return null;
  }

  const tooltipContent = (
    <div className={contentClassName}>
      {content}
    </div>
  );

  return (
    <Tooltip
      content={tooltipContent}
      placement="bottom"
      offset={TOOLTIP_OFFSET}
      theme={theme}
    >
      <QuestionMark className={classNames(styles.icon, iconClassName)} />
    </Tooltip>
  );
}

TooltipComponent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  iconClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  theme: PropTypes.string,
};
