import { head, take, isEmpty } from 'lodash';

import { PANEL_LIMIT, MOBILE_PANEL_LIMIT } from '../../utils/constants';

function useAlertsPanel({ notifications, totalNotifications, clearNotifications, ui, isMobile }) {
  const notificationsLimit = isMobile ? MOBILE_PANEL_LIMIT : PANEL_LIMIT;
  const displayNotifications = take(notifications, notificationsLimit);
  const isPanelEmpty = isEmpty(displayNotifications);
  const overLimitCount = totalNotifications - notificationsLimit;
  const { isClearingNotifications } = ui || {};

  function handleClearAll() {
    const latestNotification = head(displayNotifications);
    const { id } = latestNotification || {};

    id && clearNotifications(id);
  }

  return {
    showClearAllButton: !isPanelEmpty,
    onClearAll: handleClearAll,
    isClearAllButtonDisabled: isClearingNotifications,
    showEmptyState: isPanelEmpty,
    displayNotifications,
    showOverLimitLink: overLimitCount > 0,
    overLimitCount,
  };
}

export default useAlertsPanel;
