import { useEffect } from 'react';
import { isEmpty, map, size, reject, get, isUndefined } from 'lodash';

import {
  notifyTrackItem,
  notifyUntrackItem,
  notifySubscriptionChange,
  notifySubscriptionSendEmail,
  notifyWorkspaceSubscriptionUpdateSuccess,
  notifyWorkspaceSubscriptionUpdateFailure,
  notifyWorkspaceSubscriptionSubscribeSuccess,
  notifyWorkspaceSubscriptionSubscribeFailure,
  notifyWorkspaceSubscriptionUnsubscribeFailure,
  notifyWorkspaceSubscriptionUnsubscribeSuccess,
} from 'components/Dashboard/common/UserNotifications';
import notifications from 'components/Dashboard/utils/notifications';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getFeedSettingsUrl, isMyFeed } from 'components/Dashboard/utils';
import { getWorkspacesToTrack } from 'components/core/TrackButton/utils';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import useModal from 'components/core/Modal/useModal';
import { WORKSPACE_TYPE } from 'redux/dashboardV2/constants';
import { NEWSFEED_ITEM_TYPE } from 'utils/constants/itemTypes';
import useLoader from 'utils/useLoader';

import useWorkspaces from './useWorkspaces';
import useFrequency from './useFrequency';
import defaultApi from './api';

function useTrackButton({
  withSelectedWorkspace,
  isCommittee,
  feedToken,
  feedName,
  listSubscriptions,
  subscriptionInfo,
  recordId,
  recordType,
  itemType,
  itemTitle,
  updateDataItem,
  initialFrequency = NONE_ID,
  initialSendWeekAheadEmail,
  withWeekAheadEmail,
  initialModalOpen = false,
  searchId,
  isUpdate,
  api = defaultApi,
  trackings,
  updateCommitteeSubscriptions,
}) {
  const { isLoading, stopLoading, startLoading } = useLoader();
  const { isOpenModal, toggleModal, closeModal } = useModal(initialModalOpen);
  const workspaces = useWorkspaces();

  const isNewsSearch = recordType === DOCUMENT_TYPES.NEWS_SEARCH;
  const modelId = isNewsSearch ? searchId : recordId;
  const modelParams = { modelId, type: recordType };
  const recordItemType = isNewsSearch ? NEWSFEED_ITEM_TYPE : itemType;
  const isWorkspace = recordType === WORKSPACE_TYPE;
  const onWorkspace = !isMyFeed(feedToken) && withSelectedWorkspace;

  const {
    frequency,
    onFrequencyChange,
    sendWeekAheadEmail,
    defaultSendWeekAhead,
    onSendWeekAheadEmailChange,
    onChangeSendEmail,
  } = useFrequency({
    initialSendWeekAheadEmail,
    initialFrequency,
    isOpenModal,
  });

  useEffect(() => {
    !isOpenModal && workspaces.reset();
  }, [isOpenModal]);

  function setFinalState() {
    stopLoading();
    closeModal();
  }

  function updateData({
    newFrequency,
    newSendWeekAhead = false,
    newTrackings = [],
    newIsTracking,
  }) {
    const isTracking = isUndefined(newIsTracking) ? !isEmpty(newFrequency) : newIsTracking;

    onFrequencyChange(newFrequency || initialFrequency);
    onSendWeekAheadEmailChange(newSendWeekAhead);

    updateDataItem && updateDataItem({
      ...isCommittee && { listSubscriptions: [] },
      ...isNewsSearch && !isTracking && { modelId },
      isTracking,
      sendWeekAheadEmail: newSendWeekAhead,
      frequency: newFrequency,
      recordId,
      trackings: newTrackings,
    });
  }

  const getSelectedFeedName = selectedFeeds => get(selectedFeeds, '0.name');
  const getSelectedFeedToken = selectedFeeds => get(selectedFeeds, '0.token');

  function handleRemove(selectedFeeds) {
    startLoading();

    const getAction = () => {
      if (!isEmpty(trackings)) {
        return api.deleteSubscriptions({
          trackings,
          selectedFeeds: map(selectedFeeds, 'token'),
          ...(!isCommittee && { type: recordType }),
        });
      }

      return isCommittee
        ? api.deleteSubscriptions(reject(listSubscriptions, { isRemoved: true }), feedToken)
        : api.deleteSubscription(modelParams, feedToken);
    };

    const trackingsCount = size(selectedFeeds) || size(listSubscriptions) || 1;

    const notificationProps = {
      url: getFeedSettingsUrl(feedToken || getSelectedFeedToken(selectedFeeds)),
      itemType: recordItemType,
      itemTitle: feedName || getSelectedFeedName(selectedFeeds),
      trackingsCount,
    };

    return getAction()
      .then(trackingsLeft => updateData({
        newFrequency: frequency,
        newSendWeekAhead: sendWeekAheadEmail,
        newTrackings: trackingsLeft,
        newIsTracking: false,
        recordId,
      }))
      .then(() => {
        isWorkspace
          ? notifyWorkspaceSubscriptionUnsubscribeSuccess(notificationProps)
          : notifyUntrackItem(notificationProps);
      })
      .catch((error) => {
        isWorkspace
          ? notifyWorkspaceSubscriptionUnsubscribeFailure({ ...notificationProps, itemTitle })
          : notifications.generalFailedNotification();

        throw error;
      })
      .finally(setFinalState);
  }

  function handleSave(newFrequency = frequency) {
    startLoading();
    const data = {
      ...subscriptionInfo,
      ...modelParams,
      sendWeekAheadEmail,
      withWeekAheadEmail,
      frequency: newFrequency,
      workspaces,
    };

    const selectedFeeds = workspaces && getWorkspacesToTrack(workspaces, data);
    const hasSendWeekAheadEmailChanged = sendWeekAheadEmail !== defaultSendWeekAhead;
    const selectedFeedName = getSelectedFeedName(selectedFeeds);
    const selectedFeedToken = getSelectedFeedToken(selectedFeeds);
    const selectedFeedCount = size(selectedFeeds);
    const saveSubscription = isUpdate ? api.updateSubscription : api.createSubscription;
    const notificationProps = {
      ...(feedToken && { url: getFeedSettingsUrl(feedToken) }),
      itemType: recordItemType,
      itemToken: recordId,
      itemTitle,
    };

    return saveSubscription(data, feedToken)
      .then((response) => {
        updateData({
          newFrequency,
          newSendWeekAhead: sendWeekAheadEmail,
          newTrackings: response,
        });

        const selectedItemsCount = size(response);
        const trackingsCount = selectedFeedCount > selectedItemsCount
          ? selectedFeedCount
          : selectedItemsCount;
        const notificationUpdate = isWorkspace
          ? notifyWorkspaceSubscriptionUpdateSuccess
          : notifyTrackItem;
        const notificationCreate = isWorkspace
          ? notifyWorkspaceSubscriptionSubscribeSuccess
          : notifyTrackItem;

        isUpdate
          ? notificationUpdate({ ...notificationProps, trackingsCount })
          : notificationCreate({
            ...notificationProps,
            ...((!onWorkspace && selectedFeedCount >= 1) && {
              itemTitle: selectedFeedName,
              url: getFeedSettingsUrl(selectedFeedToken),
            }),
            trackingsCount,
          });

        if (hasSendWeekAheadEmailChanged) {
          notifySubscriptionSendEmail({ itemType, sendWeekAheadEmail });
        }
      })
      .catch((error) => {
        const errorNotificationUpdate = isWorkspace
          ? notifyWorkspaceSubscriptionUpdateFailure
          : notifications.generalFailedNotification;
        const errorNotificationCreate = isWorkspace
          ? notifyWorkspaceSubscriptionSubscribeFailure
          : notifications.generalFailedNotification;

        isUpdate
          ? errorNotificationUpdate(notificationProps)
          : errorNotificationCreate(notificationProps);

        throw error;
      })
      .finally(setFinalState);
  }

  function handleSaveDesktop() {
    handleSave();
  }

  function handleSaveMobile(newFrequency) {
    handleSave(newFrequency);
  }

  function handleSaveTrackCommittee(data) {
    startLoading();

    return updateCommitteeSubscriptions(data)
      .then(() => {
        const { isPresentStaffers, isPresentHearings } = data;
        const selectedFeeds = workspaces && getWorkspacesToTrack(workspaces, data);
        const selectedFeedName = getSelectedFeedName(selectedFeeds);
        const selectedFeedToken = getSelectedFeedToken(selectedFeeds);
        const selectedFeedCount = size(selectedFeeds);
        const trackingsCount = size(selectedFeeds);
        const notificationProps = {
          ...(feedToken && { url: getFeedSettingsUrl(feedToken) }),
          trackingsCount,
          itemType,
        };

        return ((isPresentHearings || isPresentStaffers)
          ? notifySubscriptionChange(notificationProps)
          : notifyTrackItem({
            ...notificationProps,
            ...((!onWorkspace && selectedFeedCount >= 1) && {
              itemTitle: selectedFeedName,
              url: getFeedSettingsUrl(selectedFeedToken),
            }),
          }));
      })
      .finally(setFinalState);
  }

  const handleCancel = () => {
    workspaces.reset();
    toggleModal();
  };

  return {
    isOpenModal,
    isLoading,
    frequency,
    workspaces,
    sendWeekAheadEmail,
    onSaveMobile: handleSaveMobile,
    onSaveTrackCommittee: handleSaveTrackCommittee,
    onSave: handleSaveDesktop,
    onChange: onFrequencyChange,
    onRemove: handleRemove,
    onCancel: handleCancel,
    onChangeSendEmail,
    toggleModal,
  };
}

export default useTrackButton;
