import React from 'react';

export const BillsContext = React.createContext();

function withBills(Component) {
  return function BillsDataComponent(props) {
    return (
      <BillsContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </BillsContext.Consumer>
    );
  };
}

export default withBills;
