import React from 'react';
import PropTypes from 'prop-types';

import { getDirectMailLink } from 'utils/socialMedia/links';
import Link from 'components/core/Link';

import styles from './styles.module.scss';

const { supportEmail } = gon;

const mailToHref = getDirectMailLink(supportEmail);

function NoSubscriptionMessage({ name, description }) {
  return (
    <p>
      It looks like you aren’t subscribed to {name}.
      To learn more about what amazing things {description} can do for your organization, {' '}
      <Link
        theme="light"
        href={mailToHref}
        className={styles.contactLink}
        isExternal
        displayExternalIcon={false}
      >
        contact
      </Link>
       your customer success manager today!
    </p>
  );
}

NoSubscriptionMessage.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default NoSubscriptionMessage;
