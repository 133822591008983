import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';

import { getChartOptions, transformChartData } from './utils';
import styles from './styles.module.scss';

function BarsGraph({
  isLoading,
  topActiveData,
  titles,
  className,
  customTransformChartData,
  rightItems,
  chartClassName,
}) {
  return useMemo(() => {
    if (isLoading) {
      return (
        <LoadingPlaceholder
          {...titles}
          className={className}
          rightItems={rightItems}
        />
      );
    }

    const transformer = customTransformChartData || transformChartData;
    const { chartData, labels, borderColor, backgroundColor } = transformer(topActiveData);
    const showPlaceholder = isEmpty(topActiveData);
    const data = {
      labels,
      datasets: [
        {
          data: chartData,
          borderWidth: 1,
          borderColor,
          backgroundColor,
        },
      ],
    };

    return (
      <DataCard
        {...titles}
        showPlaceholder={showPlaceholder}
        className={className}
        rightItems={rightItems}
      >
        <div className={styles.scrollable}>
          <div className={classNames(styles.chartContainer, chartClassName)}>
            <Bar options={getChartOptions()} data={data} />
          </div>
        </div>
      </DataCard>
    );
  }, [isLoading, topActiveData]);
}

BarsGraph.propTypes = {
  topActiveData: PropTypes.array,
  titles: PropTypes.object.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  customTransformChartData: PropTypes.func,
  rightItems: PropTypes.node,
  chartClassName: PropTypes.string,
};

export default BarsGraph;
