import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, size } from 'lodash';

import { getFeedUrl } from 'components/Dashboard/utils';
import Link from 'utils/Link';

import { workspacesShape } from '../../utils/shapes';
import styles from './styles.module.scss';

function Workspaces({ data, className, typeClassName }) {
  function renderWorkspaces() {
    return map(data, ({ token, name }, index, all) => {
      const separator = index !== size(all) - 1 && ', ';
      const href = getFeedUrl(token);

      return (
        <Fragment key={token}>
          <Link
            className={classNames(styles.baseLink)}
            theme="tableLink"
            href={href}
          >
            {name}
          </Link>
          {separator}
        </Fragment>
      );
    });
  }

  return (
    <div className={classNames(styles.content, className)}>
      <span className={classNames(typeClassName)}>Workspaces:</span> {renderWorkspaces()}
    </div>
  );
}

Workspaces.propTypes = {
  data: workspacesShape.isRequired,
  className: PropTypes.string,
  typeClassName: PropTypes.string,
};

export default Workspaces;
