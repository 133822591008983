import React from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './ListItem/styles.module.scss';
import parentStyles from '../styles.module.scss';

function Placeholder({ count, className }) {
  function renderPlaceholderRow(rowItem) {
    return (
      <div key={`item-${rowItem}`} className={classNames(styles.listItem, styles.placeholderRow)}>
        <div className={styles.label}>
          <TextPlaceholder className={styles.placeholderBadge} />
        </div>

        <TextPlaceholder className={styles.content} />
      </div>
    );
  }

  return (
    <div className={classNames(parentStyles.container, className)}>
      {range(count).map(renderPlaceholderRow)}
    </div>
  );
}

Placeholder.defaultProps = {
  count: 8,
};

Placeholder.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
};

export default Placeholder;
