import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../Checkbox';

import styles from './styles.module.scss';

function CheckboxButton({
  onChange,
  isChecked,
  isDisabled,
  label,
  className,
  ...restProps
}) {
  return (
    <Checkbox
      {...restProps}
      label={label}
      onChange={onChange}
      isChecked={isChecked}
      isDisabled={isDisabled}
      className={classNames(styles.checkboxButton, className)}
    />
  );
}

CheckboxButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckboxButton;
