import { map, sortBy, uniq } from 'lodash';

import { FEDERAL_ROLE, LOCAL_ROLE, STATE_ROLE } from 'utils/transformers/profile/constants';
import { joinCompact } from 'utils/string';

export function getProfileUrl(roleType, id) {
  const baseUrls = {
    [FEDERAL_ROLE]: '/federal',
    [STATE_ROLE]: '/state',
    [LOCAL_ROLE]: '/local',
  };

  return `${baseUrls[roleType]}/executives/${id}`;
}

export const getPositionDescription = (position) => {
  const { title, role, organizations, additionalInfo } = position || {};
  const { branch, department } = additionalInfo || {};
  const formattedOrganizations = uniq(map(sortBy(organizations, 'level'), 'name'));

  return joinCompact(uniq([title, role, ...formattedOrganizations, department, branch]));
};
