import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useSortTable from 'utils/useSortTable';
import { getColumnLabelBySortingKey } from 'utils/table';

function useTable({
  paginator,
  sort: sortRedux,
  onSearch,
  onClearAll,
  trackingActions,
  columns,
}) {
  const { toReset = false } = sortRedux || {};

  const sort = useSortTable(toReset);
  const isPaginatorPresent = !isEmpty(paginator);
  const { trackVotingRecordPageVisited, trackVotingRecordSorted } = trackingActions || {};

  /** Fix default sort  */
  useEffect(() => {
    if (!isEmpty(sortRedux)) {
      const { sort: reduxColumn, direction } = sortRedux;

      sort && sort.setSortData({ direction, column: reduxColumn });
    }
  }, [sortRedux]);

  function handleSortColumn(column) {
    const { getDirection } = sort;
    const direction = getDirection(column);
    const columnLabel = getColumnLabelBySortingKey(columns, column);

    onSearch({ sort: column, direction });
    trackVotingRecordSorted && trackVotingRecordSorted(columnLabel, direction);
  }

  function handleChangePage(page) {
    const { sortData = {} } = sort;
    const { column, direction } = sortData;

    onSearch({ page, sort: column, direction });
    trackVotingRecordPageVisited && trackVotingRecordPageVisited(page);
  }

  function handleClearAll() {
    onClearAll();
  }

  return {
    ...sort,
    isPaginatorPresent,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
    onClear: handleClearAll,
  };
}

export default useTable;
