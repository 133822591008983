import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/core/Label';
import RadioBox from 'components/core/RadioBox';
import { REQUIRED_FIELD_OPTIONS } from 'components/core/CustomFields/utils/constants';

function RequiredProperty({ required, onChange }) {
  return (
    <>
      <Label theme="light">Required field?</Label>
      {REQUIRED_FIELD_OPTIONS.map(({ label, value }) => (
        <RadioBox
          key={`required-field-${value}`}
          label={label}
          value={value}
          theme="default"
          onChange={onChange}
          checked={required === value}
        />
      ))}
    </>
  );
}

RequiredProperty.propTypes = {
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequiredProperty;
