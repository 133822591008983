import {
  DARK_THEME,
  LIGHT_THEME,
  MEDIUM_SIZE,
  SMALL_SIZE,
  EXTRA_SMALL_SIZE,
  BIG_SIZE,
  BLUE_THEME,
} from './constants';

import styles from './styles.module.scss';

export const loaderThemes = {
  [LIGHT_THEME]: styles.light,
  [DARK_THEME]: styles.dark,
  [BLUE_THEME]: styles.blue,
};

export const loadingTextThemes = {
  [LIGHT_THEME]: styles.loadingTextLight,
  [DARK_THEME]: styles.loadingTextDark,
};

export const loaderSizes = {
  [MEDIUM_SIZE]: styles.medium,
  [SMALL_SIZE]: styles.small,
  [EXTRA_SMALL_SIZE]: styles.xSmall,
  [BIG_SIZE]: styles.big,
};
