export const ALL_BILLS = {
  label: 'Sponsor or cosponsor',
  value: 'all',
};

export const SPONSOR_BILLS = {
  label: 'Sponsor',
  value: 'sponsor',
};

export const COSPONSOR_BILLS = {
  label: 'Cosponsor',
  value: 'cosponsor',
};

export const BILL_TYPES = [ALL_BILLS, SPONSOR_BILLS, COSPONSOR_BILLS];
