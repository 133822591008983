import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';

import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants/intervals';
import { DEFAULT_BIAS_RADIUS, DEFAULT_BIAS_LAT, DEFAULT_BIAS_LNG } from 'utils/constants/placesAutocomplete';

import Input from './components/Input';
import Dropdown from './components/Dropdown';
import useAddressAutocomplete from './useAddressAutocomplete';
import styles from './styles.module.scss';

function AddressAutocomplete({
  placeId,
  value,
  onChange,
  onBlur,
  placeType,
  inputClassName,
  placeholder,
  withFromGroup,
  withInputSelect,
  isError,
  allowInternationalSearch,
  ...restProps
}) {
  const ref = useRef(null);

  const {
    address,
    onChange: handleChange,
    onError: handleError,
    onSelect: handleSelect,
    overrideOnBlur,
  } = useAddressAutocomplete({ value, placeId, onChange, onBlur, ref });

  function renderSearchOptions() {
    const options = {
      location: new google.maps.LatLng(DEFAULT_BIAS_LAT, DEFAULT_BIAS_LNG),
      radius: DEFAULT_BIAS_RADIUS,
      types: placeType ? [placeType] : [],
    };

    if (allowInternationalSearch) return options;

    return {
      ...options,
      componentRestrictions: {
        country: 'us',
      },
    };
  }

  const classes = classNames(
    styles.addressAutocomplete,
    withFromGroup && 'form-group',
  );

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={handleError}
      searchOptions={renderSearchOptions()}
      debounce={DEFAULT_DEBOUNCE_TIME}
      highlightFirstSuggestion
      ref={overrideOnBlur}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={classes} ref={ref}>
          <Input
            {...restProps}
            getInputProps={getInputProps}
            isLoading={loading}
            className={inputClassName}
            placeholder={placeholder}
            withInputSelect={withInputSelect}
            isError={isError}
          />
          <Dropdown
            items={suggestions}
            getSuggestionItemProps={getSuggestionItemProps}
          />
        </div>
      )}
    </PlacesAutocomplete>
  );
}

AddressAutocomplete.defaultProps = {
  value: '',
  withFromGroup: false,
  withInputSelect: false,
  allowInternationalSearch: false,
};

AddressAutocomplete.propTypes = {
  placeId: PropTypes.string,
  value: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  // https://developers.google.com/places/supported_types#table3
  placeType: PropTypes.string,
  withFromGroup: PropTypes.bool,
  withInputSelect: PropTypes.bool,
  isError: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  allowInternationalSearch: PropTypes.bool,
};

export default AddressAutocomplete;
