import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';
import { stringNumberType } from 'utils/shapes';

import { DEFAULT_TEXT_COLOR, DEFAULT_LINK_COLOR } from '../../utils/colors.scss';
import LabelTitle from '../LabelTitle';

const styles = StyleSheet.create({
  valueText: {
    fontWeight: 400,
    fontSize: 12,
    color: DEFAULT_LINK_COLOR,
    paddingBottom: 2,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  emptyText: {
    fontWeight: 400,
    fontSize: 12,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function LabelLink({
  containerStyle,
  label,
  href,
  value,
  isUnderline,
  linkStyle,
  emptyTextStyle,
  labelStyle,
}) {
  if (isEmpty(href)) {
    const emptyStyle = {
      ...styles.emptyText,
      ...emptyTextStyle,
    };

    return (
      <View style={containerStyle}>
        <LabelTitle style={labelStyle} label={label} />
        <Text style={emptyStyle}>{value || EMPTY_VALUE}</Text>
      </View>
    );
  }

  const currentStyle = {
    ...styles.valueText,
    ...(!isUnderline ? styles.noUnderline : {}),
    ...linkStyle,
  };

  return (
    <View style={containerStyle}>
      <LabelTitle style={labelStyle} label={label} />
      <Link src={href} style={currentStyle}>{value || href}</Link>
    </View>
  );
}

LabelLink.defaultProps = {
  labelStyle: {},
  isUnderline: false,
};

const styleShape = PropTypes.shape({
  [PropTypes.string]: stringNumberType,
});

LabelLink.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  href: PropTypes.string,
  labelStyle: styleShape,
  containerStyle: styleShape,
  isUnderline: PropTypes.bool,
  linkStyle: styleShape,
  emptyTextStyle: styleShape,
};

export default LabelLink;
