export const NO_PRIORITY = 'no_priority';

export const priorities = {
  highest: {
    label: 'Highest',
    icon: 'fas fa-chevron-double-up',
  },
  high: {
    label: 'High',
    icon: 'fas fa-arrow-alt-up',
  },
  [NO_PRIORITY]: {
    label: 'No priority',
    icon: 'fas fa-times-circle',
  },
  low: {
    label: 'Low',
    icon: 'fas fa-arrow-alt-down',
  },
  lowest: {
    label: 'Lowest',
    icon: 'fas fa-chevron-double-down',
  },
};
