import 'core-js/es6/map';
import { uniq, isEmpty } from 'lodash';

function getMappedValues(allValues = [], toString = false) {
  if (isEmpty(allValues)) {
    return [];
  }

  const mapData = new Map([]);

  for (const item of allValues) {
    const { label, value } = item;
    const values = [value] || [];

    if (mapData.has(label)) {
      values.push(...mapData.get(label).value);
    }

    const currentValues = toString
      ? values.map(currentValue => currentValue.toString())
      : values;

    mapData.set(label, { ...item, value: uniq(currentValues) });
  }

  return Array.from(mapData.values());
}

export default getMappedValues;
