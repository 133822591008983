import React from 'react';
import classNames from 'classnames';

import { Icon } from 'components/core/Icons';

import styles from './Container/styles.module.scss';

function Placeholder() {
  return (
    <span className={styles.notifications}>
      <Icon iconClass={classNames('fa fa-bell', styles.icon, styles.isDisabled)} />
    </span>
  );
}

export default Placeholder;
