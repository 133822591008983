import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import Tooltip from '@tippyjs/react';

import { LegislatorItem } from 'components/core/Table/components/ColumnItem';
import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';

import { getLegislatorUrl } from './utils';
import styles from './styles.module.scss';

function LegislatorListItem({
  keyName,
  trackLegislatorVisited,
  formatLegislatorUrl,
  columnClassName,
  tooltipBottomContent,
  item,
}) {
  const {
    legislators = [{}],
    allLegislatorsSize = 0,
  } = item || {};

  const firstItem = legislators[0] || {};
  const isEmptyData = isEmpty(legislators);
  const legislatorsLength = legislators.length;
  const currentLegislatorsLength = !isNil(allLegislatorsSize) ? allLegislatorsSize : legislatorsLength;

  function getLegislatorItem(legislator = {}) {
    const { name, id } = legislator;
    const url = getLegislatorUrl(legislator, formatLegislatorUrl);

    const keyId = legislator.partyShort ? id : `committee-${id}`;

    const handleLegislatorClick = () => trackLegislatorVisited
      && trackLegislatorVisited(name);

    return (
      <LegislatorItem
        {...legislator}
        className={classNames(styles.legislatorItem, styles.cosponsorItem)}
        keyName={`${keyName}-item-${keyId}`}
        contentClassName={styles.tooltipHoverItem}
        url={url}
        isPlainText
        key={`legislator-item-${keyId}`}
        onClick={handleLegislatorClick}
      />
    );
  }

  function tooltipNode() {
    if (isEmptyData) {
      return null;
    }

    const tooltipLegislators = [...legislators];
    tooltipLegislators.shift();

    return (
      <div className={styles.tooltipContent}>
        {tooltipLegislators.map(getLegislatorItem)}

        {tooltipBottomContent}
      </div>
    );
  }

  function renderLinks() {
    if (isEmptyData || isEmpty(firstItem)) {
      return EMPTY_VALUE;
    }

    if (legislatorsLength === 1) {
      return getLegislatorItem(firstItem);
    }

    return (
      <div className={styles.container}>
        <Tooltip
          content={tooltipNode()}
          className={classNames(
            styles.tooltipWrapper,
            styles.tooltipContainer,
            columnClassName,
          )}
          placement="bottom"
          appendTo={document.body}
          interactive
        >
          <div className={styles.linkLabel}>
            <div className={styles.cosponsorItem}>
              {getLegislatorItem(firstItem)}
            </div>

            <div className={styles.badge}>
              +  {currentLegislatorsLength - 1} more
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }

  return <Column key={keyName} keyName={keyName}>{renderLinks}</Column>;
}

LegislatorListItem.propTypes = {
  tooltipBottomContent: PropTypes.node,
  item: PropTypes.object.isRequired,
  trackLegislatorVisited: PropTypes.func,
  keyName: PropTypes.string.isRequired,
  formatLegislatorUrl: PropTypes.func,
  columnClassName: PropTypes.string,
};

export default LegislatorListItem;
