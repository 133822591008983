import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Link from 'components/core/Link';
import Avatar from 'components/core/Avatar';
import { mapTypeCodes } from 'components/core/Map/utils/constants';
import { PartyIcon } from 'components/core/Icons';
import { getLegislatorProfileUrl } from 'components/Stakeholder/Profile/utils';
import { TWITTER_LINK } from 'utils/constants/socialMedia';

import { transformLegislator } from './utils';
import Placeholder from './components/Placeholder';
import styles from './styles.module.scss';

export default function Legislator({ legislator, mapType, className }) {
  const {
    id,
    role,
    twitterName,
    phone,
    party,
    photoUrl,
    title,
    name,
  } = transformLegislator(legislator);

  if (isEmpty(legislator)) {
    return <Placeholder />;
  }

  const isFederal = mapType === mapTypeCodes.congressional;

  function getContact() {
    const isTwitterNameEmpty = isEmpty(twitterName);
    const isPhoneEmpty = isEmpty(phone);

    if (isTwitterNameEmpty && isPhoneEmpty) {
      return null;
    }

    function getTwitterLink() {
      if (isTwitterNameEmpty) {
        return null;
      }

      return (
        <Link
          size="none"
          theme="link"
          href={`${TWITTER_LINK}/${twitterName}`}
          className={styles.link}
        >
          @{twitterName}
        </Link>
      );
    }

    function getPhone() {
      if (isPhoneEmpty) {
        return null;
      }

      return (
        <Link
          size="none"
          theme="link"
          href={`tel:${phone}`}
          className={styles.link}
        >
          {phone}
        </Link>
      );
    }

    return (
      <div className={styles.contact}>
        {getTwitterLink()}
        {twitterName && phone && ' | '}
        {getPhone()}
      </div>
    );
  }

  return (
    <section className={classNames(styles.card, className)}>
      <Avatar
        name={name}
        photoUrl={photoUrl}
        className={styles.avatar}
      />
      <div>
        <Link
          size="none"
          theme="light"
          className={styles.title}
          href={getLegislatorProfileUrl(isFederal, id)}
        >
          {title}
        </Link>

        <p className={styles.description}>{role}</p>

        {getContact()}
        <PartyIcon party={party} className={styles.partyIcon} />
      </div>
    </section>
  );
}

const legislatorShape = PropTypes.shape({
  full_role: PropTypes.string,
  twitter_screen_name: PropTypes.string,
  phone: PropTypes.string,
  party: PropTypes.string,
  photo_url: PropTypes.string,
  long_moniker: PropTypes.string.isRequired,
});

Legislator.propTypes = {
  legislator: legislatorShape,
  mapType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Placeholder, legislatorShape };
