import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import { map, reduce, find, slice, size } from 'lodash';

import ButtonTag from 'components/core/ButtonTag';
import Link from 'components/core/Link';
import { workspaceShape } from 'redux/events/utils/shapes';
import { TagsPlaceholder } from 'components/core/Placeholder';
import { trackingShape } from 'redux/subscriptions/utils/shapes';
import { TAGS_DISPLAY_LENGTH } from 'components/core/ViewTags/constants';

import { getTileUrl } from '../WorkspaceTile/utils';
import WorkspaceTile from '../WorkspaceTile';
import tagsStyles from '../ViewTags/styles.module.scss';
import styles from './styles.module.scss';

function WorkspaceTiles({
  className,
  wrapperClassName,
  sharings,
  isLoading,
  trackings,
  maxLength,
  displayAll,
}) {
  if (isLoading) {
    return <TagsPlaceholder className={wrapperClassName} itemsNumber={1} />;
  }

  const workspaces = reduce(sharings, (acc, sharing) => {
    const tracking = find(trackings, ({ feed }) => feed.token === sharing.token);

    if (!tracking) acc.push({ feed: sharing });

    return acc;
  }, [...trackings]);
  const displayedWorkspaces = displayAll ? workspaces : slice(workspaces, 0, maxLength);
  const remainingWorkspaces = slice(workspaces, maxLength);
  const workspacesSize = size(workspaces);
  const showTooltip = !displayAll && (workspacesSize > maxLength);

  const renderTile = ({ feed, subscription, subscriptions }) => (
    <WorkspaceTile
      key={feed.token}
      className={className}
      wrapperClassName={wrapperClassName}
      workspace={feed}
      subscription={subscription}
      subscriptions={subscriptions}
      isLoading={isLoading}
    />
  );

  const renderWorkspaceLink = ({ feed }) => (
    <Link
      href={getTileUrl(feed.token)}
      key={feed.token}
      theme="tableLink"
      className={styles.workspaceLink}
    >
      {feed.name}
    </Link>
  );
  const tooltipContent = (
    <div className={tagsStyles.tooltipContainer}>
      {map(remainingWorkspaces, renderWorkspaceLink)}
    </div>
  );

  return (
    <>
      {map(displayedWorkspaces, renderTile)}
      {showTooltip && (
        <Tooltip
          theme="dark"
          content={tooltipContent}
          placement="bottom"
          arrow
          interactive
          maxWidth={380}
        >
          <span className={tagsStyles.remainingTags}>
            <ButtonTag theme="green">
              +{workspacesSize - maxLength}
            </ButtonTag>
          </span>
        </Tooltip>
      )}
    </>
  );
}

WorkspaceTiles.defaultProps = {
  trackings: [],
  maxLength: TAGS_DISPLAY_LENGTH,
  displayAll: false,
};

WorkspaceTiles.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  sharings: PropTypes.arrayOf(workspaceShape),
  isLoading: PropTypes.bool,
  trackings: PropTypes.arrayOf(trackingShape),
  maxLength: PropTypes.number,
  displayAll: PropTypes.bool,
};

export default WorkspaceTiles;
