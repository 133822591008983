import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function Banner({ message, contactEmail, className }) {
  return (
    <div className={classNames(styles.container, className)} data-test="banner">
      <p className={styles.message}>
        {message}
      </p>
      <Link
        href={`mailto:${contactEmail}`}
        theme="primary"
        className={styles.button}
        isExternal
      >
        Contact Customer Success
      </Link>
    </div>
  );
}

Banner.defaultProps = {
  message: 'Product not available.',
  contactEmail: gon.supportEmail,
};

Banner.propTypes = {
  message: PropTypes.string,
  contactEmail: PropTypes.string,
  className: PropTypes.string,
};

export default Banner;
