import React from 'react';

import { TextPlaceholder } from 'components/core/Placeholder';

import getItemStyle from '../theme';
import styles from '../styles.module.scss';

function Placeholder() {
  return (
    <div className={styles.container}>
      <ul className={styles.group}>
        {
          Array(3).fill(0).map((_, index) => (
            <li
              className={styles.draggable}
              style={getItemStyle(false)}
              key={index}
            >
              <i className="far fa-arrows-alt" /> {<TextPlaceholder />}
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default Placeholder;
