import React from 'react';
import PropTypes from 'prop-types';

import {
  CHECKBOX_TYPE_SLUG,
  RADIO_TYPE_SLUG,
  TEXTAREA_TYPE_SLUG,
  DATE_TYPE_SLUG,
  AUTOCOMPLETE_SLUG,
} from 'components/core/CustomFields/utils/constants';

import InputControl from './controls/InputControl';
import CheckboxControl from './controls/CheckboxControl';
import RadioboxControl from './controls/RadioboxControl';
import TextareaControl from './controls/TextareaControl';
import DateControl from './controls/DateControl';
import Autocomplete from './controls/Autocomplete';
import customFieldShape from './shapes/customField';
import controlStateShape from './shapes/controlState';

export default function CustomField({
  field,
  controlState,
  handlers,
  styles,
  ...restProps
}) {
  const { fieldType, token } = field;
  const { controlClass, wrapperClass } = styles;
  const { handleChange, handleBlur } = handlers;
  const commonProps = {
    onChange: handleChange,
    onBlur: handleBlur,
    className: controlClass,
    name: token,
    state: controlState,
    field,
    ...restProps,
  };

  function getControl() {
    switch (fieldType) {
      case CHECKBOX_TYPE_SLUG:
        return <CheckboxControl {...commonProps} />;

      case RADIO_TYPE_SLUG:
        return <RadioboxControl {...commonProps} />;

      case TEXTAREA_TYPE_SLUG:
        return <TextareaControl {...commonProps} />;

      case DATE_TYPE_SLUG:
        return <DateControl {...commonProps} />;

      case AUTOCOMPLETE_SLUG:
        return <Autocomplete {...commonProps} />;

      default:
        return <InputControl {...commonProps} />;
    }
  }

  return (
    <div key={token} className={wrapperClass}>
      {getControl()}
    </div>
  );
}

CustomField.defaultProps = {
  styles: {},
};

CustomField.propTypes = {
  field: customFieldShape,
  controlState: controlStateShape,
  handlers: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }).isRequired,
  styles: PropTypes.shape({
    controlClass: PropTypes.string,
    wrapperClass: PropTypes.string,
  }),
};
