import React from 'react';
import PropTypes from 'prop-types';

import { withDimensionLocking } from '../../utils/withDimensionLocking';
import Default from './components/Default';
import DnD from './components/DnD';

function Column({ withDragNDrop, children, ...restProps }) {
  const ColumnComponent = withDragNDrop ? DnD : Default;

  return (
    <ColumnComponent {...restProps}>
      {children}
    </ColumnComponent>
  );
}

Column.propTypes = {
  withDragNDrop: PropTypes.bool.isRequired,
  children: PropTypes.func,
};

export default withDimensionLocking(Column);
