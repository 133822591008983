import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';

import { DEFAULT_TEXT_COLOR } from '../../utils/colors.scss';
import LabelTitle from '../LabelTitle';
import LabelLink from '../LabelLink';

const styles = StyleSheet.create({
  valueText: {
    fontWeight: 400,
    fontSize: 12,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function MultipleLabelArray({ style, label, values, valueStyle }) {
  if (isEmpty(values)) {
    return (
      <View style={style}>
        {label && <LabelTitle label={label} />}
        <Text style={styles.valueText}>{EMPTY_VALUE}</Text>
      </View>
    );
  }

  function getLinkOrTextValue(linkValueObject) {
    const { value, href } = linkValueObject || {};

    if (isEmpty(href)) {
      return <Text style={styles.valueText}>{value || EMPTY_VALUE}</Text>;
    }

    return <LabelLink {...linkValueObject} />;
  }

  function renderValues(valueObject, index) {
    return (
      <View key={`multiple-label-${index}`} style={valueStyle}>
        <LabelTitle label={values.length > 1 ? `${label} ${index + 1}` : label} />
        {getLinkOrTextValue(valueObject)}
      </View>
    );
  }

  return (
    <View style={style}>
      {values.map(renderValues)}
    </View>
  );
}

MultipleLabelArray.defaultProps = {
  style: {},
};

MultipleLabelArray.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  style: PropTypes.object,
  valueStyle: PropTypes.object,
};

export default MultipleLabelArray;
