import { LOGO_CUSTOMIZATION_ID, PDF_ID } from 'utils/constants/export';

export const EXPORT_FILE_ITEMS = [
  {
    id: PDF_ID,
    title: 'PDF',
  },
];

export const EXPORT_CUSTOMIZATION_ITEMS = [
  {
    id: LOGO_CUSTOMIZATION_ID,
    title: 'Show my logo',
  },
];
