import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { DISABLED_MSG, OPTION_PLACEHOLDER } from 'components/core/CustomFields/utils/constants';
import withCustomField from 'components/core/CustomFields/utils/withCustomField';
import Button from 'components/core/Button';
import Label from 'components/core/Label';
import Input from 'components/core/Input';
import { getError } from 'utils/forms';

import styles from '../styles.module.scss';

function Option({
  ui,
  index,
  option: {
    value,
    initialValue,
  } = {},
  formState,
  isEditable,
  answered,
  onRemove,
  initialOptionsLength,
  onChange,
  onBlur,
  optionsCount,
}) {
  function handleRemove() {
    onRemove(index);
  }

  function getIsDisabled() {
    return !isEditable && index < initialOptionsLength;
  }

  function getErrorMessage() {
    return getIsDisabled()
      ? DISABLED_MSG
      : getError(`options[${index}].value`, formState);
  }

  const isLoading = ui.isSavingCustomField || ui.isUpdatingCustomField;
  const isDisabledOrLoading = getIsDisabled(index) || isLoading;
  const withDeleteButton = answered ? (optionsCount > 2 && isNil(initialValue)) : optionsCount > 2;
  const commonProps = {
    onChange,
    onBlur,
  };

  return (
    <div className={styles.option}>
      <Label htmlFor={`options-${index}`} theme="light">Value {index + 1}</Label>

      <div className={styles.optionForm}>
        <div className={styles.fieldWrap}>
          <Input
            id={`options-${index}`}
            name={`options.${index}.value`}
            value={value}
            placeholder={OPTION_PLACEHOLDER}
            disabled={isDisabledOrLoading}
            errorMessage={getErrorMessage()}
            {...commonProps}
          />
        </div>

        {withDeleteButton && (
          <Button
            type="button"
            size="none"
            theme="light"
            onClick={handleRemove}
            isRemoveIcon
            disabled={isDisabledOrLoading}
          />
        )}
      </div>
    </div>
  );
}

Option.propTypes = {
  ui: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  answered: PropTypes.bool,
  index: PropTypes.number.isRequired,
  option: PropTypes.object.isRequired,
  initialOptionsLength: PropTypes.number,
  formState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  optionsCount: PropTypes.number.isRequired,
};

export default withCustomField(Option);
