import React from 'react';
import PropTypes from 'prop-types';

import { LegislatorListItem } from 'components/core/Table/components/ColumnItem';

import withBills from '../../utils/withBills';
import ViewAllLink from '../ViewAllLink';

function CosponsorsColumn({ keyName, item, trackingActions, formatLegislatorUrl }) {
  const { trackBillCosponsorVisited } = trackingActions;
  const currentItem = {
    ...item,
    legislators: item.cosponsors,
    allLegislatorsSize: item.allCosponsorsSize,
  };
  const tooltipContent = <ViewAllLink item={currentItem} />;

  return (
    <LegislatorListItem
      item={currentItem}
      keyName={keyName}
      trackLegislatorVisited={trackBillCosponsorVisited}
      formatLegislatorUrl={formatLegislatorUrl}
      tooltipBottomContent={tooltipContent}
      columnClassName="legislator-bills__cosponsor-column"
    />
  );
}

CosponsorsColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  trackingActions: PropTypes.object,
  item: PropTypes.object.isRequired,
  formatLegislatorUrl: PropTypes.func.isRequired,
};

export default withBills(CosponsorsColumn);
