import React from 'react';
import PropTypes from 'prop-types';

import TextPlaceholder from '../../../Text';
import styles from './styles.module.scss';

function MobileTabPlaceholder({ itemsNumber }) {
  function renderTitle(_, index) {
    return (
      <div className={styles.mobileItem} key={`tab-mobile-${index}`}>
        <TextPlaceholder className={styles.title} />
        <TextPlaceholder className={styles.description} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {Array(itemsNumber).fill(0).map(renderTitle)}
    </div>
  );
}

MobileTabPlaceholder.propTypes = {
  itemsNumber: PropTypes.number.isRequired,
};

export default MobileTabPlaceholder;
