import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.module.scss';

export default function Title({ onClose, title }) {
  return (
    <h2 className={styles.title}>
      {title}
      <i className={classNames(styles.icon, 'fal fa-times')} onClick={onClose} />
    </h2>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
