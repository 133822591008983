import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { DEFAULT_LOGO_URL } from 'utils/constants';
import { getCorsImageUrl } from 'utils/constants/export';

import { DEFAULT_TEXT_COLOR } from '../../utils/colors.scss';

const styles = StyleSheet.create({
  headerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 25,
  },
  image: {
    height: 25,
    maxWidth: 130,
    marginTop: 7,
  },
  rightTextSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '45vw',
  },
  reportText: {
    flex: 1,
    maxHeight: 14,
    fontSize: 10,
    fontWeight: 300,
    textAlign: 'right',
    color: DEFAULT_TEXT_COLOR,
  },
  titleText: {
    flex: 1,
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'right',
    color: DEFAULT_TEXT_COLOR,
  },
});

function DocumentTitle({ logoUrl, title, date, withLogo, fixed, imageStyle }) {
  const currentDate = date || moment().format(GLOBAL_DATE_FORMAT);

  return (
    <View style={styles.headerSection} fixed={fixed}>
      {withLogo && (
        <Image
          src={getCorsImageUrl(logoUrl)}
          style={[styles.image, imageStyle]}
        />
      )}
      <View style={styles.rightTextSection}>
        <Text style={styles.reportText}>Report ({currentDate})</Text>
        {title && <Text style={styles.titleText}>{title}</Text>}
      </View>
    </View>
  );
}

DocumentTitle.defaultProps = {
  withLogo: true,
  logoUrl: DEFAULT_LOGO_URL,
};

DocumentTitle.propTypes = {
  logoUrl: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  withLogo: PropTypes.bool,
  fixed: PropTypes.bool,
  imageStyle: PropTypes.instanceOf(StyleSheet),
};

export default DocumentTitle;
