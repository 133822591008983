import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ColumnsContainer({ children }) {
  return (
    <div className={styles.columnsContainer}>
      {children}
    </div>
  );
}

ColumnsContainer.propTypes = {
  children: PropTypes.node,
};

export default ColumnsContainer;

