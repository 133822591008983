import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';
import PartyIcon from 'components/shared/party-icon';

import styles from './styles.module.scss';

function Legislator({
  id,
  name,
  partyShort,
  partySlug,
  title,
  className,
  formatLegislatorUrl,
}) {
  const partyIcon = (
    <PartyIcon partyShort={partyShort} partySlug={partySlug} />
  );

  const legislatorTitle = title && (
    <span className={styles.title}>
      ({title})
    </span>
  );

  const renderLink = () => {
    if (!id) {
      return (
        <span>
          {name} {partyIcon} {legislatorTitle}
        </span>
      );
    }

    return (
      <Link
        theme="default"
        size="none"
        href={formatLegislatorUrl(id)}
        className={styles.link}
      >
        {name} {partyIcon} {legislatorTitle}
      </Link>
    );
  };

  return (
    <div className={className}>
      {renderLink()}
    </div>
  );
}

Legislator.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  partyShort: PropTypes.string.isRequired,
  partySlug: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  formatLegislatorUrl: PropTypes.func.isRequired,
};

export default Legislator;

