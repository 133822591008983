import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';

import SearchInput from './components/SearchInput';
import useCheckboxGroup from './utils/useCheckboxGroup';
import styles from './styles.module.scss';

function SearchableCheckboxGroup({
  options,
  className,
  labelGroupClassName,
  onItemCheck,
  onCheckAll,
  onSearch,
  numColumns,
  checkedItems,
  isMobile,
  renderNoResultsPlaceholder,
  showSelectAll,
}) {
  const {
    query,
    groupedOptions,
    handleInputChange,
    areOptionsEmpty,
    onCheckItem,
    isAllChecked,
  } = useCheckboxGroup({
    checkedItems,
    showSelectAll,
    options,
    isMobile,
    onItemCheck,
    onSearch,
    numColumns,
  });

  function renderCheckbox(option, index) {
    const { keyName, label, isSelectAll } = option;
    const onChange = isSelectAll ? onCheckAll : onCheckItem(keyName);
    const isChecked = isSelectAll ? isAllChecked : checkedItems.includes(keyName);

    return (
      <Checkbox
        key={`${keyName}-${index}`}
        value={keyName}
        isChecked={isChecked}
        onChange={onChange}
        label={label}
      />
    );
  }

  function renderGroup(optionsGroup, i) {
    const containerClassNames = classNames(
      styles.labelGroup,
      numColumns > 1 && styles.multipleColumn,
    );

    return (
      <LabelGroup
        key={`group-${i}`}
        containerClassName={containerClassNames}
        className={styles.labelGroupItem}
      >
        {optionsGroup.map(renderCheckbox)}
      </LabelGroup>
    );
  }

  return (
    <div className={className}>
      <SearchInput query={query} onChange={handleInputChange} />
      <div className={classNames(styles.labelGroupContainer, labelGroupClassName)}>
        {areOptionsEmpty
          ? renderNoResultsPlaceholder(query)
          : groupedOptions.map(renderGroup)
        }
      </div>
    </div>
  );
}

SearchableCheckboxGroup.defaultProps = {
  showSelectAll: false,
};

SearchableCheckboxGroup.propTypes = {
  onSearch: PropTypes.func,
  onItemCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    keyName: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
  numColumns: PropTypes.number.isRequired,
  labelGroupClassName: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderNoResultsPlaceholder: PropTypes.func.isRequired,
  showSelectAll: PropTypes.bool,
};

export default SearchableCheckboxGroup;
