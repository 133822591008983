import { useEffect, useState } from 'react';
import map from 'lodash/map';

import { Push } from 'components/core/Notification';
import useMultipleNotifications from 'components/core/Notification/utils/useMultipleNotifications';
import DisplayAll from 'components/core/Notification/components/MultipleNotifications/DisplayAll';

import styles from '../styles.module.scss';
import { MY_FEED_LINK, BADGE_CONTENT_LIMIT } from './constants';

function useNotifications({
  notifications = [],
  fetchNotifications,
  stopFetchNotifications,
  isMobile,
  totalNotifications,
  displayNotifications,
  localCount = 0,
  togglePanel,
  isNotificationsPanelOpen,
  resetPanel,
}) {
  const [showToast, setShowToast] = useState(false);
  const [previousTotal, setPreviousTotal] = useState(localCount);

  const isOverLimit = totalNotifications > BADGE_CONTENT_LIMIT;
  const displayBadge = totalNotifications > 0 && !(isMobile && isNotificationsPanelOpen);
  const badgeContent = isOverLimit
    ? `${BADGE_CONTENT_LIMIT}+`
    : totalNotifications;
  const displayCloseIcon = !gon.isNavigationV2Enabled && isMobile && isNotificationsPanelOpen;

  const alerts = map(notifications, (notification) => {
    const { id } = notification;

    return { id, content: Push({ ...notification, className: styles.card, withTitle: true }) };
  });

  const myPageProps = { href: MY_FEED_LINK, pageName: 'My feed' };

  const { pulse, onChangePulse } = useMultipleNotifications({
    id: 'multipleNotifications',
    nextList: alerts,
    myPageProps,
    renderDisplayAll: DisplayAll,
    displayNotifications: !isMobile && displayNotifications && !isNotificationsPanelOpen && showToast,
  });

  function handleTogglePanel() {
    isNotificationsPanelOpen
      ? fetchNotifications && fetchNotifications()
      : stopFetchNotifications && stopFetchNotifications();

    onChangePulse(!isNotificationsPanelOpen);
    togglePanel();
  }

  useEffect(() => {
    setShowToast(previousTotal !== localCount);
    setPreviousTotal(localCount);
  }, [localCount]);

  useEffect(() => {
    displayNotifications && fetchNotifications();

    return () => stopFetchNotifications();
  }, [displayNotifications]);

  useEffect(() => resetPanel, []);

  return {
    isOpenPanel: isNotificationsPanelOpen,
    onTogglePanel: handleTogglePanel,
    pulse: isMobile && pulse,
    displayBadge,
    displayCloseIcon,
    badgeContent,
    isOverLimit,
  };
}

export default useNotifications;
