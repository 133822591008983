import React from 'react';
import { map, isEmpty } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getPositionContent } from 'components/core/Profile/components/Cards/PoliticalHistory/utils';

import TableSection from '../../TableSection';

function PoliticalHistory({ person }) {
  const { politicalHistory } = person || {};

  if (isEmpty(politicalHistory)) {
    return null;
  }

  const tables = [
    map(politicalHistory, (historyItem, index) => ({
      label: `Organization ${index + 1}`,
      value: getPositionContent(historyItem),
    })),
  ];

  return (
    <TableSection
      title="Political history"
      tables={tables}
    />
  );
}

PoliticalHistory.propTypes = {
  person: personShape,
};

export default PoliticalHistory;
