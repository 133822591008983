import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

export default function usePageViewTracking({
  pageView,
  trackTableViewVisited = () => {},
  trackMapViewVisited = () => {},
}) {
  const { isTablePageView } = pageView;
  const hasPageView = !isEmpty(pageView);

  const trackPageViewVisited = () => {
    if (!hasPageView || isTablePageView) {
      return trackTableViewVisited();
    }

    return trackMapViewVisited();
  };

  useEffect(trackPageViewVisited, [isTablePageView]);
}
