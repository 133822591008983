import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  creationDate: {
    fontSize: 8,
    fontWeight: 300,
  },
});

export default styles;
