import { useEffect, useState } from 'react';
import { isFunction, merge, isEqual } from 'lodash';

import useSearchOptions from 'components/core/AdvancedSearch/utils/useSearchOptions';
import { hasAppliedFilters } from 'redux/appliedSearchFilters/utils';
import Notifications from 'components/core/PageLookup/utils/Notifications';
import { queryParse } from 'utils/query-string';

import { removeUnusedOptions } from './removeUnusedOptions';
import { SEARCH_ID_PARAM } from '../../../utils/constants';
import defaultUseSearchParams from '../../../utils/useSearchParams';

function useAdvancedSearch({
  initialState = false,
  dataProps = {},
  dataOptions = {},
  advancedSearch = {},
  notifications = {},
  appliedFilters = {},
  onSearchTransform,
  onCloseCustomView,
  useSearchParams = defaultUseSearchParams,
  lastSearchId,
  setLastSearchId,
  tableData,
}) {
  const {
    searchId: querySearchId,
    direction: queryDirection,
    sort: queryColumn,
    page: queryPage,
  } = queryParse(window.location.search);
  const { paginator = {}, sort: sortData = {} } = tableData;
  const { current: currentPage } = paginator;
  const { direction: sortDirection, sort: sortColumn } = sortData;
  const { useOptions, emptyState, ignoreOptions } = dataOptions;
  const { options: emptyOptions } = emptyState || {};
  const {
    id,
    sort,
    onSearch,
    onSaveItem,
    onRemoveItem,
    saveInRecentSearch,
    loadSavedSearches,
  } = advancedSearch;

  const {
    getSearchParams,
    handleReset: onClearAllSearchParams,
    handleSearchIdChange: handleSavedSearchExecution,
    clearParams,
  } = useSearchParams({
    sortDirection: sortDirection || queryDirection,
    sortColumn: sortColumn || queryColumn,
    currentPage: currentPage || queryPage,
    lastSearchId: lastSearchId || querySearchId,
  });

  const handlePreviousSearchExecution = (searchId) => {
    handleSavedSearchExecution(searchId);
    setLastSearchId && setLastSearchId(searchId);
  };

  const {
    allowSubmit,
    isDirty,
    isClickedItem,
    searchValue,
    searchType,
    setAllowSubmit,
    setSearchValue,
    setSearchTypeAdvanced,
    onClickItemHandleState,
    onClearAllHandleState,
    onChangeHandleState,
    onClickSearch,
  } = useSearchOptions({ ...emptyState, ...appliedFilters });

  const [previousSearchSelectedId, setPreviousSearchSelectedId] = useState(null);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(initialState);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const options = useOptions({ onChangeHandleState, setAllowSubmit, searchValue, appliedFilters, dataOptions });
  const { optionValues, fillOptions, resetValues, onChange } = options;
  const searchData = { searchValue, searchType, options: optionValues };

  useEffect(() => {
    setSearchTypeAdvanced(searchType);
  }, [isAdvancedSearchOpen]);

  function handleCloseSection() {
    setIsAdvancedSearchOpen(false);
  }

  function handleToggle() {
    onCloseCustomView();
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
  }

  function handleSearchToggle() {
    setIsSearchOpen(!isSearchOpen);
  }

  function getNotificationFailed() {
    const { defaultFetchDataFailed } = notifications || {};
    isFunction(defaultFetchDataFailed) && defaultFetchDataFailed();
  }

  function fetchData(params) {
    try {
      loadSavedSearches(params);
    } catch (error) {
      getNotificationFailed();
      throw error;
    }
  }

  function handleSavedPageChange(page) {
    fetchData({ page });
  }

  function handleClearSearch() {
    setSearchValue('');
    onChangeHandleState();
  }

  function handleClear() {
    handleClearSearch();

    resetValues();
    onClearAllHandleState();
    onClearAllSearchParams();
  }

  function handleClearAll() {
    handleClear();

    /** Reset table search on clear all */
    if (hasAppliedFilters(appliedFilters)) {
      onSearch({ id, searchType, clearAll: true, dataProps, searchData: emptyState });
    }
  }

  function handleCancel() {
    handleCloseSection();
  }

  function handleSaveItem(data = {}) {
    onSaveItem && onSaveItem(data, dataProps)
      .then(Notifications.defaultSavedItemSuccess)
      .catch((error) => {
        Notifications.defaultSavedItemFailed();
        throw error;
      });
  }

  function handleRemoveItem(data = {}) {
    onRemoveItem && onRemoveItem(data.id, dataProps)
      .then(Notifications.defaultRemoveSavedItemSuccess)
      .catch((error) => {
        Notifications.defaultRemoveSavedItemFailed();
        throw error;
      });
  }

  function handleOnClickItem(item) {
    const { id: searchId } = item;
    const { searchValue: inputValue, ...restProps } = item.options || {};

    fillOptions(restProps);
    setSearchValue(inputValue || emptyState.searchValue);
    onClickItemHandleState();
    setPreviousSearchSelectedId(searchId);
  }

  function handleSearchChange(event) {
    const { value } = event.target;

    setSearchValue(value);
    onChangeHandleState();
  }

  function handleAdvancedSearch(eventData) {
    const { newOptions } = eventData || {};
    const currentSort = sort || {};
    const data = merge({}, searchData, newOptions);
    const { options: searchDataOptions } = data;

    const payload = {
      ...currentSort,
      id,
      isClickedItem,
      dataProps,
      searchData: onSearchTransform ? onSearchTransform(data) : data,
    };

    onSearch({ ...payload, isAdvancedSearchOpen, isSearchClick: true })
      .then(() => saveInRecentSearch && saveInRecentSearch(payload))
      .catch((error) => {
        getNotificationFailed();
        throw error;
      });

    clearParams([
      ...(
        isEqual(
          removeUnusedOptions(searchDataOptions, ignoreOptions),
          removeUnusedOptions(emptyOptions, ignoreOptions),
        ) ? [SEARCH_ID_PARAM] : []),
    ]);

    handleCancel();
    onClickSearch();

    if (!isDirty) {
      handlePreviousSearchExecution(previousSearchSelectedId);
    }
  }

  function handleOnSubmitSearch() {
    isSearchOpen && handleSearchToggle();
    handleAdvancedSearch();
  }

  /**
   * Applicable only to simple data structure, no nested  object values, prevState not available
   * Add a different onChange func to use prevState (e.g onChangeDateRange for changing: date: { start, end }))
   * */
  function handleChangeFilter(key, value) {
    onChange(key, value);
    onChangeHandleState();

    const newOptions = { options: { [key]: value } };
    handleAdvancedSearch({ newOptions });
  }

  return {
    isDirty,
    isSearchOpen,
    isAdvancedSearchOpen,
    allowSubmit,
    searchData,
    searchValue,
    isClickedItem,
    onSearchToggle: handleSearchToggle,
    onToggleAdvancedSearch: handleToggle,
    onClearSearch: handleClearSearch,
    onChangeSearchValue: handleSearchChange,
    onChangeSavedPage: handleSavedPageChange,
    options,
    onSaveItem: handleSaveItem,
    onRemoveItem: handleRemoveItem,
    onClickItem: handleOnClickItem,
    onClearItem: handleClear,
    onClearAll: handleClearAll,
    onSearch: handleAdvancedSearch,
    onCancel: handleCancel,
    onSubmit: handleOnSubmitSearch,
    onSearchValueChange: setSearchValue,
    onChangeFilter: handleChangeFilter,
    getSearchParams,
    onClearAllSearchParams,
  };
}

export default useAdvancedSearch;
