import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isDemocrat, isRepublican } from 'utils/parties';

import styles from './styles.module.scss';

export default function PartyColorBar({ partyShort }) {
  return (
    <div
      className={classNames(styles.partyZone, {
        [styles.partyZoneDemocrat]: isDemocrat(partyShort),
        [styles.partyZoneRepublican]: isRepublican(partyShort),
      })}
    />
  );
}

PartyColorBar.propTypes = {
  partyShort: PropTypes.string,
};
