import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';
import Textarea from 'components/core/Textarea';
import Checkbox from 'components/core/Checkbox';
import RadioBox from 'components/core/RadioBox';
import LabelGroup from 'components/core/LabelGroup';
import Select from 'components/core/Select';
import {
  CHECKBOX_TYPE_SLUG,
  INPUT_TYPE_SLUG,
  NUMERIC_TYPE_SLUG,
  OPTION_PLACEHOLDER,
  RADIO_TYPE_SLUG,
  TEXTAREA_TYPE_SLUG,
  DATE_TYPE_SLUG,
  AUTOCOMPLETE_SLUG,
} from 'components/core/CustomFields/utils/constants';
import { optionShape } from 'components/core/CustomFields/utils/shapes';
import getStyle from 'components/core/TagInput/theme';

import PreviewDateInput from './PreviewDateInput';
import styles from '../styles.module.scss';

function PreviewInput({ slug, options }) {
  const formatAutocompleteOptions = () => options.map(
    ({ value }, index) => ({ value: index, label: value || OPTION_PLACEHOLDER })
  );

  const { [slug]: previewNode } = {
    [INPUT_TYPE_SLUG]: <Input type="text" />,
    [NUMERIC_TYPE_SLUG]: <Input type="number" />,
    [TEXTAREA_TYPE_SLUG]: <Textarea />,
    [DATE_TYPE_SLUG]: <PreviewDateInput />,
    [CHECKBOX_TYPE_SLUG]: (
      <LabelGroup containerClassName={styles.options}>
        {options.map(({ value }, index) => (
          <Checkbox
            // at this point we might not have a unique value to use as key
            // eslint-disable-next-line react/no-array-index-key
            key={`checkbox-option-${index}`}
            label={value || OPTION_PLACEHOLDER}
            theme="light"
            className={classNames(styles.previewOption, !value && styles.optionPlaceholder)}
          />
        ))}
      </LabelGroup>
    ),
    [RADIO_TYPE_SLUG]: (
      <LabelGroup containerClassName={styles.options}>
        {options.map(({ value }, index) => (
          <RadioBox
            // eslint-disable-next-line react/no-array-index-key
            key={`radio-option-${index}`}
            name="value"
            theme="light"
            label={value || OPTION_PLACEHOLDER}
            className={classNames(styles.previewOption, !value && styles.optionPlaceholder)}
          />
        ))}
      </LabelGroup>
    ),
    [AUTOCOMPLETE_SLUG]: <Select customTheme={getStyle()} options={formatAutocompleteOptions()} />,
  };

  return previewNode;
}

PreviewInput.propTypes = {
  slug: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionShape),
};

export default PreviewInput;
