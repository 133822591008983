// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes = 0, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['bt', 'kb', 'mb', 'gb', 'tb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
}

export function createFileId({ name, size }) {
  return `${name}_${size}`;
}

export function getProgress(progress, { name, size }) {
  const fileId = createFileId({ name, size });
  return progress[fileId];
}
