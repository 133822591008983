import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text } from '@react-pdf/renderer';

import { DEFAULT_TEXT_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  legislatorName: {
    color: DEFAULT_TEXT_COLOR,
    fontSize: 10,
    fontWeight: 300,
  },
});

function FooterName({ name }) {
  return (
    <Text style={styles.legislatorName}>{name}</Text>
  );
}

FooterName.propTypes = {
  name: PropTypes.string,
};

export default FooterName;
