import React from 'react';

import Link from 'components/core/Link';

import styles from '../styles.module.scss';

export default function CustomFieldsIcon() {
  const customFieldsUrl = '/custom_fields';

  return (
    <Link
      theme="light"
      href={customFieldsUrl}
      className={styles.customFieldsLink}
    >
      <img
        src="/images/cog.png"
        className={styles.customFieldsIcon}
        alt="Custom Fields"
      />
    </Link>
  );
}
