import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { DEFAULT_TEXT_COLOR, DEFAULT_LINE_COLOR } from '../../utils/colors.scss';

export const styles = StyleSheet.create({
  titleSection: {
    borderBottomWidth: 1,
    borderBottomColor: DEFAULT_LINE_COLOR,
    marginBottom: 15,
  },
  titleText: {
    fontWeight: 700,
    fontSize: 14,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function SectionTitle({ title, renderCustomTitle, fixed }) {
  if (isFunction(renderCustomTitle)) {
    return (
      <View style={styles.titleSection} fixed={fixed}>
        {renderCustomTitle()}
      </View>
    );
  }

  if (isEmpty(title)) {
    return null;
  }

  return (
    <View style={styles.titleSection} fixed={fixed}>
      <Text style={styles.titleText}>{title}</Text>
    </View>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  renderCustomTitle: PropTypes.func,
  fixed: PropTypes.bool,
};

export default SectionTitle;
