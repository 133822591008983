import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { ClearableEmptyPlaceholder } from 'components/core/Profile/components/Placeholders';
import TrimTextItem from 'components/core/Table/components/ColumnItem/TrimTextItem';
import { DateItem } from 'components/core/Table/components/ColumnItem';
import Table, { Column } from 'components/core/Table';
import Paginator from 'components/core/Paginator';

import { DEFAULT_PAGE_SIZE } from '../../utils/constants';
import BillColumn from './components/BillColumn';
import CommitteesColumn from './components/CommitteesColumn';
import VoteNumberColumn from './components/VoteNumberColumn';
import useTable from './utils/useTable';
import tableColumns from './utils/tableColumns';
import styles from './styles.module.scss';

function TableView({
  sort,
  allItems,
  isLoading,
  isMobile,
  paginator,
  onSearch,
  trackingActions,
  areFiltersApplied,
  emptyDescription,
  onClearAll,
  formatBillUrl,
  customTableColumns,
}) {
  const columns = customTableColumns || tableColumns;
  const {
    isPaginatorPresent,
    sortData,
    onSortColumn,
    onChangePage,
    onClear,
  } = useTable({ onSearch, trackingActions, sort, paginator, onClearAll, columns });

  if (!isLoading && isEmpty(allItems)) {
    return (
      <ClearableEmptyPlaceholder
        areFiltersApplied={areFiltersApplied}
        emptyDescription={emptyDescription}
        onClear={onClear}
      />
    );
  }

  function renderColumn(column) {
    function getColumn(value) {
      return <div className={styles.tableColumn}>{value}</div>;
    }

    function getWrapColumn(value) {
      return (
        <div className={styles.title}>
          <TrimTextItem textToTrim={value} maxTextLength={80} />
        </div>
      );
    }

    const { keyName } = column;
    const columnProps = { key: keyName, keyName };
    const columnComponents = {
      billName: (
        <BillColumn
          trackingActions={trackingActions}
          formatBillUrl={formatBillUrl}
          {...columnProps}
        />
      ),
      committees: <CommitteesColumn {...columnProps} />,
      voteNumber: <VoteNumberColumn trackingActions={trackingActions} {...columnProps} />,
      title: <Column {...columnProps}>{getWrapColumn}</Column>,
      voteQuestion: <Column {...columnProps}>{getWrapColumn}</Column>,
      voteDate: <DateItem {...columnProps} id="voteDate" />,
    };

    return columnComponents[keyName] || <Column key={keyName} keyName={keyName}>{getColumn}</Column>;
  }

  function renderPagination() {
    if (!isPaginatorPresent) {
      return null;
    }

    const { current, total, totalCount, pageSize } = paginator;

    return (
      <Paginator
        isMobile={isMobile}
        isLoading={isLoading}
        current={current}
        total={total}
        pageSize={pageSize}
        totalItems={totalCount}
        onChange={onChangePage}
      />
    );
  }

  const shouldApplyFixedHeight = paginator
    && paginator.current > 1
    && paginator.current === paginator.total;
  const tableClassName = classNames(
    styles.tableContent,
    shouldApplyFixedHeight && styles.fixedHeight,
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isLoading}
        rowLengthPlaceholder={DEFAULT_PAGE_SIZE}
        uniqueKey="id"
        columns={columns}
        containerStyle={tableClassName}
        data={allItems}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={onSortColumn}
        emptyDescription={emptyDescription}
        isPaginatorPresent={isPaginatorPresent}
        isMobile={isMobile}
        isFixed
        isAlignedToTop
      >
        {columns.map(renderColumn)}
      </Table>

      {renderPagination()}
    </div>
  );
}

TableView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.object.isRequired,
  allItems: PropTypes.array.isRequired,
  onClearAll: PropTypes.func.isRequired,
  formatBillUrl: PropTypes.func.isRequired,
  paginator: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  emptyDescription: PropTypes.string.isRequired,
  areFiltersApplied: PropTypes.bool.isRequired,
  customTableColumns: PropTypes.array,
  trackingActions: PropTypes.shape({
    trackVotingRecordSorted: PropTypes.func,
    trackVotingRecordPageVisited: PropTypes.func,
    trackVotingRecordIssueVisited: PropTypes.func,
    trackVotingRecordVoteVisited: PropTypes.func,
  }),
};

export default TableView;
