import { map, isEmpty } from 'lodash';

import { joinCompact } from 'utils/string';

function buildLabel(items) {
  return !isEmpty(items) && joinCompact(map(items, 'label'));
}

export function getFilterLabels(filters) {
  const transformedFilters = map(filters, ((filter) => {
    const { label, value } = filter;

    if (isEmpty(value)) {
      return null;
    }

    const formattedValue = typeof value !== 'string' ? buildLabel(value) : value;

    return `${label}: ${formattedValue}`;
  }));

  return joinCompact(transformedFilters, '; ');
}
