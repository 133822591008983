import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Card from 'components/core/Card';
import Badge from 'components/core/Badge';
import { LegislatorName } from 'components/Legislator';

import Items from './components/Items';
import styles from './styles.module.scss';

function LegislatorBadgeField({
  isSponsor,
  isOriginalCosponsor,
  isCosponsor,
  position,
  items,
  ...restProps
}) {
  return (
    <div className={styles.container}>
      <Card className={classNames(styles.cardContainer, !isEmpty(items) && styles.cardItems)}>
        <div className={styles.flexSection}>
          <LegislatorName {...restProps} className={styles.legislator} />
        </div>
        <div className={styles.flexSection}>
          {isSponsor && <Badge className={styles.sponsorBadge}>Sponsor</Badge>}
          {isOriginalCosponsor && <Badge className={styles.defaultBadge}>Original cosponsor</Badge>}
          {isCosponsor && <Badge className={styles.defaultBadge}>Cosponsor</Badge>}
          {position && <Badge className={styles.defaultBadge}>{position}</Badge>}
        </div>
      </Card>

      <Items items={items} />
    </div>
  );
}

LegislatorBadgeField.propTypes = {
  isSponsor: PropTypes.bool,
  isOriginalCosponsor: PropTypes.bool,
  isCosponsor: PropTypes.bool,
  position: PropTypes.string,
  items: PropTypes.array,
};

export default LegislatorBadgeField;
