import React from 'react';

export const LookupContext = React.createContext();

function withLookup(Component) {
  return function PageLookupComponent(props) {
    return (
      <LookupContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </LookupContext.Consumer>
    );
  };
}

export default withLookup;
