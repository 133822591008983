import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { isBottom } from 'utils/scroll';

import { DEFAULT_DEBOUNCE_TIME } from '../../constants';

function useInfiniteLoad({ ref, onLoad, isLoading, defaultShouldLoad = false, capture = false }) {
  const [shouldLoad, setShouldLoad] = useState(defaultShouldLoad);

  function handleLoad() {
    return !isLoading && shouldLoad && onLoad();
  }

  function handleShouldLoad() {
    setShouldLoad(isBottom(ref));
  }

  const debouncedLoad = debounce(handleLoad, DEFAULT_DEBOUNCE_TIME);

  function addScrollEvent() {
    document.addEventListener('scroll', handleShouldLoad, capture);
  }

  function removeScrollEvent() {
    document.removeEventListener('scroll', handleShouldLoad, capture);
  }

  function onInit() {
    addScrollEvent();

    return () => removeScrollEvent();
  }

  useEffect(onInit, []);
  useEffect(debouncedLoad, [shouldLoad]);
}

export default useInfiniteLoad;
