import React from 'react';

export const StakeholderContext = React.createContext();

function withStakeholders(Component) {
  return function StakeholderDataComponent(props) {
    return (
      <StakeholderContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </StakeholderContext.Consumer>
    );
  };
}

export default withStakeholders;
