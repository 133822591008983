import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function SectionTitle({ title }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <hr className={styles.divider} />
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
