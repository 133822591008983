export default function handleDroppedStatementGroup({ result, data, updateData }) {
  updateData({
    payload: {
      ...data,
      statement: {
        ...data.statement,
        statements: [
          ...data.statement.statements,
          {
            id: `statementgroup-${new Date().getTime()}`,
            type: 'statements_group',
            operator: {
              label: 'And',
              value: 'and',
            },
            statements: [
              {
                id: `statement-${new Date().getTime()}`,
                type: 'statement',
                name: result.draggableId,
                options: {},
              },
            ],
          },
        ],
      },
    },
  });
}
