import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';
import Button from 'components/core/Button';
import { ConfirmationModal as Modal } from 'components/core/Modal';

import styles from './styles.module.scss';

function ViewBiography({ biography }) {
  const { isOpenModal, toggleModal, openModal } = useModal();

  if (isEmpty(biography)) {
    return null;
  }

  return (
    <span className={styles.viewBiography}>
      (
      <Button
        theme="tableLink"
        onClick={openModal}
        className={styles.modalTrigger}
      >
        view biography
      </Button>
      )

      <Modal
        theme="scrollable"
        title="Biography"
        isOpen={isOpenModal}
        toggle={toggleModal}
        messageContinue="Close"
        bodyClassName={styles.modalBody}
        className={styles.modal}
        hideCancel
      >
        {biography}
      </Modal>
    </span>
  );
}

ViewBiography.propTypes = {
  biography: PropTypes.node,
};

export default ViewBiography;
