import PropTypes from 'prop-types';

export const mentionDataShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
});

export const mentionShape = PropTypes.shape({
  trigger: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(mentionDataShape),
  strategySlug: PropTypes.string,
});
