import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/core/ErrorMessage';
import DatePicker from 'components/core/DatePicker';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

import customFieldShape from '../../shapes/customField';
import controlStateShape from '../../shapes/controlState';
import normalizeEventTarget from '../../utils/normalizeEventTarget';
import formatStringToDate from '../../utils/formatStringToDate';
import Tooltip from '../../components/Tooltip';

export default function DateControl({ field, state, onChange, tooltipTheme, ...restProps }) {
  const { description, token, name, required } = field;
  const { value, error } = state;
  const formatedValue = formatStringToDate(value[0]);

  function handleChange(date) {
    const stringDate = date && moment(date).format(GLOBAL_DATE_FORMAT);
    const normalizedEvent = normalizeEventTarget({}, { id: token, value: [stringDate] });

    onChange(normalizedEvent);
  }

  return (
    <>
      <Label htmlFor={token}>
        {name} <Asterisk show={required} />
        <Tooltip content={description} theme={tooltipTheme} />
      </Label>
      <DatePicker
        {...restProps}
        selected={formatedValue}
        onChange={handleChange}
      />
      <ErrorMessage message={error} />
    </>
  );
}

DateControl.propTypes = {
  field: customFieldShape.isRequired,
  state: controlStateShape,
  onChange: PropTypes.func.isRequired,
  tooltipTheme: PropTypes.string,
};
