import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Item({ label, isChecked }) {
  const starIconClassName = isChecked ? classNames('fas fa-star', styles.saved) : 'far fa-star';

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <i className={starIconClassName} />
      </div>
      <div className={styles.label}>
        {label}
      </div>
    </div>
  );
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

export default Item;
