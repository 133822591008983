import { useState, useEffect } from 'react';
import isFunction from 'lodash/isFunction';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

function useButtonSlider({
  baseParams,
  onSetOption,
  initialOption,
  isLoadingOption,
  handleUpdateOption,
  options,
  shouldFetchOnInit,
  fetchData,
  isOpen,
}) {
  const { isMobile } = useMediaQuery();

  const [isListOpen, setIsListOpen] = useState(isOpen);
  const [option, setOption] = useState(initialOption);
  const [isLoading, setIsLoading] = useState(isLoadingOption);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    setIsLoading(isLoadingOption);
  }, [isLoadingOption]);

  const optionsList = Object.keys(options);
  const otherOptions = optionsList.filter(p => p !== option);
  const showDropdown = !isMobile && isListOpen;
  const showModal = isMobile && isListOpen;

  const toggleOpen = () => setIsListOpen(!isListOpen);

  const changeOption = (currentOption) => {
    !isInitialLoading && isFunction(onSetOption) && onSetOption(currentOption);
    setOption(currentOption);
    setIsLoading(false);
  };

  const rollbackOption = () => {
    setOption(option);
    setIsLoading(false);
  };

  const handleClick = (currentOption) => {
    if (currentOption !== option) {
      handleUpdateOption({
        currentOption,
        baseParams,
        setIsLoading,
        changeOption,
        rollbackOption,
      }).finally(toggleOpen);
    } else {
      toggleOpen();
    }
  };

  function handleFetchOnInit() {
    return fetchData()
      .then(response => changeOption(response))
      .catch(rollbackOption);
  }

  useEffect(() => {
    shouldFetchOnInit
      ? handleFetchOnInit()
      : (initialOption && setOption(initialOption));

    setIsInitialLoading(false);
  }, [initialOption]);

  return {
    option,
    options,
    onOptionClick: handleClick,
    otherOptions,
    showDropdown,
    showModal,
    toggleOpen,
    optionsList,
    isLoading,
  };
}

export default useButtonSlider;
