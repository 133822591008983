import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';
import ViewMoreButton from 'components/core/ViewMoreButton';

import TimeGroup from '../TimeGroup';
import useAsyncTimeline from './useAsyncTimeline';
import styles from '../../styles.module.scss';

function AsyncTimeline({
  title,
  description,
  emptyDescription,
  className,
  items,
  paginator,
  fetchTimeline,
  isLoading,
  isInitialLoading,
  isUpperCaseItemTitle,
  renderCustomCardContent,
  disableFirstCardStyle,
  hideLastAction,
  renderCustomBoxText,
  renderCustomTitle,
  limit,
}) {
  const commonProps = { title, description, emptyDescription };
  const {
    timelineContainerRef,
    isOpenMore,
    visibleItems,
    onClickViewMore,
    isHiddenEvents,
    showPlaceholder,
  } = useAsyncTimeline({ paginator, items, isLoading, fetchTimeline, limit });

  if (isInitialLoading) {
    return <LoadingPlaceholder {...commonProps} />;
  }

  function renderTimeGroup(item, index) {
    const { date } = item;

    return (
      <TimeGroup
        key={`card-action-${date}-${index}`}
        timeGroupItem={item}
        isUpperCaseItemTitle={isUpperCaseItemTitle}
        renderCustomCardContent={renderCustomCardContent}
        renderCustomBoxText={renderCustomBoxText}
        renderCustomTitle={renderCustomTitle}
        disableFirstCardStyle={disableFirstCardStyle}
        hideLastAction={hideLastAction}
        isFirstCard={index === 0}
      />
    );
  }

  function renderLoadingIcon() {
    if (isLoading) {
      return (
        <Fragment>
          Loading
          <i className={classNames('fas fa-circle-notch fa-spin', styles.loadingIcon)} />
        </Fragment>
      );
    }

    return null;
  }

  return (
    <div ref={timelineContainerRef}>
      <DataCard
        {...commonProps}
        showPlaceholder={showPlaceholder}
        className={classNames(styles.container, className)}
      >
        <div className={styles.itemsContainer}>
          {visibleItems.map(renderTimeGroup)}
        </div>

        <ViewMoreButton
          theme="grey"
          openText="View more"
          closeText="Collapse"
          className={styles.viewMoreButton}
          buttonClassName={styles.button}
          isVisible={isHiddenEvents}
          isOpen={isOpenMore}
          onClick={onClickViewMore}
          disabled={isLoading}
          customContent={renderLoadingIcon()}
        />
      </DataCard>
    </div>
  );
}

AsyncTimeline.defaultProps = {
  isDesktop: true,
};

AsyncTimeline.propTypes = {
  isInitialLoading: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isUpperCaseItemTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  emptyDescription: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  paginator: PropTypes.object,
  renderCustomCardContent: PropTypes.func,
  renderCustomTitle: PropTypes.func,
  fetchTimeline: PropTypes.func.isRequired,
  disableFirstCardStyle: PropTypes.bool,
  hideLastAction: PropTypes.bool,
  renderCustomBoxText: PropTypes.func,
  limit: PropTypes.number,
};

export default AsyncTimeline;
