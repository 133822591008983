import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { QuestionMark } from 'components/core/Icons';
import { disclaimerPropsShape } from 'components/core/SearchBox/shapes';

import useModal from '../Modal/useModal';
import { Disclaimer } from '../Modal';
import Button from '../Button';
import Input from '../Input';
import styles from './styles.module.scss';

const buttonThemes = {
  inside: styles.inside,
};

export default function SearchBox({
  className,
  inputClassName,
  withButton,
  buttonTheme,
  buttonLabel,
  withIcon,
  iconClass,
  onSubmit,
  disclaimerProps,
  withSearchDisclaimer,
  isClearable,
  value,
  onClearSearch,
  ...restProps
}) {
  const { isOpenModal, toggleModal } = useModal();
  const modalProps = { ...disclaimerProps, isOpenModal, toggleModal, modalClassName: styles.disclaimerModal };
  const withDisclaimer = withSearchDisclaimer && !isEmpty(disclaimerProps);
  const withClear = isClearable && value;

  const INSIDE = 'inside';
  const withInsideTheme = buttonTheme === INSIDE;

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(event);
  }

  return (
    <form onSubmit={handleSubmit} className={classNames(styles.searchContainer, className)}>
      {withIcon && (
        <i className={classNames(styles.searchIcon, iconClass)} />
      )}

      <Input
        {...restProps}
        value={value}
        type="text"
        spellCheck={false}
        className={classNames(
          styles.input,
          withIcon && styles.withIcon,
          withInsideTheme && styles.withInsideTheme,
          withDisclaimer && styles.withDisclaimer,
          withButton && styles.inputWithButton,
          isClearable && styles.isClearable,
          inputClassName,
        )}
      />
      {withClear && (
        <Button
          theme="light"
          size="none"
          customIcon={<i className="fas fa-times-circle" />}
          className={classNames(styles.clearBtn, withDisclaimer && styles.withDisclaimer)}
          onClick={onClearSearch}
        />
      )}
      {withDisclaimer && (
        <>
          <Button
            theme="light"
            size="none"
            customIcon={<QuestionMark className={styles.icon} />}
            className={styles.disclaimer}
            iconClassName={styles.icon}
            onClick={toggleModal}
          />
          <Disclaimer {...modalProps} />
        </>
      )}

      {withButton && (
        <Button
          onClick={onSubmit}
          className={classNames(
            styles.button,
            buttonThemes[buttonTheme],
          )}
        >
          <i className="fal fa-search" />
          {buttonLabel}
        </Button>
      )}
    </form>
  );
}

SearchBox.defaultProps = {
  withIcon: false,
  withButton: false,
  withSearchDisclaimer: false,
  iconClass: 'fal fa-search',
  buttonLabel: 'Search',
  disclaimerProps: {},
  isClearable: false,
};

SearchBox.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  withButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonTheme: PropTypes.string,
  withIcon: PropTypes.bool,
  iconClass: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  disclaimerProps: disclaimerPropsShape,
  withSearchDisclaimer: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClearSearch: PropTypes.func,
};
