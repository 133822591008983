import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isArray, map, get } from 'lodash';

import Badge from 'components/core/Badge';

import styles from './styles.module.scss';

export default function CustomBadge({ content }) {
  if (isEmpty(content)) {
    return null;
  }

  if (isArray(content)) {
    return map(content, (badgeContent, index) => (
      <Badge className={styles.customBadge} key={get(badgeContent, 'key', index)}>
        {badgeContent}
      </Badge>
    ));
  }

  return (
    <Badge className={styles.customBadge}>
      {content}
    </Badge>
  );
}

CustomBadge.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
