import React from 'react';
import classNames from 'classnames';

import RichOption from 'components/core/Select/components/RichOption';
import fetcher from 'utils/fetcher';

import { mentionShape, mentionDataShape } from './shapes.js';
import styles from '../styles/styles.module.scss';

export const createFetchFunction = strategySlug => (query, callback) => {
  if (!query) return;

  const transformQueryResult = result => result.map(
    ({
      label,
      value,
      type,
      ...rest
    }) => ({
      id: `${type}:${value}`,
      display: label,
      type,
      ...rest,
    })
  );
  fetcher({ strategySlug, inputText: query })
    .then(transformQueryResult)
    .then(callback);
};

// react-mention compose the mention just by display and id, so in order to have
// the whole data, the id we create for react-mention is recordType:recordId
export const formatMention = ({ id, display }) => ({
  display,
  type: id.split(':')[0],
  id: id.split(':')[1],
});

export const createOnAddFunction = (prop, setFieldValue, data) => (id, label) => {
  const [type, value] = id.split(':');

  setFieldValue(prop, [...data, { label, value, type }]);
};

export const renderSuggestion = (
  suggestion,
  search,
  highlightedDisplay,
  index,
  focused
) => {
  const { display, id, ...rest } = suggestion;

  return (
    <div key={id} className={classNames(styles.suggestion, focused && styles.suggestionFocused)}>
      <RichOption
        label={display}
        inputValue={search}
        {...rest}
      />
    </div>
  );
};

// React-Mentions returns an empty event and we need to add a name for Formik
export const createFormikHandleFunction = (onFunction, name) => event => onFunction({
  ...event,
  target: {
    ...event.target,
    name,
  },
});

export const buildMention = ({ trigger, strategySlug, data, ...restProps }) => ({
  markup: `${trigger}[__display__](__id__)`,
  trigger,
  data: data || createFetchFunction(strategySlug),
  renderSuggestion,
  displayTransform: (id, label) => `${trigger}${label}`,
  appendSpaceOnAdd: true,
  ...restProps,
});

export const buildOnChangeEventParams = ({ event, newValue, newPlainTextValue, allMentions }) => ({
  ...event,
  target: {
    ...event.target,
    value: newValue, // it fixes a react-mentions bug https://github.com/signavio/react-mentions/issues/450#issue-752391781
    newValue,
    newPlainTextValue,
    allMentions,
  },
});

export { mentionShape, mentionDataShape };
