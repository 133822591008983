import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getAwardContent } from './utils';

function Awards({ person, isLoading, hideOnEmptyData }) {
  const { awards = [] } = person || {};

  const sections = [{
    id: 'awardsSection',
    title: 'Awards',
    tables: [{
      id: 'awardsTable',
      label: 'Award',
      placeholderSize: 2,
      columns: [{
        id: 'award',
        getContent: getAwardContent,
      }],
      data: awards,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(awards)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

Awards.defaultProps = {
  hideOnEmptyData: true,
};

Awards.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(Awards);
