import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Sentiment({ type, percentage, className, showType }) {
  const sentimenClassNames = classNames(
    styles.sentiment,
    styles[type],
    className,
  );

  return (
    <div className={sentimenClassNames}>
      {showType && (
        <div className={styles.type}>{type}</div>
      )}
      {percentage}
    </div>
  );
}

Sentiment.propTypes = {
  type: PropTypes.oneOf([
    'positive',
    'negative',
    'neutral',
  ]),
  percentage: PropTypes.string,
  showType: PropTypes.bool,
  className: PropTypes.string,
};

export default Sentiment;
