import cloneDeep from 'lodash/cloneDeep';

import { findNode } from './common';

export default function handleDroppedFilterJoin({ parentId, result, data, updateData }) {
  const draggedFilter = {
    id: `filter-${new Date().getTime()}`,
    type: 'filter',
    dimension: result.draggableId,
  };

  const oldFilterGroup = findNode(data, parentId);
  const newFilterGroup = cloneDeep(oldFilterGroup);
  newFilterGroup.filters.splice(newFilterGroup.filters.length, 0, draggedFilter);

  updateData({
    payload: JSON.parse(
      JSON.stringify(data).replace(
        JSON.stringify(oldFilterGroup),
        JSON.stringify(newFilterGroup),
      ),
    ),
  });
}
