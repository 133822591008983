import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Placeholder from './components/Placeholder';
import ErrorPdf from './components/ErrorPdf';
import Iframe from './components/Iframe';
import styles from './styles.module.scss';

export default function PDF({
  link,
  isLoading,
  hasPdf,
  sourceType,
  containerClass,
  iframeClass,
  originalSourceUrl,
}) {
  const renderWithContainer = node => (
    <div className={classNames(styles.container, containerClass)}>{node}</div>
  );

  if (isLoading) return renderWithContainer(<Placeholder />);
  if (!hasPdf) return renderWithContainer(<ErrorPdf originalSourceUrl={originalSourceUrl} />);

  return renderWithContainer(
    <Iframe
      link={link}
      sourceType={sourceType}
      iframeClass={iframeClass}
      originalSourceUrl={originalSourceUrl}
    />
  );
}

PDF.propTypes = {
  link: PropTypes.string,
  isLoading: PropTypes.bool,
  hasPdf: PropTypes.bool,
  containerClass: PropTypes.string,
  iframeClass: PropTypes.string,
  originalSourceUrl: PropTypes.string,
};
