import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TrimTextItem from 'components/core/Table/components/ColumnItem/TrimTextItem';
import { Column } from 'components/core/Table';
import Link from 'components/core/Link';
import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles.module.scss';

function BillTitleItem({ keyName, item, trackingActions }) {
  const { id, billId, billTitle, billUrl } = item || {};
  const { trackBillVisited } = trackingActions;
  const handleClick = () => trackBillVisited && trackBillVisited(billId);

  function renderBill() {
    if (isEmpty(billId)) {
      return EMPTY_VALUE;
    }

    if (isEmpty(id) || isEmpty(billUrl)) {
      return <div>{billId}</div>;
    }

    const billLink = (
      <Link
        className={styles.billColumnLink}
        theme="default"
        href={billUrl}
        onClick={handleClick}
      >
        {billId}
      </Link>
    );

    if (isEmpty(billTitle)) {
      return billLink;
    }

    return (
      <div className={styles.billColumn}>
        {billLink}
        <TrimTextItem textToTrim={billTitle} maxTextLength={80} />
      </div>
    );
  }

  return (
    <Column key={keyName} keyName={keyName} className={styles.container}>{renderBill}</Column>
  );
}

BillTitleItem.defaultProps = {
  item: {},
  trackingActions: {},
};

BillTitleItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  trackingActions: PropTypes.object,
  item: PropTypes.object,
};

export default BillTitleItem;
