import { useEffect } from 'react';

export function useDismissibleAlert({ ui, type, fetchAlert, dismissAlert, resetData }) {
  const { isLoadingAlert, isDismissingAlert } = ui;

  function handleClose() {
    dismissAlert(type);
  }

  useEffect(() => {
    fetchAlert(type);

    return () => resetData();
  }, []);

  return {
    isDisabled: isLoadingAlert || isDismissingAlert,
    handleClose,
  };
}
