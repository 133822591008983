import { useState, useEffect } from 'react';

function useTabs({
  tabs,
  onTabChange,
  defaultActiveTabId,
}) {
  const getTabById = (tabId, defaultTab = tabs[0]) => (
    tabs.find(tab => tab.props.id === tabId) || defaultTab
  );

  const getInitialActiveTab = () => getTabById(defaultActiveTabId);

  const [activeTab, setActiveTab] = useState(getInitialActiveTab);

  useEffect(() => {
    const newActiveTab = getTabById(defaultActiveTabId, activeTab);

    setActiveTab(newActiveTab);
  }, [defaultActiveTabId]);

  function handleOnTabClick(tab) {
    setActiveTab(tab);
    onTabChange && onTabChange(tab.props);
  }

  return {
    activeTab,
    onTabClick: handleOnTabClick,
  };
}

export default useTabs;
