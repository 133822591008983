import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';

import useDocumentSummary from 'components/core/ExportDocument/components/ExportModal/utils/useDocumentSummary';

import MultiStepModal from '../MultiStep';
import ItemsSelection from './components/ItemsSelection';
import ColumnsSelection from './components/ColumnsSelection';
import Settings from './components/Settings';
import Buttons from './components/Buttons';
import useButtons from './useButtons';
import useSettings from './useSettings';
import useItemsSelected from './useItemsSelected';
import useColumns from './useColumns';
import useSteps from './useSteps';
import shapes from './shapes';

import styles from './styles.module.scss';

function ExportModal({
  isLoading,
  initialValues,
  initialOptions,
  documentTitle,
  exportFileItems,
  defaultFileFormatValue,
  getCustomizationData,
  toggleModal,
  onExport,
}) {
  const { itemsSelected, itemsSelectedManager } = useItemsSelected();

  const { selectedColumns, columnsManager } = useColumns({ itemsSelected });
  const { documentSummary, onChangeSummary, onResetSummary } = useDocumentSummary();
  const { settings, settingsManager } = useSettings({
    defaultFileFormatValue,
    initialOptions,
    initialValues,
  });

  const documentSummaryProps = {
    reportHasSummary: settings.showSummary,
    onChange: onChangeSummary,
    onReset: onResetSummary,
    value: documentSummary,
  };

  const response = {
    selectedColumns,
    documentSummary,
    settings,
  };

  const {
    isValid,
    isFirstStep,
    isLastStep,
    previousStep,
    nextStep,
    currentStep,
    currentItem,
  } = useSteps({ settings, itemsSelected, selectedColumns });

  if (!gon.isFederalProductEnabled && settings.options) {
    settings.options[0].items = settings.options[0].items.filter(item => !item.value.includes('federal'));
  }
  if (!gon.isStateProductEnabled && settings.options) {
    settings.options[0].items = settings.options[0].items.filter(item => !item.value.includes('state'));
  }

  const buttonsProps = useButtons({
    isValid,
    isLoading,
    isFirstStep,
    isLastStep,
    previousStep,
    nextStep,
    onCancel: toggleModal,
    onSubmit: () => onExport(response),
  });

  const buttons = () => (
    <Buttons {...buttonsProps} />
  );

  const steps = filter([
    {
      index: 0,
      component: (
        <Settings
          options={settings.options}
          settings={settings}
          documentTitle={documentTitle}
          documentSummaryProps={documentSummaryProps}
          settingsManager={settingsManager}
          exportFileItems={exportFileItems}
          getCustomizationData={getCustomizationData}
        />
      ),
    },
    {
      index: 1,
      component: (
        <ItemsSelection
          options={settings.options}
          itemsSelectedManager={itemsSelectedManager}
        />
      ),
    },
    currentItem && ({
      index: 2,
      component: <ColumnsSelection item={currentItem} columnsManager={columnsManager} />,
    }),
  ]);

  return (
    <MultiStepModal
      isOpen
      title="Export options"
      toggleOpen={toggleModal}
      steps={steps}
      currentStep={currentStep}
      onClose={toggleModal}
      isLoading={isLoading}
      isSaving={false}
      CustomButtons={buttons}
      className={styles.modal}
      theme="scrollable"
      shouldResetStepOnClose
      showLoadingPlaceholder={false}
    />
  );
}

ExportModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialOptions: PropTypes.oneOfType([
    PropTypes.func,
    shapes.options,
  ]),
  documentTitle: PropTypes.string,
  exportFileItems: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.shape({ title: PropTypes.string }),
  defaultFileFormatValue: PropTypes.string,
  getCustomizationData: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default ExportModal;
