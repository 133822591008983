import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import size from 'lodash/size';

import { LabelItemList, LabelLink } from 'components/core/ExportDocument';

import BillCard from '../../../BillCard';
import BillFooter from '../../../BillFooter';
import { getDescriptionItem, renderLegislator, renderSnippet } from '../../../utils';
import styles from '../../../utils/styles';
import BillHeader from '../Header';

function Bill({
  bill,
  displayBillTitleSection,
  displaySnippetSection,
  displayLastActionDateSection,
  displayLastActionSection,
  displayDescriptionSection,
  displaySummarySection,
  displaySponsorsSection,
  displayCosponsorsSection,
  ...restDisplayProps
}) {
  const {
    billId,
    billTitle,
    lastAction,
    lastActionDate,
    sponsors,
    cosponsors,
    billDescription,
    allSponsorsSize,
    allCosponsorsSize,
    govPredictLink,
    billSummary,
    billSummaryLastUpdated,
    snippet,
  } = bill || {};
  const {
    displayBillStateSection,
    displayBillStatusSection,
    displayIntroducedSection,
    displayVoteSection,
    displayBillTextSection,
    displayGovpredictLinkSection,
    displayTagsSection,
  } = restDisplayProps || {};

  const displayHeader = displayBillStateSection || displayIntroducedSection
    || displayBillStatusSection || displayVoteSection;

  const displayFooter = displayBillTextSection || displayGovpredictLinkSection || displayTagsSection;

  const headerContent = displayHeader ? <BillHeader bill={bill} {...restDisplayProps} /> : null;
  const footerContent = displayFooter ? <BillFooter bill={bill} {...restDisplayProps} billLinkLabel="Bill website" /> : null;

  const displayViewAllSponsors = size(sponsors) < allSponsorsSize;
  const displayViewAllCosponsors = size(cosponsors) < allCosponsorsSize;

  const summaryLabelPrefix = 'Bill summary';
  const summaryLabel = billSummaryLastUpdated
    ? `${summaryLabelPrefix} - Last updated ${billSummaryLastUpdated}`
    : summaryLabelPrefix;

  return (
    <BillCard
      {...restDisplayProps}
      headerContent={headerContent}
      footerContent={footerContent}
      bill={bill}
    >
      <View style={styles.billRowSection}>
        {getDescriptionItem(billId, 'Bill number', govPredictLink)}
      </View>

      {displayBillTitleSection && (
        <View style={styles.billRowSection}>
          {getDescriptionItem(billTitle, 'Bill title', govPredictLink, 500)}
        </View>
      )}
      {displaySnippetSection && (
        <View style={styles.billRowSection}>
          {renderSnippet(snippet)}
        </View>
      )}

      <View style={styles.billRowSection}>
        {displayLastActionDateSection && (
          <View style={styles.billLastActionDateSection}>
            {getDescriptionItem(lastActionDate, 'Last action date')}
          </View>
        )}

        {displayLastActionSection && (
          <View style={styles.billLastActionSection}>
            {getDescriptionItem(lastAction, 'Last action', govPredictLink, 500)}
          </View>
        )}
      </View>

      {displayDescriptionSection && (
        <View style={styles.billRowSection}>
          {getDescriptionItem(billDescription, 'Bill description', govPredictLink)}
        </View>
      )}

      {displaySummarySection && (
        <View style={styles.billRowSection}>
          {getDescriptionItem(billSummary, summaryLabel, govPredictLink, 1500)}
        </View>
      )}

      {displaySponsorsSection && (
        <View style={styles.billRowSection}>
          <LabelItemList
            listStyle={styles.billDescriptionText}
            labelStyle={styles.billLabelText}
            label="Sponsors"
            hideComma
          >
            {sponsors.map(renderLegislator)}
            {displayViewAllSponsors ? (
              <LabelLink
                value={`View all ${allSponsorsSize} on bill page`}
                href={govPredictLink}
                linkStyle={styles.billDescriptionLink}
              />
            ) : null}
          </LabelItemList>
        </View>
      )}

      {displayCosponsorsSection && (
        <View style={styles.billRowSection}>
          <LabelItemList
            listStyle={styles.billDescriptionText}
            labelStyle={styles.billLabelText}
            label="Cosponsors"
            hideComma
          >
            {cosponsors.map(renderLegislator)}
            {displayViewAllCosponsors ? (
              <LabelLink
                value={`View all ${allCosponsorsSize} on bill page`}
                href={govPredictLink}
                linkStyle={styles.billDescriptionLink}
              />
            ) : null}
          </LabelItemList>
        </View>
      )}
    </BillCard>
  );
}

Bill.propTypes = {
  bill: PropTypes.object.isRequired,
  displayBillStateSection: PropTypes.bool,
  displayBillStatusSection: PropTypes.bool,
  displayBillTitleSection: PropTypes.bool,
  displayDescriptionSection: PropTypes.bool,
  displaySummarySection: PropTypes.bool,
  displayIntroducedSection: PropTypes.bool,
  displayLastActionSection: PropTypes.bool,
  displayLastActionDateSection: PropTypes.bool,
  displaySponsorsSection: PropTypes.bool,
  displayCosponsorsSection: PropTypes.bool,
  displayBillTextSection: PropTypes.bool,
  displayTagsSection: PropTypes.bool,
  displayGovpredictLinkSection: PropTypes.bool,
  displayVoteSection: PropTypes.bool,
  displaySnippetSection: PropTypes.bool,
};

export default Bill;
