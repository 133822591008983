import { useEffect, useState } from 'react';
import { isEmpty, isNumber } from 'lodash';

import useCloseConfirmModal from 'components/core/Modal/components/CloseConfirmation/useCloseConfirmModal';

function useMultiStepModal({
  initialStep = 0,
  steps,
  toggleOpen,
  withCloseConfirmation,
  onReset,
  onClose,
  currentStep,
  resetStep,
  onChangeCurrentStep,
  title,
  isOpen,
  shouldResetStepOnClose,
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);

  useEffect(() => {
    const isCurrentStepValid = isNumber(currentStep) && currentStep >= 0;

    isCurrentStepValid && setCurrentStepIndex(currentStep);
  }, [currentStep, resetStep]);

  function handleChangeStep(index) {
    setCurrentStepIndex(index);
    onChangeCurrentStep && onChangeCurrentStep(index);
  }

  useEffect(() => {
    (!isOpen && shouldResetStepOnClose) && handleChangeStep(initialStep);
  }, [isOpen, shouldResetStepOnClose]);

  function handleResetModal() {
    onReset && onReset();
    handleChangeStep(initialStep);
  }

  const modalTitle = !isEmpty(title) ? title :
    `Step ${currentStepIndex + 1}: ${steps[currentStepIndex].title}`;

  const {
    isCloseConfirmOpen,
    exitCloseConfirm,
    openCloseConfirm,
    returnCloseConfirm,
  } = useCloseConfirmModal(false, toggleOpen, handleResetModal);

  const isLastStep = currentStepIndex === steps.length - 1;

  function handleCloseModal() {
    toggleOpen();
    onClose && onClose();
    withCloseConfirmation && openCloseConfirm();
  }

  function handleStepClick(index) {
    return () => {
      handleChangeStep(index);
    };
  }

  function handleNextStep() {
    const index = isLastStep ? currentStepIndex : currentStepIndex + 1;

    handleChangeStep(index);
  }

  function handlePrevStep() {
    const index = currentStepIndex ? currentStepIndex - 1 : initialStep;

    handleChangeStep(index);
  }

  return {
    isLastStep,
    isCloseConfirmOpen,
    currentStepIndex,
    modalTitle,
    exitCloseConfirm,
    returnCloseConfirm,
    handleCloseModal,
    handleChangeStep,
    handleStepClick,
    handleNextStep,
    handlePrevStep,
  };
}

export default useMultiStepModal;
