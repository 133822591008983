import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Card from 'components/core/Card';

import styles from '../styles.module.scss';

function ItemCards({
  isFirstCard,
  isUpperCaseItemTitle,
  events,
  renderCustomCardContent,
  renderCustomTitle,
}) {
  if (isEmpty(events)) {
    return (
      <Card className={styles.cardContainer}>
        The requested data is currently unavailable
      </Card>
    );
  }

  function renderContent(event, index) {
    const { text } = event || {};

    if (renderCustomCardContent) {
      return renderCustomCardContent(event, index);
    }

    return text;
  }

  function renderTitle(isFirstElement, event, index, className) {
    if (renderCustomTitle) {
      return renderCustomTitle(event, index, className);
    }

    const { title } = event || {};
    const eventTitle = isFirstElement ? 'Last action' : title;

    if (isEmpty(eventTitle)) {
      return null;
    }

    return <div className={className}>{eventTitle}</div>;
  }

  function renderCard(event, index) {
    const keyName = `${index}-event`;
    const isFirstElement = isFirstCard && index === 0;
    const isUpperCase = isFirstElement || isUpperCaseItemTitle;
    const titleClassNames = classNames(styles.eventTitle, isUpperCase && styles.uppercase);

    return (
      <Card key={keyName} className={classNames(styles.cardContainer, isFirstElement && styles.firstItem)}>
        {renderTitle(isFirstElement, event, index, titleClassNames)}
        <div className={styles.eventText}>
          {renderContent(event, index)}
        </div>
      </Card>
    );
  }

  return events.map(renderCard);
}

ItemCards.propTypes = {
  isUpperCaseItemTitle: PropTypes.bool,
  isFirstCard: PropTypes.bool,
  events: PropTypes.array,
  renderCustomCardContent: PropTypes.func,
  renderCustomTitle: PropTypes.func,
};

export default ItemCards;
