import React from 'react';
import PropTypes from 'prop-types';

import BasePageHeader from 'components/core/PageHeader';

function PageHeader({
  customBreadcrumb,
  avatarUrl,
  isMobile,
  isLoading,
  ...restProps
}) {
  const avatar = !isMobile ? avatarUrl : '';

  return (
    <BasePageHeader
      {...restProps}
      isLoading={isLoading}
      customBreadcrumb={customBreadcrumb}
      avatarUrl={avatar}
      withBorder
      isFullWidth
      isFullWidthTitle
    />
  );
}

PageHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customBreadcrumb: PropTypes.string,
  avatarUrl: PropTypes.string,
};

export default PageHeader;
