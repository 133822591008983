import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';

import { pdfStylesShape } from 'components/core/ExportDocument/utils/shapes';

import styles from './styles';

function Tag({ children, style }) {
  const tagStyles = {
    ...styles.tag,
    ...style,
  };

  return <Text style={tagStyles}>{children}</Text>;
}

Tag.defaultProps = {
  style: {},
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  style: pdfStylesShape,
};

export default Tag;
