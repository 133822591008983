import React from 'react';
import PropTypes from 'prop-types';
import { head, isEmpty } from 'lodash';

import { getFullName } from 'components/core/Profile/utils';
import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';
import LargerPhoto from 'components/core/Profile/components/Cards/LargerPhoto';
import { getPositionDescription, getName } from 'components/core/Profile/components/Cards/Positions/utils';

import Biography from './Biography';
import styles from './styles.module.scss';

function Card({ person, isLoading, children, customBadge, customBadgeNode, onAvatarError }) {
  const {
    avatarUrl,
    positions,
    party,
    facts,
    interests,
    narrativeBiography = {},
  } = person || {};
  const fullName = getFullName(person);
  const { infoText } = narrativeBiography || {};

  const mainPosition = head(positions) || {};
  const description = getPositionDescription(mainPosition);
  const name = getName(fullName, mainPosition);

  const {
    electionInfo,
    address,
  } = mainPosition || {};

  const { state: { name: stateName } = {} } = address || {};
  const { code: partyShort, name: partySlug } = party || {};

  const isInfoTextEmpty = isEmpty(infoText);
  const isInterestsEmpty = isEmpty(interests);
  const isFactsEmpty = isEmpty(facts);
  const hasBiography = !(isInfoTextEmpty && isInterestsEmpty && isFactsEmpty);

  const biography = hasBiography && <Biography person={person} />;

  return (
    <LargerPhoto
      isLoadingProfile={isLoading}
      className={styles.container}
      description={description}
      avatarUrl={avatarUrl}
      name={name}
      partyShort={partyShort}
      partySlug={partySlug}
      biography={biography}
      electionInfo={electionInfo}
      state={stateName}
      customBadge={customBadge}
      customBadgeNode={customBadgeNode}
      onAvatarError={onAvatarError}
    >
      {children}
    </LargerPhoto>
  );
}

Card.propTypes = {
  person: personShape.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  customBadge: PropTypes.node,
  customBadgeNode: PropTypes.node,
  onAvatarError: PropTypes.func,
};

export default withProfile(Card);
