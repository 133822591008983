import {
  controlBackground,
  controlOpenBackground,
  optionSelectedBackground,
  optionHoverBackground,
  optionColor,
  controlBorder,
  controlBorderRadius,
  mediumControlHeight,
  mediumErrorControlHeight,
  borderError,
  menuBorderRadius,
} from './settings.scss';

const style = isError => ({
  control: base => ({
    ...base,
    border: controlBorder,
    backgroundImage: controlBackground,
    boxShadow: 'none',
    minHeight: mediumControlHeight,
    borderRadius: controlBorderRadius,
    '&:hover': {
      border: controlBorder,
    },
    ...(isError && {
      border: borderError,
      boxShadow: 'none',
      minHeight: mediumErrorControlHeight,
      '&:hover': {
        border: borderError,
      },
    }),
  }),
  valueContainer: base => ({
    ...base,
    justifyContent: 'center',
    paddingRight: 0,
  }),
  placeholder: base => ({
    ...base,
    justifyContent: 'center',
    paddingRight: 0,
    fontStyle: 'italic',
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: '4px',
  }),
  menu: base => ({
    ...base,
    border: controlBorder,
    borderTop: 'none',
    backgroundImage: controlOpenBackground,
    borderRadius: menuBorderRadius,
    boxShadow: 'none',
    overflowY: 'hidden',
    margin: 0,
    paddingTop: '10px',
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 999,
  }),
  option: (base, state) => ({
    ...base,
    padding: '8px 20px',
    ...(state.isSelected && {
      background: optionSelectedBackground,
      color: optionColor,
    }),
    '&:hover': {
      background: optionHoverBackground,
    },
  }),
});

export default style;
