import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  Header,
  BaseCard,
  Sentiment,
} from 'components/core/News/components/Cards/common';
import { highlightSummary } from 'components/News/utils';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { selectedFeedShape, cardShape } from 'components/News/utils/shapes';
import { workspaceShape } from 'redux/events/utils/shapes';

import Footer from './components/Footer';
import styles from './styles.module.scss';

function Story({
  card,
  isMobile,
  isDesktop,
  showPreview,
  selectedFeed,
  isClusterPage,
  onSelectCard,
  selectedFeedKeywords,
  isChecked,
  feedType,
  displayCheckbox,
  displayShowRelated,
  isExternalComponent,
  onSaveTags,
  tags,
  isLoadingTags,
  onShareToMyFeed,
  isSharingToMyFeed,
  isLoadingMyFeedDocuments,
  isRouterLink,
  sharings,
}) {
  if (isEmpty(card)) {
    return null;
  }

  const { summary, title, href, sentiment, clusterIds } = card;

  const headerRightContent = !isMobile && (
    <Sentiment showType {...sentiment} />
  );

  const summaryInnerHTML = {
    __html: isEmpty(selectedFeedKeywords)
      ? summary
      : highlightSummary(summary, selectedFeedKeywords),
  };

  const summaryNode = summary && showPreview && (
    <p className={styles.summaryBody} dangerouslySetInnerHTML={summaryInnerHTML} />
  );

  return (
    <BaseCard displayCheckbox={displayCheckbox} onChange={onSelectCard} isChecked={isChecked}>
      <Header
        {...card}
        displaySource
        rightContent={headerRightContent}
        isMobile={isMobile}
        selectedFeed={selectedFeed}
        isExternalComponent={isExternalComponent}
        isRouterLink={isRouterLink}
        tags={tags}
        isLoadingTags={isLoadingTags}
        isLoadingMyFeedDocuments={isLoadingMyFeedDocuments}
        sentiment={sentiment}
        summary={summaryNode}
        sharings={sharings}
        withSummary
      />

      <Footer
        newsHref={href}
        newsTitle={title}
        newsSummary={summary}
        sentiment={sentiment}
        clusterIds={clusterIds}
        isClusterPage={isClusterPage}
        selectedFeed={selectedFeed}
        feedType={feedType}
        displayShowRelated={displayShowRelated}
        onSaveTags={onSaveTags}
        tags={tags}
        isLoadingTags={isLoadingTags}
        onShareToMyFeed={onShareToMyFeed}
        isSharingToMyFeed={isSharingToMyFeed}
        isDesktop={isDesktop}
      />
    </BaseCard>
  );
}

Story.defaultProps = {
  isExternalComponent: false,
  isRouterLink: false,
};

Story.propTypes = {
  card: cardShape,
  onSelectCard: PropTypes.func,
  isChecked: PropTypes.bool,
  selectedFeed: selectedFeedShape,
  showPreview: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isClusterPage: PropTypes.bool,
  selectedFeedKeywords: PropTypes.arrayOf(PropTypes.string),
  feedType: PropTypes.string,
  displayCheckbox: PropTypes.bool,
  displayShowRelated: PropTypes.bool,
  isExternalComponent: PropTypes.bool,
  onSaveTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  isLoadingTags: PropTypes.bool.isRequired,
  onShareToMyFeed: PropTypes.func.isRequired,
  isSharingToMyFeed: PropTypes.bool.isRequired,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
  isRouterLink: PropTypes.bool,
  sharings: PropTypes.arrayOf(workspaceShape),
};

export default Story;
