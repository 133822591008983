import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import badgeStyles from '../DayEvents/components/BadgeInfo/styles.module.scss';
import styles from './styles.module.scss';

function BadgeInfoPlaceholder({ isMobile, senate, expired, isFederal }) {
  return (
    <div className={classNames(styles.badgeContainer, badgeStyles.badgeContainer)}>
      <div className={badgeStyles.leftItems}>
        <span
          className={classNames(
            styles.badge,
            styles.time,
            badgeStyles.badge,
            isMobile && expired && styles.timeExpired
          )}
        />

        {!isMobile && (
          <span
            className={classNames(
              styles.badge,
              styles.date,
              badgeStyles.badge,
              badgeStyles.largeBadge,
            )}
          />
        )}
      </div>
      <div className={classNames(styles.rightItems, badgeStyles.rightItems)}>
        {expired && (
          <span
            className={classNames(
              styles.badge,
              styles.expired,
              badgeStyles.expiredBadge,
            )}
          />
        )}
        {isFederal && (
          <span
            className={classNames(
              badgeStyles.roundBadge,
              styles.roundBadge,
              senate ? badgeStyles.senateBadge : badgeStyles.houseBadge
            )}
          />
        )}
      </div>
    </div>
  );
}

BadgeInfoPlaceholder.propTypes = {
  isMobile: PropTypes.bool,
  senate: PropTypes.bool,
  expired: PropTypes.bool,
  isFederal: PropTypes.bool,
};

export default BadgeInfoPlaceholder;
