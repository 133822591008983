import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../Checkbox';
import styles from './styles.module.scss';
import useIndeterminateCheckbox from './useIndeterminateCheckbox';

function IndeterminateCheckbox({
  values,
  className,
  useIndeterminateBehavior,
  ...restProps
}) {
  const {
    isChecked,
    isIndeterminate,
  } = useIndeterminateBehavior(values);
  const indeterminateClass = isIndeterminate && isChecked && styles.indeterminate;

  return (
    <Checkbox
      {...restProps}
      isChecked={isChecked}
      className={classNames(className, indeterminateClass)}
    />
  );
}

IndeterminateCheckbox.defaultProps = {
  useIndeterminateBehavior: useIndeterminateCheckbox,
};

IndeterminateCheckbox.propTypes = {
  values: PropTypes.arrayOf(PropTypes.bool).isRequired,
  className: PropTypes.string,
  useIndeterminateBehavior: PropTypes.func,
};

export default IndeterminateCheckbox;
