import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function SelectedFile({ file, resetFile }) {
  const { name, size } = file;

  const sizeInKB = Math.round(size / 1024);
  const sizeInKBMessage = `(${sizeInKB}KB)`;

  return (
    <div className={styles.container}>
      <div className={styles.fileData}>
        <span className={styles.fileName}>
          {name}
        </span>

        {sizeInKBMessage}
      </div>

      <Button
        onClick={resetFile}
        theme="none"
        className={styles.removeFile}
      >
        <i className="fas fa-times" />
      </Button>
    </div>
  );
}

SelectedFile.defaultProps = {
  label: 'Choose file',
};

SelectedFile.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  resetFile: PropTypes.func.isRequired,
};

export default SelectedFile;
