import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Badge, { themes as badgeThemes } from 'components/core/Badge';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Header({ children, count, counterTheme, onAddClick }) {
  const countText = count < 100 ? count : '99+';

  return (
    <header className={styles.header}>
      <div className={styles.leftSide}>
        <Badge theme={counterTheme} className={styles.count}>{countText}</Badge>
        {children}
      </div>
      <Button theme="tableLink" size="small" onClick={onAddClick} className={styles.button}>
        <i className={classNames('fas fa-plus-circle', styles.plusIcon)} />
      </Button>
    </header>
  );
}

Header.defaultProps = {
  count: '',
};

Header.propTypes = {
  children: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onAddClick: PropTypes.func.isRequired,
  counterTheme: PropTypes.oneOf(badgeThemes).isRequired,
};

export default Header;
