import PropTypes from 'prop-types';

import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';

const userShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  photo: PropTypes.string,
  email: PropTypes.string,
});

const linkShape = PropTypes.shape({
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    token: PropTypes.string,
    value: PropTypes.string,
    externalUrl: PropTypes.string,
    id: PropTypes.number,
  })),
});

const uploadShape = PropTypes.shape({
  file_upload_id: PropTypes.number,
  file_upload_url: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
});

const customFieldShape = PropTypes.shape({
  token: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
});

const taskUserShape = PropTypes.shape({
  id: PropTypes.number,
  fullName: PropTypes.string,
  photoUrl: PropTypes.string,
});

const taskShape = PropTypes.shape({
  id: PropTypes.number,
  assignee: taskUserShape,
  assigner: taskUserShape,
  dueDateTime: PropTypes.string,
  priority: PropTypes.string,
  status: PropTypes.string,
});

export const cardShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  fullCreationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  eventDate: PropTypes.string,
  interactionType: PropTypes.string,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  user: userShape,
  createdBy: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(linkShape),
  customFieldsAnswers: PropTypes.arrayOf(customFieldShape),
  cardType: PropTypes.string,
  commentsCount: PropTypes.number,
  uploads: PropTypes.arrayOf(uploadShape),
  disabled: PropTypes.bool,
  task: taskShape,
  documentType: PropTypes.string,
});
