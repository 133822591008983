import * as Yup from 'yup';

import validateUniqueness from 'utils/yup/validateUniqueness';

import { hasOptions } from '.';
import errorMessages from './errorMessages';

Yup.addMethod(Yup.array, 'unique', validateUniqueness);

const validationSchema = Yup.object().shape({
  fieldName: Yup.string().required(errorMessages.fieldName),
  fieldType: Yup.object().shape({
    value: Yup.string().required(errorMessages.fieldType),
  }),
  productSlugs: Yup.array().required(errorMessages.productSlugs),
  fieldDescription: Yup.string().required(errorMessages.fieldDescription),
  options: Yup.mixed()
    .when(
      'fieldType', {
        is: (fieldType => hasOptions(fieldType.value)),
        then: Yup.array()
          .min(1, errorMessages.options.min)
          .of(Yup.object().shape({
            value: Yup.string().required(errorMessages.options.required),
          }))
          .unique(errorMessages.options.unique, 'value'),
      },
    ),
});

export default validationSchema;
