import React from 'react';
import PropTypes from 'prop-types';

import Item from './components/Item';

import styles from './styles.module.scss';

function Legend({ title }) {
  return (
    <div className={styles.legend}>
      <div className={styles.header}>{ title }</div>
      <Item text="House in session" className={styles.inHouse} />
      <Item text="Senate in session" className={styles.inSenate} />
    </div>
  );
}

Legend.propTypes = {
  title: PropTypes.string,
};

Legend.defaultProps = {
  title: 'Legend',
};

export default Legend;
