import React from 'react';

export const MapContext = React.createContext();

function withMap(Component) {
  return function MapComponent(props) {
    return (
      <MapContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </MapContext.Consumer>
    );
  };
}

export default withMap;
