import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export default function ErrorMessage({ message, className }) {
  if (!message) {
    return null;
  }

  return (
    <span className={classNames(styles.errorMessage, className)}>
      {message}
    </span>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};
