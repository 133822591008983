import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PartyIcon from 'components/shared/party-icon';

import styles from './styles.module.scss';

function LegislatorTitle({ moniker, partyShort, partySlug }) {
  const description = partyShort && partySlug && (
    <PartyIcon
      partyShort={partyShort}
      partySlug={partySlug}
      className={styles.partyIcon}
    />
  );

  return (
    <>
      {moniker}
      <span className={styles.badges}>
        {description}
      </span>
    </>
  );
}

LegislatorTitle.propTypes = {
  moniker: PropTypes.string.isRequired,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
};

export default LegislatorTitle;
