import React from 'react';
import PropTypes from 'prop-types';

import TrackModal from 'components/Dashboard/common/TrackModal';
import Button from 'components/core/Button';

import { initialSubscriptionShape } from './shapes';
import useBulkTrack from './useBulkTrack';

function BulkTrack({
  isCommittee,
  isMobile,
  theme,
  title,
  isLoading: isLoadingRedux,
  buttonClassName,
  onBulkTrack,
  selectedItems,
  defaultSubscription,
  isMultipleItems,
  initialSubscription,
  documentType,
  recordType,
  itemType,
}) {
  const {
    isLoading,
    isOpenModal,
    toggleModal,
    selectedFrequency,
    onChangeFrequency,
    onSaveTrackCommittee,
    onSaveMobile,
    onSave,
    workspaces,
    ...restProps
  } = useBulkTrack({
    documentType,
    onBulkTrack,
    selectedItems,
    defaultSubscription,
    initialSubscription,
    isMultipleItems,
    itemType,
  });

  const buttonProps = {
    theme,
    className: buttonClassName,
  };

  const modalProps = {
    ...restProps,
    isCommittee,
    isMobile,
    isLoading,
    isOpenModal,
    toggleModal,
    recordType,
    itemType,
    frequency: selectedFrequency,
    onSave,
    onSaveMobile,
    onChange: onChangeFrequency,
    onUpdateTrackCommittee: onSaveTrackCommittee,
    workspaces,
  };

  return (
    <>
      <Button
        {...buttonProps}
        disabled={isLoading || isLoadingRedux}
        onClick={toggleModal}
      >
        {title}
      </Button>
      <TrackModal {...modalProps} />
    </>
  );
}

BulkTrack.defaultProps = {
  theme: 'default',
  title: 'Track',
};

BulkTrack.propTypes = {
  isCommittee: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isMultipleItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string,
  recordType: PropTypes.string,
  documentType: PropTypes.string,
  itemType: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonClassName: PropTypes.string,
  defaultSubscription: PropTypes.string,
  initialSubscription: initialSubscriptionShape,
  onBulkTrack: PropTypes.func.isRequired,
};

export default BulkTrack;
