import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { EXPORT_RECORD_LIMIT, DOCX_ID, CSV_ID } from 'utils/constants/export';
import useModal from 'components/core/Modal/useModal';
import Loader from 'components/core/Loader';
import Button from 'components/core/Button';
import { Icon } from 'components/core/Icons';
import ExportAllWarning from 'components/core/ExportAllWarning';
import { paginatorShape } from 'components/core/Paginator/shapes';
import { tableColumnShape } from 'utils/shapes/table';
import { sortShape } from 'utils/shapes';

import styles from '../styles.module.scss';

function BulkExport({
  data,
  appliedSearches,
  exportReport,
  paginator,
  buttonTheme,
  loaderTheme,
  tracking,
  withIcon,
  withIconWrapper,
  customKeyMessage,
  sort,
  className,
  tableColumns,
  exportModal: ExportModal,
}) {
  const { ui = {} } = exportReport || {};
  const { totalCount } = paginator || {};
  const { trackAllResultsExported } = tracking || {};
  const warningModal = useModal();
  const bulkExportModal = useModal();

  const isLoadingExport = ui.isLoadingExports;
  const isEmptyData = isEmpty(data);
  const exportDataExceeded = totalCount > EXPORT_RECORD_LIMIT;

  function handleExportAll() {
    return exportDataExceeded
      ? warningModal.toggleModal()
      : bulkExportModal.toggleModal();
  }

  const loadingContent = isLoadingExport
    ? (
      <Loader
        className={classNames(styles.loader, withIconWrapper && styles.withIconWrap)}
        size="small"
        theme={loaderTheme}
        onlyIcon
      />
    )
    : <Icon iconClass="fa-download" withIconWrapper={withIconWrapper} />;

  return (
    <>
      <Button
        theme={buttonTheme}
        customIcon={withIcon && loadingContent}
        iconClassName={styles.icon}
        className={classNames(
          'header__export-all',
          styles.exportAll,
          isEmptyData && styles.btnDisabled,
          className,
        )}
        onClick={handleExportAll}
        disabled={isLoadingExport || isEmptyData}
      >
        <span
          className={classNames(
            styles.textButton,
            withIconWrapper && styles.textButtonWithIconWrap
          )}
        >
          Bulk export
        </span>
      </Button>

      <ExportModal
        {...bulkExportModal}
        appliedFilters={appliedSearches}
        sort={sort}
        onTracking={trackAllResultsExported}
        customFileFormats={[DOCX_ID, CSV_ID]}
        tableColumns={tableColumns}
        isBulkExport
      />

      {exportDataExceeded && (
        <ExportAllWarning
          {...warningModal}
          customKeyMessage={customKeyMessage}
          onExport={bulkExportModal.toggleModal}
        />
      )}
    </>
  );
}

BulkExport.defaultProps = {
  areEmptyEmails: false,
  loaderTheme: 'blue',
  buttonTheme: 'secondary',
  withIcon: true,
  withIconWrapper: false,
};

// TODO: add missing shapes
BulkExport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataProps: PropTypes.shape({}),
  appliedSearches: PropTypes.shape({}),
  exportReport: PropTypes.shape({}),
  tracking: PropTypes.shape({}),
  submitExport: PropTypes.func,
  submitAllExport: PropTypes.func,
  onSuccessExport: PropTypes.func,
  paginator: paginatorShape.isRequired,
  buttonTheme: PropTypes.string,
  loaderTheme: PropTypes.string,
  customKeyMessage: PropTypes.string,
  withIcon: PropTypes.bool,
  areEmptyEmails: PropTypes.bool,
  withIconWrapper: PropTypes.bool,
  className: PropTypes.string,
  sort: sortShape,
  tableColumns: PropTypes.arrayOf(tableColumnShape),
  exportModal: PropTypes.func,
};

export default BulkExport;
