import { useEffect, useState, createRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import jump from 'jump.js';

import { isBottom } from 'utils/scroll';

import { largeNavHeaderHeight } from 'style/navigation/settings.scss';

const NAV_OFFSET = -parseInt(largeNavHeaderHeight, 10);
const TIMEOUT_SCROLL = 500;
const offsetTop = 40;

function getSelectedElement(refList) {
  return Object.keys(refList).reduce(
    (refElement, key) => {
      const value = refList[key];

      if (!value.current) {
        return refElement;
      }

      const { top } = value.current.getBoundingClientRect();
      const differenceFromTop = Math.abs(top);

      return window.pageYOffset > value.current.offsetTop - offsetTop
        ? { differenceFromTop, key }
        : refElement;
    },
    { differenceFromTop: 0, key: '' },
  );
}

function useScrollProvider({ activeId }) {
  const [selected, setSelected] = useState(activeId);
  const [currentElement, setCurrentElement] = useState();
  const [isMoving, setIsMoving] = useState(false);
  const [refList, setRefList] = useState({});

  function handleScroll() {
    const keys = Object.keys(refList);
    const lastItemKey = keys.pop();
    const lastItem = refList[lastItemKey];

    const currentSelected = isBottom(lastItem)
      ? { key: lastItemKey, differenceFromTop: 0 }
      : getSelectedElement(refList);

    !isEmpty(currentSelected.key) && setSelected(currentSelected.key);
  }

  function addScrollEvent() {
    document.addEventListener('scroll', handleScroll);
  }

  function removeScrollEvent() {
    document.removeEventListener('scroll', handleScroll);
  }

  function goToElement() {
    removeScrollEvent();

    const { id, element } = currentElement;
    setSelected(id);
    setIsMoving(true);

    jump(element, {
      duration: TIMEOUT_SCROLL,
      offset: NAV_OFFSET,
      callback: () => {
        setIsMoving(false);
        setCurrentElement();
      },
    });
  }

  /** Add/Remove scroll listener + Go to element */
  useEffect(() => {
    isEmpty(currentElement) ? addScrollEvent() : goToElement();

    return removeScrollEvent;
  }, [currentElement, refList]);

  function handleClickToSection(section) {
    const sectionRef = refList[section];
    if (!sectionRef) return console.warn('Section ID not recognized!');

    if (selected === section) {
      return null;
    }

    return setCurrentElement({ id: section, element: sectionRef.current });
  }

  function handleRegisterRef(id) {
    if (!Object.keys(refList).includes(id)) {
      const newRef = createRef();
      setRefList(prevProps => ({ ...prevProps, [id]: newRef }));

      return newRef;
    }

    return refList[id];
  }

  return {
    refList,
    selected,
    isMoving,
    onClickToSection: handleClickToSection,
    onRegisterRef: handleRegisterRef,
  };
}

export default useScrollProvider;
