import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import withMap from 'components/core/Map/utils/withMap';

import BaseFilter from '../BaseFilter';
import styles from './styles.module.scss';

function MapType({ title, options, selectedFilter, onFilterClick, isLoadingMap, filterClassName }) {
  if (isEmpty(options)) {
    return null;
  }

  return (
    <div className={classNames(styles.container, filterClassName)}>
      <BaseFilter
        title={title}
        options={options}
        selectedFilter={selectedFilter}
        onFilterClick={onFilterClick}
        isLoadingMap={isLoadingMap}
      />
    </div>
  );
}

MapType.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  isLoadingMap: PropTypes.bool.isRequired,
  filterClassName: PropTypes.string,
};

export default withMap(MapType);
