import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LINK_COLOR,
  LABEL_FIELD_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  text: {
    color: LABEL_FIELD_COLOR,
    fontWeight: 400,
    fontSize: 8,
  },
  link: {
    color: DEFAULT_LINK_COLOR,
    fontWeight: 400,
    fontSize: 8,
    maxLines: 1,
    maxWidth: 300,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
  },
  indent: {
    marginLeft: 4,
  },
  label: {
    color: LABEL_FIELD_COLOR,
    fontSize: 8,
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default styles;
