import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function TextInput({ children, className }) {
  return (
    <p className={classNames(styles.textInputPlaceholder, className)}>
      {children}
    </p>
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default TextInput;
