import { useState, useEffect, useRef } from 'react';
import { isNil, isEmpty, trim, size } from 'lodash';

import notification from './notification';
import { LIMIT, EMPTY_SUMMARY } from './constants';
import { getSummaryAPI } from './api';
import useConfirmBeforeLeave from './useConfirmBeforeLeave';

function useSummary({ recordType, recordId, trackSaveSummary }) {
  const summaryRef = useRef(null);
  // Data
  const [summary, setSummary] = useState(EMPTY_SUMMARY);
  const [content, setContent] = useState('');
  const summaryAPI = getSummaryAPI({ recordType, recordId });

  // State
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInvalid = size(content) > LIMIT;
  const isDisabled = isInvalid || isLoading;

  const scrollToSummary = () => {
    summaryRef.current && summaryRef.current.scrollIntoView();
  };

  useConfirmBeforeLeave({
    enabled: isOpen,
    onContinue: scrollToSummary,
  });

  function resetSummary() {
    setSummary(EMPTY_SUMMARY);
    setContent('');
    setIsOpen(false);
  }

  function fetchSummary() {
    setIsLoading(true);

    summaryAPI.fetchSummary()
      .then(setSummary)
      .catch(() => null) // when there is no summary
      .finally(() => {
        setIsLoading(false);
      });
  }

  function deleteSummary() {
    if (isNil(summary.id)) {
      return Promise.resolve();
    }

    return summaryAPI
      .deleteSummary()
      .then(resetSummary);
  }

  function saveSummary() {
    const payload = {
      content,
      summaryId: summary.id,
    };

    return summaryAPI
      .saveSummary(payload)
      .then(setSummary);
  }

  function handleChange(event) {
    const { value } = event.target;

    setContent(value);
  }

  function handleUpdate() {
    if (isEmpty(trim(content))) {
      return deleteSummary();
    }

    return saveSummary();
  }

  function handleClick() {
    if (isOpen) {
      setIsLoading(true);

      handleUpdate()
        .then(() => {
          trackSaveSummary && trackSaveSummary();
          notification.savedWithSuccess();
          setIsOpen(false);
        })
        .catch(() => {
          notification.failedToSave();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsOpen(true);
    }
  }

  function getButtonLabel() {
    if (isOpen) {
      return 'Save summary';
    }

    if (isNil(summary.id)) {
      return 'Add summary';
    }

    return 'Edit summary';
  }

  useEffect(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    setContent(summary.content);
  }, [summary]);

  return {
    summaryRef,
    isOpen,
    isDisabled,
    isLoading,
    content,
    updatedAt: summary.updatedAt,
    buttonLabel: getButtonLabel(),
    onChange: handleChange,
    onClick: handleClick,
  };
}

export default useSummary;
