import React from 'react';
import PropTypes from 'prop-types';

import { FixedTabs } from 'components/core/Tabs';

import PageHeader from './components/PageHeader';

function Profile({
  pageHeaderProps,
  isLoading,
  items,
  activeId,
  className,
  itemsNumberPlaceholder,
  history,
  ...restProps
}) {
  return (
    <>
      <PageHeader
        isLoading={isLoading}
        {...pageHeaderProps}
      />

      <FixedTabs
        isLoading={isLoading}
        items={items}
        activeId={activeId}
        className={className}
        itemsNumberPlaceholder={itemsNumberPlaceholder}
        history={history}
        {...restProps}
      />
    </>
  );
}

Profile.propTypes = {
  pageHeaderProps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  activeId: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemsNumberPlaceholder: PropTypes.number,
  history: PropTypes.object,
};

export default Profile;
