import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CoreModal from '../Modal';
import styles from './styles.module.scss';

export const DEFAULT_SLIDE_DURATION = 300;

function SlideUpModal({
  isOpen,
  toggleOpen,
  children,
  slideDurationMillis,
  className,
  ...restProps
}) {
  const [isSlidingOpen, setIsSlidingOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  function handleModalToggle() {
    if (isOpen) {
      setIsModalOpen(true);

      return setTimeout(() => setIsSlidingOpen(true), 0);
    }
    setIsSlidingOpen(false);

    return setTimeout(() => setIsModalOpen(false), slideDurationMillis);
  }

  useEffect(() => {
    const timer = handleModalToggle();

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  const modalClassNames = classNames(
    styles.modal,
    isSlidingOpen && styles.modalOpen,
    className,
  );

  return (
    <CoreModal
      {...restProps}
      isOpen={isModalOpen}
      className={modalClassNames}
      toggleOpen={toggleOpen}
    >
      {children}
    </CoreModal>
  );
}

SlideUpModal.defaultProps = {
  slideDurationMillis: DEFAULT_SLIDE_DURATION,
};

SlideUpModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  slideDurationMillis: PropTypes.number,
  className: PropTypes.string,
};

export default SlideUpModal;
