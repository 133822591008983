import { useRef, useEffect, useState } from 'react';

import { CHART_COLORS } from 'utils/constants/charts';

import { transformChartData, getGradientColor, getChartOptions } from './utils';

function useAreaChart({
  lineChartData,
  isMobile,
  ...chartOptionProps
}) {
  const {
    fillStartColor,
    fillEndColor,
    borderColor,
    tooltipLabel,
    borderWidth,
    pointBorderWidth,
    pointHitRadius,
    pointRadius,
    pointHoverRadius,
    maxXAxisTicksLimit,
    rotateMobileXTicks,
    gradientY1,
  } = { ...chartOptionProps };

  const { chartData, labels } = transformChartData(lineChartData);
  const [fillColor, setFillColor] = useState(fillStartColor);
  const showPlaceholder = lineChartData.length <= 1;
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const gradient = getGradientColor(
        chartRef.current,
        fillStartColor,
        fillEndColor,
        gradientY1,
      );
      setFillColor(gradient);
    }
  }, [chartRef.current, lineChartData]);

  const chartDataOptions = {
    pointBorderColor: borderColor,
    pointBorderWidth,
    pointRadius,
    pointHitRadius,
    pointHoverRadius,
    borderColor,
    backgroundColor: fillColor,
  };

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        borderWidth,
        lineTension: 0,
        pointBackgroundColor: CHART_COLORS.POINT_BACKGROUND_COLOR,
        pointHoverBackgroundColor: CHART_COLORS.POINT_BACKGROUND_COLOR,
        ...chartDataOptions,
      },
    ],
  };

  return {
    data,
    chartRef,
    showPlaceholder,
    chartOptions: getChartOptions({ isMobile, tooltipLabel, maxXAxisTicksLimit, rotateMobileXTicks }),
  };
}

export default useAreaChart;
