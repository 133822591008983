import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Link from 'utils/Link';

import styles from './styles.module.scss';

function TypeTitle({
  itemType,
  itemTitle,
  maxTitleLength,
  url,
  isText,
  className,
  typeClassName,
  titleClassName,
  isKeywordMatch,
}) {
  const isItemTitleEmpty = isEmpty(itemTitle);

  if (isEmpty(itemType) && isItemTitleEmpty) {
    return null;
  }

  function getSeparator() {
    if (isItemTitleEmpty) {
      return null;
    }

    return isKeywordMatch ? ', keyword:' : ':';
  }

  const content = (
    <div className={classNames(styles.content, className)}>
      {itemType && (
        <span className={classNames(styles.uppercase, typeClassName)}>
          {itemType}{getSeparator()}
        </span>
      )}
      {itemTitle && (
        <span className={classNames(styles.defaultCase, titleClassName)}>
          {truncate(itemTitle, { length: maxTitleLength })}
        </span>
      )}
    </div>
  );

  if (url) {
    return (
      <Link
        className={classNames(styles.baseLink, isText && styles.linkBlack)}
        theme="tableLink"
        href={url}
      >
        {content}
      </Link>
    );
  }

  return content;
}

TypeTitle.defaultProps = {
  maxTitleLength: 50,
  isText: true,
  isKeywordMatch: false,
};

TypeTitle.propTypes = {
  itemType: PropTypes.string,
  itemTitle: PropTypes.string,
  maxTitleLength: PropTypes.number,
  url: PropTypes.string,
  isText: PropTypes.bool,
  className: PropTypes.string,
  typeClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  isKeywordMatch: PropTypes.bool,
};

export default TypeTitle;
