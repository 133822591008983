import React from 'react';
import PropTypes from 'prop-types';

import { defaultCheatSheetData } from './utils';
import styles from './styles.module.scss';

function CheatSheet({ description, data }) {
  return (
    <div>
      {description && <p>{description}</p>}

      <table className={styles.cheatSheetContent}>
        <thead>
          <tr>
            <td>Syntax</td>
            <td>Usage</td>
          </tr>
        </thead>
        <tbody>
          {data.map(({ syntax, usageMessage }, index) => (
            <tr key={`${syntax}-${index}`}>
              <td>{syntax}</td>
              <td>{usageMessage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

CheatSheet.defaultProps = {
  description: 'You can create advanced queries with the following syntax in the search bar:',
  data: defaultCheatSheetData,
};

CheatSheet.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.array,
};

export default CheatSheet;
