import React from 'react';
import PropTypes from 'prop-types';

import { LegislatorName } from 'components/Legislator';
import { Column } from 'components/core/Table';

function LegislatorItem({
  keyName,
  isPlainText,
  ...restProps
} = {}) {
  function renderName() {
    return <LegislatorName {...restProps} />;
  }

  return isPlainText
    ? renderName()
    : <Column key={keyName} keyName={keyName}>{renderName}</Column>;
}

LegislatorItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  isPlainText: PropTypes.bool,
};

export default LegislatorItem;
