import React from 'react';
import PropTypes from 'prop-types';

import { RECORD_TYPES } from './utils/constants';
import useSummary from './utils/useSummary';
import useConfirmBeforeLeave from './utils/useConfirmBeforeLeave';
import SummaryButton from './components/SummaryButton';
import SummaryContent from './components/SummaryContent';
import styles from './styles.module.scss';

function Summary({ recordType, recordId }) {
  const {
    summaryRef,
    isOpen,
    isDisabled,
    isLoading,
    onChange,
    onClick,
    updatedAt,
    content,
    buttonLabel,
  } = useSummary({ recordType, recordId });

  return (
    <div className={styles.summary} ref={summaryRef}>
      <div className={styles.buttonContainer}>
        <SummaryButton
          isLoading={isLoading}
          buttonLabel={buttonLabel}
          onClick={onClick}
          disabled={isDisabled}
        />
      </div>
      <SummaryContent
        isOpen={isOpen}
        content={content}
        updatedAt={updatedAt}
        onChange={onChange}
      />
    </div>
  );
}

Summary.propTypes = {
  recordType: PropTypes.oneOf(
    Object.values(RECORD_TYPES)
  ),
  recordId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Summary;

export { RECORD_TYPES, SummaryButton, SummaryContent, useSummary, useConfirmBeforeLeave };
