import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import DocumentContent from 'components/core/DocumentContent';
import Placeholder from 'components/core/DocumentPlaceholder';
import RightSideBar from 'components/core/RightSideBar';

import { summaryShape } from './utils/shapes';

function DocumentContainer({
  summary,
  ui,
  fetchDetails,
  onExportDocument,
  sidebarItems,
  match,
  documentType,
  actionButtons,
  onDocumentShared,
  onTagsSaved,
}) {
  const { pdfUrl, source_url: sourceUrl } = summary || {};
  const { isLoading, isExporting } = ui || {};
  const { params } = match || {};
  const { id } = params || {};

  useEffect(() => {
    fetchDetails(documentType, id);
  }, []);

  if (!isLoading && isEmpty(summary)) {
    return <Placeholder isEmptyText />;
  }

  const rightSideBarData = {
    ...summary,
    id,
    onDocumentShared,
    onTagsSaved,
  };

  return (
    <>
      <RightSideBar items={sidebarItems} data={rightSideBarData} />
      <DocumentContent
        isLoadingDocument={isLoading}
        pdfUrl={pdfUrl}
        sourceUrl={sourceUrl}
        documentType={documentType}
        isLoadingExports={isExporting}
        onExportDocument={onExportDocument}
        actionButtons={actionButtons}
      />
    </>
  );
}

DocumentContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }).isRequired,
  }),
  documentType: PropTypes.shape({
    name: PropTypes.string,
    apiUrl: PropTypes.string,
  }),
  summary: summaryShape,
  ui: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  exportReport: PropTypes.shape({
    ui: PropTypes.shape({
      isLoadingExports: PropTypes.bool,
    }),
  }),
  fetchDetails: PropTypes.func.isRequired,
  onExportDocument: PropTypes.func.isRequired,
  sidebarItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.element,
    }),
  ),
  actionButtons: PropTypes.element,
  onDocumentShared: PropTypes.func.isRequired,
  onTagsSaved: PropTypes.func.isRequired,
};

export default DocumentContainer;
