import PropTypes from 'prop-types';

const settings = PropTypes.shape({
  title: PropTypes.string,
  fileFormat: PropTypes.string.isRequired,
  showMyLogo: PropTypes.bool.isRequired,
  chooseColumns: PropTypes.bool.isRequired,
});

const settingsManager = PropTypes.shape({
  setTitle: PropTypes.func.isRequired,
  setFileFormat: PropTypes.func.isRequired,
  setCustomization: PropTypes.func.isRequired,
});

const itemsSelectedManager = PropTypes.shape({
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
});

const columnsManager = PropTypes.shape({
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
  isCheckedAll: PropTypes.func.isRequired,
});

const column = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const item = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(column),
});

const options = PropTypes.arrayOf(
  PropTypes.shape({
    section: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(item),
    isOpen: PropTypes.bool,
  })
);

const summary = PropTypes.shape({
  reportHasSummary: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
});

export default {
  settings,
  settingsManager,
  itemsSelectedManager,
  columnsManager,
  column,
  item,
  options,
  summary,
};
