import { useEffect, useState } from 'react';

import {
  NEW_STATE,
  RECENT_STATE,
  SAVED_STATE,
} from 'components/core/AdvancedSearch/utils/constants';

function getIsSaved(type) {
  return type === SAVED_STATE;
}

function getIsRecent(type) {
  return type === RECENT_STATE;
}

function useActiveItem({ isMobile = false, loadSavedSearches, loadRecentSearches }) {
  const [activeBtn, setActiveBtn] = useState(null);

  const isSaved = getIsSaved(activeBtn);
  const isRecent = getIsRecent(activeBtn);
  const isNew = activeBtn === NEW_STATE;

  useEffect(() => {
    setActiveBtn(RECENT_STATE);
    !isMobile && loadRecentSearches();
  }, []);

  useEffect(() => {
    isMobile
      ? setActiveBtn(NEW_STATE)
      : setActiveBtn(RECENT_STATE);
  }, [isMobile]);

  const handleClickActiveState = type => () => {
    setActiveBtn(type);

    if (loadSavedSearches && getIsSaved(type)) {
      loadSavedSearches();
    } else if (loadRecentSearches && getIsRecent(type)) {
      loadRecentSearches();
    }
  };

  return {
    isRecent,
    isNew,
    isSaved,
    activeBtn,
    onChange: handleClickActiveState,
  };
}

export default useActiveItem;
