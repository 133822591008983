import { useState, useEffect } from 'react';

export default function useFileUrl({ file, onChange }) {
  const [fileUrl, setFileUrl] = useState();

  const updateFileUrl = ({ target: { result } }) => setFileUrl(result);

  function loadFile() {
    const reader = new FileReader();
    reader.onload = updateFileUrl;
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if (file instanceof File) {
      loadFile();
    } else {
      setFileUrl();
    }
  }, [file]);

  useEffect(() => {
    onChange(fileUrl);
  }, [fileUrl]);
}
