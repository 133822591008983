import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';

import { DEFAULT_TEXT_COLOR } from '../../utils/colors.scss';
import LabelTitle from '../LabelTitle';

const styles = StyleSheet.create({
  valueText: {
    fontWeight: 400,
    fontSize: 12,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function LabelText({ style, label, value, textStyle, labelStyle, wrap, fixed }) {
  const currentTextStyle = { ...styles.valueText, ...textStyle };
  const currentLabelStyle = { ...styles.valueText, ...labelStyle };

  return (
    <View fixed={fixed} style={style} wrap={wrap}>
      {label && <LabelTitle fixed={fixed} label={label} style={currentLabelStyle} wrap={wrap} />}
      <Text fixed={fixed} wrap={wrap} style={currentTextStyle}>{value || EMPTY_VALUE}</Text>
    </View>
  );
}

LabelText.defaultProps = {
  style: {},
  wrap: true,
  fixedLabel: false,
  fixed: false,
};

LabelText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
  style: PropTypes.object,
  textStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  wrap: PropTypes.bool,
  fixed: PropTypes.bool,
};

export default LabelText;
