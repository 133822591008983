import { joinCompact, getParenthesisItem } from 'utils/string';

import { getDate } from '../../utils';

export const getBranchContent = ({ highestRank, branch, details, startDate, endDate }) => {
  const date = getDate(startDate, endDate);
  const formattedDetails = getParenthesisItem(details);
  const secondPart = joinCompact([branch, formattedDetails, date], ' ');

  return joinCompact([highestRank, secondPart]);
};
