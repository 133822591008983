import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import Push from 'components/core/Notification/components/Push';

import { notificationShape, uiShape } from '../../utils/shapes';
import { MY_FEED_LINK } from '../../utils/constants';
import useAlertsPanel from './useAlertsPanel';
import styles from './styles.module.scss';

function AlertsPanel({
  ui,
  isMobile,
  notifications,
  clearNotifications,
  totalNotifications,
  onClose,
}) {
  const {
    showClearAllButton,
    onClearAll,
    isClearAllButtonDisabled,
    showEmptyState,
    displayNotifications,
    showOverLimitLink,
    overLimitCount,
  } = useAlertsPanel({ notifications, clearNotifications, ui, totalNotifications, isMobile });

  const descriptionLimit = 55;

  function renderNotification(notification) {
    const { id } = notification;

    return (
      <Push
        {...notification}
        className={styles.notification}
        key={id}
        isMobile={isMobile}
        customDescriptionLimit={descriptionLimit}
        typeClassName={styles.type}
        bodyClassName={styles.body}
      />
    );
  }

  function handleViewAll() {
    onClearAll();
    onClose();
  }

  const displayNotificationCount = totalNotifications > 0;
  const isMobileV2 = gon.isNavigationV2Enabled && isMobile;

  const renderHeader = () => {
    const title = `Notifications ${displayNotificationCount ? `(${totalNotifications})` : ''}`;

    if (isMobileV2) {
      return (
        <header className={styles.mobileHeader}>
          <div className={styles.title}>
            {title}
          </div>
          <Button className={styles.closeButton} theme="light" onClick={onClose}>
            <i className={classNames('far fa-times', styles.icon)} />
          </Button>
        </header>
      );
    }

    return (
      <header className={styles.header}>
        {title}

        {showClearAllButton && (
          <Button
            theme="light"
            size="none"
            className={styles.clearButton}
            onClick={onClearAll}
            disabled={isClearAllButtonDisabled}
          >
            Clear all
          </Button>
        )}
      </header>
    );
  };

  const renderActions = () => {
    const viewAllLink = (
      <Link
        theme="tertiary"
        href={MY_FEED_LINK}
        onClick={handleViewAll}
        className={styles.myFeedLink}
      >
        View all in My feed
      </Link>
    );

    if (isMobileV2) {
      return (
        <div className={styles.actions}>
          {showClearAllButton && (
            <Button onClick={onClearAll} theme="light" className={styles.markAllAsRead}>
              Mark all as read
            </Button>
          )}
          {viewAllLink}
        </div>
      );
    }

    return viewAllLink;
  };

  const overLimitLink = showOverLimitLink && (
    <Link
      theme="default"
      href={MY_FEED_LINK}
      className={styles.overLimit}
      onClick={handleViewAll}
    >
      {`+ ${overLimitCount} more`}
    </Link>
  );

  const renderNotifications = () => {
    const notificationsNode = (
      <>
        {map(displayNotifications, renderNotification)}
        {overLimitLink}
      </>
    );

    if (isMobileV2) {
      return (
        <div className={styles.notifications}>
          {notificationsNode}
        </div>
      );
    }

    return notificationsNode;
  };

  return (
    <div className={classNames(styles.content, gon.isNavigationV2Enabled && styles.v2)}>
      {renderHeader()}

      {showEmptyState && (
        <p className={styles.emptyPanel}>
          You're all caught up!
        </p>
      )}

      {renderNotifications()}

      {renderActions()}
    </div>
  );
}

AlertsPanel.propTypes = {
  notifications: PropTypes.arrayOf(notificationShape),
  clearNotifications: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  ui: uiShape.isRequired,
  totalNotifications: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default AlertsPanel;
