import { last, split, isEmpty } from 'lodash';

export function getTitle(district) {
  if (isEmpty(district)) {
    return 'No district found';
  }

  return `${district.label} district ${district.value}`;
}

export function transformLegislator({
  full_role,
  twitter_screen_name,
  photo_url,
  long_moniker,
  twitter_url,
  full_name,
  ...restProps
}) {
  // TODO: Remove splitting twitterName when this will be done on BE or solved on data side
  const twitterName = last(split(twitter_screen_name, 'twitter.com/'));

  return {
    ...restProps,
    role: full_role,
    twitterName,
    photoUrl: photo_url,
    title: long_moniker,
    twitterUrl: twitter_url,
    name: full_name,
  };
}
