import { useState } from 'react';

function useRadioGroup({ id, defaultCheckedItems, onChange }) {
  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);

  function handleOnCheck(event) {
    const { value: currentValue, checked } = event.target;
    const item = checked ? [currentValue] : checkedItems;

    setCheckedItems(item);
    onChange(id, item);
  }

  return {
    checkedItems,
    onCheck: handleOnCheck,
  };
}

export default useRadioGroup;
