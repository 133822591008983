import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Label from 'components/core/Label';
import TagInput from 'components/core/TagInput';
import theme from 'components/core/TagInput/theme';
import Select from 'components/core/Select';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import { Disclaimer } from 'components/core/Modal';
import Button from 'components/core/Button';
import useModal from 'components/core/Modal/useModal';
import Tooltip from 'components/core/Table/components/Header/components/Tooltip';
import Asterisk from 'components/core/Asterisk';

import styles from './styles.module.scss';

function BaseOption({
  isMobile,
  title,
  placeholder,
  questionText,
  tagInputPlaceholder,
  className,
  customComponent,
  isRequired,
  withDisclaimer,
  withOverflow,
  disclaimerProps,
  isSelect,
  onChange,
  options,
  headerClassName,
  labelClassName,
  errorMessage,
  ...restProps
}) {
  const { isOpenModal, toggleModal } = useModal();
  const { title: disclaimerTitle, isDisabled } = disclaimerProps;
  const modalProps = { ...disclaimerProps, isOpenModal, toggleModal };

  function renderField() {
    if (isSelect) {
      return (
        <Select
          {...restProps}
          placeholder=""
          customTheme={theme({ withDisclaimer, isMobile, isError: !isEmpty(errorMessage) })}
          onChange={onChange}
          options={options}
          errorMessage={errorMessage}
        />
      );
    }

    return (
      <TagInput
        {...restProps}
        onChange={onChange}
        placeholder={tagInputPlaceholder}
        withDisclaimer={withDisclaimer}
        withOverflow={withOverflow}
        isMobile={isMobile}
        errorMessage={errorMessage}
        shouldOverrideValue
      />
    );
  }

  function renderComponent() {
    if (!isEmpty(customComponent)) {
      return customComponent;
    }

    return (
      <div className={styles.optionWrapper}>
        {renderField()}

        {withDisclaimer && (
          <Button
            theme="tertiary"
            size="none"
            customIcon={<i className="fas fa-question-circle" />}
            className={styles.disclaimer}
            iconClassName={styles.icon}
            onClick={toggleModal}
            disabled={isDisabled}
          >
            {!isMobile && disclaimerTitle}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={classNames(styles.header, headerClassName)}>
        {title && (
          <Label className={classNames(styles.label, labelClassName)}>
            {title}
            <Asterisk show={isRequired} />
            {questionText && <Tooltip content={<div className={styles.tooltipContent}>{questionText}</div>} />}
          </Label>
        )}

        {placeholder && (
          <TextInputPlaceholder className={styles.placeholder}>{placeholder}</TextInputPlaceholder>
        )}
      </div>

      {renderComponent()}

      {withDisclaimer && <Disclaimer {...modalProps} />}
    </div>
  );
}

BaseOption.defaultProps = {
  tagInputPlaceholder: '',
  withDisclaimer: false,
  withOverflow: false,
  isMobile: false,
  disclaimerProps: {},
  isSelect: false,
  options: [],
};

BaseOption.propTypes = {
  headerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  tagInputPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  questionText: PropTypes.string,
  className: PropTypes.string,
  customComponent: PropTypes.node,
  isRequired: PropTypes.bool,
  isMobile: PropTypes.bool,
  withDisclaimer: PropTypes.bool,
  withOverflow: PropTypes.bool,
  disclaimerProps: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
    isDisabled: PropTypes.bool,
  }),
  isSelect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  errorMessage: PropTypes.string,
};

export default BaseOption;
