import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

import Select from 'components/core/Select';

import { addNewStatementStyle } from '../theme';
import styles from '../styles.module.scss';

function StatementsAdd({
  isNewGroupPartHidden,
  isJoinGroupPartHidden,
  isDraggingStatement,
  statementsGroupId,
  errorStatements,
  statements,
}) {
  const hasStatements = statements.length > 0;
  const displayOperator = isDraggingStatement && hasStatements;
  const displayJoinGroup = !isJoinGroupPartHidden && hasStatements;
  const displayAddNewGroup = !(isNewGroupPartHidden && hasStatements);
  const messageJoinGroup = 'Drop here to merge this statement with another';
  const messageAddGroup = `${(isDraggingStatement && 'Drop') || 'Drag'} a statement here`;

  const commonDroppableProps = { type: 'droppableStatements' };

  const droppableStatementsJoinProps = {
    ...commonDroppableProps,
    droppableId: `droppableStatementsJoinFor:${statementsGroupId}`,
  };

  const droppableStatementsGroupProps = {
    ...commonDroppableProps,
    droppableId: 'droppableStatementsGroupNew',
  };

  return (
    <>
      {displayJoinGroup && (
        <Droppable {...droppableStatementsJoinProps}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              className={styles.droppableJoinStatement}
              style={
                addNewStatementStyle(
                  isDraggingStatement,
                  droppableSnapshot.isDraggingOver,
                  statements,
                )
              }
            >
              <div className={styles.placeholder}>
                <span>{messageJoinGroup}</span>
              </div>
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      )}

      {displayAddNewGroup && (
        <>
          {displayOperator && (
            <section className={styles.droppableStatementOperator}>
              <Select
                placeholder="AND"
                hasNewStyle
                isDisabled
              />
            </section>
          )}

          <Droppable {...droppableStatementsGroupProps}>
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                className={classNames(
                  styles.droppableNew,
                  errorStatements && styles.invalid
                )}
                style={
                  addNewStatementStyle(
                    isDraggingStatement,
                    droppableSnapshot.isDraggingOver,
                    statements,
                  )
                }
              >
                <div className={styles.placeholder}>
                  <span>{messageAddGroup}</span>
                </div>
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </>
      )}
    </>
  );
}

StatementsAdd.defaultProps = {
  isNewGroupPartHidden: false,
  isJoinGroupPartHidden: false,
};

StatementsAdd.propTypes = {
  isNewGroupPartHidden: PropTypes.bool,
  isJoinGroupPartHidden: PropTypes.bool,
  isDraggingStatement: PropTypes.bool.isRequired,
  statementsGroupId: PropTypes.string,
  statements: PropTypes.array.isRequired,
};

export default StatementsAdd;
