import { range, floor } from 'lodash';

export const getPage = (page, total) => {
  if (page < 1) {
    return 1;
  }
  if (page > total) {
    return total;
  }

  return page;
};

export const getPageNumbers = (start, end) => range(start, end + 1);

export const isNumberOfPagesValid = (start, end, numberOfPages) =>
  end - start + 1 === numberOfPages;

/**
 * This function returns an interval that centers the current page if this
 * interval is valid.
 */
export const getIntervalFromCurrentPage = (currentPage, numberOfPages, total) => {
  const sidePages = floor(numberOfPages / 2);
  const start = currentPage - sidePages;
  let end = currentPage + sidePages;

  // if number of pages is even we will have n pages on the left
  // and n-1 pages on the right
  if (numberOfPages % 2 === 0) {
    end -= 1;
  }

  // if end is greater than total we should get an interval with the fixed number
  // of pages that ends in total
  if (end > total) {
    return { start: total - numberOfPages + 1, end: total };
  }

  // if start is lesser than one we should get an interval from 1 to number of
  // pages
  if (start < 1) {
    return { start: 1, end: numberOfPages };
  }

  return { start, end };
};

/**
 * Returns a new interval starting from nextPage or ending from total
 */
export const getNextInterval = (nextPage, numberOfPages, total) => {
  let start = nextPage;
  const end = getPage(nextPage + numberOfPages - 1, total);

  if (!isNumberOfPagesValid(start, end, numberOfPages)) {
    start = total - numberOfPages + 1;
  }

  return { start, end };
};

/**
 * Returns a new interval ending from nextPage or starting from 1
 */
export const getPreviousInterval = (nextPage, numberOfPages, total) => {
  const start = getPage(nextPage - numberOfPages + 1, total);
  let end = nextPage;

  if (!isNumberOfPagesValid(start, end, numberOfPages)) {
    end = numberOfPages;
  }

  return { start, end };
};

export function getChunkedArray(array = [], chunkSize) {
  const chunkRange = range(Math.ceil(array.length / chunkSize));

  return chunkRange.map((_, index) => array.slice(index * chunkSize, (index * chunkSize) + chunkSize));
}
