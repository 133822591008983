import React from 'react';
import PropTypes from 'prop-types';

import { SourceLabel } from 'components/core/News/components/Cards/common';
import { cardShape } from 'components/News/utils/shapes';

import styles from './styles.module.scss';

function StoryItem({ story, isMobile }) {
  const { title, publishedAt, author, source } = story;
  const { logoUrl } = source;

  return (
    <div className={styles.storyContainer}>
      {logoUrl && <img className={styles.image} src={logoUrl} alt="Story thumbnail" />}
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>{title}</h4>
        <SourceLabel
          author={author}
          source={source}
          className={styles.source}
          publishedAt={publishedAt.full}
        />
      </div>
      {!isMobile && <span className={styles.publishedAt}>{publishedAt.full}</span>}
    </div>
  );
}

StoryItem.propTypes = {
  story: cardShape.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default StoryItem;
