export const mapStyleUrl = gon.mapboxStylesUrl;

export const linkListLimit = 3;
export const remainedLinkListLimit = 25;

export const mapTypeCodes = {
  none: 'NOT DEFINED',
  congressional: 'cd',
  stateUpper: 'sldu',
  stateLower: 'sldl',
};

export const displayTypeCodes = {
  markers: 'markers',
  heatmap: 'heatmap',
};

export const highlightLayers = ['district-fill', 'district-stroke'];

export const defaultHighlightingFilters = [
  'all',
  ['==', ['get', 'geoId'], 'NOT DEFINED'],
  ['==', ['get', 'type'], 'NOT DEFINED'],
];

export const layers = [
  'districts_boundary_line',
  'districts_1_label',
  'districts_1',
  'districts_2',
  'districts_3',
  'districts_4',
  'districts_5',
];

export const mapboxApiEndpoint = `https://api.mapbox.com/v4/${gon.mapboxTilesetId}/tilequery`;
