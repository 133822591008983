import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { SettingsButton, settingsButtonOptionsShape } from 'components/core/PageHeader';

import withSearch from '../../utils/withSearch';
import AdvancedSearch from '../AdvancedSearch';
import { CustomToggleViewButton, CustomToggleViewContent } from '../CustomToggleView';
import PageViewToggle from '../PageViewToggle';
import styles from './styles.module.scss';

function DesktopView({
  className,
  appliedFiltersLength,
  searchContent,
  advancedSearchContent,
  actionsContent,
  handleAdvancedSearchToggle,
  isAdvancedSearchOpen,
  rightContent,
  onClearAll,
  pageView,
  customToggleViewProps,
  settingsButtonOptions,
}) {
  const withActionsContent = typeof actionsContent === 'function' && !isEmpty(actionsContent());
  const withPageView = !isEmpty(pageView);
  const withCustomToggleView = !isEmpty(customToggleViewProps);

  if (isAdvancedSearchOpen) {
    return (
      <section className={classNames(styles.container, className)}>
        <header className={styles.advancedSearchHeader}>
          {advancedSearchContent}
        </header>
      </section>
    );
  }

  function getSettingsButton() {
    if (isEmpty(settingsButtonOptions)) {
      return null;
    }

    return (
      <SettingsButton
        options={settingsButtonOptions}
        className={styles.settingsButton}
      />
    );
  }

  return (
    <section className={classNames(styles.container, className)}>
      <header className={classNames('search-header', styles.header)}>
        <div className={styles.left}>
          <div className={styles.search}>
            {searchContent}
          </div>

          {!isEmpty(advancedSearchContent) && (
            <AdvancedSearch
              handleAdvancedSearchToggle={handleAdvancedSearchToggle}
              appliedFiltersLength={appliedFiltersLength}
              onClearAll={onClearAll}
            />
          )}
          {withCustomToggleView && <CustomToggleViewButton />}
        </div>

        <div className={styles.right}>
          {withActionsContent && actionsContent()}
          {rightContent && rightContent}
          {withPageView && <PageViewToggle />}
        </div>
      </header>
      {withCustomToggleView && <CustomToggleViewContent />}
      {getSettingsButton()}
    </section>
  );
}

DesktopView.defaultProps = {
  isAdvancedSearchOpen: false,
  customToggleViewProps: {},
};

DesktopView.propTypes = {
  className: PropTypes.string,
  appliedFiltersLength: PropTypes.number,
  handleAdvancedSearchToggle: PropTypes.func,
  isAdvancedSearchOpen: PropTypes.bool,
  advancedSearchContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
  searchContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  actionsContent: PropTypes.func,
  onClearAll: PropTypes.func,
  pageView: PropTypes.shape({
    onPageViewChange: PropTypes.func,
    isTablePageView: PropTypes.bool,
  }),
  rightContent: PropTypes.node,
  customToggleViewProps: PropTypes.shape({
    viewContent: PropTypes.node,
    contentClassName: PropTypes.string,
    isCustomViewOpen: PropTypes.bool,
    mobileTitle: PropTypes.string,
    onToggleCustomView: PropTypes.func,
  }),
  settingsButtonOptions: settingsButtonOptionsShape,
};

export default withSearch(DesktopView);
