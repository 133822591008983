import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/core/Label';
import RadioBox from 'components/core/RadioBox';
import LabelGroup from 'components/core/LabelGroup';
import ErrorMessage from 'components/core/ErrorMessage';
import Asterisk from 'components/core/Asterisk';

import customFieldShape from '../../shapes/customField';
import controlStateShape from '../../shapes/controlState';
import normalizeEventTarget from '../../utils/normalizeEventTarget';
import Tooltip from '../../components/Tooltip';
import styles from '../styles.module.scss';

export default function RadioboxControl({ field, state, onChange, tooltipTheme, ...restProps }) {
  const { token, description, options, name, required } = field;
  const { value, error } = state;

  const anyAnswer = option => value.includes(option);

  function handleChange(event) {
    const { target } = event;
    const { value: newValue } = target;
    const targetOptions = { id: token, value: [newValue] };
    const normalizedEvent = normalizeEventTarget(event, targetOptions);

    onChange(normalizedEvent);
  }

  return (
    <>
      <Label htmlFor={token}>
        {name} <Asterisk show={required} />
        <Tooltip content={description} theme={tooltipTheme} />
      </Label>
      <LabelGroup containerClassName={styles.options}>
        {
          options.map(option => (
            <RadioBox
              {...restProps}
              key={`radio-box-${option}`}
              label={option}
              value={option}
              checked={anyAnswer(option)}
              onChange={handleChange}
            />
          ))
        }
      </LabelGroup>
      <ErrorMessage message={error} />
    </>
  );
}

RadioboxControl.propTypes = {
  field: customFieldShape.isRequired,
  state: controlStateShape,
  onChange: PropTypes.func.isRequired,
  tooltipTheme: PropTypes.string,
};
