import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DataField } from 'components/core/Profile/components/KeyDetailsFields';
import { TextPlaceholder } from 'components/core/Placeholder';
import Card from 'components/core/Card';

import Separator from '../../Separator';
import Summary from './components/Summary';
import styles from './styles.module.scss';

function ItemName({
  isLoading,
  name,
  label,
  withSummary,
  recordType,
  recordId,
  customContent,
}) {
  function renderContent() {
    if (isLoading) {
      return <TextPlaceholder />;
    }

    if (customContent) {
      return customContent;
    }

    return (
      <DataField
        label={label}
        value={name}
        containerClassName={styles.titleContainer}
        className={styles.title}
      />
    );
  }

  function renderSummary() {
    if (!withSummary) {
      return null;
    }

    return (
      <>
        <Separator className={styles.separator} />
        <Summary recordType={recordType} recordId={recordId} />
      </>
    );
  }

  const cardClassNames = classNames(
    styles.card,
    withSummary && styles.cardWithSummary,
  );

  return (
    <Card className={cardClassNames}>
      {renderContent()}
      {renderSummary()}
    </Card>
  );
}

ItemName.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  withSummary: PropTypes.bool,
  recordType: PropTypes.string,
  recordId: PropTypes.string,
  customContent: PropTypes.node,
};

export default ItemName;
