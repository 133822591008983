import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';

import styles from '../styles.module.scss';

const CustomDropdownIndicator = (props) => {
  const { DropdownIndicator } = components;

  return (
    DropdownIndicator && (
      <DropdownIndicator {...props}>
        <i className={classNames('icon', 'fas', 'fa-caret-down', styles.dropdownIndicatorIcon)} />
      </DropdownIndicator>
    )
  );
};

export default CustomDropdownIndicator;
