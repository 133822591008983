import React from 'react';
import PropTypes from 'prop-types';

import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import Tooltip from 'components/core/Table/components/Header/components/Tooltip';
import Checkbox from 'components/core/Checkbox';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import ErrorMessage from 'components/core/ErrorMessage';

import TitleInput from './components/TitleInput';
import DescriptionTextArea from './components/DescriptionTextArea';
import defaultLabels from './labels';
import useValidation from './useValidation';
import styles from './styles.module.scss';

function GeneralDetails({
  bus,
  title,
  description,
  isTemplate,
  updateTitle,
  updateDescription,
  updateIsTemplate,
  onFocus,
  labels,
  disableIsTemplate,
  titleCharactersLimit,
  descriptionCharactersLimit,
  enableTooltip,
  isDescriptionRequired,
  isTitleRequired,
  isScrollIntoViewErrors,
}) {
  const { govpredictAdmin } = gon.currentUser;

  const { title: defaultTitleLabel, description: defaultDescriptionLabel, isTemplate: defaultTemplateLabel } = defaultLabels;
  const {
    title: titleLabel = defaultTitleLabel,
    description: descriptionLabel = defaultDescriptionLabel,
    isTemplate: templateLabel = defaultTemplateLabel,
  } = labels;

  const {
    errorTitle,
    errorDescription,
    setErrors,
  } = useValidation({
    description,
    descriptionCharactersLimit,
    isDescriptionRequired,
    title,
    titleCharactersLimit,
    bus,
    isScrollIntoViewErrors,
  });

  const handleTitle = event => updateTitle({ payload: event.target.value });

  const handleDescription = event => updateDescription({ payload: event.target.value });

  function renderLabel(label, isRequired) {
    const { name, desc } = label || {};

    if (enableTooltip) {
      return (
        <div className={styles.tooltipLabel}>
          {name}
          <Tooltip content={desc} />
        </div>
      );
    }

    return (
      <>
        {name}
        <Asterisk show={isRequired} />
        <p>{desc}</p>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.formGroup}>
        <Label id="titleLabel" htmlFor="title" theme="light">
          {renderLabel(titleLabel, isTitleRequired)}
        </Label>
        <TitleInput
          id="title"
          name="title"
          value={title}
          onFocus={onFocus('title', setErrors)}
          onChange={handleTitle}
          isError={!!errorTitle}
          charactersLimit={titleCharactersLimit}
        />
        <ErrorMessage message={errorTitle} />
      </div>

      <div className={styles.formGroup}>
        <Label id="descriptionLabel" htmlFor="description" theme="light">
          {renderLabel(descriptionLabel, isDescriptionRequired)}
        </Label>
        <DescriptionTextArea
          id="description"
          name="description"
          value={description}
          onFocus={onFocus('description', setErrors)}
          onChange={handleDescription}
          isError={!!errorDescription}
          charactersLimit={descriptionCharactersLimit}
        />
        <ErrorMessage message={errorDescription} />
      </div>

      {govpredictAdmin && !disableIsTemplate && (
        <div className={styles.formGroup}>
          <Label id="templateLabel" htmlFor="template" theme="light">
            {templateLabel.name}
          </Label>
          <Checkbox
            id="template"
            label={templateLabel.desc}
            isChecked={isTemplate}
            onChange={updateIsTemplate}
          />
        </div>
      )}
    </div>
  );
}

GeneralDetails.defaultProps = {
  labels: defaultLabels,
  isDescriptionRequired: true,
  isTitleRequired: true,
  isScrollIntoViewErrors: true,
};

GeneralDetails.propTypes = {
  bus: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  updateTitle: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired,
  updateIsTemplate: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  labels: PropTypes.object,
  disableIsTemplate: PropTypes.bool,
  titleCharactersLimit: PropTypes.number,
  descriptionCharactersLimit: PropTypes.number,
  enableTooltip: PropTypes.bool,
  isDescriptionRequired: PropTypes.bool,
  isTitleRequired: PropTypes.bool,
  isScrollIntoViewErrors: PropTypes.bool,
};

export default withSmartSearch(GeneralDetails);
