import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStickyNode from 'components/core/StickyContainer/useStickyNode';
import { canRenderTopBar } from 'components/core/ProductBar/utils';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { PRODUCT_BAR_HEIGHT } from 'utils/constants';

import styles, { largeNavHeaderHeight } from './styles.module.scss';

const ref = createRef();
const largeNavHeaderHeightParsed = parseInt(largeNavHeaderHeight, 10);

function StickyContainer({ children, className, contentClassName, offsetHeight = largeNavHeaderHeightParsed }) {
  const { isMobile } = useMediaQuery();
  const withProductBar = canRenderTopBar(isMobile);
  const topBarOffset = withProductBar ? PRODUCT_BAR_HEIGHT : 0;
  const isSticky = useStickyNode({ ref, offsetHeight: offsetHeight + topBarOffset });

  return (
    <section ref={ref} className={classNames(styles.container, className)}>
      <div className={classNames(styles.wrapper, isSticky && styles.isSticky, withProductBar && styles.withProductBar)}>
        <div className={classNames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </section>
  );
}

StickyContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  offsetHeight: PropTypes.number,
};

export default StickyContainer;
