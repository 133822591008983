import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, compact } from 'lodash';

import FederalBillsLegend from './FederalBillsLegend';
import styles from './styles.module.scss';

function Legend({ limits, mapColors, ...restProps }) {
  if (isEmpty(limits)) {
    return null;
  }

  const limitValues = compact(limits);

  function renderLegendItem(limit, index) {
    const iconStyles = { backgroundColor: mapColors[index + 1] };

    const minLimit = limits[index] + 1;
    const maxLimit = limit;
    const range = minLimit === maxLimit ? minLimit : `${minLimit} - ${maxLimit}`;

    return (
      <li key={index}>
        <span style={iconStyles} />
        {range}
      </li>
    );
  }

  return (
    <div className={styles.container}>
      <ul className={styles.legend}>
        {limitValues.map(renderLegendItem)}
      </ul>
      <FederalBillsLegend
        {...restProps}
        limits={limits}
        mapColors={mapColors}
      />
    </div>
  );
}

Legend.defaultProps = {
  limits: [],
};

Legend.propTypes = {
  limits: PropTypes.arrayOf(PropTypes.number),
  mapColors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Legend;
