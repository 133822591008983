import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { includes, size, filter } from 'lodash';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import { BaseOption } from 'components/core/AdvancedSearch';
import Label from 'components/core/Label';
import withStatesAPI from 'utils/hoc/withStatesAPI';

import styles from './styles.module.scss';

function AllStatesSelectField({
  keyName,
  titles,
  value,
  onChange,
  className,
  checkboxLabel,
  allStates,
  filterStates,
}) {
  const [isChecked, setChecked] = useState(false);
  const filteredAllStates = filter(allStates, state => !includes(filterStates, state.value));
  const isAllChecked = size(filteredAllStates) > 0 && size(value) === size(filteredAllStates);

  function handleChange(currentValue) {
    onChange(keyName, currentValue);
    setChecked(false);
  }

  function handleCheckAll() {
    isChecked
      ? onChange(keyName, [])
      : onChange(keyName, filteredAllStates);
    setChecked(!isChecked);
  }

  return (
    <>
      {titles && titles.title && (
        <Label className={styles.allStatesTitle}>
          {titles.title}
        </Label>
      )}
      <Checkbox
        label={checkboxLabel}
        key="include_all_states"
        isChecked={isAllChecked}
        onChange={handleCheckAll}
        className={styles.allStatesSelectCheckbox}
      />
      <BaseOption
        className={classNames('form-group', className)}
        name={keyName}
        value={value}
        options={filteredAllStates}
        onChange={handleChange}
        isSelect
        isClearable
        multi
        noGroupClassName
      />
    </>
  );
}

AllStatesSelectField.defaultProps = {
  checkboxLabel: 'Include all states',
  filterStates: ['AS', 'GU', 'MP', 'PR', 'UM', 'VI'],
};

AllStatesSelectField.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.objectOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  checkboxLabel: PropTypes.string,
  allStates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  filterStates: PropTypes.arrayOf(PropTypes.string),
};

export default withStatesAPI(AllStatesSelectField);
