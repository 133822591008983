import { StyleSheet } from '@react-pdf/renderer';

import { LABEL_FIELD_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  description: {
    color: LABEL_FIELD_COLOR,
    fontSize: 8,
    fontWeight: 'normal',
    marginBottom: 10,
    overflow: 'visible',
  },
  link: {
    color: 'black',
    fontSize: 8,
    textOverflow: 'wrap',
  },
});

export default styles;
