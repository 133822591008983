const tableColumns = [
  {
    label: 'Vote number',
    keyName: 'voteNumber',
    sortingKey: 'display_number.raw',
    allowToggle: true,
  },
  {
    label: 'Vote date',
    keyName: 'voteDate',
    sortingKey: 'vote_date', // Also change on useBillsAndVotes.js
    allowToggle: true,
  },
  {
    label: 'Issue',
    keyName: 'billName',
    sortingKey: 'bill_display_id.raw',
    allowToggle: true,
  },
  {
    label: 'Question',
    keyName: 'voteQuestion',
    sortingKey: 'question.raw',
    allowToggle: true,
  },
  {
    label: 'Vote',
    keyName: 'voteType',
    sortingKey: 'legislator_vote',
    allowToggle: true,
  },
  {
    label: 'Result',
    keyName: 'result',
    sortingKey: 'result_with_numbers.raw',
    allowToggle: true,
  },
];

export default tableColumns;
