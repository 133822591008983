import { useState, useEffect } from 'react';
import { isNil, isEmpty, size, head, indexOf } from 'lodash';

const SETTINGS = 0;
const ITEMS = 1;
const COLUMNS = 2;

function useSteps({ settings, itemsSelected, selectedColumns }) {
  const [currentStep, setCurrentStep] = useState(SETTINGS);
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    setCurrentItem(head(itemsSelected));
  }, [size(itemsSelected)]);

  const isValid = () => {
    if (currentStep === SETTINGS && !isNil(settings.title)) {
      if (isEmpty(settings.title)) return false;
    }

    if (currentStep === ITEMS) {
      if (isEmpty(itemsSelected)) return false;
    }

    if (currentStep === COLUMNS) {
      const columns = selectedColumns[currentItem.value];

      if (isEmpty(columns)) return false;
    }

    return true;
  };

  const isFirstStep = () => currentStep === SETTINGS;

  const isLastStep = () => {
    if (currentStep === SETTINGS && settings.chooseColumns) {
      return false;
    }

    if (currentStep === ITEMS) {
      return false;
    }

    if (currentStep === COLUMNS) {
      const index = indexOf(itemsSelected, currentItem);
      const isLast = index === (size(itemsSelected) - 1);

      if (!isLast) {
        return false;
      }
    }

    return true;
  };

  const previousColumnStep = () => {
    const index = indexOf(itemsSelected, currentItem);
    const isFirst = index === 0;

    if (isFirst) {
      setCurrentStep(currentStep - 1);
    } else {
      const newCurrentItem = itemsSelected[index - 1];
      setCurrentItem(newCurrentItem);
    }
  };

  const nextColumnStep = () => {
    const index = indexOf(itemsSelected, currentItem);

    const newCurrentItem = itemsSelected[index + 1];
    setCurrentItem(newCurrentItem);
  };

  const previousStep = () => {
    if (currentStep === COLUMNS) {
      previousColumnStep();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep === COLUMNS) {
      nextColumnStep();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return {
    isValid: isValid(),
    isFirstStep: isFirstStep(),
    isLastStep: isLastStep(),
    previousStep,
    nextStep,
    currentItem,
    currentStep,
  };
}

export default useSteps;
