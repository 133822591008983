import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from 'components/core/Loader';

import Modal from '../Modal';
import CloseConfirmation from '../CloseConfirmation';
import FooterActions from './components/FooterActions';
import useMultiStepModal from './useMultiStepModal';
import styles from './styles.module.scss';

function MultiStepModal({
  isOpen,
  toggleOpen,
  handleAction,
  steps,
  isLoading,
  withCloseConfirmation,
  customCloseMessage,
  onClose,
  onReset,
  currentStep,
  resetStep,
  onChangeCurrentStep,
  CustomButtons,
  title,
  theme,
  className,
  isSaving,
  shouldResetStepOnClose,
  modalStepClassName,
  modalHeaderClassName,
  modalBodyClassName,
  modalFooterClassName,
  showLoadingPlaceholder,
}) {
  const {
    modalTitle,
    isLastStep,
    currentStepIndex,
    isCloseConfirmOpen,
    handleStepClick,
    handleCloseModal,
    handleNextStep,
    handlePrevStep,
    exitCloseConfirm,
    returnCloseConfirm,
  } = useMultiStepModal({
    steps,
    toggleOpen,
    withCloseConfirmation,
    onClose,
    onReset,
    currentStep,
    resetStep,
    onChangeCurrentStep,
    title,
    isOpen,
    shouldResetStepOnClose,
  });

  function renderModalContent() {
    if (showLoadingPlaceholder && isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader message="Loading ..." />
        </div>
      );
    }

    return steps.map(({ index, component }) => {
      const isActive = currentStepIndex === index;

      return (
        <div
          key={`step-${index}`}
          className={classNames(styles.step, isActive && styles.isActive, modalStepClassName)}
          role="tab"
        >
          {component}
        </div>
      );
    });
  }

  const buttons = (
    <FooterActions
      steps={steps}
      currentStepIndex={currentStepIndex}
      isLoading={isLoading}
      isSaving={isSaving}
      isLastStep={isLastStep}
      onSubmit={handleAction}
      onStepClick={handleStepClick}
      onNextStep={handleNextStep}
      onPrevStep={handlePrevStep}
      onCloseModal={handleCloseModal}
      CustomButtons={CustomButtons}
    />
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={modalTitle}
        buttons={buttons}
        toggleOpen={handleCloseModal}
        theme={theme}
        bodyClassName={classNames(styles.body, modalBodyClassName)}
        headerClassName={classNames(styles.header, modalHeaderClassName)}
        footerClassName={classNames(styles.footer, modalFooterClassName)}
        className={classNames(styles.multiStepModal, className)}
      >
        {renderModalContent()}
      </Modal>

      {withCloseConfirmation && (
        <CloseConfirmation
          toggleOpen={exitCloseConfirm}
          onReturn={returnCloseConfirm}
          isOpen={isCloseConfirmOpen}
          customMessage={customCloseMessage}
        />
      )}
    </>
  );
}

MultiStepModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  withCloseConfirmation: PropTypes.bool,
  toggleOpen: PropTypes.func,
  handleAction: PropTypes.func,
  onChangeCurrentStep: PropTypes.func,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  currentStep: PropTypes.number,
  resetStep: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      index: PropTypes.number,
      component: PropTypes.node,
    })
  ).isRequired,
  title: PropTypes.string,
  CustomButtons: PropTypes.elementType,
  theme: PropTypes.string,
  className: PropTypes.string,
  modalStepClassName: PropTypes.string,
  modalHeaderClassName: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  modalFooterClassName: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  customCloseMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  shouldResetStepOnClose: PropTypes.bool,
  showLoadingPlaceholder: PropTypes.bool,
};

MultiStepModal.defaultProps = {
  withCloseConfirmation: false,
  showLoadingPlaceholder: true,
  theme: 'scrollable',
};

export default MultiStepModal;
