import { withFormik } from 'formik';
import { map, size } from 'lodash';

import Notifications from 'components/core/CustomFields/utils/notifications';

import Form from './components/Form';
import validationSchema from './validationSchema';

function onSubmitForm(values, actions) {
  const { toggleModal, updateCustomField } = actions.props;
  const {
    token,
    name,
    options,
    enabled,
    fieldDescription: description,
    productSlugs,
    required,
  } = values;

  const customField = {
    name,
    description,
    product_slugs: map(productSlugs, 'value'),
    options: map(options, ({ initialValue, value }) => ({
      old_value: initialValue,
      new_value: value,
    })),
    required,
  };

  updateCustomField(token, customField, toggleModal, enabled)
    .then(Notifications.customFieldUpdated)
    .catch(Notifications.customFieldFailed);
}

const EditForm = withFormik({
  validationSchema,
  mapPropsToValues: ({ customField }) => {
    const { options, ...restCustomField } = customField || {};

    return ({
      ...restCustomField,
      initialOptionsLength: size(options),
      options: map(options, ({ value }) => ({
        initialValue: value,
        value,
      })),
    });
  },
  enableReinitialize: false,
  handleSubmit: onSubmitForm,
})(Form);

export default EditForm;
