import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Link from 'components/core/Link';
import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles.module.scss';

function LinkItem({
  value,
  keyName,
  item,
  isExternalLink,
  className,
  href,
  label,
  labelId,
  prefix,
  isValueLink,
  onClick,
  isRouterLink,
}) {
  const { url } = item;
  const linkUrl = isValueLink ? value : (url || href);
  const labelElement = item[labelId] && label && <span>({ label })</span>;

  function renderLink() {
    if (isEmpty(value) || value === EMPTY_VALUE) {
      return EMPTY_VALUE;
    }

    if (isEmpty(linkUrl)) {
      return (
        <div className={classNames(styles.column, className)}>
          {value}
          {labelElement}
        </div>
      );
    }

    const customProps = {
      ...(isExternalLink && {
        target: '_blank',
        rel: 'noopener noreferrer',
      }),
    };

    const mountedUrl = `${prefix}${linkUrl}`;

    return (
      <div className={classNames(styles.column, className)}>
        <Link
          {...customProps}
          isRouterLink={isRouterLink}
          theme="link"
          to={mountedUrl}
          href={mountedUrl}
          className={styles.link}
          onClick={onClick}
        >
          {value}
        </Link>
        {labelElement}
      </div>
    );
  }

  return <Column key={keyName} keyName={keyName}>{renderLink}</Column>;
}

LinkItem.defaultProps = {
  item: {},
  isExternalLink: false,
  isValueLink: false,
  prefix: '',
  onClick: () => {},
  isRouterLink: false,
};

LinkItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    url: PropTypes.string,
  }),
  value: PropTypes.node,
  className: PropTypes.string,
  isExternalLink: PropTypes.bool,
  isValueLink: PropTypes.bool,
  label: PropTypes.string,
  labelId: PropTypes.string,
  href: PropTypes.string,
  prefix: PropTypes.string,
  onClick: PropTypes.func,
  isRouterLink: PropTypes.bool,
};

export default LinkItem;
