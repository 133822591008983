import { isEmpty, isString, truncate, upperFirst } from 'lodash';

import { getViewUrl } from 'components/Dashboard/Workspaces/utils';

import { DESCRIPTION_LIMIT, MOBILE_DESCRIPTION_LIMIT } from './constants';

function usePush({
  id,
  subscriptionToken,
  description,
  url,
  isMobile,
  customDescriptionLimit,
  feedToken,
}) {
  const eventUrl = `${getViewUrl(feedToken)}/recent/${id}`;
  const subscriptionUrl = subscriptionToken
    ? `${getViewUrl(feedToken)}?keywordId=${subscriptionToken}`
    : '';

  const descriptionLimit = isMobile
    ? MOBILE_DESCRIPTION_LIMIT
    : customDescriptionLimit || DESCRIPTION_LIMIT;
  const showDescription = !isEmpty(description);
  const formattedDescription = isString(description)
    ? upperFirst(truncate(description, { length: descriptionLimit }))
    : description;

  return {
    eventUrl,
    detailsUrl: subscriptionUrl || url,
    showDescription,
    formattedDescription,
  };
}

export default usePush;
