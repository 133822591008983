import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/core/Loader';
import Button from 'components/core/Button';
import FieldsOptions from 'components/core/CustomFields/components/FieldsOptions';
import { CustomFieldsContext } from 'components/core/CustomFields/utils/withCustomField';
import {
  uiShape,
  errorsShape,
  touchedShape,
  valuesShape,
} from 'components/core/CustomFields/utils/shapes';

import FieldInfoConsumer from './FieldInfo';
import styles from '../styles.module.scss';

function Form({
  ui,
  toggleModal,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
}) {
  const editFormContext = {
    ...values,
    ui,
    handleChange,
    handleBlur,
    setFieldValue,
    formState: { errors, touched },
  };

  const loader = <Loader size="small" theme="light" className={styles.loadingBtn} />;
  const buttonSubmitContent = ui.isUpdatingCustomField ? loader : 'Save';

  return (
    <form onSubmit={handleSubmit} className={styles.modal}>
      <CustomFieldsContext.Provider value={editFormContext}>
        <FieldInfoConsumer />
        <FieldsOptions />
      </CustomFieldsContext.Provider>

      <div className={styles.modalActions}>
        <Button
          type="reset"
          theme="light"
          size="small"
          onClick={toggleModal}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="small"
        >
          {buttonSubmitContent}
        </Button>
      </div>
    </form>
  );
}

Form.propTypes = {
  ui: uiShape.isRequired,
  errors: errorsShape,
  touched: touchedShape,
  values: valuesShape.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Form;
