import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import store from 'redux/store';

import Container from './components/Container';
import { NOTIFICATIONS_DOM_ID } from './utils/constants';

function Notifications({ id, ...restProps }) {
  return (
    <div id={id}>
      <Provider store={store}>
        <Container {...restProps} />
      </Provider>
    </div>
  );
}

Notifications.defaultProps = {
  id: NOTIFICATIONS_DOM_ID,
};

Notifications.propTypes = {
  id: PropTypes.string,
};

export default Notifications;
