import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import cardGroupStyles from 'components/core/CardGroup/styles.module.scss';
import cardStyles from 'components/core/Card/styles.module.scss';

import BadgeInfoPlaceholder from './BadgeInfoPlaceholder';
import styles from './styles.module.scss';

function CardGroupPlaceholder({ isMobile, senate, expired, isFederal }) {
  const checkbox = (
    <div className={classNames(styles.checkbox, cardGroupStyles.checkbox)} />
  );

  const actions = (
    <div className={classNames(styles.actions, cardGroupStyles.actions)}>
      <div className={classNames(styles.hintBtn)} />
    </div>
  );

  const details = (
    <div className={styles.details}>
      <p><span className={styles.title} /><span className={styles.text} /></p>
      <p><span className={styles.title} /><span className={styles.text} /></p>
      <p><span className={styles.title} /><span className={styles.link} /></p>
    </div>
  );

  const cardClassNames = classNames(
    styles.cardGroup,
    cardGroupStyles.cardGroup,
    expired && styles.expiredCard
  );

  if (isMobile) {
    return (
      <div className={cardClassNames}>
        <div className={classNames(cardStyles.card, cardGroupStyles.header)}>
          {checkbox}
          <div className={cardGroupStyles.headerContent}>
            <BadgeInfoPlaceholder
              senate={senate}
              expired={expired}
              isMobile={isMobile}
              isFederal={isFederal}
            />
          </div>
          {actions}
        </div>
        <div className={classNames(cardStyles.card, cardGroupStyles.content)}>
          {details}
        </div>
      </div>
    );
  }

  return (
    <div className={cardClassNames}>
      {checkbox}

      <div className={classNames(styles.card, cardStyles.card, cardGroupStyles.content)}>
        <BadgeInfoPlaceholder
          senate={senate}
          expired={expired}
          isMobile={isMobile}
          isFederal={isFederal}
        />
        {details}
      </div>

      {actions}
    </div>
  );
}

CardGroupPlaceholder.propTypes = {
  isMobile: PropTypes.bool,
  senate: PropTypes.bool,
  expired: PropTypes.bool,
  isFederal: PropTypes.bool,
};

export default CardGroupPlaceholder;
