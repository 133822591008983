import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/core/Modal';
import withSearch from 'components/core/SearchHeader/utils/withSearch';

import styles from '../styles.module.scss';

function Content({ customToggleViewProps, isMobile }) {
  const {
    viewContent,
    contentClassName,
    isCustomViewOpen,
    mobileTitle,
    onToggleCustomView,
  } = customToggleViewProps;

  if (!isCustomViewOpen) {
    return null;
  }

  if (isMobile) {
    return (
      <Modal
        title={mobileTitle}
        isOpen={isCustomViewOpen}
        theme="scrollableBody"
        className={styles.modal}
        bodyClassName={styles.contentModalBody}
        toggleOpen={onToggleCustomView}
      >
        {viewContent}
      </Modal>
    );
  }

  return (
    <div className={classNames(styles.content, contentClassName)}>
      {viewContent}
    </div>
  );
}

Content.propTypes = {
  customToggleViewProps: PropTypes.shape({
    viewContent: PropTypes.node.isRequired,
    contentClassName: PropTypes.string,
    isCustomViewOpen: PropTypes.bool.isRequired,
    mobileTitle: PropTypes.string.isRequired,
    onToggleCustomView: PropTypes.func.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withSearch(Content);
