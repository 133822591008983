import React from 'react';
import PropTypes from 'prop-types';

import Collapse from './Collapse';

function Item(props) {
  const { isOpen, children, className, renderTitle, renderContent, animationTimeout, noAnimation, id } = props;
  const tagId = id ? `accordion__item-${id}` : null;

  if (noAnimation) {
    return (
      <div className={className} id={tagId}>
        {renderTitle({ ...props })}

        {isOpen && (children || renderContent({ ...props }))}
      </div>
    );
  }

  return (
    <div className={className} id={tagId}>
      {renderTitle({ ...props })}
      <Collapse animationTimeout={animationTimeout} isOpen={isOpen}>
        {children || renderContent({ ...props })}
      </Collapse>
    </div>
  );
}

Item.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  renderTitle: PropTypes.func,
  renderContent: PropTypes.func,
  animationTimeout: PropTypes.number,
  noAnimation: PropTypes.bool,
  id: PropTypes.string,
};

Item.defaultProps = {
  isOpen: false,
  className: '',
  noAnimation: false,
  id: null,
};

export default Item;
