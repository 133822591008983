import React from 'react';

import { TextPlaceholder } from 'components/core/Placeholder';

import styles from './styles.module.scss';

function CommentPlaceholder() {
  return (
    <div className={styles.container}>
      <TextPlaceholder className={styles.avatar} />
      <div className={styles.column}>
        <div className={styles.content}>
          <TextPlaceholder className={styles.user} />
          <TextPlaceholder className={styles.bigLine} />
          <TextPlaceholder className={styles.smallLine} />
        </div>
        <TextPlaceholder className={styles.reply} />
      </div>
    </div>
  );
}

export default CommentPlaceholder;
