import React from 'react';
import PropTypes from 'prop-types';

import Paginator from 'components/core/Paginator';

function TablePaginator({
  isMobile,
  isLoading,
  isPaginatorPresent,
  isTablePaginator,
  paginator,
  onChangePage,
  className,
}) {
  if (!isPaginatorPresent) {
    return null;
  }

  const { current, total, totalCount, pageSize } = paginator;

  return (
    <Paginator
      className={className}
      isTablePaginator={isTablePaginator}
      isMobile={isMobile}
      isLoading={isLoading}
      current={current}
      total={total}
      pageSize={pageSize}
      totalItems={totalCount}
      onChange={onChangePage}
    />
  );
}

TablePaginator.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPaginatorPresent: PropTypes.bool,
  isTablePaginator: PropTypes.bool,
  paginator: PropTypes.object,
  onChangePage: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TablePaginator;
