import React from 'react';
import PropTypes from 'prop-types';
import { map, filter, includes } from 'lodash';

import LabelGroup from 'components/core/LabelGroup';
import CoreCheckbox from 'components/core/Checkbox';

import SelectAll from '../SelectAll';
import styles from '../styles.module.scss';
import Item from './components/Item';
import { itemShape, valuesShape } from './utils/shapes';

function Checkbox({
  withoutBorders,
  withStars,
  withSelectAll,
  id,
  items,
  values,
  onChange,
  isSingleSelection,
}) {
  const handleChange = item => ({ target: { checked } }) => {
    isSingleSelection
      ? onChange(id, checked ? item.id : '')
      : onChange(
        id, checked ? [...values, item.id] : filter(values, valueItem => valueItem !== item.id)
      );
  };

  const renderLabel = (labelProps) => {
    const { label } = labelProps || {};

    if (withStars) {
      return <Item {...labelProps} />;
    }

    return label;
  };

  const renderCheckbox = (item) => {
    const { id: checkboxId, label, disabled } = item || {};
    const isChecked = isSingleSelection ? values === checkboxId : includes(values, checkboxId);

    return (
      <CoreCheckbox
        key={checkboxId}
        isDisabled={disabled}
        isChecked={isChecked}
        label={renderLabel({ label, isChecked })}
        className={withStars && styles.checkboxWithStars}
        onChange={handleChange(item)}
      />
    );
  };

  return (
    <LabelGroup
      containerClassName={styles.item}
      className={withoutBorders && styles.checkboxWithoutBorders}
    >
      {withSelectAll && (
        <SelectAll
          id={id}
          values={values}
          items={items}
          selector="id"
          onChange={onChange}
        />
      )}
      {map(items, renderCheckbox)}
    </LabelGroup>
  );
}

Checkbox.defaultProps = {
  isSingleSelection: false,
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemShape).isRequired,
  values: valuesShape.isRequired,
  onChange: PropTypes.func.isRequired,
  withoutBorders: PropTypes.bool,
  withStars: PropTypes.bool,
  withSelectAll: PropTypes.bool,
  isSingleSelection: PropTypes.bool,
};

export default Checkbox;
