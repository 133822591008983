import { useEffect, useState } from 'react';

function useTableReceipts({ searchParams }) {
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [receiptModalId, setReceiptModalId] = useState('');

  function handleReceiptModal(receiptId) {
    const newReceiptId = Number(receiptId);

    Number.isInteger(newReceiptId) && setReceiptModalId(receiptId);
    setIsReceiptModalOpen(!isReceiptModalOpen);
  }

  useEffect(() => {
    if (searchParams) {
      const { receiptId } = searchParams || {};

      receiptId && handleReceiptModal(receiptId);
    }
  }, []);

  return {
    isReceiptModalOpen,
    receiptModalId,
    handleReceiptModal,
  };
}

export default useTableReceipts;
