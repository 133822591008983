import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import styles from './styles.module.scss';

function Options({ isMobile, displayHeader, optionsNode, title }) {
  if (isEmpty(optionsNode)) {
    return null;
  }

  const showHeader = !isMobile && displayHeader;

  return (
    <>
      {showHeader && (
        <h2 className={styles.title}>
          {title}
        </h2>
      )}

      <div className={styles.content}>
        {optionsNode}
      </div>
    </>
  );
}

Options.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  displayHeader: PropTypes.bool.isRequired,
  optionsNode: PropTypes.node,
};

export default withSearch(Options);
