import React from 'react';
import { upperFirst, toLower, get, uniq, sortBy, map } from 'lodash';

import { LinkColumn } from 'components/core/Profile/components/Table';
import { getValidPhones } from 'components/core/Profile/utils';
import { joinCompact, getParenthesisItem } from 'utils/string';

import { getStateName } from '../../utils';

export const getPhoneLabel = ({ type, displayOrder } = {}) => (
  `${upperFirst(toLower(type))} ${displayOrder}`
);

export const getPhone = ({ phoneNumber, areaCode } = {}) => joinCompact(
  [getParenthesisItem(areaCode), phoneNumber],
  ' ',
);

const formatOrganizations = organizations => map(sortBy(organizations, 'level'), 'name');

export const getPositionDescription = (position) => {
  const { title, role, organizations, additionalInfo } = position || {};
  const { branch, department } = additionalInfo || {};

  return (
    <>
      <div data-testid="title-role">{joinCompact(uniq([title, role]))}</div>
      <div data-testid="position-details">
        {joinCompact(uniq([branch, department, ...formatOrganizations(organizations)]))}
      </div>
    </>
  );
};

export const getPositionDescriptionForPdf = (position) => {
  const { title, role, organizations, additionalInfo } = position || {};
  const { branch, department, county, municipal, district } = additionalInfo || {};

  const areaInfo = getParenthesisItem(
    joinCompact(uniq([county, municipal, district]))
  );

  const formattedBranch = joinCompact([branch, areaInfo], ' ');

  return joinCompact(
    uniq([title, role, ...formatOrganizations(organizations), department, formattedBranch])
  );
};

export const getName = (fullName, mainPosition) => {
  const { additionalInfo } = mainPosition || {};
  const { county, municipal, district } = additionalInfo || {};
  const additionalInfoText = getParenthesisItem(
    joinCompact(uniq([county, municipal, district]))
  );

  return joinCompact([fullName, additionalInfoText], ' ');
};

export const getAddress = ({ zipCode, street, city, state = {} } = {}) => {
  const stateName = getStateName(get(state, 'name'));
  const address = joinCompact([street, city, stateName, zipCode]);

  return address || undefined;
};

export const getAddressTable = ({ building, ...address } = {}) => {
  const displayedAddress = getAddress(address);

  return {
    id: 'location',
    data: [
      {
        id: 'address',
        label: 'Address',
        content: displayedAddress,
      },
      {
        id: 'building',
        label: 'Building',
        content: building,
      },
    ],
  };
};

export const getPhoneTable = phones => ({
  id: 'phone',
  columns: [{
    id: 'link',
    component: LinkColumn,
    getUrl: getPhone,
  }],
  data: getValidPhones(phones),
  getLabel: getPhoneLabel,
});
