import React from 'react';

import { joinCompact } from 'utils/string';
import { electionInfoShape } from 'components/core/Profile/utils/shapes';

import styles from './styles.module.scss';

export default function ElectionInfo({ electionInfo }) {
  const { percentVote, term } = electionInfo || {};

  const hasPercentVote = Boolean(Number(percentVote));

  if (!hasPercentVote && !term) return null;

  const numberSuffix = { 1: 'st', 2: 'nd', 3: 'rd' }[term] || 'th';

  const termInfo = term && `${term}${numberSuffix} term`;
  const percentageInfo = hasPercentVote && `${percentVote}% of vote`;

  return (
    <span className={styles.electionInfo}>
      ({joinCompact([termInfo, percentageInfo])})
    </span>
  );
}

ElectionInfo.propTypes = {
  electionInfo: electionInfoShape,
};
