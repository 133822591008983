import React from 'react';
import PropTypes from 'prop-types';

import { BaseDocument } from 'components/core/ExportDocument';
import {
  ProfilePageLayout,
  YourActivity,
} from 'components/core/Profile/components/Export/components/DocumentLayouts';
import { getFullName } from 'components/core/Profile/utils';
import { personShape } from 'components/core/Profile/utils/shapes';
import Header from 'components/core/Profile/components/Export/components/Header';
import {
  cardShape,
} from 'components/core/Profile/components/Export/components/DocumentLayouts/YourActivity/utils/shapes';
import {
  Awards,
  Education,
  EmploymentHistory,
  Membership,
  MilitaryService,
  PersonalDetails,
  PoliticalHistory,
  Positions,
  Publications,
  WebAddresses,
} from 'components/core/Profile/components/Export';
import { getPositionDescriptionForPdf } from 'components/core/Profile/components/Cards/Positions/utils';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';

import { displayShape } from '../../utils/shapes';
import { getPartyBadge } from '../../utils';

function ExportDocument({
  person,
  logoUrl,
  display,
  timelineCards,
  documentTitle,
  footerName,
  hasAvatar,
  tags,
  profileUrl,
}) {
  const { yourActivity: displayActivity, profile: displayProfile } = display;
  const { avatarUrl, party, positions } = person || {};
  const fullName = getFullName(person);
  const [primaryPosition] = positions || [];
  const description = getPositionDescriptionForPdf(primaryPosition);

  const partyBadge = getPartyBadge(party);

  return (
    <BaseDocument>
      <ProfilePageLayout
        documentTitle={documentTitle}
        logoUrl={logoUrl || undefined}
        footerName={footerName}
        isPageNumbersVisible
      >
        <Header
          avatarUrl={avatarUrl}
          label={fullName}
          partyBadge={partyBadge}
          description={description}
          hasAvatar={hasAvatar}
          tags={tags}
          profileUrl={profileUrl}
        />
        {displayProfile && (
          <>
            <Positions positions={positions} />
            <WebAddresses person={person} />
            <PoliticalHistory person={person} />
            <EmploymentHistory person={person} />
            <Education person={person} />
            <MilitaryService person={person} />
            <Membership person={person} />
            <Publications person={person} />
            <Awards person={person} />
            <PersonalDetails person={person} />
          </>
        )}
        {displayActivity && <YourActivity cards={timelineCards} />}
      </ProfilePageLayout>
    </BaseDocument>
  );
}

ExportDocument.propTypes = {
  person: personShape.isRequired,
  logoUrl: PropTypes.string,
  display: displayShape,
  timelineCards: PropTypes.arrayOf(cardShape),
  documentTitle: PropTypes.string,
  footerName: PropTypes.string,
  hasAvatar: PropTypes.bool,
  tags: PropTypes.arrayOf(tagShape),
  profileUrl: PropTypes.string,
};

export default ExportDocument;
