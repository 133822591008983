import { isEmpty } from 'lodash';

export const CommentsRepository = comments => ({
  deleteById: commentId => (
    comments.filter(comment => comment.id !== commentId)
  ),
  updateComment: (updatedComment) => {
    const replaceComment = comment => (
      comment.id === updatedComment.id
        ? updatedComment
        : comment
    );

    return comments.map(replaceComment);
  },
  prependComments: newComment => ([
    ...newComment,
    ...comments,
  ]),
  appendComments: newComments => ([
    ...comments,
    ...newComments,
  ]),
  getOldestCreatedAt: () => (
    isEmpty(comments) ? undefined : comments[comments.length - 1].createdAt
  ),
});
