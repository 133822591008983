import PropTypes from 'prop-types';

import { addressShape, phoneShape } from 'components/core/Profile/utils/shapes';

const positionShape = PropTypes.shape({
  title: PropTypes.string,
  role: PropTypes.string,
  address: addressShape,
  phone: phoneShape,
});

const executiveStafferShape = PropTypes.shape({
  positions: PropTypes.arrayOf(positionShape),
});

export const stafferShape = PropTypes.oneOfType([
  executiveStafferShape,
  PropTypes.shape({}), // TODO: add other staffer shapes
]);

export const uiShape = PropTypes.shape({
  isLoading: PropTypes.bool,
});
