import PropTypes from 'prop-types';

export const sortOptionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const sortShape = PropTypes.shape({
  sort: PropTypes.string,
  direction: PropTypes.string,
  toReset: PropTypes.bool,
});
