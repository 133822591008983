import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

const themes = {
  default: styles.whiteTheme,
  grey: styles.greyTheme,
};

function ViewMoreButton({
  disabled,
  customContent,
  isVisible,
  openText,
  closeText,
  onClick,
  isOpen,
  className,
  buttonClassName,
  theme,
}) {
  if (!isVisible) {
    return null;
  }

  const iconClassNames = classNames(
    'fal',
    isOpen ? 'fa-chevron-double-up' : 'fa-chevron-double-down',
    styles.icon,
  );

  const iconContent = (<i className={iconClassNames} />);
  const buttonText = isOpen ? closeText : openText;

  function renderContent() {
    if (customContent) {
      return customContent;
    }

    return (
      <Fragment>
        {iconContent}
        {buttonText}
        {iconContent}
      </Fragment>
    );
  }

  return (
    <div className={classNames(styles.viewMore, themes[theme], className)}>
      <Button
        theme="light"
        onClick={onClick}
        disabled={disabled}
        className={classNames(styles.button, buttonClassName)}
      >
        {renderContent()}
      </Button>
    </div>
  );
}

ViewMoreButton.defaultProps = {
  isVisible: true,
  isOpen: false,
  openText: 'Show more',
  closeText: 'Show less',
  theme: 'default',
};

ViewMoreButton.propTypes = {
  openText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  closeText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  customContent: PropTypes.node,
  theme: PropTypes.oneOf(Object.keys(themes)),
};

export default ViewMoreButton;
