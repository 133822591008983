import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import themes from '../themes.module.scss';
import styles from '../styles.module.scss';

function LabelDot({
  isActive,
  isLast,
  isFirst,
  isFailed,
  label,
  className,
  labelClassName,
}) {
  const containerClassName = classNames(
    styles.labelDot,
    isFirst && styles.firstLabelDot,
    isActive && themes.activeLabelDot,
    isFailed && themes.failedDot,
    className,
  );
  const labelClassNames = classNames(
    isLast ? styles.lastLabel : styles.label,
    labelClassName,
  );

  return (
    <div className={containerClassName}>
      {isFailed && <i className={classNames('far fa-times-circle', styles.failedIcon)} />}
      <div className={labelClassNames}>{label}</div>
    </div>
  );
}

LabelDot.propTypes = {
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isActive: PropTypes.bool,
  isFailed: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default LabelDot;
