import { useState } from 'react';
import { useListener } from 'react-bus';
import { isEmpty, findKey, size } from 'lodash';

import scrollIntoViewElementById from 'utils/scrollIntoViewElementById';

const isTextInvalid = (text, charactersLimit) => (!charactersLimit
  ? isEmpty(text)
  : (isEmpty(text) || size(text) > charactersLimit));

const errorMessages = {
  title: 'Please add a Title.',
  description: 'Please add a Description.',
};

const useValidation = ({
  description,
  descriptionCharactersLimit,
  isDescriptionRequired,
  title,
  titleCharactersLimit,
  bus,
  isScrollIntoViewErrors,
}) => {
  const [errors, setErrors] = useState({});
  const { title: errorTitle, description: errorDescription } = errors || {};

  const validateDescription = () => (isDescriptionRequired
    ? !isTextInvalid(description, descriptionCharactersLimit)
    : (!isTextInvalid(description, descriptionCharactersLimit) || isEmpty(description)));

  const validate = () => {
    const validator = {
      ...(isTextInvalid(title, titleCharactersLimit) && { title: errorMessages.title }),
      ...(!validateDescription() && { description: errorMessages.description }),
    };

    if (isEmpty(validator)) return;

    setErrors(validator);
    bus.emit('errors', true);

    const errorField = findKey(validator, error => !!error);
    isScrollIntoViewErrors && scrollIntoViewElementById(errorField);
  };

  useListener('submitting', validate);

  return {
    errorTitle,
    errorDescription,
    setErrors,
  };
};

export default useValidation;
