import React from 'react';
import PropTypes from 'prop-types';

import OverviewCard from './components/Card';
import OverviewTables from './components/Tables';
import Actions from './components/Actions';

function Overview({ customBadge, customBadgeNode }) {
  return (
    <OverviewCard
      customBadge={customBadge}
      customBadgeNode={customBadgeNode}
    >
      <Actions />
      <OverviewTables />
    </OverviewCard>
  );
}

Overview.propTypes = {
  customBadge: PropTypes.node,
  customBadgeNode: PropTypes.node,
};

export default Overview;
