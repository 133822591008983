import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import RowValue from '../RowValue';
import { valueType } from '../../utils/shapes';
import { getRowStyle, labelStyle, valueStyle } from './styles';

function Row({ label, value, href, rowIndex }) {
  const rowStyle = getRowStyle(rowIndex);

  const isReactElement = isValidElement(value);

  return (
    <View style={rowStyle} wrap={false}>
      <Text style={labelStyle}>{label}</Text>
      {isReactElement
        ? value
        : (
          <View style={valueStyle}>
            <RowValue value={value} href={href} />
          </View>
        )}
    </View>
  );
}

Row.propTypes = {
  label: PropTypes.string,
  value: valueType,
  href: PropTypes.string,
  rowIndex: PropTypes.number,
};

export default Row;
