import PropTypes from 'prop-types';

import { suggestionShape } from 'utils/shapes/suggestion';
import { PRODUCT_SLUGS } from './constants';

export const uiShape = PropTypes.shape({
  isLoadingCustomFields: PropTypes.bool.isRequired,
  isLoadingCustomFieldByToken: PropTypes.bool.isRequired,
  isSavingCustomField: PropTypes.bool.isRequired,
  isUpdatingCustomField: PropTypes.bool.isRequired,
  resetTable: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isUpdatingPositions: PropTypes.bool.isRequired,
  availableIn: PropTypes.oneOf([PRODUCT_SLUGS.GRM, PRODUCT_SLUGS.STAKEHOLDER_MGMT]).isRequired,
});

export const actionsShape = PropTypes.shape({
  enabled: PropTypes.bool,
  token: PropTypes.string,
  editable: PropTypes.bool,
});

export const productSlugsShape = PropTypes.shape({
  value: PropTypes.arrayOf(PropTypes.string),
  token: PropTypes.string,
});

export const allCustomFieldsItemShape = PropTypes.shape({
  fieldType: PropTypes.string,
  createdAt: PropTypes.string,
  isEditable: PropTypes.bool,
  actions: actionsShape,
  productSlugs: productSlugsShape,
  required: PropTypes.bool.isRequired,
});

export const optionShape = PropTypes.shape({
  value: PropTypes.string,
});

export const productSlugsType = PropTypes.arrayOf(suggestionShape);

export const customFieldShape = PropTypes.shape({
  fieldType: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldDescription: PropTypes.string,
  isEditable: PropTypes.bool,
  question: PropTypes.string,
  options: PropTypes.arrayOf(optionShape),
  required: PropTypes.bool,
  productSlugs: productSlugsType,
});

export const fieldTypeShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const valuesShape = PropTypes.shape({
  fieldName: PropTypes.string,
  fieldType: fieldTypeShape,
  productSlugs: productSlugsType,
  fieldDescription: PropTypes.string,
  question: PropTypes.string,
  options: PropTypes.arrayOf(optionShape),
  initialOptionsLength: PropTypes.number,
  required: PropTypes.bool,
});

const errorShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.string,
});

export const errorsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(errorShape),
  ]),
});

const touchedItemShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.bool,
});

export const touchedShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(touchedItemShape),
  ]),
});
