import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/core/Card';
import Title from 'components/core/Profile/components/Title';
import CallToActions from 'components/core/Profile/components/CallToActions';
import { redirectToGlobalSearch } from 'components/GlobalAdvancedSearch/utils';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import ButtonTagManager from 'components/core/ButtonTagManager';
import useModal from 'components/core/Modal/useModal';
import { stringNumberType } from 'utils/shapes';

import EmptyTagsPlaceholder from './EmptyTagsPlaceholder';
import styles from './styles.module.scss';

function Tags({
  isLoadingTags,
  tags,
  onUpdateTags,
  id,
  documentType,
  itemType,
  emptyPlaceholderTargetType,
  getEmptyPlaceholderText,
  getEmptyPlaceholderButtonText,
}) {
  const { isOpenModal, toggleModal } = useModal();

  const tagManagerLabel = (
    <span className={styles.detail}>
      (<span className={styles.label}>edit</span>)
    </span>
  );
  const titleAction = (
    <ButtonTagManager
      buttonClassName={styles.editButton}
      label={tagManagerLabel}
      theme="light"
      recordId={id}
      recordType={documentType}
      itemType={itemType}
      tags={tags}
      updateDataItem={onUpdateTags}
      autoload
      customIsOpenModal={isOpenModal}
      customToggleModal={toggleModal}
    />
  );

  const emptyPlaceholder = (
    <EmptyTagsPlaceholder
      getText={getEmptyPlaceholderText}
      getButtonText={getEmptyPlaceholderButtonText}
      targetType={emptyPlaceholderTargetType}
      onClick={toggleModal}
    />
  );

  return (
    <Card className={styles.card}>
      <Title
        title="Tags"
        titleClassName={styles.title}
        detail={titleAction}
      />

      <CallToActions
        isLoadingTags={isLoadingTags}
        tags={tags}
        onTagClick={redirectToGlobalSearch}
        containerClassName={styles.actions}
        className={styles.tags}
        emptyTagsPlaceholder={emptyPlaceholder}
      />
    </Card>
  );
}

Tags.propTypes = {
  isLoadingTags: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  onUpdateTags: PropTypes.func.isRequired,
  id: stringNumberType,
  documentType: PropTypes.string,
  itemType: PropTypes.string,
  emptyPlaceholderTargetType: PropTypes.string,
  getEmptyPlaceholderText: PropTypes.func,
  getEmptyPlaceholderButtonText: PropTypes.func,
};

export { EmptyTagsPlaceholder };
export default Tags;
