import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import Input from 'components/core/Input';
import DateRange from 'components/core/DateRange';

import BaseOption from '../BaseOption';
import styles from './styles.module.scss';

function BaseRange({
  title,
  placeholder,
  questionText,
  className,
  isDateRange,
  dateFormat,
  showMonthDropdown,
  showYearDropdown,
  start,
  end,
  onChangeStart,
  onChangeEnd,
  ...restProps
}) {
  function formatToMoment(date) {
    return date && moment(date);
  }

  function renderComponent() {
    if (isDateRange) {
      return (
        <DateRange
          dateFormat={dateFormat}
          startDate={formatToMoment(start)}
          endDate={formatToMoment(end)}
          handleChangeStart={onChangeStart}
          handleChangeEnd={onChangeEnd}
          className={classNames(styles.range, 'base-range__date-range')}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
        />
      );
    }

    return (
      <div>
        <Input
          {...restProps}
          type="number"
          value={start || ''}
          onChange={onChangeStart}
          placeholder=""
          className={styles.start}
        />
        to
        <Input
          {...restProps}
          type="number"
          value={end || ''}
          onChange={onChangeEnd}
          placeholder=""
          className={styles.end}
        />
      </div>
    );
  }

  return (
    <BaseOption
      title={title}
      placeholder={placeholder}
      questionText={questionText}
      className={className}
      customComponent={renderComponent()}
    />
  );
}

BaseRange.defaultProps = {
  isDateRange: false,
};

BaseRange.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  questionText: PropTypes.string,
  className: PropTypes.string,
  isDateRange: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  start: PropTypes.oneOfType([
    PropTypes.object, PropTypes.number, PropTypes.string,
  ]),
  end: PropTypes.oneOfType([
    PropTypes.object, PropTypes.number, PropTypes.string,
  ]),
  onChangeStart: PropTypes.func,
  onChangeEnd: PropTypes.func,
  dateFormat: PropTypes.string,
};

export default BaseRange;
