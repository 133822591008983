export const NO_POSITION_ID = 'no_position';
export const MONITOR_ID = 'monitor';
export const SUPPORT_ID = 'support';
export const OPPOSE_ID = 'oppose';

const basePath = '/images/positions/';

export const positions = {
  [NO_POSITION_ID]: {
    color: 'grey',
    label: 'No position',
    icon: 'fas fa-times-circle',
    image: `${basePath}no_position.png`,
  },
  [MONITOR_ID]: {
    color: 'orange',
    label: 'Monitor',
    icon: 'fas fa-eye',
    image: `${basePath}monitor.png`,
  },
  [SUPPORT_ID]: {
    color: 'green',
    label: 'Support',
    icon: 'fas fa-thumbs-up',
    image: `${basePath}support.png`,
  },
  [OPPOSE_ID]: {
    color: 'red',
    label: 'Oppose',
    icon: 'fas fa-thumbs-down',
    image: `${basePath}oppose.png`,
  },
};

export const positionsAligned = {
  [SUPPORT_ID]: positions[SUPPORT_ID],
  [NO_POSITION_ID]: positions[NO_POSITION_ID],
  [MONITOR_ID]: positions[MONITOR_ID],
  [OPPOSE_ID]: positions[OPPOSE_ID],
};

export const POSITION_LOOKUP_OPTIONS = [
  { value: SUPPORT_ID, label: positions[SUPPORT_ID].label },
  { value: OPPOSE_ID, label: positions[OPPOSE_ID].label },
  { value: MONITOR_ID, label: positions[MONITOR_ID].label },
  { value: NO_POSITION_ID, label: positions[NO_POSITION_ID].label },
];

export const getPosition = position => positionsAligned[position];
