import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NAME_PLACEHOLDER } from 'components/core/CustomFields/utils/constants';
import withCustomField from 'components/core/CustomFields/utils/withCustomField';
import { fieldTypeShape, optionShape } from 'components/core/CustomFields/utils/shapes';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import Asterisk from 'components/core/Asterisk';

import PreviewInput from './PreviewInput';
import styles from '../styles.module.scss';

function PreviewContent({ fieldName, fieldDescription, fieldType, options, required }) {
  return (
    <>
      <p
        className={classNames(
          !fieldName && styles.placeholder,
          fieldName && styles.label
        )}
      >
        {fieldName || NAME_PLACEHOLDER} <Asterisk show={required} />
      </p>
      <div
        className={classNames(
          !fieldDescription && styles.placeholder,
          fieldDescription && styles.label
        )}
      >
        <TextInputPlaceholder className={styles.placeholder}>{fieldDescription}</TextInputPlaceholder>
      </div>

      <PreviewInput
        slug={fieldType.value}
        options={options}
      />
    </>
  );
}

PreviewContent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldDescription: PropTypes.string.isRequired,
  fieldType: fieldTypeShape,
  options: PropTypes.arrayOf(optionShape),
  required: PropTypes.bool.isRequired,
};

export default withCustomField(PreviewContent);
