import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import DatePicker from 'components/core/DatePicker';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import { formatDate } from 'utils/date';
import { trackMonthToggled, trackDateChosen } from 'utils/tracking';

import withCalendar from '../../utils/withCalendar';
import constants from '../../utils/constants';
import { typeShape } from '../../utils/shapes';
import { getCalendarEventsByType } from '../../utils';
import Legend from './components/Legend';
import styles from './styles.module.scss';

function Calendar({ type, calendarData, onSelectCalendarDate, onChangeCalendarMonth }) {
  const { SENATE_COMMITTEE, HOUSE_COMMITTEE } = constants;
  const [selectedDate, setSelectedDate] = useState(moment());
  const withLegend = type === constants.FEDERAL;
  let highlightDates = [];

  if (calendarData.length) {
    highlightDates = [
      { [styles[SENATE_COMMITTEE]]: getCalendarEventsByType(calendarData, SENATE_COMMITTEE) },
      { [styles[HOUSE_COMMITTEE]]: getCalendarEventsByType(calendarData, HOUSE_COMMITTEE) },
    ];
  }

  function handleMonthChange(date) {
    onChangeCalendarMonth && onChangeCalendarMonth(formatDate(date));
    trackMonthToggled();
  }

  function handleChange(date) {
    onSelectCalendarDate(formatDate(date));
    setSelectedDate(date);
    trackDateChosen();
  }

  return (
    <Card className={classNames('calendar', styles.calendarContainer)}>
      <DatePicker
        inline
        selected={selectedDate}
        highlightDates={highlightDates}
        onChange={handleChange}
        onMonthChange={handleMonthChange}
      />
      <hr />
      <Box text="Click a date to view detailed agenda and hearing breakdown on the left." />
      {withLegend && <Legend />}
    </Card>
  );
}

Calendar.propTypes = {
  type: typeShape.isRequired,
  calendarData: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectCalendarDate: PropTypes.func.isRequired,
  onChangeCalendarMonth: PropTypes.func,
};

export default withCalendar(Calendar);
