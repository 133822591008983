import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@react-pdf/renderer';
import { isEmpty, flatten, map } from 'lodash';

import { valueType } from '../../utils/shapes';
import { linkStyle } from './styles';

export default function RowValue({ value, href }) {
  if (!isEmpty(href)) {
    return <Link wrap="false" src={href} style={linkStyle}>{value}</Link>;
  }

  const valuesArray = flatten([value]);

  return map(valuesArray, valueItem => (
    <Text key={valueItem}>
      {valueItem}
    </Text>
  ));
}

RowValue.propTypes = {
  value: valueType,
  href: PropTypes.string,
};
