import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import styles from './styles';

function BillHeader({ children }) {
  return (
    <View style={styles.billHeaderSection}>
      {children}
    </View>
  );
}

BillHeader.propTypes = {
  children: PropTypes.object,
};

export default BillHeader;
