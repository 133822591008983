import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import styles from './styles.module.scss';

const THEMES = {
  justContent: styles.justContent,
  default: styles.default,
};

function AutosizeTextarea({ className, theme, innerRef, ...restProps }) {
  return (
    <TextareaAutosize
      {...restProps}
      ref={innerRef}
      className={classNames(styles.textarea, className, THEMES[theme])}
    />
  );
}

AutosizeTextarea.defaultProps = {
  theme: 'default',
};

AutosizeTextarea.propTypes = {
  innerRef: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(THEMES)),
};

export default AutosizeTextarea;
