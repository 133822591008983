import React from 'react';
import PropTypes from 'prop-types';
import { map, size, head } from 'lodash';

import {
  updateNotification,
  defaultNotification,
  Slide,
  Zoom,
  AlertType,
} from 'components/core/Notification';
import Button from 'components/core/Button';
import Link from 'utils/Link';

import styles from './styles.module.scss';

function DisplayAll({
  title,
  id,
  notifications,
  isOverLimit,
  myPageProps,
  onClose,
}) {
  const options = { transition: Slide, onClose };

  function seeAllNotifications() {
    const [first, ...rest] = notifications || [];
    const currentOptions = { ...options, transition: Zoom };

    updateNotification(id, first, currentOptions);
    map(rest, item => defaultNotification(item, currentOptions)());
  }

  function renderLink() {
    if (isOverLimit) {
      const { href, pageName } = myPageProps || {};

      return (
        <Link
          theme="tableLink"
          size="none"
          href={href}
          className={styles.link}
        >
          Go to “{pageName}”
        </Link>
      );
    }

    return (
      <Button
        theme="tableLink"
        size="none"
        onClick={seeAllNotifications}
        className={styles.link}
      >
        Click to show all new notifications
      </Button>
    );
  }

  const alert = size(notifications) === 1
    ? head(notifications)
    : <AlertType title={title} content={renderLink()} className={styles.card} />;

  return defaultNotification(alert, { ...options, id })();
}

DisplayAll.propTypes = {
  id: PropTypes.string.isRequired,
  isOverLimit: PropTypes.bool,
  title: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.node).isRequired,
  myPageProps: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
  }).isRequired,
};

export default DisplayAll;
