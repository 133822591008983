import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import ActionBar from 'components/core/ActionBar';
import Loader from 'components/core/Loader';
import { getEmailsLink } from 'utils/emails';

import Notifications from '../../../utils/Notifications';
import withLookup from '../../../utils/withLookup';
import styles from '../styles.module.scss';

function FooterActions({
  ui,
  dataLookup,
  tracking,
  actions,
  fetchData,
  onClear,
  ActionBarButtons,
  renderActionBarButtons,
  ...restProps
}) {
  const { isLoadingExports } = ui;
  const { selectedItems } = restProps;
  const { allData, appliedFilters, areEmptyEmails = false } = dataLookup;
  const { submitExport } = actions;
  const { trackResultsExported, trackResultEmailed } = tracking;

  const actionBarProps = {
    ...restProps,
    className: styles.actionBar,
  };

  const btnProps = {
    theme: 'light',
    size: 'medium',
  };

  function handleEmailAll(event) {
    event.preventDefault();
    getEmailsLink({ data: allData, selectedItems, trackResultEmailed });
  }

  function handleExportAll(event) {
    event.preventDefault();
    submitExport({
      items: selectedItems,
      searchData: appliedFilters,
      exportNotifications: {
        getSuccess: Notifications.exportEventSuccess,
        getFailed: Notifications.exportEventFailed,
      },
    }).then(() => {
      trackResultsExported && trackResultsExported();
    });
  }

  return (
    <ActionBar {...actionBarProps}>
      <Button {...btnProps} onClick={onClear}>Clear</Button>

      {renderActionBarButtons && renderActionBarButtons({ btnProps, selectedItems, fetchData })}

      {ActionBarButtons && (
        <ActionBarButtons
          btnProps={btnProps}
          selectedItems={selectedItems}
          fetchData={fetchData}
        />
      )}

      {submitExport && (
        <Button
          {...btnProps}
          className={styles.actionBtn}
          onClick={handleExportAll}
          disabled={isLoadingExports}
        >
          {isLoadingExports && <Loader size="small" theme="dark" onlyIcon />}
          Export
        </Button>
      )}

      {!areEmptyEmails && (
        <Button
          {...btnProps}
          className={styles.actionBtn}
          onClick={handleEmailAll}
        >
          Email
        </Button>
      )}
    </ActionBar>
  );
}

FooterActions.defaultProps = {
  tracking: {},
};

FooterActions.propTypes = {
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  dataLookup: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isFixed: PropTypes.bool.isRequired,
  tracking: PropTypes.object,
  ActionBarButtons: PropTypes.elementType,
  renderActionBarButtons: PropTypes.func,
};

export default withLookup(FooterActions);
