import React from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';

const allStrategy = { only: 'all' };

function FederalCommittee({ keyName, strategy, titles, value, onChange, className }) {
  function handleChange(data) {
    onChange(keyName, data);
  }

  function filterOption(candidate) {
    return !value.some(option => option.label === candidate.label);
  }

  return (
    <BaseOption
      {...titles}
      className={className}
      name={keyName}
      strategy={strategy}
      strategyInputs={allStrategy}
      overrideValue={value}
      defaultSelections={value}
      onChange={handleChange}
      customFilterOption={filterOption}
    />
  );
}

FederalCommittee.defaultProps = {
  keyName: 'committee',
  strategy: 'federal_committee',
  titles: {
    title: 'Committee or subcommittee',
    placeholder:
      'Committees or subcommittees the legislator is a member of (e.g., House Ways and Means)',
  },
};

FederalCommittee.propTypes = {
  keyName: PropTypes.string,
  strategy: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FederalCommittee;
