import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.module.scss';

function Caret({ isOpen, onClick }) {
  const caretClassName = classNames('pull-right', 'far fa-chevron-up', styles.transitionCaret, {
    [styles.rotateCaret]: !isOpen,
  });

  return <i onClick={onClick} className={caretClassName} />;
}

Caret.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Caret;
