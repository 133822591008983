import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';
import { formatToMoment } from 'components/core/Profile/utils';
import { stringNumberType } from 'utils/shapes';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { formatDateBy } from 'utils/date';

import styles from './styles.module.scss';

function DateItem({ id, keyName, item, label, labelId, className }) {
  const currentValue = item[id];

  function renderDate() {
    if (isEmpty(currentValue)) {
      return EMPTY_VALUE;
    }
    const date = formatToMoment(currentValue);

    return (
      <div className={classNames(styles.column, className)}>
        {formatDateBy(date, GLOBAL_DATE_FORMAT)}
        {item[labelId] && label && <span>({ label })</span>}
      </div>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderDate}</Column>
  );
}

DateItem.defaultProps = {
  item: {},
  id: 'date',
};

DateItem.propTypes = {
  id: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: stringNumberType,
    labelId: PropTypes.string,
  }),
  label: PropTypes.string,
  labelId: PropTypes.string,
  className: PropTypes.string,
};

export default DateItem;
