import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import ExportsPlaceholder from '../ActionButtons/components/Placeholder';
import ListEventsPlaceholder from '../ListEvents/components/Placeholder';
import CalendarPlaceholder from '../Calendar/components/Placeholder';
import FiltersPlaceholder from '../Filters/components/Placeholder';
import styles from '../Container/styles.module.scss';

export function Placeholder({ isFederal }) {
  const { isDesktop } = useMediaQuery();

  return (
    <section>
      <div className={styles.filters}>
        <div className={styles.filtersContent}>
          <FiltersPlaceholder />
          <ExportsPlaceholder />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.listEvents}>
          <ListEventsPlaceholder isFederal={isFederal} />
        </div>

        {isDesktop && (
          <div className={styles.calendar}>
            <CalendarPlaceholder isFederal={isFederal} />
          </div>
        )}
      </div>
    </section>
  );
}

Placeholder.propTypes = {
  isFederal: PropTypes.bool,
};
