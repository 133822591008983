import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';

import DataField from '../DataField';
import styles from './styles.module.scss';

function LinkField({
  label,
  value,
  className,
  isExternal,
  onClick,
  href,
  ...restProps
}) {
  const fieldValue = (value && href) && (
    <Link
      theme="default"
      size="none"
      href={href}
      isExternal={isExternal}
      onClick={onClick}
      className={styles.linkBlock}
    >
      {value}
    </Link>
  );

  return (
    <DataField
      label={label}
      value={fieldValue}
      className={className}
      {...restProps}
    />
  );
}

LinkField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string,
  isExternal: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default LinkField;
