import { useEffect, useState } from 'react';

import useLoader from 'utils/useLoader';

function useDropdownPosition({
  initialOption,
  isLoadingOption,
  baseParams,
  handleUpdateOption,
  onSetOption,
}) {
  const [option, setOption] = useState(initialOption);
  const { isLoading, setIsLoading, stopLoading } = useLoader();

  useEffect(() => {
    initialOption && setOption(initialOption);
  }, [initialOption]);

  const onChange = (position) => {
    if (position !== option) {
      handleUpdateOption({
        baseParams,
        currentOption: position,
        changeOption: setOption,
        setIsLoading,
      })
        .then(() => {
          setOption(position);
          onSetOption(position);
        })
        .finally(stopLoading);
    }
  };

  return {
    option,
    isLoading: isLoading || isLoadingOption,
    onChange,
  };
}

export default useDropdownPosition;
