import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function CloseButton({ closeToast, content, onClose }) {
  function handleClose(props) {
    closeToast(props);
    onClose && onClose(content);
  }

  return (
    <Button
      theme="light"
      size="none"
      className={styles.deleteButton}
      onClick={handleClose}
    >
      <i className="fal fa-times" />
    </Button>
  );
}

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  onClose: PropTypes.func,
};

export default CloseButton;
