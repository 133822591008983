import { cloneDeep, some } from 'lodash';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function findNode(object, searchId) {
  let response;

  function traverseArray(arr) {
    let traverseArrayResponse;

    for (const arrItem of arr) {
      const arrItemResponse = findNode(arrItem, searchId);
      if (arrItemResponse) {
        traverseArrayResponse = arrItemResponse;
        break;
      }
    }

    return traverseArrayResponse;
  }

  function traverseObject(obj) {
    let traverseObjectResponse;
    const { id, ...restObj } = obj || {};

    if (id === searchId) traverseObjectResponse = obj;
    if (!traverseObjectResponse && restObj) {
      for (const key of Object.keys(restObj)) {
        const statementItemResponse = findNode(restObj[key], searchId);
        if (statementItemResponse) {
          traverseObjectResponse = statementItemResponse;
          break;
        }
      }
    }

    return traverseObjectResponse;
  }

  if (Array.isArray(object)) response = traverseArray(object);
  else if (typeof object === 'object') response = traverseObject(object);

  return cloneDeep(response);
}

function findParentNode(object, searchId) {
  let response;

  function traverseArray(arr) {
    let traverseArrayResponse;

    for (const arrItem of arr) {
      const arrItemResponse = findParentNode(arrItem, searchId);
      if (arrItemResponse) {
        traverseArrayResponse = arrItemResponse;
        break;
      }
    }

    return traverseArrayResponse;
  }

  function traverseObject(obj) {
    let traverseObjectResponse;

    if (obj) {
      for (const key of Object.keys(obj)) {
        if (some(obj, ['id', searchId])) {
          traverseObjectResponse = obj;
          break;
        }

        if (Array.isArray(obj[key]) && obj[key].some(item => item.id === searchId)) {
          traverseObjectResponse = obj;
          break;
        }

        if (!traverseObjectResponse) {
          const statementItemResponse = findParentNode(obj[key], searchId);
          if (statementItemResponse) {
            traverseObjectResponse = statementItemResponse;
            break;
          }
        }
      }
    }

    return traverseObjectResponse;
  }

  if (Array.isArray(object)) response = traverseArray(object);
  else if (typeof object === 'object') response = traverseObject(object);

  return cloneDeep(response);
}

export {
  reorder,
  findNode,
  findParentNode,
};
