import { useEffect, useState } from 'react';
import moment from 'moment';

import {
  TWITTER,
  FACEBOOK,
  EMAIL_ID,
} from 'utils/constants/socialMedia';
import {
  getFacebookDescriptionLink,
  getMailLink,
  getTwitterLink,
} from 'utils/socialMedia/links';
import { trackHearingShared } from 'utils/tracking';

function useShare(isShareModalOpen, selectedEvent, checkedEvents) {
  const [shareOption, setShareOption] = useState();

  useEffect(() => {
    /** Reset default share option */
    !isShareModalOpen && setShareOption(TWITTER);
  }, [isShareModalOpen]);

  function handleChangeShareItem(event) {
    setShareOption(event.target.value);
  }

  function getShareMessage({ title, location, scheduledDate, committeeName, url }) {
    return `
      ${committeeName ? `Hearing: ${committeeName}` : ''}
      Title: ${title}
      Time: ${moment(scheduledDate).format('DD-MM-YYYY hh:mm A')}
      Location: ${location || 'See website for location'}
      Link: ${url}
    `;
  }

  function handleShareSubmit() {
    const event = selectedEvent || checkedEvents[0];
    const shareMessage = encodeURI(getShareMessage(event));

    if (shareOption === FACEBOOK) {
      window.open(getFacebookDescriptionLink(event.url, shareMessage));
    }

    if (shareOption === TWITTER) {
      window.open(getTwitterLink(shareMessage));
    }

    if (shareOption === EMAIL_ID) {
      window.open(getMailLink(shareMessage));
    }

    trackHearingShared({ type: shareOption });
  }

  return {
    handleChangeShareItem,
    handleShareSubmit,
  };
}

export default useShare;
