import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, sortBy, isEmpty, includes } from 'lodash';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';

import useCheckedItems from 'components/core/AdvancedSearch/utils/useCheckedItems';
import { TextInputPlaceholder } from 'components/core/Placeholder';
import Label from 'components/core/Label';
import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';

import styles from './styles.module.scss';

function BaseLabelGroup({
  id,
  value,
  onChange,
  className,
  labelGroupClassName,
  title,
  placeholder,
  values,
  name,
  withSelectAll,
  renderCustomWhenSelected,
  disabledValues,
  disabledMessage,
}) {
  const valueKeys = Object.keys(values);
  const { checkedItems, onCheck, onCheckAll } = useCheckedItems(id, value, onChange, valueKeys);

  function renderSelectAll() {
    if (!withSelectAll) {
      return null;
    }

    return (
      <Checkbox
        label="Select/Deselect All"
        onChange={onCheckAll}
        isChecked={isEqual(sortBy(checkedItems), sortBy(valueKeys))}
      />
    );
  }

  function renderCustomCheckItem() {
    if (isEmpty(checkedItems) || !renderCustomWhenSelected) {
      return null;
    }

    return renderCustomWhenSelected();
  }

  function checkBoxItem(key) {
    const itemDisabled = !isEmpty(disabledValues) && includes(disabledValues, key);

    return (
      <div key={`${id}-${key}`} className={itemDisabled ? `${styles.labelGroup} ${styles.disabledItem}` : styles.labelGroup}>
        <Checkbox
          label={values[key]}
          value={key}
          name={name}
          onChange={onCheck}
          isChecked={includes(checkedItems, key)}
          isDisabled={itemDisabled}
        />
        {
          itemDisabled ? (
            <Tooltip
              placement="bottom"
              content={disabledMessage}
              arrow
            >
              <i className="fas fa-lock" />
            </Tooltip>
          ) : null
        }
      </div>
    );
  }

  return (
    <div className={className}>
      <Label>{title}</Label>
      <TextInputPlaceholder className={styles.placeholder}>{placeholder}</TextInputPlaceholder>

      <LabelGroup className={classNames(labelGroupClassName, styles.labelParent)}>
        {renderSelectAll()}
        {valueKeys.map(key => checkBoxItem(key))}
      </LabelGroup>

      {renderCustomCheckItem()}
    </div>
  );
}

BaseLabelGroup.defaultProps = {
  withSelectAll: false,
};

BaseLabelGroup.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelGroupClassName: PropTypes.string,
  placeholder: PropTypes.string,
  withSelectAll: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  renderCustomWhenSelected: PropTypes.func,
  disabledValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  disabledMessage: PropTypes.string,
};

export default BaseLabelGroup;
