import React from 'react';
import { map, isEmpty } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getPublicationContent } from 'components/core/Profile/components/Cards/Publications/utils';

import TableSection from '../../TableSection';

function Publications({ person }) {
  const { publications } = person || {};

  if (isEmpty(publications)) {
    return null;
  }

  const tables = [
    map(publications, (publication, index) => ({
      label: `Publication ${index + 1}`,
      value: getPublicationContent(publication),
    })),
  ];

  return (
    <TableSection
      title="Publications"
      tables={tables}
    />
  );
}

Publications.propTypes = {
  person: personShape,
};

export default Publications;
