import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useTextValidator from './utils/useTextValidator';
import LetterInput from './components/LetterInput';
import styles from './styles.module.scss';

export default function TextValidator({ text, onValidationStatusChange, className }) {
  const {
    requiredLetters,
    typedLetters,
    fieldRefs,
    handleFocus,
    handleChange,
    handleKeyDown,
  } = useTextValidator({ text, onValidationStatusChange });

  const commonProps = {
    requiredLetters,
    typedLetters,
    fieldRefs,
    handleFocus,
    handleChange,
    handleKeyDown,
  };

  return (
    <div className={classNames(styles.container, className)}>
      {requiredLetters.map((letter, index) =>
        <LetterInput
          key={`letter-input-${index}`}
          letter={letter}
          index={index}
          {...commonProps}
        />)}
    </div>
  );
}

TextValidator.propTypes = {
  text: PropTypes.string.isRequired,
  onValidationStatusChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
