import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_CALENDAR_TIME_ZONE } from 'utils/constants/date';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import useUIPaginator from 'utils/paginator/useUIPaginator';
import Paginator from 'components/core/Paginator';
import PageHeader from 'components/core/PageHeader';
import { stringNumberType } from 'utils/shapes';
import { queryParse } from 'utils/query-string';

import { HearingCalendarContext } from '../../utils/withCalendar';
import Notifications from '../../utils/Notifications';
import { typeShape } from '../../utils/shapes';
import { titles } from '../../utils/titles';
import ExportHearingCalendarsModal from '../ExportHearingCalendarsModal';
import ShareModal from '../modals/Share';
import FooterActions from '../FooterActions';
import ActionsButton from '../ActionButtons';
import ListEvents from '../ListEvents';
import Calendar from '../Calendar';
import Filters from '../Filters';
import styles from './styles.module.scss';

function Container({
  ui,
  type,
  events,
  checkedEvents,
  selectedStates,
  selectedCommittees,
  fetchEvents,
  calendarData,
  fetchCalendarData,
  onSelectCalendarDate,
  onChangeCalendarMonth,
  onCheckAllEvents,
  onClearAllEvents,
  onCheckSelectedEvent,
  filterByCommittee,
  filterByState,
  showCalendar,
  showFilters,
  showPaginator,
  showHeader,
  monthsAheadToFetch,
  setMonthsAheadToFetch,
  emptyPlaceholderDescription,
  floatFooterActions,
}) {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const isExportOpen = !!queryParse(window.location.search, 'export');

  useEffect(() => {
    (showCalendar && fetchCalendarData) && fetchCalendarData().catch(Notifications.getCalendarDataFailed);
    setMonthsAheadToFetch(monthsAheadToFetch);
    fetchEvents().catch(Notifications.getEventsFailed);
  }, [selectedCommittees]);

  moment.tz.setDefault(DEFAULT_CALENDAR_TIME_ZONE);

  function toggleExportModal(event) {
    setSelectedEvent(event);
    setIsExportModalOpen(!isExportModalOpen);
  }

  function toggleShareModal(event) {
    setSelectedEvent(event);
    setIsShareModalOpen(!isShareModalOpen);
  }

  const { isDesktop, isMobile } = useMediaQuery();
  const hearingCalendarContext = {
    isLoadingEvents: ui.isLoadingEvents,
    isLoadingExports: ui.isLoadingExports,
    isExportModalOpen,
    isShareModalOpen,
    events,
    checkedEvents,
    selectedStates,
    selectedCommittees,
    calendarData,
    onSelectCalendarDate,
    onChangeCalendarMonth,
    onCheckAllEvents,
    onClearAllEvents,
    onCheckSelectedEvent,
    selectedEvent,
    toggleExportModal,
    toggleShareModal,
    filterByCommittee,
    filterByState,
    exportNotifications: {
      getFailed: Notifications.exportEventFailed,
      getSuccess: Notifications.exportEventSuccess,
    },
    emptyPlaceholderDescription,
  };

  const {
    items: paginatedEvents,
    paginator,
    isLoadingItems,
    onChangePaginator,
  } = useUIPaginator({ allItems: events, isLoading: ui.isLoadingEvents });
  const {
    current,
    total,
    totalCount,
  } = paginator;

  const dayEvents = showPaginator ? paginatedEvents : events;

  const pageHeaderProps = {
    headerClassName: styles.pageHeader,
    titleClassName: styles.subProductTitle,
    title: titles[type],
  };

  return (
    <HearingCalendarContext.Provider value={hearingCalendarContext}>
      {showHeader && <PageHeader {...pageHeaderProps} />}
      {showFilters && (
        <div className={classNames('hearing-filters-container', styles.filters)}>
          <div className={styles.filtersContent}>
            <Filters type={type} />
            <ActionsButton type={type} allItems={events} isExportOpen={isExportOpen} />
          </div>
        </div>
      )}

      <div className={classNames(styles.body, checkedEvents.length && styles.bottom)}>
        <div className={classNames('hearing-list-events', styles.listEvents)}>
          <ListEvents type={type} dayEvents={dayEvents} />
        </div>

        {showCalendar && isDesktop && (
          <div className={styles.calendar}>
            <Calendar type={type} />
          </div>
        )}
      </div>

      {floatFooterActions && <FooterActions isFixed={false} />}
      {showPaginator && !isEmpty(paginatedEvents) && (
        <Paginator
          isMobile={isMobile}
          isLoading={isLoadingItems}
          current={current}
          total={total}
          totalItems={totalCount}
          onChange={onChangePaginator}
          className={styles.paginator}
          pageSize={5}
        />
      )}

      {!floatFooterActions && <FooterActions />}
      <ExportHearingCalendarsModal
        isOpenModal={isExportModalOpen}
        toggleModal={toggleExportModal}
        selectedItems={checkedEvents}
        allItems={events}
        type={type}
      />
      <ShareModal />
    </HearingCalendarContext.Provider>
  );
}

Container.defaultProps = {
  ui: {},
  showFilters: true,
  showCalendar: true,
  showHeader: true,
  showPaginator: false,
  monthsAheadToFetch: 1,
  floatFooterActions: false,
};

// TODO: define missing shapes
Container.propTypes = {
  ui: PropTypes.shape({
    isLoadingEvents: PropTypes.bool,
    isLoadingExports: PropTypes.bool,
  }),
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkedEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedStates: PropTypes.arrayOf(PropTypes.string),
  selectedCommittees: PropTypes.arrayOf(stringNumberType),
  fetchEvents: PropTypes.func.isRequired,
  calendarData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchCalendarData: PropTypes.func,
  onSelectCalendarDate: PropTypes.func.isRequired,
  onChangeCalendarMonth: PropTypes.func,
  onCheckAllEvents: PropTypes.func.isRequired,
  onClearAllEvents: PropTypes.func.isRequired,
  onCheckSelectedEvent: PropTypes.func.isRequired,
  filterByCommittee: PropTypes.func,
  filterByState: PropTypes.func,
  monthsAheadToFetch: PropTypes.number,
  setMonthsAheadToFetch: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  showCalendar: PropTypes.bool,
  showPaginator: PropTypes.bool,
  showHeader: PropTypes.bool,
  emptyPlaceholderDescription: PropTypes.string,
  floatFooterActions: PropTypes.bool,
  type: typeShape.isRequired,
};

export default Container;
