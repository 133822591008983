import cloneDeep from 'lodash/cloneDeep';

import { findNode } from '../dragEndActions/common';

export default function handleDroppedStatementJoin({ parentId, result, data, updateData }) {
  const draggedItem = {
    id: `statement-${new Date().getTime()}`,
    type: 'statement',
    name: result.draggableId,
    options: {},
  };

  const oldStatementGroup = findNode(data, parentId);
  const newStatementGroup = cloneDeep(oldStatementGroup);
  newStatementGroup.statements.splice(newStatementGroup.statements.length, 0, draggedItem);

  updateData({
    payload: JSON.parse(
      JSON.stringify(data).replace(
        JSON.stringify(oldStatementGroup),
        JSON.stringify(newStatementGroup),
      ),
    ),
  });
}
