import React from 'react';
import PropTypes from 'prop-types';
import { reduce, isEmpty, map } from 'lodash';

import ExportModal, {
  Customization,
  FileFormat,
  Sections,
  Preview,
} from 'components/core/ExportDocument/components/ExportModal';
import Loader from 'components/core/Loader';
import { ReportTitlePreview } from 'components/core/ExportDocument/components/ExportModal/components/Preview';
import {
  CSV_ID,
  LOGO_CUSTOMIZATION_ID,
  PDF_ID,
  DOCX_ID,
  EXPORT_FILE_ITEMS,
  SUMMARY_CUSTOMIZATION_ID,
  SHOW_PAGE_CUSTOMIZATION_ID,
} from 'utils/constants/export';
import { sortShape } from 'components/core/Sort/shapes';

import { customFileFormatItemsShape, customFileFormatsShape } from '../../shapes';
import useExportLookupModal from './utils/useExportLookupModal';
import { getCustomizationSectionProps, getFormatItems } from './utils';
import styles from './styles.module.scss';

function ExportLookupModal({
  isOpenModal,
  toggleModal,
  fileName,
  sort,
  selectedItems,
  documentTitle,
  defaultColumnsIds,
  withExportSummary,
  exportTableColumns,
  customFileFormats,
  customFileFormatItems,
  fetchComponentData,
  documentComponent,
  slugSingleExport,
  onFileExported,
  customApiProps,
  defaultFormat,
  fileFormatDescription,
  withDocxCustomization,
  sectionsTitle,
  sectionsDescription,
  perPage,
  onTracking,
  onNotifySuccess,
  onNotifyFailure,
  defaultFileFormat,
  withGovReportToPDF,
}) {
  const {
    onExport,
    isLoading,
    onExportPDF,
  } = useExportLookupModal({
    documentTitle,
    fetchComponentData,
    documentComponent,
    slugSingleExport,
    selectedItems,
    toggleModal,
    fileName,
    sort,
    customApiProps,
    perPage,
    onNotifySuccess,
    onNotifyFailure,
  });

  const tabColumns = exportTableColumns.map(tab => tab.id);
  const fileFormatItems = isEmpty(customFileFormatItems) ? getFormatItems(customFileFormats) : customFileFormatItems;
  const exportFileItems = isEmpty(fileFormatItems) ? EXPORT_FILE_ITEMS : fileFormatItems;

  function handleExport(props, logoUrl, documentSummary) {
    const { sections, customization, fileFormat } = props || {};

    const [fileExtension = defaultFormat] = fileFormat || [];
    const filterColumns = exportTableColumns.filter(
      item => !item.isSelectAll && sections.includes(item.id)
    );

    const visibleColumns = reduce(filterColumns, (result, item) => {
      const hasAditionalValues = !isEmpty(item.values);

      if (hasAditionalValues) {
        return [
          ...result,
          ...map(item.values, value => ({ value: value.id, label: value.title })),
        ];
      }

      return [
        ...result,
        { value: item.id, label: item.title },
      ];
    }, []);

    const isLogoVisible = customization.includes(LOGO_CUSTOMIZATION_ID);
    const isPageNumbersVisible = customization.includes(SHOW_PAGE_CUSTOMIZATION_ID);

    onFileExported && onFileExported(props);
    onTracking && onTracking(fileExtension);
    if (fileExtension === PDF_ID && !withGovReportToPDF) {
      return onExportPDF({
        ...props,
        logoUrl: isLogoVisible ? logoUrl : undefined,
        isPageNumbersVisible,
        documentSummary,
      });
    }

    return onExport({
      fileExtension,
      visibleColumns,
      defaultColumns: defaultColumnsIds,
      isLogoVisible,
      documentSummary,
    });
  }

  function renderModalContent(exportProps) {
    const {
      getFileFormatProps,
      getCustomizationProps,
      getSectionsProps,
      companyHasLogo,
      onResetSummary,
      onChangeSummary,
      documentSummary,
      isLogoLoading,
      logoUrl,
    } = exportProps;

    const { values } = getCustomizationProps();
    const { values: fileFormatValues } = getFileFormatProps();
    const previewCompanyLogo = values.includes(LOGO_CUSTOMIZATION_ID);
    const reportHasSummary = values.includes(SUMMARY_CUSTOMIZATION_ID);

    const isPDF = fileFormatValues.includes(PDF_ID);
    const displayDocxCustomization = withDocxCustomization && fileFormatValues.includes(DOCX_ID);
    const displayCustomization = isPDF || displayDocxCustomization;
    const previewLogoUrl = previewCompanyLogo ? logoUrl : undefined;

    const documentSummaryProps = {
      reportHasSummary,
      onReset: onResetSummary,
      onChange: onChangeSummary,
      value: documentSummary,
    };

    const previewComponent = (
      <ReportTitlePreview
        logoUrl={previewLogoUrl}
        documentTitle={documentTitle}
      />
    );

    if (isLogoLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader message="Loading ..." />
        </div>
      );
    }

    return (
      <>
        <div className={styles.flexItem}>
          <div className={styles.section}>
            <Sections
              {...getSectionsProps()}
              exportTabItems={exportTableColumns}
              title={sectionsTitle}
              description={sectionsDescription}
            />
          </div>
        </div>

        <div className={styles.flexItem}>
          <div className={styles.section}>
            <FileFormat
              {...getFileFormatProps()}
              description={fileFormatDescription}
              exportFileItems={exportFileItems}
            />
          </div>

          {displayCustomization && (
            <>
              <div className={styles.section}>
                <Customization
                  {...getCustomizationProps()}
                  {...getCustomizationSectionProps({ withExportSummary, companyHasLogo, isPDF, withGovReportToPDF })}
                  onChangeSummary={onChangeSummary}
                  documentSummaryProps={documentSummaryProps}
                  enableTooltip
                />
              </div>
              <div className={styles.section}>
                <Preview previewComponent={previewComponent} />
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <ExportModal
      isOpenModal={isOpenModal}
      toggleModal={toggleModal}
      onExport={handleExport}
      defaultSectionValues={tabColumns}
      displayCompanyLogo
      isLoading={isLoading}
      theme="scrollableBody"
      className={styles.modal}
      bodyClassName={styles.modalBody}
      defaultFileFormatValues={defaultFileFormat}
    >
      {renderModalContent}
    </ExportModal>
  );
}

ExportLookupModal.defaultProps = {
  defaultFormat: CSV_ID,
  sectionsTitle: 'Columns',
  sectionsDescription: 'Choose which columns to include',
  withGovReportToPDF: false,
};

ExportLookupModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  sort: sortShape,
  selectedItems: PropTypes.array,
  documentTitle: PropTypes.string.isRequired,
  documentComponent: PropTypes.node,
  slugSingleExport: PropTypes.string.isRequired,
  exportTableColumns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isSelectAll: PropTypes.bool,
    isDisabled: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })),
  })).isRequired,
  withExportSummary: PropTypes.bool,
  defaultColumnsIds: PropTypes.arrayOf(PropTypes.string),
  fetchComponentData: PropTypes.func,
  onFileExported: PropTypes.func,
  customFileFormats: customFileFormatsShape,
  customFileFormatItems: customFileFormatItemsShape,
  defaultFileFormat: PropTypes.arrayOf(PropTypes.string),
  customApiProps: PropTypes.object,
  defaultFormat: PropTypes.string,
  fileFormatDescription: PropTypes.string,
  withDocxCustomization: PropTypes.bool,
  sectionsTitle: PropTypes.string,
  sectionsDescription: PropTypes.string,
  perPage: PropTypes.number,
  onTracking: PropTypes.func,
  onNotifySuccess: PropTypes.func,
  onNotifyFailure: PropTypes.func,
  withGovReportToPDF: PropTypes.bool,
};

export default ExportLookupModal;
