import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';

function RecordColumn({ keyName, item, recordTypes }) {
  const { type } = item || {};

  function renderRecord() {
    const foundRecord = recordTypes.find(({ id }) => id === type) || {};

    return foundRecord.label || foundRecord.labelPlural || EMPTY_VALUE;
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderRecord}</Column>
  );
}

RecordColumn.defaultProps = {
  item: {},
};

RecordColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
  recordTypes: PropTypes.array,
};

export default RecordColumn;
