import TextPlaceholder from './components/Text';
import TablePlaceholder from './components/Table';
import TextInputPlaceholder from './components/TextInput';
import InputPlaceholder from './components/InputPlaceholder';
import ButtonGroupPlaceholder from './components/ButtonGroup';
import PageHeaderPlaceholder from './components/PageHeader';
import PaginatorPlaceholder from './components/Paginator';
import TabsPlaceholder from './components/Tabs';
import TagsPlaceholder from './components/Tags';
// eslint-disable-next-line import/no-cycle
import CardPlaceholder, { CardTablePlaceholder, CardNewsStoryPlaceholder } from './components/Card';

export {
  TextPlaceholder,
  TablePlaceholder,
  ButtonGroupPlaceholder,
  PageHeaderPlaceholder,
  TextInputPlaceholder,
  PaginatorPlaceholder,
  TabsPlaceholder,
  CardPlaceholder,
  CardTablePlaceholder,
  CardNewsStoryPlaceholder,
  InputPlaceholder,
  TagsPlaceholder,
};
