import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { LabelText } from 'components/core/ExportDocument';

import styles from './styles';

function getHeaderItem(itemValue, itemLabel) {
  return (
    <LabelText
      style={styles.billHeaderItem}
      textStyle={styles.billHeaderText}
      labelStyle={styles.billDescriptionText}
      value={itemValue}
      label={itemLabel}
      wrap={false}
    />
  );
}

function BillHeader({
  bill,
  displayBillStateSection,
  displayBillStatusSection,
  displayIntroducedSection,
  displayVoteSection,
}) {
  const {
    status,
    state,
    dateIntroduced,
    hasVote,
  } = bill || {};

  return (
    <Fragment>
      {displayBillStateSection && getHeaderItem(state, 'State')}
      {displayIntroducedSection && getHeaderItem(dateIntroduced, 'Introduced')}
      {displayBillStatusSection && getHeaderItem(status, 'Status')}
      {displayVoteSection && getHeaderItem(hasVote, 'Has vote?')}
    </Fragment>
  );
}

BillHeader.propTypes = {
  bill: PropTypes.object,
  displayBillStateSection: PropTypes.bool,
  displayBillStatusSection: PropTypes.bool,
  displayIntroducedSection: PropTypes.bool,
  displayVoteSection: PropTypes.bool,
};

export default BillHeader;
