import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from 'components/core/ErrorMessage';

import styles from './styles.module.scss';

function Textarea({ className, errorMessage, isError, ...restProps }) {
  return (
    <Fragment>
      <textarea
        {...restProps}
        className={classNames(
          styles.textarea,
          className,
          isError && styles.invalid,
          errorMessage && styles.invalid,
        )}
      />

      <ErrorMessage message={errorMessage} />
    </Fragment>
  );
}

Textarea.defaultProps = {
  isError: false,
};

Textarea.propTypes = {
  isError: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Textarea;
export { default as AutosizeTextarea } from './components/AutosizeTextarea';
