import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import { TextPlaceholder } from 'components/core/Placeholder';

import { getPosition } from '../../utils/constants';
import styles from './styles.module.scss';

function TooltipButton({
  isDisabled,
  isLoading,
  position,
  className,
  iconClassName,
  onClick,
}) {
  const { label, icon, color } = getPosition(position) || {};

  function handleClick() {
    onClick(position);
  }

  return (
    <Button
      customIcon={!isLoading && <i className={icon} />}
      disabled={isDisabled || isLoading}
      onClick={handleClick}
      theme="light"
      iconClassName={classNames(styles[color], iconClassName)}
      className={className}
    >
      {isLoading ? <TextPlaceholder className={styles.placeholder} /> : label}
    </Button>
  );
}

TooltipButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  position: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default TooltipButton;
