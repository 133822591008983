import { joinCompact } from 'utils/string';

import { getDate } from '../../utils';

export const getEmploymentContent = ({
  title,
  company,
  startDate,
  endDate,
  startYear,
  endYear,
}) => {
  const start = startDate || startYear;
  const end = endDate || endYear;
  const date = getDate(start, end);

  return `${joinCompact([title, company])} ${date}`;
};
