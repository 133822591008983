import React, { Fragment } from 'react';

import TextPlaceholder from 'components/core/Placeholder/components/Text';

import styles from '../styles.module.scss';

function Placeholder() {
  function renderItem() {
    return (
      <div className={styles.itemPlaceholder}>
        {Array(3).fill(0).map((_, index) => (
          <Fragment key={`text-card-mobile-${index}`}>
            <div className={styles.titlePlaceholder}><TextPlaceholder /></div>
            <div className={styles.contentPlaceholder}><TextPlaceholder /></div>
          </Fragment>
        ))}
        <hr />
      </div>
    );
  }

  return (
    <div className={styles.card}>
      {Array(3).fill(0).map((_, index) => (
        <div key={`cardMobile-${index}`} className={styles.itemCard}>
          {renderItem()}
        </div>
      ))}
    </div>
  );
}

export default Placeholder;
