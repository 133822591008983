import styles from '../styles.module.scss';

export const directions = {
  right: styles.toRight,
  left: styles.toLeft,
};

export const OPTION_TYPES = {
  open: {
    label: 'Open',
    icon: 'fas fa-exclamation-circle',
    color: 'red',
    value: 'open',
  },
  inProgress: {
    label: 'In-progress',
    icon: 'fas fa-tasks',
    color: 'blue',
    value: 'inProgress',
  },
  closed: {
    label: 'Closed',
    icon: 'fas fa-check-circle',
    color: 'green',
    value: 'closed',
  },
};
