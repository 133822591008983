import * as Yup from 'yup';

import errorMessages from 'components/core/CustomFields/utils/errorMessages';
import { hasOptions } from 'components/core/CustomFields/utils';
import validateUniqueness from 'utils/yup/validateUniqueness';

Yup.addMethod(Yup.array, 'unique', validateUniqueness);

const validationSchema = Yup.object().shape({
  name: Yup.string().required(errorMessages.fieldName),
  fieldDescription: Yup.string().required(errorMessages.fieldDescription),
  productSlugs: Yup.array().required(errorMessages.productSlugs),
  options: Yup.mixed()
    .when(
      'fieldType', {
        is: (fieldType => hasOptions(fieldType.value)),
        then: Yup.array()
          .min(1, errorMessages.options.min)
          .of(Yup.object().shape({
            value: Yup.string().required(errorMessages.options.required),
          }))
          .unique(errorMessages.options.unique, 'value'),
      },
    ),
});

export default validationSchema;
