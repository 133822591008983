import { useLayoutEffect, useState } from 'react';

export default function useIndeterminateCheckbox(values = []) {
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useLayoutEffect(() => {
    const truthyValuesCount = values.filter(value => value).length;
    const newValue = truthyValuesCount && truthyValuesCount !== values.length;

    setIsIndeterminate(Boolean(newValue));
    setIsChecked(Boolean(truthyValuesCount));
  }, values);

  return { isChecked, isIndeterminate };
}
