import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import TextPlaceholder from '../Text';
import styles from './styles.module.scss';

function TablePlaceholder({ rows, columns, className, contentClassName }) {
  function getHeaderContent() {
    return (
      <div key={`tr-${uniqueId()}`} className={styles.titleContent}>
        <TextPlaceholder className={styles.headerTitle} />
      </div>
    );
  }

  function getColumnContent() {
    return (
      <div key={`text-${uniqueId()}`} className={styles.colText}>
        <TextPlaceholder />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={classNames(styles.header, contentClassName)}>
        {Array(columns).fill(0).map(getHeaderContent)}
      </div>

      {Array(rows).fill(0).map(() => (
        <div key={`td-${uniqueId()}`} className={classNames(styles.colContent, contentClassName)}>
          {Array(columns).fill(0).map(getColumnContent)}
        </div>
      ))}
    </div>
  );
}

TablePlaceholder.defaultProps = {
  rows: 3,
  columns: 3,
};

TablePlaceholder.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default TablePlaceholder;
