import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Tooltip from '@tippyjs/react';

import { EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import EmailNotifications from 'utils/emails/Notifications';
import { getEmailsLink } from 'utils/emails';
import Loader from 'components/core/Loader';
import Button from 'components/core/Button';
import { Icon } from 'components/core/Icons';

import styles from './styles.module.scss';

function BulkEmail({
  ui,
  data,
  tracking,
  paginator,
  getAllEmails,
  areEmptyEmails,
  withIcon,
  withIconWrapper,
  buttonTheme,
  loaderTheme,
  className,
}) {
  if (areEmptyEmails) {
    return null;
  }

  const { totalCount } = paginator || {};
  const { trackAllResultsEmailed } = tracking || {};
  const isEmptyData = isEmpty(data);
  const emailDataExceeded = totalCount > EXPORT_EMAIL_LIMIT;

  function handleEmailAll(event) {
    event.preventDefault();

    getAllEmails()
      .then((result) => {
        trackAllResultsEmailed && trackAllResultsEmailed();

        return getEmailsLink({ data: result.payload, withFilter: false });
      })
      .catch((error) => {
        EmailNotifications.exportEmailFailed();
        throw error;
      });
  }

  function getTooltipNode() {
    return (
      <p className={styles.tooltipText}>
        Your current search exceeded the mass email limit of {EXPORT_EMAIL_LIMIT} recipients.
        Refine your search parameters and try again.
      </p>
    );
  }

  const isDisabledEmail = isEmptyData || emailDataExceeded || areEmptyEmails;
  const { isLoadingAllEmails } = ui;

  const loadingContent = isLoadingAllEmails
    ? (
      <Loader
        className={classNames(styles.loader, withIconWrapper && styles.withIconWrap)}
        size="small"
        theme={loaderTheme}
        onlyIcon
      />
    )
    : <Icon iconClass="fa-paper-plane" withIconWrapper={withIconWrapper} />;

  const bulkEmailNode = (
    <Button
      theme={buttonTheme}
      customIcon={withIcon && loadingContent}
      iconClassName={styles.icon}
      className={classNames(
        'header__email-all',
        styles.emailAll,
        className,
      )}
      disabled={isDisabledEmail}
      onClick={handleEmailAll}
    >
      <span
        className={classNames(
          styles.textButton,
          withIconWrapper && styles.textButtonWithIconWrap
        )}
      >
        Mass Email
      </span>
    </Button>
  );

  if (emailDataExceeded) {
    return (
      <Tooltip
        content={getTooltipNode()}
        placement="bottom-end"
        className={classNames(
          'header__tooltip-exceed',
          styles.tooltipWrapper,
          styles.tooltipContainer
        )}
      >
        <div className={styles.tooltipNone}>{bulkEmailNode}</div>
      </Tooltip>
    );
  }

  return bulkEmailNode;
}

BulkEmail.defaultProps = {
  areEmptyEmails: false,
  withIcon: true,
  withIconWrapper: false,
  loaderTheme: 'blue',
  buttonTheme: 'secondary',
};

BulkEmail.propTypes = {
  ui: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  paginator: PropTypes.object.isRequired,
  tracking: PropTypes.object,
  getAllEmails: PropTypes.func,
  areEmptyEmails: PropTypes.bool,
  withIcon: PropTypes.bool,
  withIconWrapper: PropTypes.bool,
  buttonTheme: PropTypes.string,
  loaderTheme: PropTypes.string,
  className: PropTypes.string,
};

export default BulkEmail;
