import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import StatementsAdd from '../components/StatementsAdd';
import GroupOperator from './GroupOperator';
import Statement from './Statement';
import { getStatementsStyle } from '../../Definitions/theme';
import styles from '../styles.module.scss';

function StatementChecker({
  keyPrefix,
  isLastOperator,
  isDraggingOver,
  isDraggingStatement,
  isDraggingFilter,
  draggableIndex,
  parentOperator,
  parentId,
  statement,
  deleteStatement,
  deleteFilter,
}) {
  const { id, type, operator, statements } = statement;

  if (type === 'statements_group') {
    const isGroupOperatorDisplayed = !isLastOperator && !isDraggingOver;

    return (
      <Fragment>
        <Droppable droppableId={`droppableStatementsReorder:${id}`} type={id}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getStatementsStyle(droppableSnapshot.isDraggingOver)}
              className={styles.statementGroup}
            >
              <div className={styles.statements}>
                {statements.map((statementItem, index) => (
                  <StatementChecker
                    key={`${keyPrefix}-${index}`}
                    keyPrefix={`${keyPrefix}-${index}`}
                    isLastOperator={statements.length - 1 === index}
                    isDraggingOver={droppableSnapshot.isDraggingOver}
                    isDraggingStatement={isDraggingStatement}
                    isDraggingFilter={isDraggingFilter}
                    draggableIndex={index}
                    parentOperator={operator}
                    parentId={id}
                    statement={statementItem}
                    deleteStatement={deleteStatement}
                    deleteFilter={deleteFilter}
                  />
                ))}

                <StatementsAdd
                  isNewGroupPartHidden
                  isDraggingStatement={isDraggingStatement}
                  statementsGroupId={id}
                  statements={statements}
                />

                {droppableProvided.placeholder}
              </div>
            </div>
          )}
        </Droppable>

        {isGroupOperatorDisplayed && (
          <div className={styles.statementFiltersGroupOperator}>
            <GroupOperator type="group" id={parentId} operator={parentOperator} />
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <Statement
      key={`${keyPrefix}-${id}`}
      isDraggingOver={isDraggingOver}
      isLastOperator={isLastOperator}
      isDraggingStatement={isDraggingStatement}
      isDraggingFilter={isDraggingFilter}
      draggableIndex={draggableIndex}
      parentOperator={parentOperator}
      parentId={parentId}
      statement={statement}
      deleteStatement={deleteStatement}
      deleteFilter={deleteFilter}
    />
  );
}

StatementChecker.propTypes = {
  keyPrefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isLastOperator: PropTypes.bool.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  isDraggingStatement: PropTypes.bool.isRequired,
  isDraggingFilter: PropTypes.bool.isRequired,
  draggableIndex: PropTypes.number.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  statement: PropTypes.object.isRequired,
  deleteStatement: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
};

export default StatementChecker;
