import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { pluralize } from 'utils/string';
import { TextPlaceholder } from 'components/core/Placeholder';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function AppliedFiltersLabel({
  filtersCount,
  results,
  onClearFilters,
  searchValue,
  resultsWord,
  containerClass,
  placeholderClass,
  isLoading,
}) {
  const showPlaceholder = isLoading && (searchValue || filtersCount);

  if (filtersCount === 0 && !searchValue) {
    return null;
  }

  if (showPlaceholder) {
    return (
      <div className={classNames(styles.container, containerClass)}>
        <TextPlaceholder className={classNames(styles.placeholder, placeholderClass)} />
      </div>
    );
  }

  function renderSearchKeyword() {
    if (!searchValue) {
      return '.';
    }

    return <>&nbsp;for <b>{searchValue}</b>.</>;
  }

  function renderAppliedFilters() {
    if (!filtersCount) {
      return null;
    }

    const filtersWord = pluralize('filter', 'filters', filtersCount);

    return <>&nbsp;<b>{filtersCount}</b> {filtersWord} applied</>;
  }

  return (
    <div className={classNames(styles.container, containerClass)}>
      Showing <b>{results}</b> {resultsWord}
      {renderSearchKeyword()}
      {renderAppliedFilters()}
      &nbsp;
      <Button
        size="small"
        theme="tableLink"
        className={styles.link}
        onClick={onClearFilters}
      >
        Clear all
      </Button>.
    </div>
  );
}

AppliedFiltersLabel.propTypes = {
  results: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
  resultsWord: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  searchValue: PropTypes.string,
  placeholderClass: PropTypes.string,
  containerClass: PropTypes.string,
};

export default AppliedFiltersLabel;
