import React from 'react';
import PropTypes from 'prop-types';

import LegislativeIssueArea from 'components/Rolodex/StafferLookup/components/Header/components/Options/components/LegislativeIssueArea';
import Title from 'components/core/Table/components/Dimensions/components/Title';
import { StafferTitle } from 'components/core/PageLookup/components/Options';
import Button from 'components/core/Button';
import Select from 'components/core/Select';
import Label from 'components/core/Label';

import { OFFICE_TYPE_OPTIONS } from '../../../utils/constants';
import styles from '../styles.module.scss';

function TooltipContent({
  onClose,
  onSubmit,
  stafferTitles,
  issuesArea,
  officeType,
  onChangeOfficeType,
  onChangeStafferTitle,
  onChangeIssueArea,
  isFederal,
  isMobile,
  id,
}) {
  const strategyInputs = { legislator_ids: [id] };
  const stafferTitleInputs = isFederal ? strategyInputs : { state_offices: [`legislator_${id}`] };

  function handleSubmit() {
    onSubmit();
    onClose();
  }

  return (
    <div>
      {!isMobile && <Title title="Additional filters" onClose={onClose} />}

      <div className={styles.formBody}>
        <StafferTitle
          keyName="stafferTitles"
          value={stafferTitles}
          isFederal={isFederal}
          strategyInputs={stafferTitleInputs}
          onChange={onChangeStafferTitle}
          withAjaxAbort={false}
          autoload
          cacheOptions
        />
        {isFederal && (
          <>
            <Label className={styles.label}>
              Office type
            </Label>
            <Select
              value={officeType}
              options={OFFICE_TYPE_OPTIONS}
              onChange={onChangeOfficeType}
              hasNewStyle
              className={styles.officeType}
            />
            <LegislativeIssueArea
              value={issuesArea}
              className={styles.legislativeIssueArea}
              onChange={onChangeIssueArea}
              strategyInputs={strategyInputs}
              withAjaxAbort={false}
              autoload
              cacheOptions
            />
          </>
        )}
      </div>

      <div className={styles.footer}>
        <Button className={styles.footerButton} theme="light" onClick={onClose}>
          Cancel
        </Button>
        <Button className={styles.footerButton} onClick={handleSubmit}>
          Apply
        </Button>
      </div>
    </div>
  );
}

TooltipContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  issuesArea: PropTypes.array.isRequired,
  stafferTitles: PropTypes.array.isRequired,
  officeType: PropTypes.string.isRequired,
  onChangeOfficeType: PropTypes.func.isRequired,
  onChangeStafferTitle: PropTypes.func.isRequired,
  onChangeIssueArea: PropTypes.func.isRequired,
  isFederal: PropTypes.bool,
  isMobile: PropTypes.bool,
  id: PropTypes.string,
};

export default TooltipContent;
