import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import StatementFilterChecker from './StatementFilterChecker';
import { getStatementFiltersStyle } from '../theme';

function StatementFilters({
  isDraggingFilter,
  parentOperator,
  parentId,
  filters,
  deleteFilter,
}) {
  return (
    <Droppable droppableId={`droppableFiltersReorder:${parentId}`} type={parentId}>
      {(droppableProvided, droppableSnapshot) => (
        <div
          ref={droppableProvided.innerRef}
          style={getStatementFiltersStyle(droppableSnapshot.isDraggingOver)}
        >
          {filters.map((filter, index) => (
            <StatementFilterChecker
              key={index}
              keyPrefix={index}
              isLastOperator={filters.length - 1 === index}
              isDraggingOver={droppableSnapshot.isDraggingOver}
              isDraggingFilter={isDraggingFilter}
              parentOperator={parentOperator}
              draggableIndex={index}
              parentId={parentId}
              filter={filter}
              deleteFilter={deleteFilter}
            />
          ))}

          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

StatementFilters.propTypes = {
  isDraggingFilter: PropTypes.bool.isRequired,
  parentOperator: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
};

export default StatementFilters;
