import React from 'react';

import { TextPlaceholder } from 'components/core/Placeholder';
import styles from '../styles.module.scss';

export default function Placeholder() {
  return (
    <div className={styles.placeholder}>
      <TextPlaceholder className={styles.title} />
      {Array(20).fill(0).map((_, index) => (
        <TextPlaceholder key={`item-${index}`} className={styles.text} />
      ))}
    </div>
  );
}
