import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { filterOptions, getGroupedOptions } from './index';

function useCheckboxGroup({
  showSelectAll,
  options,
  isMobile,
  onItemCheck,
  onSearch,
  numColumns,
  checkedItems,
}) {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const groupedOptions = getGroupedOptions({
    query,
    isMobile,
    options: filteredOptions,
    numColumns,
    showSelectAll,
  });
  const areOptionsEmpty = isEmpty(filteredOptions);
  const isAllChecked = checkedItems.length === options.length;

  function searchItems(searchTerms) {
    const results = filterOptions(options, searchTerms);

    setFilteredOptions(results);
    setQuery(searchTerms);
  }

  function handleInputChange({ target }) {
    searchItems(target.value);
    onSearch && onSearch({ target });
  }

  function handleItemCheck(keyName) {
    return (event) => {
      onItemCheck(keyName, event);
    };
  }

  return {
    query,
    groupedOptions,
    handleInputChange,
    areOptionsEmpty,
    isAllChecked,
    onCheckItem: handleItemCheck,
  };
}

export default useCheckboxGroup;
