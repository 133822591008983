import {
  BASE_TOOLTIP,
  CHART_COLORS,
  BASE_GRID_LINES,
  BASE_TICK,
  BASE_OPTIONS,
  BASE_BORDER_TOP_GRID,
  BASE_BORDER_RIGHT_GRID,
} from 'utils/constants/charts';

// TODO remove democrat once backend changes
export const REPUBLICAN_PARTY_SLUG = 'republican';
export const DEMOCRAT_PARTY_SLUG = 'democrat';
export const DEMOCRATIC_PARTY_SLUG = 'democratic';

export const CHART_OPTIONS = {
  ...BASE_OPTIONS,
  tooltips: BASE_TOOLTIP,
  scales: {
    xAxes: [
      {
        maxBarThickness: 15,
        barThickness: 15,
        categoryPercentage: 1,
        ticks: { display: false },
        gridLines: { display: false },
      },
      BASE_BORDER_TOP_GRID,
    ],
    yAxes: [
      {
        ticks: {
          ...BASE_TICK,
          precision: 0,
          padding: 4,
          min: 0,
          maxTicksLimit: 5,
          minStepSize: 1,
        },
        gridLines: BASE_GRID_LINES,
      },
      BASE_BORDER_RIGHT_GRID,
    ],
  },
};

export const DEMOCRAT_COLORS = {
  borderColor: CHART_COLORS.DEMOCRATIC_BORDER_COLOR,
  backgroundColor: CHART_COLORS.DEMOCRATIC_BACKGROUND_COLOR,
};

export const REPUBLICAN_COLORS = {
  borderColor: CHART_COLORS.REPUBLICAN_BORDER_COLOR,
  backgroundColor: CHART_COLORS.REPUBLICAN_BACKGROUND_COLOR,
};

export const INDEPENDENT_COLORS = {
  borderColor: CHART_COLORS.INDEPENDENT_BORDER_COLOR,
  backgroundColor: CHART_COLORS.INDEPENDENT_BACKGROUND_COLOR,
};

// TODO remove democrat once backend changes
export const BAR_COLORS = {
  [REPUBLICAN_PARTY_SLUG]: REPUBLICAN_COLORS,
  [DEMOCRAT_PARTY_SLUG]: DEMOCRAT_COLORS,
  [DEMOCRATIC_PARTY_SLUG]: DEMOCRAT_COLORS,
};
