import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DataCard from 'components/core/Profile/components/DataCard';
import Loader from 'components/core/Loader';

import styles from './styles.module.scss';

function LoadingPlaceholder({ placeholderClassName, ...restProps }) {
  return (
    <DataCard title="" {...restProps}>
      <div className={classNames(styles.chartPlaceholder, placeholderClassName)}>
        <Loader message="Loading information ..." />
      </div>
    </DataCard>
  );
}

LoadingPlaceholder.propTypes = {
  placeholderClassName: PropTypes.string,
};

export default LoadingPlaceholder;
