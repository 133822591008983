import PropTypes from 'prop-types';

import { tableShape } from 'components/core/Profile/utils/shapes';

export const sectionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleDetail: PropTypes.string,
  titleDescription: PropTypes.node,
  tables: PropTypes.arrayOf(tableShape).isRequired,
  tablesClassName: PropTypes.string,
});
