import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ConfirmationModal as Modal } from 'components/core/Modal';
import useModal from 'components/core/Modal/useModal';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Medals({ medals }) {
  const { toggleModal, isOpenModal } = useModal();

  if (isEmpty(medals)) { return null; }

  return (
    <>
      <Button
        onClick={toggleModal}
        className={styles.medalButton}
        theme="light"
        size="none"
      >
        <i className="far fa-medal" />
      </Button>

      <Modal
        title="Medals"
        isOpen={isOpenModal}
        toggle={toggleModal}
        message={medals}
        messageContinue="Close"
        hideCancel
      />
    </>
  );
}

Medals.propTypes = {
  medals: PropTypes.string,
};

export default Medals;
