import cloneDeep from 'lodash/cloneDeep';

import { findNode } from '../dragEndActions/common';

export default function handleDroppedFilterGroup({ parentId, result, data, updateData }) {
  const draggedGroupWithFilter = {
    id: `filtergroup-${new Date().getTime()}`,
    type: 'filters_group',
    operator: {
      label: 'And',
      value: 'and',
    },
    filters: [{
      id: `filter-${new Date().getTime()}`,
      type: 'filter',
      dimension: result.draggableId,
    }],
  };

  const node = findNode(data, parentId);
  const { type, filter } = node || {};

  if (type !== 'statement') {
    // case when statement filter contain filter groups
    const newFilterGroup = cloneDeep(node);
    newFilterGroup.filters.splice(newFilterGroup.filters.length, 0, draggedGroupWithFilter);

    updateData({
      payload: JSON.parse(
        JSON.stringify(data).replace(
          JSON.stringify(node),
          JSON.stringify(newFilterGroup),
        ),
      ),
    });
  } else {
    // case when statement contain empty filter
    const { id } = filter || {};

    if (!id) {
      const newFilterGroup = cloneDeep(node);
      newFilterGroup.filter = {
        id: `filtergroup-${new Date().getTime()}0`,
        type: 'filters_group',
        operator: {
          label: 'And',
          value: 'and',
        },
        filters: [draggedGroupWithFilter],
      };

      updateData({
        payload: JSON.parse(
          JSON.stringify(data).replace(
            JSON.stringify(node),
            JSON.stringify(newFilterGroup),
          ),
        ),
      });
    } else {
      // case when statement filter contain empty filters
      handleDroppedFilterGroup({ result, data, updateData, parentId: id });
    }
  }
}
