import React from 'react';
import PropTypes from 'prop-types';

import { currencyFormat } from 'utils/formatters';
import { Column } from 'components/core/Table';

export default function MoneyColumn({ column, value }) {
  const { keyName } = column;
  const displayAmount = currencyFormat(Math.abs(value));

  function renderValue() {
    if (value >= 0) {
      return displayAmount;
    }

    return (
      <span>
        (<span>{displayAmount}</span>)
      </span>
    );
  }

  return <Column key={keyName} keyName={keyName}>{renderValue}</Column>;
}

MoneyColumn.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.number,
};
