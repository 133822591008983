import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import ButtonGroup from 'components/core/ButtonGroup';
import withSearch from 'components/core/SearchHeader/utils/withSearch';

import { TABLE, MAP } from '../../utils/constants';
import styles from './styles.module.scss';

function PageViewToggle({ pageView, isLoading }) {
  const { isTablePageView, onPageViewChange } = pageView;
  return (
    <ButtonGroup theme="light" className={classNames(styles.btnGroup, !isTablePageView && styles.mapView)}>
      <Button
        theme="default"
        size="small"
        className={classNames(styles.button, isTablePageView && styles.activeBtn)}
        onClick={onPageViewChange(TABLE)}
        disabled={isLoading}
      >
        <i className="far fa-table" />
      </Button>
      <Button
        theme="default"
        size="small"
        className={classNames(styles.button, !isTablePageView && styles.activeBtn)}
        onClick={onPageViewChange(MAP)}
        disabled={isLoading}
      >
        <i className="far fa-map-marked-alt" />
      </Button>
    </ButtonGroup>
  );
}

PageViewToggle.propTypes = {
  pageView: PropTypes.shape({
    onPageViewChange: PropTypes.func,
    isTablePageView: PropTypes.bool,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default withSearch(PageViewToggle);
