import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, over } from 'lodash';

import TrackModal from 'components/Dashboard/common/TrackModal';
import { listSubscriptionShape } from 'components/Dashboard/utils/shapes';
import { trackingShape } from 'redux/subscriptions/utils/shapes';
import { isMyFeed } from 'components/Dashboard/utils';
import Button from 'components/core/Button';
import { stringNumberType } from 'utils/shapes';

import Placeholder from './components/Placeholder';
import UntrackModal from './components/UntrackModal';
import useTrackButton from './utils/useTrackButton';
import {
  subscriptionInfoShape,
  subscriptionShape,
  apiShape,
} from './shapes';
import styles from './styles.module.scss';

function TrackButton({
  isCommittee,
  isLoadingTracking,
  isTracking,
  isMobile,
  isOpen,
  className,
  placeholderClassName,
  documentType,
  recordId,
  recordType,
  subscriptionInfo,
  itemType,
  itemTitle,
  defaultSubscription: initialFrequency,
  updateDataItem,
  updateParams,
  shouldDisplayIcon,
  searchId,
  listSubscriptions,
  updateCommitteeSubscriptions,
  withHeaderNode,
  frequencyTitle,
  untrackTitle,
  untrackDescription,
  customIcon,
  iconClassName,
  withButtonContent,
  api,
  onClick,
  feedToken,
  feedName,
  trackings,
  withSelectedWorkspace,
  id,
  shouldTrack,
}) {
  const {
    sendWeekAheadEmail: initialSendWeekAheadEmail,
    withWeekAheadEmail,
  } = subscriptionInfo || {};

  const {
    sendWeekAheadEmail,
    isOpenModal,
    isLoading,
    frequency,
    toggleModal,
    onChange,
    onChangeSendEmail,
    onSaveMobile,
    onSaveTrackCommittee,
    onSave,
    onRemove,
    workspaces,
    onCancel,
  } = useTrackButton({
    withSelectedWorkspace,
    isCommittee,
    feedToken,
    feedName,
    listSubscriptions,
    subscriptionInfo,
    recordId,
    recordType,
    itemType,
    itemTitle,
    updateDataItem,
    initialFrequency,
    initialSendWeekAheadEmail,
    withWeekAheadEmail,
    initialModalOpen: isOpen,
    searchId,
    api,
    trackings,
    updateCommitteeSubscriptions,
  });

  const shouldToggleModal = !feedToken || isMyFeed(feedToken) || isTracking;
  const handleButtonClick = shouldToggleModal ? toggleModal : onSave;

  useEffect(() => {
    if (!isLoadingTracking) {
      shouldTrack && handleButtonClick();
    }
  }, [shouldTrack, isLoadingTracking]);

  if (isLoadingTracking) {
    return <Placeholder className={placeholderClassName} />;
  }

  const iconClassNames = classNames(
    'fas fa-check-circle',
    isTracking && styles.iconTracked,
  );
  const icon = customIcon || <i className={iconClassNames} />;
  const buttonContent = isTracking ? 'Tracking' : 'Track';

  function renderModal() {
    const modalProps = {
      withWeekAheadEmail,
      sendWeekAheadEmail,
      isCommittee,
      isLoading,
      isMobile,
      isOpenModal,
      documentType,
      recordType,
      itemType,
      frequency,
      listSubscriptions,
      updateParams,
      toggleModal,
      onChange,
      onChangeSendEmail,
      onSaveMobile,
      onSave,
      onRemove,
      onUpdateTrackCommittee: onSaveTrackCommittee,
      onCancel,
      trackings,
    };

    if (isTracking) {
      const workspacesDescription = 'Are you sure you would like to untrack this item? Doing so will also remove it from your tracked items tab. You will also no longer receive email updates for this item. Any feed item previously populated will not be removed. Please select the feed you\'d like to untrack the item from:';
      const hasTrackings = !isEmpty(trackings);
      const withWorkspacesOptions = hasTrackings && gon.isWorkspacesEnabled;
      const defaultDescription = withWorkspacesOptions ? workspacesDescription : undefined;
      const description = untrackDescription || defaultDescription;

      return (
        <UntrackModal
          {...modalProps}
          title={untrackTitle}
          description={description}
          hasTrackings={hasTrackings}
          withWorkspacesOptions={withWorkspacesOptions}
        />
      );
    }

    return (
      <TrackModal
        {...modalProps}
        withHeaderNode={withHeaderNode}
        frequencyTitle={frequencyTitle}
        feedToken={feedToken}
        workspaces={workspaces}
        withSelectedWorkspace={withSelectedWorkspace}
        withSlideUp={withSelectedWorkspace}
      />
    );
  }

  return (
    <>
      {renderModal()}
      <Button
        size="small"
        theme="secondary"
        className={classNames(styles.button, className)}
        iconClassName={classNames(styles.icon, iconClassName)}
        disabled={isLoading}
        customIcon={shouldDisplayIcon && icon}
        onClick={over(handleButtonClick, onClick)}
        id={id}
      >
        {withButtonContent && buttonContent}
      </Button>
    </>
  );
}

TrackButton.defaultProps = {
  shouldDisplayIcon: true,
  withButtonContent: false,
  onClick: () => {},
  isOpen: false,
  shouldTrack: false,
  isLoadingTracking: false,
};

TrackButton.propTypes = {
  isMobile: PropTypes.bool,
  isLoadingTracking: PropTypes.bool,
  isCommittee: PropTypes.bool,
  isTracking: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldDisplayIcon: PropTypes.bool,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
  recordId: stringNumberType,
  documentType: PropTypes.string,
  recordType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  itemType: PropTypes.string,
  itemTitle: PropTypes.string,
  defaultSubscription: PropTypes.string,
  subscriptionInfo: subscriptionInfoShape,
  updateParams: PropTypes.shape({
    modelId: stringNumberType,
    id: stringNumberType,
  }),
  listSubscriptions: PropTypes.arrayOf(listSubscriptionShape),
  updateDataItem: PropTypes.func,
  searchId: stringNumberType,
  withHeaderNode: PropTypes.bool,
  frequencyTitle: PropTypes.string,
  untrackTitle: PropTypes.string,
  untrackDescription: PropTypes.string,
  customIcon: PropTypes.node,
  iconClassName: PropTypes.string,
  withButtonContent: PropTypes.bool,
  api: apiShape,
  onClick: PropTypes.func,
  feedToken: PropTypes.string,
  feedName: PropTypes.string,
  trackings: PropTypes.arrayOf(trackingShape),
  withSelectedWorkspace: PropTypes.bool,
  id: PropTypes.string,
  updateCommitteeSubscriptions: PropTypes.func,
  shouldTrack: PropTypes.bool,
};

export { subscriptionInfoShape, subscriptionShape };
export default TrackButton;
