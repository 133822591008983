import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import Select from 'components/core/Select';
import Label from 'components/core/Label';

import styles from './styles.module.scss';

function ButtonGroupResponsive({
  title,
  value,
  options,
  onChange,
  displaySelect = false,
}) {
  const renderField = () => {
    if (displaySelect) {
      return (
        <Select
          value={value}
          options={options}
          onChange={option => onChange(option.value)}
          searchable={false}
          clearable={false}
          hasNewStyle
        />
      );
    }

    return (
      <ButtonGroup theme="light" className={styles.buttonGroup}>
        {options.map(option => (
          <Button
            key={option.value}
            theme="default"
            onClick={() => onChange(option.value)}
            className={classNames(styles.button, option.value === value && styles.isActive)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <>
      <Label>{title}</Label>
      {renderField()}
    </>
  );
}

ButtonGroupResponsive.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  displaySelect: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default ButtonGroupResponsive;
