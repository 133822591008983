import React from 'react';
import PropTypes from 'prop-types';

import { SlideUpModal } from 'components/core/Modal';
import { optionShape } from '../../shapes';

import Button from '../Button';
import styles from './styles.module.scss';

function Modal({ isOpen, toggleOpen, onOptionClick, options, optionsList }) {
  const renderButton = currentOption => (
    <Button
      className={styles.button}
      iconClassName={styles.icon}
      key={currentOption}
      option={{
        ...options[currentOption],
        value: currentOption,
      }}
      onClick={onOptionClick}
      options={options}
    />
  );

  return (
    <SlideUpModal
      title="Position"
      isOpen={isOpen}
      bodyClassName={styles.modalBody}
      toggleOpen={toggleOpen}
    >
      {optionsList.map(renderButton)}
    </SlideUpModal>
  );
}

Modal.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.objectOf(optionShape).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

export default Modal;
