import { useState } from 'react';

function useComment({
  comment,
  onEdit,
  onDelete,
}) {
  const { content, id } = comment;
  const [mentions, setMentions] = useState([]);
  const [fieldContent, setFieldContent] = useState(content);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const disableEdit = () => setIsEditing(false);

  const handleCancelEdit = () => {
    setFieldContent(content);
    disableEdit();
  };

  const handleSaveEdit = () => {
    if (content !== fieldContent) {
      setShowSaveLoader(true);
      onEdit({ id, text: fieldContent, mentions })
        .then(() => {
          setShowSaveLoader(false);
          disableEdit();
        });
    } else {
      handleCancelEdit();
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    return onDelete(id).then(() => setIsDeleting(false));
  };

  const handleCommentChange = ({ target: { value } }) => setFieldContent(value);

  const handleMentionsChange = newMentions => setMentions(newMentions);

  return {
    fieldContent,
    isEditing,
    isDeleting,
    showSaveLoader,
    setIsEditing,
    handleDelete,
    handleCommentChange,
    handleSaveEdit,
    handleCancelEdit,
    handleMentionsChange,
  };
}

export default useComment;
