import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';

import styles from '../styles.module.scss';

function CardButtonGroup({
  isEnabled,
  setIsEnable,
  setIsDisable,
  enabledLabel,
  disabledLabel,
}) {
  return (
    <ButtonGroup theme="light" className={styles.btnGroup}>
      <Button
        theme="default"
        size="small"
        className={classNames(isEnabled && styles.activeBtn)}
        onClick={setIsEnable}
      >
        {enabledLabel}
      </Button>
      <Button
        theme="default"
        size="small"
        className={classNames(!isEnabled && styles.activeBtn)}
        onClick={setIsDisable}
      >
        {disabledLabel}
      </Button>
    </ButtonGroup>
  );
}

CardButtonGroup.defaultProps = {
  enabledLabel: 'Enabled',
  disabledLabel: 'Disabled',
};

CardButtonGroup.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  setIsEnable: PropTypes.func.isRequired,
  setIsDisable: PropTypes.func.isRequired,
  enabledLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
};

export default CardButtonGroup;
