/** Common tracking functions */
export function trackSaveSearchDeletedItem(trackFunction) {
  trackFunction('save search deleted');
}

export function trackRecentSearchSavedItem(trackFunction) {
  trackFunction('search saved');
}

export function trackSearchedItems(trackFunction) {
  trackFunction('searched');
}

export function trackAllResultsEmailed(trackFunction) {
  trackFunction('all results emailed');
}

export function trackResultEmailed(trackFunction) {
  trackFunction('result emailed');
}

export function trackAllResultsExported(trackFunction) {
  trackFunction('all results exported');
}

export function trackResultsExported(trackFunction) {
  trackFunction('results exported');
}

export function trackResultSearched(trackFunction) {
  trackFunction('searched');
}

export function trackTableViewVisited(trackFunction) {
  trackFunction('table view visited');
}
export function trackMapViewVisited(trackFunction) {
  trackFunction('map view visited');
}
