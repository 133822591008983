import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import {
  trackFederalCommitteesFiltered,
  trackStateCommitteesFiltered,
} from 'utils/tracking/intel';

import withCalendar from '../../utils/withCalendar';
import { typeShape } from '../../utils/shapes';
import constants from '../../utils/constants';
import styles from './styles.module.scss';

function Filters({
  type,
  filterByState,
  filterByCommittee,
}) {
  const { [type]: {
    onChangeAction,
    onChangeTrack,
    ...tagInputProps
  } } = {
    [constants.FEDERAL]: {
      name: 'committee',
      strategy: 'federal_committee',
      placeholder: 'Choose which committee(s) to display',
      onChangeTrack: trackFederalCommitteesFiltered,
      onChangeAction: filterByCommittee,
    },
    [constants.STATE]: {
      name: 'state',
      strategy: 'mappy_state_alpha2',
      placeholder: 'Choose which state(s) to display',
      onChangeTrack: trackStateCommitteesFiltered,
      onChangeAction: filterByState,
    },
  };

  function handleChange(selectedItems) {
    onChangeAction(selectedItems);
    onChangeTrack();
  }

  return (
    <div className={classNames('hearing-filters', styles.filters)}>
      <div className={classNames('hearing-filters-input', styles.input)}>
        <TagInput {...tagInputProps} onChange={handleChange} />
      </div>
    </div>
  );
}

Filters.propTypes = {
  type: typeShape.isRequired,
  filterByCommittee: PropTypes.func,
  filterByState: PropTypes.func,
};

export default withCalendar(Filters);
