import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Footer({ children, className }) {
  return (
    <div className={classNames(styles.footer, className)}>
      {children}
    </div>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Footer;
