import isEmpty from 'lodash/isEmpty';

/**
 * Parser to TagInput component options
 * If receive a item with a null value, creates a fake value (id) to allow user insertion
 */
export default function optionsParser(allowCreate) {
  return (options) => {
    if (isEmpty(options)) {
      return [];
    }

    if (!allowCreate) {
      return options.filter(option => option.value !== null);
    }

    const firstOption = options[0];
    if (options.length === 1 && firstOption.value === null) {
      return [{
        label: firstOption.label,
        value: firstOption.label,
      }];
    }

    return options;
  };
}
