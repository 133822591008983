import PropTypes from 'prop-types';

export const uiShape = PropTypes.shape({
  isInitialLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  isLoadingMapData: PropTypes.bool.isRequired,
  isLoadingLists: PropTypes.bool.isRequired,
  isLoadingAllEmails: PropTypes.bool.isRequired,
  isApplyingLists: PropTypes.bool.isRequired,
  isApplyingTags: PropTypes.bool.isRequired,
  toResetSelectedItems: PropTypes.bool.isRequired,
  isResetFilters: PropTypes.bool.isRequired,
  isLoadingDistrictStakeholdersMetadata: PropTypes.bool.isRequired,
});

export const stakeholderShape = PropTypes.shape({
  id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  middle_name: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  organization: PropTypes.string,
  party_code: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.string,
  lists: PropTypes.string,
  custom_fields: PropTypes.string,
  facebook_profile: PropTypes.string,
  twitter_username: PropTypes.string,
  url: PropTypes.string,
});
