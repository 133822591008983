import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import Item from './components/Item';
import { fieldColumnsLabels, getFieldTypeDescription } from '../../utils/constants';
import { allCustomFieldsItemShape } from '../../utils/shapes';
import styles from './styles.module.scss';

function MobileCardView({ data, renderRowActions }) {
  const { isMobile, isTablet } = useMediaQuery();
  const isMobileOrTablet = isMobile || isTablet;

  if (!isMobileOrTablet) {
    return null;
  }

  function getContent(itemName, itemValue) {
    if (itemName === 'fieldType') {
      return getFieldTypeDescription(itemValue);
    }

    return itemValue;
  }

  function renderCard(item, index) {
    return (
      <div
        key={`cardMobile-${index}`}
        className={styles.itemCard}
      >
        {Object.keys(item).map(key => (
          <Item
            key={`${key}-${index}`}
            title={fieldColumnsLabels[key]}
            content={getContent(key, item[key])}
          />
        ))}

        {renderRowActions(item.actions)}
        <hr />
      </div>
    );
  }

  const emptyContent = <div>Sorry, the requested data is currently unavailable</div>;

  return (
    <div className={styles.card}>
      {isEmpty(data) ? emptyContent : data.map(renderCard)}
    </div>
  );
}

MobileCardView.propTypes = {
  data: PropTypes.arrayOf(allCustomFieldsItemShape).isRequired,
  renderRowActions: PropTypes.func.isRequired,
};

export default MobileCardView;
