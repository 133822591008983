import { useEffect, useState } from 'react';

import GlobalNotifier from 'components/GlobalNotifier';

import { addDefaultLayers } from './index';
import { mapStyleUrl } from './constants';
import useMapFilters from './useMapFilters';

function useMap({
  mapRef,
  data,
  defaultFilters,
  withNavigation,
  withFullscreen,
  withFilters,
  isLoadingMap,
  center,
  withDisabledZoom,
  zoom = 2,
}) {
  const [map, setMap] = useState(null);
  const [activeFilters, setActiveFilters] = useState(defaultFilters);
  const { resetFilters, setFilters } = useMapFilters(map);
  const navigationControl = new mapboxgl.NavigationControl();
  const fullscreenControl = new mapboxgl.FullscreenControl();

  function onMapStylesLoad(mapInstance) {
    mapInstance.once('styledata', () => {
      addDefaultLayers(mapInstance);
    });
  }

  function onChangeFilter(filterKey, filterValue) {
    map && map.loaded() && map.isStyleLoaded() && setActiveFilters(prevState => ({ ...prevState, [filterKey]: filterValue }));
  }

  function enableMapNavigation(mapInstance) {
    mapInstance.addControl(navigationControl);
  }

  function enableFullscreen(mapInstance) {
    mapInstance.addControl(fullscreenControl);
  }

  function initMap() {
    if (map) {
      map.remove();
    }

    mapboxgl.accessToken = gon.mapboxAccessToken;
    let mapInstance;

    const settings = {
      container: mapRef.current,
      style: mapStyleUrl,
      zoom,
      center,
    };

    if (mapRef.current && !isLoadingMap) {
      try {
        mapInstance = new mapboxgl.Map(settings);

        withNavigation && enableMapNavigation(mapInstance);
        withFullscreen && enableFullscreen(mapInstance);
        setMap(mapInstance);
        setActiveFilters(defaultFilters);
      } catch (error) {
        const message = 'An error occurred during the map loading. Please try again.';
        GlobalNotifier.addNotification({ level: 'error', message });

        if (window.Sentry) {
          window.Sentry.captureMessage(error);
        }
      }
    }
  }

  useEffect(initMap, [data]);

  useEffect(() => {
    if (map) {
      withFilters && onMapStylesLoad(map);
      withDisabledZoom && map.scrollZoom.disable();
    }
  }, [map]);

  return {
    map,
    activeFilters,
    onChangeFilter,
    setFilters,
    resetFilters,
  };
}

export default useMap;
