import { pluralize } from 'utils/string';

import styles from './styles.module.scss';

function getCharsMessage(charsLeft, charsLeftAbs) {
  const characters = pluralize('character', 'characters', charsLeftAbs);

  if (charsLeft < 0) {
    return `${characters} beyond the limit`;
  }

  return `${characters} left`;
}

function getCharsClassName(charsLeft) {
  if (charsLeft < 10) {
    return styles.danger;
  }

  return null;
}

function getCharInfo({ content, limit }) {
  const chars = content.length;
  const charsLeft = limit - chars;
  const charsLeftAbs = Math.abs(charsLeft);

  return {
    charsLeft: charsLeftAbs,
    charsMessage: getCharsMessage(charsLeft, charsLeftAbs),
    charsClassName: getCharsClassName(charsLeft),
  };
}

export default getCharInfo;
