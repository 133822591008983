import React, { createRef } from 'react';

import Tooltip from 'components/core/Tooltip';
import Button from 'components/core/Button';

import TooltipContent from './components/TooltipContent';
import styles from './styles.module.scss';

function RecordTypesButton() {
  const tooltipRef = createRef();
  const handleClose = () => setTimeout(tooltipRef.current.toggleHint, 0);
  const getTooltip = () => <TooltipContent onClose={handleClose} />;

  return (
    <Tooltip
      content={getTooltip}
      persist
      className={styles.tooltip}
      forwardRef={tooltipRef}
      hintEvent="click"
    >
      <div className={styles.container}>
        <Button
          theme="tertiary"
          className={styles.filterButton}
          customIcon={<i className="fas fa-filter" />}
        >
          Filters
        </Button>
      </div>
    </Tooltip>
  );
}

export default RecordTypesButton;
