import { useEffect } from 'react';
import { toggleActionsOffset } from 'utils/freshchat';

function useChatPosition(areItemsEmpty, withOffset = false, persist = false) {
  function fixChatPosition() {
    const bodyClassList = document.body.classList;
    const className = 'frame-bottom';

    if (persist) {
      return bodyClassList.add(className);
    }

    return areItemsEmpty
      ? bodyClassList.remove(className)
      : bodyClassList.add(className);
  }

  useEffect(() => {
    withOffset && toggleActionsOffset(persist || !areItemsEmpty);
    fixChatPosition();
  }, [areItemsEmpty, persist]);
}

export default useChatPosition;
