import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Tabs, { Tab } from 'components/core/Tabs';
import ResponsiveTooltipContainer from 'components/core/Tooltip/components/ResponsiveTooltipContainer';
import SearchableCheckboxGroup from 'components/core/Checkbox/components/SearchableCheckboxGroup';
import Title from './components/Title';
import EmptyMessage from './components/EmptyMessage';
import CustomFieldsIcon from './components/CustomFieldsIcon';
import styles from './styles.module.scss';

export default function Dimensions({
  hidden,
  visibleColumns,
  columns,
  onClose,
  onToggle,
  isMobile,
}) {
  const dimensionTypes = [...new Set(columns.map(column => column.dimensionType))];

  function handleColumnToggle(keyName) {
    onToggle(keyName, !hidden[keyName]);
  }

  function renderTab(dimensionType, index) {
    function renderNoResultsPlaceholder(query) {
      return <EmptyMessage query={query} isMobile={isMobile} />;
    }

    const checkedItems = !isEmpty(visibleColumns) ? visibleColumns.map(({ keyName }) => keyName) : [];
    const tabOptions =
      columns.filter(option => option.dimensionType === dimensionType);

    return (
      <Tab key={index} id={index} title={dimensionType}>
        <SearchableCheckboxGroup
          options={tabOptions}
          numColumns={2}
          labelGroupClassName={styles.dimensions}
          onItemCheck={handleColumnToggle}
          checkedItems={checkedItems}
          renderNoResultsPlaceholder={renderNoResultsPlaceholder}
          isMobile={isMobile}
        />
      </Tab>
    );
  }

  return (
    <ResponsiveTooltipContainer className={styles.container}>
      <Title onClose={onClose} title="Customize table" />
      <Tabs
        className={styles.tabsContainer}
        tabContentClassName={styles.tabContent}
        sideContent={!isMobile && <CustomFieldsIcon />}
        mobileLayout="tabs"
      >
        {dimensionTypes.map(renderTab)}
      </Tabs>
      {!isMobile && (<div className={styles.footer} />)}
    </ResponsiveTooltipContainer>
  );
}

const columnShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
});

Dimensions.propTypes = {
  onClose: PropTypes.func,
  visibleColumns: PropTypes.array,
  columns: PropTypes.arrayOf(columnShape).isRequired,
  onToggle: PropTypes.func,
  hidden: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};
