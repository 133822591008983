import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import themes from './themes.module.scss';
import styles from './styles.module.scss';

function ColorPicker({ theme, value, onChange, ...restProps }) {
  const [backgroundColor, setBackgroundColor] = useState(value);
  const handleChange = ({ hex }) => {
    setBackgroundColor(hex);
    onChange && onChange(hex);
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleOpen = () => setDisplayColorPicker(true);
  const handleClose = () => setDisplayColorPicker(false);

  useEffect(() => {
    setBackgroundColor(value);
  }, [value]);

  return (
    <Fragment>
      <div className={themes[theme]} style={{ backgroundColor }} onClick={handleOpen} />

      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={backgroundColor}
            onChange={handleChange}
            {...restProps}
          />
        </div>
      )}
    </Fragment>
  );
}

// Need a ColorPicker preview with different shape? Add a new theme.
ColorPicker.defaultProps = {
  theme: 'default',
  value: '#ffffff',
};

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  theme: PropTypes.string,
};


export default ColorPicker;
