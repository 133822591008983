import React from 'react';
import ReactDOM from 'react-dom';

import Popup from '../components/Popup';

function getPopupSettings() {
  const markerHeight = 40;
  const markerRadius = 30;
  const linearOffset = 10;

  const popupOffsets = {
    top: [0, markerHeight / 2],
    'top-left': [0, markerHeight / 2],
    'top-right': [0, markerHeight / 2],
    bottom: [0, -markerHeight + linearOffset],
    'bottom-left': [0, -(markerHeight / 2)],
    'bottom-right': [0, -(markerHeight / 2)],
    left: [markerRadius, (markerHeight - markerRadius - linearOffset) * -1],
    right: [-markerRadius, (markerHeight - markerRadius - linearOffset) * -1],
  };

  return {
    offset: popupOffsets,
    closeButton: false,
    maxWidth: '290px',
    className: 'map-popup',
  };
}

function getPopupNode(popupProps) {
  const popupNode = document.createElement('div');
  popupNode.classList.add('map-popup-container');

  const PopupComponent = popupProps.PopupComponent || Popup;

  ReactDOM.render(<PopupComponent {...popupProps} />, popupNode);

  return popupNode;
}

export function getMapPopup({ properties, onFetch, profileUrl, PopupComponent }) {
  const settings = getPopupSettings();
  const popupProps = { properties, onFetch, profileUrl, PopupComponent };
  const popupNode = getPopupNode(popupProps);

  return new mapboxgl.Popup(settings)
    .setDOMContent(popupNode);
}
