import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import PartyIcon from 'components/shared/party-icon';

import styles from './styles.module.scss';

export default function PartyIconComponent({ party, className }) {
  if (isEmpty(party)) {
    return null;
  }

  const partyLowerCase = `${party}`.toLowerCase();
  const partyShort = party[0];

  return (
    <div className={classNames(styles.partyIcon, className)}>
      <PartyIcon partySlug={partyLowerCase} partyShort={partyShort} />
    </div>
  );
}

PartyIconComponent.propTypes = {
  party: PropTypes.string.isRequired,
  className: PropTypes.string,
};
