import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function navigationButton({
  onClick,
  disabled,
  isNext,
}) {
  const chevronDirection = isNext ? 'right' : 'left';

  return (
    <button type="button" className={styles.button} onClick={onClick} disabled={disabled}>
      <i className={`fa fa-chevron-${chevronDirection}`} />
    </button>
  );
}

navigationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isNext: PropTypes.bool,
};

navigationButton.defaultProps = {
  disabled: false,
  isNext: false,
};

export default navigationButton;
