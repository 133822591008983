import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import usePaginator from '../../usePaginator';
import styles from './styles.module.scss';

function LoadMorePaginator({
  pagination,
  isEmptyData,
  isLoading,
  isInitialLoading,
  onLoad,
  customLoader,
  loaderProps,
  buttonClassName,
  loaderClassName,
  className,
  theme,
  title,
  size,
  withToken,
}) {
  const { onLoadMore: onClick } = usePaginator({ onChange: onLoad });
  const { nextPage } = pagination || {};

  const isPaginatorPresent = !isInitialLoading && !isEmptyData;

  if (!isPaginatorPresent) {
    return null;
  }

  function handleLoad() {
    if (withToken) {
      return onLoad(nextPage);
    }

    return onClick(nextPage);
  }

  function renderLoader() {
    if (customLoader) return customLoader;

    return (
      <Loader
        {...loaderProps}
        className={classNames(styles.loader, loaderClassName)}
      />
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Button
        theme={theme}
        size={size}
        className={classNames(styles.button, buttonClassName)}
        onClick={handleLoad}
        disabled={isLoading}
      >
        {isLoading
          ? renderLoader()
          : <span>{title}</span>
        }
      </Button>
    </div>
  );
}

LoadMorePaginator.defaultProps = {
  title: 'Load more',
  theme: 'secondary',
  isEmptyData: false,
  isLoading: false,
  isInitialLoading: false,
  withToken: false,
};

LoadMorePaginator.propTypes = {
  onLoad: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  isEmptyData: PropTypes.bool,
  withToken: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  loaderProps: PropTypes.object,
  customLoader: PropTypes.node,
  theme: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default LoadMorePaginator;
