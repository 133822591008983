import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';

import KEY_CODES from 'utils/keyCodes';

import TextareaWithMentions from 'components/core/TextareaWithMentions';

function CommentInput({
  isEditable,
  parentId,
  fieldRef,
  onCtrlEnterDown,
  value,
  ...restProps
}) {
  const mentionsData = [
    {
      trigger: '@',
      strategySlug: 'people',
    },
    {
      trigger: '#',
      strategySlug: 'bill',
    },
  ];

  const innerRef = useRef(null);
  const inputRef = fieldRef || innerRef;

  const parent = document.getElementById(parentId);

  useEffect(() => {
    const { current } = inputRef;

    if (isEditable && current) {
      const lastPosition = size(value);

      current.focus();
      current.setSelectionRange(lastPosition, lastPosition);
    }
  }, [isEditable]);

  const handleCtrlEnterDown = (event) => {
    const isEnter = event.keyCode === KEY_CODES.ENTER;
    (event.ctrlKey || event.metaKey) && isEnter && onCtrlEnterDown();
  };

  return (
    <TextareaWithMentions
      value={value}
      mentionsData={mentionsData}
      disabled={!isEditable}
      inputRef={inputRef}
      onKeyDown={handleCtrlEnterDown}
      allowSpaceInQuery
      suggestionsPortalHost={parent}
      {...restProps}
    />
  );
}

CommentInput.defaultProps = {
  isEditable: true,
};

CommentInput.propTypes = {
  // eslint-disable-next-line
  fieldRef: PropTypes.object,
  value: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onCtrlEnterDown: PropTypes.func.isRequired,
};

export default CommentInput;
