import React from 'react';
import { map, isEmpty } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getEducationItemContent } from 'components/core/Profile/components/Cards/Education/utils';

import TableSection from '../../TableSection';

function Education({ person }) {
  const { education } = person || {};

  if (isEmpty(education)) {
    return null;
  }

  const tables = [
    map(education, (educationItem, index) => ({
      label: `Education ${index + 1}`,
      value: getEducationItemContent(educationItem),
    })),
  ];

  return (
    <TableSection
      title="Education"
      tables={tables}
    />
  );
}

Education.propTypes = {
  person: personShape,
};

export default Education;
