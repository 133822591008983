import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';

import styles from '../styles.module.scss';

function InputComponent({
  getInputProps,
  autoComplete,
  className,
  isLoading,
  placeholder,
  withInputSelect,
  isError,
}) {
  const inputProps = getInputProps({
    autoComplete,
    className,
    placeholder,
    isError,
  });

  const loadingIcon = (
    <span className={classNames(styles.loadingIcon, withInputSelect && styles.withInputSelect)}>
      <i className={classNames('far', 'fa-circle-notch', 'fa-spin')} />
    </span>
  );

  return (
    <Fragment>
      <Input {...inputProps} />

      {isLoading && loadingIcon}
    </Fragment>
  );
}

InputComponent.defaultProps = {
  autoComplete: 'none',
  className: 'form-control',
  isLoading: false,
  withInputSelect: false,
};

InputComponent.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  getInputProps: PropTypes.func.isRequired,
  withInputSelect: PropTypes.bool,
  isError: PropTypes.bool,
};

export default InputComponent;
