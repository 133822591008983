import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Title from 'components/core/Profile/components/Title';
import TagInput from 'components/core/TagInput';
import theme from 'components/core/TagInput/theme';
import Select from 'components/core/Select';

import useSuggesterItem from './useSuggesterItem';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

function SuggesterItem({
  title,
  description,
  onChange,
  strategy,
  strategyInputs,
  disabled,
  options,
  multi,
  withRichOption,
  isVisible,
  rigthItems,
}) {
  const inputClassName = classNames(
    disabled && styles.disabledInput
  );

  const selectClassName = classNames(
    styles.select,
    disabled && styles.disabledInput,
  );

  const {
    inputValue,
    updateInputValue,
  } = useSuggesterItem({
    onChange,
    disabled,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <Title
        title={title}
        description={description}
        enableTooltip
        className={parentStyles.titleWithTooltip}
        titleClassName={parentStyles.title}
        rightItems={rigthItems}
      />

      {strategy && <TagInput
        strategy={strategy}
        strategyInputs={strategyInputs}
        onChange={updateInputValue}
        disabled={disabled}
        overrideValue={inputValue}
        shouldOverrideValue
        multiple={multi}
        withRichOption={withRichOption}
        className={inputClassName}
      />}

      {!strategy && <Select
        options={options}
        onChange={updateInputValue}
        isDisabled={disabled}
        customTheme={theme()}
        multi={multi}
        value={inputValue}
        className={selectClassName}
      />}
    </Fragment>
  );
}

SuggesterItem.defaultProps = {
  withRichOption: false,
  isVisible: true,
};

SuggesterItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  strategy: PropTypes.string,
  strategyInputs: PropTypes.shape({
    type: PropTypes.string,
    state: PropTypes.arrayOf(
      PropTypes.string,
    ),
    congressional_district: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }),
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  withRichOption: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  isVisible: PropTypes.bool,
  rigthItems: PropTypes.node,
};

export default SuggesterItem;
