import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TitleWithTooltip from 'components/core/TitleWithTooltip';
import Tooltip from 'components/core/Table/components/Header/components/Tooltip';

import styles from './styles.module.scss';

function Title({
  title,
  detail,
  description,
  className,
  titleClassName,
  descriptionClassName,
  rightItems,
  questionText,
  questionTooltipTheme,
  enableTooltip,
  titleContainerClassName,
}) {
  if (enableTooltip) {
    return (
      <TitleWithTooltip
        title={title}
        description={description}
        className={className}
        titleClassName={titleClassName}
        rightItems={rightItems}
      />
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.titleContainer, titleContainerClassName)}>
        <div className={styles.flexItem}>
          {title && (
            <h2 className={classNames(styles.title, titleClassName)}>
              {title}
              {detail && <span className={styles.detail}>{detail}</span>}
            </h2>
          )}
          {questionText && (
            <Tooltip
              content={<div className={styles.tooltipContent}>{questionText}</div>}
              theme={questionTooltipTheme}
            />
          )}
        </div>

        {rightItems && <div>{rightItems}</div>}
      </div>

      {description && (
        <div className={classNames(styles.description, descriptionClassName)}>{description}</div>
      )}
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  rightItems: PropTypes.node,
  questionText: PropTypes.string,
  questionTooltipTheme: PropTypes.string,
  enableTooltip: PropTypes.bool,
  titleContainerClassName: PropTypes.string,
};

export default Title;
