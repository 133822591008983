import { useState, useEffect } from 'react';
import { every, isEmpty } from 'lodash';

import Notifications from './Notifications';

function useStaffers({ id, fetchStaffers, slug, customTransformers }) {
  const [keywordSearch, setKeywordSearch] = useState('');
  const [stafferTitles, setStafferTitles] = useState([]);
  const [officeType, setOfficeType] = useState('');
  const [issuesArea, setIssuesArea] = useState([]);
  const [searchData, setSearchData] = useState({});
  const filters = [keywordSearch, stafferTitles, officeType, issuesArea];
  const areFiltersApplied = !every(filters, isEmpty);

  function handleSearch(customData) {
    if (id) {
      fetchStaffers({
        id,
        slug,
        stafferTitles,
        issuesArea,
        officeType,
        ...searchData,
        ...customData,
      }, customTransformers)
        .catch((error) => {
          Notifications.defaultFetchDataFailed();
          throw error;
        });
    }
  }

  function handleChangeKeywordSearch(event) {
    const { value } = event.target;
    setKeywordSearch(value);
  }

  function handleChangeStafferTitle(_, value) {
    setStafferTitles(value);
  }

  function handleChangeIssueArea(_, value) {
    setIssuesArea(value);
  }

  function handleChangeOfficeType(option) {
    const { value = '' } = option || {};

    setOfficeType(value);
  }

  function handleSubmit() {
    setSearchData({ keywordSearch, stafferTitles });
  }

  function handleClearAll() {
    setKeywordSearch('');
    setStafferTitles([]);
    setIssuesArea([]);
    setSearchData({});
    setOfficeType('');
  }

  useEffect(handleSearch, [searchData, id]);

  return {
    handleSearch,
    keywordSearch,
    stafferTitles,
    issuesArea,
    officeType,
    areFiltersApplied,
    onChangeKeywordSearch: handleChangeKeywordSearch,
    onChangeStafferTitle: handleChangeStafferTitle,
    onChangeIssueArea: handleChangeIssueArea,
    onChangeOfficeType: handleChangeOfficeType,
    onSubmit: handleSubmit,
    onClearAll: handleClearAll,
  };
}

export default useStaffers;
