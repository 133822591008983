import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Error from 'components/core/Table/components/Error';
import useChatPosition from 'components/core/ActionBar/useChatPosition';
import { PaginatorPlaceholder } from 'components/core/Placeholder';
import Paginator from 'components/core/Paginator';
import Table from 'components/core/Table';

import useTable from '../../utils/useTable';
import withLookup from '../../utils/withLookup';
import { EXPORT_OPTIONS } from '../../utils/constants';
import useFetchData from './utils/useFetchData';
import FooterActions from './components/FooterActions';
import styles from './styles.module.scss';

function TableView({
  dataProps,
  dataLookup,
  tableData,
  advancedProps,
  advancedSearch,
  actions,
  notifications,
  pageView,
  tracking,
  columnOptions,
}) {
  const {
    id,
    uniqueKey = 'id',
    title,
    columns,
    sort,
    paginator,
    renderTableColumn,
    renderExpandedRow,
    customEmptyMessage,
    isEmptyFooterActions,
    renderFooterActions,
    tableCustomComponents,
    useRenderFooterActions,
    useTableCustomComponents,
    defaultHiddenColumns,
    withContextualMenu = false,
    tableWrapperClassname,
    isAlignedToTop,
    fixedEmptyMessage,
  } = tableData;
  const { fetchData, submitExport } = actions;
  const { ui, isMobile, isDesktopLarge, exportReport, allData, allDataError, appliedFilters } = dataLookup;
  const isHiddenTable = !isEmpty(pageView) && !pageView.isTablePageView;
  const { isLoadingData, toResetSelectedItems, isInitialLoading } = ui;
  const { tableProps, paginatorProps } = advancedProps;
  const { onChangeFilter } = columnOptions || {};

  const {
    selectedItems,
    isSelectedItemsPresent,
    sortData,
    getDirection,
    handleSelectItems,
    handleClearItems,
    hiddenColumns,
    handleHideColumn,
  } = useTable({
    data: allData,
    sort,
    isLoading: isLoadingData,
    defaultHiddenColumns,
    toResetSelectedItems,
  });

  const { isEstimated, current, total, totalCount, totalItems } = paginator;
  const isPaginatorPresent = !isEmpty(allData) && !isEmpty(paginator) && total >= 1;

  useChatPosition(false);

  const actionsMap = {
    ...exportReport,
    submitExport,
    exportModalTitle: `Export ${title}`,
    exportModalOptions: EXPORT_OPTIONS,
  };

  const advancedData = advancedSearch && advancedSearch.data
    ? { data: advancedSearch.data }
    : {};

  const filters = {
    ...advancedData,
    appliedFilters,
    dataProps,
  };

  const { defaultFetchDataFailed: notifyDefaultFetchDataFailed } = notifications || {};

  const { handleFetchData } = useFetchData({
    fetchData,
    pageView,
    notifyDefaultFetchDataFailed,
    filters,
  });

  function handleSortByColumn(column) {
    handleFetchData({
      page: 1,
      sort: column,
      direction: getDirection(column),
      isSortColumn: true,
    });
  }

  function handleChangePage(page) {
    const { column, direction } = sortData;

    handleFetchData({
      page,
      direction,
      sort: column,
    });
  }

  const errorMessage = allDataError && {
    customEmptyMessage: customEmptyMessage || <Error reload={handleFetchData} />,
  };

  function handleColumnToggle(columnKeyName, isHidden) {
    handleHideColumn(columnKeyName, isHidden);
  }

  const onColumnToggle = withContextualMenu ? handleColumnToggle : null;

  function footerActionsNode() {
    if (isEmptyFooterActions) return null;

    const commonProps = {
      tracking,
      actionsMap,
      selectedItems,
      data: allData,
      paginator,
      ui,
      isFixed: false,
      onClear: handleClearItems,
      fetchData: handleFetchData,
      className: styles.actionBar,
    };

    return renderFooterActions
      ? renderFooterActions({ ...commonProps, ...useRenderFooterActions })
      : <FooterActions {...commonProps} />;
  }

  function renderTableComponents() {
    if (isInitialLoading) return <PaginatorPlaceholder className="paginator-placeholder" />;
    if (!isPaginatorPresent) {
      return tableCustomComponents ? tableCustomComponents(useTableCustomComponents) : null;
    }

    return (
      <>
        {isSelectedItemsPresent && footerActionsNode()}

        {tableCustomComponents && tableCustomComponents(useTableCustomComponents)}

        <Paginator
          {...paginatorProps}
          current={current}
          total={total}
          onChange={handleChangePage}
          totalItems={totalCount || totalItems}
          isMobile={isMobile}
          isEstimated={isEstimated}
          isLoading={isLoadingData}
          className={classNames(styles.paginator, isSelectedItemsPresent && styles.margin)}
        />
      </>
    );
  }

  return (
    <div className={classNames(styles.tableContainer, { hidden: isHiddenTable })}>
      <Table
        {...errorMessage}
        {...tableProps}
        containerStyle={`${id}__data-results`}
        uniqueKey={uniqueKey}
        isLoading={isLoadingData}
        columns={columns}
        data={allData}
        selectedItems={selectedItems}
        onSelectItems={handleSelectItems}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={handleSortByColumn}
        tableContainerClassname={classNames(styles.tableWrapper, tableWrapperClassname)}
        renderExpandedRow={renderExpandedRow}
        isMobile={isMobile}
        isDesktopLarge={isDesktopLarge}
        isPaginatorPresent={isPaginatorPresent}
        onColumnToggle={onColumnToggle}
        hiddenColumns={hiddenColumns}
        fullWidthTable
        isCheckboxPresent
        isAlignedToTop={isAlignedToTop}
        preserveCheckboxes
        isDefaultTable
        isFixed
        fixedEmptyMessage={fixedEmptyMessage}
      >
        {columns.map(props => renderTableColumn({
          ...props,
          ...useTableCustomComponents,
          onChangeFilter,
          dataLength: allData.length,
          fetchData: handleFetchData,
          allData,
          onClear: handleClearItems,
        }))}
      </Table>

      {renderTableComponents()}
    </div>
  );
}

TableView.defaultProps = {
  advancedProps: {
    tableProps: {},
    paginatorProps: {},
  },
};

TableView.propTypes = {
  dataLookup: PropTypes.object.isRequired,
  dataProps: PropTypes.object,
  advancedSearch: PropTypes.object,
  tableData: PropTypes.object.isRequired,
  advancedProps: PropTypes.object,
  actions: PropTypes.object.isRequired,
  pageView: PropTypes.object.isRequired,
  tracking: PropTypes.object,
  notifications: PropTypes.func,
  columnOptions: PropTypes.shape({
    onChangeFilter: PropTypes.func,
  }),
};

export default withLookup(TableView);
