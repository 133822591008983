import { useState } from 'react';
import { isFunction, includes, get } from 'lodash';

import { DEFAULT_LOGO_URL } from 'utils/constants';
import {
  PDF_ID,
  LOGO_CUSTOMIZATION_ID,
  SUMMARY_CUSTOMIZATION_ID,
  CHOOSE_FIELDS_CUSTOMIZATION_ID,
} from 'utils/constants/export';

export default function useSettings({
  defaultFileFormatValue,
  initialOptions = {},
  initialValues = {},
}) {
  const [fileFormat, setFileFormat] = useState(defaultFileFormatValue || PDF_ID);
  const [title, setTitle] = useState(get(initialValues, 'title', null));
  const [customizationValues, setCustomizationValues] = useState([]);
  const [logoUrl, setLogoUrl] = useState(DEFAULT_LOGO_URL);
  const options = isFunction(initialOptions)
    ? initialOptions({ fileFormat })
    : initialOptions;

  return {
    settings: {
      options,
      title,
      logoUrl,
      fileFormat,
      customizationValues,
      showMyLogo: includes(customizationValues, LOGO_CUSTOMIZATION_ID),
      showSummary: includes(customizationValues, SUMMARY_CUSTOMIZATION_ID),
      chooseColumns: includes(customizationValues, CHOOSE_FIELDS_CUSTOMIZATION_ID),
    },
    settingsManager: {
      setTitle,
      setLogoUrl,
      setFileFormat,
      setCustomization: (_, values) => setCustomizationValues(values),
    },
  };
}
