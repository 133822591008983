import { toast } from 'react-toastify';
import classNames from 'classnames';

import AlertType from '../components/AlertType';
import CloseButton from '../components/CloseButton';
import { Slide } from './animations';
import { getContent } from './index';
import styles from '../styles.module.scss';

export const notificationDefaultProps = {
  position: 'top-right',
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  autoClose: 10 * 1000,
};

export function defaultNotification(content, options) {
  const { id, onClose, ...restProps } = options || {};
  const closeButton = props => CloseButton({ ...props, onClose, content });

  return () => toast(getContent(content), {
    transition: Slide,
    closeButton,
    ...notificationDefaultProps,
    ...restProps,
    toastId: id,
  });
}

export function success(content, options) {
  const { id, onClose, ...restProps } = options || {};
  const closeButton = props => CloseButton({ ...props, onClose, content });

  return () => toast(getContent(content), {
    transition: Slide,
    className: classNames(styles.card, styles.success),
    closeButton,
    ...notificationDefaultProps,
    ...restProps,
    toastId: id,
  });
}

export function error(content, options) {
  const { id, onClose, ...restProps } = options || {};
  const closeButton = props => CloseButton({ ...props, onClose, content });

  return () => toast(getContent(content), {
    transition: Slide,
    className: classNames(styles.card, styles.error),
    closeButton,
    ...notificationDefaultProps,
    ...restProps,
    toastId: id,
  });
}

export function alertSuccessAction(content, options) {
  return success(AlertType(content), options)();
}

export function alertErrorAction(content, options) {
  return error(AlertType(content), options)();
}
