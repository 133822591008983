import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Badge from 'components/core/Badge';

import styles from './styles.module.scss';

function ButtonBadge({ children, className, containerClassName, ...restProps }) {
  return (
    <span className={classNames(styles.container, containerClassName)}>
      <Badge {...restProps} className={classNames(styles.badge, className)}>
        {children}
      </Badge>
    </span>
  );
}

ButtonBadge.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ButtonBadge;
