import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import getItemStyle from '../theme';
import styles from '../styles.module.scss';

function DraggableOption({
  id,
  name,
  index,
  disabled,
  droppableSnapshot,
}) {
  const shouldRenderClone = id === droppableSnapshot.draggingFromThisWith;

  const clone = (
    <li className={classNames(styles.draggable, styles.draggableCopy)}>
      <i className="far fa-arrows-alt" /> {name}
    </li>
  );

  const draggable = (
    <Draggable index={index} draggableId={id} isDragDisabled={disabled}>
      {(provided, snapshot) => {
        const draggableItemProps = {
          ...provided.draggableProps,
          ...provided.dragHandleProps,
          ref: provided.innerRef,
          className: classNames(styles.draggable, disabled && styles.draggableDisabled),
          style: getItemStyle(snapshot.isDragging, provided.draggableProps.style),
        };

        return (
          <li {...draggableItemProps}>
            <i className="far fa-arrows-alt" /> {name}
          </li>
        );
      }}
    </Draggable>
  );

  if (shouldRenderClone) {
    return clone;
  }

  return draggable;
}

DraggableOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  droppableSnapshot: PropTypes.object.isRequired,
};

export default DraggableOption;
