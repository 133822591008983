import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/core/HearingCalendars/components/Icon';

import styles from './styles.module.scss';

function Placeholder({ isFederal }) {
  return (
    <section>
      <div className={styles.placeholder}>
        <div className={styles.calendar}>
          <div className={styles.calendarHeader}>
            <span className={styles.headerText} />
            <span className={styles.navButtons}>
              <Icon />
              <Icon />
            </span>
            <div className={styles.headerWeek} />
          </div>

          <div className={styles.month}>
            {Array(5).fill(0).map(
              (_, index) => <div className={styles.week} key={index} />
            )}
          </div>
        </div>
        <hr />

        <div className={styles.box} />
        {isFederal && (
          <div className={styles.legend}>
            <div className={styles.header} />
            <div>
              <div className={classNames(styles.item, styles.senate)}>
                <Icon />
                <span className={styles.text} />
              </div>

              <div className={classNames(styles.item, styles.house)}>
                <Icon />
                <span className={styles.text} />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

Placeholder.propTypes = {
  isFederal: PropTypes.bool,
};

export default Placeholder;
