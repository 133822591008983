import notifications from 'components/Dashboard/utils/notifications';
import { notifySharedToMyFeed } from 'components/Dashboard/common/UserNotifications';
import { bulkShareToMyFeed } from 'redux/dashboardV2/common/subscriptions/api';

export function onShareToMyFeed({ id, type, itemType }) {
  const data = { record_ids: [id], record_type: type };

  function onSuccess() {
    notifySharedToMyFeed({ itemType });
  }

  function onFailure(error) {
    notifications.generalFailedNotification();

    throw error;
  }

  return bulkShareToMyFeed(data)
    .then(onSuccess)
    .catch(onFailure);
}
