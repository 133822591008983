type RemoveUnusedOptionsFn = (
  filterOptions: { [key: string]: unknown },
  ignoreOptions: string[],
) => { [key: string]: unknown };

export const removeUnusedOptions: RemoveUnusedOptionsFn = (filterOptions, ignoreOptions) => {
  if (!ignoreOptions) {
    return filterOptions;
  }

  return Object.keys(filterOptions).reduce((validOptions, optionKey) => {
    if (ignoreOptions.includes(optionKey)) {
      return validOptions;
    }

    return { ...validOptions, [optionKey]: filterOptions[optionKey] };
  }, {});
};
