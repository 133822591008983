import { cssTransition } from 'react-toastify';

export const Slide = cssTransition({
  enter: 'slideIn',
  exit: 'slideOut',
  duration: 200,
});

export const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 200,
});

export const SlideNoAnimation = cssTransition({
  enter: 'slideIn',
  exit: 'slideOut',
  duration: [200, 0],
});
