import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_VALUE } from 'utils/constants';
import Link from 'components/core/Link';

import styles from './styles.module.scss';

function Info({
  label,
  value,
  href,
  isPrimary,
  isExternal,
  labelClassName,
  valueClassName,
  onlyLabel,
  customAdditionalValue,
}) {
  const valueStyle = classNames(styles.spacing, styles.value, valueClassName);
  const labelStyle = classNames(styles.spacing, styles.label, labelClassName);

  function renderContent() {
    if (isEmpty(href)) {
      return value || EMPTY_VALUE;
    }

    return (
      <Link
        theme="light"
        href={href}
        className={styles.link}
        isExternal={isExternal}
      >
        {value || href}
      </Link>
    );
  }

  return (
    <div className={styles.info}>
      {label && <div className={labelStyle}>{label}</div>}

      {!onlyLabel && (
        <div className={valueStyle}>
          {renderContent()}
          {isPrimary && <span> (primary)</span>}
          {customAdditionalValue}
        </div>
      )}
    </div>
  );
}

Info.defaultProps = {
  showIcon: false,
  isPrimary: false,
  onlyLabel: false,
};

Info.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  href: PropTypes.string,
  isPrimary: PropTypes.bool,
  isExternal: PropTypes.bool,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  onlyLabel: PropTypes.bool,
  customAdditionalValue: PropTypes.node,
};

export default Info;
