import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/core/Button';

import useBackToTop from './useBackToTop';
import styles from './styles.module.scss';

type ButtonBackToTopProps = {
  title?: string,
  className?: string,
  iconClassName?: string,
  descriptionClassName?: string,
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
};

const ButtonBackToTop: FC<ButtonBackToTopProps> = ({
  title = 'Back to the top',
  className,
  iconClassName,
  descriptionClassName,
  containerRef,
}) => {
  const handleScrollToTop = () => {
    containerRef
      ? containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
      : window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button
      theme="default"
      size="none"
      className={classNames(styles.button, className)}
      onClick={handleScrollToTop}
    >
      <div className={classNames(styles.icon, iconClassName)}>
        <i className="far fa-arrow-to-top" />
      </div>
      <div className={classNames(styles.description, descriptionClassName)}>
        {title}
      </div>
    </Button>
  );
};

export default ButtonBackToTop;
export { useBackToTop };
