import { StyleSheet } from '@react-pdf/renderer';

import { BOX_SHADOW_COLOR } from 'components/core/ExportDocument/utils/colors.scss';

const AVATAR_SIZE = 75;

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    backgroundColor: BOX_SHADOW_COLOR,
    borderRadius: AVATAR_SIZE / 2,
    display: 'flex',
    flexDirection: 'row',
    height: AVATAR_SIZE,
    justifyContent: 'center',
    overflow: 'hidden',
    width: AVATAR_SIZE,
    marginRight: 35,
  },
  image: {
    borderRadius: AVATAR_SIZE / 2,
    objectFit: 'cover',
    objectPosition: '50% 50%',
    width: AVATAR_SIZE,
  },
});

export default styles;
