import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import CardPlaceholder from './components/CardPlaceholder';
import useRenderPlaceholders from './useRenderPlaceholders';
import styles from './styles.module.scss';

function Column({
  children,
  header,
  footer,
  id,
  isPlaceholderBeforeCard,
  className,
  contentClassName,
  emptyPlaceholder,
  ...restProps
}) {
  const {
    getCardPlaceholderData,
    shouldRenderEmptyPlaceholder,
    canRenderCardPlaceholder,
    shouldRenderCardPlaceholder,
  } = useRenderPlaceholders({
    isPlaceholderBeforeCard,
    hasEmptyPlaceholderComponent: !!emptyPlaceholder,
    hasChildren: Children.count(children) > 0,
  });

  const renderPlaceholder = (placeholder, type) => (
    <CardPlaceholder placeholder={placeholder} type={type} />
  );

  const renderChildren = (placeholder, { draggingFromThisWith, draggingOverWith }) => {
    const {
      draggingCardId,
      cardPlaceholderType,
    } = getCardPlaceholderData({ draggingFromThisWith, draggingOverWith });
    const renderEmptyPlaceholder = shouldRenderEmptyPlaceholder({ draggingCardId });

    if (renderEmptyPlaceholder) {
      return emptyPlaceholder;
    }

    let isPlaceholderRendered = false;

    return (
      <>
        {Children.map(children, (child) => {
          const cardId = child.props.id;
          const renderCardPlaceholder = shouldRenderCardPlaceholder({
            cardId,
            draggingCardId,
            isPlaceholderRendered,
          });

          if (renderCardPlaceholder) {
            isPlaceholderRendered = true;
          }

          return (
            <Fragment key={cardId}>
              {renderCardPlaceholder && renderPlaceholder(placeholder, cardPlaceholderType)}
              {child}
            </Fragment>
          );
        })}
        {canRenderCardPlaceholder({ draggingCardId, isPlaceholderRendered }) && renderPlaceholder(placeholder, cardPlaceholderType)}
      </>
    );
  };

  return (
    <Droppable droppableId={id} {...restProps}>
      {({ innerRef, droppableProps, placeholder }, snapshot) => (
        <div
          className={classNames(styles.wrapper, snapshot.isDraggingOver && styles.highlighted)}
          ref={innerRef}
          {...droppableProps}
        >
          <div className={classNames(styles.column, className)}>
            {header}
            <div className={classNames(styles.columnContent, contentClassName)}>
              {renderChildren(placeholder, snapshot)}
            </div>
            {footer}
          </div>
        </div>
      )}
    </Droppable>
  );
}

Column.defaultProps = {
  // default behavior is to render placeholder at the top of the list
  isPlaceholderBeforeCard: () => true,
};

Column.propTypes = {
  isPlaceholderBeforeCard: PropTypes.func,
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  emptyPlaceholder: PropTypes.node,
};

export default Column;

export { default as Header } from './components/Header';
export { default as Footer } from './components/Footer';
export { default as DraggableCard } from './components/DraggableCard';
export { default as EmptyPlaceholder } from './components/EmptyPlaceholder';
