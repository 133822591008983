import React from 'react';
import { Provider } from 'react-redux';

import store from 'redux/store';

import Container from './Container';

function MobileHeaderBar(props) {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
}

export default MobileHeaderBar;
