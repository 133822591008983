import React from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';
import { View, Text } from '@react-pdf/renderer';

import styles from './styles';
import { defaultTheme } from './themes';

const themes = {
  default: defaultTheme,
};

function LabeledProgressBar({ labels, activeLabel, theme, containerStyle }) {
  const idValues = labels.map(({ id }) => id);
  const foundItem = find(labels, { id: activeLabel });

  if (isEmpty(foundItem)) {
    return null;
  }

  const isActive = currentId =>
    idValues.indexOf(currentId) <= idValues.indexOf(activeLabel);

  const getProgressPercent = currentLabel =>
    (idValues.indexOf(currentLabel)) / (idValues.length - 1) * 100;

  function renderDot(item) {
    const { id, label, pdfStyle = {} } = item;

    const dotStyles = [
      styles.labelDot,
      isActive(id) && themes[theme].labelDotActive,
    ];

    return (
      <View style={styles.dotContainer} key={`progress-bar-dot-${id}`}>
        <View style={dotStyles} />
        <Text style={[styles.dotText, pdfStyle]}>{label}</Text>
      </View>
    );
  }

  const progressBarActiveStyles = [
    styles.progressBar,
    styles.activeProgressBar,
    themes[theme].progressBarActive,
    { width: `${getProgressPercent(activeLabel)}%` },
  ];

  return (
    <View style={[styles.labelBarContainer, containerStyle]}>
      <View style={styles.progressBar} />
      <View style={progressBarActiveStyles} />
      <View style={styles.dotsContainer}>
        {labels.map(renderDot)}
      </View>
    </View>
  );
}

LabeledProgressBar.defaultProps = {
  theme: 'default',
};

LabeledProgressBar.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    pdfStyle: PropTypes.shape({
      left: PropTypes.number,
    }),
  })).isRequired,
  activeLabel: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default LabeledProgressBar;
