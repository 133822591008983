import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from './components/Header';
import styles from './styles.module.scss';

export default function DesktopView({
  className,
  activeTab,
  tabs,
  onTabClick,
  tabHeaderClassName,
  tabContentClassName,
  sideContent,
}) {
  return (
    <section className={classNames(styles.container, className)}>
      <Header
        activeTab={activeTab}
        tabs={tabs}
        onTabClick={onTabClick}
        sideContent={sideContent}
        className={tabHeaderClassName}
      />

      <div className={classNames(styles.tabContent, tabContentClassName)}>
        {tabs.map(tab => tab.props.id === activeTab.props.id && tab.props.children)}
      </div>
    </section>
  );
}

DesktopView.propTypes = {
  activeTab: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func,
  className: PropTypes.string,
  tabHeaderClassName: PropTypes.string,
  tabContentClassName: PropTypes.string,
  sideContent: PropTypes.node,
};
