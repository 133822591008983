import cloneDeep from 'lodash/cloneDeep';

import { findNode, reorder } from './common';

export default function handleDroppedStatementReorder({ parentId, result, data, updateData }) {
  const oldStatementGroup = findNode(data, parentId);
  const newStatementGroup = cloneDeep(oldStatementGroup);

  newStatementGroup.statements = [
    ...reorder(
      newStatementGroup.statements,
      result.source.index,
      result.destination.index,
    ),
  ];

  updateData({
    payload: JSON.parse(
      JSON.stringify(data).replace(
        JSON.stringify(oldStatementGroup),
        JSON.stringify(newStatementGroup),
      ),
    ),
  });
}
