import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';

import { PartyIcon, Tags } from 'components/core/ExportDocument';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { getUrlWithBase } from 'utils/url';

import Avatar from './components/Avatar';
import Tag from '../Tags/components/Tag';
import tagStyles from '../Tags/components/Tag/styles';
import styles from './styles';

function Header({
  wrap,
  fixed,
  avatarUrl,
  label,
  description,
  partyBadge,
  badge,
  tags,
  hasAvatar,
  profileUrl,
}) {
  const { short, slug } = partyBadge || {};
  const url = getUrlWithBase(profileUrl);

  return (
    <View style={styles.container} fixed={fixed} wrap={wrap}>
      {hasAvatar && <Avatar photoUrl={avatarUrl} />}

      <View style={styles.textSection}>
        <View style={styles.rowSection}>
          <Text style={styles.labelText}>{label}</Text>
          <PartyIcon style={styles.tagShape} partyShort={short} partySlug={slug} />
          {!isEmpty(badge) && <Tag style={styles.colorLessTag}>{badge}</Tag>}
        </View>

        <Text style={styles.descriptionText}>{description}</Text>

        <Tags tags={tags} url={url} tagStyle={tagStyles.tag} />
      </View>
    </View>
  );
}

Header.defaultProps = {
  wrap: false,
  fixed: false,
  tags: [],
};

Header.propTypes = {
  wrap: PropTypes.bool,
  fixed: PropTypes.bool,
  avatarUrl: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  partyBadge: PropTypes.shape({
    short: PropTypes.string,
    slug: PropTypes.string,
  }),
  badge: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
  hasAvatar: PropTypes.bool,
  profileUrl: PropTypes.string,
};

export default Header;
