import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PLACEHOLDER_TYPES } from '../../constants';
import styles from './styles.module.scss';

const THEMES = {
  [PLACEHOLDER_TYPES.draggedIn]: styles.draggedInPlaceholder,
  [PLACEHOLDER_TYPES.draggedOut]: styles.draggedOutPlaceholder,
};

function CardPlaceholder({ placeholder, type }) {
  // Workaround to resize react-beaultiful-dnd placeholder, so the border of this component won't resize the column
  const placeholderClone = cloneElement(placeholder, {
    ...placeholder.props,
    on: {
      ...placeholder.props.on,
      client: {
        ...placeholder.props.on.client,
        borderBox: { ...placeholder.props.on.client.borderBox, height: placeholder.props.on.client.borderBox.height - 2 },
        contentBox: { ...placeholder.props.on.client.contentBox, height: placeholder.props.on.client.contentBox.height - 2 },
        marginBox: { ...placeholder.props.on.client.marginBox, height: placeholder.props.on.client.marginBox.height - 2 },
        paddingBox: { ...placeholder.props.on.client.paddingBox, height: placeholder.props.on.client.paddingBox.height - 2 },
      },
    },
  });

  return (
    <div className={classNames(styles.placeholder, THEMES[type])}>
      {placeholderClone}
    </div>
  );
}

CardPlaceholder.defaultProps = {
  type: PLACEHOLDER_TYPES.draggedIn,
};

CardPlaceholder.propTypes = {
  placeholder: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(PLACEHOLDER_TYPES)),
};

export default CardPlaceholder;
