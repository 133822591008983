import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { LabelText } from 'components/core/ExportDocument';
import styles from './styles';

function ReportSummary({ value, label }) {
  return (
    <View>
      <LabelText
        labelStyle={styles.summaryLabel}
        textStyle={styles.summaryText}
        value={value}
        label={label}
      />
    </View>
  );
}

ReportSummary.defaultProps = {
  label: 'Report summary',
};

ReportSummary.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default ReportSummary;
