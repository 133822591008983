import { useState } from 'react';

function useDocumentSummary() {
  const [documentSummary, setDocumentSummary] = useState('');

  function handleChangeSummary(event = {}) {
    const { value } = event.target || {};

    setDocumentSummary(value);
  }

  function handleResetSummary() {
    setDocumentSummary('');
  }

  return {
    documentSummary,
    onChangeSummary: handleChangeSummary,
    onResetSummary: handleResetSummary,
  };
}

export default useDocumentSummary;
