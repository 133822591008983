import {
  BASE_TOOLTIP,
  BASE_GRID_LINES,
  BASE_TICK,
  BASE_OPTIONS,
  BASE_BORDER_TOP_GRID,
  BASE_BORDER_RIGHT_GRID,
  CHART_COLORS,
} from 'utils/constants/charts';

export const FILL_START_COLOR = 'rgba(249, 5, 5, 0.5)';
export const FILL_END_COLOR = 'rgba(248, 223, 223, 0.46)';
export const BORDER_COLOR = '#ff0000';

export const CHART_OPTIONS = {
  ...BASE_OPTIONS,
  tooltips: BASE_TOOLTIP,
  elements: {
    point: {
      backgroundColor: CHART_COLORS.POINT_BACKGROUND_COLOR,
      radius: 4,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          ...BASE_TICK,
          padding: 4,
          maxTicksLimit: 12,
          maxRotation: 0,
          minRotation: 0,
        },
        gridLines: { display: false },
      },
      BASE_BORDER_TOP_GRID,
    ],
    yAxes: [
      {
        ticks: {
          ...BASE_TICK,
          padding: 4,
          precision: 0,
          maxTicksLimit: 5,
          min: 0,
          minStepSize: 1,
        },
        gridLines: BASE_GRID_LINES,
      },
      BASE_BORDER_RIGHT_GRID,
    ],
  },
};
