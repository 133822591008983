import React, { useState } from 'react';
import isFunction from 'lodash/isFunction';

import Notifications from 'components/core/ExportDocument/components/ExportModal/utils/Notifications';

import { downloadReactPdfDocument } from './index';

function useExportPDFDocument({
  documentComponent,
  fetchComponentData,
  fileName,
  onFileExported,
  limitExceedProps,
  trackingActions,
  onNotifySuccess,
  onNotifyFailure,
}) {
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const { limit, selectedItemsLength, onLimitExceedError, onLimitExceedInfo } = limitExceedProps || {};
  const { trackGeneratedExport } = trackingActions || {};

  function handleExportFail(error) {
    setIsDocumentLoading(false);
    isFunction(onNotifyFailure) ? onNotifyFailure() : Notifications.errorApi();
    throw error;
  }

  function handleExportSuccess() {
    setIsDocumentLoading(false);
    onFileExported && onFileExported();
    onNotifySuccess && onNotifySuccess();
  }

  function onFetchData(documentData) {
    const dataDocument = React.cloneElement(documentComponent, documentData);

    return downloadReactPdfDocument(dataDocument, fileName);
  }

  function handleExport(...args) {
    const [{
      logoUrl,
      displayingProps,
      documentSummary,
      sections,
      fileFormat,
      customization,
      isPageNumbersVisible,
    }] = args;

    if (selectedItemsLength > limit && onLimitExceedInfo) {
      onLimitExceedInfo(limit);
    }

    if (selectedItemsLength > limit && onLimitExceedError) {
      return onLimitExceedError(limit);
    }

    setIsDocumentLoading(true);

    trackGeneratedExport && trackGeneratedExport({ sections, fileFormat, customization });

    if (isFunction(fetchComponentData)) {
      return fetchComponentData(...args)
        .then(response => onFetchData({
          logoUrl,
          displayingProps,
          documentSummary,
          isPageNumbersVisible,
          ...response,
        }))
        .then(handleExportSuccess)
        .catch(handleExportFail);
    }

    return onFetchData({ logoUrl, displayingProps, documentSummary })
      .then(handleExportSuccess)
      .catch(handleExportFail);
  }

  return {
    isDocumentLoading,
    onExport: handleExport,
  };
}

export default useExportPDFDocument;
