import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { StyleSheet, Text } from '@react-pdf/renderer';

import { DEFAULT_TEXT_COLOR } from '../../utils/colors.scss';

const styles = StyleSheet.create({
  labelText: {
    fontWeight: 200,
    fontSize: 12,
    color: DEFAULT_TEXT_COLOR,
    paddingBottom: 2,
  },
});

function LabelTitle({ label, style, fixed }) {
  if (isEmpty(label)) {
    return null;
  }

  const currentStyle = { ...styles.labelText, ...style };

  return (
    <Text fixed={fixed} style={currentStyle}>{label}</Text>
  );
}

LabelTitle.defaultProps = {
  style: {},
  fixed: false,
};

LabelTitle.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  fixed: PropTypes.bool,
};

export default LabelTitle;
