import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

export default function Header({ tabs, activeTab, onTabClick, buttonClassName }) {
  function handleClick(tab) {
    return () => onTabClick(tab);
  }

  return (
    <ButtonGroup theme="light" className={styles.header}>
      {tabs.map((tab) => {
        const { id, title } = tab.props;
        const isActive = activeTab.props.id === id;

        return (
          <Button
            key={`header-${title}-${id}`}
            theme="default"
            className={classNames(isActive && styles.active, buttonClassName)}
            onClick={handleClick(tab)}
          >
            {title}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

Header.propTypes = {
  activeTab: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
};
