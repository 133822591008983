import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function EmptyPlaceholder({ children }) {
  return (
    <div className={styles.emptyPlaceholder}>
      {children}
    </div>
  );
}

EmptyPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyPlaceholder;
