import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { BaseOption } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function SelectField({ keyName, value, options, strategy, titles, onChange, className, isSelect, ...restProps }) {
  function handleChange(data) {
    onChange(keyName, data);
  }

  const fieldValue = !isEmpty(value) ? value : undefined;

  return (
    <BaseOption
      {...restProps}
      {...titles}
      isSelect={isSelect}
      className={classnames(className, styles.selectLabel)}
      options={options}
      strategy={strategy}
      onChange={handleChange}
      value={fieldValue}
    />
  );
}

SelectField.defaultProps = {
  isSelect: true,
};

SelectField.propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  strategy: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  isSelect: PropTypes.bool,
  titles: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default SelectField;
