import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

function ExportButton({ theme, className, customIcon, openModal }) {
  return (
    <Button
      theme={theme}
      customIcon={customIcon}
      className={className}
      onClick={openModal}
    >
      Export profile
    </Button>
  );
}

ExportButton.defaultProps = {
  theme: 'secondary',
  customIcon: <i className="far fa-download" />,
};

ExportButton.propTypes = {
  theme: PropTypes.string,
  className: PropTypes.string,
  customIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  openModal: PropTypes.func.isRequired,
};

export default ExportButton;
