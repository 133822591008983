import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BaseLabelGroup } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

function HistoricalRecords({ keyName, titles, value, onChange, className, labelText }) {
  function handleChange() {
    onChange(keyName, !value);
  }
  const options = {
    [keyName]: labelText,
  };

  return (
    <BaseLabelGroup
      {...titles}
      className={classNames(className, styles.historicalContainer)}
      id={keyName}
      name={keyName}
      values={options}
      value={value ? [keyName] : []}
      onChange={handleChange}
    />
  );
}

HistoricalRecords.defaultProps = {
  keyName: 'historicalLegislator',
  labelText: 'Include former legislators who no longer serve',
  titles: {
    title: 'Historical legislators',
  },
};

HistoricalRecords.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
};

export default HistoricalRecords;
