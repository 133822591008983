import sortDirections from 'utils/constants/sortDirections';

export const SORT_RELEVANCE_ID = 'relevance';
export const SORT_RECENCY_ID = 'recency';

export const DEFAULT_SORT = {
  label: 'Relevance',
  value: SORT_RELEVANCE_ID,
  direction: sortDirections.DESC,
};

export const SORT_OPTIONS = [
  DEFAULT_SORT,
  {
    label: 'Recency',
    value: SORT_RECENCY_ID,
    direction: sortDirections.DESC,
  },
];

export const SORT_OPTIONS_WITH_DIRECTION = [
  { ...DEFAULT_SORT, label: `${DEFAULT_SORT.label} (A -> Z)`, direction: 'asc' },
  { ...DEFAULT_SORT, label: `${DEFAULT_SORT.label} (Z -> A)`, direction: 'desc' },
];
