import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Item from './components/Item';
import Caret from './components/Caret';
import CaretTitle from './components/CaretTitle';
import useAccordionState from './utils/useAccordionState';
import { isOpenShape } from './utils/shapes';
import styles from './styles.module.scss';

function Accordion({
  isDisabled,
  className,
  children,
  multi,
  onToggle,
  isOpen,
  ...props
}) {
  const initialOpenedTabs = {};
  let hasSomeTabsOpened = false;

  Children.forEach(children, ({ key, props: childProps }) => {
    const canOpenMoreTabs = multi || !hasSomeTabsOpened;

    initialOpenedTabs[key] = canOpenMoreTabs && childProps.isOpen;

    hasSomeTabsOpened = hasSomeTabsOpened || childProps.isOpen;
  });

  const { isOpen: internalIsOpen, handleToggle: internalHandleToggle } = useAccordionState(initialOpenedTabs, multi);
  const [itemIsOpen, handleToggle] = onToggle ? [isOpen, onToggle] : [internalIsOpen, internalHandleToggle];

  return (
    <div className={classNames(isDisabled && styles.disabled, className)}>
      {Children.map(children, child => cloneElement(child, {
        ...props,
        ...child.props,
        children: child.props.children,
        isOpen: itemIsOpen[child.key],
        toggle: () => handleToggle(child.key),
      }))}
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  multi: PropTypes.bool,
  onToggle: PropTypes.func,
  isOpen: isOpenShape,
  isDisabled: PropTypes.bool,
};

Accordion.defaultProps = {
  className: '',
  multi: true,
};

export default Accordion;
export { Item, Caret, CaretTitle, useAccordionState };
