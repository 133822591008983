import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import size from 'lodash/size';

import Link from 'utils/Link';

import { workspacesShape } from '../../utils/shapes';
import Workspaces from '../Workspaces';
import TypeTitle from '../TypeTitle';
import usePush from './utils/usePush';
import styles from './styles.module.scss';

function Push({
  id,
  title,
  description,
  itemType,
  itemTitle,
  url,
  withTitle,
  className,
  subscriptionToken,
  isMobile,
  customDescriptionLimit,
  typeClassName,
  customBodyUrl,
  bodyClassName,
  isKeywordMatch,
  workspaces,
  feedToken,
}) {
  const {
    eventUrl,
    detailsUrl,
    showDescription,
    formattedDescription,
  } = usePush({
    id,
    subscriptionToken,
    description,
    url,
    isMobile,
    customDescriptionLimit,
    feedToken,
  });

  return (
    <div className={classNames(styles.card, className)}>
      {withTitle && <div className={styles.title}>{title}</div>}

      {showDescription && (
        <Link
          theme="tableLink"
          href={customBodyUrl || eventUrl}
          className={classNames(styles.body, bodyClassName)}
        >
          {formattedDescription}
        </Link>
      )}

      <TypeTitle
        itemType={itemType}
        itemTitle={itemTitle}
        url={detailsUrl}
        isText={false}
        className={styles.typeTitle}
        typeClassName={classNames(styles.itemType, typeClassName)}
        isKeywordMatch={isKeywordMatch}
      />

      {size(workspaces) > 1 && (
        <Workspaces
          data={workspaces}
          className={styles.typeTitle}
          typeClassName={classNames(styles.itemType, typeClassName)}
        />
      )}
    </div>
  );
}

Push.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  itemType: PropTypes.string,
  itemTitle: PropTypes.string,
  url: PropTypes.string,
  withTitle: PropTypes.bool,
  className: PropTypes.string,
  subscriptionToken: PropTypes.string,
  isMobile: PropTypes.bool,
  customDescriptionLimit: PropTypes.number,
  typeClassName: PropTypes.string,
  customBodyUrl: PropTypes.string,
  bodyClassName: PropTypes.string,
  isKeywordMatch: PropTypes.bool,
  feedToken: PropTypes.string.isRequired,
  workspaces: workspacesShape,
};

export default Push;
