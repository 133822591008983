import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Link from 'components/core/Link';

import DataField from '../DataField';
import styles from './styles.module.scss';

function DataArrayField({ label, values, className }) {
  if (isEmpty(values)) {
    return <DataField label={label} className={className} />;
  }

  function renderValue(valueItem, index) {
    const key = `value-${label}-${index}`;
    const { value, isExternal, onClick, href } = valueItem;

    const fieldValue = (value && href) ? (
      <Link
        key={key}
        theme="default"
        size="none"
        href={href}
        isExternal={isExternal}
        onClick={onClick}
        className={styles.linkBlock}
      >
        {value}
      </Link>
    ) : value;

    return (
      <DataField
        key={key}
        value={fieldValue}
        className={className}
      />
    );
  }

  return (
    <div className={styles.arrayBlock}>
      {label && <p className={styles.label}>{label}</p>}
      {values.map(renderValue)}
    </div>
  );
}

DataArrayField.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  className: PropTypes.string,
};

export default DataArrayField;
