import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  loaderSizes,
  loaderThemes,
  loadingTextThemes,
} from './helpers';

import {
  DARK_THEME,
  MEDIUM_SIZE,
} from './constants';

import styles from './styles.module.scss';

function Loader({ message, onlyIcon, theme, size, className }) {
  return (
    <>
      <div
        className={classNames(
          styles.loader,
          onlyIcon && styles.onlyIcon,
          loaderSizes[size],
          loaderThemes[theme],
          className,
        )}
      />
      {!onlyIcon && (
        <p className={classNames(styles.loadingText, loadingTextThemes[theme])}>
          {message}
        </p>
      )}
    </>
  );
}

Loader.propTypes = {
  message: PropTypes.string,
  onlyIcon: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(loaderThemes)),
  size: PropTypes.oneOf(Object.keys(loaderSizes)),
};

Loader.defaultProps = {
  message: 'Loading',
  onlyIcon: false,
  theme: DARK_THEME,
  size: MEDIUM_SIZE,
};

export default Loader;
