import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import styles from './styles';

function BillFooter({ children }) {
  return (
    <View style={styles.billFooterSection}>
      {children}
    </View>
  );
}

BillFooter.propTypes = {
  children: PropTypes.object,
};

export default BillFooter;
