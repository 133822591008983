import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import { pluralize } from 'utils/string';

import styles from './styles.module.scss';

function Actions({
  isEditing,
  repliesCount,
  onReplyClick,
  onSaveEdit,
  onCancelEdit,
  isSaveDisabled,
  showSaveLoader,
  onLoadMoreClick,
  hasMoreToLoad,
  areFirstRepliesLoaded,
  isLoadingReplies,
}) {
  const handleReply = () => onReplyClick();
  const loadRepliesText = areFirstRepliesLoaded ? 'Load more replies' : 'Load replies';
  const renderLoader = () => <Loader size="small" onlyIcon className={styles.loader} />

  const renderReplyAction = () => (
    <Fragment>
      <Button
        theme="tableLink"
        className={styles.button}
        onClick={handleReply}
      >
        REPLY
      </Button>
      {!!repliesCount && (
        <div className={styles.repliesInfo}>
          {`${repliesCount} ${pluralize('Reply', 'Replies', repliesCount)}`}
        </div>
      )}
      {hasMoreToLoad && (
        <div className={styles.repliesInfo}>
          <Button
            className={styles.loadMore}
            theme="tableLink"
            onClick={onLoadMoreClick}
            disabled={isLoadingReplies}
          >
            {loadRepliesText}
            {isLoadingReplies && renderLoader()}
          </Button>
        </div>
      )}
    </Fragment>
  );

  const renderEditAction = () => (
    <Fragment>
      <Button
        theme="tableLink"
        type="submit"
        disabled={isSaveDisabled || showSaveLoader}
        className={styles.button}
        onClick={onSaveEdit}
      >
        SAVE
        {showSaveLoader && renderLoader()}
      </Button>
      <Button
        theme="tableLink"
        className={styles.button}
        onClick={onCancelEdit}
      >
        CANCEL
      </Button>
    </Fragment>
  );

  return (
    <div className={styles.container}>
      {isEditing ? renderEditAction() : renderReplyAction()}
    </div>
  );
}

Actions.propTypes = {
  isEditing: PropTypes.bool,
  repliesCount: PropTypes.number,
  isSaveDisabled: PropTypes.bool,
  showSaveLoader: PropTypes.bool,
  onReplyClick: PropTypes.func.isRequired,
  onSaveEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  hasMoreToLoad: PropTypes.bool.isRequired,
  areFirstRepliesLoaded: PropTypes.bool.isRequired,
  isLoadingReplies: PropTypes.bool.isRequired,
};

export default Actions;
