import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import withPublicRecords from 'components/core/Profile/components/Cards/PublicRecord/utils/withPublicRecords';
import { ClearableEmptyPlaceholder } from 'components/core/Profile/components/Placeholders';
import AddedToItem from 'components/core/Table/components/ColumnItem/AddedToItem';
import ActionsItem from 'components/core/Table/components/ColumnItem/ActionsItem';
import TagsItem from 'components/core/Table/components/ColumnItem/TagsItem';
import { DateItem } from 'components/core/Table/components/ColumnItem';
import { PAGE_SIZE } from 'components/core/Profile/utils';
import Table, { Column } from 'components/core/Table';
import Paginator from 'components/core/Paginator';
import { sortShape } from 'components/core/Sort/shapes';
import { paginatorShape } from 'components/core/Paginator/shapes';
import { tableColumnShape } from 'components/core/Profile/components/Cards/utils/shapes';

import SnippetColumn from './components/SnippetColumn';
import RecordColumn from './components/RecordColumn';
import TitleColumn from './components/TitleColumn';
import tableColumns from './utils/tableColumns';
import useTable from './utils/useTable';
import styles from './styles.module.scss';

function TableView({
  isMobile,
  isLoading,
  records,
  paginator,
  recordTypes,
  trackingContext,
  customTableColumns,
  emptyDescription,
  emptyDescriptionFiltering,
  areFiltersApplied,
  selectedTypes,
  onClearAll,
  customColumnComponents,
  customTransformers,
  sort,
  defaultSort,
  onSearch,
  trackingFunctions,
  updateDataItem,
  defaultSubscription,
  isLoadingSubscriptions,
  isLoadingTags,
  isLoadingMyFeedDocuments,
  ...restProps
}) {
  const currentTableColumns = customTableColumns || tableColumns;

  const {
    isPaginatorPresent,
    sortData,
    onSortColumn,
    onChangePage,
  } = useTable({
    tableColumns: currentTableColumns,
    paginator,
    isLoading,
    trackingContext,
    sort,
    defaultSort,
    onSearch,
    ...trackingFunctions,
    ...restProps,
  });

  if (!isLoading && (isEmpty(records) || isEmpty(selectedTypes))) {
    return (
      <ClearableEmptyPlaceholder
        areFiltersApplied={areFiltersApplied}
        emptyDescription={emptyDescription}
        emptyDescriptionFiltering={emptyDescriptionFiltering}
        onClear={onClearAll}
      />
    );
  }

  function renderColumn(column) {
    const { keyName } = column;

    function getColumn(value) {
      return <div className={styles.tableColumn}>{value}</div>;
    }

    const columnProps = {
      keyName,
      key: keyName,
    };

    const columns = {
      type: <RecordColumn {...columnProps} recordTypes={recordTypes} />,
      title: <TitleColumn {...columnProps} />,
      addedTo: (
        <AddedToItem
          key={keyName}
          keyName={keyName}
          customTransformers={customTransformers}
          isLoadingMyFeedDocuments={isLoadingMyFeedDocuments}
        />
      ),
      tags: (
        <TagsItem
          key={keyName}
          keyName={keyName}
          isLoading={isLoadingTags}
        />
      ),
      actions: (
        <ActionsItem
          key={keyName}
          keyName={keyName}
          recordTypes={recordTypes}
          customTransformers={customTransformers}
          updateDataItem={updateDataItem}
          defaultSubscription={defaultSubscription}
          isLoadingSubscriptions={isLoadingSubscriptions}
          isMobile={isMobile}
          itemNameBy="title"
        />
      ),
      date: <DateItem {...columnProps} />,
      snippet: <SnippetColumn {...columnProps} />,
      ...customColumnComponents,
    };

    return columns[keyName] || <Column key={keyName} keyName={keyName}>{getColumn}</Column>;
  }

  function renderPagination() {
    if (isPaginatorPresent) {
      const { current, total, totalCount, pageSize } = paginator;

      return (
        <Paginator
          isMobile={isMobile}
          isLoading={isLoading}
          current={current}
          total={total}
          pageSize={pageSize}
          totalItems={totalCount}
          onChange={onChangePage}
        />
      );
    }

    return null;
  }

  const tableClassName = classNames(
    styles.tableContent,
    paginator
    && paginator.current > 1
    && paginator.current === paginator.total
    && styles.fixedHeight
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isLoading}
        rowLengthPlaceholder={PAGE_SIZE}
        uniqueKey="id"
        columns={currentTableColumns}
        containerStyle={tableClassName}
        data={records}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={onSortColumn}
        isPaginatorPresent={isPaginatorPresent}
        isMobile={isMobile}
        isFixed
      >
        {currentTableColumns.map(renderColumn)}
      </Table>

      {renderPagination()}
    </div>
  );
}

TableView.defaultTypes = {
  customColumnComponents: {},
};

TableView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // TODO: define recordShape
  paginator: paginatorShape.isRequired,
  onSearch: PropTypes.func.isRequired,
  toResetTable: PropTypes.bool.isRequired,
  trackingContext: PropTypes.string.isRequired,
  recordTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    labelPlural: PropTypes.string,
  })),
  customTransformers: PropTypes.shape({
    transformRecord: PropTypes.func,
    transformRecords: PropTypes.func,
  }),
  customTableColumns: PropTypes.arrayOf(tableColumnShape),
  emptyDescription: PropTypes.string,
  emptyDescriptionFiltering: PropTypes.string,
  areFiltersApplied: PropTypes.bool,
  selectedTypes: PropTypes.arrayOf(PropTypes.string),
  onClearAll: PropTypes.func.isRequired,
  customColumnComponents: PropTypes.objectOf(PropTypes.node),
  sort: sortShape,
  defaultSort: sortShape,
  trackingFunctions: PropTypes.shape({}).isRequired, // { fnName: fn, ... }
  updateDataItem: PropTypes.func,
  defaultSubscription: PropTypes.string,
  isLoadingSubscriptions: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool,
};

export default withPublicRecords(TableView);
