import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import Card from 'components/core/Card';
import { EmptyTablePlaceholder } from 'components/core/Profile/components/Placeholders';

import Title from '../Title';
import styles from './styles.module.scss';

export default function DataCard({
  id,
  showPlaceholder,
  title,
  description,
  children,
  emptyTitle,
  emptyDescription,
  className,
  titleClassName,
  placeholderClassName,
  rightItems,
  questionText,
  questionTooltipTheme,
  customTitle,
  customTitleClassName,
  theme,
}) {
  const placeholder = (
    <EmptyTablePlaceholder
      title={emptyTitle}
      description={emptyDescription}
      className={placeholderClassName}
    />
  );

  function renderTitle() {
    if (isFunction(customTitle)) {
      return customTitle();
    }

    return (
      <Title
        title={title}
        description={description}
        className={titleClassName}
        titleClassName={customTitleClassName}
        rightItems={rightItems}
        questionText={questionText}
        questionTooltipTheme={questionTooltipTheme}
      />
    );
  }

  return (
    <Card
      id={id}
      className={classNames(styles.container, showPlaceholder && styles.withPlaceholder, className)}
      theme={theme}
    >
      {renderTitle()}
      {showPlaceholder ? placeholder : children}
    </Card>
  );
}

DataCard.defaultProps = {
  showPlaceholder: false,
};

DataCard.propTypes = {
  id: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  emptyTitle: PropTypes.string,
  emptyDescription: PropTypes.node,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
  rightItems: PropTypes.node,
  questionText: PropTypes.string,
  questionTooltipTheme: PropTypes.string,
  customTitle: PropTypes.func,
  customTitleClassName: PropTypes.string,
  theme: PropTypes.string,
};
