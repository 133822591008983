import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, isEmpty } from 'lodash';

import ViewMoreButton from 'components/core/ViewMoreButton';

import Placeholder from './components/Placeholder';
import ListItem from './components/ListItem';
import styles from './styles.module.scss';

function ListField({
  isLoading,
  isVisible,
  items,
  className,
  isViewMoreEnabled,
  placeholderClassName,
  placeholderItemsCount,
  listItemClassName,
  listContentClassName,
  listLabelClassName,
  badgeClassName,
  withStripedLines,
  fullWidth,
  isSimpleLabel,
}) {
  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return (
      <Placeholder
        count={placeholderItemsCount}
        className={placeholderClassName}
      />
    );
  }

  if (!isVisible || isEmpty(items)) {
    return null;
  }

  const handleViewMore = () => setIsOpen(!isOpen);

  function renderItems() {
    const renderedItems = map(items, ({ id, ...item }, index) => (
      <ListItem
        {...item}
        fullWidth={fullWidth}
        key={id || `listItem-${index}`}
        withHiddenItems={isViewMoreEnabled}
        listItemClassName={classNames(withStripedLines && styles.stripedLines, listItemClassName)}
        listContentClassName={listContentClassName}
        listLabelClassName={listLabelClassName}
        badgeClassName={badgeClassName}
        isSimpleLabel={isSimpleLabel}
      />
    ));

    if (isViewMoreEnabled) {
      return (
        <div className={classNames(styles.items, isOpen && styles.itemsAll)}>
          {renderedItems}
        </div>
      );
    }

    return renderedItems;
  }

  const renderButtonContent = label => <div className={styles.viewMoreText}>{label}</div>;

  return (
    <div className={className}>
      {renderItems()}

      {isViewMoreEnabled && (
        <ViewMoreButton
          theme="grey"
          isOpen={isOpen}
          onClick={handleViewMore}
          className={styles.viewMore}
          openText={renderButtonContent('View all')}
          closeText={renderButtonContent('Hide all')}
          buttonClassName={styles.viewMoreButton}
        />
      )}
    </div>
  );
}

ListField.defaultProps = {
  items: [],
  isViewMoreEnabled: false,
  isVisible: true,
  fullWidth: false,
  placeholderItemsCount: 8,
};

ListField.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  isViewMoreEnabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  placeholderClassName: PropTypes.string,
  placeholderItemsCount: PropTypes.number,
  listItemClassName: PropTypes.string,
  listContentClassName: PropTypes.string,
  listLabelClassName: PropTypes.string,
  badgeClassName: PropTypes.string,
  withStripedLines: PropTypes.bool,
  isSimpleLabel: PropTypes.bool,
};

export default ListField;
