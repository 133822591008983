import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import Select from 'components/core/Select';

import { addNewFilterStyle } from '../theme';
import styles from '../styles.module.scss';

function StatementFiltersAdd({
  isJoinGroupPartHidden,
  isNewGroupPartHidden,
  isDraggingFilter,
  currentId,
  parentId,
  filters,
}) {
  const hasFilters = filters && filters.length > 0;
  const displayOperator = isDraggingFilter && hasFilters;
  const displayJoinGroup = !isJoinGroupPartHidden && hasFilters;
  const displayAddNewGroup = !(isNewGroupPartHidden && hasFilters);

  const messageJoinGroup = 'Drop here to join with previous filter';
  const messageAddGroup = hasFilters
    ? 'Drop here to add as a separate filter'
    : 'Drop a filter here';

  const commonDroppableProps = { type: 'droppableFilters' };

  const droppableFiltersJoinProps = {
    ...commonDroppableProps,
    droppableId: `droppableFiltersJoinFor:${currentId}`,
  };

  const droppableFiltersGroupProps = {
    ...commonDroppableProps,
    droppableId: `droppableFiltersGroupFor:${parentId}`,
  };

  return (
    <Fragment>
      {displayJoinGroup && (
        <Droppable {...droppableFiltersJoinProps}>
          {(droppableProvidedFilters, droppableSnapshotFilters) => (
            <div
              ref={droppableProvidedFilters.innerRef}
              className={styles.droppableNew}
              style={
                addNewFilterStyle(
                  isDraggingFilter,
                  droppableSnapshotFilters.isDraggingOver,
                  filters,
                )
              }
            >
              <div className={styles.placeholder}>
                <span>{messageJoinGroup}</span>
              </div>
              {droppableProvidedFilters.placeholder}
            </div>
          )}
        </Droppable>
      )}

      {displayAddNewGroup && (
        <Fragment>
          {displayOperator && (
            <section className={styles.droppableFilterOperator}>
              <Select
                placeholder="AND"
                hasNewStyle
                isDisabled
              />
            </section>
          )}

          <Droppable {...droppableFiltersGroupProps}>
            {(droppableProvidedFiltersGroup, droppableSnapshotFiltersGroup) => (
              <div
                ref={droppableProvidedFiltersGroup.innerRef}
                className={styles.droppableNew}
                style={
                  addNewFilterStyle(
                    isDraggingFilter,
                    droppableSnapshotFiltersGroup.isDraggingOver,
                    filters,
                  )
                }
              >
                <div className={styles.placeholder}>
                  <span>{messageAddGroup}</span>
                </div>
                {droppableProvidedFiltersGroup.placeholder}
              </div>
            )}
          </Droppable>
        </Fragment>
      )}
    </Fragment>
  );
}

StatementFiltersAdd.defaultProps = {
  isNewGroupPartHidden: false,
  isJoinGroupPartHidden: false,
};

StatementFiltersAdd.propTypes = {
  isNewGroupPartHidden: PropTypes.bool,
  isJoinGroupPartHidden: PropTypes.bool,
  isDraggingFilter: PropTypes.bool.isRequired,
  currentId: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  filters: PropTypes.array,
};

export default StatementFiltersAdd;
