import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_LINK_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  container: {
    display: 'inline',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 12,
  },
  text: {
    color: DEFAULT_TEXT_COLOR,
  },
  link: {
    color: DEFAULT_LINK_COLOR,
    textDecoration: 'none',
  },
  image: {
    borderRadius: 9,
    marginRight: 5,
    maxWidth: 18,
    minWidth: 18,
    maxHeight: 18,
    minHeight: 18,
    objectFit: 'cover',
  },
});

export default styles;
