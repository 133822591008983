import { map, flatMap, isEmpty, filter, includes } from 'lodash';

import * as api from 'redux/dashboardV2/common/subscriptions/api';

import { transformSubscriptionsForUI } from './transformers';
import { getWorkspacesToTrack } from '.';

const deleteSubscription = (subscription, feedToken) => (
  api.deleteSubscription(feedToken, subscription)
);

const deleteSubscriptions = (payload, feedToken) => {
  if (feedToken) {
    return Promise.all(
      map(payload, subscription => deleteSubscription(subscription, feedToken))
    );
  }

  const { trackings, type, selectedFeeds } = payload || {};
  const getTrackingsRequests = () => flatMap(trackings, ({ feed, subscription, subscriptions }) => {
    if (!includes(selectedFeeds, feed.token)) return false;

    if (!isEmpty(subscriptions)) {
      return map(
        subscriptions,
        item => deleteSubscription({ ...item, type }, feed.token)
      );
    }

    return deleteSubscription({ ...subscription, type }, feed.token);
  });
  const getTrackingsLeft = () => filter(
    trackings, ({ feed }) => !includes(selectedFeeds, feed.token)
  );

  return Promise.all(getTrackingsRequests())
    .then(getTrackingsLeft);
};

const updateSubscription = (data, feedToken) => (
  api.updateSubscription(feedToken, data)
);

const createSubscription = (payload = {}, feedToken) => {
  const workspacesToTrack = getWorkspacesToTrack(payload.workspaces, payload);
  const workspaceTokens = feedToken ? [feedToken] : map(workspacesToTrack, 'token');

  return Promise.all(map(workspaceTokens, token => api.createSubscription(token, payload)))
    .then(response => transformSubscriptionsForUI(response, workspacesToTrack));
};

export default {
  createSubscription,
  deleteSubscription,
  deleteSubscriptions,
  updateSubscription,
};
