import React from 'react';
import PropTypes from 'prop-types';

import SectionTitle from 'components/core/Profile/components/SectionTitle';
import DataCard from 'components/core/Profile/components/DataCard';

import Paginator from './Paginator';
import Header from './Header';
import RightItems from './RightItems';
import List from './List';
import useContainer from './utils/useContainer';
import { NewsContext } from './utils/withNews';
import {
  LEGISLATOR_PROFILE_TYPE,
  BILL_PROFILE_TYPE,
  EXECUTIVE_PROFILE_TYPE,
} from './utils/constants';
import styles from './styles.module.scss';

function Container({
  title,
  emptyDescription,
  id,
  isMobile,
  isDesktop,
  profileType,
  withTitle,
  shouldFetch,
  ...restProps
}) {
  const {
    showPreview,
    onShowPreview,
    items,
    paginator,
    onChangePaginator,
    showCluster,
    onShowCluster,
    onHideCluster,
    keywordSearch,
    onChangeKeywordSearch,
    onSearch,
    onClearSearch,
    isLoading,
    showPlaceholder,
    hasPreviewButton,
    isEmptySearch,
    ui,
  } = useContainer({ ...restProps, shouldFetch });

  const { [profileType]: rightItems } = {
    [BILL_PROFILE_TYPE]: <RightItems />,
    [EXECUTIVE_PROFILE_TYPE]: <RightItems />,
  };

  const { [profileType]: headerActions } = {
    [LEGISLATOR_PROFILE_TYPE]: <Header />,
  };

  const context = {
    ...restProps,
    ...ui,
    isMobile,
    isDesktop,
    showPreview,
    onShowPreview,
    items,
    paginator,
    onChangePaginator,
    showCluster,
    onShowCluster,
    onHideCluster,
    keywordSearch,
    onChangeKeywordSearch,
    onSearch,
    onClearSearch,
    isLoading,
    hasPreviewButton,
    isEmptySearch,
  };

  return (
    <NewsContext.Provider value={context}>
      {withTitle && <SectionTitle title="News" />}

      <DataCard
        id={id}
        title={title}
        emptyDescription={emptyDescription}
        showPlaceholder={showPlaceholder}
        rightItems={rightItems}
        className={styles.dataCard}
        placeholderClassName={styles.placeholder}
      >
        {headerActions}
        <List />
        {!isEmptySearch && <Paginator />}
      </DataCard>
    </NewsContext.Provider>
  );
}

Container.defaultProps = {
  withTitle: false,
  shouldFetch: true,
};

Container.propTypes = {
  title: PropTypes.string.isRequired,
  emptyDescription: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  profileType: PropTypes.string.isRequired,
  withTitle: PropTypes.bool,
  shouldFetch: PropTypes.bool,
  url: PropTypes.string,
};

export default Container;
