import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';

import { getCorsImageUrl } from 'utils/constants/export';

import styles from './styles';

function Avatar({ photoUrl }) {
  if (isEmpty(photoUrl)) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Image style={styles.image} src={getCorsImageUrl(photoUrl)} />
    </View>
  );
}

Avatar.propTypes = {
  photoUrl: PropTypes.string,
};

export default Avatar;
