import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, find } from 'lodash';
import classNames from 'classnames';

import Card from 'components/core/Card';
import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import Paginator from 'components/core/Paginator';
import Select from 'components/core/Select';
import withCustomField from 'components/core/CustomFields/utils/withCustomField';
import useTable from 'components/core/CustomFields/components/TableView/useTable';
import { paginatorShape } from 'components/core/Paginator/shapes';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import {
  uiShape,
  allCustomFieldsItemShape,
  customFieldShape,
} from '../../utils/shapes';
import { PRODUCT_SLUGS } from '../../utils/constants';
import MobileCardView from '../MobileCardView';
import MobileCardViewPlaceholder from '../MobileCardView/components/Placeholder';
import EditForm from './components/EditForm';
import CardButtonGroup from './components/CardButtonGroup';
import FooterActions from './components/FooterActions';
import FieldsTable from './components/FieldsTable';
import EditButton from './components/EditButton';
import { ENABLED_OPTIONS } from './utils/constants';
import styles from './styles.module.scss';

function TableView({
  ui,
  customField,
  allCustomFields,
  updateCustomField,
  paginator,
  updatePosition,
  ...restProps
}) {
  const { isLoadingCustomFields, isEnabled, availableIn } = ui;
  const {
    isEditModal,
    selectedItems,
    handleOpenEditForm,
    handleFieldStatus,
    handleSelectItems,
    handleDisableItems,
    handleEnableItems,
    handleClearSelectedItems,
    toggleEditModal,
    sort,
    handleSortByColumn,
    handleChangePage,
    isPaginatorPresent,
    handleSelectChange,
    handlePositionChange,
    handleChangeAvailableIn,
  } = useTable({
    isEnabled,
    isLoadingCustomFields,
    paginator,
    updatePosition,
    availableIn,
    ...restProps,
  });
  const { isDesktop } = useMediaQuery();

  function renderRowActions(action) {
    const { token, enabled, editable } = action;
    return (
      <div className={styles.actions}>
        <EditButton
          onClick={handleOpenEditForm(token)}
          isEnabled={editable}
        >
          Edit
        </EditButton>
        <Button
          theme="tertiary"
          size="small"
          onClick={handleFieldStatus(action)}
          className={styles.actionBtn}
        >
          {enabled ? 'Disable' : 'Enable'}
        </Button>
      </div>
    );
  }

  function handlePaginatorChangePage(page) {
    handleChangePage(page);
  }

  function renderMobileCard() {
    if (isLoadingCustomFields) {
      return <MobileCardViewPlaceholder />;
    }

    return (
      <MobileCardView
        data={allCustomFields}
        renderRowActions={renderRowActions}
      />
    );
  }

  function renderPaginator(isMobile) {
    if (!isPaginatorPresent) {
      return null;
    }

    const { current, total, totalCount, pageSize } = paginator;
    return (
      <Paginator
        current={current}
        total={total}
        pageSize={pageSize}
        isLoading={isLoadingCustomFields}
        isMobile={isMobile}
        totalItems={totalCount}
        onChange={handlePaginatorChangePage}
      />
    );
  }

  const formatOptionLabel = ({ label }, { context }) => (
    context === 'value' ? <span><b>Showing:</b> {label}</span> : label
  );

  const headerNodes = (
    <>
      <Select
        hasNewStyle
        value={find(ENABLED_OPTIONS, { value: isEnabled })}
        options={ENABLED_OPTIONS}
        clearable={false}
        searchable={false}
        onChange={handleSelectChange}
        className={classNames(styles.selectEnabled, 'custom-fields__enabled')}
        isDisabled={isLoadingCustomFields}
        formatOptionLabel={formatOptionLabel}
      />
      <CardButtonGroup
        isEnabled={availableIn === PRODUCT_SLUGS.GRM}
        setIsDisable={handleChangeAvailableIn(PRODUCT_SLUGS.STAKEHOLDER_MGMT)}
        setIsEnable={handleChangeAvailableIn(PRODUCT_SLUGS.GRM)}
        enabledLabel="Activity"
        disabledLabel={isDesktop ? 'Stakeholder management' : 'Stakeholder'}
      />
    </>
  );

  return (
    <section className={styles.customFields}>
      <h4>Manage Custom Fields</h4>
      <Card
        className={styles.tableCard}
        headerNodes={headerNodes}
        headerNodeWrapperClassName={styles.headerNodes}
      >
        <div className={styles.table}>
          <FieldsTable
            sort={sort}
            ui={ui}
            fetchCustomFields={restProps.fetchCustomFields}
            isLoadingCustomFields={isLoadingCustomFields}
            allCustomFields={allCustomFields}
            selectedItems={selectedItems}
            renderRowActions={renderRowActions}
            onSelectItems={handleSelectItems}
            handleSortByColumn={handleSortByColumn}
            onUpdatePosition={handlePositionChange}
          />
          <FooterActions
            ui={ui}
            selectedItems={selectedItems}
            onClear={handleClearSelectedItems}
            onDisable={handleDisableItems}
            onEnable={handleEnableItems}
          />
          {renderPaginator(false)}
        </div>

        <div className={styles.mobileCard}>
          {renderMobileCard()}
          {renderPaginator(true)}
        </div>
      </Card>

      <Modal
        title={isEmpty(customField) ? 'Edit Custom Field' : `Edit: ${customField.name}`}
        isOpen={isEditModal}
        toggleOpen={toggleEditModal}
        theme="scrollable"
      >
        <EditForm
          ui={ui}
          customField={customField}
          toggleModal={toggleEditModal}
          updateCustomField={updateCustomField}
        />
      </Modal>
    </section>
  );
}

TableView.propTypes = {
  ui: uiShape.isRequired,
  allCustomFields: PropTypes.arrayOf(allCustomFieldsItemShape).isRequired,
  customField: customFieldShape.isRequired,
  updateCustomField: PropTypes.func.isRequired,
  paginator: paginatorShape,
  updatePosition: PropTypes.func.isRequired,
};

export default withCustomField(TableView);
