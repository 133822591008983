import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import {
  SummaryContent,
  SummaryButton,
  useSummary,
} from 'components/core/Profile/components/Cards/ItemName/components/Summary';
import { TextPlaceholder } from 'components/core/Placeholder';

import LinkTitleField from './LinkTitleField';
import styles from './styles.module.scss';

function TitleField({
  isLoadingProfile,
  recordId,
  recordType,
  title,
  viewText,
  customRenderLink,
  trackSaveSummary,
  customContentNode,
  withSummary = true,
}) {
  const {
    summaryRef,
    isOpen,
    isDisabled,
    isLoading,
    onChange,
    onClick,
    updatedAt,
    content,
    buttonLabel,
  } = useSummary({ recordType, recordId, trackSaveSummary });

  if (isLoadingProfile) {
    return <TextPlaceholder className={styles.placeholder} />;
  }

  function renderLink() {
    if (isFunction(customRenderLink)) {
      return customRenderLink();
    }

    return <LinkTitleField {...viewText} />;
  }

  return (
    <>
      <div className={styles.titleContainer}>
        <div>
          <h2 className={styles.title} data-testid="key-details-title">{title}</h2>
          {renderLink()}
          {withSummary && customContentNode}
        </div>
        {withSummary && (
          <div className={styles.summaryButton}>
            <SummaryButton
              isDisabled={isDisabled}
              buttonLabel={buttonLabel}
              isLoading={isLoading}
              onClick={onClick}
            />
          </div>
        )}
      </div>
      {withSummary && (
        <div ref={summaryRef}>
          <SummaryContent
            isOpen={isOpen}
            updatedAt={updatedAt}
            content={content}
            onChange={onChange}
            className={styles.summaryText}
          />
        </div>
      )}
    </>
  );
}

TitleField.propTypes = {
  isLoadingProfile: PropTypes.bool,
  recordId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string,
  viewText: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
  customRenderLink: PropTypes.func,
  trackSaveSummary: PropTypes.func,
  customContentNode: PropTypes.node,
  withSummary: PropTypes.bool,
};

export default TitleField;
