import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_VALUE } from 'utils/constants';
import { Column } from 'components/core/Table';
import Link from 'components/core/Link';

import styles from '../styles.module.scss';

function SnippetColumn({ keyName, item }) {
  const { url, snippet } = item || {};

  function getSnippetText() {
    const innerHTML = { __html: snippet };

    return (
      <span
        className={styles.snippetText}
        dangerouslySetInnerHTML={innerHTML}
      />
    );
  }

  function renderSnippet() {
    if (isEmpty(snippet)) {
      return EMPTY_VALUE;
    }

    if (isEmpty(url)) {
      return getSnippetText();
    }

    const isLocal = url[0] === '/';

    return (
      <Link
        theme="default"
        href={url}
        isExternal={!isLocal}
        className={styles.linkTable}
      >
        {getSnippetText()}
      </Link>
    );
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderSnippet}</Column>
  );
}

SnippetColumn.defaultProps = {
  item: {},
};

SnippetColumn.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
};

export default SnippetColumn;
