import React from 'react';
import PropTypes from 'prop-types';
import { Geography } from 'react-simple-maps';

import { colorScale } from '../../utils';
import { HIGHLIGHT_COLOR, HOVER_COLOR, STROKE_COLOR } from '../../utils/constants';

function MapState({
  projection,
  geography,
  colorLimits,
  stateCounters,
  mapColors,
  onStateClick,
  onStateHover,
  selectedState,
}) {
  const stateId = geography.properties && geography.properties.ID_1;
  const stateName = geography.properties && geography.properties.NAME_1;
  const stateBillsCount = stateCounters[stateName] || 0;
  const isSelected = selectedState && selectedState === stateName;

  const style = {
    default: {
      fill: isSelected ?
        HIGHLIGHT_COLOR :
        colorScale(+stateBillsCount, colorLimits, mapColors),
      stroke: STROKE_COLOR,
      outline: 'none',
    },
    hover: {
      fill: HOVER_COLOR,
      stroke: STROKE_COLOR,
      strokeWidth: 0.75,
      outline: 'none',
    },
    pressed: {
      fill: isSelected ? HIGHLIGHT_COLOR : HOVER_COLOR,
      stroke: STROKE_COLOR,
      strokeWidth: 0.75,
      outline: 'none',
    },
  };

  return (
    <Geography
      key={stateId}
      cacheId={stateId}
      geography={geography}
      projection={projection}
      onClick={onStateClick}
      style={style}
      onMouseEnter={onStateHover(stateName, stateBillsCount)}
      round
    />
  );
}

MapState.propTypes = {
  projection: PropTypes.func.isRequired,
  geography: PropTypes.object.isRequired,
  colorLimits: PropTypes.array.isRequired,
  stateCounters: PropTypes.object.isRequired,
  mapColors: PropTypes.array.isRequired,
  onStateClick: PropTypes.func,
  onStateHover: PropTypes.func,
  selectedState: PropTypes.string,
};

export default MapState;
