import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  titleContainer: {
    marginBottom: 8,
  },
  title: {
    fontSize: 10,
  },
  description: {
    fontSize: 8,
  },
});

export default styles;
