import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import { Text, StyleSheet } from '@react-pdf/renderer';

import {
  WHITE_COLOR,
  DEMOCRATIC_PARTY_COLOR,
  REPUBLICAN_PARTY_COLOR,
  DEFAULT_PARTY_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

import { pdfStylesShape } from '../../utils/shapes';

const styles = StyleSheet.create({
  partyIcon: {
    marginLeft: 2,
    borderRadius: 4,
    color: WHITE_COLOR,
    padding: '1px 4px',
    fontWeight: 700,
    fontSize: 11,
    backgroundColor: DEFAULT_PARTY_COLOR,
    textTransform: 'capitalize',
  },
  democratic: {
    backgroundColor: DEMOCRATIC_PARTY_COLOR,
  },
  democrat: {
    backgroundColor: DEMOCRATIC_PARTY_COLOR,
  },
  republican: {
    backgroundColor: REPUBLICAN_PARTY_COLOR,
  },
});

function PartyIcon({
  partyShort,
  partySlug,
  style,
}) {
  if (!partyShort) {
    return null;
  }

  const partyIconStyles = {
    ...styles.partyIcon,
    ...(styles[toLower(partySlug)] || {}),
    ...style,
  };

  return <Text style={partyIconStyles}>{partyShort}</Text>;
}

PartyIcon.defaultProps = {
  style: {},
};

PartyIcon.propTypes = {
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  style: pdfStylesShape,
};

export default PartyIcon;
