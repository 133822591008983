import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import ButtonTagManager from 'components/core/ButtonTagManager';
import {
  FooterContainer,
  ShowClusterLink,
} from 'components/core/News/components/Cards/common';
import useModal from 'components/core/Modal/useModal';
import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { selectedFeedShape } from 'components/News/utils/shapes';
import {
  getMailLinkFromObj,
  getTwitterShareLink,
  getLinkedInShareLink,
  getFacebookDescriptionLink,
} from 'utils/socialMedia/links';
import { EMAIL_ID, FACEBOOK, LINKEDIN, TWITTER } from 'utils/constants/socialMedia';
import { trackNewsShared } from 'utils/tracking/news';
import { removeHTMLElements } from 'utils/string';

import styles from './styles.module.scss';

function Footer({
  className,
  newsHref,
  selectedFeed,
  isClusterPage,
  newsTitle,
  newsSummary,
  clusterIds,
  feedType,
  displayShowRelated,
  onSaveTags,
  tags,
  isLoadingTags,
  onShareToMyFeed,
  isSharingToMyFeed,
  isDesktop,
}) {
  const isSingleCluster = clusterIds.length === 1;
  const [clusterId] = clusterIds;
  const { isOpenModal: isOpenTooltip, toggleModal: toggleTooltip } = useModal();

  function handleTracking(shareType) {
    return () => {
      trackNewsShared(shareType, feedType);
      toggleTooltip();
    };
  }

  function handleShareToMyFeed() {
    return onShareToMyFeed()
      .then(toggleTooltip);
  }

  function getContent() {
    const externalLinkProps = {
      isExternal: true,
      displayExternalIcon: true,
      theme: 'light',
      className: styles.link,
    };
    const mailInfo = {
      subject: `${newsTitle} via Intelligence`,
      body: `${removeHTMLElements(newsSummary)} - ${newsHref}`,
    };

    return (
      <div className={styles.tooltipContent}>
        <h2 className={styles.tooltipTitle}>Share to...</h2>
        <Button
          theme="light"
          onClick={handleShareToMyFeed}
          className={styles.link}
          disabled={isSharingToMyFeed}
        >
          My feed
        </Button>
        <Link
          {...externalLinkProps}
          href={getFacebookDescriptionLink(newsHref, newsTitle)}
          onClick={handleTracking(FACEBOOK)}
        >
          Facebook
        </Link>
        <Link
          {...externalLinkProps}
          href={getTwitterShareLink({ link: newsHref, text: newsTitle })}
          onClick={handleTracking(TWITTER)}
        >
          Twitter
        </Link>
        <Link
          {...externalLinkProps}
          href={getLinkedInShareLink(newsHref)}
          onClick={handleTracking(LINKEDIN)}
        >
          LinkedIn
        </Link>
        <Link
          {...externalLinkProps}
          href={getMailLinkFromObj(mailInfo)}
          onClick={handleTracking(EMAIL_ID)}
        >
          Email
        </Link>
      </div>
    );
  }

  return (
    <FooterContainer className={className}>
      <div className={styles.leftActions}>
        <ButtonTagManager
          theme="light"
          size="small"
          buttonClassName={styles.footerButton}
          onSaveTags={onSaveTags}
          isLoading={isLoadingTags}
          tags={tags}
          autoload
        />

        {newsHref && (
          <Tooltip
            placement="bottom-start"
            className={styles.tooltip}
            content={getContent()}
            theme="light"
            interactive
            onClickOutside={toggleTooltip}
            visible={isOpenTooltip}
          >
            <span className={styles.shareWrapper}>
              <Button
                theme="light"
                size="small"
                className={styles.footerButton}
                onClick={toggleTooltip}
              >
                Share
              </Button>
            </span>
          </Tooltip>
        )}
      </div>

      {(displayShowRelated && isSingleCluster && !isClusterPage) && (
        <ShowClusterLink
          selectedFeed={selectedFeed}
          id={clusterId}
          isDesktop={isDesktop}
        />
      )}
    </FooterContainer>
  );
}

Footer.defaultProps = {
  clusterIds: [],
};

Footer.propTypes = {
  newsHref: PropTypes.string,
  newsTitle: PropTypes.string,
  clusterIds: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  newsSummary: PropTypes.string,
  selectedFeed: selectedFeedShape,
  isClusterPage: PropTypes.bool,
  className: PropTypes.string,
  feedType: PropTypes.string,
  displayShowRelated: PropTypes.bool,
  onSaveTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  isLoadingTags: PropTypes.bool.isRequired,
  onShareToMyFeed: PropTypes.func.isRequired,
  isSharingToMyFeed: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default Footer;
