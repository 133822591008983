import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withMap from 'components/core/Map/utils/withMap';

import styles from '../styles.module.scss';

function Header({ properties }) {
  const { name } = properties;

  return (
    <header className={styles.header}>
      <i className={classNames('fas', 'fa-address-card', styles.icon)} />
      <h3 className={styles.title}>{name}</h3>
    </header>
  );
}

Header.propTypes = {
  properties: PropTypes.object.isRequired,
};

export default withMap(Header);
