import React from 'react';

export const NewsContext = React.createContext();

function withNews(Component) {
  return function NewsComponent(props) {
    return (
      <NewsContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </NewsContext.Consumer>
    );
  };
}

export default withNews;
