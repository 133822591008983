import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/core/HearingCalendars/components/Icon';
import styles from './styles.module.scss';

function Item({ text, className }) {
  return (
    <div className={classNames(className, styles.item)}>
      <Icon />
      <span className={styles.text}>{text}</span>
    </div>
  );
}

Item.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

Item.defaultProps = {
  text: '',
  className: '',
};

export default Item;
