const defaultTableColumns = [
  {
    label: 'Bill',
    keyName: 'bill',
    sortingKey: 'id',
    allowToggle: true,
  },
  {
    label: 'Introduced',
    keyName: 'introducedAt',
    sortingKey: 'introduced_at',
    allowToggle: true,
  },
  {
    label: 'Last action',
    keyName: 'lastAction',
    sortingKey: 'last_action.text.raw',
    allowToggle: true,
  },
  {
    label: 'Last action date',
    keyName: 'lastActionDate',
    sortingKey: 'last_action.acted_at',
    allowToggle: true,
  },
  {
    label: 'Status',
    keyName: 'status',
    sortingKey: 'status_full.raw',
    allowToggle: true,
  },
  {
    label: 'Sponsors',
    keyName: 'sponsors',
    sortingKey: 'sponsor.universal_moniker.raw',
    allowToggle: true,
  },
  {
    label: 'Cosponsors',
    keyName: 'cosponsors',
    allowToggle: false,
  },
  {
    label: 'Added to',
    keyName: 'addedTo',
    allowToggle: false,
  },
  {
    label: 'Tags',
    keyName: 'tags',
    allowToggle: false,
  },
  {
    label: 'Actions',
    keyName: 'actions',
    allowToggle: false,
  },
];

export default defaultTableColumns;
