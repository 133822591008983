import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import Modal from '../Modal';

function Confirm({
  isOpen,
  title,
  message,
  hideCancel,
  withAction,
  keepItOpened,
  bodyClassName,
  headerClassName,
  footerClassName,
  buttonClassName,
  disableContinue,
  messageContinue,
  action,
  toggle,
  children,
  ...restProps
}) {
  function handleSubmit() {
    action && withAction && action();
    !keepItOpened && toggle();
  }

  const buttons = (
    <>
      {!hideCancel && (
        <Button
          size="small"
          theme="light"
          className={buttonClassName}
          onClick={toggle}
        >
          Cancel
        </Button>
      )}

      <Button
        size="small"
        className={buttonClassName}
        disabled={disableContinue}
        onClick={handleSubmit}
      >
        {messageContinue}
      </Button>
    </>
  );

  const bodyContent = children || <p>{message}</p>;

  return (
    <Modal
      {...restProps}
      isOpen={isOpen}
      title={title}
      buttons={buttons}
      bodyClassName={bodyClassName}
      headerClassName={headerClassName}
      footerClassName={footerClassName}
      toggleOpen={toggle}
    >
      {bodyContent}
    </Modal>
  );
}

Confirm.defaultProps = {
  isOpen: false,
  title: 'Warning',
  messageContinue: 'Continue',
  disableContinue: false,
  keepItOpened: false,
  hideCancel: false,
  withAction: true,
};

Confirm.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.node,
  toggle: PropTypes.func.isRequired,
  withAction: PropTypes.bool,
  action: PropTypes.func,
  messageContinue: PropTypes.node,
  disableContinue: PropTypes.bool,
  keepItOpened: PropTypes.bool,
  hideCancel: PropTypes.bool,
  bodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
};

export default Confirm;
