import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { isEmpty, map } from 'lodash';

import styles from './styles';

function RowsLabelValues({ rows, style }) {
  if (isEmpty(rows)) {
    return null;
  }

  function renderRow(row, index) {
    const { label, value } = row;
    const isFirst = index === 0;

    return (
      <View key={`row-${label}-${index}`} style={[styles.row, !isFirst && styles.rowBorder]}>
        <View style={styles.labelView}>
          <Text style={styles.label}>
            {label}
          </Text>
        </View>
        <View style={styles.value}>{value}</View>
      </View>
    );
  }

  return (
    <View style={[styles.container, style]}>
      {map(rows, renderRow)}
    </View>
  );
}

RowsLabelValues.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node,
  })).isRequired,
  style: PropTypes.object,
};

export default RowsLabelValues;
