import PropTypes from 'prop-types';

export const displayShape = PropTypes.shape({
  profile: PropTypes.bool,
  yourActivity: PropTypes.bool,
});

export const sectionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});
