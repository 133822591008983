import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { StoryCard, StoryClusterCard } from 'components/core/News';
import { selectedFeedShape, cardShape, clusterCardShape } from 'components/News/utils/shapes';

function StoryFeedCard({
  card,
  showPreview,
  selectedFeed,
  isMobile,
  isDesktop,
  selectedFeedKeywords,
  onSelectCard,
  isChecked,
  feedType,
  displayCheckbox,
  displayShowRelated,
  isExternalComponent,
  onShowCluster,
  isLoadingTags,
  onSaveTags,
  isSharingToMyFeed,
  onShareToMyFeed,
  isLoadingMyFeedDocuments,
  isRouterLink,
}) {
  const { clusterId, data: stories, tags, sharings } = card;
  const [mainStory, ...restStories] = stories || [];

  const commonProps = {
    isMobile,
    isDesktop,
    selectedFeed,
    onSelectCard,
    isChecked,
    displayCheckbox,
    isExternalComponent,
    isRouterLink,
  };

  const singleProps = {
    ...commonProps,
    showPreview,
    selectedFeedKeywords,
    feedType,
    displayShowRelated,
    isLoadingTags,
    onSaveTags,
    isSharingToMyFeed,
    onShareToMyFeed,
    sharings,
    isLoadingMyFeedDocuments,
    tags,
  };

  function handleShowCluster() {
    onShowCluster(stories);
  }

  if (!clusterId) {
    return <StoryCard {...singleProps} card={card} />;
  }

  if (!isEmpty(restStories)) {
    const storyCluster = { clusterId, ...mainStory, stories: restStories };

    return (
      <StoryClusterCard
        {...commonProps}
        storyCluster={storyCluster}
        onShowCluster={handleShowCluster}
      />
    );
  }

  return <StoryCard {...singleProps} card={mainStory} />;
}

StoryFeedCard.defaultProps = {
  displayCheckbox: true,
  displayShowRelated: true,
  isExternalComponent: false,
  isRouterLink: false,
};

StoryFeedCard.propTypes = {
  card: PropTypes.oneOfType([
    cardShape,
    clusterCardShape,
  ]).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool,
  onSelectCard: PropTypes.func,
  selectedFeed: selectedFeedShape,
  selectedFeedKeywords: PropTypes.arrayOf(PropTypes.string),
  isChecked: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  displayShowRelated: PropTypes.bool,
  isExternalComponent: PropTypes.bool,
  onShowCluster: PropTypes.func,
  feedType: PropTypes.string,
  isLoadingTags: PropTypes.bool,
  onSaveTags: PropTypes.func,
  isSharingToMyFeed: PropTypes.bool.isRequired,
  onShareToMyFeed: PropTypes.func.isRequired,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
  isRouterLink: PropTypes.bool,
};

export default StoryFeedCard;
