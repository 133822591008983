import React from 'react';
import PropTypes from 'prop-types';

export default function Tab({ children, ...restProps }) {
  return <section {...restProps}>{children}</section>;
}

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};
