import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

const useNewComment = ({
  onPost,
  parentType,
  parentId,
  replyUserMention,
  onCancelReplyMention,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [previousReplyMention, setPreviousReplyMention] = useState('');
  const [mentions, setMentions] = useState([]);

  const inputHasPreviousReplyMention = value => (
    !isEmpty(previousReplyMention) && value.startsWith(previousReplyMention)
  );

  useEffect(() => {
    // if current reply mention someone, and the new also mention someone, should replace
    // if current reply don't mention someone, and the new mention someone, add new Mention
    // otherwise should remove previous mention.
    if (inputHasPreviousReplyMention(inputValue) && replyUserMention) {
      setInputValue(inputValue.replace(previousReplyMention, replyUserMention));
    } else if (replyUserMention) {
      setInputValue(`${replyUserMention} ${inputValue}`);
    } else {
      setInputValue(inputValue.replace(previousReplyMention, '').trimLeft());
      onCancelReplyMention();
    }

    setPreviousReplyMention(replyUserMention);
  }, [replyUserMention]);

  const handleClick = () => {
    !isEmpty(inputValue) && onPost({ text: inputValue, parentType, parentId, mentions })
      .then(() => setInputValue(''));
  };
  const handleChange = ({ target: { value } }) => {
    setInputValue(value);
    if (replyUserMention && !inputHasPreviousReplyMention(value)) {
      onCancelReplyMention();
      setPreviousReplyMention('');
    }
  };
  const handleMentionsChange = newMentions => setMentions(newMentions);

  return {
    inputValue,
    handleClick,
    handleChange,
    handleMentionsChange,
  };
};

export default useNewComment;
