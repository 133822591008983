import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import Box from 'components/core/Box';

import ItemCards from './ItemCards';
import styles from '../styles.module.scss';

function TimeGroup({
  timeGroupItem,
  isFirstCard,
  renderCustomTitle,
  isUpperCaseItemTitle,
  renderCustomCardContent,
  disableFirstCardStyle,
  renderCustomBoxText,
}) {
  const { date, events } = timeGroupItem;

  function renderBoxText() {
    if (isFunction(renderCustomBoxText)) {
      return renderCustomBoxText({ isFirstCard, date, events });
    }

    return date;
  }

  return (
    <Fragment>
      <Box text={renderBoxText()} className={styles.title} />
      <ItemCards
        isFirstCard={!disableFirstCardStyle && isFirstCard}
        renderCustomTitle={renderCustomTitle}
        isUpperCaseItemTitle={isUpperCaseItemTitle}
        events={events}
        renderCustomCardContent={renderCustomCardContent}
      />
    </Fragment>
  );
}

TimeGroup.propTypes = {
  timeGroupItem: PropTypes.object,
  isUpperCaseItemTitle: PropTypes.bool,
  renderCustomCardContent: PropTypes.func,
  isFirstCard: PropTypes.bool,
  renderCustomTitle: PropTypes.func,
  disableFirstCardStyle: PropTypes.bool,
  renderCustomBoxText: PropTypes.func,
};

export default TimeGroup;
