import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useCheckedItems from 'components/core/AdvancedSearch/utils/useCheckedItems';
import Title from 'components/core/Profile/components/Title';
import LabelGroup from 'components/core/LabelGroup';

import titles from '../../utils/titles';
import LabelItem from '../LabelItem';
import styles from '../styles.module.scss';

function Sections({
  values,
  onChange,
  title,
  description,
  exportTabItems,
  enableTooltip,
}) {
  const allInitialValues = exportTabItems.map(tab => tab.id) || [];
  const { checkedItems, onCheckAll, onCheck, onCheckSubItem, ...restProps } = useCheckedItems(
    'sections',
    values,
    onChange,
    allInitialValues,
    exportTabItems,
  );
  const titleClassName = {
    className: enableTooltip ? styles.titleWithTooltip : styles.container,
    titleClassName: styles.title,
    descriptionClassName: styles.description,
  };

  function getLabel(item) {
    const { title: itemTitle, isSelectAll, className, onCheckItem } = item || {};
    const handleCheck = onCheckItem || onCheck;

    return (
      <div className={className} key={`export-label-${itemTitle}`}>
        <LabelItem
          {...item}
          {...restProps}
          checkedItems={checkedItems}
          onCheck={isSelectAll ? onCheckAll : handleCheck}
          allItemsLength={allInitialValues.length}
        />
      </div>
    );
  }

  /** If select all/default options are present -> include subItems array in allInitialValues **/
  function renderSubItems(subItems, item) {
    if (isEmpty(subItems)) {
      return null;
    }

    const { id } = item || {};
    const isParentChecked = checkedItems.includes(id);

    if (!isParentChecked) {
      return null;
    }

    return (
      <div className={styles.subItemsContainer}>
        {subItems.map(props => getLabel({
          ...props,
          className: styles.subItem,
          onCheckItem: event => onCheckSubItem(event, item),
        }))}
      </div>
    );
  }

  function renderLabelItems(item, index) {
    const { subItems } = item || {};

    return (
      <Fragment key={`export-customization-${index}`}>
        {getLabel(item)}
        {renderSubItems(subItems, item)}
      </Fragment>
    );
  }

  return (
    <>
      <Title
        title={title}
        description={description}
        enableTooltip={enableTooltip}
        {...titleClassName}
      />

      <LabelGroup>
        {exportTabItems.map(renderLabelItems)}
      </LabelGroup>
    </>
  );
}

Sections.defaultProps = {
  title: titles.sections.title,
  description: titles.sections.description,
};

Sections.propTypes = {
  exportTabItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.node,
  })),
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  enableTooltip: PropTypes.bool,
};

export default Sections;
