import React from 'react';

import LabelGroup from 'components/core/LabelGroup';
import Checkbox from 'components/core/Checkbox';
import Label from 'components/core/Label';

import shapes from '../../shapes';

function ColumnsSelection({
  item,
  columnsManager,
}) {
  function renderColumn(column) {
    return (
      <Checkbox
        key={column.value}
        value={column.value}
        label={column.label}
        isChecked={columnsManager.isChecked(item, column)}
        onChange={() => columnsManager.onCheck(item, column)}
      />
    );
  }

  return (
    <>
      <Label theme="medium">Choose which <b>{item.label}</b> columns to include</Label>
      <LabelGroup>
        <Checkbox
          isChecked={columnsManager.isCheckedAll(item)}
          onChange={() => columnsManager.onCheckAll(item)}
          label="Select/Deselect all"
        />
        {item.columns.map(renderColumn)}
      </LabelGroup>
    </>
  );
}

ColumnsSelection.propTypes = {
  item: shapes.item,
  columnsManager: shapes.columnsManager,
};

export default ColumnsSelection;
