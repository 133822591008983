import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import Bill from './components/Bill';

function StateBills({ bills, style, ...restDisplayProps }) {
  return (
    <View style={style}>
      {bills.map((bill, index) => (
        <Bill key={`bill=${bill.id}-${index}`} bill={bill} {...restDisplayProps} />
      ))}
    </View>
  );
}

StateBills.defaultProps = {
  bills: [],
};

StateBills.propTypes = {
  bills: PropTypes.array,
  style: PropTypes.object,
};

export default StateBills;
