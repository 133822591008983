import React from 'react';
import PropTypes from 'prop-types';

import {
  veryHigh,
  high,
  mediumHigh,
  medium,
  mediumLow,
  low,
  veryLow,
} from 'components/core/Map/utils/settings.scss';
import styles from './styles.module.scss';

export default function Legend({ title, options }) {
  return (
    <div className={styles.legend}>
      <h4 className={styles.title}>Legend: {title}</h4>
      <ul className={styles.options}>
        {options.map((option, index) => {
          const iconStyle = { backgroundColor: option.color };

          return (
            <li key={`${option.value}-${index}`} className={styles.option}>
              <span className={styles.icon} style={iconStyle} />
              <span className={styles.label}>
                {option.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

Legend.defaultProps = {
  title: 'Density',
  options: [
    {
      color: veryHigh,
      label: 'Very High',
    },
    {
      color: high,
      label: 'High',
    },
    {
      color: mediumHigh,
      label: 'Medium High',
    },
    {
      color: medium,
      label: 'Medium',
    },
    {
      color: mediumLow,
      label: 'Medium Low',
    },
    {
      color: low,
      label: 'Low',
    },
    {
      color: veryLow,
      label: 'Very Low',
    },
  ],
};

Legend.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
};
