import { tagStyle } from 'components/core/TagInput/theme';
import {
  controlPadding,
  controlBackground,
  controlOpenBackground,
  controlOpenBorderRadius,
  controlBoxShadow,
  controlBorderColor,
  boxShadow,
  menuBorderRadius,
  minControlHeight,
  mediumControlHeight,
  backgroundErrorColor,
  borderError,
} from './settings.scss';

const style = (hasNewStyle, isError) => ({
  control: (base, state) => ({
    ...base,
    minHeight: minControlHeight,
    ...(state.isFocused && {
      boxShadow: controlBoxShadow,
      borderColor: controlBorderColor,
    }),
    '&:hover': {
      borderColor: base.borderColor,
    },
    ...(hasNewStyle && {
      border: 'unset',
      padding: controlPadding,
      backgroundImage: controlBackground,
      boxShadow,
      minHeight: mediumControlHeight,
      ...(state.menuIsOpen && {
        borderRadius: controlOpenBorderRadius,
        backgroundColor: controlOpenBackground,
        backgroundImage: 'none',
      }),
      ...(isError && {
        background: backgroundErrorColor,
        border: borderError,
        boxShadow: 'none',
        '&:hover': {
          border: borderError,
        },
      }),
    }),
  }),
  option: base => ({
    ...base,
    ...(hasNewStyle && {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',
    }),
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: base => ({
    ...base,
    ...(hasNewStyle && {
      border: 'unset',
      backgroundImage: controlBackground,
      borderRadius: menuBorderRadius,
      boxShadow,
      zIndex: 2,
      overflowY: 'hidden',
      margin: 0,
    }),
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    ...(hasNewStyle && !state.selectProps.isClearable && {
      display: 'none',
    }),
  }),
  multiValue: (base, state) => ({
    ...base,
    ...(state.data.isFixed && {
      backgroundColor: 'gray',
    }),
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    ...(state.data.isFixed && {
      fontWeight: 'bold',
      color: 'white',
      paddingRight: 6,
    }),
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    ...(state.data.isFixed && {
      display: 'none',
    }),
  }),
  ...(hasNewStyle && tagStyle),
  placeholder: base => ({
    ...base,
    ...(hasNewStyle && {
      fontStyle: 'italic',
      fontWeight: 300,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',
    }),
  }),
});

export default style;
