import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';

import { DRAG_END_REASON } from './constants';
import styles from './styles.module.scss';

function KanbanBoard({ children, onMoveCard, className }) {
  const handleDragEnd = ({ destination, source, draggableId, reason }) => {
    if (!destination || destination.droppableId === source.droppableId || reason === DRAG_END_REASON.CANCEL) {
      return;
    }

    // onMoveCard is responsible to reorder the cards when they move
    // tip: if you make the reordering syncronously in the state of your
    // component(instead of waiting redux/api response), it will work more smoothly
    onMoveCard({
      destinationColumnId: destination.droppableId,
      sourceColumnId: source.droppableId,
      cardId: draggableId,
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classNames(styles.board, className)}>
        {children}
      </div>
    </DragDropContext>
  );
}

KanbanBoard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onMoveCard: PropTypes.func.isRequired,
};

export default KanbanBoard;

export { default as Column } from './components/Column';
export * from './components/Column';
