import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type ItemProps = {
  label: ReactNode;
  isChecked: boolean;
}

function Item({ label, isChecked }: ItemProps): ReactNode {
  const starIconClassName = isChecked ? classNames('fas fa-star', styles.saved) : 'far fa-star';

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <i className={starIconClassName} />
      </div>
      <div className={styles.label}>
        {label}
      </div>
    </div>
  );
}

export default Item;
