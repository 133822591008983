import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import map from 'lodash/map';

import { positionTooltipZIndex } from 'style/global/_variables.scss';

import { positionsAligned } from '../../utils/constants';
import TooltipButton from './TooltipButton';
import useDropdownPosition from './useDropdownPosition';
import styles from './styles.module.scss';

function DropdownPosition({
  initialOption,
  isLoadingOption,
  baseParams,
  containerClassName,
  handleUpdateOption,
  onSetOption,
}) {
  const { option, isLoading, onChange } = useDropdownPosition({
    initialOption,
    isLoadingOption,
    baseParams,
    handleUpdateOption,
    onSetOption,
  });

  const renderOption = positionOption => (
    <TooltipButton
      key={positionOption}
      position={positionOption}
      className={styles.button}
      isDisabled={isLoading}
      iconClassName={styles.icon}
      onClick={onChange}
    />
  );

  const content = (
    <div className={styles.positionsContainer}>
      {map(Object.keys(positionsAligned), renderOption)}
    </div>
  );

  return (
    <Tooltip
      theme="light"
      placement="bottom-end"
      content={!isLoading && content}
      disabled={isLoading}
      zIndex={positionTooltipZIndex}
      className={styles.tooltip}
      trigger="click"
    >
      <span className={classNames(styles.container, containerClassName)}>
        <TooltipButton
          position={option}
          className={styles.button}
          isLoading={isLoading}
          iconClassName={styles.icon}
          onClick={onChange}
        />
      </span>
    </Tooltip>
  );
}

DropdownPosition.propTypes = {
  isLoadingOption: PropTypes.bool,
  containerClassName: PropTypes.string,
  baseParams: PropTypes.shape({
    recordId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    recordType: PropTypes.string,
  }),
  initialOption: PropTypes.string.isRequired,
  onSetOption: PropTypes.func.isRequired,
  handleUpdateOption: PropTypes.func.isRequired,
};

export default DropdownPosition;
