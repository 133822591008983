import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';

import { positionShape } from 'components/core/Profile/utils/shapes';

import Position from '../Position';

function Positions({ positions }) {
  if (isEmpty(positions)) {
    return null;
  }

  function renderPosition(position, index) {
    return (
      <Position
        positionIndex={index + 1}
        position={position}
        title={`Role/Office ${index + 1}`}
        key={`role-office-${index}`}
      />
    );
  }

  return map(positions, renderPosition);
}

Positions.propTypes = {
  positions: PropTypes.arrayOf(positionShape),
};

export default Positions;
