import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/core/Link';
import withMap from 'components/core/Map/utils/withMap';

import styles from '../styles.module.scss';

function Footer({ properties, profileUrl }) {
  const { id } = properties;

  return (
    <footer className={styles.footer}>
      <Link
        href={`${profileUrl}${id}`}
        theme="light"
        size="medium"
        className={classNames('marker-view', styles.link)}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Profile
        <i className="far fa-external-link" />
      </Link>
    </footer>
  );
}

Footer.propTypes = {
  properties: PropTypes.object.isRequired,
  profileUrl: PropTypes.string.isRequired,
};

export default withMap(Footer);
