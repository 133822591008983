import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/core/Input';
import { BaseOption } from 'components/core/AdvancedSearch';

import styles from './styles.module.scss';

const TEXT_EXCLUDES_KEY = 'textExcludes';

function TextExcludesField({ value, onChange, titles, className }) {
  function handleChange(event) {
    const { value: eventValue } = event.target;

    onChange(TEXT_EXCLUDES_KEY, eventValue);
  }

  const customComponent = (
    <Input
      value={value}
      onChange={handleChange}
      className={styles.textExcludesInput}
    />
  );

  return <BaseOption {...titles.textExcludes} customComponent={customComponent} className={className} />;
}

TextExcludesField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  titles: PropTypes.object,
  className: PropTypes.string,
};

export default TextExcludesField;
