import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { displayTypeCodes } from 'components/core/Map/utils/constants';
import withMap from 'components/core/Map/utils/withMap';

import MapType from '../MapType';
import Heatmap from '../Heatmap';
import Legend from '../Heatmap/components/Legend';
import styles from '../../styles.module.scss';

function DesktopView({ filters, withHeatmap, activeFilters, onFilterClick }) {
  const mapTypeKey = 'mapType';
  const heatmapKey = 'displayType';
  const showHeatmap = activeFilters[heatmapKey] === displayTypeCodes.heatmap;

  return (
    <Fragment>
      <div className={styles.container}>
        <MapType
          {...filters[mapTypeKey]}
          filterKey={mapTypeKey}
          onFilterClick={onFilterClick(mapTypeKey)}
          selectedFilter={activeFilters[mapTypeKey]}
        />
        {withHeatmap && (
          <Heatmap
            {...filters[heatmapKey]}
            filterKey={heatmapKey}
            onFilterClick={onFilterClick(heatmapKey)}
            selectedFilter={activeFilters[heatmapKey]}
          />
        )}
      </div>
      {showHeatmap && <Legend />}
    </Fragment>
  );
}

DesktopView.propTypes = {
  filters: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  withHeatmap: PropTypes.bool.isRequired,
};

export default withMap(DesktopView);
