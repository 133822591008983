import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export default function Separator({ className }) {
  return <hr className={classNames(styles.separator, className)} />;
}

Separator.propTypes = {
  className: PropTypes.string,
};
