import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';

import styles from './styles';

function CreationDate({ children }) {
  return (
    <Text style={styles.creationDate}>
      {children}
    </Text>
  );
}

CreationDate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CreationDate;
