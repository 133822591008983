// @ts-nocheck
import React from 'react';

import Button from 'components/core/Button';

import useBriefingReport from './useBriefingReport';

export { useBriefingReport };

type Props = {
  url: string;
  title?: string;
  theme?: string;
  className?: string;
}

function BriefingReportButton({ url, title = 'Briefing report', theme = 'light', className }: Props) {
  const { isLoading, onBriefingClick } = useBriefingReport(url);

  return (
    <Button
      theme={theme}
      onClick={onBriefingClick}
      disabled={isLoading}
      className={className}
    >
      {title}
    </Button>
  );
}

export default BriefingReportButton;
