import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_VALUE } from 'utils/constants';

import Info from './Info';

function ArrayInfo({ label, values, emptyValue, isMobile }) {
  if (isEmpty(values)) {
    return <Info label={label} value={emptyValue} />;
  }

  const isMultipleValues = values.length > 1;

  function getMultipleFieldLabel(index, title, category, className) {
    return (
      <span>
        {title} {isMultipleValues && index + 1}
        {category && !isMobile && (
          <span className={className}> ({category})</span>
        )}
      </span>
    );
  }

  function renderInfo(valueItem, index) {
    const { category, value, ...restProps } = valueItem;

    return (
      <Info
        {...restProps}
        key={`${label}-${index}`}
        label={getMultipleFieldLabel(index, label, category)}
        value={value}
      />
    );
  }

  return values.map(renderInfo);
}

ArrayInfo.defaultProps = {
  emptyValue: EMPTY_VALUE,
  isMobile: false,
};

ArrayInfo.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isMobile: PropTypes.bool,
};

export default ArrayInfo;
