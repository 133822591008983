import { useEffect, useState } from 'react';

import { notifyBriefingError } from 'components/core/Profile/utils/notifications';
import useLoadingRequest from 'utils/useLoadingRequest';

import { fetchBriefing } from './utils/api';

function useBriefingReport(url) {
  const [reportUrl, setReportUrl] = useState('');
  const { onLoad, isLoading } = useLoadingRequest({ loadRequest: fetchBriefing(url) });

  useEffect(() => {
    onLoad().then(response => setReportUrl(response));
  }, []);

  const onBriefingClick = () => {
    reportUrl ? window.open(reportUrl, '_blank') : notifyBriefingError();
  };

  return {
    isLoading,
    onBriefingClick,
  };
}

export default useBriefingReport;
