import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Badge({ children, className, badgeClassName }) {
  return (
    <div className={classNames(styles.container, className)}>
      <span className={classNames(styles.badge, badgeClassName)}>
        {children}
      </span>
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  badgeClassName: PropTypes.string,
};

export default Badge;
