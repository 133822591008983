import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { filter, size, isEmpty } from 'lodash';

import Actions from 'components/core/Profile/components/Actions';
import withCalendar from 'components/core/HearingCalendars/utils/withCalendar';
import constants from 'components/core/HearingCalendars/utils/constants';
import { extractAllEvents } from 'components/core/HearingCalendars/utils';
import Button from 'components/core/Button';
import { CheckboxButton } from 'components/core/Checkbox';
import {
  trackFederalAllHearingsSelected,
  trackStateAllHearingsSelected,
} from 'utils/tracking/intel';

import { typeShape, eventShape } from '../../utils/shapes';
import ExportHearingCalendarsModal from '../ExportHearingCalendarsModal';
import useBulkExport from './useBulkExport';
import styles from './styles.module.scss';

function ActionButtons({
  type,
  allItems,
  onCheckAllEvents,
  checkedEvents,
  isLoadingEvents,
  isExportOpen,
}) {
  const {
    toggleModal,
    isOpenModal,
    isBulkExportDisabled,
  } = useBulkExport({ allItems, isExportOpen });

  const options = filter([
    {
      id: 'bulkExport',
      component: (
        <Button
          disabled={isBulkExportDisabled}
          onClick={toggleModal}
          theme="light"
        >
          Bulk export
        </Button>
      ),
    },
  ]);

  const events = extractAllEvents(allItems);
  const isAllChecked = !isEmpty(checkedEvents) && size(checkedEvents) === size(events);
  const isDisabled = isEmpty(events) || isLoadingEvents;
  const onCheckAllTrack = type === constants.FEDERAL
    ? trackFederalAllHearingsSelected
    : trackStateAllHearingsSelected;

  function handleCheckAll(event) {
    const { checked } = event.target;

    checked && onCheckAllTrack();
    onCheckAllEvents(checked);
  }

  return (
    <>
      <div className={styles.rightButtonGroup}>
        <CheckboxButton
          label="Select all"
          onChange={handleCheckAll}
          isChecked={isAllChecked}
          isDisabled={isDisabled}
          className={classNames('hearing-select-all', styles.checkboxButton)}
        />
        <Actions
          theme="secondary"
          options={options}
          large
        />
      </div>
      <ExportHearingCalendarsModal
        isOpenModal={isOpenModal}
        type={type}
        allItems={allItems}
        toggleModal={toggleModal}
      />
    </>
  );
}

ActionButtons.propTypes = {
  type: typeShape.isRequired,
  allItems: PropTypes.arrayOf(eventShape),
  checkedEvents: PropTypes.arrayOf(eventShape).isRequired,
  onCheckAllEvents: PropTypes.func.isRequired,
  isLoadingEvents: PropTypes.bool,
  isExportOpen: PropTypes.bool.isRequired,
};

export default withCalendar(ActionButtons);
