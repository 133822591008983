import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import { useDismissibleAlert } from '../useDismissibleAlert';
import styles from './styles.module.scss';

function DismissibleAlert({ showAlert, className, children, ...restProps }) {
  const { handleClose, isDisabled } = useDismissibleAlert(restProps);

  if (!showAlert) {
    return null;
  }

  return (
    <div className={classNames(styles.container, isDisabled && styles.isDisabled, className)}>
      <span className={styles.content}>{children}</span>

      <Button
        theme="none"
        customIcon={<i className="fa fa-times" />}
        onClick={handleClose}
        disabled={isDisabled}
        className={styles.button}
        iconClassName={styles.icon}
      />
    </div>
  );
}

DismissibleAlert.propTypes = {
  ui: PropTypes.shape({
    isLoadingAlert: PropTypes.bool,
    isDismissingAlert: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
  showAlert: PropTypes.bool.isRequired,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  fetchAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default DismissibleAlert;
