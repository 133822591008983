import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/core/Table/components/Header/components/Tooltip';
import Caret from 'components/core/Accordion/components/Caret';

import styles from './styles.module.scss';

function AccordionTitle({ isOpen, title, tooltip, toggle }) {
  return (
    <div className={styles.container}>
      <div className={styles.content} onClick={toggle}>
        <h4>
          {title}{tooltip && <Tooltip content={<div>{tooltip}</div>} />}
        </h4>
        <Caret isOpen={isOpen} />
      </div>

      <hr />
    </div>
  );
}

AccordionTitle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

export default AccordionTitle;
