import { get, isEmpty, startCase } from 'lodash';

const transformValue = (value) => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    return value;
  }

  if (isEmpty(value)) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.map(transformValue).join(', ');
  }

  if (typeof value === 'object') {
    return get(value, 'label', '');
  }

  return '';
};

export const defaultOptionTransformer = {
  label: (key, value) => startCase(key),
  value: (key, value) => transformValue(value),
};

const transformFilters = (search, transformer = {}) => {
  const filterKeys = Object.keys(search.search_params);

  return filterKeys.map((key) => {
    const optionsTransformer = transformer[key] || defaultOptionTransformer;
    const value = search.search_params[key];

    return {
      label: optionsTransformer.label(key, value),
      value: optionsTransformer.value(key, value),
    };
  });
};

export const transformSearches = (searches, transformer = {}) => searches.map(search => ({
  ...search,
  title: search.name || 'Filters applied',
  filters: transformFilters(search, transformer),
}));

export const transformPagination = pagination => ({
  current: pagination.current_page,
  total: pagination.total_pages,
  totalCount: pagination.total_count,
  perPage: pagination.per_page,
});
