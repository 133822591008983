import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';

import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles.module.scss';

function InfoTab({ label, value, renderValue, className }) {
  if (isFunction(renderValue)) {
    return (
      <>
        <p className={styles.label}>{label}</p>
        {renderValue()}
      </>
    );
  }

  return (
    <>
      <p className={styles.label}>{label}</p>
      <p className={classNames(styles.value, className)}>{value || EMPTY_VALUE}</p>
    </>
  );
}

InfoTab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  renderValue: PropTypes.func,
  className: PropTypes.string,
};

export default InfoTab;
