import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';

import Details from '../Details';
import { getOrganizationContent } from './utils';

function Membership({ person, isLoading, hideOnEmptyData }) {
  const { membership = [] } = person || {};

  const sections = [{
    id: 'membership',
    title: 'Membership',
    tables: [{
      id: 'organizations',
      label: 'Organization',
      placeholderSize: 2,
      columns: [{
        id: 'organization',
        getContent: getOrganizationContent,
      }],
      data: membership,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(membership)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

Membership.defaultProps = {
  hideOnEmptyData: true,
};

Membership.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(Membership);
