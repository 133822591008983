import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import withSearch from 'components/core/SearchHeader/utils/withSearch';

import styles from './styles.module.scss';

function AdvancedSearch({
  handleAdvancedSearchToggle,
  appliedFiltersLength,
  isMobile,
  onClearAll,
}) {
  const customIcon = <i className={classNames('fas', 'fa-filter', styles.icon)} />;

  if (isMobile) {
    return (
      <div className={styles.advancedSearch}>
        <Button
          theme="default"
          customIcon={customIcon}
          onClick={handleAdvancedSearchToggle}
          className={styles.button}
          iconClassName={styles.iconWrapper}
        />
        {appliedFiltersLength > 0 && (
          <span className={styles.badge}>
            {appliedFiltersLength}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className={styles.advancedSearch}>
      <Button
        theme="light"
        customIcon={customIcon}
        onClick={handleAdvancedSearchToggle}
        className={styles.button}
        iconClassName={styles.iconWrapper}
      >
        <span className={styles.text}>Advanced Search</span>
        {appliedFiltersLength > 0 ? ` (${appliedFiltersLength})` : ''}
      </Button>

      {appliedFiltersLength > 0 && (
        <Button
          theme="link"
          onClick={onClearAll}
          className={styles.clearAll}
        >
          Clear All
        </Button>
      )}
    </div>
  );
}

AdvancedSearch.defaultProps = {
  isMobile: false,
  appliedFiltersLength: 0,
};

AdvancedSearch.propTypes = {
  isMobile: PropTypes.bool,
  appliedFiltersLength: PropTypes.number,
  handleAdvancedSearchToggle: PropTypes.func,
  onClearAll: PropTypes.func,
};

export default withSearch(AdvancedSearch);
