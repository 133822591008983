import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import DataCard from 'components/core/Profile/components/DataCard';
import TablePaginator from 'components/core/Paginator/TablePaginator';
import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import Table, { Column } from 'components/core/Table';

import useTable from './useTable';
import styles from './styles.module.scss';

function TotalTable({
  id,
  isMobile,
  isLoading,
  title,
  description,
  questionText,
  emptyDescription,
  tableColumns,
  items,
  sort,
  paginator,
  trackingActions,
  renderColumn,
  customColumnsElement,
  onSearch,
  uniqueKey,
  customData,
  totalLength,
  sortUI,
  customSortColumn,
  customChangePage,
}) {
  const isEmptyData = isEmpty(items);

  function getTotalItems() {
    if (totalLength) return totalLength;
    if (!isEmpty(paginator)) return paginator.totalCount || 0;
    if (!isEmptyData) return items.length || 0;

    return 0;
  }

  const totalItemsLength = getTotalItems();
  const totalTextElement = !isLoading && (
    <div className={styles.totalText}>Total: {totalItemsLength}</div>
  );

  const tableProps = {
    tableColumns,
    onSearch,
    sort,
    paginator,
    trackingActions,
    customData,
    totalLength: totalItemsLength,
    isLoading,
    customSortColumn,
    customChangePage,
  };

  const {
    isPaginatorPresent,
    shouldApplyFixedHeight,
    sortData,
    onSortColumn,
    onChangePage,
  } = useTable(tableProps);

  const tableClassName = classNames(
    styles.tableContent,
    shouldApplyFixedHeight && styles.fixedHeight,
  );

  function customRenderColumn(column, data) {
    const { keyName } = column || {};

    if (renderColumn) {
      return renderColumn(column, data);
    }

    return <Column key={keyName} keyName={keyName} />;
  }

  function getSortData(currentSortData) {
    const { column, direction } = currentSortData || {};

    return { sortColumn: column, sortDirection: direction };
  }

  const currentSortData = isEmpty(sortUI) ? getSortData(sortData) : getSortData(sortUI);

  return (
    <DataCard
      id={id}
      title={title}
      description={description}
      questionText={questionText}
      emptyDescription={emptyDescription}
      showPlaceholder={!isLoading && isEmptyData}
      rightItems={totalTextElement}
    >
      <div className={styles.tableContainer}>
        <Table
          {...currentSortData}
          isLoading={isLoading}
          rowLengthPlaceholder={PAGE_SIZE}
          uniqueKey={uniqueKey}
          columns={tableColumns}
          containerStyle={tableClassName}
          data={items}
          onSort={onSortColumn}
          emptyDescription={emptyDescription}
          isPaginatorPresent={isPaginatorPresent}
          isMobile={isMobile}
          isFixed
        >
          {isEmpty(customColumnsElement)
            ? tableColumns.map(customRenderColumn)
            : customColumnsElement
          }
        </Table>

        <TablePaginator
          isMobile={isMobile}
          isLoading={isLoading}
          isPaginatorPresent={isPaginatorPresent}
          paginator={paginator}
          onChangePage={onChangePage}
        />
      </div>
    </DataCard>
  );
}

TotalTable.defaultProps = {
  uniqueKey: 'id',
};

TotalTable.propTypes = {
  id: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  questionText: PropTypes.string,
  emptyDescription: PropTypes.string,
  tableColumns: PropTypes.array.isRequired,
  items: PropTypes.array,
  sort: PropTypes.object,
  paginator: PropTypes.object.isRequired,
  trackingActions: PropTypes.object,
  renderColumn: PropTypes.func,
  customColumnsElement: PropTypes.node,
  onSearch: PropTypes.func.isRequired,
  uniqueKey: PropTypes.string,
  customData: PropTypes.object,
  totalLength: PropTypes.number,
  sortUI: PropTypes.object,
  customSortColumn: PropTypes.func,
  customChangePage: PropTypes.func,
};

export default TotalTable;
