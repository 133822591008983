import { isEven } from 'utils/number';

const oddRowStyle = {
  backgroundColor: '#FFFFFF',
};

const evenRowStyle = {
  backgroundColor: '#F7F8FA',
};

const commonRowStyle = {
  borderBottom: '1pt solid #EEEFF3',
  padding: '5 8',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  minHeight: 20,
  color: '#26294B',
  fontSize: 8,
};

export const getRowStyle = rowIndex => ([
  commonRowStyle,
  (isEven(rowIndex + 1) ? evenRowStyle : oddRowStyle),
]);

export const labelStyle = {
  fontWeight: 300,
  minWidth: 80,
  marginRight: 20,
};

export const valueStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
};
