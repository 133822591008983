import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text, View, Image } from '@react-pdf/renderer';
import isEmpty from 'lodash/isEmpty';

import { getCorsImageUrl } from 'utils/constants/export';
import { getUrlWithBase } from 'utils/url';
import { EMPTY_VALUE } from 'utils/constants';
import { reactPdfStyleShape } from 'utils/shapes';

import PartyIcon from '../PartyIcon';
import styles from './styles';

function LegislatorName({
  hideAvatar,
  name,
  partyShort,
  partySlug,
  avatarUrl,
  url,
  containerStyle,
  partyIconStyle,
  nameStyle,
}) {
  const textStyles = { ...styles.text, ...nameStyle };

  if (isEmpty(name)) {
    return <Text style={textStyles}>{EMPTY_VALUE}</Text>;
  }

  const withAvatar = !!avatarUrl && !hideAvatar;
  const linkStyles = { ...styles.link, ...nameStyle };
  const containerStyles = { ...styles.container, ...containerStyle };

  function renderName() {
    if (isEmpty(url)) {
      return <Text style={textStyles}>{name || EMPTY_VALUE}</Text>;
    }

    return <Link style={linkStyles} src={getUrlWithBase(url)}>{name}</Link>;
  }

  return (
    <View style={containerStyles}>
      {withAvatar && <Image style={styles.image} src={getCorsImageUrl(avatarUrl)} />}
      {renderName()}
      <PartyIcon
        style={partyIconStyle}
        partyShort={partyShort}
        partySlug={partySlug}
      />
    </View>
  );
}

LegislatorName.defaultProps = {
  hideAvatar: false,
  nameStyle: {},
  containerStyle: {},
  partyIconStyle: {},
};

LegislatorName.propTypes = {
  name: PropTypes.string,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  url: PropTypes.string,
  avatarUrl: PropTypes.string,
  containerStyle: reactPdfStyleShape,
  partyIconStyle: reactPdfStyleShape,
  hideAvatar: PropTypes.bool,
  nameStyle: reactPdfStyleShape,
};

export default LegislatorName;
