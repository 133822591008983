import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { EMPTY_VALUE } from 'utils/constants';
import Badge from 'components/core/Badge';

import styles from './styles.module.scss';

function ListItem({
  label,
  content,
  value,
  fullWidth,
  hideIfEmpty,
  withHiddenItems,
  listItemClassName,
  listContentClassName,
  listLabelClassName,
  badgeClassName,
  isSimpleLabel,
}) {
  if (hideIfEmpty && isEmpty(value)) {
    return null;
  }

  const labelNode = isSimpleLabel
    ? label
    : <Badge className={classNames(styles.badge, badgeClassName)}>{label}</Badge>;

  return (
    <div
      className={
        classNames(
          styles.listItem,
          fullWidth && styles.fullWidth,
          withHiddenItems && styles.withHiddenItems,
          listItemClassName && listItemClassName,
        )
      }
    >
      <div className={classNames(listLabelClassName, styles.label)}>
        {labelNode}
      </div>

      <div className={classNames(styles.content, listContentClassName)}>
        {isEmpty(content) ? EMPTY_VALUE : content}
      </div>
    </div>
  );
}

ListItem.defaultProps = {
  hideIfEmpty: false,
  withHiddenItems: false,
  fullWidth: false,
  isSimpleLabel: false,
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  fullWidth: PropTypes.bool,
  hideIfEmpty: PropTypes.bool,
  withHiddenItems: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  listItemClassName: PropTypes.string,
  listContentClassName: PropTypes.string,
  listLabelClassName: PropTypes.string,
  badgeClassName: PropTypes.string,
  isSimpleLabel: PropTypes.bool,
};

export default ListItem;
