export const GTE = 'gte';
export const BETWEEN = 'between';
export const LTE = 'lte';

export const OPTIONS = [
  {
    value: GTE,
    label: 'Greater than',
  },
  {
    value: BETWEEN,
    label: 'Between',
  },
  {
    value: LTE,
    label: 'Less than',
  },
];
