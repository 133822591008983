import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { join, filter } from 'lodash';

import CardGroup from 'components/core/CardGroup';
import withCalendar from 'components/core/HearingCalendars/utils/withCalendar';
import { eventShape } from 'components/core/HearingCalendars/utils/shapes';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import {
  trackHearingsCommitteeVisited,
  trackHearingVisited,
} from 'utils/tracking/intel';

import TooltipContent from './TooltipContent';
import BadgeInfo from './BadgeInfo';
import Bills from './Bills';
import styles from '../styles.module.scss';

function Event({
  event,
  checkedEvents,
  onCheckSelectedEvent,
  toggleExportModal,
  toggleShareModal,
}) {
  const {
    id,
    committeeName,
    committeeUrl,
    committeeType,
    scheduledDate,
    state,
    chamber,
    bills,
    title,
    location,
    url,
  } = event;

  const { isMobile } = useMediaQuery();
  const isPastDate = moment(scheduledDate).isBefore();
  const eventClassNames = classNames('hearing-event', styles.dayEvent, isPastDate && styles.expired);
  const stateAndChamber = join(filter([state, chamber], Boolean), ' - ');

  function isEventChecked() {
    return checkedEvents.map(checkedEvent => checkedEvent.id).includes(event.id);
  }

  function toggleCheckbox(e) {
    onCheckSelectedEvent(event, e.target.checked);
  }

  const badgeInfo = (
    <BadgeInfo
      committeeType={committeeType}
      scheduledDate={scheduledDate}
    />
  );

  const tooltipContentProps = {
    event,
    toggleExportModal,
    toggleShareModal,
  };

  const renderCommmitteeName = () => {
    if (committeeUrl) {
      return (
        <a href={committeeUrl} onClick={trackHearingsCommitteeVisited}>{committeeName}</a>
      );
    }

    return committeeName;
  };

  return (
    <CardGroup
      name={id}
      isChecked={isEventChecked()}
      toggleCheckbox={toggleCheckbox}
      className={eventClassNames}
      tooltipContent={<TooltipContent {...tooltipContentProps} />}
      headerContent={isMobile && badgeInfo}
    >
      {!isMobile && badgeInfo}

      <div className={styles.details}>
        {title && (
          <p className="subject">
            <span>Title:</span>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackHearingVisited({ menu: 'list view' })}
            >
              {title}
              {' '}
              <i className="far fa-external-link" />
            </a>
          </p>
        )}
        {location && <p className="location"><span>Location:</span>{location}</p>}
        {committeeName && (
          <p className="committee">
            <span>Committee:</span>
            {renderCommmitteeName()}
          </p>
        )}
        {stateAndChamber && (
          <p>
            <span>State/Chamber:</span>
            <a href={`/state/states/${state}`}>{stateAndChamber}</a>
          </p>
        )}
        <Bills bills={bills} />
      </div>
    </CardGroup>
  );
}

Event.propTypes = {
  event: eventShape.isRequired,
  checkedEvents: PropTypes.arrayOf(eventShape).isRequired,
  onCheckSelectedEvent: PropTypes.func.isRequired,
  toggleExportModal: PropTypes.func.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
};

export default withCalendar(Event);
