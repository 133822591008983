import isEmpty from 'lodash/isEmpty';

import { AUTOCOMPLETE_SLUG, CHECKBOX_TYPE_SLUG, RADIO_TYPE_SLUG } from './constants';

export function isEmptyFieldType(fieldType) {
  return isEmpty(fieldType) || isEmpty(fieldType.value);
}

export function hasOptions(fieldTypeSlug) {
  return [CHECKBOX_TYPE_SLUG, RADIO_TYPE_SLUG, AUTOCOMPLETE_SLUG].includes(fieldTypeSlug);
}
