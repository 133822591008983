import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import TrackButton from 'components/core/TrackButton';
import ButtonTagManager from 'components/core/ButtonTagManager';
import ButtonShare from 'components/core/ButtonShare';
import { Column } from 'components/core/Table';
import { getMyFeed } from 'components/Dashboard/utils';

import { itemShape } from '../utils/shapes';
import { getIsTracking, getSendWeekAheadEmail } from './utils';
import styles from './styles.module.scss';

function ActionsItem({
  item,
  isMobile,
  isColumn,
  keyName,
  itemNameBy,
  onSaveTags,
  shareItemUrl,
  updateDataItem,
  defaultSubscription,
  shouldDisplayTrackIcon,
  isLoadingSubscriptions,
  customUpdateParams,
  updateCommitteeSubscriptions,
}) {
  const {
    id,
    url,
    billUrl,
    documentType,
    trackingType,
    title,
    itemType,
    tags = [],
    [itemNameBy]: name,
    trackings,
    withTrack,
    isCommittee,
    listSubscriptions,
    withWeekAheadEmail,
  } = item || {};

  const isTracking = getIsTracking(trackings);
  const sendWeekAheadEmail = getSendWeekAheadEmail(trackings);
  // TODO: Fix federal committees subscription
  const subscriptionInfo = { sendWeekAheadEmail, withWeekAheadEmail };

  const updateParams = {
    ...customUpdateParams,
    modelId: id,
    id,
  };

  function handleSubscriptionUpdate({
    recordId,
    listSubscriptions: newListSubscriptions,
    trackings: newTrackings,
  }) {
    const data = isCommittee
      ? { recordId, listSubscriptions: newListSubscriptions, trackings: newTrackings }
      : { recordId, trackings: newTrackings };

    return updateDataItem && updateDataItem(data);
  }

  function handleShareSuccess(recordId) {
    return updateDataItem && updateDataItem({
      recordId,
      sharings: [getMyFeed()],
    });
  }

  const renderActions = () => {
    if (!id) {
      return null;
    }

    return (
      <>
        {withTrack && (
          <TrackButton
            isCommittee={isCommittee}
            subscriptionInfo={subscriptionInfo}
            defaultSubscription={defaultSubscription}
            updateDataItem={handleSubscriptionUpdate}
            isMobile={isMobile}
            isTracking={isTracking || !isEmpty(listSubscriptions)}
            isLoadingTracking={isLoadingSubscriptions}
            recordId={id}
            recordType={trackingType || documentType}
            itemType={itemType}
            itemTitle={title || name}
            className={classNames(styles.button, styles.withRightMargin)}
            shouldDisplayIcon={shouldDisplayTrackIcon}
            placeholderClassName={styles.buttonPlaceholder}
            listSubscriptions={listSubscriptions}
            updateParams={updateParams}
            trackings={trackings}
            updateCommitteeSubscriptions={updateCommitteeSubscriptions}
            withButtonContent
          />
        )}

        <ButtonShare
          size="small"
          theme="secondary"
          tooltipPlacement="bottom-end"
          recordId={id}
          recordType={documentType}
          itemType={itemType}
          itemName={title || name}
          itemUrl={shareItemUrl && `${shareItemUrl}${id}` || url || billUrl}
          className={classNames(styles.button, styles.withRightMargin, !isColumn && styles.notColumn)}
          onSuccess={handleShareSuccess}
        />

        <ButtonTagManager
          autoload
          size="small"
          theme="secondary"
          tags={tags}
          recordId={id}
          recordType={documentType}
          itemType={itemType}
          buttonClassName={classNames(styles.button, !isColumn && styles.notColumn)}
          onSaveTags={onSaveTags}
          updateDataItem={updateDataItem}
        />
      </>
    );
  };

  if (!isColumn) {
    return renderActions();
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderActions}</Column>
  );
}

ActionsItem.defaultProps = {
  isColumn: true,
  shouldDisplayTrackIcon: true,
  keyName: 'actions',
  customUpdateParams: {},
};

ActionsItem.propTypes = {
  keyName: PropTypes.string,
  item: itemShape.isRequired,
  itemNameBy: PropTypes.string,
  isMobile: PropTypes.bool,
  isColumn: PropTypes.bool,
  isLoadingSubscriptions: PropTypes.bool,
  shouldDisplayTrackIcon: PropTypes.bool,
  shareItemUrl: PropTypes.string,
  defaultSubscription: PropTypes.string,
  onSaveTags: PropTypes.func,
  updateDataItem: PropTypes.func,
  customUpdateParams: PropTypes.shape({
    priority: PropTypes.string,
    isSaved: PropTypes.bool,
  }),
  updateCommitteeSubscriptions: PropTypes.func,
};

export default memo(ActionsItem);
