export function buildTagsPayload(documentType, documentId) {
  return [{
    documentType,
    recordId: documentId,
  }];
}

export function transformTagsForUI(response) {
  const { tags } = response[0] || {};

  return tags || [];
}
