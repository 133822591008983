import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@tippyjs/react';
import { map, size, slice, isEmpty } from 'lodash';

import Avatar from 'components/core/Avatar';

import { avatarShape } from './shapes';
import styles from './styles.module.scss';

export const AVATAR_STACK_LIMIT = 5;
export const MOBILE_AVATAR_STACK_LIMIT = 2;

function AvatarGroup({ onClick, avatars, stackLimit, mobileStackLimit, isMobile, id }) {
  if (isEmpty(avatars)) {
    return null;
  }

  const avatarsLimit = isMobile ? mobileStackLimit : stackLimit;
  const avatarsSize = size(avatars);
  const overLimitSize = avatarsSize - avatarsLimit;
  const isOverLimit = overLimitSize > 0;
  const shownAvatars = slice(avatars, 0, avatarsLimit);
  const hiddenAvatars = slice(avatars, avatarsLimit);

  const getRow = ({ name, email }, isMultiple = false) => (
    <span className={classNames(styles.avatarTooltip, isMultiple && styles.isMultiple)}>
      {name} {email && `(${email})`}
    </span>
  );

  const renderAvatar = (avatar, index) => {
    const { name, photoUrl } = avatar;

    return (
      <Tooltip key={index} content={getRow(avatar)} arrow>
        <span className={styles.tooltipSpan}>
          <Avatar
            name={name}
            photoUrl={photoUrl}
            className={styles.avatar}
            onClick={onClick}
            isClickable
          />
        </span>
      </Tooltip>
    );
  };

  const tooltipOverLimitNode = () => (
    <>
      <p className={styles.header}>Additional Subscribers</p>
      {map(hiddenAvatars, (avatar, index) => (
        <Fragment key={index}>{getRow(avatar, true)}</Fragment>
      ))}
    </>
  );

  return (
    <div className={styles.avatarGroup} id={id}>
      <div className={styles.avatars}>
        {map(shownAvatars, renderAvatar)}
      </div>
      {isOverLimit && (
        <Tooltip content={tooltipOverLimitNode()} arrow>
          <span className={styles.badge}>
            +{overLimitSize}
          </span>
        </Tooltip>
      )}
    </div>
  );
}

AvatarGroup.defaultProps = {
  stackLimit: AVATAR_STACK_LIMIT,
  mobileStackLimit: MOBILE_AVATAR_STACK_LIMIT,
};

AvatarGroup.propTypes = {
  avatars: PropTypes.arrayOf(avatarShape),
  onClick: PropTypes.func,
  stackLimit: PropTypes.number,
  mobileStackLimit: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default AvatarGroup;
