import React from 'react';
import PropTypes from 'prop-types';

import useModal from 'components/core/Modal/useModal';
import CoreAvatar from 'components/core/Avatar';
import useAvatar from 'components/core/Profile/utils/useAvatar';

import ImageModal from './ImageModal';
import styles from './styles.module.scss';

export default function Avatar({ isLoadingProfile, avatarUrl, name, partyIcon, onError }) {
  const { isOpenModal, toggleModal } = useModal();
  const { hasAvatar, onAvatarError } = useAvatar();

  function handleError() {
    onAvatarError();
    onError && onError();
  }

  const avatarUrlProps = avatarUrl && {
    photoUrl: avatarUrl,
    onClick: toggleModal,
    onError: handleError,
    isClickable: hasAvatar,
  };

  return (
    <div className={styles.avatarContainer}>
      <ImageModal
        isOpen={isOpenModal}
        title={name}
        image={avatarUrl}
        partyIcon={partyIcon}
        toggleOpen={toggleModal}
        isLoading={isLoadingProfile}
      />

      <CoreAvatar
        {...avatarUrlProps}
        name={name}
        className={styles.avatar}
      />
    </div>
  );
}

Avatar.propTypes = {
  isLoadingProfile: PropTypes.bool,
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  partyIcon: PropTypes.node,
  onError: PropTypes.func,
};
