import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TabsPlaceholder } from 'components/core/Placeholder';

import MenuItem from './components/MenuItem';
import ActiveItem from './components/ActiveItem';
import useMobileTabs from './useMobileTabs';
import styles from './styles.module.scss';

function MobileTabs({
  items,
  isLoading,
  itemsNumberPlaceholder,
  className,
  ...restProps
}) {
  const { isActiveItem, activeItem, onClickItem, onClickMainMenu } = useMobileTabs(restProps);
  const containerClassNames = classNames(
    styles.titles,
    isActiveItem && styles.activeContainer,
    className,
  );

  function renderContent() {
    if (isLoading) {
      return (
        <TabsPlaceholder
          itemsNumber={itemsNumberPlaceholder}
          isDesktop={false}
        />
      );
    }

    if (isActiveItem) {
      return <ActiveItem activeItem={activeItem} onClick={onClickMainMenu} />;
    }

    return items.map((tab, index) => (
      <MenuItem
        key={`${tab.id}-${index}`}
        tab={tab}
        onClick={onClickItem}
      />
    ));
  }

  return (
    <div className={containerClassNames}>
      {renderContent()}
    </div>
  );
}

MobileTabs.propTypes = {
  items: PropTypes.array.isRequired,
  activeTab: PropTypes.object,
  setActiveTab: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemsNumberPlaceholder: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default MobileTabs;
