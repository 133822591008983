import GlobalNotifier from 'components/GlobalNotifier';

const { addNotification } = GlobalNotifier;

const success = message => addNotification({ message, level: 'success' });
const error = message => addNotification({ message, level: 'error' });

export default class Notifications {
  static customFieldSaved = () => success('Custom Fields successfully saved.');

  static customFieldUpdated = () => success('Custom Fields successfully updated.');

  static customFieldFailed = () => error('An error occurred. Please try again.');
}
