import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/core/Loader';
import ExportModal, {
  Sections,
  FileFormat,
  Preview,
  Customization,
} from 'components/core/ExportDocument/components/ExportModal';
import useExportPDFDocument from 'components/core/ExportDocument/utils/useExportPDFDocument';
import { LOGO_CUSTOMIZATION_ID } from 'utils/constants/export';

import { sectionShape } from '../../utils/shapes';
import ExportPreview from '../ExportPreview';
import {
  EXPORT_FILE_ITEMS,
  EXPORT_CUSTOMIZATION_ITEMS,
} from './constants';
import styles from './styles.module.scss';

function Modal({
  isOpenModal,
  toggleModal,
  documentComponent,
  fileName,
  fetchComponentData,
  defaultSectionValues,
  defaultCustomizationValues,
  sections,
  previewDocumentTitle,
  onFileExport,
  trackingActions,
}) {
  // TODO: add success and failure notifications to replace the legacy ones
  const { isDocumentLoading, onExport } = useExportPDFDocument({
    documentComponent,
    onFileExported: toggleModal,
    fileName,
    fetchComponentData,
    trackingActions,
  });

  function handleExport(...args) {
    onFileExport && onFileExport(...args);
    return onExport(...args);
  }

  const renderModalContent = (exportProps) => {
    const {
      getFileFormatProps,
      getCustomizationProps,
      getSectionsProps,
      companyHasLogo,
      logoUrl,
    } = exportProps;
    const { values } = getCustomizationProps();
    const previewCompanyLogo = values.includes(LOGO_CUSTOMIZATION_ID);
    const previewLogoUrl = previewCompanyLogo ? logoUrl : undefined;

    const previewComponent = (
      <ExportPreview documentTitle={previewDocumentTitle} logoUrl={previewLogoUrl} />
    );

    function getCustomization() {
      if (!companyHasLogo) {
        return null;
      }

      return (
        <div className={styles.flexItem}>
          <div className={styles.section}>
            <Customization
              {...getCustomizationProps()}
              exportCustomizationItems={EXPORT_CUSTOMIZATION_ITEMS}
            />
          </div>
          <div className={styles.section}>
            <Preview previewComponent={previewComponent} />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.flexItem}>
          <div className={styles.section}>
            <Sections
              {...getSectionsProps()}
              exportTabItems={sections}
            />
          </div>
          <div className={styles.section}>
            <FileFormat
              {...getFileFormatProps()}
              exportFileItems={EXPORT_FILE_ITEMS}
            />
          </div>
        </div>
        {getCustomization()}
      </>
    );
  };

  const placeholder = (
    <div className={styles.loaderContainer}>
      <Loader message="Loading ..." />
    </div>
  );

  return (
    <ExportModal
      isLoading={isDocumentLoading}
      isOpenModal={isOpenModal}
      toggleModal={toggleModal}
      onExport={handleExport}
      defaultSectionValues={defaultSectionValues}
      defaultCustomizationValues={defaultCustomizationValues}
      displayCompanyLogo
    >
      {({ isLogoLoading, ...props }) => (
        isLogoLoading
          ? placeholder
          : renderModalContent(props)
      )}
    </ExportModal>
  );
}

Modal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  fetchComponentData: PropTypes.func,
  documentComponent: PropTypes.node.isRequired,
  defaultSectionValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  sections: PropTypes.arrayOf(sectionShape).isRequired,
  defaultCustomizationValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  previewDocumentTitle: PropTypes.string.isRequired,
  onFileExport: PropTypes.func,
  trackingActions: PropTypes.shape({
    trackOpenExportModal: PropTypes.func,
    trackGeneratedExport: PropTypes.func,
  }),
};

export default Modal;
