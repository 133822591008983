import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { modalThemes } from './helpers';
import styles from './styles.module.scss';

function Modal({
  title,
  headerTitle,
  buttons,
  children,
  bodyClassName,
  headerClassName,
  footerClassName,
  className,
  overlayClassName,
  isOpen,
  toggleOpen,
  theme,
  onRequestClose,
  onCrossBtnClick,
  shouldReturnFocusAfterClose,
  parentSelector,
  id,
}) {
  if (!isOpen) {
    return null;
  }

  const handleRequestClose = onRequestClose || toggleOpen;
  const handleCrossBtnClick = onCrossBtnClick || toggleOpen;

  const modalTitle = headerTitle || title;

  ReactModal.setAppElement('body');
  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      contentLabel={title}
      className={classNames(modalThemes[theme], className)}
      overlayClassName={classNames(styles.overlay, overlayClassName)}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      parentSelector={parentSelector}
    >
      {modalTitle && (
        <div className={classNames(styles.header, headerClassName)}>
          <h3 className={styles.modalTitle}>{modalTitle}</h3>
          {handleCrossBtnClick && (
            <button type="button" onClick={handleCrossBtnClick} className={styles.closeIcon}>
              <i className={classNames('fal', 'fa-times')} />
            </button>
          )}
        </div>
      )}

      <div className={classNames(styles.body, bodyClassName)}>
        {children}
      </div>

      {buttons && (
        <div className={classNames(styles.footer, footerClassName)}>
          {buttons}
        </div>
      )}
    </ReactModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  headerTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  buttons: PropTypes.node,
  children: PropTypes.node,
  bodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  theme: PropTypes.oneOf(
    Object.keys(modalThemes)
  ),
  onRequestClose: PropTypes.func,
  onCrossBtnClick: PropTypes.func,
  shouldReturnFocusAfterClose: PropTypes.bool,
  parentSelector: PropTypes.func,
  id: PropTypes.string,
};

Modal.defaultProps = {
  children: (<p>Body is empty.</p>),
  theme: 'default',
  shouldReturnFocusAfterClose: true,
  id: 'modal',
};

export default Modal;
