import React, { createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isBlankspace } from '../../utils';
import styles from '../../styles.module.scss';

export default function LetterInput({
  index,
  letter,
  requiredLetters,
  typedLetters,
  fieldRefs,
  handleFocus,
  handleChange,
  handleKeyDown,
}) {
  const typedLetter = typedLetters[index];
  const inputValue = typedLetter || '';
  const isValid = typedLetter && letter === typedLetter;
  const isInvalid = (typedLetter === null) || (typedLetter && letter !== typedLetter);
  const isDisabled = !letter.trim();
  const inputClassNames = classNames(styles.input, {
    [styles.valid]: isValid,
    [styles.invalid]: isInvalid,
    [styles.borderLeft]: isBlankspace(index - 1, requiredLetters),
    [styles.noBorderRight]: isBlankspace(index, requiredLetters),
  });

  fieldRefs[index] = createRef();

  function handleInputChange(event) {
    return handleChange(event, index);
  }

  function handleInputKeyDown(event) {
    return handleKeyDown(event, index);
  }

  return (
    <input
      name={`text-confirm-input-${index}`}
      type="text"
      maxLength="1"
      value={inputValue}
      ref={fieldRefs[index]}
      className={inputClassNames}
      placeholder={letter}
      autoFocus={index === 0}
      tabIndex={index + 1}
      disabled={isDisabled}
      onFocus={handleFocus}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
    />
  );
}

LetterInput.propTypes = {
  index: PropTypes.number.isRequired,
  letter: PropTypes.string.isRequired,
  requiredLetters: PropTypes.array.isRequired,
  typedLetters: PropTypes.array.isRequired,
  fieldRefs: PropTypes.array.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
};
