import React from 'react';
import PropTypes from 'prop-types';

import { MapContext } from './utils/withMap';
import MapContainer from './components/Container';
import SimpleStatesMap from './components/SimpleStatesMap';

function Map({ forwardRef, ...restProps }) {
  const mapContext = {
    forwardRef,
    ...restProps,
  };

  return (
    <MapContext.Provider value={mapContext}>
      <MapContainer forwardRef={forwardRef} />
    </MapContext.Provider>
  );
}

Map.defaultProps = {
  withFilters: true,
  withNavigation: true,
  withHeatmap: false,
  withGeoCoder: false,
};

Map.propTypes = {
  withFilters: PropTypes.bool,
  withNavigation: PropTypes.bool,
  withHeatmap: PropTypes.bool,
  withGeoCoder: PropTypes.bool,
  height: PropTypes.string.isRequired,
  defaultFilters: PropTypes.object,
  forwardRef: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Map;
export { SimpleStatesMap };
