import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';

import MapContent from './components/MapContent';

function DistrictMap({
  isLoading,
  mapLocation,
  isMobile,
  title,
  description,
  emptyDescription,
}) {
  const titleInfo = { title, description, emptyDescription };

  if (isLoading) {
    return <LoadingPlaceholder {...titleInfo} />;
  }

  return (
    <DataCard
      {...titleInfo}
      showPlaceholder={isEmpty(mapLocation)}
    >
      <MapContent mapLocation={mapLocation} isMobile={isMobile} />
    </DataCard>
  );
}

DistrictMap.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  mapLocation: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  emptyDescription: PropTypes.string,
};

export default memo(DistrictMap);
