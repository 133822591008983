import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Actions({
  linkClassName,
  buttonClassName,
  tooltipPlacement,
  buttonWrapperClassName,
  tooltipContentClassName,
  options,
  label,
  large,
  ...restProps
}) {
  const tooltipContent = (
    <div className={classNames(styles.tooltipContent, tooltipContentClassName)}>
      {options.map(({ id, component }) => cloneElement(component, { key: id }))}
    </div>
  );

  const buttonClassNames = classNames(
    styles.button,
    buttonClassName,
    large && styles.large,
  );

  return (
    <Tooltip
      arrow
      theme="light"
      trigger="click"
      placement={tooltipPlacement}
      content={tooltipContent}
      appendTo={document.body}
      zIndex={2}
    >
      <span className={buttonWrapperClassName}>
        <Button {...restProps} className={buttonClassNames}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
}

Actions.defaultProps = {
  label: (
    <>
      <span className={styles.label}>Actions</span>
      <i className={styles.triangle} />
    </>
  ),
  tooltipPlacement: 'bottom-end',
  options: [],
  large: false,
};

Actions.propTypes = {
  buttonClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  buttonWrapperClassName: PropTypes.string,
  tooltipContentClassName: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      component: PropTypes.node,
    })
  ),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  large: PropTypes.bool,
};

export default Actions;
