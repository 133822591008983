import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View } from '@react-pdf/renderer';

import Header from './components/Header';
import Footer from './components/Footer';
import styles from './styles';

function BillCard({
  bill,
  headerContent,
  footerContent,
  children,
}) {
  const { billId } = bill || {};
  const bodyStyles = {
    ...styles.billBodySection,
    ...(!headerContent && styles.withoutHeader),
    ...(!footerContent && styles.withoutFooter),
  };

  if (isEmpty(billId)) {
    return null;
  }

  return (
    <View className="bill-card" style={styles.billCard}>
      <View break>
        {headerContent && <Header>{headerContent}</Header>}
      </View>

      <View style={bodyStyles}>
        {children}
      </View>

      {footerContent && <Footer>{footerContent}</Footer>}
    </View>
  );
}

BillCard.propTypes = {
  bill: PropTypes.object,
  headerContent: PropTypes.node,
  footerContent: PropTypes.node,
  children: PropTypes.node,
};

export default BillCard;
