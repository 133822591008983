import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withMap from '../../utils/withMap';
import Filters from '../Filters';
import MapPlaceholder from '../Placeholder';
import styles from './styles.module.scss';

function MapContainer({
  isLoadingMap,
  height,
  withFilters,
  withHeatmap,
  forwardRef,
  className,
  style,
}) {
  return (
    <div className={classNames(styles.mapContainer, className, withHeatmap && styles.withHeatmap)}>
      {isLoadingMap && <MapPlaceholder />}
      <div ref={forwardRef} style={{ ...style, height }} />
      {withFilters && <Filters />}
    </div>
  );
}

MapContainer.propTypes = {
  isLoadingMap: PropTypes.bool.isRequired,
  withFilters: PropTypes.bool.isRequired,
  withHeatmap: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  forwardRef: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default withMap(MapContainer);
