import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

function useMobileTabs({ activeTab, setActiveTab }) {
  const [activeItem, setActiveItem] = useState(activeTab);
  const isActiveItem = !isEmpty(activeItem);

  useEffect(() => {
    !isEmpty(activeTab) && setActiveItem(activeTab);
  }, [activeTab]);

  function handleClickMobileItem(tab) {
    setActiveItem(tab);
    setActiveTab(tab);
  }

  function handleClickMainMenu() {
    setActiveItem();
  }

  return {
    isActiveItem,
    activeItem,
    onClickItem: handleClickMobileItem,
    onClickMainMenu: handleClickMainMenu,
  };
}

export default useMobileTabs;
