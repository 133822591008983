import { fetchDocuments } from 'redux/events/actions';
import { fetchTagsByRecordIdsNoDispatch } from 'redux/tags/actions';
import { transformDocumentsForProfile } from 'redux/events/utils/transformers';

import { transformTagsForUI, buildTagsPayload } from './utils';

export const fetchTags = (documentType, documentId) => {
  const tagsPayload = buildTagsPayload(documentType, documentId);

  return fetchTagsByRecordIdsNoDispatch(tagsPayload)
    .then(transformTagsForUI);
};

export const fetchIsShared = (documentType, documentId) => (
  fetchDocuments(documentType, [documentId])
    .then(response => transformDocumentsForProfile(response, documentId))
);
