import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CommentInput from 'components/core/Comments/components/CommentInput';
import { TextWithMentions } from 'components/core/TextareaWithMentions';

import Options from './components/Options';
import { transformTimeForUI } from './utils';
import styles from './styles.module.scss';

function CommentBox({
  isEditing,
  isCurrentUser,
  isCommentHighlighted,
  userName,
  content,
  commentDate,
  onDelete,
  onChange,
  onEnableEdit,
  isDeleting,
  id,
  onMentionsChange,
  onSaveEdit,
  isReply,
}) {
  const elementId = `comment-${id}`;
  const displayDateTime = transformTimeForUI(commentDate);

  const renderContent = () => (
    isEditing ? (
      <CommentInput
        onMentionsChange={onMentionsChange}
        isEditable={isEditing}
        value={content}
        onChange={onChange}
        theme="justContent"
        parentId={elementId}
        onCtrlEnterDown={onSaveEdit}
        placeholder="Edit your comment..."
      />
    ) : (
      <TextWithMentions>{content}</TextWithMentions>
    )
  );

  return (
    <div
      className={classNames(
        styles.commentBox,
        isCommentHighlighted && styles.highlightedCommentId,
      )}
      id={elementId}
    >
      <header className={styles.header}>
        <div className={styles.userName}>{userName}</div>
        <div className={styles.rightHeader}>
          <div className={styles.time}>{displayDateTime}</div>
          {isCurrentUser && (
            <Options
              onEnableEdit={onEnableEdit}
              onDelete={onDelete}
              isDeleting={isDeleting}
              showRepliesMessage={!isReply}
            />
          )}
        </div>
      </header>
      {renderContent()}
    </div>
  );
}

CommentBox.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  isCommentHighlighted: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  commentDate: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnableEdit: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  onMentionsChange: PropTypes.func.isRequired,
  onSaveEdit: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
};

export default CommentBox;
