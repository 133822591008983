import isEmpty from 'lodash/isEmpty';

export function filterOptions(options, query) {
  const lowercaseQuery = query.toLowerCase();

  return options.filter(option =>
    option.label.toLowerCase().includes(lowercaseQuery));
}

export function getGroupedOptions({
  query,
  isMobile,
  options,
  numColumns = 1,
  showSelectAll = false,
}) {
  if (isMobile) {
    return [options];
  }

  let displayedOptions = options;
  const selectAllOption = {
    label: 'Select/deselect all',
    keyName: 'selectAll',
    isSelectAll: true,
  };

  /* display select all when user is not searching for a field */
  if (showSelectAll && isEmpty(query)) {
    displayedOptions = [selectAllOption, ...displayedOptions];
  }

  const groupedOptions = Array(numColumns).fill([]);
  displayedOptions.forEach((option, index) => {
    const columnIndex = index % numColumns;
    groupedOptions[columnIndex] = [...groupedOptions[columnIndex], option];
  });

  return groupedOptions;
}
