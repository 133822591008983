import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import withSearch from 'components/core/AdvancedSearch/utils/withSearch';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import Input from 'components/core/Input';
import Label from 'components/core/Label';

import useSearchItem from '../utils/useSearchItem';
import ItemInfo from './ItemInfo';
import ItemActions from './ItemActions';
import SearchesPaginator from './SearchesPaginator';
import Placeholder from './Placeholder';
import styles from '../styles.module.scss';

function ListItems({
  isRecent,
  isSaved,
  activeBtn,
  isLoadingSearches,
  items,
  onClickItem,
  onSaveItem,
  onClearItem,
  onRemoveItem,
  isDirty,
  allowSubmit,
  isSaveNameEnabled,
  onSearch,
  isMobile,
  isClickedItem,
}) {
  const {
    selectedItem,
    name,
    onClick,
    onClear,
    onTriggerSave,
    onSaveWithName,
    onRemove,
    onChangeName,
    isOpenModal,
    toggleModal,
    nameErrorMessage,
  } = useSearchItem({
    activeBtn,
    onClickItem,
    onRemoveItem,
    onSaveItem,
    onClearItem,
    isDirty,
    allowSubmit,
    isSaveNameEnabled,
    onSearch,
    isMobile,
    isClickedItem,
  });

  if (isLoadingSearches) {
    return <Placeholder />;
  }

  if (isEmpty(items)) {
    const emptySavedMessage = (
      <span>
        You haven’t saved any searches yet. <br />
        Press ‘Save’ on a recently searched item to store your search.
      </span>
    );

    return (
      <p className={styles.emptyMessage}>
        {isRecent
          ? 'No recent searches to show.'
          : emptySavedMessage
        }
      </p>
    );
  }

  const saveSearchActions = (
    <Fragment>
      <Button
        type="small"
        theme="light"
        onClick={toggleModal}
      >
        Cancel
      </Button>

      <Button
        type="small"
        onClick={onSaveWithName}
      >
        Save
      </Button>
    </Fragment>
  );

  const testClassName = isRecent ? 'search__list-recent' : 'search__list-saved';

  return (
    <div className={classNames(testClassName, styles.listContainer)}>
      <ul className={styles.list}>
        {items.map((item, index) => {
          const isSelected = selectedItem === index;
          const listClass = classNames(
            styles.listItem,
            styles.btnWrap,
            isSelected && styles.selectedItem,
          );

          if (isEmpty(item.filters)) {
            return null;
          }

          return (
            <li
              key={`recent-list-${index}`}
              onClick={onClick(item, index)}
              role="button"
              className={listClass}
            >
              <ItemInfo
                title={item.title}
                filters={item.filters}
              />

              <ItemActions
                item={item}
                isSelected={isSelected}
                isRecent={isRecent}
                onClear={onClear}
                onSave={onTriggerSave}
                onRemove={onRemove}
              />
            </li>
          );
        })}

        <li>
          <SearchesPaginator isSaved={isSaved} />
        </li>
      </ul>

      {isSaveNameEnabled && (
        <Modal
          title="Save search"
          desc
          isOpen={isOpenModal}
          toggleOpen={toggleModal}
          buttons={saveSearchActions}
        >
          <Label htmlFor="saveSearchName">Name</Label>
          <Input
            className={styles.nameInput}
            id="saveSearchName"
            name="name"
            value={name}
            placeholder="Give your saved search a name (e.g., super search)."
            onChange={onChangeName}
            errorMessage={nameErrorMessage}
          />
        </Modal>
      )}
    </div>
  );
}

ListItems.defaultProps = {
  isSaveNameEnabled: false,
};

ListItems.propTypes = {
  isSaveNameEnabled: PropTypes.bool,
  isRecent: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  allowSubmit: PropTypes.bool,
  activeBtn: PropTypes.string,
  isLoadingSearches: PropTypes.bool.isRequired,
  onClickItem: PropTypes.func,
  onSaveItem: PropTypes.func.isRequired,
  onClearItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  items: PropTypes.array,
  onSearch: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isClickedItem: PropTypes.bool.isRequired,
};

export default withSearch(ListItems);
