import React from 'react';
import PropTypes from 'prop-types';

import Container from './components/Container';

function Card({
  id,
  title,
  eventDate,
  interactionType,
  creationDate,
  createdBy,
  user,
  description,
  tags,
  links,
  customFieldsAnswers,
  cardType,
  uploads,
}) {
  return (
    <Container
      id={id}
      title={title}
      creationDate={creationDate}
      eventDate={eventDate}
      interactionType={interactionType}
      createdBy={createdBy}
      user={user}
      description={description}
      tags={tags}
      links={links}
      customFields={customFieldsAnswers}
      cardType={cardType}
      uploads={uploads}
    />
  );
}

Card.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  interactionType: PropTypes.string,
  user: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  customFieldsAnswers: PropTypes.array.isRequired,
  cardType: PropTypes.string.isRequired,
  uploads: PropTypes.array.isRequired,
};

export default Card;
