import React from 'react';

import styles from './styles.module.scss';

function Placeholder() {
  return (
    <div className={styles.button}>
      <span className={styles.text} />
    </div>
  );
}

export default Placeholder;
