import React, { forwardRef, LegacyRef, ChangeEvent } from 'react';
import classNames from 'classnames';

import ErrorMessage from 'components/core/ErrorMessage';

import styles from '../../styles.module.scss';

const inputSizes = {
  default: styles.default,
  small: styles.small,
};

type InputProps = {
  size?: 'default' | 'small',
  className?: string,
  errorMessage?: string,
  isError?: boolean,
  innerRef?: LegacyRef<HTMLInputElement>,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  placeholder?: string,
};

export function InputComponent({
  size = 'default',
  className,
  errorMessage,
  isError = false,
  innerRef,
  ...restProps
}: InputProps) {
  return (
    <>
      <input
        {...restProps}
        ref={innerRef}
        className={classNames(
          styles.input,
          inputSizes[size],
          className,
          isError && styles.invalid,
          errorMessage && styles.invalid,
        )}
      />
      <ErrorMessage message={errorMessage} />
    </>
  );
}

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <InputComponent {...props} innerRef={ref} />
));
