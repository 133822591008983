import React from 'react';
import { map, isEmpty } from 'lodash';

import { personShape } from 'components/core/Profile/utils/shapes';
import { getOrganizationContent } from 'components/core/Profile/components/Cards/Membership/utils';

import TableSection from '../../TableSection';

function Membership({ person }) {
  const { membership } = person || {};

  if (isEmpty(membership)) {
    return null;
  }

  const tables = [
    map(membership, (membershipItem, index) => ({
      label: `Organization ${index + 1}`,
      value: getOrganizationContent(membershipItem),
    })),
  ];

  return (
    <TableSection
      title="Membership"
      tables={tables}
    />
  );
}

Membership.propTypes = {
  person: personShape,
};

export default Membership;
