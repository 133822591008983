import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BackTo } from 'components/core/Link';

import styles from './styles.module.scss';

function BackToHeaderBar({ to, title, contentClassName, containerClassName, ...restProps }) {
  return (
    <section className={classNames(styles.content, contentClassName)}>
      <div className={classNames(styles.container, containerClassName)}>
        <BackTo {...restProps} to={to} title={title} />
      </div>
    </section>
  );
}

BackToHeaderBar.defaultProps = {
  title: 'Back to my feed',
};

BackToHeaderBar.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default BackToHeaderBar;
