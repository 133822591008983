import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Button from 'components/core/Button';
import { formatBytes, getProgress, createFileId } from './utils';
import styles from './styles.module.scss';
import {
  handleUploadClick,
} from './handlers';

function FileUpload({
  alreadyAddedFiles,
  filesUploaded,
  filesAdded,
  addFiles,
  deleteFile,
  emptyState,
  onChange,
  className,
  btnClassName,
  listClassName,
}) {
  const [progress, setProgress] = useState({});
  const [failures, setFailures] = useState({});

  useEffect(() => {
    onChange(filesUploaded);
  }, [filesUploaded]);

  useEffect(() => {
    alreadyAddedFiles && alreadyAddedFiles.length && filesAdded(alreadyAddedFiles);
    return () => {
      emptyState();
    };
  }, []);

  // eslint-disable-next-line
  const renderFileName = ({ file_upload_url: url, name, size }) => {
    if (!isEmpty(url)) {
      return (
        <div>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </div>
      );
    }
    const failedMessage = failures[createFileId({ name, size })] ? '(failed upload) ' : '';
    return <div>{failedMessage}{name}</div>;
  };

  // eslint-disable-next-line
  const renderFileSize = ({ size }) => (<div>&nbsp;({formatBytes(size)})</div>)
  const uploadIcon = (<i className="far fa-paperclip" />);

  const handleDeleteClick = index => () => deleteFile(index);
  const errorClassName = key => failures[key] && styles.withError;

  const btnClassNames = classNames(
    styles.uploadBtn,
    btnClassName
  );
  const listClassNames = classNames(
    styles.uploadedList,
    listClassName
  );

  const renderUploadedList = (filesList) => {
    if (isEmpty(filesList)) {
      return null;
    }

    return (
      <div className={listClassNames}>
        {filesList.map((file, index) => (
          <div key={createFileId(file)}>
            <div
              className={styles.progressBar}
              style={{ width: `${getProgress(progress, file)}%` }}
            />
            <div className={classNames(styles.fileName, errorClassName(createFileId(file)))}>
              {renderFileName(file)}
              {renderFileSize(file)}
            </div>
            <div
              role="button"
              onClick={handleDeleteClick(index)}
            >
              <i className="fal fa-times" />
            </div>
          </div>
        )).reverse()}
      </div>
    );
  };

  return (
    <div className={classNames(styles.container, className)}>
      <Button
        onClick={handleUploadClick(addFiles, setProgress, setFailures)}
        customIcon={uploadIcon}
        theme="link"
        className={btnClassNames}
        iconClassName={styles.uploadIcon}
      >
        Add attachment
      </Button>
      {renderUploadedList(filesUploaded)}
    </div>
  );
}

FileUpload.propTypes = {
  alreadyAddedFiles: PropTypes.array,
  filesUploaded: PropTypes.array.isRequired,
  addFiles: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  emptyState: PropTypes.func.isRequired,
  filesAdded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  listClassName: PropTypes.string,
};

export default FileUpload;
