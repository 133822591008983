import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function ActionCard({
  title,
  subTitle,
  children,
  className,
  actions,
  beforeActions,
}) {
  return (
    <div
      data-spec="action-card"
      className={classNames(styles.card, className)}
    >
      <div className={styles.header}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
      {children && (
        <div className={styles.body}>
          {children}
        </div>
      )}
      {actions && (
        <div className={styles.footer}>
          { beforeActions && beforeActions}
          { actions.map(({ name, onClick, isDisabled }) => (
            <Button
              theme="light"
              onClick={onClick}
              key={name}
              disabled={isDisabled}
            >
              {name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}

ActionCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  beforeActions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ActionCard;
