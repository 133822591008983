import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextPlaceholder } from 'components/core/Placeholder';
import { ReportTitlePreview } from 'components/core/ExportDocument/components/ExportModal/components/Preview';
import styles from './styles.module.scss';

function ExportPreview({ logoUrl, documentTitle }) {
  return (
    <ReportTitlePreview
      logoUrl={logoUrl}
      documentTitle={documentTitle}
    >
      <div className={styles.content}>
        <h3 className={styles.contentTitle}>Overview</h3>
        <hr className={styles.line} />

        <TextPlaceholder className={classNames(styles.shortPlaceholder, styles.placeholder)} />
        <TextPlaceholder className={styles.placeholder} />
      </div>
    </ReportTitlePreview>
  );
}

ExportPreview.propTypes = {
  documentTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

export default ExportPreview;
