import { useEffect } from 'react';

import { toggleActionsOffset } from 'utils/freshchat';

function useActionFooter({ checkedEvents }) {
  const isPresentSelectedItems = checkedEvents.length > 0;
  const onlyOneEventSelected = checkedEvents.length === 1;
  const checkedEventsFirstItem = checkedEvents[0];

  useEffect(() => {
    toggleActionsOffset(isPresentSelectedItems);
  }, [isPresentSelectedItems]);

  return {
    isPresentSelectedItems,
    onlyOneEventSelected,
    checkedEventsFirstItem,
  };
}

export default useActionFooter;
