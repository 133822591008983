import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/core/Input';
import { InputWithCharInfo } from 'components/core/CharInfo';

function TitleInput({ charactersLimit, ...restProps }) {
  if (!charactersLimit) {
    return (
      <Input {...restProps} />
    );
  }

  return (
    <InputWithCharInfo
      {...restProps}
      charInfo={{
        limit: charactersLimit,
      }}
    />
  );
}

TitleInput.propTypes = {
  charactersLimit: PropTypes.number,
};

export default TitleInput;
