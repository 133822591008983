import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Column } from 'components/core/Table';
import { EMPTY_VALUE } from 'utils/constants';
import { TrimTextItem } from 'components/core/Table/components/ColumnItem';

function CustomFieldsItem({ keyName, item, maxTextLength }) {
  const { custom_fields } = item || {};

  const customFields = custom_fields[keyName];
  const isCustomFieldsArray = Array.isArray(customFields);

  function renderCustomFields() {
    if (isEmpty(customFields)) {
      return EMPTY_VALUE;
    }
    const content = isCustomFieldsArray ? customFields.join(', ') : customFields;

    return <TrimTextItem textToTrim={content} maxTextLength={maxTextLength} />;
  }

  return <Column key={keyName} keyName={keyName}>{renderCustomFields}</Column>;
}

CustomFieldsItem.defaultProps = {
  maxTextLength: 65,
};

CustomFieldsItem.propTypes = {
  keyName: PropTypes.string.isRequired,
  item: PropTypes.object,
  maxTextLength: PropTypes.number,
};

export default CustomFieldsItem;
