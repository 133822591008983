import { truncate, split, isEmpty, includes } from 'lodash';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { currentDate } from 'utils/date';
import {
  fetchTimelineCards,
} from 'components/core/Profile/components/Export/components/DocumentLayouts/YourActivity/utils/api';
import { LOGO_CUSTOMIZATION_ID } from 'utils/constants/export';

import { ACTIVITY_SECTION_ID, PROFILE_SECTION_ID } from './constants';

const MAX_FILENAME_TITLE_LENGTH = 25;

export const getExportFileName = (recordType, personName) => {
  const truncatedName = truncate(personName, { length: MAX_FILENAME_TITLE_LENGTH });
  const date = currentDate(GLOBAL_DATE_FORMAT);

  return `${recordType} - ${truncatedName} - ${date}.pdf`;
};

export const getPartyBadge = (party) => {
  if (isEmpty(party)) return {};

  const { code: partyCode, name: partyName } = party;

  return {
    short: partyCode,
    slug: split(partyName, ' ')[0],
  };
};

function getDisplayingProps({ sections, customization }, logoUrl) {
  const displayCustomLogo = includes(customization, LOGO_CUSTOMIZATION_ID);

  return {
    ...(displayCustomLogo && { logoUrl }),
    display: {
      profile: includes(sections, PROFILE_SECTION_ID),
      yourActivity: includes(sections, ACTIVITY_SECTION_ID),
    },
  };
}

export function getFetchingFunction(...args) {
  const displayingProps = getDisplayingProps(...args);
  const { yourActivity } = displayingProps.display;

  return yourActivity
    ? fetchTimelineCards().then(data => ({ ...displayingProps, timelineCards: data }))
    : Promise.resolve(displayingProps);
}
