import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function Error({ reload }) {
  function handleReload() {
    reload && reload();
  }

  const emptyMessage = 'There are no results that match the applied filters.';
  const tryAgainMessage = reload && (
    <>
      The data you requested encountered an issue. Please reload the table to
      <Link theme="default" className={styles.reload} onClick={handleReload}>
        try again.
      </Link>
    </>
  );

  return (
    <div className={styles.container}>
      {tryAgainMessage && (
        <h2>
          Something went wrong…
        </h2>
      )}

      <div className={styles.info}>
        {tryAgainMessage || emptyMessage}
      </div>
    </div>
  );
}

Error.propTypes = {
  reload: PropTypes.func,
};

export default Error;
