import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import map from 'lodash/map';

import { pdfStylesShape } from 'components/core/ExportDocument/utils/shapes';

import Row from '../Row';
import { rowShape } from '../../utils/shapes';
import { tableStyles } from './styles';

export default function Table({ rows, style }) {
  const tableContainer = {
    ...tableStyles,
    ...style,
  };

  return (
    <View style={tableContainer}>
      {map(rows, ({ label, value, href }, rowIndex) => (
        <Row
          key={label}
          label={label}
          value={value}
          href={href}
          rowIndex={rowIndex}
        />
      ))}
    </View>
  );
}

Table.propTypes = {
  rows: PropTypes.arrayOf(rowShape),
  style: pdfStylesShape,
};
