import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_VALUE } from 'utils/constants';
import withProfile from 'components/core/Profile/utils/withProfile';
import { personShape } from 'components/core/Profile/utils/shapes';
import { LinkColumn } from 'components/core/Profile/components/Table';

import Details from '../Details';
import getLabel from './getLabel';

function WebAddresses({ person, isLoading, hideOnEmptyData }) {
  const { urls = [] } = person || {};

  const sections = [{
    id: 'webAddresses',
    title: 'Web addresses',
    tables: [{
      id: 'links',
      placeholderSize: 2,
      columns: [{
        id: 'link',
        component: LinkColumn,
        defaultValue: EMPTY_VALUE,
      }],
      getLabel,
      data: urls,
    }],
  }];

  if (!isLoading && hideOnEmptyData && isEmpty(urls)) {
    return null;
  }

  return (
    <Details
      sections={sections}
      isLoading={isLoading}
    />
  );
}

WebAddresses.defaultProps = {
  hideOnEmptyData: true,
};

WebAddresses.propTypes = {
  person: personShape,
  isLoading: PropTypes.bool,
  hideOnEmptyData: PropTypes.bool,
};

export default withProfile(WebAddresses);
