import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { POSITION_BUTTON_RECORD_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import Actions from 'components/core/Profile/components/Actions';
import ButtonTagManager from 'components/core/ButtonTagManager';
import ButtonShare from 'components/core/ButtonShare';
import { Column } from 'components/core/Table';

import CallToActions from '../CallToActions';
import { itemShape } from './utils/shapes';
import styles from './styles.module.scss';

function ActionsItem({
  item,
  isColumn,
  keyName,
  onSaveTags,
  updateDataItem,
  defaultSubscription,
  shouldDisplayTrackIcon,
  withBadges,
}) {
  const {
    id,
    url,
    documentType,
    title,
    itemType,
    tags = [],
    trackings,
  } = item || {};
  const callToActionsProps = {
    id,
    itemType,
    isMobile: false,
    withTrack: true,
    defaultSubscription,
    shouldDisplayIcon: shouldDisplayTrackIcon,
    positionClassName: styles.positionContainer,
    placeholderClassName: styles.positionPlaceholder,
    containerClassName: styles.callToActionsContainer,
    documentType: POSITION_BUTTON_RECORD_TYPES[documentType],
    onUpdateTrackings: updateDataItem,
    withBadges,
    trackings,
  };

  const options = [
    {
      id: 'tag',
      component: (
        <ButtonTagManager
          autoload
          theme="light"
          tags={tags}
          recordId={id}
          recordType={documentType}
          itemType={itemType}
          buttonClassName={styles.linkButton}
          onSaveTags={onSaveTags}
          updateDataItem={updateDataItem}
        />
      ),
    },
    {
      id: 'share',
      component: (
        <ButtonShare
          withModal
          theme="light"
          recordId={id}
          recordType={documentType}
          itemType={itemType}
          itemUrl={url}
          itemName={title}
          className={styles.linkButton}
        />
      ),
    },
  ];

  const renderActions = () => {
    if (!id) {
      return null;
    }

    return (
      <div className={styles.container}>
        <CallToActions
          {...callToActionsProps}
          onUpdateTrackings={updateDataItem}
          withPosition
        />

        <Actions
          theme="secondary"
          options={options}
        />
      </div>
    );
  };

  if (!isColumn) {
    return renderActions();
  }

  return (
    <Column key={keyName} keyName={keyName}>{renderActions}</Column>
  );
}

ActionsItem.defaultProps = {
  isColumn: true,
  keyName: 'actions',
  shouldDisplayTrackIcon: false,
};

ActionsItem.propTypes = {
  keyName: PropTypes.string,
  item: itemShape.isRequired,
  isMobile: PropTypes.bool,
  isColumn: PropTypes.bool,
  isLoadingSubscriptions: PropTypes.bool,
  defaultSubscription: PropTypes.string,
  onSaveTags: PropTypes.func,
  updateDataItem: PropTypes.func,
  withWeekAheadEmail: PropTypes.bool,
  shouldDisplayTrackIcon: PropTypes.bool,
  withBadges: PropTypes.bool,
};

export default memo(ActionsItem);
