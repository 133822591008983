import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useDidUpdateEffect from 'utils/useDidUpdateEffect';
import Input from 'components/core/Input';

import { isValid } from './utils';
import styles from './styles.module.scss';

export default function TextInputValidator({
  text,
  onValidationStatusChange,
  errorMessage,
  className,
  isCaseSensitive,
}) {
  const [inputValue, setInputValue] = useState();
  const hasError = !isValid({ inputValue, text, isCaseSensitive });

  const updateValidationStatus = () => onValidationStatusChange(!hasError);
  useDidUpdateEffect(updateValidationStatus, [inputValue]);

  const onInputChange = ({ target }) => setInputValue(target.value);

  const inputErrorMessage = hasError ? errorMessage : null;

  return (
    <Input
      onChange={onInputChange}
      isError={hasError}
      errorMessage={inputErrorMessage}
      className={classNames(styles.input, className)}
    />
  );
}

TextInputValidator.defaultProps = {
  isCaseSensitive: false,
};

TextInputValidator.propTypes = {
  text: PropTypes.string.isRequired,
  onValidationStatusChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  isCaseSensitive: PropTypes.bool,
};
