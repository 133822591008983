import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import PageHeader, { settingsButtonOptionsShape } from 'components/core/PageHeader';

import {
  trackSaveSearchDeletedItem,
  trackRecentSearchSavedItem,
  trackSearchedItems,
  trackAllResultsEmailed,
  trackResultEmailed,
  trackAllResultsExported,
  trackResultsExported,
  trackResultSearched,
  trackTableViewVisited,
  trackMapViewVisited,
} from './utils/trackingFunctions';
import { advancedSearchShape, mapDataShape } from './utils/shapes';
import { LookupContext } from './utils/withLookup';
import Container from './components/Container';
import { defaultDisclaimerProps } from './utils/constants';

function PageLookup({
  headerData,
  dataProps,
  dataLookup,
  dataOptions,
  tableData,
  mapData,
  pageView,
  PopupComponent,
  markerImage,
  renderCustomActions,
  advancedProps,
  advancedSearch,
  actions,
  notifications,
  trackFunction,
  ActionBarButtons,
  renderActionBarButtons,
  HeaderButtons,
  renderHeaderButtons,
  useCustomActions,
  customToggleViewProps,
  withSearchDisclaimer,
  disclaimerProps,
  settingsButtonOptions,
}) {
  const { isMobile, isDesktopLarge } = useMediaQuery();
  const { fetchData } = actions;
  const { ui: dataLookupUi, exportReport } = dataLookup;
  const { ui: exportReportUi } = exportReport || {};

  const {
    ui: advancedSearchUi,
    savedPaginator,
    loadAllRecentSearches,
    loadAllSavedSearches,
  } = advancedSearch || {};

  const commonUi = {
    ...dataLookupUi,
    ...exportReportUi,
    ...advancedSearchUi,
  };

  function loadRecentSearches(params = {}) {
    loadAllRecentSearches && loadAllRecentSearches(params, dataProps);
  }

  function loadSavedSearches(params = {}) {
    loadAllSavedSearches && loadAllSavedSearches(params, dataProps);
  }

  function handleChangeSavedItems(page) {
    loadAllSavedSearches && loadAllSavedSearches({ page }, dataProps);
  }

  function getTrackingFunctions() {
    if (!isFunction(trackFunction)) {
      return {};
    }

    return {
      trackRecentSearchSavedItem: () => trackRecentSearchSavedItem(trackFunction),
      trackSaveSearchDeletedItem: () => trackSaveSearchDeletedItem(trackFunction),
      trackSearchedItems: () => trackSearchedItems(trackFunction),
      trackAllResultsEmailed: () => trackAllResultsEmailed(trackFunction),
      trackResultEmailed: () => trackResultEmailed(trackFunction),
      trackAllResultsExported: () => trackAllResultsExported(trackFunction),
      trackResultsExported: () => trackResultsExported(trackFunction),
      trackResultSearched: () => trackResultSearched(trackFunction),
      trackTableViewVisited: () => trackTableViewVisited(trackFunction),
      trackMapViewVisited: () => trackMapViewVisited(trackFunction),
    };
  }

  const context = {
    dataProps,
    dataLookup: {
      ...dataLookup,
      ui: commonUi,
      isMobile,
      isDesktopLarge,
    },
    dataOptions,
    tableData,
    pageView,
    mapData,
    PopupComponent,
    markerImage,
    renderCustomActions,
    useCustomActions,
    advancedProps,
    actions,
    customToggleViewProps,
    advancedSearch: {
      ...advancedSearch,
      onSearch: fetchData,
      isLoadingSearches: advancedSearch.ui.isSavedSearchesLoading || advancedSearch.ui.isRecentSearchesLoading,
      loadRecentSearches,
      loadSavedSearches,
      paginatorSaved: savedPaginator,
      onChangeSavedPage: handleChangeSavedItems,
    },
    notifications,
    tracking: getTrackingFunctions(),
    renderActionBarButtons,
    ActionBarButtons,
    renderHeaderButtons,
    HeaderButtons,
    withSearchDisclaimer,
    disclaimerProps,
    settingsButtonOptions,
  };

  return (
    <LookupContext.Provider value={context}>
      {!isEmpty(headerData) && (
        <PageHeader {...headerData} isFullWidth />
      )}

      <section>
        <Container />
      </section>
    </LookupContext.Provider>
  );
}

PageLookup.defaultProps = {
  pageView: {},
  withSearchDisclaimer: false,
  disclaimerProps: defaultDisclaimerProps,
};

PageLookup.propTypes = {
  customToggleViewProps: PropTypes.shape({
    viewContent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
  }),
  headerData: PropTypes.shape({
    title: PropTypes.string,
  }),
  dataProps: PropTypes.shape({
    yearsRange: PropTypes.arrayOf(PropTypes.string),
  }),
  dataLookup: PropTypes.shape({
    customKeyMessage: PropTypes.string,
    allData: PropTypes.arrayOf(PropTypes.object),
    exportReport: PropTypes.shape({
      isLoadingExports: PropTypes.bool,
    }),
    ui: PropTypes.shape({
      isLoadingData: PropTypes.bool,
    }),
  }).isRequired,
  dataOptions: PropTypes.shape({
    useOptions: PropTypes.func,
    optionsComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
    emptyState: PropTypes.shape({
      searchValue: PropTypes.string,
      options: PropTypes.shape({
        legislator: PropTypes.arrayOf(PropTypes.object),
      }),
      omit: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  tableData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  mapData: mapDataShape,
  pageView: PropTypes.shape({
    pageView: PropTypes.string,
    isTablePageView: PropTypes.bool,
    onPageViewChange: PropTypes.func,
  }),
  renderCustomActions: PropTypes.func,
  advancedSearch: advancedSearchShape.isRequired,
  actions: PropTypes.shape({
    submitAllExport: PropTypes.func,
    fetchData: PropTypes.func,
    resetData: PropTypes.func,
    initialFetch: PropTypes.func,
  }),
  notifications: PropTypes.func,
  trackFunction: PropTypes.func,
  advancedProps: PropTypes.shape({
    tableProps: PropTypes.shape({
      perPage: PropTypes.number,
    }),
    paginatorProps: PropTypes.shape({
      pageSize: PropTypes.number,
    }),
  }),
  useCustomActions: PropTypes.shape({
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    toggleOpen: PropTypes.func,
    paginator: PropTypes.shape({
      current: PropTypes.number,
      pageSize: PropTypes.number,
      total: PropTypes.number,
      totalCount: PropTypes.number,
    }),
  }),
  ActionBarButtons: PropTypes.elementType,
  renderActionBarButtons: PropTypes.func,
  HeaderButtons: PropTypes.elementType,
  renderHeaderButtons: PropTypes.func,
  withSearchDisclaimer: PropTypes.bool,
  disclaimerProps: PropTypes.shape({
    withButtons: PropTypes.bool,
    content: PropTypes.node,
    isDisabled: PropTypes.bool,
    title: PropTypes.node,
    modalTheme: PropTypes.string,
    modalClassName: PropTypes.string,
  }),
  PopupComponent: PropTypes.elementType,
  markerImage: PropTypes.string,
  settingsButtonOptions: settingsButtonOptionsShape,
};

export default PageLookup;
