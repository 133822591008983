import { useEffect } from 'react';

import { desktopOffset, mobileOffset } from './settings.scss';

function useMapView({ dataLookup, pageView, actions }) {
  const { fetchData } = actions;
  const { isTablePageView } = pageView;
  const { ui, isMobile, appliedFilters } = dataLookup;
  const { isLoadingMapData: isLoadingMap } = ui;

  function getHeight() {
    const mobileHeight = `calc(100vh - ${mobileOffset})`;
    const desktopHeight = `calc(100vh - ${desktopOffset})`;

    return isMobile ? mobileHeight : desktopHeight;
  }

  useEffect(() => {
    if (!isTablePageView) {
      fetchData({ appliedFilters, isTablePageView });
    }
  }, [isTablePageView]);

  return {
    height: getHeight(),
    isMobile,
    isLoadingMap,
    isTablePageView,
    onFetch: fetchData,
  };
}

export default useMapView;
