export function transformPublicRecordTypes({
  publicRecordTypes = [],
  recordTypeLabelMapping = {},
}) {
  return publicRecordTypes
    .map(([val, key]) => ({
      id: key,
      labelPlural: val,
      label: recordTypeLabelMapping[key],
    }));
}
