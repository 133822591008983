const PEOPLE = {
  STATE_LEGISLATOR: 'state_legislator',
  FEDERAL_LEGISLATOR: 'federal_legislator',
  STAKEHOLDER: 'stakeholder',
  STATE_STAFFER: 'state_staffer',
  FEDERAL_STAFFER: 'federal_staffer',
  COLLEAGUE: 'colleague',
  LOCAL_EXECUTIVE: 'local_executive',
  STATE_EXECUTIVE: 'state_executive',
  FEDERAL_EXECUTIVE: 'federal_executive',
  STATE_AGENCY_STAFFER: 'state_agency_staffer',
};

const BILL = {
  STATE_BILL: 'state_bill',
  FEDERAL_BILL: 'federal_bill',
};

const REGULATION = {
  STATE_REGULATION: 'state_regulation',
  FEDERAL_REGULATION: 'federal_regulation',
};

const COMMITTEE = {
  STATE_COMMITTEE: 'state_committee',
  FEDERAL_COMMITTEE: 'federal_committee',
};

const HILL_DAY_ACTIVITY = {
  HILL_DAY: 'hill_day',
  HILL_DAY_MEETING: 'hill_day_meeting',
};

export const DOCUMENTS = {
  AGENCY_PRESS_RELEASE: 'agency_press_release',
  CBO_COST_ESTIMATE: 'cbo_cost_estimate',
  COMMITTEE_REPORT: 'committee_report',
  CONGRESSIONAL_PRESS_RELEASE: 'congressional_press_release',
  CONGRESSIONAL_RECORD_ITEM: 'congressional_record_item',
  CONTRACT: 'contract',
  CRS_REPORT: 'crs_report',
  DEAR_COLLEAGUE_LETTER: 'dear_colleague_letter',
  EXECUTIVE_ORDER: 'executive_order',
  GAO_REPORT: 'gao_report',
  GRANT: 'grant',
  LIVE_HEARING_TRANSCRIPT: 'live_hearing_transcript',
  OFFICIAL_HEARING_TRANSCRIPT: 'official_hearing_transcript',
  SAP_REPORT: 'sap_report',
  STATE_OF_THE_UNION_ADDRESS: 'state_of_the_union_address',
  TREATY_DOCUMENT: 'treaty_document',
  THIRD_PARTY_REPORT: 'third_party_report',
  WHITE_HOUSE_PRESS_BRIEFING: 'white_house_press_briefing',
  GOVERNOR_ORDER: 'governor_order',
  GOVERNOR_PRESS_RELEASE: 'governor_press_release',
};

const CONSTITUENT = 'constituent';

const USER = 'user';

export const TYPES = {
  ...PEOPLE,
  ...BILL,
  ...REGULATION,
  ...COMMITTEE,
  ...HILL_DAY_ACTIVITY,
  ...DOCUMENTS,
  CONSTITUENT,
  USER,
};
