import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';
import classNames from 'classnames';
import map from 'lodash/map';

import { MY_FEED, FACEBOOK, TWITTER, EMAIL_ID } from 'utils/constants/socialMedia';
import {
  getMailLinkFromObj,
  getTwitterShareLink,
  getFacebookDescriptionLink,
} from 'utils/socialMedia/links';
import Button from 'components/core/Button';
import Link from 'components/core/Link';
import useModal from 'components/core/Modal/useModal';
import { shareTooltipZIndex } from 'style/global/_variables.scss';

import { optionsShape, mailInfoShape } from '../../shapes';
import styles from '../../styles.module.scss';

function ButtonShare({
  withOptions,
  options,
  itemName,
  linkClassName,
  tooltipPlacement,
  tooltipContentClassName,
  onShare,
  isOpen,
  fullUrl,
  mailInfo,
  ...restProps
}) {
  const {
    isOpenModal: isVisible,
    toggleModal: toggleTooltip,
    closeModal: closeTooltip,
  } = useModal(isOpen);

  const commonProps = {
    theme: 'light',
    className: classNames(styles.link, linkClassName),
  };

  const externalLinkProps = {
    isExternal: true,
    displayExternalIcon: true,
  };

  const optionHref = {
    [FACEBOOK]: getFacebookDescriptionLink(fullUrl, itemName),
    [TWITTER]: getTwitterShareLink({ link: fullUrl, text: itemName }),
    [EMAIL_ID]: getMailLinkFromObj(mailInfo),
  };

  const tooltipContent = (
    <div className={styles.tooltipContent}>
      <h3 className={styles.tooltipTitle}>Share to...</h3>
      {map(options, ({ value, label }) => {
        if (value === MY_FEED) {
          return (
            <Button
              {...commonProps}
              key={value}
              onClick={onShare}
            >
              {label}
            </Button>
          );
        }

        return withOptions && (
          <Link
            {...commonProps}
            {...externalLinkProps}
            key={value}
            href={optionHref[value]}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );

  return (
    <Tooltip
      arrow
      theme="light"
      visible={isVisible}
      onHide={closeTooltip}
      onClickOutside={toggleTooltip}
      placement={tooltipPlacement}
      content={tooltipContent}
      appendTo={document.body}
      zIndex={shareTooltipZIndex}
    >
      <span className={tooltipContentClassName}>
        <Button {...restProps} onClick={toggleTooltip}>Share</Button>
      </span>
    </Tooltip>
  );
}

ButtonShare.defaultProps = {
  tooltipPlacement: 'bottom-start',
  withOptions: true,
};

ButtonShare.propTypes = {
  withOptions: PropTypes.bool,
  options: optionsShape,
  withShare: PropTypes.bool,
  recordId: PropTypes.string,
  recordType: PropTypes.string,
  itemType: PropTypes.string,
  itemName: PropTypes.string,
  onShare: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  tooltipContentClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  fullUrl: PropTypes.string,
  mailInfo: mailInfoShape,
};

export default ButtonShare;
