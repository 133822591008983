import { map } from 'lodash';

export const formatCreateLabel = (value, name = 'tag') => `Create new ${name} "${value}"`;

/**
 * set isNew flag so we can make pass the tag value as label
 * instead of: "Create new tag ..."
 */
export const getNewOptionData = (value, label) => ({ label, value, isNew: true });

export const transformTag = ({ isNew, value, label, ...tag }) => ({
  ...tag,
  isNew: !!isNew,
  label: isNew ? value : label,
  value,
});

export const transformFixedTags = (tags, isFixed) => map(tags, item => ({ ...item, isFixed }));
