import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/core/Link';

import styles from './styles.module.scss';

function Footer({ children, href }) {
  return (
    <Link theme="tableLink" href={href} className={styles.footer}>
      {children}
    </Link>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

export default Footer;
