import React from 'react';
import PropTypes from 'prop-types';
import { isString, isEmpty } from 'lodash';
import { View, Text } from '@react-pdf/renderer';

import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles';

function Timeline({ breakView, timeline, itemTextStyle }) {
  const timelineLength = timeline.length;

  function renderItem(item, index) {
    const { title, text } = item;
    const keyName = `timeline-${title}-${index}`;
    const isLast = index === timelineLength - 1;
    const itemStyles = [styles.timelineItem, isLast && styles.lastItem];
    const dashStyles = [styles.timelineDash, isLast && styles.lastTimelineDash];
    const itemTextStyles = [styles.itemText, itemTextStyle];

    if (isEmpty(title) || isEmpty(text)) {
      return null;
    }

    const titleComponent = isString(title)
      ? <Text style={styles.title}>{title}</Text>
      : title;

    return (
      <View wrap={false} style={itemStyles} key={keyName}>
        <View style={dashStyles} />
        <View style={styles.itemInfo}>
          {titleComponent}
          <Text style={itemTextStyles}>{text}</Text>
        </View>
      </View>
    );
  }

  function renderTimelineItems() {
    if (isEmpty(timeline)) {
      return (
        <Text style={styles.emptyText}>
          {EMPTY_VALUE}
        </Text>
      );
    }

    if (timelineLength === 1) {
      const { title, text } = timeline[0];

      return (
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.itemText}>{text}</Text>
        </View>
      );
    }

    return (
      <View style={styles.timelineItemsContainer}>
        {timeline.map(renderItem)}
      </View>
    );
  }

  return (
    <View break={breakView} style={styles.timelineContainer}>
      <Text style={styles.timelineTitle}>Timeline</Text>
      {renderTimelineItems()}
    </View>
  );
}

Timeline.propTypes = {
  breakView: PropTypes.bool,
  itemTextStyle: PropTypes.object,
  timeline: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.node,
    text: PropTypes.string,
  })),
};

export default Timeline;
