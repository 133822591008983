import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

function ImageIcon({ url, alt, className }) {
  return (
    <img className={classNames(styles.icon, className)} src={url} alt={alt} />
  );
}

ImageIcon.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default ImageIcon;
