import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getNameInDraggableItems } from '../utils';
import styles from '../styles.module.scss';

function joinWithSeparator(separator) {
  return (prev, curr, index) => [
    prev,
    <Fragment key={`${separator}-${index}`}> {separator} </Fragment>,
    curr,
  ];
}

function getOperatorElement(operatorLabel, index) {
  return <b key={`${operatorLabel}-${index}`}> {operatorLabel.toLowerCase()} </b>;
}

function StatementDescription({
  transactor,
  parentStatements,
  parentOperator,
  getFilterTranscript,
  draggableItems,
  searchTitle,
}) {
  let { label: transactorLabel = '' } = transactor || {};
  transactorLabel = transactorLabel.toLowerCase();

  function getFilterGroupTranscript(filtersGroup, parentSize) {
    const { id, operator, filters } = filtersGroup;
    const { label: operatorLabel } = operator || {};

    const filtersSize = filters.length;

    const filtersTranscript = filters.reduce((accumulator, filter, index) => {
      const { type, value } = filter;
      let filterTranscript;

      if (type === 'filter') {
        if (!isEmpty(value)) {
          filterTranscript = getFilterTranscript({ filter, index, draggableItems });
        }
      } else {
        filterTranscript = getFilterGroupTranscript(filter, filtersSize);
      }

      if (!isEmpty(filterTranscript)) {
        if (!isEmpty(accumulator)) {
          accumulator.push(getOperatorElement(operatorLabel, index));
        }

        accumulator.push(filterTranscript);
      }

      return accumulator;
    }, []);

    if (isEmpty(filtersTranscript)) {
      return null;
    }

    const shouldWrap = parentSize > 1;
    return (
      <Fragment key={id}>
        {shouldWrap && '('}{filtersTranscript}{shouldWrap && ')'}
      </Fragment>
    );
  }

  function getStatementTranscript(statement, parentSize) {
    const { id, filter, name } = statement;

    let filtersElement;
    let filtersTranscript;

    if (!isEmpty(filter)) {
      filtersTranscript = getFilterGroupTranscript(filter);
    }

    if (!isEmpty(filtersTranscript)) {
      filtersElement = <Fragment> where {filtersTranscript}</Fragment>;
    }

    const statementName = getNameInDraggableItems(name, draggableItems);

    const shouldWrap = parentSize > 1;
    return (
      <Fragment key={id}>
        {shouldWrap && '('}{transactorLabel} {statementName}{filtersElement}{shouldWrap && ')'}
      </Fragment>
    );
  }

  function getStatementGroupTranscript(statementGroup, parentSize) {
    const { id, operator, statements } = statementGroup;
    const { label: operatorLabel } = operator || {};

    const statementsSize = statements.length;

    let statementsTranscript;

    if (!isEmpty(statements)) {
      statementsTranscript = statements.map(statement => getStatementTranscript(statement, statementsSize))
        .reduce(joinWithSeparator(getOperatorElement(operatorLabel)));
    }

    const shouldWrap = parentSize > 1;
    return (
      <Fragment key={id}>
        {shouldWrap && '('}{statementsTranscript}{shouldWrap && ')'}
      </Fragment>
    );
  }

  function getSearchTitle() {
    if (isEmpty(searchTitle)) { return 'new list'; }
    return searchTitle;
  }

  function getStatementDescription() {
    if (isEmpty(transactorLabel)) {
      return <div>N/A</div>;
    }

    let statementsDescription = '..';

    if (!isEmpty(parentStatements)) {
      const { label: operatorLabel } = parentOperator;
      const statementsSize = parentStatements.length;

      statementsDescription = parentStatements.map(statement => getStatementGroupTranscript(statement, statementsSize))
        .reduce(joinWithSeparator(getOperatorElement(operatorLabel)));
    }

    return <div>Include a <b>{transactorLabel}</b> in {getSearchTitle()} IF {statementsDescription}.</div>;
  }

  return (
    <div className={styles.statementDescription}>
      <div className={styles.statementDescriptionTitle}>
        Statement:
      </div>
      {getStatementDescription()}
    </div>
  );
}

StatementDescription.defaultProps = {
  transactor: {},
  parentStatements: [],
  parentOperator: {},
  searchTitle: '',
};

StatementDescription.propTypes = {
  transactor: PropTypes.object,
  parentStatements: PropTypes.array,
  parentOperator: PropTypes.object,
  getFilterTranscript: PropTypes.func.isRequired,
  draggableItems: PropTypes.array.isRequired,
  searchTitle: PropTypes.string,
};

export default StatementDescription;
