import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import styles from '../styles.module.scss';

function MenuItem({ tab, onClick }) {
  if (isEmpty(tab)) {
    return null;
  }

  const { id, title, description } = tab;

  function handleClickItem() {
    const { onClick: onTabClick } = tab;

    onClick(tab);
    onTabClick && onTabClick(tab);
  }

  return (
    <button
      key={id}
      type="button"
      className={classNames(styles.itemButton)}
      onClick={handleClickItem}
    >
      <div className={styles.itemContent}>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.itemDescription}>{description}</div>
      </div>
      <i className="fas fa-caret-right" />
    </button>
  );
}

MenuItem.propTypes = {
  tab: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onClick: PropTypes.func.isRequired,
};

export default MenuItem;
