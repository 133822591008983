import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TippyTooltip from '@tippyjs/react';

import Button from 'components/core/Button';
import { trackHelpCenterDialogOpen } from 'utils/tracking';

import TooltipContent from '../TooltipContent';
import styles from './styles.module.scss';

export default function HelpButton({ page, pageName, className, triggerClassName }) {
  const tooltipContent = (
    <TooltipContent
      page={page}
      pageName={pageName}
    />
  );

  return (
    <TippyTooltip
      content={tooltipContent}
      className={styles.menuTooltip}
      placement="bottom-end"
      trigger="click"
      theme="light"
      interactive
      appendTo={document.body}
    >
      <div className={classNames(styles.trigger, triggerClassName)}>
        <Button
          theme="light"
          size="small"
          onClick={trackHelpCenterDialogOpen}
          customIcon={<i className={'fas fa-question'} />}
          className={classNames(styles.helpButton, className)}
          iconClassName={styles.questionMarkIcon}
        />
      </div>
    </TippyTooltip>
  );
}

HelpButton.propTypes = {
  page: PropTypes.string,
  pageName: PropTypes.string,
  className: PropTypes.string,
  triggerClassName: PropTypes.string,
};
