import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Link, Text, Image } from '@react-pdf/renderer';
import { isNil, isArray, map, reduce } from 'lodash';

import { GREY_COLOR } from 'components/core/ExportDocument/utils/colors.scss';
import { officialSourceShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { isURL, getUrlWithBase } from 'utils/url';

const styles = StyleSheet.create({
  row: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  externalImage: {
    width: 12,
    height: 12,
    marginRight: 2,
  },
  link: {
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 400,
    color: GREY_COLOR,
    letterSpacing: 0,
    textDecoration: 'underline',
  },
});

function ExternalLink({ href, label }) {
  if (isNil(href)) {
    return null;
  }

  if (isArray(href)) {
    const renderMultipleExternalLinks = () => {
      const externalLinkNodes = map(href, ({ value: itemHref, label: itemLabel }) => (
        <View key={itemHref}>
          <Image
            style={styles.externalImage}
            src={getUrlWithBase('/images/external-link.png')}
          />
          <Link style={styles.link} src={itemHref}>{itemLabel}</Link>
        </View>
      ));

      return reduce(externalLinkNodes, (prev, curr) => [prev, ' / ', curr]);
    };

    return (
      <View style={styles.row}>
        {renderMultipleExternalLinks()}
      </View>
    );
  }

  if (!isURL(href)) {
    return (
      <View style={styles.row}>
        <Text style={styles.link}>{href}</Text>
      </View>
    );
  }

  return (
    <View style={styles.row}>
      <Image
        style={styles.externalImage}
        src={getUrlWithBase('/images/external-link.png')}
      />
      <Link style={styles.link} src={href}>{label}</Link>
    </View>
  );
}

ExternalLink.propTypes = {
  href: officialSourceShape,
  label: PropTypes.string,
};

export default ExternalLink;
