import { useState } from 'react';

function useModal(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return {
    isOpenModal: isOpen,
    toggleModal,
    openModal,
    closeModal,
  };
}

export default useModal;
