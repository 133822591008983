import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Icon({ iconClass, withIconWrapper }) {
  if (withIconWrapper) {
    return (
      <span className={styles.iconWrapper}>
        <i className={classNames(styles.icon, 'fas', iconClass)} />
      </span>
    );
  }

  return (
    <i className={classNames('far', iconClass)} />
  );
}

Icon.defaultProps = {
  withIconWrapper: false,
};

Icon.propTypes = {
  iconClass: PropTypes.string,
  withIconWrapper: PropTypes.bool,
};

export default Icon;
