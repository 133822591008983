import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from 'components/core/Loader';
import usePaginator from 'components/core/Paginator/usePaginator';

import styles from './styles.module.scss';
import useInfiniteLoad from './useInfiniteLoad';

const paginatorRef = createRef();

function InfiniteLoadPaginator({
  isLoading,
  isPaginatorPresent,
  onLoad,
  className,
  onlyIcon,
  renderCustomLoader,
  capture,
}) {
  const { onLoadMore } = usePaginator({ onChange: onLoad });
  useInfiniteLoad({ onLoad: onLoadMore, ref: paginatorRef, isLoading, capture });

  if (!isPaginatorPresent) {
    return null;
  }

  function renderLoader() {
    if (renderCustomLoader) {
      return renderCustomLoader(isLoading);
    }

    return isLoading && (
      <Loader onlyIcon={onlyIcon} className={styles.loader} size="small" />
    );
  }

  return (
    <div className={classNames(styles.container, className)} ref={paginatorRef}>
      {renderLoader()}
    </div>
  );
}

InfiniteLoadPaginator.defaultProps = {
  onlyIcon: false,
};

InfiniteLoadPaginator.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isPaginatorPresent: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  renderCustomLoader: PropTypes.func,
  className: PropTypes.string,
  capture: PropTypes.bool,
};

export default InfiniteLoadPaginator;
