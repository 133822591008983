import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const themes = {
  default: styles.default,
  light: styles.light,
};

const positions = {
  right: styles.right,
  bottom: styles.bottom,
};

function ContextualMenu({ theme, position, className, iconClassName, iconSizeClassName, isDefaultStyle }) {
  return (
    <span
      role="button"
      className={classNames(
        'contextual-menu-icon',
        isDefaultStyle && styles.iconWrapper,
        themes[theme],
        positions[position],
        className,
      )}
    >
      <i className={classNames(iconSizeClassName, iconClassName, styles.icon)} />
    </span>
  );
}

ContextualMenu.defaultProps = {
  theme: 'default',
  position: '',
  iconClassName: 'fa-ellipsis-h',
  iconSizeClassName: 'far',
  isDefaultStyle: true,
};

ContextualMenu.propTypes = {
  theme: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSizeClassName: PropTypes.oneOf(['fas', 'far', 'fal', 'fad', 'fab']),
  isDefaultStyle: PropTypes.bool,
};

export default ContextualMenu;
