import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import { getFilterLabels } from '../../utils';

import styles from './styles.module.scss';

function ItemInfo({ isPresentShowMore, title, filters }) {
  const filterLabels = getFilterLabels(filters);
  const [isOpen, setIsOpen] = useState(false);
  const maxLength = 85;
  const withShowMore = isPresentShowMore && filterLabels.length > maxLength;

  function handleShowToggle(event) {
    event.stopPropagation();
    setIsOpen(!isOpen);
  }

  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <article className={styles.description}>
        {filters && (
          <span className={classNames(styles.text, isOpen && styles.isOpen)}>
            {(!withShowMore || isOpen) ? filterLabels : `${filterLabels.slice(0, maxLength)}...`}
          </span>
        )}
        {withShowMore && (
          <Button
            theme="light"
            size="small"
            className={styles.showToggle}
            onClick={handleShowToggle}
          >
            Show { isOpen ? 'less' : 'more' }
          </Button>
        )}
      </article>
    </div>
  );
}

ItemInfo.defaultProps = {
  isPresentShowMore: true,
};

ItemInfo.propTypes = {
  isPresentShowMore: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.array,
};

export default ItemInfo;
