import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from 'components/core/Select';

import theme from './theme';
import styles from './styles.module.scss';

function InputSelect({
  name,
  onChange,
  isError,
  className,
  customTheme,
  menuPosition,
  ...restProps
}) {
  function handleChangeType(selectedOption) {
    onChange(name, selectedOption.value);
  }

  return (
    <Select
      {...restProps}
      optionKey="value"
      onChange={handleChangeType}
      className={classNames(styles.inputType, className)}
      customTheme={{ ...theme(isError), ...customTheme }}
      clearable={false}
      searchable={false}
      hasNewStyle
      menuPosition={menuPosition}
    />
  );
}

InputSelect.defaultProps = {
  customTheme: null,
  menuPosition: 'absolute',
};

InputSelect.propTypes = {
  value: PropTypes.string,
  menuPosition: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  customTheme: PropTypes.object,
};

export default InputSelect;
