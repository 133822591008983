import React from 'react';

import styles from './styles.module.scss';

function Logo() {
  return (
    <img
      className={styles.logo}
      src="/images/intelligence-icon-dark.png"
      alt="Intelligence Logo"
    />
  );
}

export default Logo;
