import React from 'react';
import PropTypes from 'prop-types';

import PageActionsButton from 'components/core/PageActionsButton';
import PageHeader from 'components/core/PageHeader';

export default function ActionPageHeader({
  title,
  description,
  page,
  pageName,
  pageLinks,
  applyExtraMargin,
  headerClassName,
  customBreadcrumb,
  ...restProps
}) {
  return (
    <PageHeader
      {...restProps}
      applyExtraMargin={applyExtraMargin}
      headerClassName={headerClassName}
      customBreadcrumb={customBreadcrumb}
      title={title}
      page={page}
      pageName={pageName}
      description={description}
      rightContent={<PageActionsButton pageLinks={pageLinks} />}
    />
  );
}

ActionPageHeader.defaultProps = {
  applyExtraMargin: true,
};

ActionPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  page: PropTypes.string,
  pageName: PropTypes.string,
  pageLinks: PropTypes.array.isRequired,
  applyExtraMargin: PropTypes.bool,
  headerClassName: PropTypes.string,
  customBreadcrumb: PropTypes.string,
};
