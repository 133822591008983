import React from 'react';
import PropTypes from 'prop-types';

import { getDate, utcDayEnd, utcDayStart } from 'utils/date';
import DateRange from 'components/core/DateRange';

import styles from '../styles.module.scss';

function DateFilter({ id, values, onChange }) {
  const { startDate, endDate } = values;

  const handleChange = newValue => onChange(id, newValue);

  const handleChangeStart = newDate => handleChange({ startDate: utcDayStart(newDate), endDate });

  const handleChangeEnd = newDate => handleChange({ startDate, endDate: utcDayEnd(newDate) });

  return (
    <DateRange
      autoOpenEndDate={false}
      maxStartDate={new Date()}
      startDate={getDate(startDate)}
      endDate={getDate(endDate)}
      handleChangeStart={handleChangeStart}
      handleChangeEnd={handleChangeEnd}
      className={styles.item}
      showMonthDropdown
      showYearDropdown
    />
  );
}

DateFilter.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateFilter;
