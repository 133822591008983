import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, map, flatten, uniq, compact } from 'lodash';

import { joinCompact } from 'utils/string';
import { ViewMoreItem } from 'components/core/Table/components/ColumnItem';
import { stafferShape } from 'components/core/Profile/components/Cards/Staffers/utils/shapes';
import { getStateName } from 'components/core/Profile/components/Cards/utils';

function AddressColumn({ keyName, item }) {
  const { positions } = item || {};
  const addresses = flatten(map(positions, 'address'));
  const dataList = uniq(compact(map(addresses, ({ street, city, state, zipCode } = {}) => {
    const stateName = getStateName(get(state, 'name'));

    return joinCompact([street, city, stateName, zipCode]);
  })));

  return (
    <ViewMoreItem
      keyName={keyName}
      dataList={dataList}
      isEmptyData={isEmpty(dataList)}
    />
  );
}

AddressColumn.propTypes = {
  keyName: PropTypes.string,
  item: stafferShape,
};

export default AddressColumn;
