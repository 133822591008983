import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const checkboxThemes = {
  default: styles.default,
  light: styles.light,
};

function Checkbox({
  label,
  theme,
  isChecked,
  isDisabled,
  className,
  checkMarkClassName,
  onChange,
  ...restProps
}) {
  const labelClass = classNames(
    styles.container,
    !label && styles.emptyLabel,
    isDisabled && styles.disabledLabel,
    checkboxThemes[theme],
    className,
  );

  const handleChange = (event) => {
    const { target } = event || {};

    target.focus && target.focus();
    onChange && onChange(event);
  };

  return (
    <label className={labelClass}>
      <input
        {...restProps}
        onChange={handleChange}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        className={styles.input}
      />
      {label}
      <span className={classNames(styles.checkmark, checkMarkClassName)} />
    </label>
  );
}

Checkbox.defaultProps = {
  theme: 'default',
  isDisabled: false,
  onChange: () => { },
};

Checkbox.propTypes = {
  theme: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  checkMarkClassName: PropTypes.string,
};

export default Checkbox;
