import { startCase, toLower } from 'lodash';

import { getParenthesisItem } from 'utils/string';

const CURRENT_YEAR = 'CURR';

export const getEndDate = (endDate) => {
  if (!endDate || endDate === CURRENT_YEAR) {
    return 'Present';
  }

  return endDate;
};

export const getDate = (start, end) => {
  if (!(start || end)) {
    return '';
  }

  const date = start ? `${start}-${getEndDate(end)}` : getEndDate(end);

  return getParenthesisItem(date);
};

export const getStateName = (state) => {
  const isFederal = state === 'All';

  if (isFederal) {
    return null;
  }

  return startCase(toLower(state));
};
