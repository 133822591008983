import colors from './colors.scss';

const themeStyles = {
  ORANGE: {
    '--fa-primary-color': colors.orange8,
    '--fa-secondary-color': colors.orange11,
    '--fa-secondary-opacity': 0.8,
  },
  GREEN: {
    '--fa-primary-color': colors.green8,
    '--fa-secondary-color': colors.green5,
    '--fa-secondary-opacity': 0.8,
  },
};

const THEMES = {
  ORANGE: 'ORANGE',
  GREEN: 'GREEN',
};

export { themeStyles, THEMES };
