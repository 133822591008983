import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FooterActions from 'components/Stakeholder/Lookup/components/TableColumnsView/components/FooterActions';
import TableColumnsView from 'components/Stakeholder/Lookup/components/TableColumnsView';
import initialTableColumns from 'components/Stakeholder/Lookup/utils/tableColumns';
import { PAGE_SIZE } from 'components/core/Profile/utils';
import { tableColumnShape } from 'components/core/Profile/components/Cards/utils/shapes';
import Table from 'components/core/Table';
import Paginator from 'components/core/Paginator';
import { paginatorShape } from 'components/core/Paginator/shapes';
import { sortShape } from 'utils/shapes';

import { stakeholderShape, uiShape } from '../../utils/shapes';
import withStakeholders from '../../utils/withStakeholders';
import useTable from '../../utils/useTable';
import styles from './styles.module.scss';

function TableView({
  id,
  ui,
  isMobile,
  sort,
  paginator,
  stakeholders,
  fetchStakeholders,
  districtInfo,
  chamberCategory,
  trackingActions,
  tableColumns,
  customTransformers,
  defaultHiddenColumns,
  isFederalSenator,
  ...restProps
}) {
  const { isLoadingData, toResetSelectedItems } = ui;
  const {
    selectedItems,
    isPaginatorPresent,
    isSelectedItemsPresent,
    sortData,
    perPage,
    onSortColumn,
    onChangePage,
    onClearItems,
    onSelectItems,
  } = useTable({
    id,
    sort,
    paginator,
    fetchStakeholders,
    trackingActions,
    customTransformers,
    tableColumns,
    defaultHiddenColumns,
    toResetSelectedItems,
    districtInfo,
    chamberCategory,
    isFederalSenator,
  });

  function renderColumn(column) {
    return TableColumnsView({
      ...column,
      trackingActions,
      isLookupTable: false,
      fetchData: fetchStakeholders,
    });
  }

  function renderPagination() {
    if (!isPaginatorPresent) return null;

    const { current, total, totalCount, pageSize = perPage } = paginator;

    return (
      <Paginator
        isLoading={isLoadingData}
        isMobile={isMobile}
        current={current}
        total={total}
        pageSize={pageSize}
        totalItems={totalCount}
        onChange={onChangePage}
      />
    );
  }

  const footerProps = {
    ui,
    data: stakeholders,
    sortData,
    onClear: onClearItems,
    actionsMap: {
      modalProps: {
        ...restProps,
        ui,
        fetchStakeholders,
      },
    },
    paginator: {
      ...paginator,
      pageSize: paginator.pageSize || perPage,
    },
    trackingFunctions: trackingActions,
    isFixed: false,
    selectedItems,
  };

  function renderTableComponents() {
    return (
      <>
        {isSelectedItemsPresent && (
          <FooterActions
            {...footerProps}
            allowEditing={false}
          />
        )}
        {renderPagination()}
      </>
    );
  }

  const tableClassName = classNames(
    styles.tableContent,
    paginator
      && paginator.current > 1
      && paginator.current === paginator.total
      && styles.fixedHeight
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isLoadingData}
        rowLengthPlaceholder={PAGE_SIZE}
        uniqueKey="id"
        columns={tableColumns}
        containerStyle={tableClassName}
        data={stakeholders}
        sortColumn={sortData.column}
        sortDirection={sortData.direction}
        onSort={onSortColumn}
        isPaginatorPresent={isPaginatorPresent}
        isMobile={isMobile}
        selectedItems={selectedItems}
        onSelectItems={onSelectItems}
        customEmptyMessage="stakeholders"
        isCheckboxPresent
        preserveCheckboxes
        isDefaultTable
        isFixed
      >
        {tableColumns.map(renderColumn)}
      </Table>

      {renderTableComponents()}
    </div>
  );
}

TableView.defaultProps = {
  trackingActions: {},
  tableColumns: initialTableColumns,
  isFederalSenator: false,
  defaultHiddenColumns: {
    middle_name: true,
  },
};

TableView.propTypes = {
  id: PropTypes.string,
  ui: uiShape.isRequired,
  sort: sortShape.isRequired,
  paginator: paginatorShape.isRequired,
  stakeholders: PropTypes.arrayOf(stakeholderShape).isRequired,
  fetchStakeholders: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  customTransformers: PropTypes.shape({
    [PropTypes.string]: PropTypes.func,
  }),
  tableColumns: PropTypes.arrayOf(tableColumnShape),
  defaultHiddenColumns: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  districtInfo: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    state: PropTypes.string,
  }),
  isFederalSenator: PropTypes.bool,
  chamberCategory: PropTypes.string,
  trackingActions: PropTypes.shape({
    trackStakeholderEmailed: PropTypes.func,
    trackStakeholderVisited: PropTypes.func,
    trackStakeholderPhoned: PropTypes.func,
    trackStakeholdersPageVisited: PropTypes.func,
    trackStakeholderResultsSorted: PropTypes.func,
  }),
};

export default withStakeholders(TableView);
