const staffer = (sortingKey = 'full_name.raw') => ({
  label: 'Staffer',
  keyName: 'name',
  allowToggle: false,
  sortingKey,
});

const title = {
  label: 'Title',
  keyName: 'title',
  allowToggle: false,
  sortingKey: 'sort_title',
};

const legislativeIssueArea = {
  label: 'Legislative issue area',
  keyName: 'issues',
  allowToggle: false,
};

const email = (hasSorting = true) => ({
  label: 'Email',
  keyName: 'email',
  allowToggle: false,
  ...(hasSorting && { sortingKey: 'email.raw' }),
});

const phone = (hasSorting = true) => ({
  label: 'Phone',
  keyName: 'phone',
  allowToggle: false,
  ...(hasSorting && { sortingKey: 'phone.raw' }),
});

const address = {
  label: 'Address',
  keyName: 'address',
  allowToggle: false,
};

const titles = {
  label: 'Title',
  keyName: 'titles',
  allowToggle: false,
};

const role = {
  label: 'Role',
  keyName: 'role',
  allowToggle: false,
};

const officeType = {
  label: 'Office type',
  keyName: 'office',
  allowToggle: false,
};

export const federalTableColumns = [staffer(), title, officeType, legislativeIssueArea, email()];

export const stateTableColumns = [staffer(), title, email(), phone()];

export const stateExecutiveTableColumns = [
  staffer('full_name'),
  titles,
  role,
  address,
  email(false),
  phone(false),
];
