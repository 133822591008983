import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, size, map } from 'lodash';

import { TextPlaceholder } from 'components/core/Placeholder';
import withPublicRecords from 'components/core/Profile/components/Cards/PublicRecord/utils/withPublicRecords';
import Checkbox from 'components/core/Checkbox';

import styles from './styles.module.scss';

function Types({ checkboxRecordTypes, selectedTypes, onCheckType }) {
  if (isEmpty(checkboxRecordTypes)) {
    return <TextPlaceholder className={styles.placeholder} />;
  }

  function handleChange(typeId) {
    return (event) => {
      onCheckType(typeId, event);
    };
  }

  return (
    <div className={styles.container}>
      {map(checkboxRecordTypes, (type = {}) => (
        <Checkbox
          key={`${type.id}-${type.labelPlural}`}
          label={type.labelPlural}
          name={type.id}
          value={type.id}
          className={styles.checkbox}
          isChecked={selectedTypes.includes(type.id)}
          onChange={handleChange(type.id)}
          isDisabled={size(checkboxRecordTypes) === 1}
        />
      ))}
    </div>
  );
}

Types.propTypes = {
  checkboxRecordTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      labelPlural: PropTypes.string,
    })
  ),
  selectedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheckType: PropTypes.func.isRequired,
};

export default withPublicRecords(Types);
