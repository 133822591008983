import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import withSearch from 'components/core/AdvancedSearch/utils/withSearch';

import ListSearches from '../ListSearches';
import styles from './styles.module.scss';

function MobileView({ onCancel, displayHeader, title }) {
  return (
    <div className={styles.mobileContainer}>
      {displayHeader && (
        <div className={styles.header}>
          <h2>{title}</h2>

          <Button
            theme="light"
            size="none"
            onClick={onCancel}
          >
            <i className="fal fa-times" />
          </Button>
        </div>
      )}

      <ListSearches />
    </div>
  );
}

MobileView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  displayHeader: PropTypes.bool.isRequired,
};

export default withSearch(MobileView);
