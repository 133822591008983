import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBackToParams } from 'components/core/Link/components/BackTo/utils';
import Link from 'components/core/Link';
import Button from 'components/core/Button';
import { selectedFeedShape } from 'components/News/utils/shapes';

import styles from './styles.module.scss';

function ShowClusterLink({
  id,
  className,
  selectedFeed,
  isExternalComponent,
  onShowCluster,
  isDesktop,
  isRouterLink,
  backTo,
  backToQuery,
}) {
  const backToParams = getBackToParams({ backTo, backToQuery, isFirstParam: true });
  const href = `/news/cluster/${id}${backToParams}`;
  const clusterLink = {
    pathname: href,
    state: { selectedFeed },
  };

  const customIcon = <i className="fas fa-expand-alt" />;
  const commonProps = {
    theme: 'default',
    iconClassName: styles.icon,
    customIcon,
  };

  const title = isDesktop ? 'More coverage' : '';

  if (isExternalComponent) {
    return (
      <Button
        {...commonProps}
        onClick={onShowCluster}
        className={classNames(styles.showClusterButton, className)}
      >
        {title}
      </Button>
    );
  }

  return (
    <Link
      {...commonProps}
      isRouterLink={isRouterLink}
      className={classNames(styles.showClusterLink, className)}
      to={clusterLink}
      href={href}
    >
      {title}
    </Link>
  );
}

ShowClusterLink.defaultProps = {
  isExternalComponent: false,
  isRouterLink: false,
};

ShowClusterLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedFeed: selectedFeedShape,
  className: PropTypes.string,
  isExternalComponent: PropTypes.bool,
  onShowCluster: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
  isRouterLink: PropTypes.bool,
  backTo: PropTypes.string,
  backToQuery: PropTypes.string,
};

export default ShowClusterLink;
