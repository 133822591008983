import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RichOption from './RichOption';
import styles from './styles.module.scss';

class Option extends Component {
  handleClick = () => {
    const { data, isMulti, setValue, getValue } = this.props;

    if (data && data.isDisabled) return;

    const value = isMulti
      ? [...getValue(), data]
      : data;

    setTimeout(() => setValue(value, 'set-value'), 0);
  };

  renderChildren = () => {
    const { data, children, selectProps } = this.props;

    if (selectProps.withRichOption) {
      return (
        <RichOption
          {...data}
          inputValue={selectProps.inputValue}
          strategy={selectProps.strategy}
        />
      );
    }

    return children;
  };

  render() {
    const { getStyles, innerRef, innerProps, isFocused } = this.props;

    const className = classNames(
      'Select-option',
      isFocused && styles.withRichOption,
    );

    return (
      <div
        {...innerProps}
        className={className}
        ref={innerRef}
        onClick={this.handleClick}
        style={getStyles('option', this.props)}
      >
        {this.renderChildren()}
      </div>
    );
  }
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
  isMulti: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  selectProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default Option;
