import { useEffect, useState } from 'react';

import { MY_FEED, FACEBOOK, TWITTER, EMAIL_ID } from 'utils/constants/socialMedia';
import {
  getFacebookDescriptionLink,
  getMailLinkFromObj,
  getTwitterShareLink,
} from 'utils/socialMedia/links';

function useShare({ isOpen = false, options, itemName, onShare, fullUrl, mailInfo }) {
  const { value: initialCheckedValue } = options[0] || {};
  const [isShareModalOpen, setIsShareModalOpen] = useState(isOpen);
  const [shareOption, setShareOption] = useState(initialCheckedValue);

  useEffect(() => {
    setShareOption(initialCheckedValue);
  }, [isShareModalOpen]);

  function toggleShareModal() {
    setIsShareModalOpen(prevState => !prevState);
  }

  function handleChangeShareItem({ target: { value } = {} } = {}) {
    setShareOption(value);
  }

  function handleShareSubmit() {
    if (shareOption === MY_FEED) {
      onShare();
    }

    if (shareOption === FACEBOOK) {
      window.open(getFacebookDescriptionLink(fullUrl, itemName));
    }

    if (shareOption === TWITTER) {
      window.open(getTwitterShareLink({ link: fullUrl, text: itemName }));
    }

    if (shareOption === EMAIL_ID) {
      window.open(getMailLinkFromObj(mailInfo));
    }

    toggleShareModal();
  }

  return {
    isShareModalOpen,
    shareOption,
    toggleShareModal,
    handleChangeShareItem,
    handleShareSubmit,
  };
}

export default useShare;
