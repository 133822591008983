import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/core/Input';
import CharInfo from 'components/core/CharInfo';

import styles from './styles.module.scss';

function InputWithCharInfo({ charInfo, ...props }) {
  return (
    <div className={styles.container}>
      <Input {...props} />
      <CharInfo
        content={props.value}
        {...charInfo}
      />
    </div>
  );
}

InputWithCharInfo.propTypes = {
  value: PropTypes.string,
  charInfo: PropTypes.object,
};

export default InputWithCharInfo;
