import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';

import styles from './styles.module.scss';

class DatePickerInput extends Component {
  render() {
    const { onClick, iconPosition, ...restProps } = this.props;

    return (
      <span>
        <i
          onClick={onClick}
          className={classNames('far fa-calendar', styles.icon, styles[iconPosition])}
        />
        <Input
          {...restProps}
          onClick={onClick}
          className={classNames(styles.input, styles[iconPosition])}
        />
      </span>
    );
  }
}

DatePickerInput.defaultProps = {
  iconPosition: 'left',
};

DatePickerInput.propTypes = {
  onClick: PropTypes.func,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

export default DatePickerInput;
