import PropTypes from 'prop-types';

export const linkShape = PropTypes.shape({
  value: PropTypes.string,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
});

export const historyShape = PropTypes.shape({
  action: PropTypes.string,
  block: PropTypes.func,
  createHref: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  length: PropTypes.number,
  listen: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const matchShape = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  path: PropTypes.string,
  url: PropTypes.string,
});
