import { useEffect, useState } from 'react';
import { map, find } from 'lodash';

import useSortTable from 'utils/useSortTable';

function useTable({
  data,
  sort,
  isLoading,
  defaultHiddenColumns = {},
  toResetSelectedItems = false,
} = {}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);
  const sorting = useSortTable(sort, isLoading);

  const isSelectedItemsPresent = selectedItems.length > 0;

  function handleSelectItems(items) {
    setSelectedItems(items);
  }

  function handleClearItems() {
    setSelectedItems([]);
  }

  function handleHideColumn(columnKeyName, isHidden) {
    setHiddenColumns(prevState => ({
      ...prevState,
      [columnKeyName]: isHidden,
    }));
  }

  useEffect(() => {
    const updateSelectedItems = (item = {}) => {
      const { id: selectedId } = item;

      return find(data, ({ id: dataId }) => selectedId === dataId) || item;
    };

    setSelectedItems(prevState => map(prevState, updateSelectedItems));
  }, [data]);

  useEffect(() => {
    toResetSelectedItems && handleClearItems();
  }, [toResetSelectedItems]);

  return {
    ...sorting,
    hiddenColumns,
    isSelectedItemsPresent,
    selectedItems,
    handleHideColumn,
    handleClearItems,
    handleSelectItems,
  };
}

export default useTable;
