import { StyleSheet } from '@react-pdf/renderer';

import {
  DEFAULT_LINE_COLOR,
  SECONDARY_BADGE_COLOR,
  DEFAULT_TEXT_COLOR,
} from 'components/core/ExportDocument/utils/colors.scss';

const styles = StyleSheet.create({
  container: {
    borderColor: DEFAULT_LINE_COLOR,
    borderWidth: 1,
    borderRadius: 4,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 11px',
  },
  rowBorder: {
    borderTopColor: DEFAULT_LINE_COLOR,
    borderTopWidth: 1,
  },
  label: {
    borderRadius: 4,
    backgroundColor: SECONDARY_BADGE_COLOR,
    color: DEFAULT_TEXT_COLOR,
    fontSize: 9,
    padding: '3px 9px 3px 6px',
  },
  labelView: {
    maxWidth: '25%',
  },
  value: {
    width: '75%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export default styles;
