import React from 'react';
import Tooltip from '@tippyjs/react';

import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import useModal from 'components/core/Modal/useModal';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import TooltipContent from './components/TooltipContent';
import styles from './styles.module.scss';

function FilterButton(props) {
  const { isOpenModal, toggleModal, closeModal } = useModal();
  const { isMobile } = useMediaQuery();

  const button = (
    <div className={styles.container}>
      <Button
        onClick={toggleModal}
        theme="tertiary"
        className={styles.filterButton}
        customIcon={<i className="fas fa-filter" />}
      >
        Filters
      </Button>
    </div>
  );

  const tooltipContent = isOpenModal && <TooltipContent {...props} isMobile={isMobile} onClose={closeModal} />;

  if (isMobile) {
    return (
      <>
        {button}
        <Modal
          className={styles.modal}
          theme="scrollable"
          title="Additional filters"
          isOpen={isOpenModal}
          toggleOpen={toggleModal}
          onRequestClose={closeModal}
        >
          {tooltipContent}
        </Modal>
      </>
    );
  }

  return (
    <Tooltip
      content={tooltipContent}
      visible={isOpenModal}
      onHide={closeModal}
      onClickOutside={toggleModal}
      interactive
      className={styles.tooltip}
      placement="bottom-end"
      maxWidth="none"
      theme="light"
      zIndex={2}
    >
      {button}
    </Tooltip>
  );
}

export default FilterButton;
