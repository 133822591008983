import isEmpty from 'lodash/isEmpty';

import useSortTable from 'utils/useSortTable';
import { getColumnLabelBySortingKey } from 'utils/table';

function useTable({ onSearch, paginator, trackingActions, tableColumns }) {
  const sort = useSortTable();
  const isPaginatorPresent = !isEmpty(paginator);
  const {
    trackStaffersPageVisited,
    trackStaffersResultsSorted,
  } = trackingActions;

  function handleSortColumn(column) {
    const { getDirection } = sort;
    const direction = getDirection(column);
    const columnLabel = getColumnLabelBySortingKey(tableColumns, column);

    onSearch({ sort: column, direction });
    trackStaffersResultsSorted(columnLabel, direction);
  }

  function handleChangePage(page) {
    const { sortData = {} } = sort;
    const { column, direction } = sortData;

    onSearch({ page, sort: column, direction });
    trackStaffersPageVisited(page);
  }

  return {
    ...sort,
    isPaginatorPresent,
    onSortColumn: handleSortColumn,
    onChangePage: handleChangePage,
  };
}

export default useTable;
