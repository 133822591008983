import { useState } from 'react';

type UseAvatar = {
  isValid: boolean;
  handleError: () => void;
};

// this MUST be the same value found for default_url in app/uploaders/user_photo_uploader.rb
const DEFAULT_PHOTO_URL = 'thumb_user_photo.png';

const useAvatar = (
  photoUrl?: string,
  avoidDefaultIcon = true,
  onError?: () => void,
): UseAvatar => {
  // the default behavior is to show user initials instead of this default image
  // although, BE is returning it instead of null, and changing it in BE will break many things
  const isValidUrl = photoUrl && !(avoidDefaultIcon && photoUrl.endsWith(DEFAULT_PHOTO_URL));
  const [isValid, setIsValid] = useState(!!isValidUrl);

  function handleError() {
    setIsValid(false);
    onError && onError();
  }

  return {
    isValid,
    handleError,
  };
};

export default useAvatar;
