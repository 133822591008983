import React from 'react';
import PropTypes from 'prop-types';

import updateGroupOperator from 'components/core/SmartSearchBuilder/utils/operatorActions/updateGroupOperator';
import updateItemOperator from 'components/core/SmartSearchBuilder/utils/operatorActions/updateItemOperator';
import { groupOperators } from 'components/core/SmartSearchBuilder/utils/groupOperators';
import withSmartSearch from 'components/core/SmartSearchBuilder/utils/withSmartSearch';
import Select from 'components/core/Select';

import styles from '../styles.module.scss';

const groupOperatorActions = {
  group: updateGroupOperator,
  item: updateItemOperator,
};

function GroupOperator({ type, id, operator, data, updateData }) {
  const { value: operatorValue } = operator || {};

  const handleOperatorChange = item => groupOperatorActions[type]({
    id,
    data,
    value: item,
    updateData,
  });

  return (
    <section className={styles.operator}>
      <Select
        value={operatorValue}
        options={groupOperators}
        onChange={handleOperatorChange}
        placeholder="Operator"
        searchable={false}
        clearable={false}
        hasNewStyle
      />
    </section>
  );
}

GroupOperator.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  operator: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withSmartSearch(GroupOperator);
