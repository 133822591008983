import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BaseOption } from 'components/core/AdvancedSearch';
import Select from 'components/core/Select';
import Input from 'components/core/Input';

import { GTE, LTE, BETWEEN, OPTIONS } from './constants';
import { rangeOptionFromValues } from './utils';
import styles from './styles.module.scss';

function RangeField({ keyName, titles, value, onChangeStart, onChangeEnd }) {
  const { start, end } = value;

  const [rangeOption, setRangeOption] = useState(rangeOptionFromValues(start, end));
  const [insideChange, setInsideChange] = useState(false);

  const showTo = rangeOption === BETWEEN;
  const showLessThan = rangeOption !== GTE;
  const showGreaterThan = rangeOption !== LTE;

  useEffect(() => {
    if (!insideChange) {
      setRangeOption(() => rangeOptionFromValues(start, end));
    }

    setInsideChange(false);
  }, [start, end]);

  useEffect(() => {
    if (rangeOption === GTE) {
      setInsideChange(true);
      onChangeEnd('');
    }

    if (rangeOption === LTE) {
      setInsideChange(true);
      onChangeStart('');
    }
  }, [rangeOption]);

  const handleSelectChange = (option) => {
    setRangeOption(option.value);
  };

  const handleChangeStart = (event) => {
    setInsideChange(true);
    onChangeStart(event.target.value);
  };

  const handleChangeEnd = (event) => {
    setInsideChange(true);
    onChangeEnd(event.target.value);
  };

  function renderComponent() {
    return (
      <div>
        <Select
          className={styles.select}
          placeholder=""
          onChange={handleSelectChange}
          options={OPTIONS}
          value={rangeOption}
          clearable={false}
          searchable={false}
          hasNewStyle
        />
        <div className={styles.inputs}>
          {showGreaterThan && (
            <Input
              type="number"
              value={start}
              onChange={handleChangeStart}
              placeholder=""
            />
          )}
          {showTo && (
            <span>to</span>
          )}
          {showLessThan && (
            <Input
              type="number"
              value={end}
              onChange={handleChangeEnd}
              placeholder=""
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <BaseOption
      {...titles}
      className="form-group"
      name={keyName}
      overrideValue={value}
      defaultSelections={value}
      customComponent={renderComponent()}
    />
  );
}

RangeField.defaultProps = {
  titles: {},
};

RangeField.propTypes = {
  keyName: PropTypes.string,
  titles: PropTypes.object,
  value: PropTypes.object.isRequired,
  onChangeStart: PropTypes.func.isRequired,
  onChangeEnd: PropTypes.func.isRequired,
};

export default RangeField;
