import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View } from '@react-pdf/renderer';

import { LabelText, LabelTitle, LabelLink } from 'components/core/ExportDocument';
import { EMPTY_VALUE } from 'utils/constants';

import styles from './styles';

function LegislatorBills({ title, bills, style }) {
  const currentTitle = (
    <View fixed>
      <LabelTitle fixed label={title} style={styles.labelTitle} />
    </View>
  );

  if (isEmpty(bills)) {
    return (
      <View style={style}>
        {currentTitle}
        <LabelText style={styles.lineBottom} />
      </View>
    );
  }

  function getDescriptionItem(itemValue) {
    if (isEmpty(itemValue)) {
      return null;
    }

    return <LabelText textStyle={styles.billDescriptionText} value={itemValue} />;
  }

  function getBill(bill, index) {
    const { billId, billUrl, billTitle, status, sponsorName } = bill;

    if (isEmpty(billId)) {
      return null;
    }

    const currentSponsor = sponsorName ? `Sponsor: ${sponsorName}` : '';

    return (
      <View
        wrap={false}
        key={`bill-${title}-${index}`}
        className="legislator-bill" style={styles.billItem}
      >
        <View style={styles.billTitleSection}>
          <LabelLink linkStyle={styles.billTitleText} value={billId} href={billUrl} />
          {getDescriptionItem(billTitle)}
        </View>

        <View style={styles.billStatusSection}>
          <LabelText
            textStyle={styles.billDescriptionText}
            value={`Status: ${status || EMPTY_VALUE}`}
          />
          {getDescriptionItem(currentSponsor)}
        </View>
      </View>
    );
  }

  return (
    <View style={style}>
      {currentTitle}
      {bills.map(getBill)}
    </View>
  );
}

LegislatorBills.propTypes = {
  title: PropTypes.string.isRequired,
  bills: PropTypes.array,
  style: PropTypes.object,
};

export default LegislatorBills;
