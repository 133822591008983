import React from 'react';
import PropTypes from 'prop-types';

import withMap from 'components/core/Map/utils/withMap';

import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';

function Filters({ showOnlyDesktopFilters, isMobile, onChangeFilter }) {
  function handleFilterClick(keyName) {
    return selectedFilterValue => onChangeFilter(keyName, selectedFilterValue);
  }

  return isMobile && !showOnlyDesktopFilters
    ? <MobileView onFilterClick={handleFilterClick} />
    : <DesktopView onFilterClick={handleFilterClick} />;
}

Filters.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  showOnlyDesktopFilters: PropTypes.bool,
  onChangeFilter: PropTypes.func.isRequired,
};

export default withMap(Filters);
