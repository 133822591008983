import { filter, find, map, reduce } from 'lodash';

import {
  LOGO_CUSTOMIZATION_ITEM,
  SUMMARY_CUSTOMIZATION_ITEM,
  SHOW_PAGE_CUSTOMIZATION_ITEM,
  FILE_ITEMS,
} from 'utils/constants/export';

export function getCustomizationSectionProps({ withExportSummary, companyHasLogo, isPDF, withGovReportToPDF }) {
  const tooltipContent = `You do not currently have a logo uploaded.
  Please contact your customer success representative to upload a logo.`;
  const showSummaryCustomization = withExportSummary && isPDF;
  const showPageCustomization = !withGovReportToPDF && isPDF;

  const exportCustomizationItems = filter([
    showPageCustomization && SHOW_PAGE_CUSTOMIZATION_ITEM,
    { ...LOGO_CUSTOMIZATION_ITEM, isDisabled: !companyHasLogo, tooltipContent },
    showSummaryCustomization && SUMMARY_CUSTOMIZATION_ITEM,
  ]);

  return { exportCustomizationItems };
}

export const getFormatItems = customFileFormats => map(
  customFileFormats,
  format => find(FILE_ITEMS, { id: format })
);

export const genericGetActiveSections = (sections, ids) => {
  const isActiveSection = section => ids[section] && { [ids[section]]: true };

  const sectionsReducer = (accumulator, section) => ({
    ...accumulator,
    ...isActiveSection(section),
  });

  return reduce(sections, sectionsReducer, {});
};

export const genericFetchComponentData = (prop, ids) => async ({ sections } = {}) => (
  { [prop]: genericGetActiveSections(sections, ids) }
);
