import PropTypes from 'prop-types';

export const sortShape = PropTypes.shape({
  toReset: PropTypes.bool,
});

export const btnPropsShape = PropTypes.shape({
  className: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
});

export const selectedItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    isEstimatedDate: PropTypes.bool,
    locale: PropTypes.string,
    snippet: PropTypes.string,
    state: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        alpha2: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ]),
    title: PropTypes.string,
    url: PropTypes.string,
  })
);

export const mapDataShape = PropTypes.shape({
  clusterName: PropTypes.string,
  defaultFilters: PropTypes.shape({
    displayType: PropTypes.string,
    mapType: PropTypes.string,
  }),
  defaultLoadingMessage: PropTypes.string,
  getFilters: PropTypes.func,
  heatmapName: PropTypes.string,
  onFilterChange: PropTypes.func,
  profileUrl: PropTypes.string,
  withFilters: PropTypes.bool,
  withHeatmap: PropTypes.bool,
  withNavigation: PropTypes.bool,
});

export const advancedSearchShape = PropTypes.shape({
  isVisible: PropTypes.bool,
  id: PropTypes.string,
  lastSearchId: PropTypes.number,
  loadAllRecentSearches: PropTypes.func,
  loadAllSavedSearches: PropTypes.func,
  loadRecentSearches: PropTypes.func,
  loadSavedSearches: PropTypes.func,
  isSaveNameEnabled: PropTypes.bool,
  advancedContainerClassName: PropTypes.string,
  setLastSearchId: PropTypes.func,
  transformSearchesForUI: PropTypes.func,
  saveInRecentSearch: PropTypes.func,
  ui: PropTypes.shape({
    isSavedSearchesLoading: PropTypes.bool,
    isRecentSearchesLoading: PropTypes.bool,
  }),
  filtersCountFn: PropTypes.func,
});
