import PropTypes from 'prop-types';

import { tagShape } from 'components/Dashboard/common/FeedPage/utils/shapes';
import { subscriptionInfoShape } from 'components/core/TrackButton';
import { workspaceShape } from 'redux/events/utils/shapes';
import { trackingShape } from 'redux/subscriptions/utils/shapes';

export const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  url: PropTypes.string,
  billUrl: PropTypes.string,
  documentType: PropTypes.string,
  trackingType: PropTypes.string,
  title: PropTypes.string,
  itemType: PropTypes.string,
  tags: PropTypes.arrayOf(tagShape),
  subscription: subscriptionInfoShape,
  withTrack: PropTypes.bool,
  sharings: PropTypes.arrayOf(workspaceShape),
  trackings: PropTypes.arrayOf(trackingShape),
});
