import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import PartyIcon from 'components/shared/party-icon';

import { TYPES } from '../constants';

const typesWithPartyIcon = [
  TYPES.FEDERAL_LEGISLATOR,
  TYPES.STATE_LEGISLATOR,
  TYPES.STATE_STAFFER,
  TYPES.FEDERAL_STAFFER,
];

function OptionPartyIcon({ type, partySlug }) {
  const hasPartyIcon = typesWithPartyIcon.includes(type);

  if (isEmpty(partySlug) || !hasPartyIcon) {
    return null;
  }

  const partyShort = partySlug.slice(0, 1).toUpperCase();

  return (
    <PartyIcon
      extraSideMargin
      partyShort={partyShort}
      partySlug={partySlug}
    />
  );
}

OptionPartyIcon.propTypes = {
  partySlug: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
};

export default OptionPartyIcon;
