import { StyleSheet } from '@react-pdf/renderer';
import { DEFAULT_LINE_COLOR } from 'components/core/ExportDocument/utils/colors';

const styles = StyleSheet.create({
  billCard: {
    marginBottom: 6,
  },
  billBodySection: {
    padding: '7px 10px 0 10px',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: DEFAULT_LINE_COLOR,
  },
  withoutHeader: {
    borderTopWidth: 1,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  withoutFooter: {
    borderBottomWidth: 1,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
});

export default styles;
