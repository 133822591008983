import handleDroppedStatementReorder from './handleDroppedStatementReorder';
import handleDroppedStatementGroup from './handleDroppedStatementGroup';
import handleDroppedFilterReorder from './handleDroppedFilterReorder';
import handleDroppedStatementJoin from './handleDroppedStatementJoin';
import handleDroppedFilterGroup from './handleDroppedFilterGroup';
import handleDroppedFilterJoin from './handleDroppedFilterJoin';

export default function dragEndActions({ action, ...rest }) {
  const actions = {
    isDroppedFilterJoin() {
      return handleDroppedFilterJoin(rest);
    },
    isDroppedFilterGroup() {
      return handleDroppedFilterGroup(rest);
    },
    isDroppedStatementJoin() {
      return handleDroppedStatementJoin(rest);
    },
    isDroppedStatementGroup() {
      return handleDroppedStatementGroup(rest);
    },
    isDroppedStatementReorder() {
      return handleDroppedStatementReorder(rest);
    },
    isDroppedFilterReorder() {
      return handleDroppedFilterReorder(rest);
    },
  };

  return actions[action] || (() => {});
}
