import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';

import { QuestionMark } from 'components/core/Icons';
import { TOOLTIP_OFFSET } from 'utils/constants';
import { tooltipZIndex } from 'style/global/_variables.scss';

import styles from './styles.module.scss';

function TooltipComponent({ content, theme }) {
  if (!content) {
    return null;
  }

  return (
    <div className={styles.tooltip}>
      <Tooltip
        content={content}
        theme={theme}
        placement="bottom"
        offset={TOOLTIP_OFFSET}
        zIndex={tooltipZIndex}
      >
        <QuestionMark className={styles.tooltipIcon} />
      </Tooltip>
    </div>
  );
}

TooltipComponent.propTypes = {
  content: PropTypes.string,
  theme: PropTypes.string,
};

export default TooltipComponent;
