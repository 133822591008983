import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import { TextValidator, TextInputValidator } from 'components/core/Validators';

import Modal from '../Modal';
import styles from './styles.module.scss';

const validators = {
  default: TextValidator,
  input: TextInputValidator,
};

export default function TextConfirmation({
  isOpen,
  title,
  validationText,
  confirmationMessage,
  submitButtonText,
  onToggle,
  onSubmit,
  isLoading,
  validatorType,
  errorMessage,
  isCaseSensitive,
}) {
  const [isValid, setIsValid] = useState(false);

  function handleValidationStatusChange(status) {
    setIsValid(status);
  }

  const buttons = (
    <>
      <Button
        size="small"
        theme="light"
        onClick={onToggle}
        className={classNames('cancel-button', styles.button)}
      >
        Cancel
      </Button>
      <Button
        size="small"
        disabled={isLoading || !isValid}
        onClick={onSubmit}
        className={classNames('submit-button', styles.button)}
      >
        {isLoading
          ? <Loader size="small" theme="light" className={styles.loader} />
          : <span>{submitButtonText}</span>}
      </Button>
    </>
  );

  const { [validatorType]: Validator } = validators;

  return (
    <Modal
      isOpen={isOpen}
      toggleOpen={onToggle}
      title={title}
      buttons={buttons}
      headerClassName={styles.header}
      footerClassName={styles.footer}
      className={styles.modal}
    >
      <>
        {confirmationMessage}
        <Validator
          text={validationText}
          onValidationStatusChange={handleValidationStatusChange}
          errorMessage={errorMessage}
          className={styles.textValidator}
          isCaseSensitive={isCaseSensitive}
        />
      </>
    </Modal>
  );
}

TextConfirmation.defaultProps = {
  submitButtonText: 'Submit',
  isLoading: false,
  validatorType: 'default',
};

TextConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  validationText: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  submitButtonText: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validatorType: PropTypes.oneOf(Object.keys(validators)),
  errorMessage: PropTypes.string,
  isCaseSensitive: PropTypes.bool,
};
